import React, { createContext, useContext, useState, ReactNode } from 'react';

interface RegisteredFlowStatusContextValue {
    showPatientManagementStructureStatus: boolean;
    setShowPatientManagementStructureStatus: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    reLoadMainInformationStatus: boolean;
    setReLoadMainInformationStatus: React.Dispatch<
        React.SetStateAction<boolean>
    >;

    registeredFlowStatus: string;
    setRegisteredFlowStatus: React.Dispatch<React.SetStateAction<string>>;
    addNewDataToWaitingTableStatus: boolean;
    setAddNewDataToWaitingTableStatus: React.Dispatch<
        React.SetStateAction<boolean>
    >;
  
}

const RegisteredFlowStatusContext = createContext<
    RegisteredFlowStatusContextValue | undefined
>(undefined);

interface RegisteredFlowStatusProviderProps {
    children: ReactNode;
}

export const RegisteredFlowStatusProvider: React.FC<
    RegisteredFlowStatusProviderProps
> = ({ children }) => {
    const [registeredFlowStatus, setRegisteredFlowStatus] =
        useState<string>('');
    const [
        showPatientManagementStructureStatus,
        setShowPatientManagementStructureStatus,
    ] = useState<boolean>(false);
    const [addNewDataToWaitingTableStatus, setAddNewDataToWaitingTableStatus] =
        useState<boolean>(false);
    const [    reLoadMainInformationStatus,
        setReLoadMainInformationStatus
    ]= useState<boolean>(false)
    return (
        <RegisteredFlowStatusContext.Provider
            value={{
                registeredFlowStatus,
                setRegisteredFlowStatus,
                showPatientManagementStructureStatus,
                setShowPatientManagementStructureStatus,
                addNewDataToWaitingTableStatus,
                setAddNewDataToWaitingTableStatus,
                reLoadMainInformationStatus,
                setReLoadMainInformationStatus
                
            }}
        >
            {children}
        </RegisteredFlowStatusContext.Provider>
    );
};

export const useRegisteredFlowStatus = (): RegisteredFlowStatusContextValue => {
    const context = useContext(RegisteredFlowStatusContext);

    if (!context) {
        throw new Error(
            'useRegisteredFlowStatus must be used within a RegisteredFlowStatusProvider'
        );
    }

    return context;
};
