import { drugTypeListProps } from 'service/drugTypeListService';
import { PrescriptionProps } from 'store/TreatmentManagement';
import { InstitutionInfoProps } from 'store/DataSetting/useInstitutionInfoStore';

const virtualMedicalOrder = 'R001,R002,R003,R004,R005,R006,R007,R008,COVID19AZ,COVID19PBNT,COVID19MOD,COVID19MVC,VIT-COVID19,PHT-COVID19,FSTP-COVID19,FSTN-COVID19,PCRP-COVID19,PCRN-COVID19,NND009,NND000,HSTP-COVID19';

export const D02orderTypeLogic = (
    drug: PrescriptionProps,
    drugTypeCode: string,
    institutionInfoData: InstitutionInfoProps
): string => {
    console.log(drug, '藥品', drugTypeCode, '藥類', institutionInfoData.prescription_style, '調劑方式')
    let orderType = "1";
    // 虛擬醫令
    if (drug.drug_no && drug.drug_no.trim() !== '' && virtualMedicalOrder.includes(drug.drug_no)) {
        orderType = "G";
    }
    // 矯正機關代號  目前應該沒有跳過
    // else if (drug.ChronicCode === "JA") {
    //     orderType = "J";
    // }
    // // 特材資料明細
    else if (drugTypeCode === 'M' || (drug.drug_no && drug.drug_no.length === 12)) {
        orderType = "3";
    }
    // 排程未執行之檢查/檢驗
    else if (drug.special_mark?.includes('O')) { 
        orderType = "P";
    }
    // 轉(代)檢之檢查/檢驗(支付標準)
    else if ((drugTypeCode === "E" || drugTypeCode === "Q") && institutionInfoData.prescription_style === 1){
        orderType = "R";
    }
    // // 不計價 => 112.12.07 IC上傳D02不計價應填M For 得得
    else if ((drugTypeCode === "O" || drugTypeCode === "L" || drugTypeCode === "W") && institutionInfoData.prescription_style === 1) {
        orderType = "M";
    }
    // 支付標準(診療)（藥類不等於 OLIW）
    else if (drugTypeCode !== "O" && drugTypeCode !== "L" && drugTypeCode !== "I" && drugTypeCode !== "W" ) {
        orderType = "2";
    }

    return orderType;
};