import React, { useState } from 'react';
import { Flex, Tooltip } from '@chakra-ui/react';
import { useUserStore } from 'store';
import { usePrescriptionStore } from 'store/TreatmentManagement';
import { ChronicSetting } from './ChronicSetting';
import { DrugFormsSetting } from './DrugFormsSetting';

export const ControlButtons: React.FC = () => {
    const { userInfo } = useUserStore();
    const { prescriptionList, updatePrescription } = usePrescriptionStore();
    const [isPowder, setIsPowder] = useState<boolean>(false);

    const handleChangeForms = () => {
        setIsPowder(prev => !prev);
    };

    return (
        <>
            <Flex gap={'8px'} hidden={true}>
                <ChronicSetting />

                <DrugFormsSetting
                    isPowder={isPowder}
                    onClick={handleChangeForms}
                />
            </Flex>
        </>
    );
};
