import {
    Flex,
    Box,
    Text,
    Button,
    Center,
    Checkbox,
    list,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { useIsICWriteInOpenStatus } from 'contexts/ICUpload/IsICWriteInOpen';
import { icDataListsDataProps } from 'service/icDataListsService';
import { ADStringToROCString } from 'functions/ADStringToROCString';
import { useEffect } from 'react';
import { useCallback } from 'react';

import { useUserStore } from 'store';
import {
    callIcDataChangeStatusService,
    icDataChangeStatusReqDataProps,
} from 'service/icDataChangeStatueService';
import { useLocation, useNavigate } from 'react-router-dom';

import { useIcDataListsAPI } from 'service/icDataListsServiceHelper';
import { useIcDataVoidAPI } from 'service/icDataVoidServiceHelper';
import { useICCardUpdateListStore } from 'store/ICCardUpdate/useICCardUpdateListStore';
import { useICCardUpdateRangeStore } from 'store/ICCardUpdate/useICCardUpdateRangeStore';
import { usePrescriptionStore } from 'store/TreatmentManagement';
import { useMedicalRecordStatus } from 'contexts/TreatmentManagement/MedicalRecordStatusContext';
import { useHistoryStore } from 'store/TreatmentManagement';
import {
    useTreatmentInfoStore,
    useCCPEStore,
    useDxStore,
    usePricingStore,
} from 'store/TreatmentManagement';

import Swal from 'sweetalert2';

export type BodyProps = {
    tabIndex?: number;
    listData?: icDataListsDataProps[];
};

export const Body: React.FC<BodyProps> = ({ tabIndex, listData }) => {
    const { userInfo } = useUserStore();
    const { callIcDataListsAPI } = useIcDataListsAPI();
    const { callIcDataVoidAPI } = useIcDataVoidAPI();
    const { updateTreatmentInfo, resetTreatmentInfo } = useTreatmentInfoStore();
    const { resetHistoryData } = useHistoryStore();
    const { updatePrescription, resetPrescriptionList } =
        usePrescriptionStore();
    const { updateDxList, resetDx } = useDxStore();
    const navigate = useNavigate();
    const { resetPricing } = usePricingStore();
    const { updateCCPEData, resetCCPEData } = useCCPEStore();
    const { setMedicalRecordStatus } = useMedicalRecordStatus();
    const { isICWriteInOpenStatus, setIsICWriteInOpenStatus } =
        useIsICWriteInOpenStatus();
    const { icCardUpdateList, updateICCardUpdateList, clearICCardUpdateList } =
        useICCardUpdateListStore();
    const { icCardUpdateRangeData, updateICCardUpdateRangeData } =
        useICCardUpdateRangeStore();

    const handleICWriteInSwitch = () => {
        setIsICWriteInOpenStatus(prev => !prev);
    };
    useEffect(() => {
        console.log('body 的 listData:', listData);
    }, [listData]);

    const formatDate = (dateStr: string): string => {
        if (!dateStr) return '';
        const year = dateStr.slice(0, 3);
        const month = dateStr.slice(3, 5);
        const day = dateStr.slice(5, 7);
        return `${year}.${month}.${day}`;
    };

    const handleChangeStatus = (
        tabIndex: number,
        medical_record_uid: string
    ) => {
        let data = {
            data_id: medical_record_uid,
            status: tabIndex === 0 ? 'void' : 'formal',
        };

        callIcDataChangeStatusAPI(data);
    };

    const callIcDataChangeStatusAPI = async (
        data: icDataChangeStatusReqDataProps
    ) => {
        try {
            const result = await callIcDataChangeStatusService(
                userInfo.token,
                data
            );

            if (result.result === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: '更新成功',
                    showConfirmButton: false,
                    timer: 1500,
                });
                if (tabIndex === 0) {
                    // 呼叫 callIcDataListsAPI
                    callIcDataListsAPI();
                } else if (tabIndex === 1) {
                    // 呼叫 callIcDataVoidAPI
                    callIcDataVoidAPI();
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '更新失敗',
                    text: result.msg,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleGoToConsultingRoom = useCallback(
        (id: string | undefined, treated_at: string) => {
            if (id) {
                console.log('Go to consulting room', id);
                resetTreatmentInfo();
                resetHistoryData();
                resetPrescriptionList();
                resetDx();
                resetCCPEData();
                resetPricing();
                setMedicalRecordStatus('2');
                updateTreatmentInfo({
                    id: id,
                });
                setTimeout(() => {
                    navigate('/OPDPage/ConsultingRoomPage');
                }, 0);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '找不到此病歷',
                    text: '請確認病歷資料是否正確',
                    showConfirmButton: true,
                });
            }

            // updateCCPEData({
            //     cc:
            // })
        },
        [
            resetTreatmentInfo,
            resetHistoryData,
            resetPrescriptionList,
            resetDx,
            resetCCPEData,
            resetPricing,
            setMedicalRecordStatus,
            updateTreatmentInfo,
            navigate,
        ]
    );

    return (
        <>
            <Flex direction={'column'}>
                {listData &&
                    listData.map((item, index) => (
                        <Flex
                            borderBottom={'1px solid  #D9D9D9'}
                            align={'center'}
                            py={'4px'}
                            key={index}
                        >
                            <Box flex={'0 0 5%'}>
                                <Text px={'4px'} borderLeft={'1px solid #fff'}>
                                    <Box
                                        hidden={
                                            tabIndex === 0 &&
                                            (item.upload_status === 1 ||
                                                item.upload_status === 5)
                                        }
                                        textColor={'rsPrimary.500'}
                                        opacity={0.8}
                                        _hover={{
                                            opacity: 1,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            handleChangeStatus(
                                                tabIndex!,
                                                item.medical_record_uid
                                            );
                                        }}
                                    >
                                        {tabIndex === 0 ? (
                                            <HeroIcons name="delete" />
                                        ) : tabIndex === 1 ? (
                                            <HeroIcons name="return" />
                                        ) : (
                                            ''
                                        )}
                                    </Box>
                                </Text>
                            </Box>
                            <Box flex={'0 0 5%'}>
                                <Box borderLeft={'1px solid #fff'}>
                                    {item.signature ? (
                                        <Button
                                            colorScheme="rsPrimary"
                                            variant={'ghost'}
                                            size={'sm'}
                                        >
                                            已簽
                                        </Button>
                                    ) : (
                                        <Button
                                            colorScheme="rsDanger"
                                            variant={'outline'}
                                            bg={'#ECECEC'}
                                            size={'sm'}
                                            boxShadow={
                                                '4px 4px 2px 0px rgba(0, 0, 0, 0.25) inset;'
                                            }
                                        >
                                            未簽
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                            <Box flex={'0 0 5%'}>
                                <Center
                                    px={'4px'}
                                    borderLeft={'1px solid #fff'}
                                >
                                    {
                                        <Checkbox
                                            colorScheme="rsPrimary"
                                            // isChecked={item.write_in}
                                        ></Checkbox>
                                    }
                                </Center>
                            </Box>
                            <Box flex={'0 0 5%'}>
                                <Center
                                    px={'4px'}
                                    borderLeft={'1px solid #fff'}
                                >
                                    {
                                        <Checkbox
                                            colorScheme="rsPrimary"
                                            // isChecked={item.correct}
                                        ></Checkbox>
                                    }
                                </Center>
                            </Box>
                            <Box flex={'0 0 5%'}>
                                <Box
                                    px={'4px'}
                                    borderLeft={'1px solid #fff'}
                                    opacity={0.5}
                                    _hover={{
                                        cursor: 'pointer',
                                        opacity: 1,
                                    }}
                                    // onClick={handleICWriteInSwitch} //先放著
                                    onClick={() =>
                                        handleGoToConsultingRoom(
                                            item.opd_uid,
                                            item.treated_at
                                        )
                                    }
                                >
                                    <HeroIcons name="read-record" />
                                </Box>
                            </Box>
                            {/* <Box flex={'0 0 5%'}>
                                <Text>
                                    {item.upload_status === 0
                                        ? '未傳'
                                        : item.upload_status === 1
                                          ? '已傳'
                                          : item.upload_status === 5
                                            ? '不傳'
                                            : ''}
                                </Text>
                            </Box> */}
                            <Box flex={'0 0 10%'}>
                                <Text px={'4px'} borderLeft={'1px solid #fff'}>
                                    {item.treated_at
                                        ? ADStringToROCString(item.treated_at)
                                        : ''}
                                </Text>
                            </Box>
                            <Box flex={'0 0 10%'}>
                                <Text px={'4px'} borderLeft={'1px solid #fff'}>
                                    {item.medical_record_number}
                                </Text>
                            </Box>
                            <Box flex={'1 0 10%'}>
                                <Text px={'4px'} borderLeft={'1px solid #fff'}>
                                    {item.name}
                                </Text>
                            </Box>
                            <Box flex={'0 0 10%'}>
                                <Text px={'4px'} borderLeft={'1px solid #fff'}>
                                    {item.birth_date
                                        ? formatDate(item.birth_date)
                                        : ''}
                                </Text>
                            </Box>
                            <Box flex={'0 0 10%'}>
                                <Text px={'4px'} borderLeft={'1px solid #fff'}>
                                    {item.id_no}
                                </Text>
                            </Box>
                            <Box flex={'0 0 5%'}>
                                <Box px={'4px'} borderLeft={'1px solid #fff'}>
                                    {/* {item.return_single && (
                                        <Box textColor={'rsPrimary.500'}>
                                            <HeroIcons name="check-circle-fill" />
                                        </Box>
                                    )} */}
                                </Box>
                            </Box>
                            <Box flex={'0 0 10%'}>
                                <Text px={'4px'} borderLeft={'1px solid #fff'}>
                                    {item.mt_type}
                                </Text>
                            </Box>
                            <Box flex={'0 0 5%'}>
                                <Text px={'4px'} borderLeft={'1px solid #fff'}>
                                    {item.card_no}
                                </Text>
                            </Box>
                            <Box flex={'0 0 5%'}>
                                <Text px={'4px'} borderLeft={'1px solid #fff'}>
                                    {/* {item.healthcare} */}
                                </Text>
                            </Box>
                        </Flex>
                    ))}
            </Flex>
        </>
    );
};
