import axios from "axios";
// import RsApiService from './RSApiService'

// Rs中控讀卡

export const callRshisGetRegisterBasicService = async () => {
    try {
        const response = await axios.post('http://localhost:23752/cshis/RshisGetRegisterBasic', {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
            },
        });
        
        const responseParse = JSON.parse(response.data);
        console.log('這邊取得了什麼東西 registerBasic', responseParse);

        // 解析後的資料結構
        return {
            PatCardNumber: responseParse.PatCardNumber,
            PatName: responseParse.PatName,
            PatIDNo: responseParse.PatIDNo,
            PatBirth: responseParse.PatBirth,
            PatGender: responseParse.PatGender,
            PatCardDate: responseParse.PatCardDate,
            PatCancelMark: responseParse.PatCancelMark,
            PatInsurerCode: responseParse.PatInsurerCode,
            PatInsurerType: responseParse.PatInsurerType,
            PatCardExpDate: responseParse.PatCardExpDate,
            PatAvaliableCount: responseParse.PatAvaliableCount,
            PatNewBornBirth: responseParse.PatNewBornBirth,
            PatNewBornMark: responseParse.PatNewBornMark,
            pBuffer: responseParse.pBuffer
        };
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.error('Error:', error.message);
        } else {
            console.error('An unknown error occurred');
        }
        throw error; // 重新拋出錯誤，讓調用者可以處理
    }
};
