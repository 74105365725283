import { create } from 'zustand';

export interface TakingTimeListsProps {
    data_id: string;
    code: string;
    freq_code: string;
    ori_freq_code: string;
    directions: string;
    days: number;
    times: number;
    type: number | null;
    taking_time: string;
}

const initialTakingTimeLists: TakingTimeListsProps[] = [];

interface TakingTimeListsState {
    takingTimeListsData: TakingTimeListsProps[];
    updateTakingTimeListsData: (newData: TakingTimeListsProps[]) => void;
    resetTakingTimeListsData: () => void;
}

export const useTakingTimeListsStore = create<TakingTimeListsState>(set => ({
    takingTimeListsData: initialTakingTimeLists,
    updateTakingTimeListsData: newData =>
        set(() => ({
            takingTimeListsData: newData,
        })),
    resetTakingTimeListsData: () =>
        set(() => ({
            takingTimeListsData: initialTakingTimeLists,
        })),
}));
