import React, { useEffect, useState, ChangeEvent } from 'react';

import {
    Box,
    Flex,
    Text,
    Input,
    Button,
    Select,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
} from '@chakra-ui/react';

import moment from 'moment';
import Swal from 'sweetalert2';
import { useQuery } from 'react-query';

// API
import { callRegFeeSettingService } from 'service/regFeeSettingService';
import {
    callScheduleDoctorRoomService,
    scheduleDoctorRoomObjProps,
} from 'service/scheduleDoctorRoomService';
import {
    callAccItemsLevelService,
    accItemsLevelProps,
} from 'service/accItemsLevelService';
import {
    callGTypeReservationTypeService,
    reservationTypeProps,
    callGTypeRegFeeIdenService,
    regFeeIdenProps,
    callGTypeMedicalTypeService,
    medicalTypeProps,
} from 'service/gTypeService';
import {
    callMedicalStaffListService,
    medicalStaffListsProps,
} from 'service/medicalStaffListService';
import {
    callMembershipService,
    membershipProps,
} from 'service/memberShipService';
import { callShiftService, shiftProps } from 'service/shiftService';

import { clinicRoomProps } from 'service/clinicRoomService';

// Store
import { useCurrentVisitInfoStore } from 'store/Registration';
import { usePatientInfoStore } from 'store/Registration';
import { useUserStore } from 'store';

function CurrentRegistered() {
    const { userInfo } = useUserStore();
    const { patientInfo } = usePatientInfoStore();
    const [todayDate, setTodayDate] = useState('');
    const { currentVisitInfoData, updateCurrentVisitInfoData } =
        useCurrentVisitInfoStore();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const openExceptionListBtnRef = React.useRef<HTMLButtonElement>(null);
    const [regFeeIdenLists, setRegFeeIdenLists] = useState<regFeeIdenProps[]>(
        []
    );
    const [HGFeeUid, setHGFeeUid] = useState('');
    const [COFeeUid, setCOFeeUid] = useState('');
    const [LPAYFeeUid, setLPAYFeeUid] = useState('');
    const exceptionStateListAPI = [
        { code: 'A000', desc: '讀卡設備故障' },
        { code: 'A010', desc: '讀卡機故障' },
        { code: 'A020', desc: '網路故障造成讀卡機無法使用' },
        { code: 'A030', desc: '安全模組故障造成讀卡機無法使用' },
        { code: 'B000', desc: '卡片不良(表面正常，晶片異常)' },
        { code: 'C000', desc: '停電' },
        { code: 'C001', desc: '例外就醫' },
        { code: 'D000', desc: '醫療資訊系統(HIS)當機' },
        { code: 'D010', desc: '醫療院所電腦故障' },
        { code: 'E000', desc: '健保署資訊系統當機' },
        { code: 'E001', desc: '急診無法更新者' },
        { code: 'F000', desc: '院所赴偏遠地區無法上網' },
        { code: 'Z000', desc: '其他' },
        { code: 'Z00A', desc: '異常就醫序號' },
        { code: 'G000', desc: '網路未建置' },
        { code: 'MSPT', desc: '代謝症候群防治計畫' },
        { code: 'ICND', desc: '未具健保身份生產案件' },
        {
            code: 'ICC4',
            desc: '癌症治療品質計畫之追蹤及診斷品質管理',
        },
    ];
    const [shiftLists, setShiftLists] = useState<shiftProps[]>([]);
    const [todayDateWithDash, setTodayDateWithDash] = useState('');
    const [scheduleDoctorRoomObj, setScheduleDoctorRoomObj] = useState<
        scheduleDoctorRoomObjProps[]
    >([]);



    useEffect(() => {
        const rocYear = (parseInt(moment().format('YYYY')) - 1911).toString();
        const mm = moment().format('MM');
        const dd = moment().format('DD');

        const rocTodayDate = `${rocYear} 年 ${mm} 月 ${dd} 日`;
        const rocTodayDateWithDash = `${rocYear}-${mm}-${dd}`;
        setTodayDate(rocTodayDate);
        setTodayDateWithDash(rocTodayDateWithDash);
    }, []);
    const handleInsertException = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        const dataCode = event.currentTarget.getAttribute('data-code');
        if (dataCode) {
            // Ensure dataCode exists before updating
            updateCurrentVisitInfoData({
                card_no: dataCode,
            });
        }
        onClose();
        Swal.fire({
            icon: 'success',
            title: '更新成功',
            timer: 1500,
        });
    };

    const [reservationTypeLists, setReservationTypeLists] = useState<
        reservationTypeProps[]
    >([]);

    const { data: reservationTypeData, isSuccess: isReservationTypeSuccess } =
        useQuery(
            'reservationType',
            () => callGTypeReservationTypeService(userInfo.token!),
            {
                enabled: !!userInfo.token,
                staleTime: Infinity,
            }
        );

    const { data: regFeeIdenData, isSuccess: isRegFeeIdenSuccess } = useQuery(
        'regFeeIden',
        () => callGTypeRegFeeIdenService(userInfo.token!),
        {
            enabled: !!userInfo.token,
            staleTime: Infinity,
        }
    );

    const { data: membershipData, isSuccess: isMembershipSuccess } = useQuery(
        'membership',
        () => callMembershipService(userInfo.token!),
        {
            enabled: !!userInfo.token,
            staleTime: Infinity,
        }
    );

    const [regFeeSettingLists, setRegFeeSettingLists] = useState<[]>();
    const { data: regFeeSettingData, isSuccess: isRegFeeSettingSuccess } =
        useQuery(
            'regFeeSetting',
            () => callRegFeeSettingService(userInfo.token!),
            { enabled: !!userInfo.token, staleTime: Infinity }
        );
    useEffect(() => {
        if (isRegFeeSettingSuccess) {
            setRegFeeSettingLists(regFeeSettingData.fee_setting);
        }
    }, [isRegFeeSettingSuccess]);
    // 取得醫師清單
    const { data: medicalStaffListData, isSuccess: isMedicalStaffListSuccuss } =
        useQuery(
            ['medicalStaffList', userInfo.token],
            () => callMedicalStaffListService(userInfo.token!, 'iden_doctor'),
            { enabled: !!userInfo.token, staleTime: Infinity }
        );

    // 取得時段

    const { data: shiftListData, isSuccess: isShiftListSuccess } = useQuery(
        ['shift', userInfo.token],
        () => callShiftService(userInfo.token!),
        { enabled: !!userInfo.token, staleTime: Infinity }
    );

    const {
        data: scheduleDoctorRoomData,
        isSuccess: isScheduleDoctorRoomSuccess,
    } = useQuery(
        'scheduleDoctorRoom',
        () => callScheduleDoctorRoomService(userInfo.token!, todayDateWithDash),
        {
            enabled: !!userInfo.token,
            staleTime: Infinity,
        }
    );
    useEffect(() => {
        if (isScheduleDoctorRoomSuccess) {
            setScheduleDoctorRoomObj(scheduleDoctorRoomData.lists);
        }
    }, [isScheduleDoctorRoomSuccess]);

    const [membershipLists, setMembershipLists] = useState<membershipProps[]>(
        []
    );
    const [medicalStaffDoctorLists, setMedicalStaffDoctorLists] = useState<
        medicalStaffListsProps[]
    >([]);
    // const [clinicRoomLists, setClinicRoomLists] = useState<clinicRoomProps[]>(
    //     []
    // );

    const handleChangeInput = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        if (name === 'treatmentSeq') {
            // const isValid = /^[0-9]{0,3}$/.test(value);
            // if(isValid){
            //     updateCurrentVisitInfoData({
            //         ...currentVisitInfoData,
            //         [name]: value
            //     })
            // }
        } else if (name === 'clinic_room_and_doctor') {
            let clinic_room_and_doctor = value.split('_');
            let clinic_room_uid = clinic_room_and_doctor[0];
            let doctor_uid = clinic_room_and_doctor[1];

            updateCurrentVisitInfoData({
                ...currentVisitInfoData,
                clinic_room_and_doctor: value,
                doctor_uid: doctor_uid,
                clinic_room_uid: clinic_room_uid,
            });
        } else if (name.startsWith('cashier[')) {
            const index = parseInt(name.split('[')[1].split(']')[0]);
            const propName = name.split('.')[1];

            const updatedCashiers = [...(currentVisitInfoData.cashier || [])];
            if (propName === 'price') {
                const newValue = value === '' ? 0 : Number(value);
                const isValid = /^[0-9]*$/.test(value); // 允許空值
                if (isValid) {
                    let acc_item_uid = '';
                    let itemIndex = -1;

                    switch (index) {
                        case 0:
                            itemIndex = updatedCashiers.findIndex(
                                item => item.acc_name === '掛號費'
                            );
                            acc_item_uid =
                                accItemsLevel3.find(item => item.code === 'HG')
                                    ?.id ?? '';
                            break;
                        case 1:
                            itemIndex = updatedCashiers.findIndex(
                                item => item.acc_name === '自付額'
                            );
                            acc_item_uid =
                                accItemsLevel3.find(item => item.code === 'CO')
                                    ?.id ?? '';
                            break;
                        case 2:
                            itemIndex = updatedCashiers.findIndex(
                                item => item.acc_name === '押單費'
                            );
                            acc_item_uid =
                                accItemsLevel3.find(
                                    item => item.code === 'LPAY'
                                )?.id ?? '';
                            break;
                        default:
                            acc_item_uid = '';
                    }

                    if (itemIndex !== -1) {
                        updatedCashiers[itemIndex] = {
                            ...updatedCashiers[itemIndex],
                            acc_item_uid: acc_item_uid,
                            price: newValue,
                            discount: 0,
                            final: newValue,
                        };
                    }
                } else {
                    return;
                }
            }

            updateCurrentVisitInfoData({
                ...currentVisitInfoData,
                cashier: updatedCashiers,
            });
        } else {
            updateCurrentVisitInfoData({
                ...currentVisitInfoData,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        updateCurrentVisitInfoData({
            ...currentVisitInfoData,
            nhi_type: patientInfo.info.nhi_identity,
            member_uid: patientInfo.info.membership,
        });
    }, []);

    const [medicalTypeLists, setMedicalTypeLists] = useState<
        medicalTypeProps[]
    >([]);

    const { data: medicalTypeData, isSuccess: isMedicalTypeSuccess } = useQuery(
        'medicalType',
        () => callGTypeMedicalTypeService(userInfo.token!),
        { enabled: !!userInfo.token, staleTime: Infinity }
    );

    useEffect(() => {
        if (isReservationTypeSuccess) {
            setReservationTypeLists(reservationTypeData.lists);
        }
        if (isRegFeeIdenSuccess && regFeeIdenData?.result === 'success') {
            setRegFeeIdenLists(regFeeIdenData.lists);
        }
        if (isMembershipSuccess) {
            setMembershipLists(membershipData.lists);
        }
        if (isMedicalStaffListSuccuss) {
            setMedicalStaffDoctorLists(medicalStaffListData.lists);
        }
        // if (isClinicRoomListSuccess) {
        //     setClinicRoomLists(clinicRoomListData.lists);
        // }
        if (isShiftListSuccess) {
            setShiftLists(shiftListData.lists);
        }
        if (isMedicalTypeSuccess) {
            setMedicalTypeLists(medicalTypeData.lists);
        }
    }, [
        isReservationTypeSuccess,
        isRegFeeIdenSuccess,
        isMembershipSuccess,
        isMedicalStaffListSuccuss,
        // isClinicRoomListSuccess,
        isShiftListSuccess,
        isMedicalTypeSuccess,
    ]);

    useEffect(() => {
        let newDate = new Date();
        let hours = newDate.getHours().toString().padStart(2, '0');
        let minutes = newDate.getMinutes().toString().padStart(2, '0');
        let nowTime = hours + minutes;
        const nowTimeInt = parseInt(nowTime);

        if (shiftLists) {
            let shifts = shiftLists
                .map(shift => ({
                    ...shift,
                    startTimeInt: parseInt(
                        shift.start_time.split(':').join('')
                    ),
                    endTimeInt: parseInt(shift.end_time.split(':').join('')),
                }))
                .sort((a, b) => a.startTimeInt - b.startTimeInt);

            for (let i = 0; i < shifts.length; i++) {
                if (i + 1 < shifts.length) {
                    shifts[i].endTimeInt = shifts[i + 1].startTimeInt;
                } else {
                    shifts[i].endTimeInt = 2400;
                }
            }

            const filteredDataIds = shifts
                .filter(shift => {
                    return (
                        shift.startTimeInt <= nowTimeInt &&
                        nowTimeInt < shift.endTimeInt
                    );
                })
                .map(shift => shift.data_id);

            updateCurrentVisitInfoData({
                shift_uid: filteredDataIds.toString(),
            });
        }
    }, [shiftLists]);

    const [scheduleDoctorRoomLists, setScheduleDoctorRoomLists] = useState<
        scheduleDoctorRoomProps[]
    >([]);
    interface scheduleDoctorRoomProps {
        data_id: string;
        name: string;
    }

    // 判斷今日時段診間 醫師lists
    useEffect(() => {
        let arr: scheduleDoctorRoomProps[] = [];
        scheduleDoctorRoomObj &&
            Object.entries(scheduleDoctorRoomObj).forEach(([key, value]) => {
                if (
                    Array.isArray(value) &&
                    key === currentVisitInfoData.shift_uid
                ) {
                    value.forEach((item, index) => {
                        Object.entries(item).forEach(([subKey, subValue]) => {
                            let finalList = {
                                data_id: subKey,
                                name: subValue as string,
                            };
                            arr.push(finalList);
                        });
                    });
                }
            });
        setScheduleDoctorRoomLists(arr);
    }, [scheduleDoctorRoomObj, currentVisitInfoData.shift_uid]);

    // 依保險對象身份註記判斷自付額及掛號身份  (目前沒做中低收跟災民 maybe會都填載nhi_type欄位)
    useEffect(() => {
        switch (patientInfo.info.insurerCode) {
            case '1':
                // updateCashierFinal();
                updateCurrentVisitInfoData({
                    ...currentVisitInfoData,
                    nhi_type: 'li',
                });

                break;
            case '2':
                updateCurrentVisitInfoData({
                    ...currentVisitInfoData,
                    nhi_type: 'hc',
                });
                break;
            case '3':
                break;
            case '4':
                // 中低收先不做
                break;
            case '8':
                updateCurrentVisitInfoData({
                    ...currentVisitInfoData,
                    nhi_type: 'hc',
                });
                break;
            default:
                break;
        }
    }, [patientInfo.info.insurerCode]);

    useEffect(() => {
        if (
            currentVisitInfoData.cashier &&
            currentVisitInfoData.cashier.length > 0
        ) {
            const updatedCashiers = [...currentVisitInfoData.cashier];
            let coFeeItemIndex;
            let lpayFeeItemIndex;

            lpayFeeItemIndex = updatedCashiers.findIndex(
                item => item.acc_name === '押單費'
            );
            switch (currentVisitInfoData.nhi_type) {
                case 'li':
                case 'hc':
                    coFeeItemIndex = updatedCashiers.findIndex(
                        item => item.acc_name === '自付額'
                    );
                    if (
                        coFeeItemIndex !== -1 &&
                        Number(updatedCashiers[coFeeItemIndex].price) !== 0
                    ) {
                        updatedCashiers[coFeeItemIndex] = {
                            ...updatedCashiers[coFeeItemIndex],
                            price: 0,
                            final: 0,
                        };
                    }
                    if (
                        coFeeItemIndex !== -1 &&
                        Number(updatedCashiers[lpayFeeItemIndex].price) == 350
                    ) {
                        updatedCashiers[lpayFeeItemIndex] = {
                            ...updatedCashiers[lpayFeeItemIndex],
                            price: 0,
                            final: 0,
                        };
                    }
                    break;
                case 'hi':
                case 'se':
                    coFeeItemIndex = updatedCashiers.findIndex(
                        item => item.acc_name === '自付額'
                    );
                    if (
                        coFeeItemIndex !== -1 &&
                        Number(updatedCashiers[coFeeItemIndex].price) !== 50
                    ) {
                        updatedCashiers[coFeeItemIndex] = {
                            ...updatedCashiers[coFeeItemIndex],
                            price: 50,
                            final: 50,
                        };
                    }
                    if (
                        coFeeItemIndex !== -1 &&
                        Number(updatedCashiers[lpayFeeItemIndex].price) == 350
                    ) {
                        updatedCashiers[lpayFeeItemIndex] = {
                            ...updatedCashiers[lpayFeeItemIndex],
                            price: 0,
                            final: 0,
                        };
                    }
                    break;
                case 'dp':
                    coFeeItemIndex = updatedCashiers.findIndex(
                        item => item.acc_name === '自付額'
                    );
                    if (
                        coFeeItemIndex !== -1 &&
                        Number(updatedCashiers[coFeeItemIndex].price) !== 50
                    ) {
                        updatedCashiers[coFeeItemIndex] = {
                            ...updatedCashiers[coFeeItemIndex],
                            price: 50,
                            final: 50,
                        };
                    }
                    if (
                        coFeeItemIndex !== -1 &&
                        Number(updatedCashiers[lpayFeeItemIndex].price) == 0
                    ) {
                        updatedCashiers[lpayFeeItemIndex] = {
                            ...updatedCashiers[lpayFeeItemIndex],
                            price: 350,
                            final: 350,
                        };
                    }
                    break;

                default:
                    break;
            }

            updateCurrentVisitInfoData({
                ...currentVisitInfoData,
                cashier: updatedCashiers,
            });
        }
    }, [currentVisitInfoData.nhi_type]);

    // 判斷年齡小於3及大於100歲自付額為0
    useEffect(() => {
        let today = new Date();
        if (patientInfo.info.birth_date) {
            let birthDate = patientInfo.info.birth_date;
            let ageGap = today.getTime() - birthDate.getTime();
            let ageGapDate = new Date(ageGap);
            let age = Math.abs(ageGapDate.getUTCFullYear() - 1970);
            if (age < 3 || age >= 100) {
                if (
                    currentVisitInfoData.cashier &&
                    currentVisitInfoData.cashier.length > 0
                ) {
                    const updatedCashiers = [...currentVisitInfoData.cashier];
                    const coFeeItemIndex = updatedCashiers.findIndex(
                        item => item.acc_name === '自付額'
                    );

                    if (
                        coFeeItemIndex !== -1 &&
                        Number(updatedCashiers[coFeeItemIndex].price) !== 0
                    ) {
                        updatedCashiers[coFeeItemIndex] = {
                            ...updatedCashiers[coFeeItemIndex],
                            price: 0,
                            final: 0,
                        };
                    }

                    // 更新 currentVisitInfoData
                    updateCurrentVisitInfoData({
                        ...currentVisitInfoData,
                        cashier: updatedCashiers,
                    });
                }
            }
        }
    }, [patientInfo.info.birth_date]);

    const { data: accItemsLevel3Data, isSuccess: isAccItemsLevel3DataSuccess } =
        useQuery(
            'accItemsLevel3',
            () => callAccItemsLevelService(userInfo.token, 3),
            {
                enabled: !!userInfo.token,
                staleTime: Infinity,
            }
        );
    const [accItemsLevel3, setAccItemLevel3] = useState<accItemsLevelProps[]>(
        []
    );
    useEffect(() => {
        if (isAccItemsLevel3DataSuccess) {
            setAccItemLevel3(accItemsLevel3Data.lists);
            setHGFeeUid(
                accItemsLevel3Data.lists.find(
                    (item: accItemsLevelProps) => item.code === 'HG'
                ).id
            );
            setCOFeeUid(
                accItemsLevel3Data.lists.find(
                    (item: accItemsLevelProps) => item.code === 'CO'
                ).id
            );
            setLPAYFeeUid(
                accItemsLevel3Data.lists.find(
                    (item: accItemsLevelProps) => item.code === 'LPAY'
                ).id
            );
        }
    }, [isAccItemsLevel3DataSuccess]);

    // 第一次還沒acc_item_uid的修改費用方式
    useEffect(() => {
      
        if (
            isRegFeeSettingSuccess &&
            isRegFeeSettingSuccess &&
            currentVisitInfoData
        ) {
            const nhiType =
                currentVisitInfoData.nhi_type === ''
                    ? 'hi'
                    : currentVisitInfoData.nhi_type; // "hi"
            const HGFeeByDataSetting =
                regFeeSettingData.fee_setting?.[nhiType]?.reg?.fee;
            // 目前沒 先做假的
            // const COFeeByDataSetting = regFeeSettingData.fee_setting?.[nhiType]?.?.fee;
            const COFeeByDataSetting = 50;
            const LPAYFeeByDataSetting =
                regFeeSettingData.fee_setting?.[nhiType]?.deposit?.fee;
            if (
                currentVisitInfoData.cashier &&
                currentVisitInfoData.cashier.length > 0
            ) {

                const updatedCashiers = [...currentVisitInfoData.cashier];
                const HGFeeItemIndex = updatedCashiers.findIndex(
                    item => item.acc_name === '掛號費'
                );
                const COFeeItemIndex = updatedCashiers.findIndex(
                    item => item.acc_name === '自付額'
                );
                const LPAYFeeItemIndex = updatedCashiers.findIndex(
                    item => item.acc_name === '押單費'
                );
                if (
                    HGFeeItemIndex !== -1 &&
                    updatedCashiers[HGFeeItemIndex].acc_item_uid === '' &&
                    updatedCashiers[HGFeeItemIndex].price !== HGFeeByDataSetting
                ) {
                    updatedCashiers[HGFeeItemIndex] = {
                        ...updatedCashiers[HGFeeItemIndex],
                        price: Number(HGFeeByDataSetting),
                        final: Number(HGFeeByDataSetting),
                    };
                    updateCurrentVisitInfoData({
                        ...currentVisitInfoData,
                        cashier: updatedCashiers,
                    });
                }
                if (
                    COFeeItemIndex !== -1 &&
                    updatedCashiers[COFeeItemIndex].acc_item_uid === '' &&
                    updatedCashiers[COFeeItemIndex].price !== COFeeByDataSetting
                ) {
                    updatedCashiers[COFeeItemIndex] = {
                        ...updatedCashiers[COFeeItemIndex],
                        price: Number(COFeeByDataSetting),
                        final: Number(COFeeByDataSetting),
                    };
                    updateCurrentVisitInfoData({
                        ...currentVisitInfoData,
                        cashier: updatedCashiers,
                    });
                }
           
                if (
                    LPAYFeeItemIndex !== -1 &&
                    updatedCashiers[LPAYFeeItemIndex].acc_item_uid === '' &&
                    updatedCashiers[LPAYFeeItemIndex].price !==
                        LPAYFeeByDataSetting &&
                    nhiType === 'dp'
                ) {
                    updatedCashiers[LPAYFeeItemIndex] = {
                        ...updatedCashiers[LPAYFeeItemIndex],
                        price: Number(LPAYFeeByDataSetting),
                        final: Number(LPAYFeeByDataSetting),
                    };
                    updateCurrentVisitInfoData({
                        ...currentVisitInfoData,
                        cashier: updatedCashiers,
                    });
                }
            }
        }
    }, [
        isRegFeeSettingSuccess,
        regFeeSettingData,
        currentVisitInfoData.nhi_type,
    ]);
    return (
        <>
            <Flex
                direction={'column'}
                bg={'gray.250'}
                p={'16px'}
                rounded={'15px'}
                borderBottom={'2px solid #ccc'}
                gap={'16px'}
            >
                <Flex gap={'30px'}>
                    <Text textStyle={'semiTitle'} flex={'0 0 120px'} py={'8px'}>
                        時間 / 診間
                    </Text>
                    <Flex flexWrap={'wrap'} columnGap={'50px'} rowGap={'8px'}>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                日期
                            </Text>

                            <Input
                                bg={'#fff'}
                                defaultValue={todayDate}
                                readOnly
                            ></Input>
                        </Flex>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                時段
                            </Text>

                            <Select
                                bg={'#fff'}
                                onChange={handleChangeInput}
                                name="shift_uid"
                                value={currentVisitInfoData.shift_uid}
                            >
                                {shiftLists &&
                                    shiftLists.map((item, index) => (
                                        <option
                                            key={index}
                                            value={item.data_id}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                            </Select>
                        </Flex>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                診間醫師
                            </Text>

                            <Select
                                bg={'#fff'}
                                onChange={handleChangeInput}
                                value={
                                    currentVisitInfoData.clinic_room_and_doctor
                                        ? currentVisitInfoData.clinic_room_and_doctor
                                        : ''
                                }
                                name="clinic_room_and_doctor"
                            >
                                <option value="請選擇" disabled>
                                    請選擇
                                </option>
                                {scheduleDoctorRoomLists &&
                                    scheduleDoctorRoomLists.map(
                                        (item, index) => (
                                            <option
                                                key={index}
                                                value={item.data_id}
                                            >
                                                {item.name}
                                            </option>
                                        )
                                    )}
                            </Select>
                        </Flex>
                    </Flex>
                </Flex>

                <hr />

                <Flex gap={'30px'}>
                    <Text textStyle={'semiTitle'} flex={'0 0 120px'} py={'8px'}>
                        身份
                    </Text>
                    <Flex flexWrap={'wrap'} columnGap={'50px'} rowGap={'8px'}>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                當次身份
                            </Text>

                            <Select
                                bg={'#fff'}
                                value={currentVisitInfoData.nhi_type}
                                onChange={handleChangeInput}
                                name="nhi_type"
                            >
                                {regFeeIdenLists &&
                                    regFeeIdenLists.map((item, index) => (
                                        <option key={index} value={item.code}>
                                            {item.name}
                                        </option>
                                    ))}
                            </Select>
                        </Flex>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                當次優免身份
                            </Text>

                            <Select
                                bg={'#fff'}
                                onChange={handleChangeInput}
                                value={currentVisitInfoData.member_uid}
                                name="member_uid"
                            >
                                <option value="">請選擇</option>
                                {membershipLists &&
                                    membershipLists.map((item, index) => (
                                        <option
                                            key={index}
                                            value={item.data_id}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                            </Select>
                        </Flex>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                其他身份
                            </Text>

                            <Select
                                bg={'#fff'}
                                // onChange={handleChangeInput}
                                // name=""
                                // value={currentVisitInfoData.}
                            >
                                <option value="">無</option>
                                <option value="">重大傷病</option>
                                <option value="">身心障礙</option>
                                <option value="">原住民</option>
                                <option value="">災民</option>
                                <option value="">新生兒</option>
                                <option value="">早產</option>
                                <option value="">替代役</option>
                                <option value="">多氯聯苯</option>
                            </Select>
                        </Flex>
                    </Flex>
                </Flex>

                <hr />

                <Flex gap={'30px'}>
                    <Text textStyle={'semiTitle'} flex={'0 0 120px'} py={'8px'}>
                        看診資訊
                    </Text>
                    <Flex flexWrap={'wrap'} columnGap={'50px'} rowGap={'8px'}>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                就醫類別
                            </Text>

                            <Select
                                bg={'#fff'}
                                onChange={handleChangeInput}
                                name="mt_type"
                                value={currentVisitInfoData.mt_type}
                            >
                                <option value="請選擇">請選擇</option>
                                {medicalTypeLists &&
                                    medicalTypeLists.map((item, index) => (
                                        <option value={item.code} key={index}>
                                            {item.code} {item.name}
                                        </option>
                                    ))}
                            </Select>
                        </Flex>
                        <Flex flex={'0 1 240px'} gap={'16px'} align={'center'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                卡號
                            </Text>

                            <Input
                                bg={'#fff'}
                                // value={
                                //     currentVisitInfoData.exception_card_number
                                // }
                                maxLength={4}
                                value={currentVisitInfoData.card_no}
                                onChange={handleChangeInput}
                                name="card_no"
                            ></Input>

                            {currentVisitInfoData.card_no === '' ? (
                                <>
                                    <Button
                                        ref={openExceptionListBtnRef}
                                        size={'sm'}
                                        colorScheme="rsDanger"
                                        onClick={onOpen}
                                    >
                                        異
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        ref={openExceptionListBtnRef}
                                        size={'sm'}
                                        colorScheme="rsSteel"
                                        onClick={() => {
                                            updateCurrentVisitInfoData({
                                                ...currentVisitInfoData,
                                                card_no: '',
                                            });
                                        }}
                                    >
                                        X
                                    </Button>
                                </>
                            )}
                        </Flex>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                看診序號
                            </Text>

                            {/* <Select bg={'#fff'}
                                onChange={handleChangeInput}
                                name="treatmentSeq"
                                value={currentVisitInfoData.treatmentSeq}
                            >
                                
                                <option value="請選擇">請選擇</option>
                                {reservationTypeLists && reservationTypeLists.map((item, index)=>(
                                    <option key={index} value={item.code}>{item.name}</option>
                                ))}
                            </Select> */}
                            <Input
                                onChange={handleChangeInput}
                                // name="treatmentSeq"
                                // value={currentVisitInfoData.treatmentSeq}
                                maxLength={3}
                            ></Input>
                        </Flex>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                告知診間
                            </Text>

                            <Input
                                bg={'#fff'}
                                onChange={handleChangeInput}
                                value={currentVisitInfoData.note}
                                name="note"
                            ></Input>
                        </Flex>
                    </Flex>
                </Flex>

                <hr />

                <Flex gap={'30px'}>
                    <Text textStyle={'semiTitle'} flex={'0 0 120px'} py={'8px'}>
                        費用
                    </Text>
                    <Flex flexWrap={'wrap'} columnGap={'50px'} rowGap={'8px'}>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                掛號費
                            </Text>
                            <Input
                                bg={'rsWarning.700'}
                                onChange={handleChangeInput}
                                value={
                                    currentVisitInfoData.cashier
                                        ? Math.floor(
                                              currentVisitInfoData.cashier.find(
                                                  item =>
                                                      item.acc_name === '掛號費'
                                              )?.final ?? 0
                                          )
                                        : 0
                                }
                                name="cashier[0].price"
                                maxLength={3}
                            ></Input>
                        </Flex>

                        <Flex flex={'0 0 240px'} gap={'16px'} align={'center'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                自付額
                            </Text>

                            <Input
                                bg={'rsWarning.700'}
                                onChange={handleChangeInput}
                                value={
                                    currentVisitInfoData.cashier
                                        ? Math.floor(
                                              currentVisitInfoData.cashier.find(
                                                  item =>
                                                      item.acc_name === '自付額'
                                              )?.final ?? 0
                                          )
                                        : 0
                                }
                                name="cashier[1].price"
                                maxLength={3}
                            ></Input>
                        </Flex>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                押單費
                            </Text>

                            <Input
                                bg={'rsWarning.700'}
                                onChange={handleChangeInput}
                                value={
                                    currentVisitInfoData.cashier
                                        ? Math.floor(
                                              currentVisitInfoData.cashier.find(
                                                  item =>
                                                      item.acc_name === '押單費'
                                              )?.final ?? 0
                                          )
                                        : 0
                                }
                                // value={currentVisitInfoData.nhi_type === 'dp' ? 350 : 0}

                                name="cashier[2].price"
                                maxLength={3}
                            ></Input>
                        </Flex>
                        <Flex flex={'0 1 240px'} gap={'16px'}>
                            <Text
                                textStyle={'semiTitle'}
                                flex={'0 0 auto'}
                                py={'8px'}
                            >
                                總金額
                            </Text>

                            <Input
                                bg={'rsDanger.700'}
                                readOnly
                                value={
                                    currentVisitInfoData.cashier?.reduce(
                                        (acc, item) => acc + Number(item.final),
                                        0
                                    ) ?? 0
                                }
                            ></Input>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={openExceptionListBtnRef}
                size={'md'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader textColor={'rsPrimary.500'}>
                        異常卡號
                    </DrawerHeader>
                    <DrawerBody>
                        <Flex
                            rowGap={'8px'}
                            flexWrap={'wrap'}
                            gap={'8px'}
                            direction={'column'}
                        >
                            {exceptionStateListAPI &&
                                exceptionStateListAPI.map((item, index) => (
                                    <Button
                                        p={0}
                                        border={0}
                                        outline={0}
                                        boxShadow={0}
                                        bg={'transparent'}
                                        h={'auto'}
                                        display={'flex'}
                                        key={index}
                                        data-code={item.code}
                                        name="exception_card_number"
                                        onClick={handleInsertException}
                                        _hover={{
                                            filter: 'hue-rotate(45deg)',
                                        }}
                                        overflow={'hidden'}
                                    >
                                        <Box
                                            px={'8px'}
                                            py={'12px'}
                                            bg={'rsPrimary.500'}
                                            textColor={'#fff'}
                                            flex={'0 0 80px'}
                                            borderRadius={'4px 0 0 4px'}
                                        >
                                            {item.code}
                                        </Box>
                                        <Box
                                            px={'16px'}
                                            py={'12px'}
                                            bg={'gray.250'}
                                            flex={'1 0 auto'}
                                            textAlign={'left'}
                                        >
                                            {item.desc}
                                        </Box>
                                    </Button>
                                ))}
                        </Flex>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button colorScheme="rsSteel" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        {/* <Button colorScheme="blue">Save</Button> */}
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
}

export default CurrentRegistered;
