// SummaryICCardUpdateData.ts
import moment from 'moment';
import { ICCardUpdateProps } from 'store/TreatmentManagement/ICCardUpdate/useICCardUpdateStore';
import { mb1Props, TreatmentInfoProps } from 'store/TreatmentManagement/useTreatmentInfoStore';
import { accItemsLevelProps } from 'service/accItemsLevelService';
import {
    CCPEProps,
    DxProps,
    PrescriptionProps,
} from 'store/TreatmentManagement';
import { DrugFreqListsProps } from 'store/DataSetting/useDrugFreqListStore';
import { cashDataStoreProps } from 'store/TreatmentManagement/Payment';
import { FeeState } from 'store/TreatmentManagement/Payment';
import { finalFeeDataLogic } from './finalFeeDataLogic';
import { CashierProps } from 'service/medicalRecordService';
import { SeqNumber256N1Props } from 'functions/hisGetSeqNumber256N1';
import { RegisterBasicProps } from 'functions/hisGetRegisterBasic';
import { drugTypeListProps } from 'service/drugTypeListService';
import { InstitutionInfoProps } from 'store/DataSetting/useInstitutionInfoStore';
import { currentVisitInfoDataProps } from 'store/Registration';
import { getDoseDay } from 'functions/getDoseDay';
import { ADDateToROCString } from 'functions/ADDateToROCString';
import { ADDateToROCStringNoDot } from 'functions/ADDateToROCStringNoDot';
import { toROCDateString } from 'functions/toROCDateString';
import { ADDateToROCStringyyyMMddHHmmssNoDot } from 'functions/ADDateToROCStringyyyMMddHHmmssNoDot';
import { ROCStringyyyMMddHHmmssToDateyyyyMMddHHmmss } from 'functions/ROCStringyyyMMddHHmmssToDateyyyyMMddHHmmss';
import {
    TreatNumNoICCardResProps,
    pBufferProps,
} from 'service/RsService/RshisGetTreatNumNoICCardService';
import { D02orderTypeLogic } from './ICDataUpdateLogic/D02orderTypeLogic';
import { D05Logic } from './ICDataUpdateLogic/D05Logic';
import { D14Logic } from './ICDataUpdateLogic/D14Logic';


export const SummaryNoICCardUpdateData = (
    treatmentInfo: TreatmentInfoProps,
    CCPEData: CCPEProps,
    dxList: DxProps[],
    prescriptionList: PrescriptionProps[],
    cashDataStore: cashDataStoreProps,
    accItemsLevelList: accItemsLevelProps[],
    feeData: FeeState,
    if_formal_data: string,
    caseUid: string,
    drugTypeLists: drugTypeListProps[],
    institutionInfoData: InstitutionInfoProps,
    drugFreqLists: DrugFreqListsProps[],
    hisGetTreatNumNoICCardResult?: pBufferProps,
    mb1?: mb1Props
) => {
 
    const filteredDxList = dxList.filter(item => item.icd_code);
    const filteredPrescriptionList = prescriptionList
        ? prescriptionList.filter(item => item.drug_no)
        : [];
    const doseDay = getDoseDay(
        prescriptionList || [],
        drugTypeLists,
        drugFreqLists
    );


    let finalData: ICCardUpdateProps = {
        // data_id:
        ic_content: {
            drugs: filteredPrescriptionList.map((item, index) => {
                const matchedDrugTypeCode =
                    drugTypeLists.find(
                        subItem => subItem.data_id === item.drug_type
                    )?.code || '';

                const D02 = D02orderTypeLogic(
                    item,
                    matchedDrugTypeCode,
                    institutionInfoData,
                );

                const D05 = D02 ? D05Logic(item, D02) : '';
                const D14 = D14Logic(item, matchedDrugTypeCode);
                return {
                    treated_at:
                        hisGetTreatNumNoICCardResult?.computerDateTime ??
                        treatmentInfo.medical_record?.mb1.treated_at ??
                        ROCStringyyyMMddHHmmssToDateyyyyMMddHHmmss(treatmentInfo.medical_record?.mb1.treated_at ?? ''),
                    drug_category: D02,
                    seq: (index + 1).toString(),
                    prescript_category: 'A', // 等下問
                    prescription_style: D05,
                    drug_no: item.drug_no,
                    drug_freq: item.freq_custom_code ?? '',
                    dose_days: Number(item.dose_day),
                    total_qty: Number(item.total_qty).toFixed(1).toString(),
                    signature: '',
                    drug_path: D14,
                    note: item.memo ?? '',

                    drug_type: item.drug_type,
                    data_id: item.data_id,
                    qty: Number(item.qty),
                    qty_in_day: Number(item.qty_in_day),
                    freq: item.freq,
                    given_qty: Number(item.given_qty),
                };
            }),
            diagnosis: filteredDxList.map(item => ({
                icd_code: item.icd_code,
            })),
            sam_code: treatmentInfo.medical_record?.mb1.sam_code ?? '', // 跟敬輝確認過了,就是這個
            card_number: treatmentInfo.medical_record?.mb1.card_number ?? '',
            patient_id_no: treatmentInfo.id_no,
            patient_birth_date: treatmentInfo.birth_date
                ? ADDateToROCStringNoDot(new Date(treatmentInfo.birth_date))
                : '',
            nhid: institutionInfoData.nhid,
            doctor_id_no: treatmentInfo.doctor_id_no,
            mt_type: treatmentInfo.selected_mt_type
                ? treatmentInfo.selected_mt_type
                : treatmentInfo.mt_type, // 診療室批價時更改的就醫類別
            treated_at:
                hisGetTreatNumNoICCardResult?.computerDateTime ??
                treatmentInfo.medical_record?.mb1.treated_at ??
                ROCStringyyyMMddHHmmssToDateyyyyMMddHHmmss(treatmentInfo.medical_record?.mb1.treated_at ?? ''),
            xml_m12: '1', //目前沒有補卡
            card_no: treatmentInfo.medical_record?.mb1.card_no ?? treatmentInfo.card_no,
            signature: treatmentInfo.medical_record?.mb1.signature ?? '', // 無卡給空值
            treatment_no:
                hisGetTreatNumNoICCardResult?.medicalTreatmentIdentificationCode ??
                treatmentInfo.medical_record?.mb1.treatment_no ?? '',
            dose_days:
                treatmentInfo.prescription_style === 1 ? 0 : Number(doseDay), // 沒有先當交付給0
            // 有值 如果是 0醫師字條,2院內藥師 院內調劑 1交付調劑    這個欄位0自調 1交付
            prescription_style:
                treatmentInfo.prescription_style !== -1
                    ? treatmentInfo.prescription_style === 1
                        ? 1
                        : 0
                    : 1,
            refill_a: '1',
            fee: (feeData.mAllFee - cashDataStore.deductibleFee).toString(),
            copayment: cashDataStore.deductibleFee
                ? cashDataStore.deductibleFee.toString()
                : '0',
            benefit_type: treatmentInfo.benefit_type || '4',
            copayment_2: '0',
            copayment_3: feeData.mFeeHD ? feeData.mFeeHD.toString() : '0',
            copayment_4: feeData.mFeeEQ ? feeData.mFeeEQ.toString() : '0',
            institution_type: treatmentInfo.medical_record?.mb1.institution_type ?? 
                (institutionInfoData.institution_type?.toString() ?? ''),
        },
        ori_medical_record_uid: treatmentInfo.data_id,

        card_no: treatmentInfo.medical_record?.mb1.card_no ?? treatmentInfo.card_no,
        treated_at:
        hisGetTreatNumNoICCardResult?.computerDateTime ??
        treatmentInfo.medical_record?.mb1.treated_at ??
        ROCStringyyyMMddHHmmssToDateyyyyMMddHHmmss(treatmentInfo.medical_record?.mb1.treated_at ?? ''),
        mt_type: treatmentInfo.selected_mt_type
            ? treatmentInfo.selected_mt_type
            : treatmentInfo.mt_type, // 診療室批價時更改的就醫類別
        patient_id_no: treatmentInfo.id_no,
        patient_birth_date: treatmentInfo.birth_date
            ? ADDateToROCStringNoDot(new Date(treatmentInfo.birth_date))
            : '',
        patient_uid: treatmentInfo.patient_uid,
        patient_name: treatmentInfo.patient_name,
        medical_record_uid: caseUid,
        // medical_record_number: treatmentInfo.medical_record_number,

        drugs: filteredPrescriptionList.map((item, index) => {
            const matchedDrugTypeCode =
                drugTypeLists.find(
                    subItem => subItem.data_id === item.drug_type
                )?.code || '';

            console.log(matchedDrugTypeCode, 'kkkkkkk');
            const D02 = D02orderTypeLogic(
                item,
                matchedDrugTypeCode,
                institutionInfoData
            );

            const D05 = D02 ? D05Logic(item, D02) : '';
            const D14 = D14Logic(item, matchedDrugTypeCode);
            return {
                treated_at:
                hisGetTreatNumNoICCardResult?.computerDateTime ??
                treatmentInfo.medical_record?.mb1.treated_at ??
                ROCStringyyyMMddHHmmssToDateyyyyMMddHHmmss(treatmentInfo.medical_record?.mb1.treated_at ?? ''),
                drug_category: D02, // Provide a default value
                seq: (index + 1).toString(),
                prescript_category: 'A',
                prescription_style: D05,
                drug_no: item.drug_no,
                drug_freq: item.freq_custom_code ?? '',
                dose_days: Number(item.dose_day),
                total_qty: Number(item.total_qty).toFixed(1).toString(),
                signature: '',
                drug_path: D14,
                note: item.memo ?? '',

                drug_type: item.drug_type,
                data_id: item.data_id,
                qty: Number(item.qty),
                qty_in_day: Number(item.qty_in_day),
                freq: item.freq,
                given_qty: Number(item.given_qty),
            };
        }),
    };

    return finalData;
};
