import { Center } from '@chakra-ui/react';

const NavICuploadIcon = ({ onClick }: any) => {
    return (
        <Center>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
            >
                <path
                    d="M8.07422 17C6.82718 17.0008 5.62484 16.5356 4.7031 15.6957C3.78137 14.8557 3.20678 13.7017 3.09207 12.4599C2.97736 11.2182 3.33082 9.97837 4.08309 8.98379C4.83535 7.9892 5.93213 7.31163 7.15822 7.084C7.37358 5.93767 7.98277 4.90264 8.88045 4.1579C9.77812 3.41317 10.9078 3.00555 12.0742 3.00555C13.2406 3.00555 14.3703 3.41317 15.268 4.1579C16.1657 4.90264 16.7749 5.93767 16.9902 7.084C18.2122 7.31625 19.3038 7.99547 20.0521 8.98912C20.8003 9.98277 21.1515 11.2196 21.037 12.4581C20.9226 13.6967 20.3508 14.8483 19.4332 15.688C18.5156 16.5278 17.3181 16.9955 16.0742 17M9.07422 12L12.0742 9M12.0742 9L15.0742 12M12.0742 9V21"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Center>
    );
};

export default NavICuploadIcon;
