/* Hooks */
import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Button,
    Input,
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Flex,
    Text,
    Grid,
    Center,
    Heading,
    Tooltip,
} from '@chakra-ui/react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';
import EditIcon from '../../CustomComponents/EditIcon';
import CloseIcon from '../../CustomComponents/CloseIcon';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DeleteIcon from 'components/CustomComponents/DeleteIcon';
import SaveIcon from 'components/CustomComponents/SaveIcon';
/* Components */
/* Service */
import { callClinicRoomService } from 'service/clinicRoomService';
// import { postClinicRoomService } from 'service/postClinicRoomService';
import { postClinicRoomService } from 'service/clinicRoomService';
import { deleteClinicRoomService } from 'service/clinicRoomService';
// import { postClinicRoomSortService } from 'service/postClinicRoomSortService';
import { postClinicRoomSortService } from 'service/clinicRoomSortService';

import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';

interface clinicRoomListsProps {
    id: string;
    name: string;
    isDisabled: boolean;
    isEditing: boolean;
}
const MedicalInstitutionBasicSetting: React.FC = () => {
    /* declare */
    const token = localStorage.getItem('token');
    /* auto height */
    const [
        medicalInstitutionBasicSettingHeight,
        setMedicalInstitutionBasicSettingHeight,
    ] = useState<number>(0);
    const [tableHeight, setTableHeight] = useState<number>(0);
    const windowHeight = window.innerHeight;

    const [newName, setNewName] = useState<string>('');
    const [seq, setSeq] = useState<string>('');
    const [clinicRoomLists, setClinicRoomLists] = useState<
        clinicRoomListsProps[]
    >([]);
    const [checkDataId, setCheckDataId] = useState<string>('');
    const [checkName, setCheckName] = useState<string>('');

    // 高度
    const outerDivRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        function updateHeight() {
            if (outerDivRef.current) {
                const height = outerDivRef.current.clientHeight;
                setMedicalInstitutionBasicSettingHeight(height);
            }
        }
        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [setMedicalInstitutionBasicSettingHeight]);
    useEffect(() => {
        const finalHeight =
            windowHeight - medicalInstitutionBasicSettingHeight - 215;
        setTableHeight(finalHeight);
    }, [windowHeight, medicalInstitutionBasicSettingHeight]);

    /* blur to add the dot for ROC date */

    const fetchData = async () => {
        if (token) {
            try {
                const result = await callClinicRoomService(token);
                if (result.result === 'success') {
                    const initialClinicRoomLists: clinicRoomListsProps[] =
                        result.lists.map((item: clinicRoomListsProps) => ({
                            ...item,
                            isEditing: true,
                        }));
                    setClinicRoomLists(initialClinicRoomLists);
                    setSeq(result.lists.length);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        } else {
            const newData = [...clinicRoomLists];

            const [movedItem] = newData.splice(result.source.index, 1);
            newData.splice(result.destination.index, 0, movedItem);

            setClinicRoomLists(newData);
            if (newData) {
                const postData = async () => {
                    const data = {
                        ids: newData.map(item => item.id),
                    };
                    if (token) {
                        try {
                            const fetchedClinicRoomSortLists =
                                await postClinicRoomSortService(token, data);
                            if (
                                fetchedClinicRoomSortLists.result === 'success'
                            ) {
                                Swal.fire({
                                    icon: 'success',
                                    title: '更新成功',
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                                fetchData();
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: '更新失敗',
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            }
                        } catch {
                            Swal.fire({
                                icon: 'error',
                                title: '後端服務錯誤',
                                timer: 1500,
                                showConfirmButton: false,
                            });
                        }
                    }
                };
                postData();
            }
            // fetchData()
        }
    };

    const handleChangeNewName = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setNewName(event.target.value);
    };

    const handleChangeName = (
        event: React.ChangeEvent<HTMLInputElement>,
        itemId: string | null
    ) => {
        const updatedClinicRoomLists = clinicRoomLists.map(item => {
            if (item.id === itemId) {
                return { ...item, name: event.target.value };
            }
            return item;
        });

        setClinicRoomLists(updatedClinicRoomLists);
    };
    const handleNewClinicRoom = () => {
        if (newName) {
            const postData = async () => {
                const token = localStorage.getItem('token');

                if (token) {
                    let data = {
                        name: newName,
                        seq: seq || '0',
                    };
                    try {
                        const fetchedClinicRoomLists =
                            await postClinicRoomService(token, data);

                        if (fetchedClinicRoomLists.result === 'success') {
                            setNewName('');
                            fetchData();
                            Swal.fire({
                                icon: 'success',
                                title: '新增成功',
                                showConfirmButton: false,
                                timer: 1500,
                            });
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: ' 新增失敗',
                                showConfirmButton: false,
                                timer: 1500,
                            });
                        }
                    } catch {
                        Swal.fire({
                            icon: 'error',
                            title: '後端服務錯誤',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                    }
                }
            };
            postData();
        } else {
            Swal.fire({
                icon: 'warning',
                title: '新增失敗，請輸入診間名稱',
                showConfirmButton: true,
                confirmButtonText: '好',
            });
            return;
        }
    };

    const handleEditClinicRoom = (itemId: string | null, name: string) => {
        if (itemId) {
            setCheckDataId(itemId);
            setCheckName(name);
        }
        setClinicRoomLists(prevClinicRoomLists => {
            return prevClinicRoomLists.map(item => {
                if (item.id === itemId) {
                    return {
                        ...item,
                        isEditing: false,
                        isDisabled: false,
                    };
                }
                return {
                    ...item,
                    isEditing: true,
                    isDisabled: true,
                };
            });
        });
    };

    const handleCancel = (itemId: string, name: string) => {
        setClinicRoomLists(prevClinicRoomLists => {
            return prevClinicRoomLists.map(item => {
                if (item.id === itemId) {
                    return {
                        ...item,
                        name: checkName,
                        isEditing: true,
                        isDisabled: false,
                    };
                }
                return {
                    ...item,
                    isEditing: true,
                    isDisabled: false,
                };
            });
        });
    };

    const handleSaveClinicRoom = (itemId: string | null, name: string) => {
        if (checkDataId === itemId && checkName === name) {
            setClinicRoomLists(prevClinicRoomLists => {
                return prevClinicRoomLists.map(item => {
                    if (item.id === itemId) {
                        return {
                            ...item,
                            isEditing: true,
                            isDisabled: false,
                        };
                    }
                    return {
                        ...item,
                        isEditing: true,
                        isDisabled: false,
                    };
                });
            });
            return;
        } else {
            const index = clinicRoomLists.findIndex(item => item.id === itemId);

            let data = {
                data_id: itemId,
                name: name,
                seq: (index + 1).toString(),
            };
            if (data) {
                const postData = async () => {
                    if (token) {
                        try {
                            const fetchedClinicRoomLists =
                                await postClinicRoomService(token, data);

                            if (fetchedClinicRoomLists.result === 'success') {
                                fetchData();
                                Swal.fire({
                                    icon: 'success',
                                    title: ' 修改成功',
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: ' 修改失敗',
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            }
                        } catch {
                            Swal.fire({
                                icon: 'error',
                                title: '後端服務錯誤',
                                timer: 1500,
                                showConfirmButton: false,
                            });
                        }
                    }
                };
                postData();
            }
        }
        setClinicRoomLists(prevClinicRoomLists => {
            return prevClinicRoomLists.map(item => {
                if (item.id === itemId) {
                    return {
                        ...item,
                        isEditing: true,
                        isDisabled: false,
                    };
                }
                return {
                    ...item,
                    isEditing: true,
                    isDisabled: false,
                };
            });
        });
    };
    const handleDeleteClinicRoom = (itemId: string) => {
        const token = localStorage.getItem('token');

        if (itemId && token) {
            Swal.fire({
                title: `是否要刪除此診間？`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: '是',
                cancelButtonText: '否',
            }).then(result => {
                if (result.isConfirmed) {
                    const deleteData = async () => {
                        try {
                            const fetchedClinicRoomLists =
                                await deleteClinicRoomService(token, itemId);

                            if (fetchedClinicRoomLists.result === 'success') {
                                Swal.fire({
                                    icon: 'success',
                                    title: '刪除成功',
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                                fetchData();
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: '刪除失败',
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            }
                        } catch {
                            Swal.fire({
                                icon: 'error',
                                title: '後端服務錯誤',
                                timer: 1500,
                                showConfirmButton: false,
                            });
                        }
                    };
                    deleteData();
                } else {
                    return;
                }
            });
        }
    };

    const [isCNtyping, setIsCNtyping] = useState<boolean>(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key;
        if (key === 'Enter') {
            handleNewClinicRoom();
        }
    };

    return (
        <>
            <Flex direction={'column'} gap={'16px'}>
                <Flex
                    wrap={'wrap'}
                    gap={'16px'}
                    w={'fit-content'}
                    rounded={'4px'}
                    bg={'#E9E5DA'}
                    p={'8px 16px'}
                    align={'center'}
                    justify={'space-between'}
                >
                    <Flex align={'center'} gap={'16px'}>
                        <Heading
                            textColor={'rsPrimary.500'}
                            flex={'0 0 auto'}
                            size={'sm'}
                        >
                            新增診間
                        </Heading>
                        <Input
                            value={newName}
                            onChange={handleChangeNewName}
                            maxLength={10}
                            bgColor={'#fff'}
                            placeholder="請輸入診間名稱"
                            onKeyDown={isCNtyping ? undefined : handleKeyDown}
                            onCompositionStart={() => {
                                setIsCNtyping(true);
                            }}
                            onCompositionEnd={() => {
                                setIsCNtyping(false);
                            }}
                        />
                    </Flex>

                    <Button
                        colorScheme="rsPrimary"
                        onClick={handleNewClinicRoom}
                    >
                        新增
                    </Button>
                </Flex>

                <DragDropContext onDragEnd={handleDragEnd}>
                    <Table variant="simple">
                        <Thead
                            borderBottom={'2px solid #9B9B7A'}
                            textColor={'#9B9B7A'}
                            fontWeight={'bold'}
                        >
                            <Tr>
                                <Td w={'80px'}>
                                    <Box textAlign={'center'}>排序</Box>
                                </Td>
                                <Td>
                                    <Box>名稱</Box>
                                </Td>

                                <Td w={'178px'}>
                                    <Box textAlign={'right'}>操作區</Box>
                                </Td>
                            </Tr>
                        </Thead>
                        <Droppable droppableId="root">
                            {provided => (
                                <Tbody
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {clinicRoomLists&&clinicRoomLists.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                        >
                                            {provided => (
                                                <Tr
                                                    key={item.id}
                                                    borderBottom={
                                                        '1px solid #9B9B7A'
                                                    }
                                                    _last={{
                                                        borderBottom:
                                                            '0px solid #9B9B7A',
                                                    }}
                                                    {...provided.draggableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {item.isEditing ? (
                                                        <>
                                                            <Td
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <Tooltip
                                                                    label="拖曳順序"
                                                                    hasArrow
                                                                    bg={
                                                                        'rsPrimary.500'
                                                                    }
                                                                    openDelay={
                                                                        1000
                                                                    }
                                                                >
                                                                    <Center
                                                                        textColor={
                                                                            'rsPrimary.500'
                                                                        }
                                                                        _hover={{
                                                                            cursor: 'pointer',
                                                                            opacity:
                                                                                '1',
                                                                        }}
                                                                        opacity={
                                                                            item.isDisabled
                                                                                ? '0.5'
                                                                                : '0.8'
                                                                        }
                                                                        pointerEvents={
                                                                            item.isDisabled
                                                                                ? 'none'
                                                                                : 'auto'
                                                                        }
                                                                        fontSize={
                                                                            '20px'
                                                                        }
                                                                    >
                                                                        &#x283F;
                                                                    </Center>
                                                                </Tooltip>
                                                            </Td>
                                                            <Td>
                                                                <>
                                                                    <Box
                                                                        h={
                                                                            '40px'
                                                                        }
                                                                        p={
                                                                            '10px 16px'
                                                                        }
                                                                    >
                                                                        {item.name ||
                                                                            ''}
                                                                    </Box>
                                                                </>
                                                            </Td>

                                                            <Td>
                                                                <Flex
                                                                    gap={'12px'}
                                                                    justify={
                                                                        'flex-end'
                                                                    }
                                                                >
                                                                    <Tooltip
                                                                        label="編輯"
                                                                        hasArrow
                                                                        bg={
                                                                            'rsPrimary.500'
                                                                        }
                                                                        openDelay={
                                                                            1000
                                                                        }
                                                                    >
                                                                        <Center
                                                                            onClick={() =>
                                                                                handleEditClinicRoom(
                                                                                    item.id,

                                                                                    item.name
                                                                                )
                                                                            }
                                                                            _hover={{
                                                                                cursor: 'pointer',
                                                                                opacity:
                                                                                    '1',
                                                                            }}
                                                                            opacity={
                                                                                item.isDisabled
                                                                                    ? '0.5'
                                                                                    : '0.8'
                                                                            }
                                                                            pointerEvents={
                                                                                item.isDisabled
                                                                                    ? 'none'
                                                                                    : 'auto'
                                                                            }
                                                                            textColor={
                                                                                'rsPrimary.500'
                                                                            }
                                                                        >
                                                                            <HeroIcons name="edit-fill" />
                                                                        </Center>
                                                                    </Tooltip>

                                                                    <Tooltip
                                                                        label="刪除"
                                                                        hasArrow
                                                                        bg={
                                                                            'rsDanger.500'
                                                                        }
                                                                        openDelay={
                                                                            1000
                                                                        }
                                                                    >
                                                                        <Center
                                                                            onClick={() => {
                                                                                if (
                                                                                    item.id
                                                                                ) {
                                                                                    handleDeleteClinicRoom(
                                                                                        item.id
                                                                                    );
                                                                                }
                                                                            }}
                                                                            textColor={
                                                                                'rsPrimary.500'
                                                                            }
                                                                            _hover={{
                                                                                cursor: 'pointer',
                                                                                opacity:
                                                                                    '1',
                                                                            }}
                                                                            opacity={
                                                                                item.isDisabled
                                                                                    ? '0.5'
                                                                                    : '0.8'
                                                                            }
                                                                            pointerEvents={
                                                                                item.isDisabled
                                                                                    ? 'none'
                                                                                    : 'auto'
                                                                            }
                                                                        >
                                                                            <HeroIcons name="delete-fill"></HeroIcons>
                                                                        </Center>
                                                                    </Tooltip>
                                                                </Flex>
                                                            </Td>
                                                        </>
                                                    ) : (
                                                        // 被選的id
                                                        <>
                                                            <Td
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <Tooltip
                                                                    label="拖曳順序"
                                                                    hasArrow
                                                                    bg={
                                                                        'rsPrimary.500'
                                                                    }
                                                                    openDelay={
                                                                        1000
                                                                    }
                                                                >
                                                                    <Center
                                                                        textColor={
                                                                            'rsPrimary.500'
                                                                        }
                                                                        _hover={{
                                                                            cursor: 'pointer',
                                                                            opacity: 1,
                                                                        }}
                                                                        opacity={
                                                                            item.isDisabled
                                                                                ? '0.5'
                                                                                : '0.8'
                                                                        }
                                                                        pointerEvents={
                                                                            item.isDisabled
                                                                                ? 'none'
                                                                                : 'auto'
                                                                        }
                                                                    >
                                                                        &#x283F;
                                                                    </Center>
                                                                </Tooltip>
                                                            </Td>
                                                            <Td>
                                                                <Input
                                                                    name={`name-${item.id}`}
                                                                    value={
                                                                        item.name ||
                                                                        ''
                                                                    }
                                                                    maxLength={
                                                                        10
                                                                    }
                                                                    onChange={event =>
                                                                        handleChangeName(
                                                                            event,
                                                                            item.id
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        item.isEditing
                                                                    }
                                                                    bg={'#fff'}
                                                                    onKeyDown={
                                                                        isCNtyping
                                                                            ? undefined
                                                                            : event => {
                                                                                  if (
                                                                                      event.key ===
                                                                                      'Enter'
                                                                                  ) {
                                                                                      handleSaveClinicRoom(
                                                                                          item.id,
                                                                                          item.name
                                                                                      );
                                                                                  }
                                                                              }
                                                                    }
                                                                    onCompositionStart={() => {
                                                                        setIsCNtyping(
                                                                            true
                                                                        );
                                                                    }}
                                                                    onCompositionEnd={() => {
                                                                        setIsCNtyping(
                                                                            false
                                                                        );
                                                                    }}
                                                                />
                                                            </Td>

                                                            <Td>
                                                                <Flex
                                                                    gap={'12px'}
                                                                    justify={
                                                                        'flex-end'
                                                                    }
                                                                >
                                                                    {item.isEditing ? (
                                                                        <Tooltip
                                                                            label="編輯"
                                                                            hasArrow
                                                                            bg={
                                                                                'rsPrimary.500'
                                                                            }
                                                                            openDelay={
                                                                                1000
                                                                            }
                                                                        >
                                                                            <Center
                                                                                onClick={() =>
                                                                                    handleEditClinicRoom(
                                                                                        item.id,
                                                                                        item.name
                                                                                    )
                                                                                }
                                                                                _hover={{
                                                                                    cursor: 'pointer',
                                                                                    opacity:
                                                                                        '1',
                                                                                }}
                                                                                opacity={
                                                                                    item.isDisabled
                                                                                        ? '0.5'
                                                                                        : '0.8'
                                                                                }
                                                                                pointerEvents={
                                                                                    item.isDisabled
                                                                                        ? 'none'
                                                                                        : 'auto'
                                                                                }
                                                                            >
                                                                                <HeroIcons name="edit" />
                                                                            </Center>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <Flex gap="12px">
                                                                            <Tooltip
                                                                                label="取消"
                                                                                hasArrow
                                                                                bg={
                                                                                    'rsPrimary.500'
                                                                                }
                                                                                openDelay={
                                                                                    1000
                                                                                }
                                                                            >
                                                                                <Center
                                                                                    onClick={() =>
                                                                                        handleCancel(
                                                                                            item.id,
                                                                                            item.name
                                                                                        )
                                                                                    }
                                                                                    _hover={{
                                                                                        cursor: 'pointer',
                                                                                        opacity:
                                                                                            '1',
                                                                                    }}
                                                                                    textColor={
                                                                                        'rsPrimary.500'
                                                                                    }
                                                                                >
                                                                                    <HeroIcons name="return"></HeroIcons>
                                                                                </Center>
                                                                            </Tooltip>

                                                                            <Tooltip
                                                                                label="儲存"
                                                                                hasArrow
                                                                                bg={
                                                                                    'rsPrimary.500'
                                                                                }
                                                                                openDelay={
                                                                                    1000
                                                                                }
                                                                            >
                                                                                <Center
                                                                                    onClick={() =>
                                                                                        handleSaveClinicRoom(
                                                                                            item.id,
                                                                                            item.name
                                                                                        )
                                                                                    }
                                                                                    _hover={{
                                                                                        cursor: 'pointer',
                                                                                        opacity:
                                                                                            '1',
                                                                                    }}
                                                                                    opacity={
                                                                                        item.isDisabled
                                                                                            ? '0.5'
                                                                                            : '0.8'
                                                                                    }
                                                                                    pointerEvents={
                                                                                        item.isDisabled
                                                                                            ? 'none'
                                                                                            : 'auto'
                                                                                    }
                                                                                    textColor={
                                                                                        'rsPrimary.500'
                                                                                    }
                                                                                >
                                                                                    <HeroIcons name="check"></HeroIcons>
                                                                                </Center>
                                                                            </Tooltip>
                                                                        </Flex>
                                                                    )}
                                                                    <Tooltip
                                                                        label="刪除"
                                                                        hasArrow
                                                                        bg={
                                                                            'rsPrimary.500'
                                                                        }
                                                                        openDelay={
                                                                            1000
                                                                        }
                                                                    >
                                                                        <Center
                                                                            onClick={() => {
                                                                                if (
                                                                                    item.id
                                                                                ) {
                                                                                    handleDeleteClinicRoom(
                                                                                        item.id
                                                                                    );
                                                                                }
                                                                            }}
                                                                            textColor={
                                                                                'rsPrimary.500'
                                                                            }
                                                                            _hover={{
                                                                                cursor: 'pointer',
                                                                                opacity:
                                                                                    '1',
                                                                            }}
                                                                            opacity={
                                                                                item.isDisabled
                                                                                    ? '0.5'
                                                                                    : '0.8'
                                                                            }
                                                                            pointerEvents={
                                                                                item.isDisabled
                                                                                    ? 'none'
                                                                                    : 'auto'
                                                                            }
                                                                        >
                                                                            <HeroIcons name="delete-fill"></HeroIcons>
                                                                        </Center>
                                                                    </Tooltip>
                                                                </Flex>
                                                            </Td>
                                                        </>
                                                    )}
                                                </Tr>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Tbody>
                            )}
                        </Droppable>
                    </Table>
                </DragDropContext>
            </Flex>
        </>
    );
};

export default MedicalInstitutionBasicSetting;
