import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ShowPatientManagementFormStatusContextValue {
    showPatientManagementFormStatus: boolean;
    setShowPatientManagementFormStatus: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

const ShowPatientManagementFormStatusContext = createContext<
    ShowPatientManagementFormStatusContextValue | undefined
>(undefined); // Set the initial value as undefined

interface ShowPatientManagementFormStatusProviderProps {
    children: ReactNode;
}

export const ShowPatientManagementFormStatusProvider: React.FC<
    ShowPatientManagementFormStatusProviderProps
> = ({ children }) => {
    const [
        showPatientManagementFormStatus,
        setShowPatientManagementFormStatus,
    ] = useState(false);

    return (
        <ShowPatientManagementFormStatusContext.Provider
            value={{
                showPatientManagementFormStatus,
                setShowPatientManagementFormStatus,
            }}
        >
            {children} {/* Fix: Remove double curly braces around children */}
        </ShowPatientManagementFormStatusContext.Provider>
    );
};

export const useShowPatientManagementFormStatus =
    (): ShowPatientManagementFormStatusContextValue => {
        const context = useContext(ShowPatientManagementFormStatusContext); // Fix: Remove the typo 'con'

        if (!context) {
            throw new Error(
                'useShowPatientManagementFormStatus must be used within a ShowPatientManagementFormStatusProvider'
            );
        }
        return context;
    };
