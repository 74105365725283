import apiService from './apiService';


//取得登入
export const postLogoutService = async (token: string) => {
    console.log('進這個logout')
    return apiService<any, any>({
        method: 'POST',
        path: '/api/logout',
        data: {},
        token: token
    });
};