import {
    Box,
    Flex,
    Text,
    List,
    ListItem,
} from '@chakra-ui/react';
import Loading from 'components/CustomComponents/Loading';
import { useEffect, useState } from 'react';
import { useTreatmentInfoStore } from 'store/TreatmentManagement';
// import { postPatientInfoService } from 'service/patientInfoService';
import { useUserStore } from 'store';

export const MedicalHistory = () => {
    const [medicalHistoryList, setMedicalHistoryList] = useState<string[]>([]);
    const {userInfo} = useUserStore()
    const { treatmentInfo } = useTreatmentInfoStore();
    const [isLoading, setIsLoading] = useState(true)
    const postPatientInfoAPI = () => {
        // let finalData = {
        //     data_id: treatmentInfo.id,
        //     drug_allergy_describe: 
        // }
        // const result = postPatientInfoService(userInfo, )
    }
    useEffect(() => {
        setMedicalHistoryList([]); 
        if (typeof treatmentInfo.seek_history === 'string' && treatmentInfo.seek_history.length > 0) {
            const seekHistoryArray = treatmentInfo.seek_history.split(',');
            console.log(seekHistoryArray); 
            setMedicalHistoryList(seekHistoryArray);
        }
        setIsLoading(false)
        
    }, [treatmentInfo]); 
    return (
        <>
            {isLoading ? (
                <>
                    <Loading/>
                </>
            ): (
                <>
                    <Flex direction={'column'} gap={'4px'}>
                        <Flex justify={'space-between'}>
                            <Text textStyle={'semiTitle'}>
                                病史 <Box as="span">({medicalHistoryList && medicalHistoryList.length})</Box>
                            </Text>
                        </Flex>
                        <List display={'flex'} flexWrap={'wrap'} columnGap={'16px'}>
                            {medicalHistoryList && medicalHistoryList.map((item, index) => (
                                <ListItem key={index} display={'flex'} gap={'4px'}>
                                    <Box>{index + 1}.</Box>
                                    <Box>{item}</Box>
                                </ListItem>
                            ))}
                        </List>
                    </Flex>
                </>

            )}
        </>
    );
};
