import { create } from 'zustand';


interface HistoryPrescriptionState {
    historyPrescriptionList: HistoryPrescriptionProps[];
    selectedIndex: { row: number, col: number };
    isEditing: boolean;
    initialValue: string;
    inputValues: { columns: string[] }[];
    setSelectedIndex: (index: { row: number, col: number }) => void;
    appendHistoryPrescription: (historyPrescription: Partial<HistoryPrescriptionProps>, inputValues: string[]) => void;
    setIsEditing: (isEditing: boolean) => void;
    setInitialValue: (value: string) => void;
    addHistoryPrescription: () => void;
    updateHistoryPrescription: (index: number, historyPrescription: Partial<HistoryPrescriptionProps>, inputValues: string[]) => void;
    updateInputValues: (values: { columns: string[] }[]) => void;
    updateCellValue: (rowIndex: number, colIndex: number, value: string) => void;
    setHistoryPrescriptionList: (list: HistoryPrescriptionProps[]) => void;
    resetHistoryPrescriptionList: ()=>void;
}


export type HistoryPrescriptionProps = {
    controlled_level: string;
    cost: string;
    data_id: string;
    disabled_date: string;
    dosage_form: string;
    dose: string;
    dose_day: string;
    dosing_days: string;
    drug_no: string;
    drug_price: number | null;
    drug_type: string;
    freq: string;
    max_dose: string;
    max_dose_day: string;
    name_en: string;
    name_tw: string;
    route_of_administration: string;
    self_price: string;
    short_code: string;
    taking_time: string;
    unit: string;

    qty?: string;
    qty_in_day?: string;
    total_qty?: string;
    given_qty?: string;
    memo?: string;
    site?:string;
    special_mark?: string;
    freq_custom_code?: string;
    freq_directions?: string;
};

export const initialHistoryPrescription: HistoryPrescriptionProps = {
    controlled_level: "",
    cost: "",
    data_id: "",
    disabled_date: "",
    dosage_form: "",
    dose: "",
    dose_day: "",
    dosing_days: "",
    drug_no: "",
    drug_price: null,
    drug_type: "",
    freq: "",
    max_dose: "",
    max_dose_day: "",
    name_en: "",
    name_tw: "",
    route_of_administration: "",
    self_price: "",
    short_code: "",
    taking_time: "",
    unit: "",

    qty_in_day: "",
    total_qty: "",
    given_qty: "",
    qty: "",
    memo: "",
    site: "",
    special_mark: "",
    freq_custom_code: "",
    freq_directions: ""
  };
  

export const useHistoryPrescriptionStore = create<HistoryPrescriptionState>((set) => ({
    historyPrescriptionList: [initialHistoryPrescription],
    selectedIndex: { row: 0, col: 0 },
    isEditing: false,
    initialValue: '',
    inputValues: [{ columns: Array(10).fill('') }],
    setSelectedIndex: (index) => set({ selectedIndex: index }),
    setIsEditing: (isEditing) => set({ isEditing }),
    setInitialValue: (value) => set({ initialValue: value }),
    addHistoryPrescription: () =>
        set((state) => ({
            historyPrescriptionList: [
                ...state.historyPrescriptionList,
                { ...initialHistoryPrescription },
            ],
            inputValues: [
                ...state.inputValues,
                { columns: Array(10).fill('') },
            ],
        })),
    updateHistoryPrescription: (index, historyPrescription, inputVals) =>
        set((state) => {
            const newHistoryPrescriptionList = [...state.historyPrescriptionList];
            newHistoryPrescriptionList[index] = {
                ...newHistoryPrescriptionList[index],
                ...historyPrescription,
            };

            const newInputValues = [...state.inputValues];
            newInputValues[index] = { columns: inputVals };

            return { 
                historyPrescriptionList: newHistoryPrescriptionList,
                inputValues: newInputValues
            };
        }),
    updateInputValues: (values) => set({ inputValues: values }),
    updateCellValue: (rowIndex, colIndex, value) =>
        set((state) => {
            const newInputValues = [...state.inputValues];
            newInputValues[rowIndex].columns[colIndex] = value;
            return { inputValues: newInputValues };
        }),
    appendHistoryPrescription: (historyPrescription, inputVals) =>
        set((state) => ({
            historyPrescriptionList: [...state.historyPrescriptionList, { ...initialHistoryPrescription, ...historyPrescription }],
            inputValues: [...state.inputValues, { columns: inputVals }]
    })),
    setHistoryPrescriptionList: (list) => set({ historyPrescriptionList: list }),
    resetHistoryPrescriptionList: () => set({
        historyPrescriptionList: [initialHistoryPrescription],
        selectedIndex: { row: 0, col: 0 },
        isEditing: false,
        initialValue: '',
        inputValues: [{ columns: Array(10).fill('') }]
    })
}));
