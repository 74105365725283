import { Center } from '@chakra-ui/react';

const ConsultingRoomKeyIcon = ({ onClick }: any) => {
    return (
        <Center>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
            >
                <g filter="url(#filter0_i_70_6176)">
                    <circle cx="17" cy="17" r="17" fill="#45533F" />
                </g>
                <path
                    d="M20 11.9942C20.5304 11.9942 21.0391 12.2049 21.4142 12.58C21.7893 12.9551 22 13.4638 22 13.9942M26 13.9942C26.0003 14.9314 25.781 15.8556 25.3598 16.6928C24.9386 17.5299 24.3271 18.2568 23.5744 18.8151C22.8216 19.3734 21.9486 19.7476 21.0252 19.9077C20.1018 20.0679 19.1538 20.0095 18.257 19.7372L16 21.9942H14V23.9942H12V25.9942H9C8.73478 25.9942 8.48043 25.8889 8.29289 25.7013C8.10536 25.5138 8 25.2594 8 24.9942V22.4082C8.00006 22.143 8.10545 21.8887 8.293 21.7012L14.257 15.7372C14.0074 14.9122 13.9386 14.0432 14.055 13.1892C14.1715 12.3352 14.4706 11.5163 14.932 10.7883C15.3934 10.0602 16.0062 9.44016 16.7287 8.97024C17.4512 8.50031 18.2665 8.19157 19.1191 8.06502C19.9716 7.93848 20.8415 7.9971 21.6693 8.2369C22.4972 8.4767 23.2637 8.89205 23.9166 9.45468C24.5696 10.0173 25.0936 10.714 25.4531 11.4974C25.8127 12.2807 25.9992 13.1323 26 13.9942Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <defs>
                    <filter
                        id="filter0_i_70_6176"
                        x="0"
                        y="-1"
                        width="35"
                        height="35"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="1" dy="-1" />
                        <feGaussianBlur stdDeviation="1" />
                        <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                        />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect1_innerShadow_70_6176"
                        />
                    </filter>
                </defs>
            </svg>
        </Center>
    );
};

export default ConsultingRoomKeyIcon;
