import React from 'react';
import { Button, Flex } from '@chakra-ui/react';

export const ExportAndPrint = () => {
    const handleExport = () => {
        console.log('匯出');
    };
    const handlePrint = () => {
        console.log('列印');
    };
    return (
        <Flex gap={'8px'}>
            <Button colorScheme="rsPrimary" onClick={handleExport}>
                匯出
            </Button>
            <Button colorScheme="rsPrimary" onClick={handlePrint}>
                列印
            </Button>
        </Flex>
    );
};
