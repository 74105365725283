import apiService from './apiService';

export type icDataChangeStatusReqDataProps = {
    data_id: string;
    status: string; // formal：正式資料； void：作廢資料
};

export type icDataChangeStatusResDataProps = {
    result: string;
    msg: string;
};

// 26. 更新 IC 資料狀態
export const callIcDataChangeStatusService = async (
    token: string,
    data: icDataChangeStatusReqDataProps
): Promise<icDataChangeStatusResDataProps> => {
    return apiService<any, icDataChangeStatusResDataProps>({
        method: 'POST',
        path: '/api/ic_data/chang_status',
        token,
        data: data,
    });
};
