import React, { useState, useEffect } from 'react';
import { Flex, Box, Checkbox, Input } from '@chakra-ui/react';
import { useICCardUpdateRangeStore } from '../../../store/ICCardUpdate/useICCardUpdateRangeStore';
import Swal from 'sweetalert2';
export const TimePick = () => {
    const [showTimeRange, setShowTimeRange] = useState(false);
    const { icCardUpdateRangeData, updateICCardUpdateRangeData } =
        useICCardUpdateRangeStore();

    const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === 'start_time') {
            updateICCardUpdateRangeData({ start_time: value });
        } else if (name === 'end_time') {
            updateICCardUpdateRangeData({ end_time: value });
        }
    };

    useEffect(() => {
        if (!showTimeRange) {
            updateICCardUpdateRangeData({ start_time: '', end_time: '' });
        }
    }, [showTimeRange]);

    return (
        <Box gap={'16px'} w={'100%'} mb={'16px'}>
            <Box mb={'8px'}>
                <Checkbox
                    flex={'0 0 auto'}
                    isChecked={showTimeRange}
                    onChange={e => setShowTimeRange(e.target.checked)}
                >
                    只顯示時間區內的資料
                </Checkbox>
            </Box>
            <Flex gap={'4px'} align={'center'} pl={'24px'}>
                <Input
                    bg={'#fff'}
                    value={icCardUpdateRangeData.start_time}
                    name="start_time"
                    onChange={handleTimeChange}
                    placeholder="HH:MM:SS"
                    disabled={!showTimeRange}
                />
                <Box>-</Box>
                <Input
                    bg={'#fff'}
                    value={icCardUpdateRangeData.end_time}
                    name="end_time"
                    onChange={handleTimeChange}
                    placeholder="HH:MM:SS"
                    disabled={!showTimeRange}
                />
            </Flex>
        </Box>
    );
};
