import {
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    TableContainer,
    Select,
    Box,
    Flex,
    Grid,
    GridItem,
    SimpleGrid,
    Text,
    CheckboxGroup,
    Checkbox,
    Heading,
    Tooltip,
    filter,
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';
import Swal from 'sweetalert2';
import { Button, Image, Input } from '@chakra-ui/react';
import { parseISO } from 'date-fns';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';

// import { updatedDataProps } from 'service/postMedicalStaffSpecService';
import { updatedDataProps } from 'service/medicalStaffSpecService';
// Components

import DragIcon from '../../CustomComponents/DragIcon';
import DrawerDetailsIcon from 'components/CustomComponents/DrawerDetailsIcon';
import DrawerCloseIcon from 'components/CustomComponents/DrawerCloseIcon';
import DeleteIcon from 'components/CustomComponents/DeleteIcon';
import ROCDatePicker from 'components/CustomComponents/ROCDatePicker';
import { CirclePicker } from 'react-color';

import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import moment from 'moment';
import { callCityService } from 'service/cityService';

// import RealsunBgImg from '../../../images/RealsunBgImg.png';

// API
import { callGTypeIdentityService } from 'service/gTypeService';
import { callMedicalStaffListService } from 'service/medicalStaffListService';
import { callMedicalStaffSpecService } from 'service/medicalStaffSpecService';
import { callLicenseTypeSecondService } from 'service/gTypeService';
import { callLicenseTypeThirdService } from 'service/gTypeService';
import { callGroupAuthListService } from 'service/groupAuthListService';
import { callSystemFunctionTreeService } from 'service/systemFunctionTreeService';
import { postMedicalStaffSpecService } from 'service/medicalStaffSpecService';
import { postVerifySetUserService } from 'service/verifySetUserService';
import { deleteMedicalStaffLicenseService } from 'service/medicalStaffLicenseService';

import GroupViewIcon from '../../CustomComponents/GroupViewIcon';
import GroupEditIcon from 'components/CustomComponents/GroupEditIcon';
import GroupDenyIcon from 'components/CustomComponents/GroupDenyIcon';
import GroupMemberIcon from 'components/CustomComponents/GroupMemberIcon';
import AddIcon from 'components/CustomComponents/AddIcon';
import { useDropDownMenuQuery } from 'query/useDropDownMenuQuery';
import { useUserStore } from 'store';

// try
import ROCDatePickerNew from 'components/CustomComponents/ROCDatePicker';
interface ShowListsProps {
    data_id: string;
    name: string;
    identity: string;
}
interface LicenseTypeProps {
    name: string;
    code: string;
}
interface PersonnelProfileDataProps {
    data_id?: string;
    group_permission: string | null;
    personal_permissions: string | null;
    name: string | null;
    name_en: string | null;
    subject_code: string | null;
    id_no: string | null;
    birth_date: Date | null;
    // ROC_birth_date: string | null;
    cellphone: string | null;
    email: string | null;
    date_in: Date | null;
    date_out: Date | null;
    address: string | null;
    emergence_contactor: string | null;
    emergence_relationship: string | null;
    emergence_phone: string | null;
    identity_code: string | null;
    group_auth: string[];
    personal_auth: {
        [key: string]: string;
    };
    color: string | null;
    city_uid: string;
    area_uid: string;
    city_name: string;
    area_name: string;
    verify_at: string | null;
}
interface LicenseProps {
    data_id?: string;
    license_type: string;
    license_name: string;
    license_no: string;
    license_type_code?: string;
    start_date: Date | null;
    end_date: Date | null;
}

interface IdentityListsProps {
    name: string;
    code: string;
}

interface GroupListsProps {
    data_id?: string;
    name: string;
    func_auth: { [key: string]: string };
}

interface NavigationProps {
    name: string;
    path?: string;
    items?: NavigationProps[];
    key: string;
    status: string;
}
interface GroupAuthItem {
    data_id: string;
}

// interface updatedDataProps {
//     data_id: string;
//     info: {
//         name: string;
//         name_en: string;
//         personal_auth: {
//             [key: string]: string;
//         };
//     };
//     license: [
//         {
//             license_type: string;
//             license_no: string;
//             start_date: string;
//             end_date: string;
//         },
//     ];
//     group_auth: [string];
// }

// 科別 Props
type hospSubjectProps = {
    id: string;
    name: string;
};

const PersonnelProfileSettingTable = () => {
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
    // 高度
    const [preferentialStatusTableHeight, setPreferentialStatusTableHeight] =
        useState(0);
    const windowHeight = window.innerHeight;
    const [tableHeight, setTableHeight] = useState(0);
    // 現在指向的lists是哪個,-1代表沒選
    const [selectedShowListsIndex, setSelectedShowListsIndex] = useState(-1);
    const outerDivRef = useRef<HTMLDivElement | null>(null);
    const bgRef = useRef<HTMLDivElement>(null);
    //修改時需要紀錄data_id
    const [drawerFormDataId, setDrawerFormDataId] = useState('');
    const token = localStorage.getItem('token');
    const { userInfo } = useUserStore();
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [editStatus, setEditStatus] = useState(false);
    // const [requiredWarning, setRequiredWarning] = useState<boolean>(true);
    const [drawerOpenStatus, setDrawerOpenStatus] = useState(false);
    const [permissionDrawerStatus, setPermissionDrawerStatus] = useState(false);

    const [licenseTypeSecondLists, setLicenseTypeSecondLists] =
        useState<LicenseTypeProps[]>();
    const [licenseTypeThirdLists, setLicenseTypeThirdLists] =
        useState<LicenseTypeProps[]>();

    const [radioStatus, setRadioStatus] = useState<string | null>(null);

    const [navigation, setNavigation] = useState<NavigationProps[]>([]);
    const [newNavigation, setNewNavigation] = useState<NavigationProps[]>();

    const [showLists, setShowLists] = useState<ShowListsProps[]>([]);

    const [isValidEmail, setIsValidEmail] = useState<boolean>(true);

    const [emailSending, setEmailSending] = useState<boolean>();

    const [selectedBirthDate, setSelectedBirthDate] = useState<Date | null>(
        null
    );
    const [selectedDateIn, setSelectedDateIn] = useState<Date | null>(null);
    const [selectedDateOut, setSelectedDateOut] = useState<Date | null>(null);

    const initialPersonnelProfileData = {
        //  等下要把data_id清掉
        data_id: '',
        group_permission: '',
        personal_permissions: '',
        name: '',
        name_en: '',
        subject_code: '',
        id_no: '',
        birth_date: null,
        // ROC_birth_date: null,
        cellphone: '',
        group_auth: [],
        email: '',
        date_in: null,
        date_out: null,
        address: '',
        emergence_contactor: '',
        emergence_relationship: '',
        emergence_phone: '',
        personal_auth: {},
        identity_code: '',
        color: '#868C6E',
        city_uid: '',
        area_uid: '',
        area_name: '',
        city_name: '',
        verify_at: null,
    };

    // 可能很多執照
    const [license, setLicense] = useState<LicenseProps[]>([]);
    const [personnelProfileData, setPersonnelProfileData] =
        useState<PersonnelProfileDataProps>(initialPersonnelProfileData);
    const closeDrawer = () => {
        setDrawerOpenStatus(false);
        setDrawerKey(prevKey => prevKey + 1);
        setHasUnsavedChanges(false);
        setSelectedShowListsIndex(-1);
        setEditStatus(false);
        setPermissionDrawerStatus(false);
        setPersonnelProfileData(initialPersonnelProfileData);
        setSelectedLicenseTypeSecondName('');
        setSelectedLicenseTypeThirdName('');
        setLicenseTypeSecondLists([]);
        setLicenseTypeThirdLists([]);
        if (!newNavigation) return;
        const updatedNavigation = newNavigation.map(item => {
            return {
                ...item,
                status: '',

                items: item.items
                    ? item.items.map(subItem => ({ ...subItem, status: '' }))
                    : item.items,
            };
        });

        setNewNavigation(updatedNavigation);
    };

    const postSetUserToVerifyAPI = async () => {
        let user = { user: personnelProfileData.data_id };
        if (token && user) {
            try {
                const result = await postVerifySetUserService(token, user);
                if (result.result === 'success') {
                    setEmailSending(false);

                    Swal.fire({
                        title: '開通信件已寄出',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else if (result.msg === '該使用者已開通') {
                    Swal.fire({
                        title: '此使用者已開通成功',
                        icon: 'info',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setEmailSending(false);
                } else if (result.result === 'failure') {
                    Swal.fire({
                        title: '操作失敗',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setEmailSending(false);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setEmailSending(false);
            }
        }
    };
    useEffect(() => {
        function updateHeight() {
            if (outerDivRef.current) {
                const height = outerDivRef.current.clientHeight;
                setPreferentialStatusTableHeight(height);
            }
        }
        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [setPreferentialStatusTableHeight]);

    useEffect(() => {
        const finalHeight = windowHeight - preferentialStatusTableHeight - 140;
        setTableHeight(finalHeight);
    }, [windowHeight, preferentialStatusTableHeight]);

    const callMedicalStaffSpecAPI = async (data_id: string) => {
        if (token) {
            try {
                const result = await callMedicalStaffSpecService(
                    token,
                    data_id
                );
                if (result.result === 'success') {
                    if (result.info.birth_date) {
                        result.info.birth_date = new Date(
                            result.info.birth_date
                        );
                    }

                    // 處理 date_in
                    if (result.info.date_in) {
                        result.info.date_in = new Date(result.info.date_in);
                    }
                    // 處理 date_out
                    if (result.info.date_out) {
                        result.info.date_out = new Date(result.info.date_out);
                    }

                    setPersonnelProfileData(result.info);
                    const updatedLicenses = result.info.license.map(
                        (licenseItem: LicenseProps) => ({
                            ...licenseItem,
                            license_name: licenseItem.license_type,
                            license_type: licenseItem.license_type_code,
                        })
                    );

                    setLicense(updatedLicenses);
                    const newDataIds = result.info.group_auth.map(
                        (item: GroupAuthItem) => item.data_id
                    );
                    setSelectedDataIds(newDataIds);
                    if (result.info.personal_auth) {
                        try {
                            const personalAuth = JSON.parse(
                                result.info.personal_auth
                            );
                            const filteredPersonalAuth = Object.entries(
                                personalAuth
                            )
                                .filter(([key, value]) => value !== null)
                                .reduce(
                                    (
                                        acc: { [key: string]: string },
                                        [key, value]
                                    ) => {
                                        acc[key] = value as string;
                                        return acc;
                                    },
                                    {}
                                );
                            // 更新 customChanges
                            setCustomChanges(filteredPersonalAuth);

                            // 更新 newNavigation
                            setNewNavigation(prevNavigation => {
                                return prevNavigation?.map(navItem => {
                                    const updatedNavItem = { ...navItem };

                                    if (personalAuth[navItem.key]) {
                                        updatedNavItem.status =
                                            personalAuth[navItem.key];
                                    }

                                    if (navItem.items) {
                                        updatedNavItem.items =
                                            navItem.items.map(subItem => {
                                                return personalAuth[subItem.key]
                                                    ? {
                                                          ...subItem,
                                                          status: personalAuth[
                                                              subItem.key
                                                          ],
                                                      }
                                                    : subItem;
                                            });
                                    }

                                    return updatedNavItem;
                                });
                            });
                        } catch (error) {}
                    }
                } else {
                    return;
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    useEffect(() => {
        if (personnelProfileData.birth_date) {
            setSelectedBirthDate(personnelProfileData.birth_date);
        }
    }, [personnelProfileData.birth_date]);

    const handleChangeDrawStatus = (
        event: React.MouseEvent,
        data_id: string,
        name: string,
        index: number
    ) => {
        setPersonnelProfileData(initialPersonnelProfileData);
        setLicense([]);
        callMedicalStaffSpecAPI(data_id);
        setDrawerOpenStatus(true);
        setSelectedBirthDate(null);
        setSelectedDateIn(null);
        setSelectedDateOut(null);
    };

    const handleCloseDrawer = (event: React.MouseEvent) => {
        if (hasUnsavedChanges) {
            event.stopPropagation();
            Swal.fire({
                title: '資料尚未儲存，是否要離開？',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: '是',
                cancelButtonText: '否',
            }).then(result => {
                if (result.isConfirmed) {
                    setDrawerOpenStatus(false);
                    setDrawerKey(prevKey => prevKey + 1);
                    setHasUnsavedChanges(false);
                    setEditStatus(false);
                    setSelectedShowListsIndex(-1);
                    setLicense([]);
                    setPersonnelProfileData(initialPersonnelProfileData);
                    closeDrawer();
                } else {
                    setDrawerOpenStatus(true);
                }
            });
        } else {
            setEditStatus(false);
            // setDrawerOpenStatus(false);
            closeDrawer();
            setPersonnelProfileData(initialPersonnelProfileData);
            setLicenseTypeSecondLists([]);
            setLicense([]);
        }
    };

    // 展開權限控制
    const handleOpenPermissionDashBoard = () => {
        setPermissionDrawerStatus(true);
        // setEditStatus(true);
    };

    const handleClosePermissionDashBoard = () => {
        setPermissionDrawerStatus(false);
        setRadioStatus('');
    };

    const handleChangeColor = (color: { hex: string }) => {
        setPersonnelProfileData(prev => ({
            ...prev,
            color: color.hex,
        }));
    };

    const [isNewDrawer, setIsNewDrawer] = useState(false);
    const [finalFormatDate, setFinalFormatDate] = useState('');
    // 西元年轉民國年 之後要拆出去
    function convertGregorianToROC(gregorianDate: Date) {
        const rocYear = gregorianDate.getFullYear() - 1911;
        const month = gregorianDate.getMonth() + 1;
        const day = gregorianDate.getDate();
        return `${rocYear}.${month.toString().padStart(2, '0')}.${day
            .toString()
            .padStart(2, '0')}`;
    }

    useEffect(()=>{
        console.log(personnelProfileData.identity_code, '有東西ㄇ', personnelProfileData )

    },  [personnelProfileData])
    const postMedicalStaffSpecAPI = async (finalData: updatedDataProps) => {
        if (token) {
            try {
                const result = await postMedicalStaffSpecService(
                    token,
                    finalData
                );
                if (result.result === 'success') {
                    function executeUpdateLogic() {
                        setShowLists((prev: ShowListsProps[]) => {
                            if (!prev) return prev;

                            const matchedIdentity = identityLists.find(
                                item =>
                                    item.code ===
                                    (finalData.info as any).identity_code
                            );

                            const newData = {
                                name: finalData.info.name,
                                data_id: result.data_id,
                                identity: matchedIdentity
                                    ? matchedIdentity.name
                                    : '',
                            };

                            const existingIndex = prev.findIndex(
                                item => item.data_id === newData.data_id
                            );

                            if (existingIndex !== -1) {
                                const updatedList = [...prev];
                                updatedList[existingIndex] = newData;
                                return updatedList;
                            } else {
                                return [newData, ...prev];
                            }
                        });
                        setHasUnsavedChanges(false);
                    }
                    if (personnelProfileData.data_id) {
                        // 修改
                        if (personnelProfileData.date_out) {
                            const dateOut = new Date(
                                personnelProfileData.date_out
                            );
                            const today = new Date();
                            today.setHours(0, 0, 0, 0);

                            if (dateOut <= today) {
                                setShowLists((prev: ShowListsProps[]) => {
                                    if (!prev) return prev;
                                    return prev.filter(
                                        item =>
                                            item.data_id !==
                                            personnelProfileData.data_id
                                    );
                                });
                            } else {
                                executeUpdateLogic();
                            }
                        } else {
                            executeUpdateLogic();
                        }
                    } else {
                        setShowLists((prev: ShowListsProps[]) => {
                            if (!prev) return prev;

                            const matchedIdentity = identityLists.find(
                                item =>
                                    item.code ===
                                    (finalData.info as any).identity_code
                            );

                            const newData = {
                                name: finalData.info.name,
                                data_id: result.data_id,
                                identity: matchedIdentity
                                    ? matchedIdentity.name
                                    : '',
                            };

                            return [newData, ...prev];
                        });
                        setHasUnsavedChanges(false);
                    }
                    Swal.fire({
                        title: '儲存成功',
                        icon: 'success',
                        confirmButtonColor: '#868C6E',
                    });

                    callMedicalStaffSpecAPI(result.data_id);
                } else if (
                    result.result === 'failure' &&
                    result.msg === 'email 已被使用'
                ) {
                    Swal.fire({
                        title: 'email 已被使用',
                        icon: 'error',
                        timer: 1500,
                        confirmButtonText: '好',
                    });
                    setEditStatus(true);
                    setHasUnsavedChanges(true);
                    return;
                } else {
                    Swal.fire({
                        title: '發生錯誤',
                        icon: 'error',
                        confirmButtonText: '好',
                    }).then(result => {
                        if (result.isConfirmed) {
                            setEditStatus(true);
                        }
                    });
                    return;
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    const handleSave = () => {
        // name不得為空
        const requiredIdentityCodes = ['iden_doctor', 'iden_medical_staff', 'iden_pharmacist'];
        console.log(personnelProfileData, '先看一下有什麼資料')
        if (personnelProfileData.identity_code && requiredIdentityCodes.includes(personnelProfileData.identity_code)) {
            if (!personnelProfileData.id_no) {
                Swal.fire({
                    title: '醫師,其他醫師,藥師需填寫身分證',
                    icon: 'error',
                    confirmButtonText: '好',
                }).then(result => {
                    return;
                });
                return; 
            }
        }
        if (!personnelProfileData.name) {
            Swal.fire({
                title: '請填寫姓名',
                icon: 'error',
                confirmButtonText: '好',
            }).then(result => {
                return;
            });

            return;
        } else if (!isValidEmail) {
            Swal.fire({
                title: 'Email 格式錯誤',
                icon: 'error',
                confirmButtonText: '好',
            }).then(result => {
                return;
            });
        } else if (
            subjectRequired &&
            (personnelProfileData.subject_code === null ||
                personnelProfileData.subject_code === '')
        ) {
            Swal.fire({
                icon: 'warning',
                title: '請選擇醫師科別',
                timer: 1500,
            }).then(result => {});
            return;
        } else {
            const updatedLicenseData = license.map(
                ({
                    data_id,
                    license_type,
                    license_no,
                    start_date,
                    end_date,
                }) => ({
                    data_id,
                    license_type,
                    license_no,
                    start_date: start_date
                        ? moment(start_date).format('YYYY-MM-DD')
                        : null,
                    end_date: end_date
                        ? moment(end_date).format('YYYY-MM-DD')
                        : null,
                })
            );
            let finalData: updatedDataProps = {
                ...(personnelProfileData.data_id &&
                personnelProfileData.data_id !== ''
                    ? { data_id: personnelProfileData.data_id }
                    : {}),
                info: {
                    name: personnelProfileData.name,
                    ...(personnelProfileData.name_en
                        ? { name_en: personnelProfileData.name_en }
                        : personnelProfileData.name_en === ''
                          ? { name_en: '' }
                          : {}),

                    ...(personnelProfileData.birth_date
                        ? {
                              birth_date: moment(
                                  personnelProfileData.birth_date
                              ).format('YYYY-MM-DD'),
                          }
                        : {}),
                    ...(personnelProfileData.id_no
                        ? { id_no: personnelProfileData.id_no }
                        : personnelProfileData.id_no === ''
                          ? { id_no: '' }
                          : {}),
                    ...(personnelProfileData.identity_code
                        ? { identity_code: personnelProfileData.identity_code }
                        : {}),
                    ...(personnelProfileData.cellphone
                        ? { cellphone: personnelProfileData.cellphone }
                        : personnelProfileData.cellphone === ''
                          ? { cellphone: '' }
                          : {}),
                    ...(personnelProfileData.address
                        ? { address: personnelProfileData.address }
                        : personnelProfileData.address === ''
                          ? { address: '' }
                          : {}),
                    ...(personnelProfileData.city_uid
                        ? { city_uid: personnelProfileData.city_uid }
                        : {}),
                    ...(personnelProfileData.area_uid
                        ? { area_uid: personnelProfileData.area_uid }
                        : {}),
                    ...(personnelProfileData.email
                        ? { email: personnelProfileData.email }
                        : {}),

                    subject_code: personnelProfileData.subject_code ?? '',

                    color: personnelProfileData.color ?? '#868C6E',

                    ...(personnelProfileData.date_in
                        ? {
                              date_in: moment(
                                  personnelProfileData.date_in
                              ).format('YYYY-MM-DD'),
                          }
                        : {}),
                    ...(personnelProfileData.date_out
                        ? {
                              date_out: moment(
                                  personnelProfileData.date_out
                              ).format('YYYY-MM-DD'),
                          }
                        : {}),
                    ...(personnelProfileData.emergence_contactor
                        ? {
                              emergence_contactor:
                                  personnelProfileData.emergence_contactor,
                          }
                        : personnelProfileData.emergence_contactor === ''
                          ? { emergence_contactor: '' }
                          : {}),
                    ...(personnelProfileData.emergence_relationship
                        ? {
                              emergence_relationship:
                                  personnelProfileData.emergence_relationship,
                          }
                        : personnelProfileData.emergence_relationship === ''
                          ? { emergence_relationship: '' }
                          : {}),
                    ...(personnelProfileData.emergence_phone
                        ? {
                              emergence_phone:
                                  personnelProfileData.emergence_phone,
                          }
                        : personnelProfileData.emergence_phone === ''
                          ? { emergence_phone: '' }
                          : {}),
                    personal_auth: { ...customChanges },

                    // 資料庫根本沒欄位
                    // group_permission: personnelProfileData.
                    // personal_permissions: string | null;
                    // identity: string | null;
                },
                license: updatedLicenseData,
                group_auth: selectedDataIds.length > 0 ? selectedDataIds : [],
            };
            setEditStatus(false);
            postMedicalStaffSpecAPI(finalData);
            // setHasUnsavedChanges(false);
        }
    };

    const [drawerKey, setDrawerKey] = React.useState(0);

    // 點空白處關閉抽屜
    const handleOutsideClick = (event: MouseEvent) => {
        const isClickInsideSwal = (event.target as HTMLElement).closest(
            '.swal2-container'
        );
        if (isClickInsideSwal) {
            return; // 如果點擊在 Swal 上，則不進行任何操作
        }

        if (
            //抽屜開著
            drawerOpenStatus &&
            sidebarRef.current &&
            !sidebarRef.current.contains(event.target as Node) &&
            !bgRef.current?.contains(event.target as Node)
        ) {
            if (hasUnsavedChanges) {
                return;
            } else {
                // 直接关闭抽屉
                closeDrawer();
            }
        }
    };
    useEffect(() => {
        if (drawerOpenStatus) {
            document.addEventListener('click', handleOutsideClick, true);
        } else {
            document.removeEventListener('click', handleOutsideClick, true);
        }
        return () => {
            document.removeEventListener('click', handleOutsideClick, true);
        };
    }, [drawerOpenStatus, sidebarRef]);

    const handleCancel = () => {
        if (hasUnsavedChanges) {
            Swal.fire({
                title: '資料尚未儲存，是否要離開？',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: '是',
                cancelButtonText: '否',
            }).then(result => {
                if (result.isConfirmed) {
                    setEditStatus(false);
                    setHasUnsavedChanges(false);
                    setDrawerOpenStatus(false);
                } else {
                    return;
                }
            });
        } else {
            const filteredLicenses = license.filter(
                item => item.data_id && item.data_id.trim() !== ''
            );
            setLicense(filteredLicenses);
            setEditStatus(false);
            // setDrawerOpenStatus(false);
        }
    };
    //拖曳
    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return; // 没有有效的拖放目标
        } else {
            const newData = [...showLists];

            const [movedItem] = newData.splice(result.source.index, 1);
            newData.splice(result.destination.index, 0, movedItem);

            setShowLists(newData);

            if (newData) {
                const postData = async () => {
                    const data = {
                        ids: newData.map(item => item.data_id),
                    };
                    const postMemberShipSort = async () => {
                        if (token) {
                        }
                    };
                    postMemberShipSort();
                };
                postData();
            }
            // fetchData()
        }
    };

    // 檢查文字中是否包含全形字符
    const containsFullWidth = (text: string) => {
        const fullWidthRegex = /[^\x00-\xff]/; // 匹配全形字符的正規表達式
        return fullWidthRegex.test(text);
    };

    // 檢查 email 格式是否正確
    const validEmail = (text: string) => {
        // 優化過的正則表達式，用以檢查 email 格式
        const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return isValidEmail.test(text);
    };
    // 為了符合
    // ^[a-zA-Z0-9._%+-]+：確保開頭以字母、數字、點、底線、百分號、加號和連字符組成，且至少有一個字符。
    // @[a-zA-Z0-9.-]+：確保有一個 @，隨後包含字母、數字、點和連字符，且至少有一個字符。
    // \.[a-zA-Z]{2,}$：確保以 點開頭 \.，後面跟著至少兩個字母字符。

    console.log('驗證一下：', validEmail('tehsing.hsu@gmail.com'));

    const handleChangeInput = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        // 當 email 為空時，不進行格式驗證
        if (name === 'email') {
            if (value.trim() === '') {
                setIsValidEmail(true);
            } else {
                setIsValidEmail(validEmail(value) && !containsFullWidth(value));
            }
        }

        if (name === 'city_uid') {
            if (value) {
                callCityAPI(value);
            } else if (value === '') {
                setPersonnelProfileData({
                    ...personnelProfileData,
                    address: '',
                });
            } else {
            }
        }

        setPersonnelProfileData(prev => ({
            ...prev,
            [name]: value,
        }));

        setHasUnsavedChanges(true);
    };

    const [subjectSelect, setSubjectSelect] = useState<boolean>(false);
    const [subjectRequired, setSubjectRequired] = useState<boolean>(false);

    useEffect(() => {
        const selectedItem = identityLists.find(
            item => item.code === personnelProfileData.identity_code
        );

        if (selectedItem && selectedItem?.name === '醫師') {
            setSubjectSelect(true);
            setSubjectRequired(true);
        } else {
            setSubjectSelect(false);
            setSubjectRequired(false);
            setPersonnelProfileData(prev => ({
                ...prev,
                subject_code: '',
            }));
        }
    }, [personnelProfileData.identity_code]);

    const handleNewDrawer = () => {
        // callMedicalStaffSpecAPI(data_id);
        setPersonnelProfileData(initialPersonnelProfileData);
        setDrawerOpenStatus(true);
        setEditStatus(true);
        // 直接清日期
        setSelectedBirthDate(null);
        setSelectedDateIn(null);
        setSelectedDateOut(null);
        setLicense([]);
    };

    const callMedicalStaffListAPI = async () => {
        if (token) {
            try {
                const result = await callMedicalStaffListService(token);
                if (result.result === 'success') {
                    setShowLists(result.lists);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    useEffect(() => {
        callMedicalStaffListAPI();
    }, []);
    // const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    // const handleChangeDate = (newDate: Date | null) => {
    //     setSelectedDate(newDate);
    // };

    // 科別下拉選單

    const [hospSubjectLists, setHospSubjectLists] = useState<
        hospSubjectProps[]
    >([]);
    const { data: hospSubjectData, isSuccess: isHospSubjectSuccess } =
        useDropDownMenuQuery('hospSubject', userInfo.token!);
    useEffect(() => {
        if (isHospSubjectSuccess) {
            setHospSubjectLists(hospSubjectData.lists);
        }
    }, [isHospSubjectSuccess]);

    const [subjectItem, setSubjectItem] = useState<hospSubjectProps>();

    useEffect(() => {
        if (personnelProfileData.subject_code === '') {
            setSubjectItem({ id: '', name: '' });
        } else {
            const subject = hospSubjectLists.find(
                item => item.id === personnelProfileData.subject_code
            );
            if (subject) {
                setSubjectItem(subject);
            }
        }
    }, [personnelProfileData.subject_code, hospSubjectLists]);

    const handleChangeBirthDate = (selectedDate: Date | null) => {
        if (selectedDate !== selectedBirthDate) {
            setSelectedBirthDate(selectedDate);
            setPersonnelProfileData({
                ...personnelProfileData,
                birth_date: selectedDate,
            });
        }
    };
    const handleFocusDate = (_: React.FocusEvent<HTMLInputElement>) => {
        setPreSelectedBirthDate(selectedBirthDate);
        setSelectedBirthDate(null);
    };
    // 新的測試
    const handleBlur = (event: any) => {
        const enteredValue = event.currentTarget.value.replace(/\./g, '');

        if (enteredValue.length < 6) {
            setSelectedBirthDate(preSelectedBirthDate);
            return;
        }

        const yearOffset = enteredValue.length === 7 ? 0 : 1;
        const year = parseInt(enteredValue.substring(0, 3 - yearOffset)) + 1911;
        const month = enteredValue.substring(3 - yearOffset, 5 - yearOffset);
        const day = enteredValue.substring(5 - yearOffset);

        const formattedDate = `${year}-${month}-${day}`;
        const enteredDate = moment(formattedDate, 'YYYY-MM-DD');

        // 檢查日期
        if (!enteredDate.isValid()) {
            setSelectedBirthDate(preSelectedBirthDate);
        } else if (enteredDate.toDate() !== selectedBirthDate) {
            handleChangeBirthDate(enteredDate.toDate());
        }
    };
    const handleEdit = () => {
        setEditStatus(true);
        // if(personnelProfileData?.birth_date){
        //     setSelectedBirthDate(personnelProfileData.birth_date)
        // }
        // if (personnelProfileData.birth_date) {
        //     // 如果 birth_date 存在，則使用它來創建新的 Date 對象
        //     setSelectedBirthDate(new Date(personnelProfileData.birth_date));
        // } else {
        //     // 如果 birth_date 不存在，您可以選擇設置一個預設日期，或者保留為 null，根據您的需求
        //     // 例如，設置為當前日期：setSelectedBirthDate(new Date());
        //     // 或者保留為 null（如果 setSelectedBirthDate 允許 null 值）
        //     setSelectedBirthDate(null); // 或其他預設處理
        // }
    };
    const handleChangeDateIn = (selectedDate: Date | null) => {
        setSelectedDateIn(selectedDate);
        setPersonnelProfileData(prev => ({
            ...prev,
            date_in: selectedDate,
        }));
    };
    const handleChangeDateOut = (selectedDate: Date | null) => {
        setSelectedDateOut(selectedDate);
        setPersonnelProfileData(prev => ({
            ...prev,
            date_out: selectedDate,
        }));
    };
    // 身份資料第一層
    const [identityLists, setIdentityLists] = useState<IdentityListsProps[]>(
        []
    );
    const callIdentityAPI = async () => {
        if (token) {
            try {
                const result = await callGTypeIdentityService(token);
                console.log(result, '看一下身份這個下拉')
                if (result.result === 'success') {
                    setIdentityLists(result.lists);
                    setSelectedLicenseTypeSecondName('');
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    useEffect(() => {
        callIdentityAPI();
    }, []);
    useEffect(() => {
        setSelectedLicenseTypeSecondName('');
    }, [personnelProfileData.identity_code]);
    // License Second
    const [selectedLicenseTypeSecondName, setSelectedLicenseTypeSecondName] =
        useState('');
    const handleChangeLicenseSecond = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSelectedLicenseTypeSecondName(event.target.value);
    };
    useEffect(() => {
        callLicenseTypeAPI();
    }, [personnelProfileData.identity_code]);
    // 執照第二層的APi

    // 這個是身份去判斷
    const callLicenseTypeAPI = async () => {
        if (token && personnelProfileData.identity_code) {
            try {
                const result = await callLicenseTypeSecondService(
                    token,
                    personnelProfileData.identity_code
                );
                if (Array.isArray(result) && result.length === 0) {
                    setLicenseTypeSecondLists([]);

                    return;
                }
                if (result && result.result === 'success') {
                    setLicenseTypeSecondLists(result.lists);
                    setSelectedLicenseTypeThirdName('');
                } else if (result.data.result === 'empty') {
                    setLicenseTypeSecondLists([]);
                    setSelectedLicenseTypeThirdName('-');
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    const [selectedLicenseTypeThirdName, setSelectedLicenseTypeThirdName] =
        useState('');
    // 執照第三層的API
    // 第二層控制
    const callLicenseTypeThirdAPI = async () => {
        const selectedLicenseTypeSecondNameCode = licenseTypeSecondLists?.find(
            item => item.name === selectedLicenseTypeSecondName
        )?.code;
        if (token && selectedLicenseTypeSecondNameCode) {
            try {
                const result = await callLicenseTypeThirdService(
                    token,
                    selectedLicenseTypeSecondNameCode
                );
                if (Array.isArray(result) && result.length === 0) {
                    setSelectedLicenseTypeThirdName('');
                    setLicenseTypeThirdLists([]);
                    return;
                }
                if (result.result) {
                    setLicenseTypeThirdLists(result.lists);
                    setSelectedLicenseTypeThirdName('');
                } else if (result.data.result === 'empty') {
                    setSelectedLicenseTypeThirdName('');
                    setLicenseTypeThirdLists([]);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    const [groupLists, setGroupLists] = useState<GroupListsProps[]>([]);

    const callGroupAuthListAPI = async () => {
        if (token) {
            try {
                const result = await callGroupAuthListService(token);
                if (result.result === 'success') {
                    setGroupLists(result.lists);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    useEffect(() => {
        callGroupAuthListAPI();
    }, []);

    useEffect(() => {
        callLicenseTypeThirdAPI();
    }, [selectedLicenseTypeSecondName]);

    const handleChangeLicenseThird = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSelectedLicenseTypeThirdName(event.target.value);
    };

    const handleAddLicense = () => {
        let identity = personnelProfileData.identity_code;
        if (identity) {
            setLicense(prev => {
                let licenseType = '';
                let licenseName = '';
                if (
                    selectedLicenseTypeThirdName &&
                    selectedLicenseTypeThirdName !== '-'
                ) {
                    // 三層的執照

                    const foundItem = licenseTypeThirdLists?.find(
                        item => item.name === selectedLicenseTypeThirdName
                    );
                    licenseType = foundItem?.code || '';
                    licenseName = foundItem?.name || '';
                } else if (
                    selectedLicenseTypeSecondName &&
                    selectedLicenseTypeSecondName !== '-'
                ) {
                    // 第二層的
                    const foundItem = licenseTypeSecondLists?.find(
                        item => item.name === selectedLicenseTypeSecondName
                    );
                    licenseType = foundItem?.code || '';
                    licenseName = foundItem?.name || '';
                } else {
                    // 目前應該只有行政人員第一層
                    licenseType = personnelProfileData.identity_code ?? '';
                    licenseName =
                        identityLists.find(
                            item =>
                                item.code === personnelProfileData.identity_code
                        )?.name || '';
                }
                const newLicense: LicenseProps = {
                    license_type: licenseType,
                    license_name: licenseName,
                    license_no: '',
                    start_date: new Date(),
                    end_date: null,
                };

                return [newLicense, ...prev];
            });
        }
    };
    // 修改license的內容
    const handleChangeLicenseInput = (
        event: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        setLicense(prev => {
            if (!prev) return prev;
            return prev.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        license_no: event.target.value,
                    };
                } else {
                    return item;
                }
            });
        });
    };

    const handleChangeLicenseStartDate = (
        selectedDate: Date | null,
        index: number
    ) => {
        setLicense(prev =>
            prev.map((item, idx) =>
                idx === index ? { ...item, start_date: selectedDate } : item
            )
        );
    };

    const handleChangeLicenseEndDate = (
        selectedDate: Date | null,
        index: number
    ) => {
        setLicense(prev =>
            prev.map((item, idx) =>
                idx === index ? { ...item, end_date: selectedDate } : item
            )
        );
    };

    const handleDeleteLicense = (data_id: string, thisIndex: number) => {
        if (data_id === '') {
            const updatedLicense = license.filter(
                (_, index) => index !== thisIndex
            );
            setLicense(updatedLicense);
        } else {
            deleteMedicalStaffLicenseAPI(data_id);
        }
    };
    useEffect(() => {}, [license]);

    const deleteMedicalStaffLicenseAPI = async (data_id: string) => {
        if (token) {
            try {
                const result = await deleteMedicalStaffLicenseService(
                    token,
                    data_id
                );
                if (result.result === 'success') {
                    // setLicense(result.lists);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    const handleClickStatus = (event: React.MouseEvent<HTMLButtonElement>) => {
        const value = event.currentTarget.value;
        if (value) {
            if (radioStatus === value) {
                setRadioStatus(null);
            } else {
                if (radioStatus === '') {
                    setRadioStatus('');
                }
                setRadioStatus(value);
            }
        }
    };

    useEffect(() => {
        //改造結構 加上status
        const transformData = () => {
            return Object.entries(navigation).map(([key, item]) => {
                const newItem = {
                    ...item,
                    key: item.key || key,
                    status: '',
                };
                if (newItem.items) {
                    newItem.items = Object.entries(newItem.items).map(
                        ([subKey, subItem]) => {
                            return {
                                ...subItem,
                                key: subItem.key || subKey,
                                status: '',
                            };
                        }
                    );
                }
                return newItem;
            });
        };
        if (navigation) {
            setNewNavigation(transformData());
        }
    }, [navigation]);

    const callSystemFunctionTreeAPI = async () => {
        if (token) {
            try {
                const result = await callSystemFunctionTreeService(token);

                if (result.result === 'success') {
                    setNavigation(result.items);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    const [selectedDataIds, setSelectedDataIds] = useState<string[]>([]);

    useEffect(() => {
        callSystemFunctionTreeAPI();
    }, []);

    const handleChangeGroupLists = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const clickedId = event.target.value;

        setSelectedDataIds(prev => {
            const newSelectedDataIds = prev.includes(clickedId)
                ? prev.filter(id => id !== clickedId)
                : [...prev, clickedId];

            return newSelectedDataIds;
        });
    };
    // 設定 哪個層級比較大 讓群組去練蠱
    const isHigherAuthLevel = (currentLevel: string, existingLevel: string) => {
        const levels = { D: 3, E: 2, V: 1, '': 0 };
        return (
            (levels[currentLevel as keyof typeof levels] || 0) >
            (levels[existingLevel as keyof typeof levels] || 0)
        );
    };
    // useEffect(() => {
    //     const filteredGroups = groupLists.filter(group =>
    //         group.data_id && selectedDataIds.includes(group.data_id)
    //     );
    // };
    const [finalMergedFuncAuth, setFinalMergedFuncAuth] =
        useState<Record<string, string>>();
    useEffect(() => {
        const filteredGroups = groupLists.filter(
            group => group.data_id && selectedDataIds.includes(group.data_id)
        );

        const mergedFuncAuth = filteredGroups.reduce<Record<string, string>>(
            (acc, group) => {
                Object.keys(group.func_auth).forEach(key => {
                    const currentAuthLevel = group.func_auth[key];
                    const existingAuthLevel = acc[key] || '';

                    if (
                        isHigherAuthLevel(currentAuthLevel, existingAuthLevel)
                    ) {
                        acc[key] = currentAuthLevel;
                    }
                });
                return acc;
            },
            {}
        );
        setFinalMergedFuncAuth(mergedFuncAuth);
    }, [selectedDataIds, groupLists]);
    //
    const [customChanges, setCustomChanges] = useState<Record<string, string>>(
        {}
    );

    useEffect(() => {
        if (!finalMergedFuncAuth || !newNavigation) return;

        const updatedNavigation = newNavigation.map(navItem => {
            const updatedNavItem = { ...navItem };

            if (navItem.items) {
                updatedNavItem.items = navItem.items.map(subItem => {
                    if (customChanges[subItem.key]) {
                        return {
                            ...subItem,
                            status: customChanges[subItem.key],
                        };
                    }
                    const updatedSubItem = { ...subItem };
                    if (finalMergedFuncAuth[subItem.key]) {
                        updatedSubItem.status =
                            finalMergedFuncAuth[subItem.key];
                    } else {
                        return { ...subItem, status: '' };
                    }
                    return updatedSubItem;
                });
            }

            return updatedNavItem;
        });

        setNewNavigation(updatedNavigation);
    }, [finalMergedFuncAuth]);

    const handleClickBtn = (itemName: string, key: string) => {
        setNewNavigation(prevNavigation => {
            if (!prevNavigation) return prevNavigation;

            const newCustomChanges = { ...customChanges };

            const updatedNavigation = prevNavigation.map(item => {
                if (item.key === key) {
                    const newStatus =
                        radioStatus === 'V' ? '' : radioStatus || 'E';

                    if (item.items) {
                        const updatedItems = item.items.map(subItem => {
                            newCustomChanges[subItem.key] = newStatus;
                            return { ...subItem, status: newStatus };
                        });

                        return {
                            ...item,
                            status: newStatus,
                            items: updatedItems,
                        };
                    }

                    return { ...item, status: newStatus };
                }
                return item;
            });

            setCustomChanges(newCustomChanges);

            return updatedNavigation;
        });
    };
    const getTextColor = (status: string) => {
        switch (status) {
            case 'E':
                return `${'rsPrimary.500'}`;
            default:
                return '';
        }
    };
    const handleClickChildBtn = (itemName: string, key: string) => {
        setNewNavigation(prevNavigation => {
            if (!prevNavigation) return prevNavigation;

            return prevNavigation.map(item => {
                let allStatusSame = true;
                let firstStatus: string | null = null;

                if (item.items) {
                    item.items = item.items.map(subItem => {
                        if (subItem.key === key) {
                            let newStatus;

                            if (radioStatus) {
                                newStatus =
                                    radioStatus === 'V' ? '' : radioStatus;
                            } else {
                                switch (subItem.status) {
                                    case '':
                                        newStatus = 'E';
                                        break;
                                    case 'E':
                                        newStatus = 'D';
                                        break;
                                    case 'D':
                                        newStatus = '';
                                        break;
                                    default:
                                        newStatus = '';
                                }
                            }
                            return { ...subItem, status: newStatus };
                        }
                        return subItem;
                    });

                    firstStatus = item.items[0]?.status;
                    allStatusSame = item.items.every(
                        subItem => subItem.status === firstStatus
                    );
                }
                const newStatus = radioStatus === 'V' ? '' : radioStatus || 'E';
                setCustomChanges(prev => ({ ...prev, [key]: newStatus }));
                return {
                    ...item,
                    status:
                        allStatusSame && firstStatus !== null
                            ? firstStatus
                            : 'N',
                };
            });
        });
    };
    const getButtonColor = (status: string) => {
        switch (status) {
            case '':
                return `${'rsPrimary'}`;
            case 'D':
                return `${'rsDanger'}`;
            case 'E':
                return `${'rsWarning'}`;
        }
    };

    const [showColorPicker, setShowColorPicker] = useState(false);

    const toggleColorPicker = () => {
        setShowColorPicker(prev => !prev);
    };

    const handleOpenAnAccount = () => {
        postSetUserToVerifyAPI();
        setEmailSending(true);
    };
    const [preSelectedBirthDate, setPreSelectedBirthDate] =
        useState<Date | null>(null);
    const [cityObjLists, setCityObjLists] = useState([]);
    const { data: cityData, isSuccess: isCitySuccess } = useDropDownMenuQuery(
        'city',
        token!
    );
    useEffect(() => {
        if (isCitySuccess) {
            setCityObjLists(cityData.lists);
        }
    }, [isCitySuccess]);
    const [cityPostalAreaObjLists, setCityPostalAreaObjList] = useState([]);

    const callCityAPI = async (city: string) => {
        if (city) {
            try {
                const result = await callCityService(token!, city);
                setCityPostalAreaObjList(result.lists);
                const firstKey = Object.keys(result.lists)[0];
                setCityPostalAreaObjList(result.lists);
                setPersonnelProfileData(prev => ({
                    ...prev,
                    area_uid: firstKey,
                }));
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    const callCityAPIWithData = async (city: string) => {
        if (city) {
            try {
                const result = await callCityService(token!, city);
                setCityPostalAreaObjList(result.lists);
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    useEffect(() => {
        if (
            personnelProfileData.area_uid &&
            cityPostalAreaObjLists.length == 0
        ) {
            callCityAPIWithData(personnelProfileData.city_uid);
        }
    }, [personnelProfileData.area_uid]);

    useEffect(() => {
        console.log('監看變動 unChange: ', hasUnsavedChanges);
        console.log('監看變動 editStatus: ', editStatus);
    }, [hasUnsavedChanges, editStatus]);

    return (
        <>
            <Box pos={'relative'}>
                <Box mb={'16px'}>
                    <Button colorScheme="rsPrimary" onClick={handleNewDrawer}>
                        <HeroIcons name="user-plus-fill" />
                        <Text className="ml-[4px]">新增人員資料</Text>
                    </Button>
                </Box>

                <DragDropContext onDragEnd={handleDragEnd}>
                    <Box
                        rounded={'15px'}
                        bgColor={'gray.50'}
                        borderBottom={'2px solid #ccc'}
                        maxW={'600px'}
                        w={'100%'}
                        overflowY={'scroll'}
                        maxH={`calc(${window.innerHeight}px - 170px)`}
                    >
                        <Box className="relative">
                            {/* Table Header */}
                            <Flex
                                borderBottom={'2px solid #9B9B7A'}
                                p={'8px 16px'}
                                gap={'16px'}
                                bg={'#F7F7F7'}
                                pos={'sticky'}
                                top={0}
                                left={0}
                                w={'100%'}
                                zIndex={10}
                            >
                                <Box hidden textAlign={'center'}>
                                    排序
                                </Box>
                                <Box flex={'0 0 100px'} textAlign={'center'}>
                                    詳細內容
                                </Box>

                                <Box flex={'1 1 100px'} minW={'100px'}>
                                    姓名
                                </Box>
                                <Box flex={'1 1 100px'}>身份</Box>
                            </Flex>

                            {/* Table Body */}
                            <Droppable
                                droppableId="droppable-table"
                                type="group"
                            >
                                {provided => (
                                    <Box
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        // maxHeight={tableHeight - 100}
                                        // overflow={'scroll'}
                                    >
                                        {showLists &&
                                            showLists.map((item, index) => (
                                                <Draggable
                                                    key={item.data_id}
                                                    draggableId={item.data_id}
                                                    index={index}
                                                >
                                                    {provided => (
                                                        <Flex
                                                            {...provided.draggableProps}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            _hover={{
                                                                bgColor:
                                                                    '#E9E5DA',
                                                            }}
                                                            bg={
                                                                selectedShowListsIndex ===
                                                                index
                                                                    ? '#DDDBBA'
                                                                    : ''
                                                            }
                                                            borderBottom={
                                                                '1px solid #ddd'
                                                            }
                                                            p={'8px 16px'}
                                                            gap={'16px'}
                                                        >
                                                            {/* 排序 */}
                                                            <Box
                                                                hidden
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <DragIcon />
                                                            </Box>

                                                            {/* 詳細內容 */}
                                                            <Box
                                                                flex={
                                                                    '0 0 100px'
                                                                }
                                                                py={'2px'}
                                                            >
                                                                <DrawerDetailsIcon
                                                                    key={index}
                                                                    isActive={
                                                                        selectedShowListsIndex ===
                                                                        index
                                                                    }
                                                                    onClick={(
                                                                        event: React.MouseEvent<HTMLElement>
                                                                    ) =>
                                                                        //檢視帶id, icd_code, name,name_en是為了沒id時可以抓值到抽屜中顯示, index則是回填時使用
                                                                        // lists顯示是code 但流程都是icd_code
                                                                        handleChangeDrawStatus(
                                                                            event,
                                                                            item.data_id,
                                                                            // item.icd_code,
                                                                            // item.code,
                                                                            item.name,
                                                                            // item.name_en,
                                                                            index
                                                                            // item.short_code,
                                                                        )
                                                                    }
                                                                />
                                                            </Box>

                                                            {/* 姓名 */}
                                                            <Box
                                                                flex={
                                                                    '1 1 100px'
                                                                }
                                                                minW={'100px'}
                                                                textOverflow={
                                                                    'ellipsis'
                                                                }
                                                                overflow={
                                                                    'hidden'
                                                                }
                                                                whiteSpace={
                                                                    'nowrap'
                                                                }
                                                            >
                                                                {item.name ||
                                                                    ''}
                                                            </Box>
                                                            {/* 身份 */}
                                                            <Box
                                                                flex={
                                                                    '1 1 100px'
                                                                }
                                                            >
                                                                {item.identity ||
                                                                    ''}
                                                            </Box>
                                                        </Flex>
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </Box>
                    </Box>
                </DragDropContext>

                {/* 打開抽屜 */}
                {drawerOpenStatus && (
                    <>
                        <Flex
                            gap={'16px'}
                            direction={'column'}
                            ref={sidebarRef}
                            id="a"
                            zIndex={30}
                            pos="fixed"
                            top="76px"
                            right={'0px'}
                            display={drawerOpenStatus ? 'block' : 'none'}
                            maxW={'1000px'}
                            w={'calc(100% - 40px)'}
                            h={`calc(${window.innerHeight}px - 76px)`}
                            bgColor="#fefefe"
                            boxSizing="border-box"
                            boxShadow="0px 15px 20px 0px rgba(0, 0, 0, 0.2)"
                            minH={'calc(100dvh - 116px)'}
                        >
                            <Flex
                                px={'16px'}
                                py={'8px'}
                                align="center"
                                justify={'space-between'}
                                bg={'rsPrimary.500'}
                                textColor={'#fff'}
                            >
                                <Text fontSize={'lg'}>詳細內容</Text>
                                <Box
                                    opacity={editStatus ? '0' : '1'}
                                    onClick={handleCloseDrawer}
                                    cursor={'pointer'}
                                    textColor={'#fff'}
                                >
                                    <HeroIcons name="xmark-sm"></HeroIcons>
                                </Box>
                            </Flex>

                            {/* 表單內容 */}
                            <SimpleGrid
                                columns={[1, 2]}
                                spacingX={'32px'}
                                spacingY={'8px'}
                                py={'8px'}
                                px={'16px'}
                            >
                                {/* 中文名 */}
                                <GridItem className="flex items-center gap-[8px]">
                                    <Text
                                        flex={'0 0 80px'}
                                        textStyle={'semiTitle'}
                                    >
                                        姓名
                                        <small
                                            className={
                                                // requiredWarning &&

                                                editStatus
                                                    ? 'text-red-500 text-[10px]'
                                                    : 'hidden'
                                            }
                                        >
                                            ＊必填
                                        </small>
                                    </Text>
                                    {editStatus ? (
                                        <>
                                            <Input
                                                onChange={handleChangeInput}
                                                name="name"
                                                maxLength={50}
                                                value={
                                                    personnelProfileData?.name ??
                                                    ''
                                                }
                                                bgColor={'#fff'}
                                            ></Input>
                                        </>
                                    ) : (
                                        <>
                                            <Text
                                                borderBottom={'1px solid #ccc'}
                                                h={'40px'}
                                                lineHeight={'40px'}
                                                w={'100%'}
                                                overflowY={'hidden'}
                                                overflowX={'hidden'}
                                                whiteSpace={'nowrap'}
                                                textOverflow={'ellipsis'}
                                                px={'16px'}
                                            >
                                                {personnelProfileData.name}
                                            </Text>
                                        </>
                                    )}
                                </GridItem>

                                {/* 英文名 */}
                                <GridItem className="flex items-center gap-[8px]">
                                    <Text
                                        flex={'0 0 80px'}
                                        textStyle={'semiTitle'}
                                    >
                                        英文姓名
                                    </Text>
                                    {editStatus ? (
                                        <>
                                            <Input
                                                onChange={handleChangeInput}
                                                name="name_en"
                                                maxLength={50}
                                                value={
                                                    personnelProfileData?.name_en ??
                                                    ''
                                                }
                                                bgColor={'#fff'}
                                            ></Input>
                                        </>
                                    ) : (
                                        <>
                                            <Flex
                                                placeItems={'center'}
                                                borderBottom={'1px solid #ccc'}
                                                w={'100%'}
                                                h={'40px'}
                                                paddingInline={'16px'}
                                            >
                                                {personnelProfileData.name_en}
                                            </Flex>
                                        </>
                                    )}
                                </GridItem>

                                {/* 身份 */}
                                <GridItem className="flex items-center gap-[8px]">
                                    <Text
                                        flex={'0 0 80px'}
                                        className="flex-none"
                                        textStyle={'semiTitle'}
                                    >
                                        身份
                                    </Text>
                                    {editStatus ? (
                                        <>
                                            <Select
                                                name="identity_code"
                                                value={
                                                    personnelProfileData.identity_code ??
                                                    ''
                                                }
                                                onChange={handleChangeInput}
                                                bgColor={'#fff'}
                                            >
                                                <option value="" disabled>
                                                    請選擇
                                                </option>
                                                {identityLists &&
                                                    identityLists.map(
                                                        (item, index) => (
                                                            <option
                                                                key={index}
                                                                value={
                                                                    item.code
                                                                }
                                                            >
                                                                {item.name}
                                                            </option>
                                                        )
                                                    )}
                                            </Select>
                                        </>
                                    ) : (
                                        <>
                                            <Flex
                                                placeItems={'center'}
                                                borderBottom={'1px solid #ccc'}
                                                w={'100%'}
                                                h={'40px'}
                                                paddingInline={'16px'}
                                            >
                                                {identityLists.find(
                                                    item =>
                                                        item.code ===
                                                        personnelProfileData?.identity_code
                                                )?.name ?? ''}
                                            </Flex>
                                        </>
                                    )}
                                </GridItem>

                                {/* 科別 */}
                                <GridItem className="flex items-center gap-[8px]">
                                    <Text
                                        flex={'0 0 80px'}
                                        className="flex-none"
                                        textStyle={'semiTitle'}
                                    >
                                        科別
                                    </Text>
                                    {editStatus ? (
                                        <>
                                            <Select
                                                bg={'#fff'}
                                                onChange={handleChangeInput}
                                                value={
                                                    personnelProfileData?.subject_code ??
                                                    ''
                                                }
                                                name="subject_code"
                                                disabled={!subjectSelect}
                                            >
                                                <option value="">無</option>
                                                {hospSubjectLists &&
                                                    hospSubjectLists.map(
                                                        (item, index) => (
                                                            <option
                                                                value={item.id}
                                                                key={index}
                                                            >
                                                                {item.id}
                                                                {item.name}
                                                            </option>
                                                        )
                                                    )}
                                            </Select>
                                        </>
                                    ) : (
                                        <>
                                            <Flex
                                                placeItems={'center'}
                                                borderBottom={'1px solid #ccc'}
                                                w={'100%'}
                                                h={'40px'}
                                                paddingInline={'16px'}
                                            >
                                                {subjectItem && subjectItem.name
                                                    ? subjectItem.id +
                                                      subjectItem.name
                                                    : '無'}
                                            </Flex>
                                        </>
                                    )}
                                </GridItem>

                                {/* 身分證字號 */}
                                <GridItem className="flex items-center gap-[8px]">
                                    <Text
                                        flex={'0 0 80px'}
                                        className="flex-none"
                                        textStyle={'semiTitle'}
                                    >
                                        身分證字號
                                    </Text>
                                    {editStatus ? (
                                        <>
                                            <Input
                                                maxLength={10}
                                                onChange={handleChangeInput}
                                                name="id_no"
                                                value={
                                                    personnelProfileData?.id_no ??
                                                    ''
                                                }
                                                bgColor={'#fff'}
                                            ></Input>
                                        </>
                                    ) : (
                                        <>
                                            <Flex
                                                placeItems={'center'}
                                                borderBottom={'1px solid #ccc'}
                                                w={'100%'}
                                                h={'40px'}
                                                paddingInline={'16px'}
                                            >
                                                {personnelProfileData.id_no}
                                            </Flex>
                                        </>
                                    )}
                                </GridItem>

                                {/* 生日 */}
                                <GridItem className="flex items-center gap-[8px]">
                                    <Text
                                        flex={'0 0 80px'}
                                        className="flex-none"
                                        textStyle={'semiTitle'}
                                    >
                                        生日
                                    </Text>
                                    {editStatus ? (
                                        <>
                                            {/* <Input
                                                onChange={handleChangeInput}
                                                name="birth_date"
                                                value={
                                                    personnelProfileData?.birth_date ??
                                                    ''
                                                }
                                            ></Input> */}
                                            <ROCDatePicker
                                                onChange={handleChangeBirthDate}
                                                selectedDate={selectedBirthDate}
                                                handleChangeDate={
                                                    handleChangeBirthDate
                                                }
                                                onFocus={handleFocusDate}
                                                onBlur={handleBlur}
                                            ></ROCDatePicker>
                                            {/* <ROCDatePickerNew  
                                                selectedDate={selectedDate}
                                                onChange={handleChangeDate}
                                            /> */}
                                        </>
                                    ) : (
                                        <>
                                            <Flex
                                                placeItems={'center'}
                                                borderBottom={'1px solid #ccc'}
                                                w={'100%'}
                                                h={'40px'}
                                                paddingInline={'16px'}
                                            >
                                                {personnelProfileData.birth_date
                                                    ? convertGregorianToROC(
                                                          new Date(
                                                              personnelProfileData.birth_date
                                                          )
                                                      )
                                                    : ''}
                                            </Flex>
                                        </>
                                    )}
                                </GridItem>
                                {/* 手機 */}
                                <GridItem className="flex items-center gap-[8px]">
                                    <Text
                                        flex={'0 0 80px'}
                                        className="flex-none"
                                        textStyle={'semiTitle'}
                                    >
                                        手機
                                    </Text>
                                    {editStatus ? (
                                        <>
                                            <Input
                                                onChange={handleChangeInput}
                                                maxLength={10}
                                                name="cellphone"
                                                value={
                                                    personnelProfileData?.cellphone ??
                                                    ''
                                                }
                                                bgColor={'#fff'}
                                            ></Input>
                                        </>
                                    ) : (
                                        <>
                                            <Flex
                                                placeItems={'center'}
                                                borderBottom={'1px solid #ccc'}
                                                w={'100%'}
                                                h={'40px'}
                                                paddingInline={'16px'}
                                            >
                                                {personnelProfileData.cellphone}
                                            </Flex>
                                        </>
                                    )}
                                </GridItem>

                                {/* 權限 */}
                                <GridItem className="flex items-center gap-[8px]">
                                    <Text
                                        flex={'0 0 80px'}
                                        className="flex-none"
                                        textStyle={'semiTitle'}
                                    >
                                        人員權限
                                    </Text>
                                    <Button
                                        size={'sm'}
                                        colorScheme="rsPrimary"
                                        onClick={handleOpenPermissionDashBoard}
                                    >
                                        查看
                                    </Button>
                                </GridItem>

                                {/* email */}
                                <GridItem className="flex items-center gap-2 ">
                                    <Tooltip
                                        label="用於開通帳號，相同 email 不能給多位人員使用"
                                        hasArrow
                                    >
                                        <Text
                                            flex={'0 0 80px'}
                                            className="flex-none"
                                            textStyle={'semiTitle'}
                                        >
                                            email
                                        </Text>
                                    </Tooltip>
                                    {editStatus ? (
                                        <>
                                            <Box pos={'relative'}>
                                                <Input
                                                    maxLength={100}
                                                    w={'100%'}
                                                    onChange={handleChangeInput}
                                                    name="email"
                                                    value={
                                                        personnelProfileData?.email ??
                                                        ''
                                                    }
                                                    bgColor={'#fff'}
                                                    placeholder="example@mail.com"
                                                ></Input>
                                                {!isValidEmail && (
                                                    <Box
                                                        pos={'absolute'}
                                                        top={'50%'}
                                                        left={
                                                            'calc(100% + 5px)'
                                                        }
                                                        sx={{
                                                            transform:
                                                                'translateY(-50%)',
                                                        }}
                                                        zIndex={60}
                                                    >
                                                        <Text
                                                            size={'sm'}
                                                            textColor={
                                                                'rsDanger.500'
                                                            }
                                                            whiteSpace={
                                                                'nowrap'
                                                            }
                                                        >
                                                            * email 格式不正確
                                                        </Text>
                                                    </Box>
                                                )}
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Flex
                                                placeItems={'center'}
                                                gap={3}
                                                w={'100%'}
                                            >
                                                <Text
                                                    h={'40px'}
                                                    minWidth={250}
                                                    maxWidth={
                                                        'calc(100% - 100px)'
                                                    }
                                                    w={'100%'}
                                                    lineHeight={'40px'}
                                                    paddingInline={'16px'}
                                                    borderBottom={
                                                        '1px solid #ccc'
                                                    }
                                                >
                                                    {personnelProfileData.email}
                                                </Text>
                                                <Button
                                                    hidden={
                                                        personnelProfileData.email ===
                                                            null ||
                                                        personnelProfileData?.email ===
                                                            '' ||
                                                        (personnelProfileData
                                                            ?.verify_at
                                                            ?.length || 0) > 0
                                                    }
                                                    colorScheme="rsSecondary"
                                                    size={'sm'}
                                                    isDisabled={
                                                        personnelProfileData.email ===
                                                            null ||
                                                        personnelProfileData?.email ===
                                                            '' ||
                                                        (personnelProfileData
                                                            ?.verify_at
                                                            ?.length || 0) > 0
                                                    }
                                                    onClick={
                                                        handleOpenAnAccount
                                                    }
                                                    isLoading={emailSending}
                                                >
                                                    開通帳號
                                                </Button>
                                            </Flex>
                                        </>
                                    )}
                                </GridItem>

                                <GridItem className="flex gap-2 items-center">
                                    <Text
                                        flex={'0 0 80px'}
                                        className="flex-none"
                                        textStyle={'semiTitle'}
                                    >
                                        班表顏色
                                    </Text>

                                    <Box
                                        h={'32px'}
                                        lineHeight={'32px'}
                                        bgColor={
                                            personnelProfileData.color ??
                                            '#868C6E'
                                        }
                                        textColor={
                                            personnelProfileData.color ===
                                                '#ffeb3b' ||
                                            personnelProfileData.color ===
                                                '#cddc39'
                                                ? '#000'
                                                : '#fff'
                                        }
                                        rounded={'4px'}
                                        px={2}
                                        onClick={
                                            editStatus
                                                ? toggleColorPicker
                                                : undefined
                                        }
                                        _hover={
                                            editStatus
                                                ? {
                                                      cursor: 'pointer',
                                                      shadow: 'md',
                                                  }
                                                : {
                                                      cursor: 'default',
                                                  }
                                        }
                                        pos={'relative'}
                                    >
                                        {personnelProfileData.color ??
                                            '#868C6E'}

                                        {showColorPicker && (
                                            <>
                                                <Box
                                                    top={0}
                                                    left={0}
                                                    w={'100%'}
                                                    h={'100%'}
                                                    pos={'fixed'}
                                                    zIndex={50}
                                                    onClick={toggleColorPicker}
                                                    bgColor={'#00000033'}
                                                >
                                                    {/* 魔法屏障 */}
                                                </Box>
                                                <Box
                                                    pos={'absolute'}
                                                    top={'calc(100% + 10px)'}
                                                    left={'0%'}
                                                    zIndex={60}
                                                    bgColor={'#fff'}
                                                    shadow={'md'}
                                                    p={2}
                                                    rounded={'15px'}
                                                >
                                                    <CirclePicker
                                                        color={
                                                            personnelProfileData.color ??
                                                            '#868C6E'
                                                        }
                                                        onChange={
                                                            handleChangeColor
                                                        }
                                                    />
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                </GridItem>

                                {/* 地址 */}
                                <GridItem
                                    className="flex items-center gap-2"
                                    colSpan={[1, 2]}
                                >
                                    <Text
                                        flex={'0 0 80px'}
                                        className="flex-none"
                                        textStyle={'semiTitle'}
                                    >
                                        地址
                                    </Text>
                                    {editStatus ? (
                                        <>
                                            <Flex
                                                placeItems={'center'}
                                                w={'100%'}
                                                h={'40px'}
                                                gap={3}
                                            >
                                                <Select
                                                    w={'120px'}
                                                    onChange={handleChangeInput}
                                                    name="city_uid"
                                                    value={
                                                        personnelProfileData.city_uid
                                                    }
                                                >
                                                    <option value="">
                                                        請選擇
                                                    </option>
                                                    {cityObjLists &&
                                                        Object.entries(
                                                            cityObjLists
                                                        ).map(
                                                            ([key, value]) => (
                                                                <option
                                                                    key={key}
                                                                    value={key}
                                                                >
                                                                    {value}
                                                                </option>
                                                            )
                                                        )}
                                                </Select>
                                                {cityPostalAreaObjLists &&
                                                    personnelProfileData.city_uid && (
                                                        <Select
                                                            w={'120px'}
                                                            onChange={
                                                                handleChangeInput
                                                            }
                                                            value={
                                                                personnelProfileData.area_uid
                                                            }
                                                            name="area_uid"
                                                        >
                                                            {cityPostalAreaObjLists &&
                                                                Object.entries(
                                                                    cityPostalAreaObjLists
                                                                ).map(
                                                                    ([
                                                                        key,
                                                                        value,
                                                                    ]) => (
                                                                        <option
                                                                            value={
                                                                                key
                                                                            }
                                                                            key={
                                                                                key
                                                                            }
                                                                        >
                                                                            {
                                                                                value
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                        </Select>
                                                    )}
                                                {personnelProfileData.area_uid &&
                                                    personnelProfileData.city_uid && (
                                                        <Input
                                                            flex={1}
                                                            onChange={
                                                                handleChangeInput
                                                            }
                                                            name="address"
                                                            maxLength={255}
                                                            value={
                                                                personnelProfileData?.address ??
                                                                ''
                                                            }
                                                            bgColor={'#fff'}
                                                        ></Input>
                                                    )}
                                            </Flex>
                                        </>
                                    ) : (
                                        <>
                                            <Flex
                                                placeItems={'center'}
                                                borderBottom={'1px solid #ccc'}
                                                w={'100%'}
                                                h={'40px'}
                                                paddingInline={'16px'}
                                            >
                                                {personnelProfileData.city_name}
                                                {personnelProfileData.area_name}
                                                {personnelProfileData.address}
                                            </Flex>
                                        </>
                                    )}
                                </GridItem>

                                {/* 到職日 */}
                                <GridItem className="flex items-center gap-[8px]">
                                    <Text
                                        flex={'0 0 80px'}
                                        className="flex-none"
                                        textStyle={'semiTitle'}
                                    >
                                        到職日
                                    </Text>
                                    {editStatus ? (
                                        <>
                                            <ROCDatePicker
                                                onChange={handleChangeDateIn}
                                                handleChangeDate={
                                                    handleChangeDateIn
                                                }
                                                selectedDate={selectedDateIn}
                                            ></ROCDatePicker>
                                        </>
                                    ) : (
                                        <>
                                            <Flex
                                                placeItems={'center'}
                                                borderBottom={'1px solid #ccc'}
                                                w={'100%'}
                                                h={'40px'}
                                                paddingInline={'16px'}
                                            >
                                                {personnelProfileData.date_in
                                                    ? convertGregorianToROC(
                                                          new Date(
                                                              personnelProfileData.date_in
                                                          )
                                                      )
                                                    : ''}
                                            </Flex>
                                        </>
                                    )}
                                </GridItem>

                                {/* 離職日 */}
                                <GridItem className="flex items-center gap-[8px]">
                                    <Text
                                        flex={'0 0 80px'}
                                        className="flex-none"
                                        textStyle={'semiTitle'}
                                    >
                                        離職日
                                    </Text>
                                    {editStatus ? (
                                        <>
                                            <ROCDatePicker
                                                onChange={handleChangeDateOut}
                                                handleChangeDate={
                                                    handleChangeDateOut
                                                }
                                                selectedDate={selectedDateOut}
                                            ></ROCDatePicker>
                                        </>
                                    ) : (
                                        <>
                                            <Flex
                                                placeItems={'center'}
                                                borderBottom={'1px solid #ccc'}
                                                w={'100%'}
                                                h={'40px'}
                                                paddingInline={'16px'}
                                            >
                                                {personnelProfileData.date_out
                                                    ? convertGregorianToROC(
                                                          new Date(
                                                              personnelProfileData.date_out
                                                          )
                                                      )
                                                    : ''}
                                            </Flex>
                                        </>
                                    )}
                                </GridItem>

                                <GridItem colSpan={[1, 2]}>
                                    <SimpleGrid columns={3} spacingX={5}>
                                        {/* 緊急聯絡人 */}
                                        <GridItem className="flex items-center gap-[8px]">
                                            <Text
                                                flex={'0 0 80px'}
                                                className="flex-none"
                                                textStyle={'semiTitle'}
                                            >
                                                緊急聯絡人
                                            </Text>
                                            {editStatus ? (
                                                <>
                                                    <Input
                                                        onChange={
                                                            handleChangeInput
                                                        }
                                                        name="emergence_contactor"
                                                        value={
                                                            personnelProfileData?.emergence_contactor ??
                                                            ''
                                                        }
                                                        maxLength={50}
                                                        bgColor={'#fff'}
                                                    ></Input>
                                                </>
                                            ) : (
                                                <>
                                                    <Flex
                                                        placeItems={'center'}
                                                        borderBottom={
                                                            '1px solid #ccc'
                                                        }
                                                        w={'100%'}
                                                        h={'40px'}
                                                        paddingInline={'16px'}
                                                    >
                                                        {
                                                            personnelProfileData.emergence_contactor
                                                        }
                                                    </Flex>
                                                </>
                                            )}
                                        </GridItem>

                                        {/* 關係 */}
                                        <GridItem className="flex items-center gap-[8px]">
                                            <Text
                                                w={'50px'}
                                                className="flex-none"
                                                textStyle={'semiTitle'}
                                            >
                                                關係
                                            </Text>
                                            {editStatus ? (
                                                <>
                                                    <Input
                                                        onChange={
                                                            handleChangeInput
                                                        }
                                                        name="emergence_relationship"
                                                        value={
                                                            personnelProfileData?.emergence_relationship ??
                                                            ''
                                                        }
                                                        maxLength={20}
                                                        bgColor={'#fff'}
                                                    ></Input>
                                                </>
                                            ) : (
                                                <>
                                                    <Flex
                                                        placeItems={'center'}
                                                        borderBottom={
                                                            '1px solid #ccc'
                                                        }
                                                        w={'100%'}
                                                        h={'40px'}
                                                        paddingInline={'16px'}
                                                    >
                                                        {
                                                            personnelProfileData.emergence_relationship
                                                        }
                                                    </Flex>
                                                </>
                                            )}
                                        </GridItem>

                                        {/* 電話 */}
                                        <GridItem className="flex items-center gap-[8px]">
                                            <Text
                                                w={'50px'}
                                                className="flex-none"
                                                textStyle={'semiTitle'}
                                            >
                                                電話
                                            </Text>
                                            {editStatus ? (
                                                <>
                                                    <Input
                                                        onChange={
                                                            handleChangeInput
                                                        }
                                                        name="emergence_phone"
                                                        value={
                                                            personnelProfileData?.emergence_phone ??
                                                            ''
                                                        }
                                                        maxLength={20}
                                                        bgColor={'#fff'}
                                                    ></Input>
                                                </>
                                            ) : (
                                                <>
                                                    <Flex
                                                        placeItems={'center'}
                                                        borderBottom={
                                                            '1px solid #ccc'
                                                        }
                                                        w={'100%'}
                                                        h={'40px'}
                                                        paddingInline={'16px'}
                                                    >
                                                        {
                                                            personnelProfileData.emergence_phone
                                                        }
                                                    </Flex>
                                                </>
                                            )}
                                        </GridItem>
                                    </SimpleGrid>
                                </GridItem>
                            </SimpleGrid>
                            {/* 執照 */}
                            <Box
                                bgColor={'#f7f7f7'}
                                p={'16px'}
                                mt={'8px'}
                                mx={'16px'}
                                rounded={'8px'}
                            >
                                {/* 新增執照 */}

                                <Flex
                                    gap={'16px'}
                                    py={'8px'}
                                    px={'16px'}
                                    w={'100%'}
                                    rounded={'4px'}
                                    bgColor={'#E9E5DA'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <Text textStyle={'semiTitle'}>
                                        新增執照
                                    </Text>
                                    <Select
                                        disabled={!editStatus}
                                        flex={1}
                                        size={'sm'}
                                        name=""
                                        value={selectedLicenseTypeSecondName}
                                        bgColor={'white'}
                                        onChange={handleChangeLicenseSecond}
                                        isDisabled={
                                            !personnelProfileData.identity_code ||
                                            personnelProfileData.identity_code ===
                                                'iden_administrative'
                                        }
                                    >
                                        <option value="" disabled>
                                            請選擇
                                        </option>
                                        {licenseTypeSecondLists &&
                                            licenseTypeSecondLists.map(item => (
                                                <option key={item.code}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Select>

                                    <Select
                                        flex={1}
                                        size={'sm'}
                                        bgColor={'white'}
                                        value={selectedLicenseTypeThirdName}
                                        onChange={handleChangeLicenseThird}
                                        isDisabled={
                                            !personnelProfileData.identity_code ||
                                            licenseTypeThirdLists?.length ===
                                                0 ||
                                            licenseTypeSecondLists?.length === 0
                                        }
                                    >
                                        <option value="" disabled>
                                            請選擇
                                        </option>
                                        {licenseTypeThirdLists &&
                                            licenseTypeThirdLists.map(item => (
                                                <option key={item.code}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Select>
                                    <Button
                                        colorScheme="rsPrimary"
                                        size={'sm'}
                                        onClick={handleAddLicense}
                                        isDisabled={
                                            !personnelProfileData.identity_code ||
                                            !selectedLicenseTypeSecondName ||
                                            (licenseTypeThirdLists?.length !==
                                                0 &&
                                                !selectedLicenseTypeThirdName)
                                        }
                                    >
                                        加入
                                    </Button>
                                </Flex>
                                {/* Header */}
                                <Flex
                                    borderBottom={`2px solid #868C6E`}
                                    py={'8px'}
                                    px={'16px'}
                                    gap={'16px'}
                                >
                                    <Text
                                        flex={'0 1 120px'}
                                        color={'rsPrimary.500'}
                                        textStyle={'semiTitle'}
                                    >
                                        類型
                                    </Text>
                                    <Text
                                        flex={'0 1 120px'}
                                        textStyle={'semiTitle'}
                                    >
                                        建立日期
                                    </Text>
                                    <Text
                                        flex={'0 1 120px'}
                                        textStyle={'semiTitle'}
                                    >
                                        到期日期
                                    </Text>
                                    <Text
                                        flex={'1 1 auto'}
                                        textStyle={'semiTitle'}
                                        whiteSpace={'nowrap'}
                                    >
                                        執照證號
                                    </Text>

                                    <Box flex={'0 1 120px'}>
                                        <Text
                                            opacity={editStatus ? 1 : 0}
                                            textStyle={'semiTitle'}
                                            textAlign={'center'}
                                        >
                                            刪除
                                        </Text>
                                    </Box>
                                </Flex>

                                {/* Content */}
                                <Box
                                    maxH={`${window.innerHeight - 702}px`}
                                    overflowY={'scroll'}
                                    px={'16px'}
                                >
                                    {license &&
                                        license.map((item, index) => (
                                            <>
                                                <Flex
                                                    gap={'16px'}
                                                    borderBottom={`1px solid #ccc`}
                                                    h={'50px'}
                                                    alignItems={'center'}
                                                    key={index}
                                                    _last={{
                                                        borderBottom: 'none',
                                                    }}
                                                >
                                                    {/* 類型 */}
                                                    <Text
                                                        flex={'0 0 120px'}
                                                        overflow={'hidden'}
                                                    >
                                                        {item.license_name}
                                                    </Text>

                                                    {/* 建立日期 */}
                                                    <Box flex={'0 0 120px'}>
                                                        <Text
                                                            textColor={
                                                                'rsDanger.500'
                                                            }
                                                        >
                                                            {item.start_date
                                                                ? convertGregorianToROC(
                                                                      new Date(
                                                                          item.start_date
                                                                      )
                                                                  )
                                                                : ''}
                                                        </Text>
                                                    </Box>

                                                    {/* 到期日期 */}
                                                    <Box flex={'0 0 120px'}>
                                                        {editStatus ? (
                                                            <>
                                                                <ROCDatePicker
                                                                    onChange={date =>
                                                                        handleChangeLicenseEndDate(
                                                                            date,
                                                                            index
                                                                        )
                                                                    }
                                                                    handleChangeDate={date => {}}
                                                                    selectedDate={
                                                                        item.end_date
                                                                            ? new Date(
                                                                                  item.end_date
                                                                              )
                                                                            : null
                                                                    }
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Text
                                                                    flex={
                                                                        '0 0 120px'
                                                                    }
                                                                    textColor={
                                                                        'rsDanger.500'
                                                                    }
                                                                >
                                                                    {item.end_date
                                                                        ? convertGregorianToROC(
                                                                              new Date(
                                                                                  item.end_date
                                                                              )
                                                                          )
                                                                        : ''}
                                                                </Text>
                                                            </>
                                                        )}
                                                    </Box>
                                                    {editStatus ? (
                                                        <>
                                                            <Input
                                                                rounded={'md'}
                                                                size={'md'}
                                                                bgColor={'#fff'}
                                                                flex={
                                                                    '1 1 auto'
                                                                }
                                                                name="license_no"
                                                                maxLength={50}
                                                                value={
                                                                    item.license_no
                                                                }
                                                                onChange={event =>
                                                                    handleChangeLicenseInput(
                                                                        event,
                                                                        index
                                                                    )
                                                                }
                                                            ></Input>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Text
                                                                flex={
                                                                    '1 1 auto'
                                                                }
                                                            >
                                                                {
                                                                    item.license_no
                                                                }
                                                            </Text>
                                                        </>
                                                    )}

                                                    <Box flex={'0 0 120px'}>
                                                        <Box
                                                            opacity={
                                                                editStatus
                                                                    ? 1
                                                                    : 0
                                                            }
                                                            onClick={() =>
                                                                handleDeleteLicense(
                                                                    item.data_id ??
                                                                        '',
                                                                    index
                                                                )
                                                            }
                                                            _hover={{
                                                                cursor: 'pointer',
                                                                filter: 'brightness(1.2)',
                                                            }}
                                                            textColor={
                                                                'rsDanger.500'
                                                            }
                                                        >
                                                            <HeroIcons name="delete-fill"></HeroIcons>
                                                        </Box>
                                                    </Box>
                                                </Flex>
                                            </>
                                        ))}
                                </Box>
                            </Box>
                            <Flex
                                pos={'absolute'}
                                bottom={'0%'}
                                right={'0%'}
                                zIndex={'40'}
                                alignContent={'flex-end'}
                                justifyContent={'flex-end'}
                                gap={'8px'}
                                bg={'#fff'}
                                w={'100%'}
                                h={'56px'}
                                p={'8px 16px'}
                            >
                                {editStatus ? (
                                    <>
                                        <Button
                                            colorScheme="rsSteel"
                                            onClick={handleCancel}
                                        >
                                            取消
                                        </Button>
                                        <Button
                                            colorScheme="rsSecondary"
                                            onClick={handleSave}
                                        >
                                            儲存
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            colorScheme="rsWarning"
                                            onClick={handleEdit}
                                        >
                                            編輯
                                        </Button>
                                    </>
                                )}
                            </Flex>
                            {/* 人員權限展開 */}
                            {permissionDrawerStatus && (
                                <>
                                    <Flex
                                        bgColor={'#fff'}
                                        pos={'absolute'}
                                        inset={'0'}
                                        zIndex={50}
                                        direction={'column'}
                                    >
                                        <Flex
                                            px={'16px'}
                                            py={'8px'}
                                            align="center"
                                            justify={'space-between'}
                                            bg={'rsPrimary.500'}
                                            textColor={'#fff'}
                                        >
                                            <Text fontSize={'lg'}>
                                                權限管理
                                            </Text>
                                            <Box
                                                onClick={
                                                    handleClosePermissionDashBoard
                                                }
                                                cursor={'pointer'}
                                                _hover={{
                                                    filter: 'brightness(0.9)',
                                                }}
                                            >
                                                <HeroIcons name="return-w"></HeroIcons>
                                            </Box>
                                        </Flex>

                                        <Box
                                            pos={'fixed'}
                                            bottom={'20px'}
                                            right={'20px'}
                                        >
                                            <Button
                                                colorScheme="rsWarning"
                                                onClick={
                                                    handleClosePermissionDashBoard
                                                }
                                            >
                                                返回
                                            </Button>
                                        </Box>

                                        <Box
                                            w={'100%'}
                                            h={'100%'}
                                            overflow={'scroll'}
                                            p={'16px'}
                                        >
                                            {/* 群組列表 */}
                                            <Flex
                                                gap={'16px'}
                                                rounded={'4px'}
                                                bgColor={'#E9E5DA'}
                                                px={'16px'}
                                                py={'8px'}
                                                mb={'8px'}
                                            >
                                                <Text
                                                    flex={'0 0 auto'}
                                                    textStyle={'semiTitle'}
                                                    whiteSpace={'nowrap'}
                                                    h={'40px'}
                                                    lineHeight={'40px'}
                                                >
                                                    加入群組
                                                </Text>
                                                {/* 如果checkbox要吃到被選取要使用defaultValue */}
                                                <CheckboxGroup
                                                    colorScheme="rsPrimary"
                                                    defaultValue={
                                                        selectedDataIds
                                                    }
                                                    isDisabled={!editStatus}
                                                >
                                                    <Flex
                                                        gap={'8px'}
                                                        wrap={'wrap'}
                                                    >
                                                        {groupLists&&groupLists.map(
                                                            (item, index) => (
                                                                <Checkbox
                                                                    key={
                                                                        item.data_id
                                                                    }
                                                                    value={
                                                                        item.data_id
                                                                    }
                                                                    bgColor={
                                                                        '#F7F7F7'
                                                                    }
                                                                    // defaultChecked={selectedDataIds.includes(item.data_id??'')}
                                                                    // isChecked={selectedDataIds.includes(item.data_id ?? '') ? true: false}
                                                                    isChecked={
                                                                        true
                                                                    }
                                                                    px={3}
                                                                    py={2}
                                                                    rounded={
                                                                        '4px'
                                                                    }
                                                                    onChange={
                                                                        handleChangeGroupLists
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </Checkbox>
                                                            )
                                                        )}
                                                    </Flex>
                                                </CheckboxGroup>
                                            </Flex>

                                            <Box
                                                bgColor={'gray.50'}
                                                rounded={'4px'}
                                                p={5}
                                            >
                                                {/* 一鍵套用區 */}
                                                <Box
                                                    w={'fit-content'}
                                                    rounded={'4px'}
                                                    bgColor={'#E9E5DA'}
                                                    px={'16px'}
                                                    py={'8px'}
                                                >
                                                    <Flex
                                                        gap={5}
                                                        alignItems={'center'}
                                                    >
                                                        <Flex
                                                            gap={2}
                                                            align={'center'}
                                                        >
                                                            <Box
                                                                className={
                                                                    editStatus &&
                                                                    radioStatus ==
                                                                        null
                                                                        ? 'heartbeat'
                                                                        : ''
                                                                }
                                                            >
                                                                {/* 手手 icon */}
                                                                <HeroIcons name="cursor-arrow"></HeroIcons>
                                                            </Box>
                                                            <Heading
                                                                textColor={
                                                                    'rsPrimary.500'
                                                                }
                                                                size={'sm'}
                                                                fontWeight={
                                                                    'semibold'
                                                                }
                                                                whiteSpace={
                                                                    'nowrap'
                                                                }
                                                            >
                                                                一鍵套用
                                                            </Heading>
                                                        </Flex>

                                                        <Button
                                                            size={'sm'}
                                                            value={'V'}
                                                            onClick={
                                                                editStatus
                                                                    ? handleClickStatus
                                                                    : undefined
                                                            }
                                                            colorScheme="rsPrimary"
                                                            className={
                                                                radioStatus ===
                                                                    'V' &&
                                                                editStatus
                                                                    ? 'heartbeat'
                                                                    : ''
                                                            }
                                                        >
                                                            <Text className="flex items-center gap-[8px]">
                                                                <GroupViewIcon />
                                                                <span className=" font-semibold">
                                                                    僅檢視
                                                                </span>
                                                            </Text>
                                                        </Button>

                                                        <Button
                                                            size={'sm'}
                                                            value={'E'}
                                                            onClick={
                                                                editStatus
                                                                    ? handleClickStatus
                                                                    : undefined
                                                            }
                                                            colorScheme="rsWarning"
                                                            className={
                                                                radioStatus ===
                                                                    'E' &&
                                                                editStatus
                                                                    ? 'heartbeat'
                                                                    : ''
                                                            }
                                                            textColor={
                                                                'rsPrimary.500'
                                                            }
                                                        >
                                                            <Text className="flex items-center gap-[8px]">
                                                                <GroupEditIcon />
                                                                <span className=" font-semibold">
                                                                    可編輯
                                                                </span>
                                                            </Text>
                                                        </Button>

                                                        <Button
                                                            size={'sm'}
                                                            value={'D'}
                                                            onClick={
                                                                editStatus
                                                                    ? handleClickStatus
                                                                    : undefined
                                                            }
                                                            colorScheme="rsDanger"
                                                            className={
                                                                radioStatus ===
                                                                    'D' &&
                                                                editStatus
                                                                    ? 'heartbeat'
                                                                    : ''
                                                            }
                                                        >
                                                            <Text className="flex items-center gap-[8px]">
                                                                <GroupDenyIcon />
                                                                <span className=" font-semibold">
                                                                    禁止使用
                                                                </span>
                                                            </Text>
                                                        </Button>
                                                    </Flex>
                                                </Box>

                                                {/* Heading */}
                                                <Flex
                                                    mt={'16px'}
                                                    borderBottom={
                                                        '2px solid #868C6E'
                                                    }
                                                    opacity={
                                                        editStatus ? '1' : '0.8'
                                                    }
                                                >
                                                    <Heading
                                                        px={2}
                                                        pb={2}
                                                        size={'sm'}
                                                        textStyle={'semiTitle'}
                                                        flex={'0 0 200px'}
                                                    >
                                                        權限項目
                                                    </Heading>
                                                    <Heading
                                                        px={2}
                                                        pb={2}
                                                        size={'sm'}
                                                        textStyle={'semiTitle'}
                                                        flex={1}
                                                    >
                                                        權限內容
                                                    </Heading>
                                                </Flex>

                                                {newNavigation &&
                                                    newNavigation.map(
                                                        (item, index) => (
                                                            <>
                                                                <Flex
                                                                    pos="relative"
                                                                    className="text-black"
                                                                    key={index}
                                                                    py={'8px'}
                                                                >
                                                                    {item.items ? (
                                                                        <>
                                                                            {/* 渲染第一層 */}
                                                                            <Flex
                                                                                flex={
                                                                                    '0 0 200px'
                                                                                }
                                                                                gap={
                                                                                    '8px'
                                                                                }
                                                                                h={
                                                                                    '40px'
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    w={
                                                                                        '30px'
                                                                                    }
                                                                                    h={
                                                                                        '30px'
                                                                                    }
                                                                                    border={
                                                                                        '1px solid #aaa'
                                                                                    }
                                                                                    onClick={() =>
                                                                                        editStatus &&
                                                                                        radioStatus !==
                                                                                            null
                                                                                            ? handleClickBtn(
                                                                                                  item.name,
                                                                                                  item.key
                                                                                              )
                                                                                            : undefined
                                                                                    }
                                                                                    colorScheme={getButtonColor(
                                                                                        item.status
                                                                                    )}
                                                                                ></Button>
                                                                                <Text
                                                                                    textStyle={
                                                                                        'semiTitle'
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </Text>
                                                                            </Flex>

                                                                            {/* 渲染第二層 */}
                                                                            <Flex
                                                                                flex={
                                                                                    '1 1 auto'
                                                                                }
                                                                                wrap={
                                                                                    'wrap'
                                                                                }
                                                                                gap={
                                                                                    '8px'
                                                                                }
                                                                            >
                                                                                {item.items && item.items.map(
                                                                                    (
                                                                                        subItem,
                                                                                        subIndex
                                                                                    ) => (
                                                                                        <>
                                                                                            <Button
                                                                                                size={
                                                                                                    'sm'
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    editStatus
                                                                                                        ? handleClickChildBtn(
                                                                                                              subItem.name,
                                                                                                              subItem.key
                                                                                                          )
                                                                                                        : undefined
                                                                                                }
                                                                                                colorScheme={getButtonColor(
                                                                                                    subItem.status
                                                                                                )}
                                                                                            >
                                                                                                <Flex
                                                                                                    align={
                                                                                                        'center'
                                                                                                    }
                                                                                                    gap={
                                                                                                        2
                                                                                                    }
                                                                                                    textColor={getTextColor(
                                                                                                        subItem.status
                                                                                                    )}
                                                                                                >
                                                                                                    <span>
                                                                                                        {subItem.status ===
                                                                                                        '' ? (
                                                                                                            <>
                                                                                                                <GroupViewIcon></GroupViewIcon>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>

                                                                                                            </>
                                                                                                        )}
                                                                                                        {subItem.status ===
                                                                                                        'D' ? (
                                                                                                            <>
                                                                                                                <GroupDenyIcon></GroupDenyIcon>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>

                                                                                                            </>
                                                                                                        )}
                                                                                                        {subItem.status ===
                                                                                                        'E' ? (
                                                                                                            <>
                                                                                                                <GroupEditIcon></GroupEditIcon>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>

                                                                                                            </>
                                                                                                        )}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {
                                                                                                            subItem.name
                                                                                                        }
                                                                                                    </span>
                                                                                                </Flex>
                                                                                            </Button>
                                                                                        </>
                                                                                    )
                                                                                )}
                                                                            </Flex>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {/* 渲染無子層的第一層 */}
                                                                            <Flex
                                                                                flex={
                                                                                    '0 0 200px'
                                                                                }
                                                                                gap={
                                                                                    '8px'
                                                                                }
                                                                                h={
                                                                                    '40px'
                                                                                }
                                                                            >
                                                                                <Button
                                                                                    w={
                                                                                        '30px'
                                                                                    }
                                                                                    h={
                                                                                        '30px'
                                                                                    }
                                                                                    border={
                                                                                        '1px solid #aaa'
                                                                                    }
                                                                                    onClick={() =>
                                                                                        editStatus &&
                                                                                        radioStatus
                                                                                            ? handleClickBtn(
                                                                                                  item.name,
                                                                                                  item.key
                                                                                              )
                                                                                            : undefined
                                                                                    }
                                                                                    colorScheme={getButtonColor(
                                                                                        item.status
                                                                                    )}
                                                                                ></Button>

                                                                                <Text
                                                                                    textStyle={
                                                                                        'semiTitle'
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </Text>
                                                                            </Flex>
                                                                        </>
                                                                    )}
                                                                </Flex>
                                                            </>
                                                        )
                                                    )}
                                            </Box>
                                        </Box>
                                    </Flex>
                                </>
                            )}
                        </Flex>
                    </>
                )}
                {drawerOpenStatus && editStatus && (
                    <>
                        <Box
                            pos={'absolute'}
                            inset={'-20px -20px -16px -20px'}
                            bgColor={'rgba(0, 0, 0, 0.1)'}
                            ref={bgRef}
                            zIndex={20}
                        ></Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default PersonnelProfileSettingTable;
