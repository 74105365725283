import axios from "axios";
// import RsApiService from './RSApiService'


type cardStatusProps = {
    cardType: string
}
// Rs讀取卡片狀態
export const RshisGetCardStatusService = async (data: cardStatusProps) => {
    try {
        const response = await axios.post('http://localhost:23752/cshis/RshisGetCardStatus', data,{
            headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json',
            },
        });
        
        console.log(response.data, 'Rs讀取卡片狀態');
        return response.data;
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.error('Error:', error.message);
        } else {
            console.error('An unknown error occurred');
        }
    }
};
