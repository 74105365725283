import React, { useEffect, useState } from 'react';
import { Box, Flex, Center, Text } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { PatientInfo } from './PatientInfo';
import { SuggestionClinic } from './SuggestionClinic';
import { Purpose } from './Purpose';
import { Diagnosis } from './Diagnosis';
import { Object } from './Object';
import { ClinicInfo } from './ClinicInfo';
import { Examination } from './Examination';
import { Plan } from './Plan';
import { Allergy } from './Allergy';
import { DoctorNotice } from './DoctorNotice';
import { Others } from './Others';
import { useIsReferralOpenStatus } from 'contexts/TreatmentManagement/BillingProcess';

const FlexItems50 = {
    flexBasis: 'calc(50% - 4px)',
    p: '8px 16px',
    bg: '#F5F5F5',
    rounded: 'lg',
};
const FlexItems25 = {
    flexBasis: 'calc(25% - 8px)',
    p: '8px 16px',
    bg: '#F5F5F5',
    rounded: 'lg',
    overflowX: 'hidden',
};

export const CreateReferral = () => {
    const { isReferralOpenStatus, setIsReferralOpenStatus } =
        useIsReferralOpenStatus();

    return (
        <>
            <Center zIndex={70} bg={'#000000aa'} pos={'fixed'} inset={0}>
                <Box
                    pos={'absolute'}
                    w={'calc(100vw - 32px)'}
                    h={'auto'}
                    bg={'#fff'}
                    rounded={'lg'}
                    border={'2px solid #646B48'}
                    pb={'16px'}
                >
                    <Flex
                        justify={'space-between'}
                        align={'center'}
                        p={'4px 16px'}
                    >
                        <Text textStyle={'semiTitle'}>轉診單</Text>
                        <Box
                            _hover={{ cursor: 'pointer' }}
                            onClick={() => {
                                setIsReferralOpenStatus(prev => !prev);
                            }}
                        >
                            <HeroIcons name="xmark"></HeroIcons>
                        </Box>
                    </Flex>

                    <Flex gap={'8px'} flexWrap={'wrap'} px={'16px'}>
                        <Box sx={FlexItems50}>
                            <PatientInfo />
                        </Box>
                        <Box sx={FlexItems50}>
                            <SuggestionClinic />
                        </Box>
                        <Box sx={FlexItems50}>
                            <Purpose />
                        </Box>
                        <Box sx={FlexItems25}>
                            <Diagnosis />
                        </Box>
                        <Box sx={FlexItems25}>
                            <Object />
                        </Box>
                        <Box sx={FlexItems50}>
                            <ClinicInfo />
                        </Box>
                        <Box sx={FlexItems50}>
                            <Examination />
                        </Box>
                        <Box sx={FlexItems50}>
                            <Plan />
                        </Box>
                        <Flex
                            direction={'column'}
                            gap={'8px'}
                            flexBasis={'calc(50% - 4px)'}
                        >
                            <Box sx={FlexItems50}>
                                <Allergy />
                            </Box>
                            <Box sx={FlexItems50}>
                                <DoctorNotice />
                            </Box>
                            <Box>
                                <Others />
                            </Box>
                        </Flex>
                    </Flex>
                </Box>
            </Center>
        </>
    );
};
