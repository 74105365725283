import { create } from 'zustand';

interface DeclareListTabIndexState {
    tabIndex: number;
    setTabIndex: (index: number) => void;
}

export const useDeclareListTabIndexStore = create<DeclareListTabIndexState>(
    set => ({
        tabIndex: 0,
        setTabIndex: index => set({ tabIndex: index }),
    })
);
