import React, { useEffect, useState } from 'react';
import NumberingRuleTable from '../../components/DataSettings/NumberingRuleComponents/NumberingRuleTable';

const NumberingRulePage: React.FC = () => {
    const [bgOpenStatus, setBgOpenStatue] = useState(false);
    const handleOpen = () => {
        setBgOpenStatue(true);
    };
    const handleClose = () => {
        setBgOpenStatue(false);
    };
    return (
        <>
            <NumberingRuleTable />
        </>
    );
};

export default NumberingRulePage;
