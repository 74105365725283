import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
type summaryProps = {
    name?: string;
    num: number;
};
export const Statistics = () => {
    // const summary: summaryProps[] = [
    //     {
    //         name: '就醫紀錄*',
    //         num: 8,
    //     },
    //     {
    //         name: '01西醫*',
    //         num: 8,
    //     },
    //     {
    //         name: '09其他*',
    //         num: 8,
    //     },
    //     {
    //         name: '預防接種*',
    //         num: 0,
    //     },
    //     {
    //         name: '就醫紀錄*',
    //         num: 8,
    //     },
    //     {
    //         name: '01西醫*',
    //         num: 8,
    //     },
    //     {
    //         name: '09其他*',
    //         num: 8,
    //     },
    //     {
    //         name: '預防接種*',
    //         num: 0,
    //     },
    //     {
    //         name: '就醫紀錄*',
    //         num: 8,
    //     },
    //     {
    //         name: '01西醫*',
    //         num: 8,
    //     },
    //     {
    //         name: '09其他*',
    //         num: 8,
    //     },
    //     {
    //         name: '預防接種*',
    //         num: 0,
    //     },
    // ];
    const summary: summaryProps[] = [{ num: 0, name: '' }];
    return (
        <>
            <Text textStyle={'semiTitle'} fontSize={'xl'}>
                統計
            </Text>
            <Box
                pos={'relative'}
                overflowY={'scroll'}
                h={`calc(${window.innerHeight}px - 584px)`}
            >
                <Flex
                    bg={'#D9D9D9'}
                    align={'center'}
                    pos={'sticky'}
                    top={0}
                    left={0}
                    w={'100%'}
                    zIndex={10}
                >
                    <Box flex={'1 1 auto'}>
                        <Text
                            textColor={'rsPrimary.500'}
                            fontWeight={'semibold'}
                            p={'4px 8px'}
                        >
                            項目
                        </Text>
                    </Box>
                    <Box flex={'0 0 auto'}>
                        <Text
                            textColor={'rsPrimary.500'}
                            fontWeight={'semibold'}
                            p={'4px 8px'}
                            textAlign={'right'}
                        >
                            數量
                        </Text>
                    </Box>
                </Flex>
                {summary &&
                    summary.map((item, index) => (
                        <Flex key={index} borderBottom={'1px solid #BFC6A2'}>
                            <Box flex={'1 1 auto'}>
                                <Text p={'4px 8px'}>{item.name}</Text>
                            </Box>
                            <Box flex={'0 0 auto'}>
                                <Text p={'4px 8px'} textAlign={'right'}>
                                    {item.num}
                                </Text>
                            </Box>
                        </Flex>
                    ))}
            </Box>

            <Box py={'4px'}>
                <Flex bg={'#BFC6A2'} align={'center'} rounded={'lg'}>
                    <Box flex={'1 1 auto'}>
                        <Text
                            textColor={'rsPrimary.500'}
                            fontWeight={'semibold'}
                            p={'4px 8px'}
                        >
                            合計
                        </Text>
                    </Box>
                    <Box flex={'0 0 auto'}>
                        <Text
                            textColor={'rsPrimary.500'}
                            fontWeight={'semibold'}
                            p={'4px 8px'}
                            textAlign={'right'}
                        >
                            {summary &&
                                summary.reduce(
                                    (acc, item) => acc + item.num,
                                    0
                                )}{' '}
                        </Text>
                    </Box>
                </Flex>
            </Box>

            <Box py={'4px'}>
                <Flex bg={'#EFEFEF'} align={'center'} rounded={'lg'}>
                    <Box flex={'1 1 auto'}>
                        <Text
                            textColor={'rsPrimary.500'}
                            fontWeight={'semibold'}
                            p={'4px 8px'}
                        >
                            作廢
                        </Text>
                    </Box>
                    <Box flex={'0 0 auto'}>
                        <Text
                            textColor={'rsPrimary.500'}
                            fontWeight={'semibold'}
                            p={'4px 8px'}
                            textAlign={'right'}
                        ></Text>
                    </Box>
                </Flex>
            </Box>
        </>
    );
};
