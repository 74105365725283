import { Table, Thead, Tbody, Tr, Td, Th } from '@chakra-ui/react';
import {
    Box,
    Flex,
    Text,
    Input,
    Button,
    Tabs,
    TabList,
    Tab,
    TabIndicator,
    Select,
} from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';
import { useWeekRange } from 'contexts/WeekRangeContext';
import moment from 'moment';
import Swal from 'sweetalert2';

import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
// API
import { callClinicRoomService } from 'service/clinicRoomService';
// import { callClinicRoomService } from 'service/callClinicRoomService';
import { callShiftService } from 'service/shiftService';
// import { callShiftService } from 'service/callShiftService';
import { postClinicScheduleService } from 'service/clinicScheduleService';
// import { postClinicScheduleService } from 'service/postClinicScheduleService';
import { callClinicScheduleService } from 'service/clinicScheduleService';
// import { callClinicScheduleService } from 'service/callClinicScheduleService';
import { deleteClinicScheduleService } from 'service/clinicScheduleService';
// import { deleteClinicScheduleService } from 'service/deleteClinicScheduleService';
// 醫師lists
// import { callMedicalStaffListService } from 'service/callMedicalStaffListService';
import { callMedicalStaffListService } from 'service/medicalStaffListService';
// 序號規則lists
// import { callRegSeqSettingService } from 'service/callRegSeqSettingService';
import { callRegSeqSettingService } from 'service/regSeqSettingService';
// 人員資料
// import { callMedicalStaffSpecService } from 'service/callMedicalStaffSpecService';

import { updatedDataProps } from 'service/clinicScheduleService';

interface clinicRoomListsProps {
    id: string;
    name: string;
}
interface shiftListsProps {
    data_id: string;
    name: string;
    start_time: string;
    end_time: string;
}

interface scheduleLists {
    data_id: string;
    date: string;
    end_date: string;
    if_top: boolean;
    room_name: string;
    shift_name: string;
    staff_name: null | string;
    start_date: string;
    subject_name: null | string;
    weekday: number;
    color: string;
    seq_setting_uid: string;
}
interface staffListsProps {
    name: string;
    data_id: string;
    identity: string;
    color: string;
}
interface DoctorSchedule {
    doctorId: string;
    doctorName: string;
}
interface schedulesProps {
    shift: string;
    room: string;
    color: (string | null)[];
    days: (string | null)[];
    data_ids: (string | null)[];
    doctors: DoctorSchedule[][];
    seqUid: (string | null)[];
}
interface DetailedSchedule {
    shiftId: string;
    roomId: string;
    doctorId: string | null;
    doctorName: string | null;
}

interface ruleListsProps {
    data_id: string;
    rule_name: string;
}

const WorkingScheduleBoard = () => {
    const token = localStorage.getItem('token');
    const { weekRange, setWeekRange, selectedDate, setSelectedDate } =
        useWeekRange();
    const [clinicRoomLists, setClinicRoomLists] = useState<
        clinicRoomListsProps[]
    >([]);
    const [staffLists, setStaffLists] = useState<staffListsProps[]>([]);
    const [ruleLists, setRuleLists] = useState<ruleListsProps[]>([]);
    const [shiftLists, setShiftLists] = useState<shiftListsProps[]>([]);

    const callClinicRoomAPI = async () => {
        if (token) {
            try {
                const result = await callClinicRoomService(token);
                if (result.result === 'success') {
                    setClinicRoomLists(result.lists);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    const callShiftAPI = async () => {
        if (token) {
            try {
                const result = await callShiftService(token);
                if (result.result === 'success') {
                    setShiftLists(result.lists);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    useEffect(() => {
        callClinicRoomAPI();
    }, []);
    useEffect(() => {
        callShiftAPI();
    }, []);
    useEffect(() => {
        callClinicScheduleAPI();
    }, []);
    const initialSchedules = shiftLists.flatMap(shift =>
        clinicRoomLists.map(room => ({
            shift: shift.name,
            room: room.name,
            color: new Array(7).fill(null),
            days: new Array(7).fill(null),
            data_ids: new Array(7).fill(null),
            doctors: new Array(7).fill([]),
            seqUid: new Array(7).fill(null),
        }))
    );

    const postClinicScheduleAPI = async (finalData: updatedDataProps) => {
        if (token) {
            try {
                const result = await postClinicScheduleService(
                    token,
                    finalData
                );
                if (result.result === 'success') {
                    callClinicScheduleAPI();
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    //  刪除特定班表
    const deleteClinicScheduleAPI = async (
        data_id: string,
        date: string,
        if_general: string
    ) => {
        if (token) {
            let data = {
                data_id: data_id,
                start_date: date,
                if_general: if_general,
            };
            try {
                const result = await deleteClinicScheduleService(token, data);
                if (result.result === 'success') {
                    Swal.fire({
                        icon: 'success',
                        title: '刪除成功',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

    const [openBoardStatus, setOpenBoardStatus] = useState<boolean>(false);
    const [schedules, setSchedules] = useState(initialSchedules);

    const callClinicScheduleAPI = async () => {
        const date = moment(selectedDate).format('YYYY-MM-DD');

        if (token && date) {
            try {
                const result = await callClinicScheduleService(token, date);
                if (result.result === 'success') {
                    console.log(result, '先看');
                    let newSchedules: schedulesProps[] = [];

                    Object.values(result.lists).forEach(item => {
                        const scheduleItem = item as scheduleLists;
                        const scheduleKey = `${scheduleItem.room_name}_${scheduleItem.shift_name}`;
                        const doctorName = scheduleItem.staff_name
                            ? scheduleItem.staff_name
                            : '-';
                        const doctorColor = scheduleItem.color;
                        const doctorNumbering = scheduleItem.seq_setting_uid;
                        let scheduleIndex = newSchedules.findIndex(
                            s => `${s.room}_${s.shift}` === scheduleKey
                        );

                        if (scheduleIndex === -1) {
                            const newSchedule: schedulesProps = {
                                shift: scheduleItem.shift_name,
                                room: scheduleItem.room_name,
                                color: Array(7).fill(null),
                                days: Array(7).fill('-'),
                                data_ids: Array(7).fill(null),
                                doctors: Array(7).fill([]),
                                seqUid: Array(7).fill(null),
                            };

                            newSchedule.days[scheduleItem.weekday] = doctorName;
                            newSchedule.data_ids[scheduleItem.weekday] =
                                scheduleItem.data_id;
                            newSchedule.color[scheduleItem.weekday] =
                                doctorColor;
                            newSchedule.seqUid[scheduleItem.weekday] =
                                doctorNumbering;

                            newSchedules.push(newSchedule);
                            // console.log(doctorName, scheduleItem.data_id, scheduleItem.color, scheduleItem.seq_setting_uid, '真的操你媽')
                        } else {
                            // console.log(doctorName, scheduleItem.data_id, scheduleItem.color, scheduleItem.seq_setting_uid, '真的操你媽')
                            newSchedules[scheduleIndex].days[
                                scheduleItem.weekday
                            ] = doctorName;
                            newSchedules[scheduleIndex].data_ids[
                                scheduleItem.weekday
                            ] = scheduleItem.data_id;

                            newSchedules[scheduleIndex].color[
                                scheduleItem.weekday
                            ] = scheduleItem.color;

                            newSchedules[scheduleIndex].seqUid[
                                scheduleItem.weekday
                            ] = scheduleItem.seq_setting_uid;
                        }
                    });

                    setSchedules(newSchedules);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    useEffect(() => {
        callMedicalStaffListAPI();
    }, []);
    useEffect(() => {
        console.log('schedules: ', schedules);
    }, [schedules]);

    const callMedicalStaffListAPI = async () => {
        if (token) {
            try {
                const result = await callMedicalStaffListService(
                    token,
                    'iden_doctor'
                );
                if (result.result === 'success') {
                    setStaffLists(result.lists);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };

    const callRegSeqSettingAPI = async () => {
        if (token) {
            try {
                const result = await callRegSeqSettingService(token);
                if (result.result === 'success') {
                    setRuleLists(result.rule_lists);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    useEffect(() => {
        callRegSeqSettingAPI();
    }, []);

    useEffect(() => {
        setSchedules(initialSchedules);

        callClinicScheduleAPI();
    }, [selectedDate]);

    useEffect(() => {
        const startOfThisWeek = moment().startOf('week').toDate();
        const endOfThisWeek = moment().endOf('week').toDate();
        setWeekRange([
            { startOfWeek: startOfThisWeek, endOfWeek: endOfThisWeek },
        ]);
    }, []);

    type doctorNameByShiftAndRoomProps = {
        [key: string]: (string | null)[];
    };

    // 精簡版 正確聲明 map 變量的類型
    const extractPropertyByShiftAndRoom = (
        schedules: schedulesProps[],
        propertyExtractor: (schedule: schedulesProps) => (string | null)[]
    ) => {
        const map: { [key: string]: (string | null)[] } = {};

        schedules.forEach(schedule => {
            const key = `${schedule.shift}_${schedule.room}`;
            map[key] = propertyExtractor(schedule);
        });

        return map;
    };

    const doctorNameByShiftAndRoom = React.useMemo(() => {
        return extractPropertyByShiftAndRoom(schedules, schedule =>
            schedule.days.map(day => day || '-')
        );
    }, [schedules]);

    const doctorColorByShiftAndRoom = React.useMemo(() => {
        return extractPropertyByShiftAndRoom(schedules, schedule =>
            schedule.color.map(color => color || '')
        );
    }, [schedules]);

    const seqUidByShiftAndRoom = React.useMemo(() => {
        return extractPropertyByShiftAndRoom(schedules, schedule =>
            schedule.seqUid.map(seqUid => seqUid || '')
        );
    }, [schedules]);

    const [doctorNameByShiftAndRoomState, setScheduleByShiftAndRoomState] =
        useState<doctorNameByShiftAndRoomProps>(doctorNameByShiftAndRoom);

    const daysOfWeek = ['日', '一', '二', '三', '四', '五', '六'];
    const handleGoToLastWeek = () => {
        if (weekRange && weekRange.length > 0) {
            const startOfLastWeek = moment(weekRange[0].startOfWeek)
                .subtract(1, 'weeks')
                .startOf('week')
                .toDate();
            const endOfLastWeek = moment(startOfLastWeek)
                .endOf('week')
                .toDate();
            setWeekRange([
                { startOfWeek: startOfLastWeek, endOfWeek: endOfLastWeek },
            ]);
        }
        // 將選擇的日期減去7天
        const newSelectedDate = selectedDate
            ? new Date(selectedDate.getTime() - 7 * 24 * 60 * 60 * 1000)
            : null;
        setSelectedDate(newSelectedDate);
    };

    const handleGoToNextWeek = () => {
        if (weekRange && weekRange.length > 0) {
            const startOfNextWeek = moment(weekRange[0].startOfWeek)
                .add(1, 'weeks')
                .startOf('week')
                .toDate();
            const endOfNextWeek = moment(startOfNextWeek)
                .endOf('week')
                .toDate();
            setWeekRange([
                { startOfWeek: startOfNextWeek, endOfWeek: endOfNextWeek },
            ]);
        }
        const newSelectedDate = selectedDate
            ? new Date(selectedDate.getTime() + 7 * 24 * 60 * 60 * 1000)
            : null;
        setSelectedDate(newSelectedDate);
    };

    const currentWeekRange =
        weekRange && weekRange.length > 0
            ? weekRange[0]
            : { startOfWeek: new Date(), endOfWeek: new Date() };
    const datesOfTheWeek: Date[] = [];
    let currentDate = new Date(currentWeekRange.startOfWeek);
    for (let i = 0; i < 7; i++) {
        datesOfTheWeek.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    const [openOptionMenuStatus, setOpenOptionMenuStatus] = useState<
        Array<boolean>
    >(Array(7).fill(false));

    const handleInsertName = (
        event: React.MouseEvent,
        shiftAndRoomKey: string,
        dayIndex: number
    ) => {
        event.stopPropagation();
        if (
            conditionSettingData.name === '' ||
            conditionSettingData.numbering === '' ||
            conditionSettingData.if_general === ''
        ) {
            let x = event.clientX;
            let y = event.clientY;

            if (x + 300 > window.innerWidth) {
                x = x - 150;
            }

            if (y + 400 > window.innerHeight) {
                y = y - 160;
            }

            setClickPosition({ x, y });
            setOpenBoardStatus(true);
        } else {
            setOpenBoardStatus(false);
            const date = moment(weekRange[0].startOfWeek)
                .add(dayIndex, 'days')
                .format('YYYY-MM-DD');
            const shiftName = shiftAndRoomKey.slice(0, 2);
            const matchingShift = shiftLists.find(
                item => item.name === shiftName
            );
            const shiftID = matchingShift ? matchingShift.data_id : null;
            const clinicName = shiftAndRoomKey.slice(3);
            const matchingClinic = clinicRoomLists.find(
                item => item.name === clinicName
            );
            const clinicID = matchingClinic ? matchingClinic.id : null;
            const matchingDoctor = staffLists.find(
                item => item.data_id === conditionSettingData.name
            );
            const doctorID = matchingDoctor ? matchingDoctor.data_id : null;
            const selectedDate = moment()
                .add(dayIndex, 'days')
                .format('YYYY-MM-DD');
            const [selectedShiftName, selectedRoomName] =
                shiftAndRoomKey.split('_');
            const selectedDoctorID = conditionSettingData.name;

            setSchedules(prevSchedules => {
                const newSchedules = prevSchedules.map(schedule => {
                    if (
                        schedule.shift === selectedShiftName &&
                        schedule.room === selectedRoomName
                    ) {
                        let doctors = schedule.doctors[dayIndex] || [];
                        if (
                            !doctors.find(
                                (doctor: DoctorSchedule) =>
                                    doctor.doctorId === selectedDoctorID
                            )
                        ) {
                            doctors.push({
                                doctorId: selectedDoctorID,
                                doctorName: conditionSettingData.name,
                            });
                            schedule.doctors[dayIndex] = doctors;
                        }
                    }
                    return schedule;
                });
                return newSchedules;
            });

            // insert時就要送api
            if (shiftID && clinicID && doctorID) {
                let finalData = {
                    start_date: date,
                    if_general: conditionSettingData.if_general,
                    shift_uid: shiftID,
                    clinic_room_uid: clinicID,
                    // subject_code?: ''
                    medical_staff_uid: doctorID,
                    seq_setting_uid: conditionSettingData.numbering,
                };

                postClinicScheduleAPI(finalData);
            }
        }
    };

    const handleDeleteName = (shiftAndRoomKey: string, dayIndex: number) => {
        Swal.fire({
            title: '您要刪除這一天還是刪除每一天？',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: '刪除每一天',
            showDenyButton: true,
            denyButtonText: '只刪這一天',
            cancelButtonText: '取消',
            showCloseButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                setSchedules(prev => {
                    if (!prev) return prev;
                    const date = moment(weekRange[0].startOfWeek)
                        .add(dayIndex, 'days')
                        .format('YYYY-MM-DD');
                    console.log('要刪的東西 date: ', date);
                    let dataIdToDelete = null;

                    const updatedSchedules = prev.map(schedule => {
                        if (
                            schedule.shift === shiftAndRoomKey.slice(0, 2) &&
                            schedule.room === shiftAndRoomKey.slice(3)
                        ) {
                            const newDays = [...schedule.days];
                            dataIdToDelete =
                                schedule.data_ids &&
                                schedule.data_ids[dayIndex];
                            newDays[dayIndex] = null;

                            return { ...schedule, days: newDays };
                        } else {
                            return schedule;
                        }
                    });

                    if (dataIdToDelete) {
                        deleteClinicScheduleAPI(dataIdToDelete, date, 'Y');
                    }

                    return updatedSchedules;
                });
            } else if (result.isDenied) {
                setSchedules(prev => {
                    if (!prev) return prev;
                    const date = moment(weekRange[0].startOfWeek)
                        .add(dayIndex, 'days')
                        .format('YYYY-MM-DD');
                    console.log('要刪除的 dayIndex: ', dayIndex);
                    console.log('要刪除的 date: ', date);
                    let dataIdToDelete = null;

                    const updatedSchedules = prev.map(schedule => {
                        if (
                            schedule.shift === shiftAndRoomKey.slice(0, 2) &&
                            schedule.room === shiftAndRoomKey.slice(3)
                        ) {
                            const newDays = [...schedule.days];
                            dataIdToDelete =
                                schedule.data_ids &&
                                schedule.data_ids[dayIndex];
                            newDays[dayIndex] = null;

                            return { ...schedule, days: newDays };
                        } else {
                            return schedule;
                        }
                    });

                    if (dataIdToDelete) {
                        deleteClinicScheduleAPI(dataIdToDelete, date, 'N');
                    }

                    return updatedSchedules;
                });
            } else {
                return;
            }
        });
    };

    const toggleBoardPicker = (event: React.MouseEvent) => {
        event.stopPropagation();
        setOpenBoardStatus(false);
    };

    interface ConditionSettingDataProps {
        name: string;
        numbering: string;
        color: string;
        if_general: string;
    }

    const initialConditionSettingData = {
        name: '',
        numbering: '',
        color: '',
        if_general: 'Y',
    };

    const [conditionSettingData, setConditionSettingData] =
        useState<ConditionSettingDataProps>(initialConditionSettingData);

    const handleChangeInput = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;

        let updatedColor = conditionSettingData.color;
        if (name === 'name') {
            const selectedStaff = staffLists.find(
                item => item.data_id === value
            ); // 用 data_id 篩選 staffLists 的其中一筆

            updatedColor = selectedStaff ? selectedStaff.color : '';
        }

        setConditionSettingData(prev => ({
            ...prev,
            [name]: value,
            color: updatedColor,
        }));
    };

    // useEffect(() => {
    //     console.log('schedules here: ', schedules);
    //     console.log('seqUidByShiftAndRoom: ', seqUidByShiftAndRoom);
    //     console.log('condition SeqUid: ', conditionSettingData.numbering);
    //     console.log('condition name: ', conditionSettingData.name);
    // }, [schedules]);
    return (
        <>
            {/*  條件設定浮層 */}
            {openBoardStatus && (
                <>
                    <Flex
                        pos={'fixed'}
                        top={`${clickPosition.y}px`}
                        left={`${clickPosition.x}px`}
                        transform={'translate(-50%,-50%)'}
                        minW={'300px'}
                        bgColor={'gray.150'}
                        backdropFilter={'blur(5px)'}
                        zIndex={50}
                        shadow={'lg'}
                        rounded={'15px'}
                        direction={'column'}
                        px={'16px'}
                        py={'16px'}
                        gap={'8px'}
                    >
                        {/* 醫生 */}
                        <Flex
                            gap={'8px'}
                            align={'center'}
                            justify={'space-between'}
                        >
                            <Text textStyle={'semiTitle'} flex={'0 0 auto'}>
                                醫師
                            </Text>
                            <Select
                                bg={'#fff'}
                                value={conditionSettingData?.name ?? ''}
                                w={'200px'}
                                name="name"
                                onChange={handleChangeInput}
                            >
                                <option value="" disabled>
                                    請選擇
                                </option>
                                {staffLists&&staffLists.map((item, index) => (
                                    <option value={item.data_id} key={index}>
                                        {item.name}
                                    </option>
                                ))}
                                {/* 醫師人員列表 */}
                            </Select>
                        </Flex>

                        {/* 顏色 */}
                        <Flex
                            gap={'8px'}
                            align={'center'}
                            justify={'space-between'}
                        >
                            <Text textStyle={'semiTitle'} flex={'0 0 auto'}>
                                顏色
                            </Text>

                            <Box pos={'relative'}>
                                <Flex
                                    align={'center'}
                                    justify={'center'}
                                    w={'200px'}
                                    h={'40px'}
                                    rounded={'4px'}
                                    bg={
                                        conditionSettingData.color ||
                                        'rsPrimary.500'
                                    }
                                >
                                    <HeroIcons name="palette"></HeroIcons>
                                </Flex>
                            </Box>
                        </Flex>
                        {/* 序號 */}
                        <Flex
                            gap={'8px'}
                            align={'center'}
                            justify={'space-between'}
                        >
                            <Text textStyle={'semiTitle'} flex={'0 0 auto'}>
                                序號
                            </Text>
                            <Select
                                bg={'#fff'}
                                value={conditionSettingData?.numbering ?? ''}
                                w={'200px'}
                                name="numbering"
                                onChange={handleChangeInput}
                            >
                                <option value="" disabled>
                                    請選擇
                                </option>
                                {ruleLists&&ruleLists.map((item, index) => (
                                    <option value={item.data_id} key={index}>
                                        {item.rule_name}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                        {/* 模式 */}
                        <Flex
                            gap={'8px'}
                            align={'center'}
                            justify={'space-between'}
                        >
                            <Text textStyle={'semiTitle'} flex={'0 0 auto'}>
                                模式
                            </Text>
                            <Select
                                bg={'#fff'}
                                value={conditionSettingData?.if_general ?? 'Y'}
                                onChange={handleChangeInput}
                                w={'200px'}
                                name="if_general"
                            >
                                <option value={'Y'}>沿用以後</option>
                                <option value={'N'}>只選這次</option>
                            </Select>
                        </Flex>
                        <Box textAlign={'center'}>
                            <Button
                                size={'sm'}
                                w={'100%'}
                                colorScheme="rsPrimary"
                                onClick={() => {
                                    setOpenBoardStatus(false);
                                }}
                            >
                                確認
                            </Button>
                        </Box>
                    </Flex>
                    {openBoardStatus && (
                        <>
                            <Box
                                top={0}
                                left={0}
                                w={'100%'}
                                h={'100%'}
                                pos={'fixed'}
                                onClick={toggleBoardPicker}
                                zIndex={49}
                                bg={'#00000099'}
                            >
                                {/* 魔法屏障 */}
                            </Box>
                            <Box
                                zIndex={50}
                                pos={'absolute'}
                                top={'calc(100% + 10px)'}
                                left={'0%'}
                                bg={'#ff0'}
                                onClick={e => e.stopPropagation()}
                            ></Box>
                        </>
                    )}
                </>
            )}

            <Box w={'100%'} overflow={'auto'}>
                {/* 條件設定 控制條 */}
                <Flex
                    bgColor={'gray.150'}
                    rounded={'15px'}
                    py={'8px'}
                    px={'16px'}
                    mb={'16px'}
                    gap={'32px'}
                    align={'center'}
                    borderBottom={'2px solid #ccc'}
                    overflow={'auto'}
                >
                    <Flex gap={'8px'} align={'center'}>
                        <Text textStyle={'semiTitle'} flex={'0 0 auto'}>
                            醫師
                        </Text>
                        <Select
                            bg={'#fff'}
                            value={conditionSettingData?.name ?? ''}
                            name="name"
                            onChange={handleChangeInput}
                        >
                            <option value="" disabled>
                                請選擇
                            </option>
                            {staffLists && staffLists.map((item, index) => (
                                <option value={item.data_id} key={index}>
                                    {item.name}
                                </option>
                            ))}
                            {/* 醫師人員列表 */}
                        </Select>
                    </Flex>
                    <Flex gap={'8px'} align={'center'}>
                        <Text textStyle={'semiTitle'} flex={'0 0 auto'}>
                            顏色
                        </Text>

                        <Box pos={'relative'}>
                            <Box
                                w={'40px'}
                                h={'40px'}
                                bg={
                                    conditionSettingData.color ||
                                    'rsPrimary.500'
                                }
                                rounded={'4px'}
                                className="flex items-center justify-center"
                            >
                                <HeroIcons name="palette"></HeroIcons>
                            </Box>
                        </Box>
                    </Flex>

                    {/* 節次 */}
                    <Flex gap={'8px'} align={'center'} hidden>
                        <Text textStyle={'semiTitle'} flex={'0 0 auto'}>
                            節次
                        </Text>
                        <Text>{14}</Text>
                    </Flex>

                    {/* 序號樣板 */}
                    <Flex gap={'8px'} align={'center'}>
                        <Text textStyle={'semiTitle'} flex={'0 0 auto'}>
                            序號樣板
                        </Text>
                        <Select
                            bg={'#fff'}
                            value={conditionSettingData?.numbering ?? ''}
                            onChange={handleChangeInput}
                            name="numbering"
                        >
                            <option value="" disabled>
                                請選擇
                            </option>
                            {ruleLists && ruleLists.map((item, index) => (
                                <option value={item.data_id} key={index}>
                                    {item.rule_name}
                                </option>
                            ))}
                            {/* 序號樣板列表 */}
                        </Select>
                    </Flex>

                    {/* 模式 */}
                    <Flex gap={'8px'} align={'center'}>
                        <Text textStyle={'semiTitle'} flex={'0 0 auto'}>
                            模式
                        </Text>
                        <Select
                            bg={'#fff'}
                            value={conditionSettingData?.if_general ?? 'Y'}
                            onChange={handleChangeInput}
                            name="if_general"
                        >
                            <option value={'Y'}>沿用以後</option>
                            <option value={'N'}>只選這次</option>
                            {/* 序號樣板列表 */}
                        </Select>
                    </Flex>
                </Flex>

                {/* 開始週表 */}
                <Box bgColor={'gray.150'} borderBottom={'2px solid #ccc'}>
                    {/* 週表頭 row */}
                    <Flex borderBottom={'1px solid #d6d6d6'}>
                        {/* 切換週次 */}
                        <Flex flex={'0 0 180px'}>
                            <Flex
                                justify={'space-between'}
                                align={'center'}
                                px={'8px'}
                                borderRight={'1px solid #d9d9d9'}
                                flex={'1 0 auto'}
                                w={'100%'}
                            >
                                <Box
                                    onClick={handleGoToLastWeek}
                                    _hover={{
                                        cursor: 'pointer',
                                    }}
                                    borderRadius={'100px'}
                                    w={'20px'}
                                    h={'20px'}
                                    lineHeight={'20px'}
                                    textAlign={'center'}
                                    textColor={'#888'}
                                >
                                    &lt;
                                </Box>
                                <Text textColor={'#888'}>切換週次</Text>
                                <Box
                                    onClick={handleGoToNextWeek}
                                    _hover={{
                                        cursor: 'pointer',
                                    }}
                                    borderRadius={'4px'}
                                    w={'20px'}
                                    h={'20px'}
                                    lineHeight={'20px'}
                                    textAlign={'center'}
                                    textColor={'#888'}
                                >
                                    &gt;
                                </Box>
                            </Flex>
                        </Flex>

                        {/* 星期週期列 */}
                        <Flex flex={'1 0 auto'}>
                            {daysOfWeek&&daysOfWeek.map((day, index) => (
                                <Flex
                                    justify={'space-between'}
                                    align={'center'}
                                    py={'4px'}
                                    px={'8px'}
                                    key={index}
                                    borderRight={'1px solid #d9d9d9'}
                                    wrap={'wrap'}
                                    flex={'1 0 auto'}
                                >
                                    <Text
                                        textStyle={'semiTitle'}
                                        flex={'0 0 auto'}
                                    >
                                        週{day}
                                    </Text>

                                    <Box w={'50px'} textAlign={'right'}>
                                        <span>
                                            {datesOfTheWeek[index].getMonth() +
                                                1}
                                        </span>
                                        <span> / </span>
                                        <span>
                                            {datesOfTheWeek[index].getDate()}
                                        </span>
                                    </Box>
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>

                    {/* 週表內容 */}
                    <Flex direction="column">
                        {shiftLists&&shiftLists.map((shift, index) => (
                            <Flex
                                key={index}
                                direction="row"
                                align="flex-start"
                                borderBottom={'3px solid #d9d9d9'}
                            >
                                {/* 時段 */}
                                <Flex
                                    p={'8px'}
                                    textAlign="center"
                                    alignSelf={'stretch'}
                                    direction={'column'}
                                    justify={'center'}
                                    borderRight={'1px solid #d9d9d9'}
                                    borderBottom={'1px solid #d9d9d9'}
                                    flex={'0 0 90px'}
                                >
                                    <Text textStyle={'semiTitle'}>
                                        {shift.name}
                                    </Text>
                                    <Flex
                                        fontSize="sm"
                                        direction={'column'}
                                        textColor={'#888'}
                                    >
                                        <span>{shift.start_time}</span>
                                        <span> {shift.end_time}</span>
                                    </Flex>
                                </Flex>

                                <Flex direction="column" flex={'1 0 auto'}>
                                    {clinicRoomLists && clinicRoomLists.map(room => (
                                        <Flex
                                            key={room.id}
                                            align="center"
                                            flex={'0 0 auto'}
                                        >
                                            {/* 診間 */}
                                            <Flex
                                                p={'8px'}
                                                alignSelf={'stretch'}
                                                justify={'center'}
                                                align={'center'}
                                                borderBottom={
                                                    '1px solid #d9d9d9'
                                                }
                                                borderRight={
                                                    '1px solid #d9d9d9'
                                                }
                                                flex={'0 0 90px'}
                                            >
                                                <Text
                                                    textAlign={'center'}
                                                    textStyle={'semiTitle'}
                                                >
                                                    {room.name}
                                                </Text>
                                            </Flex>

                                            {Array.from(
                                                { length: 7 },
                                                (_, dayIndex) => {
                                                    const key = `${shift.name}_${room.name}`;
                                                    const nameForDay =
                                                        doctorNameByShiftAndRoom[
                                                            key
                                                        ]?.[dayIndex] || '-';

                                                    const doctorColor =
                                                        doctorColorByShiftAndRoom[
                                                            key
                                                        ]?.[dayIndex] ||
                                                        '#868C6E';

                                                    const seqUid =
                                                        seqUidByShiftAndRoom[
                                                            key
                                                        ]?.[dayIndex] || '-';
                                                    return (
                                                        <Box
                                                            key={dayIndex}
                                                            p={'4px'}
                                                            bgColor={
                                                                dayIndex == 0 || // 星期天  背景顏色不同
                                                                dayIndex == 6 // 星期六 背景顏色不同
                                                                    ? '#F9F4E9'
                                                                    : '#fff'
                                                            }
                                                            borderRight={
                                                                '1px solid #d9d9d9'
                                                            }
                                                            borderBottom={
                                                                '1px solid #d9d9d9'
                                                            }
                                                            alignSelf={
                                                                'stretch'
                                                            }
                                                            flexBasis={
                                                                'calc( 100% / 7 )'
                                                            }
                                                        >
                                                            {nameForDay !==
                                                            '-' ? (
                                                                <>
                                                                    <Box
                                                                        bgColor={
                                                                            doctorColor
                                                                        }
                                                                        p={
                                                                            '4px'
                                                                        }
                                                                        borderRadius={
                                                                            '4px'
                                                                        }
                                                                        pos={
                                                                            'relative'
                                                                        }
                                                                        role="group"
                                                                    >
                                                                        <Text
                                                                            color={
                                                                                '#fff'
                                                                            }
                                                                            fontWeight={
                                                                                'semibold'
                                                                            }
                                                                        >
                                                                            {
                                                                                nameForDay
                                                                            }
                                                                        </Text>
                                                                        <Text
                                                                            color={
                                                                                '#fff'
                                                                            }
                                                                            overflowX={
                                                                                'auto'
                                                                            }
                                                                            whiteSpace={
                                                                                'nowrap'
                                                                            }
                                                                        >
                                                                            {
                                                                                ruleLists.find(
                                                                                    item =>
                                                                                        item.data_id ===
                                                                                        seqUid
                                                                                )
                                                                                    ?.rule_name
                                                                            }
                                                                        </Text>

                                                                        {/* 刪除按鈕 */}
                                                                        <Box
                                                                            pos={
                                                                                'absolute'
                                                                            }
                                                                            top={
                                                                                '-5px'
                                                                            }
                                                                            right={
                                                                                '-5px'
                                                                            }
                                                                            borderRadius={
                                                                                '50%'
                                                                            }
                                                                            opacity={
                                                                                '0'
                                                                            }
                                                                            _groupHover={{
                                                                                opacity:
                                                                                    '1',
                                                                            }}
                                                                            _hover={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleDeleteName(
                                                                                    key,
                                                                                    dayIndex
                                                                                )
                                                                            }
                                                                            p={
                                                                                '4px'
                                                                            }
                                                                            bg={
                                                                                'rsDanger.600'
                                                                            }
                                                                            textColor={
                                                                                '#fff'
                                                                            }
                                                                        >
                                                                            <HeroIcons name="xmark-xs"></HeroIcons>
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Flex
                                                                        p={1}
                                                                        _hover={{
                                                                            bgColor:
                                                                                'gray.150',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        py={
                                                                            '4px'
                                                                        }
                                                                        px={
                                                                            '8px'
                                                                        }
                                                                        borderRadius={
                                                                            '4px'
                                                                        }
                                                                        onClick={e => {
                                                                            handleInsertName(
                                                                                e,
                                                                                key,
                                                                                dayIndex
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Text textAlign="center">
                                                                            {
                                                                                nameForDay
                                                                            }
                                                                        </Text>
                                                                    </Flex>
                                                                </>
                                                            )}
                                                        </Box>
                                                    );
                                                }
                                            )}
                                        </Flex>
                                    ))}
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                </Box>
            </Box>
        </>
    );
};
export default WorkingScheduleBoard;
