// src/stores/registeredPageStore.ts
import { create } from 'zustand';

export type VitalSignProps = {
    data_id?: string | null;
    vital_sign_uid: string;
    value?: string | null;
    name: string;
};

export type DiagnosisProps = {
    icd_code: string;
    short_code: string;
    ds: string | null;
    icd_10_in_use_uid: string;
    name_tw: string;
    name_en: string;
};

export type DrugProps = {
    dose_days: number;
    freq: string;
    qty: string;
    qty_in_day: string;
    route_of_administration: string;
    total_qty: string;
    given_qty: string;
    site: string;
    special_mark: string[];
    drugs_in_use_uid: string;
    drug_no: string;
    max_dose: string;
    max_dose_day: string;
    drug_type: string;
    cost: string;
    controlled_level: string;
    disabled_date: string;
    short_code: string;
    self_price: string;
    name_tw: string;
    name_en: string;
    ing_qty_unit: string;
    dosage_form: string;
    drug_price: string;
    freq_directions: string;
    freq_custom_code: string;
    freq_code: string;
    drug_type_code: string;
    if_declare: string;
};
export type mb1Props = {
    signature: string; // 安全簽章
    treatment_no: string;  // 就醫識別碼
    treated_at: string; // 就診時間
    card_number: string; // 卡片號碼
    institution_type: string; // 機構類別
    sam_code: string; // SAM卡代碼
    card_no: string; // 卡片序號

}
export type MedicalRecordProps = {
    info?: {
        data_id: string;
        cc: string | null;
        treated_at: string;
        case_type: string;
        medical_subject: string;
    };
    diagnosis?: DiagnosisProps[];
    drugs?: DrugProps[];
    mb1: mb1Props
};

export type TreatmentInfoProps = {
    data_id?: string; // 當筆病歷id
    treated_at?: Date| null; // 就診日期
    id: string; // 掛號主檔id
    opd_at: Date | null; // 掛號日期時間
    shift_name: string; // 時段名稱
    if_formal_data?: string; // 是否保留病例 0保留 1正式
    shift_uid: string; // 時段id
    clinic_room_name: string; // 診間名稱
    clinic_room_uid: string; // 診間id
    doctor_name: string; // 醫師姓名
    doctor_uid: string; // 醫師id
    doctor_id_no: string; // 醫師身分證
    declare_doctor_uid: string; // 追加：批價畫面 申報醫師 id
    pharmacist_uid: string; // 追加：批價畫面 申報藥師 id
    patient_name: string; // 病患姓名
    patient_uid: string; // 病患資料id
    treatment_name: string; // ？？？？
    medical_record_number: string; // 病歷號碼
    member_uid: string; // 當次優免身份 id
    birth_date: Date | null; // 病患生日
    drug_allergy_describe: null | string; // 過敏藥物
    seek_history: null | string; // 過去病史
    id_no: string; // 病患身分證號
    sex: null | string; // 病患生理性別
    case_type?: null | string; // 追加：批價畫面 案件分類
    nhi_type_name: string; // 掛號身份
    card_no: string; // 健保卡號
    subject_code: string; // 醫師科別代碼
    subject_name: string; // 醫師科別名稱
    vital_sign?: VitalSignProps[]; // 基礎體徵
    mt_type: string; // 就醫類別
    selected_mt_type: string; // 追加：批價畫面 就醫類別
    prescription_style: number; // 追加：批價畫面 調劑方式
    copayment: string; // 追加：批價畫面 部分負擔
    referral: string; // 追加：批價畫面 轉檢代號
    benefit_type: string; // 追加：批價畫面 給付類別
    special_treatment: string; // 追加：批價畫面 特殊原因

    chronic_times: number | null; // prototype 使用：慢性設定
    chronic_reasons: string; // prototype 使用：慢性設定
    medical_record?: MedicalRecordProps; // 醫療紀錄
};

const initialTreatmentInfo: TreatmentInfoProps = {
    id: '',
    opd_at: null,
    shift_name: '',
    shift_uid: '',
    clinic_room_name: '',
    clinic_room_uid: '',
    doctor_name: '',
    doctor_uid: '',
    doctor_id_no: '',
    declare_doctor_uid: '',
    pharmacist_uid: '',
    patient_name: '',
    case_type: '',
    patient_uid: '',
    treatment_name: '',
    medical_record_number: '',
    member_uid: '',
    birth_date: null,
    drug_allergy_describe: '',
    seek_history: '',
    id_no: '',
    sex: null,
    nhi_type_name: '',
    card_no: '',
    vital_sign: [],
    subject_code: '',
    subject_name: '',
    mt_type: '',
    selected_mt_type: '',
    prescription_style: -1,
    copayment: '',
    referral: '',
    benefit_type: '4',
    special_treatment: '',

    chronic_times: null, // prototype 使用：慢性設定
    chronic_reasons: '', // prototype 使用：慢性設定
    medical_record: undefined, // 醫療紀錄
};

interface TreatmentInfoState {
    treatmentInfo: TreatmentInfoProps;
    updateTreatmentInfo: (newData: Partial<TreatmentInfoProps>) => void;
    resetTreatmentInfo: () => void;
}

export const useTreatmentInfoStore = create<TreatmentInfoState>(set => ({
    treatmentInfo: initialTreatmentInfo,
    updateTreatmentInfo: newData =>
        set(state => ({
            ...state,
            treatmentInfo: { ...state.treatmentInfo, ...newData },
        })),
    resetTreatmentInfo: () =>
        set(state => ({
            ...state,
            treatmentInfo: initialTreatmentInfo,
        })),
}));
