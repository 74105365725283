// 批價模型（ChargeModel）
import { currentVisitInfoDataProps } from 'store/Registration';
import { cashDataStoreProps } from 'store/TreatmentManagement/Payment';
import { FeeState } from 'store/TreatmentManagement/Payment/useFeeStore';
import { TreatmentInfoProps } from 'store/TreatmentManagement/useTreatmentInfoStore';

import { PatientInfoProps } from 'store/Registration';

export const slowRecLogic = (treatmentInfo: TreatmentInfoProps) => {
    let finalData = {
        OpenDate: new Date(),
        Freq: 0,
        Days: 0,
    };
    return finalData;
};
