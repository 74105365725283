import React from 'react';
import {
    Table,
    TableContainer,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Text,
} from '@chakra-ui/react';

export const GotHistoryList = () => {
    return (
        <>
            <TableContainer
                h={`calc(${window.innerHeight}px - 522px)`}
                overflowY={'auto'}
                pos={'relative'}
            >
                <Table variant={'striped'}>
                    <Thead bg={'#D9D9D9'} pos={'sticky'} top={0} zIndex={10}>
                        <Tr>
                            <Th>
                                <Text textStyle={'semiTitle'}>就醫日期</Text>
                            </Th>
                            <Th>
                                <Text textStyle={'semiTitle'}>卡號</Text>
                            </Th>
                            <Th>
                                <Text textStyle={'semiTitle'}>次數</Text>
                            </Th>
                            <Th>
                                <Text textStyle={'semiTitle'}>中文病名</Text>
                            </Th>
                            <Th>
                                <Text textStyle={'semiTitle'}>第一次</Text>
                            </Th>
                            <Th>
                                <Text textStyle={'semiTitle'}>第二次</Text>
                            </Th>
                            <Th>
                                <Text textStyle={'semiTitle'}>第三次</Text>
                            </Th>
                            <Th>
                                <Text textStyle={'semiTitle'}>第四次</Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>113.07.08</Td>
                            <Td>0021 </Td>
                            <Td>3 </Td>
                            <Td>糖尿病 </Td>
                            <Td>113.07.08</Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};
