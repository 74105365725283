export const InsetShadowBox = {
    p: '8px 16px',
    bg: '#fff',
    boxShadow: '4px 2px 2px 0px rgba(0,0,0,0.25) inset',
    rounded: 'lg',
};

export const InsetShadow = {
    bg: '#fff',
    boxShadow: '4px 2px 2px 0px rgba(0,0,0,0.25) inset',
};
