import axios from 'axios';
import apiService from './apiService';
import axiosInstance from './axiosInstance';


// 取得特定優免身份折扣設定清單
export const callMembershipDiscountService = async (token: string, data: string) => {
    const queryParams = data ? {data} :undefined
    return apiService<string, any>({
        method: 'GET',
        path: '/api/membership/discount',
        token ,
        data: queryParams
    })
}
// export const callMembershipDiscountService = async (
//     token: string,
//     id: string
// ) => {
//     try {
//         const queryParams = { id };
//         const headers = {
//             'Api-Token': `${token}`,
//             'Content-Type': 'application/json',
//         };
//         const response = await axios.get(`/api/membership/discount`, {
//             headers,
//             params: queryParams, // 將查詢字串參數添加到請求中
//         });

//         console.log(response, '這個呢');
//         const result = response.data;

//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };







// 刪除特定優免身份的折扣
export const deleteMembershipDiscountService = async (
    token: string,
    data_id: string
) => {
    return apiService<undefined, any>({
        method: 'DELETE',
        path: `/api/membership/discount?id=${data_id}`,
        token
    })
}
// export const deleteMembershipDiscountService = async (
//     token: string,
//     dataId: string
// ) => {
//     try {
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         console.log('service這邊有嗎', dataId);
//         const url = `/api/membership/discount?id=${dataId}`;

//         const response = await axiosInstance.delete(url, {
//             headers,
//         });

//         const result = response.data;
//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
