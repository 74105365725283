import apiService from "./apiService";


export const checkSessionService = async () => {
    return apiService<string, any>({
        method: 'GET',
        path: '/api/check_session',
        // token,
    });
};
