import React, { FC, useState, useEffect } from 'react';
import { Box, Button, Text, Checkbox, Select, Flex } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { usePatientInfoStore } from 'store/Registration';
import { regFeeIdenProps } from 'service/gTypeService';
import { useDropDownMenuQuery } from 'query/useDropDownMenuQuery';
import { useUserStore } from 'store';

import { ADDateToROCString } from 'functions/ADDateToROCString';

interface returnData {
    nhi_code: string;
}

export const HoldAndReturnButton = () => {
    const { userInfo } = useUserStore();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { patientInfo } = usePatientInfoStore();

    const [regFeeIdenLists, setRegFeeIdenLists] = useState<regFeeIdenProps[]>(
        []
    );
    const { data: regFeeIdenData, isSuccess: isRegFeeIdenSuccess } =
        useDropDownMenuQuery('regFeeIden', userInfo.token!);
    useEffect(() => {
        if (isRegFeeIdenSuccess) {
            setRegFeeIdenLists(regFeeIdenData.lists);
        }
    }, [isRegFeeIdenSuccess]);

    const handleSave = () => {
        console.log('確認送出');
        onClose();
    };

    const [returnData, setReturnData] = useState<returnData>({ nhi_code: '' });

    const handleChangeSelect = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;

        setReturnData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (patientInfo.info.nhi_identity) {
            setReturnData({ nhi_code: patientInfo.info.nhi_identity });
        }
    }, []);

    useEffect(() => {
        console.log('病患身份: ', patientInfo.info.nhi_identity);
        console.log('選擇還單身份: ', returnData);
    }, [returnData]);

    return (
        <>
            <Button colorScheme="rsDanger" onClick={onOpen}>
                還單
            </Button>

            <Modal
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
                size={'xl'}
                closeOnOverlayClick={false}
                isCentered
                motionPreset={'slideInTop'}
            >
                <ModalOverlay backdropFilter={'blur(3px)'} />
                <ModalContent>
                    <ModalHeader>
                        <Text textStyle={'semiTitle'}>還單作業提醒</Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex flexWrap={'wrap'} rowGap={'16px'}>
                            <Flex align={'center'} flex={'50%'} px={'16px'}>
                                <Text
                                    fontWeight={'semibold'}
                                    w={'80px'}
                                    flexShrink={0}
                                >
                                    姓名
                                </Text>
                                <Text w={'100px'}>{patientInfo.info.name}</Text>
                            </Flex>
                            <Flex align={'center'} flex={'50%'} px={'16px'}>
                                <Text
                                    fontWeight={'semibold'}
                                    w={'80px'}
                                    flexShrink={0}
                                >
                                    押單身份
                                </Text>
                                <Select
                                    value={returnData.nhi_code}
                                    onChange={handleChangeSelect}
                                    name="nhi_code"
                                >
                                    {regFeeIdenLists &&
                                        regFeeIdenLists.map((item, index) => (
                                            <option
                                                value={item.code}
                                                key={index}
                                            >
                                                {item.name}
                                            </option>
                                        ))}
                                </Select>
                            </Flex>
                            <Flex align={'center'} flex={'50%'} px={'16px'}>
                                <Text
                                    fontWeight={'semibold'}
                                    w={'80px'}
                                    flexShrink={0}
                                >
                                    身分證號
                                </Text>
                                <Text w={'100px'}>
                                    {patientInfo.info.id_no}
                                </Text>
                            </Flex>
                            <Flex align={'center'} flex={'50%'} px={'16px'}>
                                <Text
                                    fontWeight={'semibold'}
                                    w={'80px'}
                                    flexShrink={0}
                                >
                                    生日
                                </Text>
                                <Text w={'100px'}>
                                    {patientInfo.info.birth_date &&
                                        ADDateToROCString(
                                            patientInfo.info.birth_date
                                        )}
                                </Text>
                            </Flex>
                            <Flex
                                align={'center'}
                                flex={'100%'}
                                boxShadow={
                                    '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset'
                                }
                                p={'8px 16px'}
                                bg={'rsDanger.500'}
                                rounded={'md'}
                                textColor={'#fff'}
                            >
                                <Flex align={'center'} flex={'50%'}>
                                    <Text
                                        fontWeight={'semibold'}
                                        w={'80px'}
                                        flexShrink={0}
                                    >
                                        押單日
                                    </Text>
                                    <Text w={'100px'}>113.07.28</Text>
                                </Flex>
                                <Flex align={'center'} flex={'50%'} pl={'16px'}>
                                    <Text
                                        fontWeight={'semibold'}
                                        w={'80px'}
                                        flexShrink={0}
                                    >
                                        押單額
                                    </Text>
                                    <Text w={'100px'}>500</Text>
                                </Flex>
                            </Flex>
                            <Flex align={'center'} flex={'100%'}>
                                <Flex
                                    align={'center'}
                                    flex={'0 0 calc(50% - 16px)'}
                                    boxShadow={
                                        '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset'
                                    }
                                    p={'8px 16px'}
                                    bg={'rsPrimary.500'}
                                    rounded={'md'}
                                    textColor={'#fff'}
                                >
                                    <Text
                                        fontWeight={'semibold'}
                                        w={'80px'}
                                        flexShrink={0}
                                    >
                                        還單日
                                    </Text>
                                    <Text w={'100px'}>113.08.02</Text>
                                </Flex>
                            </Flex>
                            <Flex align={'center'} flex={'50%'} px={'16px'}>
                                <Text
                                    fontWeight={'semibold'}
                                    w={'80px'}
                                    flexShrink={0}
                                >
                                    就醫類別
                                </Text>
                                <Select>
                                    <option value="">01西醫</option>
                                </Select>
                            </Flex>
                            <Flex align={'center'} flex={'50%'} px={'16px'}>
                                <Text
                                    fontWeight={'semibold'}
                                    w={'80px'}
                                    flexShrink={0}
                                >
                                    卡號
                                </Text>
                                <Select>
                                    <option value="">0021</option>
                                </Select>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant={'ghost'} mr={3} onClick={onClose}>
                            取消
                        </Button>
                        <Button colorScheme="rsPrimary" onClick={handleSave}>
                            確認
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
