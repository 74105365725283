import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ShowEditStatusContextValue {
    showEditStatus: string;
    setShowEditStatus: React.Dispatch<React.SetStateAction<string>>;
}

const ShowEditStatusContext = createContext<
    ShowEditStatusContextValue | undefined
>(undefined);

interface ShowEditStatusProviderProps {
    children: ReactNode;
}

export const ShowEditStatusProvider: React.FC<ShowEditStatusProviderProps> = ({
    children,
}) => {
    const [showEditStatus, setShowEditStatus] = useState('');

    return (
        <ShowEditStatusContext.Provider
            value={{ showEditStatus, setShowEditStatus }}
        >
            {children}
        </ShowEditStatusContext.Provider>
    );
};

export const useShowEditStatus = (): ShowEditStatusContextValue => {
    const context = useContext(ShowEditStatusContext);

    if (!context) {
        throw new Error(
            'useShowEditStatus must be used within a ShowEditStatusProvider'
        );
    }

    return context;
};
