import axios from 'axios';

// interface postQRCodeReaderUpdateqrcodeProps {
//     QRCodeString: string;
// }
// 更新本機中控的卡片資料 
export const postQRCodeReaderUpdateqrcodeService = async (QRCodeString: string): Promise<any> => {
    // console.log(QRCodeString, '先看傳什麼')
  
    try {
        const response = await axios.post('http://localhost:3033/QRCodeReader/uploadqrcode', {
            QRCodeString
        }, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            },
        });

        // console.log(response.data, '再次要求授權');
        return response.data;
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.error('Error:', error.message);
        } else {
            console.error('An unknown error occurred');
        }
    }
};