import React, { useState } from 'react';
import {
    Box,
    Text,
    Flex,
    Stack,
    Radio,
    RadioGroup,
    Input,
} from '@chakra-ui/react';
export const Object = () => {
    return (
        <>
            <Text textStyle={'semiTitle'} mb={'8px'}>
                病情摘要（主訴及簡短病史）
            </Text>
            <Box p={'8px 16px'} overflowY={'scroll'} h={'140px'}>
                <Text></Text>
            </Box>
        </>
    );
};
