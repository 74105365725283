import React from 'react';
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    TabIndicator,
    Box,
    Text,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { RxInfo } from './RxInfo';
import { BeforeTreatment } from './BeforeTreatment';
export const Others = () => {
    return (
        <>
            <Box w={'50%'}>
                <Tabs position="relative" variant="unstyled">
                    <TabList>
                        <Tab p={'0px 8px 4px'}>
                            <Box textColor={'rsPrimary.500'} mr={'4px'}>
                                <HeroIcons name="information-circle" />
                            </Box>
                            <Text textStyle={'semiTitle'} fontSize={'sm'}>
                                醫令其他資訊
                            </Text>
                        </Tab>
                        <Tab p={'0px 8px 4px'}>
                            <Box textColor={'rsPrimary.500'} mr={'4px'}>
                                <HeroIcons name="computer-desktop" />
                            </Box>
                            <Text textStyle={'semiTitle'} fontSize={'sm'}>
                                事前審查治療項目
                            </Text>
                        </Tab>
                    </TabList>
                    <TabIndicator
                        mt="-1.5px"
                        height="2px"
                        bg="rsPrimary.500"
                        borderRadius="1px"
                    />
                    <TabPanels>
                        <TabPanel p={'8px'}>
                            <RxInfo />
                        </TabPanel>
                        <TabPanel p={'8px'}>
                            <BeforeTreatment />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </>
    );
};
