import registerIcon from '../../images/icon/register.png';
import React, { useEffect, useState } from 'react';
import { useNavigationStatus } from 'contexts/NavigationStatusContext';
import { useSidebarStatus } from 'contexts/SidebarStatusContext';
import { useNavigate } from 'react-router-dom';

interface ItemsProps {
    name: string;
    path: string;
    key: string;
}
export default function SidebarTreatmentRoomFunction() {
    const { navigationStatus, setNavigationStatus } = useNavigationStatus();
    const { sidebarStatus, setSidebarStatus } = useSidebarStatus();
 

    const navigation = useNavigate();

    const handleGo = (path: string) => {
        navigation(path);
    };

    return (
        <>
            <div className="pt-[36px]">
                <div>
                    {sidebarStatus &&
                        sidebarStatus.map((item, index) => (
                            <div
                                key={item.key}
                                className={'h-10 relative group '}
                            >
                                <label
                                    htmlFor=""
                                    className={
                                        'absolute top-3 left-10 text-[#fff] group-hover:text-[#DDDBBA] transition-all cursor-pointer'
                                    }
                                    onClick={() => handleGo(item.path)}
                                >
                                    {item.name}
                                </label>
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
}
