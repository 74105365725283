import {
    Box,
    Flex,
    Center,
    Text,
    Tooltip
} from '@chakra-ui/react';
import Loading from 'components/CustomComponents/Loading';
import { SearchResultProps } from '../DxICD10';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { useState, useEffect, useCallback, useRef } from 'react';

interface SearchResultListProps {
    searchResult: SearchResultProps[];
    isLoading: boolean;
    resultMsg: string;
    handleInsertDx: (index: number) => void;
    setIsSearchListsStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchResultList: React.FC<SearchResultListProps> = ({
    searchResult,
    isLoading,
    resultMsg,
    handleInsertDx,
    setIsSearchListsStatus
}) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const listRef = useRef<HTMLDivElement>(null);

    const scrollToSelected = useCallback(() => {
        if (listRef.current && selectedIndex !== -1) {
            const selectedElement = listRef.current.children[selectedIndex] as HTMLElement;
            if (selectedElement) {
                const containerRect = listRef.current.getBoundingClientRect();
                const elementRect = selectedElement.getBoundingClientRect();
                
                if (elementRect.top < containerRect.top || elementRect.bottom > containerRect.bottom) {
                    selectedElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                    });
                }
            }
        } else if (listRef.current && selectedIndex === 0) {
            // 第一筆會看不到
            // Scroll a bit more to show the first item completely
        }
    }, [selectedIndex]);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex((prevIndex) => 
                prevIndex > 0 ? prevIndex - 1 : prevIndex
            );
        } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex((prevIndex) => 
                prevIndex < searchResult.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (event.key === 'Enter') {
            event.preventDefault();
            if (selectedIndex !== -1) {
                handleInsertDx(selectedIndex);
            }
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setIsSearchListsStatus(false);
        } else if (event.key === 'Tab') {
            event.preventDefault();
        } else {
            setIsSearchListsStatus(false);
        }
    }, [selectedIndex, searchResult, handleInsertDx, setIsSearchListsStatus]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        scrollToSelected();
    }, [selectedIndex, scrollToSelected]);

    return searchResult.length > 0 ? (
        <Box>
            <Flex
                borderBottom={'2px solid #646B48'}
                pos={'sticky'}
                top={0}
                bg={'#fff'}
                zIndex={1}
                h={'40px'}  // 設定固定高度
            >
                <Box
                    flex={'0 0 80px'}
                    fontWeight={'semibold'}
                    textColor={'#646B48'}
                    p={'4px 16px'}
                >
                    代碼
                </Box>
                <Box
                    flex={'1 0 200px'}
                    fontWeight={'semibold'}
                    textColor={'#646B48'}
                    p={'4px 16px'}
                >
                    中文病名
                </Box>
                <Box
                    flex={'1 0 200px'}
                    fontWeight={'semibold'}
                    textColor={'#646B48'}
                    p={'4px 16px'}
                >
                    英文病名
                </Box>
                <Box
                    flex={'0 0 80px'}
                    fontWeight={'semibold'}
                    textColor={'#646B48'}
                    p={'4px 16px'}
                >
                    ICD10
                </Box>
                <Box
                    flex={'0 0 140px'}
                    textAlign={'center'}
                    fontWeight={'semibold'}
                    textColor={'#646B48'}
                    p={'4px 16px'}
                >
                    預設 CCPE
                </Box>
            </Flex>
            <Box ref={listRef} maxH="300px" overflowY="auto">
                {searchResult && searchResult.map((item, index) => (
                    <Flex
                        borderBottom={'1px solid #646B48'}
                        key={index}
                        bg={selectedIndex === index ? '#D2D2B3' : 'transparent'}
                        _hover={{
                            bg: '#D2D2B3',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            handleInsertDx(index);
                            setSelectedIndex(index);
                        }}
                        height="40px"
                        minHeight="33px"
                        transition="height 0.3s ease"
                    >
                        <Box flex={'0 0 80px'} p={'4px 16px'} display="flex" alignItems="center">
                            {item.short_code}
                        </Box>
                        <Tooltip
                            hasArrow
                            label={
                                item.name_tw
                                    ? item.name_tw
                                    : item.original_name
                            }
                        >
                            <Box
                                flex={'1 0 200px'}
                                overflow={'hidden'}
                                whiteSpace={'nowrap'}
                                textOverflow={'ellipsis'}
                                p={'4px 16px'}
                                display="flex"
                                alignItems="center"
                            >
                                {item.name_tw
                                    ? item.name_tw
                                    : item.original_name}
                            </Box>
                        </Tooltip>

                        <Tooltip hasArrow label={item.name_en}>
                            <Box
                                flex={'1 0 200px'}
                                overflow={'hidden'}
                                whiteSpace={'nowrap'}
                                textOverflow={'ellipsis'}
                                p={'4px 16px'}
                                display="flex"
                                alignItems="center"
                            >
                                {item.name_en}
                            </Box>
                        </Tooltip>
                        <Box flex={'0 0 80px'} p={'4px 16px'} display="flex" alignItems="center">
                            {item.icd_code}
                        </Box>
                        <Box flex={'0 0 140px'} p={'4px 16px'} display="flex" alignItems="center" justifyContent="center">
                            {item.attach_ccpe === 'Y' && (
                                <Box>
                                    <HeroIcons name="check-circle-fill" />
                                </Box>
                            )}
                        </Box>
                    </Flex>
                ))}
            </Box>
        </Box>
    ) : (
        <Center
            bg={'gray.50'}
            textColor={'gray.500'}
            h={'100%'}
            minH={`120px`}
        >
            {isLoading ? (
                <Loading></Loading>
            ) : (
                <Text>{resultMsg}</Text>
            )}
        </Center>
    );
};
