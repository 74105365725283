import { Input, Box, Flex, Heading, Text, Center } from '@chakra-ui/react';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';
import { useState, useEffect, useRef } from 'react';
import { MedicationInfoProps } from './DrugDataMaintenanceTable';

interface DrugDataMaintenanceMedicationInfoProps {
    medicationInfo: MedicationInfoProps;
    setMedicationInfo: React.Dispatch<
        React.SetStateAction<MedicationInfoProps>
    >;
    isEditing: boolean;
}

const DrugDataMaintenanceMedicalOrder = ({
    isEditing,
    medicationInfo,
    setMedicationInfo,
}: DrugDataMaintenanceMedicationInfoProps) => {
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputElement = event.target.value;
        setHasUnsavedChanges(true);
        setMedicationInfo({
            medicationWaring: inputElement,
        });
    };

    return (
        <>
            <Flex gap={3} direction={'column'}>
                <Text textStyle={'semiTitle'}>藥品警語</Text>
                <Input
                    bgColor="#fff"
                    placeholder="輸入警語"
                    onChange={handleChangeInput}
                    isDisabled={!isEditing}
                    value={medicationInfo.medicationWaring}
                />
            </Flex>
        </>
    );
};

export default DrugDataMaintenanceMedicalOrder;
