import React, { useEffect, useState } from 'react';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';
import { useLocation, Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import WorkingSchedulePage from 'pages/DataSetting/WorkingSchedulePage';
import NumberingRulePage from 'pages/DataSetting/NumberingRulePage';
type BreadcrumbNameMap = {
    [key: string]: string;
};
type BreadcrumbsProps = {
    isVisible: boolean;
    // navigation: NavigationItem[];
};
type NavigationItem = {
    path: string;
    navName: string;
    children?: NavigationItem[];
};
const findPathInTree = (
    tree: NavigationItem[],
    path: string
): NavigationItem[] | null => {
    for (const node of tree) {
        if (node.path === path) {
            return [node];
        } else if (node.children) {
            const result = findPathInTree(node.children, path);
            if (result) {
                return [node, ...result];
            }
        }
    }
    return null;
};
const Breadcrumbs = ({ isVisible }: BreadcrumbsProps) => {
    const navigate = useNavigate();
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
    const location = useLocation();
    const pathSegments = location.pathname
        .split('/')
        .filter(segment => segment !== '');
    const pathLinks = pathSegments.map((segment, index) => {
        const url = `/${pathSegments.slice(0, index + 1).join('/')}`;
        const parentPath = `/${pathSegments.slice(0, index).join('/')}`;
        const originalPath = `/${pathSegments.slice(0, index + 1).join('/')}`;
        return { name: segment, url, parentPath, originalPath };
    });
    const [searchParams] = useSearchParams();
    const [state, setState] = useState(false);

    const nameMap: BreadcrumbNameMap = {
        SchedulePage: '預約管理',
        StatisticalReportPage: '統計報表',
        CourseItemPage: '系統設定 > 療程項目設定',
        TrackProjectMaintenancePage: '系統設定 > 追蹤項目維護',
        HealthRiskManagementPage: '健康風險管理',
        SystemConfig: '系統設定',
        HealthRiskVisitRecordPage: '訪視紀錄',
        OPDPage: '診療作業',
        RegisteredPage: '掛號作業',
        TreatmentRoomComponents: '診療管理',
        TreatmentRoomPage: '診療作業',
        HealthCheckRegistrationPage: '健檢掛號作業',
        CheckValueInputPage: '檢驗值輸入',
        HealthCheckReportProductionPage: '健檢報告製作',
        HealthPackageMaintenancePage: '健檢套餐設定',
        HealthCheckRegisteringPage: '健檢掛號作業查詢',
        PasswordPermissionDataPage: '密碼權限設定',
        CheckValueDetailPage: '檢驗值輸入查詢',
        HealthCheckReportDetailProductionPage: '健檢報告製作查詢',
        ProfileSettingPage: '會員中心',
        XRayRoomInputPage: 'X光室輸入',
        XRayRoomDetailPage: 'X光室輸入細項',
        PhysicianAssessmentPage: '醫評維護設定',
        PhysicianAssessmentFormPage: '醫評維護內容設定',
        PhysicianAssessmentPhraseSettingPage: '醫評片語維護設定',
        PhysicianAssessmentItemSettingPage: '醫評項目維護設定',
        PatientManagementPage: '病患管理',
        MemberPage: '會員註冊',
        MedicalInstitutionDataSettingPage: '院所資料設定',
        DiseaseNameDataMaintenancePage: '病名資料維護',
        FeeSettingPage: '費用設定',
        PreferentialStatusPage: '優免身份設定',
        DrugDataMaintenancePage: '藥品資料維護',
        PersonnelProfileSettingPage: '人員資料設定',
        GroupPermissionSettingPage: '群組權限設定',
        NumberingRulePage: '給號規則設定',
        WorkingSchedulePage: '排班表',
        DrugFrequenceSettingPage: '給藥頻率設定',
        ICCardUpdatePage: 'IC 上傳',
        DeclarationAuditPage: '申報審核',
        // 將其他頁面的名稱與中文名稱對應起來
    };
    useEffect(() => {
        const currentUrl = window.location.href;
        const queryStartIndex = currentUrl.indexOf('HomePage');
        if (queryStartIndex > 0) {
            setState(true);
        } else {
            setState(false);
        }
    });
    const handleNavigationClick = (
        path: string,
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        event.preventDefault(); // 阻止默认的链接行为
        if (hasUnsavedChanges) {
            Swal.fire({
                title: '您有尚未儲存的資料！',
                text: '如果離開，您的更改將會遺失。',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '離開',
                cancelButtonText: '取消',
            }).then(result => {
                if (result.isConfirmed) {
                    setHasUnsavedChanges(false);
                    navigate(path); // 用户确认离开，执行导航
                }
                // 如果用户选择取消，则不做任何事情，保留在当前页面
            });
        } else {
            navigate(path); // 如果没有未保存的更改，直接导航
        }
    };

    return (
        <Box
            as="nav"
            px={'20px'}
            py={'8px'}
            fontSize={'14px'}
            lineHeight={'20px'}
            fontWeight={'500'}
            textColor={'rsPrimary.500'}
            className={` ${isVisible && state ? 'hidden' : ''}`}
            bg={'#E9E5DA'}
        >
            <Flex>
                <Box
                    as="a"
                    onClick={(
                        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                    ) => handleNavigationClick('HomePage', e)}
                    textColor={'rsPrimary.700'}
                    _hover={{ filter: 'brightness(1.1)', cursor: 'pointer' }}
                >
                    首頁
                </Box>
                {pathLinks &&
                    pathLinks.map((link, index) => (
                        <Flex key={index}>
                            {link.parentPath && (
                                <>
                                    <Box
                                        as="a"
                                        href={link.parentPath}
                                        onClick={(
                                            e: React.MouseEvent<
                                                HTMLAnchorElement,
                                                MouseEvent
                                            >
                                        ) =>
                                            handleNavigationClick(
                                                link.parentPath,
                                                e
                                            )
                                        }
                                        textColor={'rsPrimary.700'}
                                        _hover={{
                                            filter: 'brightness(1.1)',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {nameMap[link.parentPath]}
                                    </Box>
                                    <Box mx={'5px'}>{'／'}</Box>
                                </>
                            )}
                            <Box
                                textColor={'rsPrimary.700'}
                                _hover={{
                                    filter: 'brightness(1.1)',
                                    cursor: 'pointer',
                                }}
                            >
                                {nameMap[link.name] || link.name}
                            </Box>
                        </Flex>
                    ))}
            </Flex>
        </Box>
    );
};

export default Breadcrumbs;
