import React, { useEffect, useState, useRef } from 'react';

import { Button, Box, Input, Text, Textarea, Flex } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { DxICD10 } from './DxICD10';
import { CCPE } from './CCPE';

const CCPEDxForm: React.FC = () => {
    return (
        <>
            <Flex
                direction={'column'}
                gap={'8px'}
                py={'8px'}
                px={'12px'}
                bg={'#fff'}
                rounded={'8px'}
                boxShadow={'inset 4px 2px 2px rgba(0,0,0,0.25)'}
                h={'332px'}
            >
                <Box flex={'1 1 auto'}>
                    <CCPE />
                </Box>

                <Box flex={'0 0 auto'}>
                    <DxICD10 />
                </Box>
            </Flex>
        </>
    );
};

export default CCPEDxForm;
