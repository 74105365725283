import React, { useState, useEffect } from 'react';
import { Center, Flex, Box, Checkbox, Input } from '@chakra-ui/react';
import { useICCardUpdateRangeStore } from '../../../store/ICCardUpdate/useICCardUpdateRangeStore';

export const Pending = () => {
    const { updateICCardUpdateRangeData } = useICCardUpdateRangeStore();
    const [not_upload, setNotUpload] = useState(true);

    useEffect(() => {
        updateICCardUpdateRangeData({ not_upload: not_upload ? '1' : '0' }); // 1: 未上傳, 0: 已上傳
    }, [not_upload]);

    return (
        <Center gap={'16px'} w={'100%'}>
            <Checkbox
                flex={'0 0 100%'}
                isChecked={not_upload}
                onChange={() => {
                    setNotUpload(prev => !prev);
                }}
            >
                只顯示未上傳的資料
            </Checkbox>
        </Center>
    );
};
