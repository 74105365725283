import React, { useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Swal from 'sweetalert2';

// Context
import { useIsPartCopyStatus } from 'contexts/TreatmentManagement/MedicalRecord';
import { useIsHistoryLoadingStatus } from 'contexts/TreatmentManagement/MedicalRecord/IsHistoryLoadingContext';
import { useHistoryStore } from 'store/TreatmentManagement';

// Component
import { CCPECopy } from './CCPECopy';
import { DxICD10Copy } from './DxICD10Copy';
import { PrescriptionCopy } from './PrescriptionCopy';
import Loading from 'components/CustomComponents/Loading';
import { SwalToast } from 'components/CustomComponents/SwalComponents';
import { useCCPEStore } from 'store/TreatmentManagement';
import { useDxStore } from 'store/TreatmentManagement/useDxStore';
import { DxProps } from 'store/TreatmentManagement/useDxStore';
import {
    PrescriptionProps,
    usePrescriptionStore,
} from 'store/TreatmentManagement/usePrescriptionStore';
import {
    HistoryDxListProps,
    useHistoryCCPEStore,
    useHistoryDxListStore,
    useHistoryPrescriptionStore,
    HistoryPrescriptionProps,
} from 'store/TreatmentManagement/HistoryRecord/';
import { useIsCopyDrugFromHistoryStatus } from 'contexts/TreatmentManagement/IsCopyDrugFromHistoryContext';
import { DrugProps } from 'store/TreatmentManagement/useHistoryStore';

type DiagnosisProps = {
    icd_10_in_use_uid: string;
    icd_code: string;
    ds: string;
    name_tw: string;
};

// type DrugProps = {
//     qty: string;
//     qty_in_day: string;
//     dose_days: number;
//     given_qty: string;
//     total_qty: string;
//     freq: string;
//     freq_custom_code: string;
//     special_mark: string | string[];
//     drugs_in_use_uid: string;
//     drug_no: string;
//     name_tw: string;
// };

type InfoProps = {
    data_id: string;
    cc: string;
    treated_at: string;
};

type SuccessResult = {
    result: 'success';
    info?: InfoProps;
    diagnosis?: DiagnosisProps[];
    drugs?: DrugProps[];
};

type FailureResult = {
    result: 'failure';
    msg: string;
};

type ApiResponse = SuccessResult | FailureResult;

function isSuccessResult(result: ApiResponse): result is SuccessResult {
    return result.result === 'success';
}

export const CopyData: React.FC = () => {
    const [selected, setSelected] = useState<number>(-1);
    const { historyCCPEData, updateHistoryCCPEData } = useHistoryCCPEStore();
    const { isCopyDrugFromHistoryStatus, setIsCopyDrugFromHistoryStatus } =
        useIsCopyDrugFromHistoryStatus();
    const { updateHistoryDxList } = useHistoryDxListStore();
    const {
        historyPrescriptionList,
        updateHistoryPrescription,
        appendHistoryPrescription,
    } = useHistoryPrescriptionStore();
    const { historyData } = useHistoryStore();
    const { isHistoryLoadingStatus } = useIsHistoryLoadingStatus();
    const { isPartCopyStatus, setIsPartCopyStatus, copyWayStatus } =
        useIsPartCopyStatus();
    const { dxList, updateDx, appendDx } = useDxStore();
    const { CCPEData, updateCCPEData } = useCCPEStore();
    const {
        prescriptionList,
        updatePrescription,
        appendPrescription,
        setPrescriptionList,
    } = usePrescriptionStore();

    const [info, setInfo] = useState<InfoProps | null>(null);
    const [drugs, setDrugs] = useState<DrugProps[]>([]);
    const [diagnosis, setDiagnosis] = useState<DiagnosisProps[]>([]);

    const handleChangeCCPE = () => {
        // 1覆蓋 2接上
        if (copyWayStatus === '1') {
            updateCCPEData({
                cc: info ? info.cc : '',
            });
        } else {
            updateCCPEData({
                cc: info
                    ? CCPEData.cc
                        ? CCPEData.cc + ',' + info.cc
                        : info.cc
                    : '',
            });
        }

        setIsPartCopyStatus(false);
        SwalToast.fire({
            icon: 'success',
            title: '區 Co 成功',
        });
    };

    const mapDiagnosisToDxProps = (diagnosis: DiagnosisProps): DxProps => {
        return {
            data_id: diagnosis.icd_10_in_use_uid,
            short_code: '',
            icd_code: diagnosis.icd_code,
            code: '',
            name_en: '',
            name_tw: diagnosis.name_tw,
            original_name: '',
            attach_ccpe: '',
        };
    };

    const handleChangeDxICD10 = async () => {
        console.log('有進這個');
        const findFirstEmptyIndex = (): number | null => {
            for (let i = 0; i < dxList.length; i++) {
                if (!dxList[i].data_id) {
                    return i;
                }
            }
            return null;
        };
        const isDuplicateDataId = (data_id: string): boolean => {
            return dxList.some(dx => dx.data_id === data_id);
        };
        const showWarning = (name_tw: string) => {
            return Swal.fire({
                icon: 'warning',
                title: '重複輸入',
                text: `${name_tw}`,
                showConfirmButton: false,
                timer: 1500,
            });
        };
        let hasDuplicate = false;
        // 1 覆蓋 2 接上
        if (copyWayStatus === '1') {
            console.log('近1');
            let newDxList: DxProps[] = diagnosis.map(mapDiagnosisToDxProps);
            newDxList.forEach((dx, index) => {
                updateDx(index, dx); // 覆盖模式
            });
        } else {
            console.log('近2');
            let newDxList: DxProps[] = diagnosis.map(mapDiagnosisToDxProps);
            try {
                for (const dx of newDxList) {
                    if (isDuplicateDataId(dx.data_id)) {
                        hasDuplicate = true;
                        console.log('重複');
                        await showWarning(dx.name_tw);
                        break;
                    } else {
                        const emptyIndex = findFirstEmptyIndex();
                        if (emptyIndex !== null) {
                            updateDx(emptyIndex, dx);
                        } else {
                            appendDx(dx);
                        }
                    }
                }
            } catch (error) {
                console.error('處理診斷列表時發生錯誤：', error);
                // 可以在這裡添加更多錯誤處理邏輯，例如顯示錯誤消息給用戶
            }
        }
        if (!hasDuplicate) {
            setIsPartCopyStatus(false);
            SwalToast.fire({
                icon: 'success',
                title: '區 Co 成功',
            });
        }
        setIsPartCopyStatus(false);
    };

    const mapDrugToPrescriptionProps = (
        drug: DrugProps
    ): HistoryPrescriptionProps => {
        console.log(drug, '近來的到底是啥');
        return {
            controlled_level: drug.controlled_level ?? '',
            cost: drug.cost ?? '',
            data_id: drug.drugs_in_use_uid,
            disabled_date: drug.disabled_date ?? '',
            dosage_form: '',
            dose_day: drug.dose_days ? drug.dose_days.toString() : '',
            dose: drug.dose_days ? drug.dose_days.toString() : '',
            dosing_days: '',
            drug_no: drug.drug_no,
            drug_price: drug.drug_price ? Number(drug.drug_price) : 0,
            drug_type: drug.drug_type,
            freq: drug.freq,
            max_dose: drug.max_dose ?? '',
            max_dose_day: drug.max_dose_day ?? '',
            name_en: drug.name_en,
            name_tw: drug.name_tw,
            route_of_administration: '',
            self_price: drug.self_price ?? '0',
            short_code: drug.short_code,
            taking_time: '',
            unit: '',
            qty: drug.qty,
            qty_in_day: drug.qty_in_day,
            total_qty: drug.total_qty,
            given_qty: drug.given_qty,
            memo: '',
            site: drug.site ?? '',
            special_mark: drug.special_mark ?? '',
            freq_custom_code: drug.freq_custom_code,
            freq_directions: drug.freq_directions,
        };
    };
    const handleChangeDrug = async () => {
        const isDuplicateDrug = (drug_in_use_uid: string): boolean => {
            return prescriptionList.some(
                prescription => prescription.data_id === drug_in_use_uid
            );
        };
        console.log('有進加藥', drugs);
        let updatedPrescriptionList = [...prescriptionList];
        for (const drug of drugs) {
            const prescription = mapDrugToPrescriptionProps(drug);
            const inputValues: string[] = [];

            if (isDuplicateDrug(drug.drugs_in_use_uid)) {
                try {
                    const result = await Swal.fire({
                        icon: 'warning',
                        title: '重複輸入',
                        text: `${drug.name_tw} 已存在，是否要繼續添加？`,
                        showCancelButton: true,
                        confirmButtonText: '添加',
                        cancelButtonText: '跳過',
                    });

                    if (result.isConfirmed) {
                        updatedPrescriptionList.push({ ...prescription });
                    }
                } catch (error) {
                    console.error('處理重複藥物時發生錯誤：', error);
                    // 可以在這裡添加更多錯誤處理邏輯，例如顯示錯誤消息給用戶
                }
            } else {
                console.log('沒有重複', prescription);
                updatedPrescriptionList.push({ ...prescription });
            }
        }

        // 更新處方列表

        // 對每個新增的處方調用 appendPrescription
        const originalLength = prescriptionList.length;
        for (let i = originalLength; i < updatedPrescriptionList.length; i++) {
            appendPrescription(updatedPrescriptionList[i], []);
        }

        setIsPartCopyStatus(false);
        setIsCopyDrugFromHistoryStatus(true);
        SwalToast.fire({
            icon: 'success',
            title: '區 Co 成功',
        });
    };

    const convertDiagnosisToHistoryDxListProps = (
        diagnosis: DiagnosisProps
    ): HistoryDxListProps => ({
        data_id: diagnosis.icd_10_in_use_uid,
        short_code: '',
        icd_code: diagnosis.icd_code,
        code: '',
        name_en: '',
        name_tw: diagnosis.name_tw,
        original_name: '',
        attach_ccpe: '',
    });

    useEffect(() => {
        if (historyData) {
            setInfo(historyData.info || null);
            updateHistoryCCPEData({
                cc: historyData.info ? historyData.info.cc : '',
            });
            setDrugs(
                Array.isArray(historyData.drugs)
                    ? historyData.drugs.map(drug => ({
                          ...drug,
                          special_mark: Array.isArray(drug.special_mark)
                              ? drug.special_mark
                                    .filter(
                                        mark => mark !== '' && mark !== 'None'
                                    )
                                    .join(',')
                              : drug.special_mark,
                      }))
                    : []
            );
            setDiagnosis(historyData.diagnosis || []);
            if (historyData.diagnosis && historyData.diagnosis.length > 0) {
                historyData.diagnosis.forEach((diag, index) => {
                    const convertedDiag =
                        convertDiagnosisToHistoryDxListProps(diag);
                    updateHistoryDxList(index, convertedDiag);
                });
            }
            if (historyData.drugs && historyData.drugs.length > 0) {
                historyData.drugs.forEach((drug, index) => {
                    const prescription = mapDrugToPrescriptionProps(drug);

                    const inputValues: string[] = [];
                    updateHistoryPrescription(index, prescription, inputValues);
                });
            }
        }
    }, [historyData]);

    return (
        <>
            <Flex direction={'column'} gap={'8px'}>
                {/* {isHistoryLoadingStatus ? (<> */}
                <Box pos={'relative'} p={'6px 12px'} m={'3px'}>
                    {<CCPECopy info={info} />}
                    <Box
                        hidden={isPartCopyStatus ? false : true}
                        pos={'absolute'}
                        inset={0}
                        zIndex={10}
                        _hover={{
                            cursor: 'pointer',
                            bg: 'rgba(3,102,214,0.05)',
                            boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                        }}
                        onClick={handleChangeCCPE}
                    ></Box>
                </Box>

                <Box pos={'relative'} p={'6px 12px'} m={'3px'}>
                    <DxICD10Copy diagnosis={diagnosis} />
                    <Box
                        hidden={isPartCopyStatus ? false : true}
                        pos={'absolute'}
                        inset={0}
                        zIndex={10}
                        _hover={{
                            cursor: 'pointer',
                            bg: 'rgba(3,102,214,0.05)',
                            boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                        }}
                        onClick={handleChangeDxICD10}
                    ></Box>
                </Box>

                <Box pos={'relative'} p={'6px 12px'} m={'3px'}>
                    <PrescriptionCopy drugs={drugs} />
                    <Box
                        hidden={isPartCopyStatus ? false : true}
                        pos={'absolute'}
                        inset={0}
                        zIndex={10}
                        _hover={{
                            cursor: 'pointer',
                            bg: 'rgba(3,102,214,0.05)',
                            boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                        }}
                        onClick={handleChangeDrug}
                    ></Box>
                </Box>
                {/* </>): (<>
                    <Box className='mt-60'>
                        <Box className=' relative'>
                        <Loading/>
                             </Box>
                    </Box>
                </>)}  */}
            </Flex>
        </>
    );
};

export default CopyData;
