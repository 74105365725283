import { Flex, Box, Text } from '@chakra-ui/react';

type HeaderProps = {
    tabIndex: number;
};
export const Header: React.FC<HeaderProps> = ({ tabIndex }) => {
    return (
        <>
            <Flex
                bg={'#D9D9D9'}
                pos={'sticky'}
                top={0}
                left={0}
                w={'100%'}
                zIndex={10}
            >
                <Box flex={'0 0 5%'}>
                    <Text
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                        textAlign={'center'}
                    >
                        {tabIndex === 0 ? '作廢' : tabIndex === 1 ? '復原' : ''}
                    </Text>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                        textAlign={'center'}
                    >
                        簽章
                    </Text>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text
                        textAlign={'center'}
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                    >
                        寫入
                    </Text>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text
                        textAlign={'center'}
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                    >
                        補正
                    </Text>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                        textAlign={'center'}
                    >
                        病歷
                    </Text>
                </Box>
                {/* <Box flex={'0 0 5%'}>
                    <Text
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                        textAlign={'center'}
                    >
                        狀態
                    </Text>
                </Box> */}
                <Box flex={'0 0 10%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        時間
                    </Text>
                </Box>
                <Box flex={'0 0 10%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        病歷號
                    </Text>
                </Box>
                <Box flex={'1 0 10%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        姓名
                    </Text>
                </Box>
                <Box flex={'0 0 10%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        生日
                    </Text>
                </Box>
                <Box flex={'0 0 10%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        身分證號
                    </Text>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text
                        textAlign={'center'}
                        px={'4px'}
                        borderLeft={'1px solid #fff'}
                    >
                        還單
                    </Text>
                </Box>
                <Box flex={'0 0 10%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        類別
                    </Text>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        序號
                    </Text>
                </Box>
                <Box flex={'0 0 5%'}>
                    <Text px={'4px'} borderLeft={'1px solid #fff'}>
                        保健
                    </Text>
                </Box>
            </Flex>
        </>
    );
};
