// import axios from 'axios';
import axiosInstance from './axiosInstance';

import apiService from './apiService';

export type drugTypeListProps = {
    data_id: string;
    code: string;
    name: string;
};

// 取得藥品設定的藥類
export const callDrugTypeListService = async (token: string) => {
    return apiService<string, any>({
        method: 'GET',
        path: '/api/acc_items/lists/for_drug',
        token,
    });
};
