import { ADDateToROCStringyyyMMddHHmmssNoDot } from 'functions/ADDateToROCStringyyyMMddHHmmssNoDot';
import { ADDateToROCStringNoDot } from 'functions/ADDateToROCStringNoDot';
import { mb1Props, TreatmentInfoProps } from 'store/TreatmentManagement/useTreatmentInfoStore';
import { ICCardUpdateDrugProps } from 'store/TreatmentManagement/ICCardUpdate/useICCardUpdateStore';
import { drugTypeListProps } from 'service/drugTypeListService';
import { RegisterBasicProps } from 'functions/hisGetRegisterBasic';
import { SeqNumber256N1DataProps } from 'functions/hisGetSeqNumber256N1';
import { DrugFreqListsProps } from 'store/DataSetting/useDrugFreqListStore';
import { hisGetTreatNumNoICCardResProps } from 'functions/hisGetTreatNumNoICCard';

type hisWritePrescriptionProps = {
    pDateTime: string;
    pPatientID: string;
    pPatientBirthDate: string;
    pDataWrite: string;
    iWriteCount: number;
};

export const hisWritePrescriptionLogic = (
    treatmentInfo: TreatmentInfoProps,
    ic_data_drugs: ICCardUpdateDrugProps[],
    drugTypeList: drugTypeListProps[],
    drugFreqLists: DrugFreqListsProps[],
    seqNumber256N1Result?: SeqNumber256N1DataProps,
    basicResult?: RegisterBasicProps,
    hisGetTreatNumNoICCardResult?: hisGetTreatNumNoICCardResProps,
    mb1?: mb1Props
): Promise<hisWritePrescriptionProps> => {
    let pDateTime = treatmentInfo.medical_record?.mb1.treated_at || seqNumber256N1Result?.visitDateTime || hisGetTreatNumNoICCardResult?.pBuffer.computerDateTime || ''; // 就診日期時間
    let pPatientBirthDate = basicResult?.birthDate ? (basicResult.birthDate) : 
                             treatmentInfo.birth_date ? ADDateToROCStringNoDot(new Date(treatmentInfo.birth_date)) : 
                             ''; // 出生日期
    let pPatientID = basicResult?.idOrDocumentNumber || treatmentInfo.id_no || hisGetTreatNumNoICCardResult?.pBuffer.medicalTreatmentIdentificationCode || ''; // 身分證號碼
    // let pDataWriteFake = `${pDateTime}1AC31912100        QID               3 3.00   01`;
    let pDataWrite =
        ic_data_drugs &&
        ic_data_drugs
            .map((ic_data_drug, index) => {
                console.log(drugFreqLists, '先看看吧', ic_data_drug.freq);
                console.log(`Index: ${index}`);
                console.log(`pDateTime: ${pDateTime.padEnd(13, ' ')}`);
                // console.log(`drug_type: ${ic_data_drug.drug_type.padEnd(1, ' ') ?? ' '}`);
                console.log(`drug_type: ${'1'.padEnd(1, ' ')}`);
                console.log(
                    `drug_no: ${ic_data_drug.drug_no?.padEnd(12, ' ') ?? ''.padEnd(12, ' ')}`
                );
                console.log(
                    `site: ${ic_data_drug.site?.padEnd(6, ' ') ?? ''.padEnd(6, ' ')}`
                );
                console.log(
                    `freq: ${'TID'?.padEnd(18, ' ') ?? ''.padEnd(18, ' ')}`
                );
                console.log(
                    `dose_days: ${'3'.padEnd(2, ' ') ?? ''.padEnd(2, ' ')}`
                );
                console.log(
                    `total_qty: ${ic_data_drug.total_qty?.toString().padEnd(7, ' ') ?? ''.padEnd(7, ' ')}`
                );
                console.log(
                    `memo: ${'01'.padEnd(2, ' ') ?? ''.padEnd(2, ' ')}`
                );
                let drugFreq = drugFreqLists.find(
                    (item: DrugFreqListsProps) =>
                        item.data_id === ic_data_drug.freq
                )?.code;

                console.log(drugFreq, '看一下這會對到什麼');
                return [
                    pDateTime.padEnd(13, ' '), // 就診日期時間 (1-13)
                    // ic_data_drug.drug_type.padEnd(1, ' ') ?? ' ', // 醫令類別 (14)
                    '1'.padEnd(1, ' '), // 醫令類別 (14)
                    ic_data_drug.drug_no?.padEnd(12, ' ') ?? ''.padEnd(12, ' '), // 診療項目代號 (15-26) // 10碼
                    ic_data_drug.site?.padEnd(6, ' ') ?? ''.padEnd(6, ' '), // 診療部位 (27-32)  // S(大部分不給)
                    // ic_data_drug.freq?.padEnd(18, ' ') ?? ''.padEnd(18, ' '), // 用法 (33-50)  //BID
                    drugFreq?.padEnd(18, ' '),
                    ic_data_drug.dose_days?.toString().padEnd(2, ' ') ??
                        ''.padEnd(2, ' '), // 天數 (51-52)
                    // '3'.padEnd(2, ' '),
                    ic_data_drug.total_qty?.toString().padEnd(7, ' ') ??
                        ''.padEnd(7, ' '), // 總量 (53-59)
                    ic_data_drug.memo?.padEnd(2, ' ') ?? ''.padEnd(2, ' '), // 交付處方註記 (60-61)
                    // '01'.padEnd(2, ' ') // 交付處方註記 (60-61)
                ].join('');
            })
            .join('');

    // console.log(pDataWrite, '先看一下怎麼跑吧哎', ic_data_drugs);

    pDataWrite += '\0'; // 添加一個 null 字符作為結尾

    // 建立最終數據對象
    let finalData: hisWritePrescriptionProps = {
        pDateTime: pDateTime.toString(), // 就診日期時間
        pPatientID: pPatientID, // 身分證號碼
        pPatientBirthDate: pPatientBirthDate.toString(), // 出生日期
        pDataWrite: pDataWrite, // 拼接後的字符串數據
        iWriteCount: ic_data_drugs.length,
    };

    console.log(finalData, '寫卡處方數據');
    return Promise.resolve(finalData);
};
