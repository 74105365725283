import apiService from './apiService';

// type MedicalRecord = {
//     treated_at: string; // 就醫日期時間, 西元年月日YYYY-mm-dd
//     data_id: string; // 病歷資料id
//     case_type: string; // 案件分類
//     card_no: string; // 卡號
//     doctor_name: string; // 該次看診醫師姓名
//     icd_code: string; // 主診斷國際病名碼
//     diagnosis_name: string; // 診斷名稱
// };

// 18. 取得特定病患的某次就醫資訊（歷史病歷用）
export const callMedicalRecordHistorySpecService = async (
    token: string,
    data?: string
) => {
    const queryParams = data ? { data } : undefined;
    const response = await apiService<string, any>({
        method: 'GET',
        path: '/api/medical_record/history/spec',
        token,
        data: queryParams,
    });

    if (response.result === 'success') {
        // 將 drugs 中的 special_mark 由陣列轉成字串
        if (response.drugs && Array.isArray(response.drugs)) {
            response.drugs = response.drugs.map((drug: any) => ({
                ...drug,
                special_mark: Array.isArray(drug.special_mark)
                    ? drug.special_mark.join(',')
                    : drug.special_mark,
            }));
        }

        return {
            result: 'success',
            info: response.info,
            diagnosis: response.diagnosis,
            drugs: response.drugs,
        };
    } else {
        return {
            result: 'failure',
            msg: response.msg || '獲取特定病患就醫資訊失敗',
        };
    }

    return;
};
