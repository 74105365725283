import { DxProps } from 'store/TreatmentManagement/useDxStore'

export const isChronicLogic = (dxList: DxProps[]):boolean => {
    

const chronicConditions: string[] = [
    'A15', 'A16', 'A17', 'A18', 'A19', 'A30', 'B349', 'B35', 'B36', 'B37', 'B38', 'B39', 'B40', 'B41', 'B42', 'B43', 'B44', 'B45', 'B46', 'B47', 'B48', 'B49',
    'C00', 'C01', 'C02', 'C03', 'C04', 'C05', 'C06', 'C07', 'C08', 'C09', 'C10', 'C11', 'C12', 'C13', 'C14', 'C15', 'C16', 'C17', 'C18', 'C19', 'C20', 'C21', 'C22', 'C23', 'C24', 'C25', 'C26', 'C27', 'C28', 'C29', 'C30', 'C31', 'C32', 'C33', 'C34',
    'C37', 'C38', 'C39', 'C40', 'C41',
    'C43', 'C44', 'C45', 'C46', 'C47', 'C48', 'C49', 'C50', 'C51', 'C52', 'C53', 'C54',
    'C56', 'C57', 'C58', 'C59', 'C60', 'C61', 'C62', 'C63', 'C64', 'C65', 'C66', 'C67', 'C68',
    'C70', 'C71', 'C72', 'C73', 'C74', 'C75',
    'C80', 'C81', 'C82', 'C83', 'C84', 'C85', 'C86',
    'C88', 'C89', 'C90', 'C91', 'C92', 'C93', 'C94', 'C95', 'C96',
    'D00', 'D01', 'D02', 'D03', 'D04', 'D05', 'D06', 'D07', 'D08', 'D09',
    'D32', 'D33',
    'D45', 'D46', 'D47', 'D48',
    'D50', 'D51', 'D52', 'D53', 'D54', 'D55', 'D56', 'D57', 'D58', 'D59', 'D60', 'D61', 'D62', 'D63', 'D64', 'D65', 'D66', 'D67', 'D68', 'D69', 'D70', 'D71', 'D72', 'D73', 'D74', 'D75', 'D76', 'D77',
    'D80', 'D81', 'D82', 'D83', 'D84',
    'D890', 'D899',
    'E00', 'E01', 'E02', 'E03', 'E04', 'E05', 'E06', 'E07', 'E08', 'E09', 'E10', 'E11', 'E12', 'E13',
    'E15', 'E16', 'E17', 'E18', 'E19', 'E20', 'E21', 'E22', 'E23', 'E24', 'E25', 'E26', 'E27', 'E28', 'E29', 'E30', 'E31', 'E32', 'E33', 'E34', 'E35',
    'E65', 'E66', 'E67', 'E68', 'E69', 'E70', 'E71', 'E72', 'E73', 'E74', 'E75', 'E76', 'E77', 'E78', 'E79', 'E80', 'E81', 'E82', 'E83', 'E84', 'E85', 'E86', 'E87', 'E88', 'E89',
    'F01', 'F02', 'F03', 'F04', 'F05', 'F06', 'F07', 'F08', 'F09', 'F10', 'F11', 'F12', 'F13', 'F14', 'F15', 'F16', 'F17', 'F18', 'F19', 'F20', 'F21', 'F22', 'F23', 'F24', 'F25', 'F26', 'F27', 'F28', 'F29', 'F30', 'F31', 'F32', 'F33', 'F34', 'F35', 'F36', 'F37', 'F38', 'F39', 'F40', 'F41', 'F42', 'F43', 'F44', 'F45', 'F46', 'F47', 'F48',
    'F50', 'F51',
    'F53', 'F54', 'F55',
    'F60',
    'F63',
    'F6810',
    'F69',
    'F80', 'F81', 'F82',
    'F84',
    'F88', 'F89', 'F90', 'F91', 'F92', 'F93', 'F94', 'F95', 'F96', 'F97', 'F98', 'F99',
    'G04', 'G05', 'G06', 'G07', 'G08', 'G09', 'G10', 'G11', 'G12', 'G13',
    'G20', 'G21', 'G22', 'G23', 'G24', 'G25', 'G26', 'G27', 'G28', 'G29', 'G30', 'G31', 'G32',
    'G35', 'G36', 'G37',
    'G40', 'G41', 'G42', 'G43', 'G44', 'G45',
    'G47',
    'G50', 'G51', 'G52', 'G53', 'G54', 'G55', 'G56', 'G57', 'G58', 'G59', 'G60', 'G61', 'G62', 'G63', 'G64', 'G65', 'G66', 'G67', 'G68', 'G69', 'G70', 'G71', 'G72', 'G73',
    'G80', 'G81', 'G82', 'G83',
    'G90', 'G91', 'G92', 'G93', 'G94', 'G95', 'G96', 'G97', 'G98', 'G99',
    'H00', 'H01', 'H02',
    'H04', 'H05',
    'H10', 'H11',
    'H15', 'H16', 'H17', 'H18',
    'H20', 'H21', 'H22',
    'H27',
    'H30', 'H31', 'H32', 'H33', 'H34', 'H35', 'H36',
    'H40', 'H41', 'H42', 'H43', 'H44',
    'H46', 'H47',
    'H49',
    'H52', 'H53',
    'H55', 'H56', 'H57', 'H58', 'H59', 'H60', 'H61', 'H62',
    'H65', 'H66', 'H67', 'H68', 'H69', 'H70', 'H71', 'H72', 'H73', 'H74', 'H75',
    'H80',
    'H91', 'H92', 'H93', 'H94', 'H95',
    'I05', 'I06', 'I07', 'I08', 'I09',
    'I11',
    'I15',
    'I20',
    'I21', 'I22', 'I23', 'I24', 'I25',
    'I45', 'I46', 'I47', 'I48', 'I49', 'I50', 'I51', 'I52',
    'I60', 'I61', 'I62', 'I63', 'I64', 'I65', 'I66', 'I67',
    'I70', 'I71', 'I72', 'I73', 'I74', 'I75',
    'I77', 'I78', 'I79',
    'I88',
    'I96', 'I97',
    'J30', 'J31', 'J32',
    'J34',
    'J36', 'J37', 'J38', 'J39', 'J40', 'J41', 'J42', 'J43', 'J44', 'J45',
    'J60',
    'J66', 'J67', 'J68',
    'J709',
    'J8482',
    'K25', 'K26', 'K27', 'K28', 'K29', 'K30', 'K31',
    'K50', 'K51', 'K52', 'K53', 'K54', 'K55',
    'K60', 'K61', 'K62', 'K63', 'K64', 'K65', 'K66', 'K67', 'K68',
    'K70', 'K71', 'K72', 'K73', 'K74', 'K75', 'K76', 'K77',
    'K82', 'K83', 'K84', 'K85', 'K86', 'K87', 'K88', 'K89', 'K90', 'K91', 'K92',
    'K94',
    'L00',
    'L10', 'L11', 'L12', 'L13', 'L14', 'L15', 'L16', 'L17', 'L18', 'L19', 'L20', 'L21', 'L22', 'L23', 'L24', 'L25', 'L26', 'L27', 'L28', 'L29', 'L30', 'L31', 'L32', 'L33', 'L34', 'L35', 'L36', 'L37', 'L38', 'L39', 'L40', 'L41', 'L42', 'L43', 'L44', 'L45', 'L46', 'L47', 'L48', 'L49', 'L50', 'L51', 'L52', 'L53', 'L54', 'L55', 'L56', 'L57', 'L58', 'L59', 'L60', 'L61', 'L62', 'L63', 'L64', 'L65', 'L66', 'L67', 'L68', 'L69', 'L70', 'L71', 'L72', 'L73', 'L74', 'L75',
    'L80', 'L81', 'L82', 'L83', 'L84', 'L85', 'L86', 'L87', 'L88', 'L89', 'L90', 'L91', 'L92', 'L93', 'L94', 'L95', 'L96', 'L97', 'L98', 'L99',
    'M00', 'M01', 'M02', 'M03', 'M04', 'M05', 'M06', 'M07', 'M08',
    'M11', 'M12', 'M13', 'M14', 'M15', 'M16', 'M17', 'M18', 'M19', 'M20', 'M21', 'M22', 'M23', 'M24', 'M25',
    'M30', 'M31', 'M32', 'M33', 'M34', 'M35', 'M36',
    'M40', 'M41', 'M42', 'M43',
    'M45', 'M46',
    'M48',
    'M53',
    'M5410',
    'M5418',
    'M60', 'M61', 'M62', 'M63', 'M64', 'M65', 'M66', 'M67', 'M68', 'M69', 'M70', 'M71', 'M72', 'M73', 'M74', 'M75', 'M76', 'M77', 'M78', 'M79', 'M80', 'M81',
    'M84', 'M85', 'M86', 'M87', 'M88', 'M89', 'M90', 'M91', 'M92', 'M93', 'M94', 'M95', 'M96',
    'M99',
    'N00', 'N01', 'N02', 'N03', 'N04', 'N05', 'N06', 'N07', 'N08',
    'N10', 'N11', 'N12', 'N13', 'N14', 'N15', 'N16', 'N17', 'N18', 'N19', 'N20',
    'N25', 'N26', 'N27', 'N28',
    'N39', 'N40', 'N41', 'N42', 'N43', 'N44', 'N45',
    'N476',
    'N48', 'N49', 'N50', 'N51', 'N52', 'N53',
    'N82', 'N83', 'N84', 'N85', 'N86', 'N87', 'N88', 'N89', 'N90', 'N91', 'N92', 'N93', 'N94', 'N95', 'N96', 'N97',
    'N981',
    'N992',
    'N994',
    'N9983',
    'O28',
    'P09',
    'P293',
    'P926',
    'Q00', 'Q01', 'Q02', 'Q03', 'Q04', 'Q05', 'Q06', 'Q07',
    'Q150',
    'Q20', 'Q21', 'Q22', 'Q23', 'Q24', 'Q25', 'Q26', 'Q27', 'Q28', 'Q29', 'Q30', 'Q31', 'Q32', 'Q33', 'Q34', 'Q35', 'Q36', 'Q37', 'Q38', 'Q39', 'Q40', 'Q41', 'Q42', 'Q43', 'Q44', 'Q45',
    'Q650', 'Q651', 'Q652', 'Q653', 'Q654', 'Q655', 'Q656', 'Q657', 'Q658', 'Q659', 'Q660', 'Q661', 'Q662', 'Q663', 'Q664', 'Q665', 'Q666', 'Q667', 'Q668', 'Q669',
    'Q676', 'Q677', 'Q678',
    'Q68', 'Q69', 'Q70', 'Q71', 'Q72', 'Q73', 'Q74', 'Q75', 'Q76', 'Q77', 'Q78', 'Q79',
    'Q870',
    'R000',
    'R001',
    'R002',
    'R008', 'R009', 'R010', 'R011', 'R012',
    'R030', 'R031',
    'R04', 'R05', 'R06', 'R07',
    'R0901', 'R0902',
    'R093',
    'R0981',
    'R0982',
    'R0989',
    'R10',
    'R11',
    'R160', 'R161', 'R162',
    'R17',
    'R18', 'R19',
    'R252',
    'R26', 'R27', 'R28', 'R29',
    'R300',
    'R301',
    'R309',
    'R32',
    'R34', 'R35', 'R36',
    'R39',
    'R40',
    'R41', 'R42', 'R43', 'R44', 'R45', 'R46', 'R47', 'R48', 'R49', 'R50', 'R51', 'R52', 'R53',
    'R55', 'R56', 'R57', 'R58', 'R59',
    'R60', 'R61', 'R62', 'R63',
    'R64',
    'R65', 'R66', 'R67', 'R68',
    'R69',
    'R70', 'R71', 'R72', 'R73', 'R74', 'R75', 'R76', 'R77', 'R78', 'R79',
    'R80', 'R81', 'R82', 'R83', 'R84', 'R85', 'R86', 'R87', 'R88', 'R89',
    'R90', 'R91', 'R92', 'R93', 'R94', 'R95', 'R96', 'R97',
    'R99',
    'S00', 'S01', 'S02', 'S03', 'S04', 'S05', 'S06', 'S07', 'S08', 'S09', 'S10', 'S11', 'S12', 'S13', 'S14', 'S15', 'S16', 'S17', 'S18', 'S19', 'S20', 'S21', 'S22', 'S23', 'S24', 'S25', 'S26', 'S27', 'S28', 'S29', 'S30', 'S31', 'S32', 'S33', 'S34', 'S35', 'S36', 'S37', 'S38', 'S39', 'S40', 'S41', 'S42', 'S43', 'S44', 'S45', 'S46', 'S47', 'S48', 'S49', 'S50', 'S51', 'S52', 'S53', 'S54', 'S55', 'S56', 'S57', 'S58', 'S59', 'S60', 'S61', 'S62', 'S63', 'S64', 'S65', 'S66', 'S67', 'S68', 'S69', 'S70', 'S71', 'S72', 'S73', 'S74', 'S75', 'S76', 'S77', 'S78', 'S79', 'S80', 'S81', 'S82', 'S83', 'S84', 'S85', 'S86', 'S87', 'S88', 'S89', 'S90', 'S91', 'S92', 'S93', 'S94', 'S95', 'S96', 'S97', 'S98', 'S99',
    'T00', 'T01', 'T02', 'T03', 'T04', 'T05', 'T06', 'T07', 'T08', 'T09', 'T10', 'T11', 'T12', 'T13', 'T14', 'T15', 'T16', 'T17', 'T18', 'T19', 'T20', 'T21', 'T22', 'T23', 'T24', 'T25', 'T26', 'T27', 'T28', 'T29', 'T30', 'T31', 'T32', 'T33', 'T34', 'T35', 'T36', 'T37', 'T38', 'T39', 'T40', 'T41', 'T42', 'T43', 'T44', 'T45', 'T46', 'T47', 'T48', 'T49', 'T50', 'T51', 'T52', 'T53', 'T54', 'T55', 'T56', 'T57', 'T58', 'T59', 'T60', 'T61', 'T62', 'T63', 'T64', 'T65', 'T66', 'T67', 'T68', 'T69', 'T70', 'T71', 'T72', 'T73', 'T74', 'T75', 'T76', 'T77', 'T78', 'T79', 'T80', 'T81', 'T82', 'T83', 'T84', 'T85', 'T86', 'T87', 'T88',
    'Z0271',
    'Z0279',
    'Z0289',
    'Z029',
    'Z048',
    'Z049',
    'Z08', 'Z09',
    'Z280', 'Z281', 'Z282',
    'Z288', 'Z289',
    'Z322',
    'Z323',
    'Z4681',
    'Z515',
    'Z5189',
    'Z53',
    'Z6981',
    'Z70', 'Z71',
    'Z75', 'Z76',
    'Z763', 'Z764', 'Z765', 'Z766', 'Z767', 'Z768',
    'Z7689',
    'C4A',
    'C7A', 'C7B',
    'M1A', 'M10'
];
const chronicConditionsRegex = new RegExp(`^(${chronicConditions.join('|')})`);

return dxList.some(dx => chronicConditionsRegex.test(dx.icd_code));
} 