import { Center } from '@chakra-ui/react';

const NavManagementIcon = ({ onClick }: any) => {
    return (
        <Center>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
            >
                <path
                    d="M3.07422 7V17C3.07422 17.5304 3.28493 18.0391 3.66001 18.4142C4.03508 18.7893 4.54379 19 5.07422 19H19.0742C19.6047 19 20.1134 18.7893 20.4884 18.4142C20.8635 18.0391 21.0742 17.5304 21.0742 17V9C21.0742 8.46957 20.8635 7.96086 20.4884 7.58579C20.1134 7.21071 19.6047 7 19.0742 7H13.0742L11.0742 5H5.07422C4.54379 5 4.03508 5.21071 3.66001 5.58579C3.28493 5.96086 3.07422 6.46957 3.07422 7Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Center>
    );
};

export default NavManagementIcon;
