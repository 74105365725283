import React, {
    createContext,
    useContext,
    useReducer,
    useEffect,
    ReactNode,
} from 'react';
import { useVitalSignQuery } from 'components/WaitingListTable/WaitingList/useVitalSignQuery';
interface SignState {
    selectedSigns: string[];
}

type SignAction =
    | { type: 'TOGGLE_SIGN'; payload: string }
    | { type: 'RESET_SIGNS' };

interface SignContextType {
    state: SignState;
    dispatch: React.Dispatch<SignAction>;
}

const initialState: SignState = {
    selectedSigns: JSON.parse(localStorage.getItem('selectedSigns') || '[]'),
};

const SignContext = createContext<
    | {
          state: SignState;
          dispatch: React.Dispatch<SignAction>;
      }
    | undefined
>(undefined);

const reducer = (state: SignState, action: SignAction): SignState => {
    let newState;
    switch (action.type) {
        case 'TOGGLE_SIGN' : 
            const index = state.selectedSigns.indexOf(action.payload);
            if (index === -1) {
                newState = {
                    ...state,
                    selectedSigns: [...state.selectedSigns, action.payload],
                };
            } else {
                newState = {
                    ...state,
                    selectedSigns: state.selectedSigns.filter(
                        id => id !== action.payload
                    ),
                };
            }
            break;
        case 'RESET_SIGNS':
            newState = { selectedSigns: [] };
            break;
        default:
            throw new Error('Unhandled action type');
    }
    localStorage.setItem(
        'selectedSigns',
        JSON.stringify(newState.selectedSigns)
    );
    return newState;
};

interface SignProviderProps {
    children: ReactNode;
}
export const SignProvider = ({ children }: SignProviderProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <SignContext.Provider value={{ state, dispatch }}>
            {children}
        </SignContext.Provider>
    );
};

export const useSignContext = () => {
    const context = useContext(SignContext);
    if (!context) {
        throw new Error('useSignContext must be used within a SignProvider');
    }
    return context;
};
