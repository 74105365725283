import axios from 'axios';

// 請求病患授權
export interface VHCHisReadCSVAndGetLastTokenProps {
    HospID : string;
    sToken : string;
    ROCID: string;
} 
export const postGetVHCTeleMedicineReqCodeService = async (QRCodeString: VHCHisReadCSVAndGetLastTokenProps): Promise<any> => {
    console.log(QRCodeString, 'service這邊看看')
    try {
        console.log(QRCodeString, 'service看一下')
        const finalData = JSON.stringify(QRCodeString)
        const response = await axios.post('https://vhcapp.nhi.gov.tw/vhc/cloud/tlm/GetVHCTeleMedicineReqCode', finalData, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
            },
        });
        
        console.log(response.data, '再次要求授權');
        return response.data;
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.error('Error:', error.message);
        } else {
            console.error('An unknown error occurred');
        }
    }
};
// const handleEncryptAndSend = async () => {
//     try {
//       const encrypted = await encrypt(id, hospID);
//       setEncryptedId(encrypted);
//       console.log('加密后的ID:', encrypted);

//       const response = await axios.post('https://vhcapp.nhi.gov.tw/vhc/cloud/tlm/GetVHCTeleMedicineReqCode', {
//         "HospID": hospID,
//         "sToken": sToken,
//         "ROCID": encrypted
//       }, {
//         headers: {
//           'accept': '*/*',
//           'Content-Type': 'application/json'
//         }
//       });

//       console.log(response.data);
//       if (response.data && response.data.responseDesc === 'SUCCESS') {
//         setReqCode(response.data.responseBody.ReqCode);
//         setReqCodeExpire(response.data.responseBody.ReqCodeExpire);
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };
// export const getVHCQRCodeCloudToken = async (QRCodeString: string): Promise<any> => {
//     console.log(QRCodeString, 'service這邊看看')
//     try {
//         const response = await axios.post('http://localhost:3033/VHC/VHChisGetRegisterBasicJson', {
//             headers: {
//                 'accept': '*/*',
//                 'Content-Type': 'application/json',
//             },
//         });

//         console.log(response.data, '再次要求授權');
//         return response.data;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };