import {
    Box,
    Flex,
    List,
    ListItem,
    Tooltip,
    Input
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import React, { useState } from 'react';
import Swal from 'sweetalert2';

interface AllergyListProps {
    items: string[];
    isShowAll: boolean;
    editingIndex: number;
    handleDeleteItem: (index: number) => void;
    handleEditItem: (e: React.MouseEvent<HTMLDivElement>, index: number) => void;
    handleChangeItem: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    handleCancelItem: (index: number) => void;
    handleSaveItem: () => void;
    setEditingIndex: (index: number) => void;
    checkValue: string;
    setCheckValue: (value: string) => void;
}

export const AllergyLists: React.FC<AllergyListProps> = ({
    items,
    isShowAll,
    editingIndex,
    handleDeleteItem,
    handleEditItem,
    handleChangeItem,
    handleCancelItem,
    handleSaveItem,
    setEditingIndex,
    checkValue,
    setCheckValue
}) => {
    const [isCNTyping, setIsCNTyping] = useState<boolean>(false);

    return (
        <List
            stylePosition={isShowAll ? 'initial' : 'inside'}
            display={'flex'}
            flexWrap={isShowAll ? 'nowrap' : 'wrap'}
            columnGap={'32px'}
            flexDirection={isShowAll ? 'column' : 'row'}
            spacing={isShowAll ? '8px' : '0px'}
            maxH={isShowAll ? '480px' : 'auto'}
            overflowY={'auto'}
        >
            {items && items.map((item, index) => (
                <ListItem
                    key={index}
                    textColor={'rsDanger.500'}
                    display={'flex'}
                    gap={'8px'}
                >
                    <Box w={'auto'}>{index + 1}. </Box>
                    {isShowAll ? (
                        <>
                            {editingIndex === index ? (
                                <>
                                    <Input
                                        flex={'1 1 auto'}
                                        value={item}
                                        h={'28px'}
                                        px={'4px'}
                                        onChange={e => handleChangeItem(e, index)}
                                        autoFocus
                                        onCompositionStart={() => setIsCNTyping(true)}
                                        onCompositionEnd={() => setIsCNTyping(false)}
                                        onKeyDown={e => {
                                            if (!isCNTyping && e.key === 'Enter') {
                                                handleSaveItem();
                                            } else if (!isCNTyping && e.key === 'Escape') {
                                                handleCancelItem(index);
                                            }
                                        }}
                                    />
                                    <Flex h={'28px'} gap={'8px'} align={'center'}>
                                        <Tooltip label="取消" hasArrow bg={'gray'}>
                                            <Box
                                                opacity={0.5}
                                                _hover={{ opacity: '1', cursor: 'pointer' }}
                                                onClick={() => handleCancelItem(index)}
                                                w={'28px'}
                                            >
                                                <HeroIcons name="xmark" />
                                            </Box>
                                        </Tooltip>
                                        <Tooltip label="儲存" hasArrow bg={'gray'}>
                                            <Box
                                                opacity={0.5}
                                                _hover={{ opacity: '1', cursor: 'pointer' }}
                                                w={'28px'}
                                                onClick={handleSaveItem}
                                            >
                                                <HeroIcons name="check" />
                                            </Box>
                                        </Tooltip>
                                    </Flex>
                                </>
                            ) : (
                                <Flex h={'28px'} justify={'space-between'} w={'100%'} gap={'8px'}>
                                    <Box
                                        flex={'1 1 auto'}
                                        _hover={{ opacity: '1', cursor: 'pointer', bg: 'gray.50' }}
                                        onClick={e => handleEditItem(e, index)}
                                        px={'4px'}
                                        border={'1px 0px 1px 0px'}
                                        borderColor={'transparent'}
                                        boxShadow={'0px 1px 0px #ddd'}
                                        data-value={item}
                                    >
                                        {item}
                                    </Box>
                                    <Tooltip label="刪除" hasArrow bg={'gray'}>
                                        <Box
                                            opacity={0.5}
                                            _hover={{ opacity: '1', cursor: 'pointer' }}
                                            onClick={() => handleDeleteItem(index)}
                                        >
                                            <HeroIcons name="delete-fill" />
                                        </Box>
                                    </Tooltip>
                                </Flex>
                            )}
                        </>
                    ) : (
                        <>{item}</>
                    )}
                </ListItem>
            ))}
        </List>
    );
};

