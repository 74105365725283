import React, { useEffect, useState } from 'react';
import DiseaseNameDataMaintenanceTable from '../../components/DataSettings/DiseaseNameDataMaintenanceComponents/DiseaseNameDataMaintenanceTable';
import CloseIcon from 'components/CustomComponents/CloseIcon';
const DiseaseNameDataMaintenancePage: React.FC = () => {
    const [bgOpenStatus, setBgOpenStatue] = useState(false);
    const handleOpen = () => {
        setBgOpenStatue(true);
    };
    const handleClose = () => {
        setBgOpenStatue(false);
    };
    return (
        <>
            <DiseaseNameDataMaintenanceTable />
        </>
    );
};

export default DiseaseNameDataMaintenancePage;
