import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { RadioOptions } from './RadioOptions';
import { ButtonsOptions } from './ButtonsOptions';
import { ReportTable } from './ReportTable';

export const AuditPrint = () => {
    return (
        <>
            <Flex gap={'16px'}>
                <Flex gap={'8px'} direction={'column'}>
                    <RadioOptions />
                </Flex>

                <Flex gap={'8px'} direction={'column'} w={'100%'}>
                    <ButtonsOptions />
                    <ReportTable />
                </Flex>
            </Flex>
        </>
    );
};
