import React from 'react';
import { Flex, Button } from '@chakra-ui/react';

export const ButtonsOptions = () => {
    return (
        <>
            <Flex gap={'8px'}>
                <Button
                    colorScheme="rsPrimary"
                    boxShadow={'2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset'}
                >
                    進階模式新增
                </Button>
                <Button
                    colorScheme="rsPrimary"
                    boxShadow={'2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset'}
                >
                    清空清單
                </Button>
                <Button
                    colorScheme="rsPrimary"
                    boxShadow={'2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset'}
                >
                    全部列印
                </Button>
            </Flex>
        </>
    );
};
