import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
    Box,
    Flex,
    Center,
    Text,
    Select,
    Input,
    InputGroup,
    InputLeftElement,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';

import { caseTypeProps } from 'service/gTypeService';
import { medicalStaffListsProps } from 'service/medicalStaffListService';
import { callMedicalStaffListService } from 'service/medicalStaffListService';
import { useUserStore } from 'store';

import { callGTypeCaseTypeService } from 'service/gTypeService';

export const ConditionalSearch = () => {
    const { userInfo } = useUserStore();

    const [caseTypeLists, setCaseTypeLists] = useState<caseTypeProps[]>([]);
    const { data: caseTypeData, isSuccess: isCaseTypeSuccess } = useQuery(
        'caseTypeList',
        () => callGTypeCaseTypeService(userInfo.token!),
        { enabled: !!userInfo.token, staleTime: Infinity }
    );
    useEffect(() => {
        if (isCaseTypeSuccess) {
            setCaseTypeLists(caseTypeData.lists);
        }
    }, [isCaseTypeSuccess]);

    const [medicalStaffLists, setMedicalStaffLists] = useState<
        medicalStaffListsProps[]
    >([]);
    const { data: medicalStaffListData, isSuccess: isMedicalStaffListSuccess } =
        useQuery(
            'medicalStaffDoctorList',
            () => callMedicalStaffListService(userInfo.token!, 'iden_doctor'),
            {
                enabled: !!userInfo.token,
                staleTime: Infinity,
            }
        );
    useEffect(() => {
        if (isMedicalStaffListSuccess) {
            setMedicalStaffLists(medicalStaffListData.lists);
        }
    }, [isMedicalStaffListSuccess]);

    return (
        <Flex align={'center'} gap={'24px'}>
            <Center w={'280px'} gap={'12px'}>
                <Text flexShrink={0} fontWeight={'semibold'}>
                    案件
                </Text>
                <Select>
                    <option value="">請選擇</option>
                    {caseTypeLists &&
                        caseTypeLists.map((item, index) => (
                            <option value={item.code} key={index}>
                                {item.code} {item.name}
                            </option>
                        ))}
                </Select>
            </Center>
            <Center w={'160px'} gap={'12px'}>
                <Text flexShrink={0} fontWeight={'semibold'}>
                    醫師
                </Text>
                <Select>
                    <option value="">請選擇</option>
                    {medicalStaffLists &&
                        medicalStaffLists.map((item, index) => (
                            <option value={item.data_id} key={index}>
                                {item.name}
                            </option>
                        ))}
                </Select>
            </Center>
            <InputGroup w={'220px'}>
                <InputLeftElement pointerEvents="none">
                    <HeroIcons name="search" />
                </InputLeftElement>
                <Input placeholder="搜尋" type={'text'} />
            </InputGroup>
        </Flex>
    );
};
