import apiService from './apiService';
import axiosInstance from './axiosInstance';
// import setAuthHeader from './setAuthHeader';
import axios from 'axios';

interface loginDataProps {
    code: string;
    request_time: string;
    password: string;
    mac: string;
}
//取得登入
export const callLoginService = async (loginData: loginDataProps) => {
    return apiService<loginDataProps, any>({
        method: 'POST',
        path: '/api/login',
        data: loginData,
    });
};
// export const callLoginService = async (loginData: loginDataProps) => {
//     try {
//         const response = await axiosInstance.post(`/api/login`, loginData);
//         const result = response.data;

//         if (result.result === 'success') {
//             localStorage.setItem('token', result.token); // 保存令牌
//             setAuthHeader(result.token); // 設置 Axios 授權頭
//             // ...
//         }
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
