

export const toROCDateString = (gregorianDateString: string): string => {
    const dateParts = gregorianDateString.split('-');
    if (dateParts.length !== 3) {
        return ''; 
    }
    const year = parseInt(dateParts[0], 10) - 1911; 
    const month = dateParts[1];
    const day = dateParts[2];
    return `${year}.${month}.${day}`;
}