import { PrescriptionProps } from "store/TreatmentManagement";
import { InstitutionInfoProps } from "store/DataSetting/useInstitutionInfoStore";
export const D05Logic = (drug: PrescriptionProps, orderType:string) => {
    const validOrderTypes = ["1", "2", "P", "M", "Q", "R"];
    let D05 = "";
    if (validOrderTypes.includes(orderType)) {
        switch (orderType) {
            case "1":
            case "2":
                D05 = "0";
                break;
            case "M":
            case "Q":
                D05 = "1";
                break;
            case "R":
                D05 = "1"
                break;
            case "P":
                D05 = "0"
                break;
            default:
                D05 = "";
                break;
        }
    }
    return D05;
};