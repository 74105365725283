import axios from 'axios';
import apiService from './apiService';
import axiosInstance from './axiosInstance';

// 取得藥品附加診斷
export const callDrugAttachICDService = async (token: string, data: string) => {
    const queryParams = data ? {data} : undefined
    return apiService<any, any>({
        method: 'GET',
        path: '/api/drug/attach_icd',
        token,
        data: queryParams
    })
}
// export const callDrugAttachIcdService = async (token: string, id: string) => {
//     try {
//         const queryParams = { id };
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         const response = await axios.get('/api/drug/attach_icd', {
//             headers,
//             params: queryParams,
//         });
//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };


interface dataProps {
    data_id: string;
    icd: string[];
    ids: string[];
}
// 增修藥品附加診斷
export const postDrugAttachIcdService = async (
    token: string,
    updatedData: dataProps
) => {
    return apiService({
        method: 'POST',
        path: '/api/drug/attach_icd',
        token,
        data: updatedData
    })
}
// export const postDrugAttachIcdService = async (
//     token: string,
//     finalData: dataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(finalData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axiosInstance.post('/api/drug/attach_icd', requestBody, {
//             headers,
//         });
//         const result = response.data;

//         console.log('完整：', response);
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

// import axios from 'axios';

// 刪除藥品附加的診斷

export const deleteDrugAttachIcdService = async (
    token: string,
    data_id: string
) => {
    return apiService<undefined, any>({
        method: 'DELETE',
        path: `/api/drug/attach_icd?id=${data_id}`,
        token
    })
}
// export const deleteDrugAttachIcdService = async (
//     token: string,
//     data_id: string
// ) => {
//     try {
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         console.log('service這邊有刪除藥品附加的診斷嗎', data_id);
//         const url = `/api/drug/attach_icd?id=${data_id}`;

//         const response = await axiosInstance.delete(url, {
//             headers,
//         });

//         const result = response.data;

//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
