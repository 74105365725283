
import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, Center, Input } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
export const SuggestionClinic = () => {
    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        console.log('name:', name);
        console.log('value:', value);
    };
    return (
        <>
            <Text textStyle={'semiTitle'} mb={'8px'}>
                建議轉診院所
            </Text>
            <Flex
                columnGap={'16px'}
                rowGap={'8px'}
                flexWrap={'wrap'}
                justify={'space-between'}
            >
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        院所
                    </Text>
                    <Input
                        placeholder=""
                        onChange={handleChangeInput}
                        defaultValue={''}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        醫師
                    </Text>
                    <Input
                        placeholder=""
                        onChange={handleChangeInput}
                        defaultValue={''}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        科別
                    </Text>
                    <Input
                        placeholder=""
                        onChange={handleChangeInput}
                        defaultValue={''}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        電話
                    </Text>
                    <Input
                        placeholder=""
                        onChange={handleChangeInput}
                        defaultValue={''}
                        bg={'#fff'}
                    ></Input>
                </Flex>

                <Flex align={'center'} gap={'8px'} flexBasis={'100%'}>
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        地址
                    </Text>
                    <Input
                        placeholder=""
                        onChange={handleChangeInput}
                        defaultValue={''}
                        bg={'#fff'}
                    ></Input>
                </Flex>
            </Flex>
        </>
    );
};
