import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Box, Flex, Center } from '@chakra-ui/react';
import Swal from 'sweetalert2';

import HomePageMainFunctionBarImg from '../../images/icon/HomePageMainFunctionBarImg.png';
import HomePageRegisteredWorkImg from '../../images/icon/HomePageRegisteredWorkImg.png';
import HomePageClinicWorkImg from '../../images/icon/HomePageClinicWorkImg.png';
import HomePageICCardUploadImg from '../../images/icon/HomePageICCardUploadImg.png';
import HomePageIncomeDetailImg from '../../images/icon/HomePageIncomeDetailImg.png';
import HomePageDataSettingImg from '../../images/icon/HomePageDataSettingImg.png';
import HomePageAdministrationImg from '../../images/icon/HomePageAdministrationImg.png';
import HomePageDeclarationAuditImg from '../../images/icon/HomePageDeclarationAuditImg.png';

const HomeFunctionSide = () => {
    const navigate = useNavigate();
    const [device, setDevice] = useState<string>('');
    useEffect(() => {
        const width = window.innerWidth;
        if (width > 1200) {
            setDevice('pc');
        } else {
            setDevice('mobile');
        }
    });

    return (
        <>
            <Flex>
                <Flex
                    direction={device === 'pc' ? 'column' : 'row'}
                    flexWrap={device === 'pc' ? 'nowrap' : 'wrap'}
                >
                    <Box
                        w={'168px'}
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/RegisteredPage');
                        }}
                    >
                        <Image src={HomePageRegisteredWorkImg} />
                    </Box>
                    <Box
                        w={'168px'}
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/OPDPage');
                        }}
                    >
                        <Image src={HomePageClinicWorkImg} />
                    </Box>
                    <Box
                        w={'168px'}
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/ICCardUpdatePage');
                        }}
                    >
                        <Image src={HomePageICCardUploadImg} />
                    </Box>
                    <Box
                        w={'168px'}
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => {
                            Swal.fire({
                                icon: 'info',
                                title: '敬請期待',
                            });
                        }}
                    >
                        <Image src={HomePageIncomeDetailImg} />
                    </Box>
                    <Box
                        w={'168px'}
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/MedicalInstitutionDataSettingPage');
                        }}
                    >
                        <Image src={HomePageDataSettingImg} />
                    </Box>

                    <Box
                        w={'168px'}
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => {
                            Swal.fire({
                                icon: 'info',
                                title: '敬請期待',
                            });
                        }}
                    >
                        <Image src={HomePageAdministrationImg} />
                    </Box>

                    <Box
                        w={'168px'}
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/DeclarationAuditPage');
                        }}
                    >
                        <Image src={HomePageDeclarationAuditImg} />
                    </Box>
                </Flex>
                <Box w={'77px'} flexShrink={0}>
                    <Image src={HomePageMainFunctionBarImg} />
                </Box>
            </Flex>
        </>
    );
};

export default HomeFunctionSide;
