import React from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';

interface InsertRowTooltipProps {
    index: number;
    onInsert: (index: number) => void;
}

export const InsertRowTooltip: React.FC<InsertRowTooltipProps> = ({
    index,
    onInsert,
}) => (
    <Box
        role="group"
        pos={'relative'}
        left={'32px'}
        w={'calc(100% - 50px)'}
        h={'10px'}
        lineHeight={'8px'}
        opacity={0}
        _hover={{ opacity: 1, cursor: 'copy' }}
        onClick={() => onInsert(index)}
    >
        <Tooltip label="插入一行" hasArrow bg={'#646B48'}>
            <>
                <Box
                    pos={'absolute'}
                    left={'-16px'}
                    top={'-6px'}
                    textColor={'#646B48'}
                >
                    <HeroIcons name="add-fill" />
                </Box>
                <Box
                    pos={'absolute'}
                    top={'50%'}
                    left={'4px'}
                    transform={'translateY(-50%)'}
                    bg="#646B48"
                    w={'100%'}
                    h={'1px'}
                />
            </>
        </Tooltip>
    </Box>
);
