import React from 'react';
import { Box, Text, Flex, Input, Select } from '@chakra-ui/react';

export const Plan = () => {
    const handleChangeInput = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        console.log('textarea');
    };
    return (
        <>
            <Text textStyle={'semiTitle'} mb={'8px'}>
                安排就醫
            </Text>
            <Flex
                columnGap={'16px'}
                rowGap={'8px'}
                flexWrap={'wrap'}
                justify={'space-between'}
            >
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        開單日期
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        安排日期
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        科別
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        bg={'#fff'}
                        w={'120px'}
                    ></Input>
                    <Select placeholder="" defaultValue={''} bg={'#fff'}>
                        <option></option>
                    </Select>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        有效期限
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
            </Flex>
        </>
    );
};
