import apiService from './apiService';

export type copaymentExemptionsProps = {
    [code: string]: string;
};

// 取得常用部分負擔列表
export const callCopaymentExemptionsService =  (token: string) => {
    return apiService<copaymentExemptionsProps, any>({
        method: 'GET',
        path: '/api/copayment_exemptions',
        token,
    });
};
