import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, Center, Tooltip } from '@chakra-ui/react';
import {
    usePrescriptionStore,
} from 'store/TreatmentManagement';
import { useDropDownMenuQuery } from 'query/useDropDownMenuQuery';
import { useUserStore } from 'store';
import { drugTypeListProps } from 'service/drugTypeListService';

export const TestItemsList = () => {
    const { prescriptionList } = usePrescriptionStore();
    const { userInfo } = useUserStore();

    // 取得藥類清單
    const [drugTypeList, setDrugTypeList] = useState<drugTypeListProps[]>([]);
    const { data: drugTypeListData, isSuccess: isDrugTypeListSuccess } =
        useDropDownMenuQuery('drugTypeList', userInfo.token!);

    useEffect(() => {
        if (isDrugTypeListSuccess) {
            setDrugTypeList(drugTypeListData.lists);
        }
    }, [isDrugTypeListSuccess, drugTypeList]);

    let dataIdToCodeMap: { [key: string]: string } = {};

    if (drugTypeList) {
        dataIdToCodeMap = drugTypeList.reduce(
            (map, drugType) => {
                map[drugType.data_id] = drugType.code;
                return map;
            },
            {} as { [key: string]: string }
        );
    }

    // 過濾條件，drug_type 為 'E' 或 'Q' 的資料捨棄
    const filtered = prescriptionList.filter(
        item =>
            dataIdToCodeMap[item.drug_type] === 'E' ||
            dataIdToCodeMap[item.drug_type] === 'Q'
    );

    return (
        <>
            <Box w={'100%'} h={'100%'} p={'8px 12px'}>
                <Box
                    h={'calc(150px - 16px)'}
                    overflowY={'auto'}
                    overflowX={'hidden'}
                >
                    <Flex
                        borderBottom={'2px solid #646B48'}
                        pos={'sticky'}
                        top={0}
                        left={0}
                        w={'100%'}
                        bg={'#fff'}
                    >
                        <Box
                            flex={'1 0 44%'}
                            p={'4px 16px'}
                            textColor={'#646B48'}
                            fontWeight={'semibold'}
                            textAlign={'center'}
                        >
                            檢驗項目
                        </Box>
                        <Box
                            flex={'0 0 8%'}
                            p={'4px 16px'}
                            textColor={'#646B48'}
                            fontWeight={'semibold'}
                            textAlign={'right'}
                            hidden
                        >
                            次量
                        </Box>
                        <Box
                            flex={'0 0 8%'}
                            p={'4px 16px'}
                            textColor={'#646B48'}
                            fontWeight={'semibold'}
                            textAlign={'center'}
                        >
                            總量
                        </Box>
                        <Box
                            flex={'0 0 8%'}
                            p={'4px 16px'}
                            textColor={'#646B48'}
                            fontWeight={'semibold'}
                            textAlign={'center'}
                        >
                            M
                        </Box>
                        <Box
                            flex={'0 0 8%'}
                            p={'4px 16px'}
                            textColor={'#646B48'}
                            fontWeight={'semibold'}
                            textAlign={'right'}
                        >
                            自費價
                        </Box>
                        <Box
                            flex={'0 0 8%'}
                            p={'4px 16px'}
                            textColor={'#646B48'}
                            fontWeight={'semibold'}
                            textAlign={'right'}
                        >
                            健保價
                        </Box>
                        <Box
                            flex={'0 0 8%'}
                            p={'4px 16px'}
                            textColor={'#646B48'}
                            fontWeight={'semibold'}
                            textAlign={'right'}
                        >
                            申報方式
                        </Box>
                        <Box
                            flex={'0 0 18%'}
                            p={'4px 16px'}
                            textColor={'#646B48'}
                            fontWeight={'semibold'}
                        >
                            醫囑
                        </Box>
                    </Flex>

                    {filtered &&
                        filtered.map((item, index) => (
                            <>
                                <Flex
                                    borderBottom={'1px solid #646B48'}
                                    key={index}
                                >
                                    <Tooltip
                                        label={item.name_tw}
                                        hasArrow
                                        placement="bottom-start"
                                    >
                                        <Box
                                            flex={'1 0 44%'}
                                            p={'4px 16px'}
                                            textColor={'#646B48'}
                                            whiteSpace={'nowrap'}
                                            textOverflow={'ellipsis'}
                                            overflowX={'hidden'}
                                        >
                                            {item.name_en}
                                        </Box>
                                    </Tooltip>
                                    <Box
                                        flex={'0 0 8%'}
                                        p={'4px 16px'}
                                        textColor={'#646B48'}
                                        textAlign={'right'}
                                    >
                                        {item.given_qty}
                                    </Box>
                                    <Box
                                        flex={'0 0 8%'}
                                        p={'4px 16px'}
                                        textColor={'#646B48'}
                                        textAlign={'center'}
                                    >
                                        {item.special_mark}
                                    </Box>

                                    <Box
                                        flex={'0 0 8%'}
                                        p={'4px 16px'}
                                        textColor={'#646B48'}
                                        textAlign={'right'}
                                    >
                                        {item.self_price}
                                    </Box>
                                    <Box
                                        flex={'0 0 8%'}
                                        p={'4px 16px'}
                                        textColor={'#646B48'}
                                        textAlign={'right'}
                                    >
                                        {Number(item.drug_price) *
                                            Number(
                                                item.given_qty
                                                    ? item.given_qty
                                                    : 1
                                            )}
                                    </Box>
                                    <Box
                                        flex={'0 0 8%'}
                                        p={'4px 16px'}
                                        textColor={'#646B48'}
                                        textAlign={'right'}
                                    >
                                        {/* {item.reporting_method} */}
                                    </Box>
                                    <Tooltip
                                        // label={item.doctor_instructions}
                                        hasArrow
                                        placement="bottom-start"
                                    >
                                        <Box
                                            flex={'0 0 18%'}
                                            p={'4px 16px'}
                                            textColor={'#646B48'}
                                            whiteSpace={'nowrap'}
                                            textOverflow={'ellipsis'}
                                            overflowX={'hidden'}
                                        >
                                            {/* {item.doctor_instructions} */}
                                        </Box>
                                    </Tooltip>
                                </Flex>
                            </>
                        ))}
                </Box>
            </Box>
        </>
    );
};
