import React from 'react';
import { Box, Text, Flex, Input, Textarea } from '@chakra-ui/react';

export const Examination = () => {
    const handleChangeInput = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        console.log('textarea');
    };
    return (
        <>
            <Text textStyle={'semiTitle'} mb={'8px'}>
                檢查及診療摘要
            </Text>
            <Flex
                columnGap={'16px'}
                rowGap={'8px'}
                flexWrap={'wrap'}
                justify={'space-between'}
            >
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        檢查日期
                    </Text>
                    <Input
                        placeholder=""
                        onChange={handleChangeInput}
                        defaultValue={''}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'96px'} flexShrink={0}>
                        最後一次醫令
                    </Text>
                    <Input
                        placeholder=""
                        onChange={handleChangeInput}
                        defaultValue={''}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Textarea
                        h={'88px'}
                        resize={'none'}
                        bg={'#fff'}
                        value={''}
                        onChange={handleChangeInput}
                    ></Textarea>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(50% - 16px)'}
                >
                    <Textarea
                        h={'88px'}
                        resize={'none'}
                        bg={'#fff'}
                        value={''}
                        onChange={handleChangeInput}
                    ></Textarea>
                </Flex>
            </Flex>
        </>
    );
};
