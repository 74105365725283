import React, { useEffect, useState } from 'react';
import { VitalSigns } from './VitalSigns';
import { AllergyMedication } from './AllergyMedication';
import { MedicalHistory } from './MedicalHistory';

import {
    Button,
    Box,
    Flex,
    Text,
    Tag,
    Input,
    List,
    ListItem,
    OrderedList,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';

const VitalSignsForm: React.FC = () => {
    const currentDate = new Date(); // 獲取當前日期

    // 格式化成 年.月.日 的格式
    const today = `${currentDate.getFullYear() - 1911}.${currentDate.getMonth() + 1}.${currentDate.getDate()}`;

    return (
        <>
            <Box
                py={'8px'}
                px={'12px'}
                bg={'#fff'}
                flex={'0 0 320px'}
                rounded={'8px'}
                boxShadow={'inset 4px 2px 2px rgba(0,0,0,0.25)'}
            >
                <Box maxH={'316px'} overflow={'auto'}>
                    <VitalSigns />

                    <Box as="hr" my={'6px'} />

                    <AllergyMedication />

                    <Box as="hr" my={'6px'} />

                    <MedicalHistory />
                </Box>
            </Box>
        </>
    );
};

export default VitalSignsForm;
