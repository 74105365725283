import apiService from './apiService';

type dataProps = {
    ids: string[];
};
// 23. 取得要上傳的 IC 資料
export const postIcDataDataSetService = async (
    token: string,
    data: dataProps
) => {
    console.log('準備上車的 ids: ', data);
    return apiService<dataProps, any>({
        method: 'POST',
        path: '/api/ic_data/data_set',
        token,
        data: data,
    });
};
