import axios from 'axios';
import apiService from './apiService';

interface dataProps {
    user: string;
    password: string;
    code: string;
    mac: string;
    request_time: string;
}
// 認證密碼
export const postVerifySetPasswordService = async (
    data: dataProps
) => {
    return apiService<dataProps, any>({
        method: 'POST',
        path: '/api/verify/set_password',
   
        data: data
    })
}
// export const postVerifySetPasswordService = async (
//     token: string,
//     data: dataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(data);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         console.log(requestBody, 'requestBody service印的');

//         const response = await axios.post('/api/verify/set_password', requestBody, {
//             headers,
//         });
//         const result = response.data;
//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
