import { drugTypeListProps } from 'service/drugTypeListService';
import { PrescriptionProps } from 'store/TreatmentManagement';
import { checkNhiDrug } from './checkNhiDrug';
import { DrugFreqListsProps } from 'store/DataSetting/useDrugFreqListStore';

export const getDoseDay = (
    prescriptionList: PrescriptionProps[],
    drugTypeList: drugTypeListProps[],
    drugFreqLists: DrugFreqListsProps[]
): number => {
    let dose_day = 1;

    prescriptionList.forEach(item => {
        const drugType = drugTypeList.find(
            subItem => subItem.data_id === item.drug_type
        )?.code;

        const checkNhiDrugResult = checkNhiDrug(
            item.drug_no,
            item.special_mark || ''
        );

        if (checkNhiDrugResult && drugType && /[OWL]/.test(drugType)) {
            const itemDoseDay = Number(item.dose_day);
            if (itemDoseDay > dose_day) {
                dose_day = itemDoseDay;
            }
        }
    });

    return dose_day;
};
