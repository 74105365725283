// Hooks
import {
    Input,
    Button,
    Textarea,
    InputGroup,
    InputLeftElement,
    Flex,
    Box,
    Text,
    Heading,
    Tooltip,
} from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Td, TableContainer } from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import React from 'react';
import Swal from 'sweetalert2';
// Components
import Loading from 'components/CustomComponents/Loading';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';

import DrawerDetailsIcon from 'components/CustomComponents/DrawerDetailsIcon';
import ZoomInIcon from 'components/CustomComponents/ZoomInIcon';

// Context
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';

// Service
import { postICD10OfficialService } from 'service/ICD10OfficialService'; // 13. 模糊搜尋官方ICD-10

import { postICD10PersonalUseService } from 'service/ICD10PersonalUseService'; // 14.增修院內自用 ICD-10 資訊
import { deleteICD10PersonalUseService } from 'service/ICD10PersonalUseService'; // 15. 刪除院內自用 ICD-10 資訊
import { callICD10PersonalUseService } from 'service/ICD10PersonalUseService'; // 16. 取得或模糊搜尋院內自用的 ICD-10 資訊
import { callICD10PersonalUseSpecService } from 'service/ICD10PersonalUseService'; // 17. 取得院內自用的特定 ICD-10 資訊

// 抽屜全部的資料型態
interface drawerFormDataProps {
    code: string;
    custom_name_en: string;
    custom_name_tw: string;
    health_education: string;
    // id: string;
    icd_code: string;
    name_en: string;
    name_tw: string;
    objective: string;
    subjective: string;
    short_code: string;
    data_id: string;
}
// lists的資料型態
interface ShowListsProps {
    code: string;
    data_id?: string;
    icd_code: string;
    name_en: string;
    name_tw: string;
    original_name: string;
    short_code: string;
    id?: string;
}

const DiseaseNameDataMaintenanceTable = () => {
    // 阻擋事件context
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
    const { isOpen, onOpen, onClose } = useDisclosure();
    // 搜尋keyword
    const [keyword, setKeyword] = useState<string>('');
    const svgRef = useRef<SVGSVGElement>(null);
    // lists資料
    const [showLists, setShowLists] = useState<ShowListsProps[]>([]);
    const [drawerKey, setDrawerKey] = React.useState(0);
    // 抽屜資料
    const [drawerFormData, setDrawerFormData] = useState<drawerFormDataProps>({
        code: '',
        custom_name_en: '',
        custom_name_tw: '',
        health_education: '',
        // id: '',
        icd_code: '',
        name_en: '',
        name_tw: '',
        objective: '',
        subjective: '',
        short_code: '',
        data_id: '',
    });
    // 檢查的資料
    const checkDrawerFormData = useRef({ ...drawerFormData });
    // 編輯狀態 true 開始編輯 false 顯示
    const [editStatus, setEditStatus] = useState(false);

    // 回填時需要lists的index傳到抽屜
    const [drawerIndex, setDrawerIndex] = useState<number>(0);
    // 抽屜狀態 true開 false關
    const [drawerOpenStatus, setDrawerOpenStatus] = useState(false);

    const sidebarRef = useRef<HTMLDivElement>(null);
    useState<boolean>(true);
    // 抽屜內的Tab
    const [selectedTab, setSelectedTab] = useState(0);
    const handleChangeTabIndex = (event: number) => {
        setSelectedTab(event);
    };

    // 高度
    const [
        patientManagementFormTableHeight,
        setPatientManagementFormTableHeight,
    ] = useState(0);
    const windowHeight = window.innerHeight;

    // api
    const token = localStorage.getItem('token');

    const [ICD10InUseOfficialTables, setICD10InUseOfficialTables] = useState(0);
    const [ICD10InUsePersonalTables, setICD10InUsePersonalTables] = useState(0);

    const [isAPILoading, setIsAPILoading] = useState<boolean>(false);

    const fetchICD10PersonalUseData = async (keyword?: string) => {
        let page = 1;
        setIsInUse(true);

        if (token) {
            // 這個之後要討論
            const queryParams: { [key: string]: string | number } = {
                page: page,
            };
            if (keyword !== undefined) {
                queryParams.keyword = keyword;
            }
            setIsAPILoading(true);
            try {
                const result = await callICD10PersonalUseService(
                    token,
                    queryParams
                );

                setIsAPILoading(false);

                if (keyword && keyword.length > 0) {
                    // 如果有 key

                    if (result.result === 'success') {
                        // 而且有結果
                        setShowLists(result.lists); // 渲染 showList
                    } else if (result.result === 'failure') {
                        // 如果沒有結果
                        Swal.fire({
                            icon: 'warning',
                            title: '查無資料',
                            showConfirmButton: true,
                            confirmButtonText: '重新嘗試',
                        }).then(result => {
                            if (result.isConfirmed) {
                                fetchICD10PersonalUseData();
                            }
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: '發生錯誤',
                            showConfirmButton: true,
                            confirmButtonText: '重新嘗試',
                        });
                    }
                } else {
                    // 如果沒有 key
                    if (result.result === 'success') {
                        // 而且有結果
                        setShowLists(result.lists); // 渲染 showList
                    } else {
                        return;
                    }
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                });
                setIsAPILoading(false);
            }
        }
    };
    // 預設取得lists渲染
    useEffect(() => {
        fetchICD10PersonalUseData();
    }, []);

    // 查詢官方ICD10 需要keyword
    const handleSearch = () => {
        setShowLists([]);
        setIsInUse(false);
        if (keyword === '') {
            Swal.fire({
                icon: 'info',
                title: '請至少輸入 1 個字元',
                showConfirmButton: true,
                confirmButtonText: '好',
            });
            fetchICD10PersonalUseData();
        } else {
            postICD10OfficialData(keyword);
        }
    };

    // 查詢自用 有keyword精準搜尋 沒有keyword模糊搜尋
    const handleSearchPersonalUse = () => {
        setShowLists([]);
        setIsInUse(true);
        if (keyword === '') {
            fetchICD10PersonalUseData();
        } else {
            fetchICD10PersonalUseData(keyword);
        }
    };
    // 取得官方ICD10 API
    const postICD10OfficialData = async (keyword: string, page?: number) => {
        let finalData = {
            keyword: keyword,
            // Include the page in the data only if it is provided
            ...(page !== undefined && { page: page }),
        };
        if (token && keyword) {
            setIsAPILoading(true);
            try {
                const fetchedICD10OfficialLists =
                    await postICD10OfficialService(token, finalData);

                setIsAPILoading(false);

                if (fetchedICD10OfficialLists.result === 'success') {
                    setShowLists(fetchedICD10OfficialLists.lists);

                    console.log(
                        'ICD10 回應：',
                        fetchedICD10OfficialLists.lists
                    );

                    setICD10InUseOfficialTables(
                        fetchedICD10OfficialLists.total_pages
                    );
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: '查無資料',
                        showConfirmButton: true,
                        confirmButtonText: '重新嘗試',
                    });
                    fetchICD10PersonalUseData();
                }
            } catch {
                setIsAPILoading(false);
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                });
            }
        }
    };
    // 更動keyword Input
    const handleChangeKeyword = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const inputElement = event.target.value;
        setKeyword(inputElement);
    };

    // 清空drawer資料
    const emtpyInfo = () => {
        let emptyInfo = {
            code: '',
            custom_name_en: '',
            custom_name_tw: '',
            health_education: '',
            id: '',
            icd_code: '',
            name_en: '',
            name_tw: '',
            objective: '',
            subjective: '',
            short_code: '',
            data_id: '',
        };
        setDrawerFormData(emptyInfo);
    };
    // 清空檢查的資料
    const emptyCheckInfo = () => {
        let emptyInfo = {
            code: '',
            custom_name_en: '',
            custom_name_tw: '',
            health_education: '',
            id: '',
            icd_code: '',
            name_en: '',
            name_tw: '',
            objective: '',
            subjective: '',
            short_code: '',
            data_id: '',
        };
        checkDrawerFormData.current = {
            ...emptyInfo,
        };
    };

    // 打開抽屜按鈕
    // 從列表打開drawer 官方,自用, 或創新
    const handleChangeDrawStatus = (
        e: React.MouseEvent,
        data_id?: string,
        id?: string,
        icd_code?: string,
        code?: string,
        name_tw?: string,
        name_en?: string,
        short_code?: string,
        index?: number
    ) => {
        //判斷是否已經開啟drawer
        setDrawerOpenStatus(true);

        const fetchICD10PersonalSpecificData = async () => {
            setDrawerIndex(index ?? 0);
            let page = 1;

            try {
                if (token && data_id) {
                    const result = await callICD10PersonalUseSpecService(
                        token,
                        data_id
                    );

                    if (result.result === 'success') {
                        const resultLists = result.info;

                        let info = {
                            code: resultLists.code,
                            custom_name_en: resultLists.custom_name_en,
                            custom_name_tw: resultLists.custom_name_tw,
                            health_education: resultLists.health_education,
                            icd_code: resultLists.icd_code,
                            data_id: resultLists.id,
                            name_en: resultLists.name_en,
                            name_tw: resultLists.name_tw,
                            objective: resultLists.objective,
                            subjective: resultLists.subjective,
                            short_code: resultLists.short_code,
                        };

                        setDrawerFormData(info);

                        if (info.custom_name_tw === null) {
                            setIsCustomNameTW(false);
                        } else {
                            setIsCustomNameTW(true);
                        }
                    }
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                });
            }
        };

        try {
            if (code && data_id) {
                // 修改(院內)
                fetchICD10PersonalSpecificData();
            } else if (code) {
                // 新增(官方)
                setDrawerIndex(index ?? 0);
                // 直接用lists帶值沒有打api

                let info = {
                    code: code ?? '',
                    custom_name_en: '',
                    custom_name_tw: '',
                    health_education: '',
                    id: '',
                    icd_code: icd_code ?? '',
                    name_en: name_en ?? '',
                    name_tw: name_tw ?? '',
                    objective: '',
                    subjective: '',
                    short_code: '',
                    data_id: '',
                };

                setDrawerFormData(info);
                setIsCustomNameTW(false);
            }
            e.stopPropagation();
        } catch {
            Swal.fire({
                icon: 'error',
                title: '後端服務錯誤',
                timer: 1500,
            });
        }
    };

    useEffect(() => {
        console.log('showLists: ', showLists);
    }, [showLists]);

    useEffect(() => {
        console.log('drawerFormData: ', drawerFormData);
    }, [drawerFormData]);

    // 自創 自用及官方的 增修使用
    // 儲存按鈕
    const handleSave = () => {
        // 資料沒變就直接return

        if (
            JSON.stringify(checkDrawerFormData.current) ==
            JSON.stringify(drawerFormData)
        ) {
            setEditStatus(false);
            return;
        }
        //是否需要更動
        else {
            //修改
            if (drawerFormData.data_id) {
                let finalData = {
                    data_id: drawerFormData.data_id,
                    short_code: drawerFormData.short_code,
                    custom_name_tw: drawerFormData.custom_name_tw,
                    custom_name_en: drawerFormData.custom_name_en,
                    icd_code: drawerFormData.icd_code,
                    subjective: drawerFormData.subjective,
                    objective: drawerFormData.objective,
                    health_education: drawerFormData.health_education,
                };

                if (drawerFormData.short_code.length === 0) {
                    Swal.fire({
                        icon: 'warning',
                        title: '無病名代碼，你要停用此診斷嗎？',
                        confirmButtonText: '是',
                        cancelButtonText: '否',
                        showCancelButton: true,
                    }).then(result => {
                        if (result.isConfirmed) {
                            handleDeactivated();
                        } else {
                            return;
                        }
                    });
                }

                if (token && drawerFormData) {
                    const postPersonalUse = async () => {
                        try {
                            const postPersonalUseData =
                                await postICD10PersonalUseService(
                                    token,
                                    finalData
                                );

                            if (postPersonalUseData.result === 'success') {
                                Swal.fire({
                                    icon: 'success',
                                    title: '修改成功',
                                    showConfirmButton: false, // 隱藏確認按鈕
                                    timer: 1500, // 設定自動關閉的時間 (1.5 秒)
                                });
                                setHasUnsavedChanges(false);
                                setEditStatus(false);
                                const updatedShowLists = [...showLists];
                                let updatedData;

                                if (drawerFormData.custom_name_tw !== '') {
                                    updatedData = {
                                        ...updatedShowLists[drawerIndex],
                                        short_code: drawerFormData.short_code,
                                        name_tw: drawerFormData.custom_name_tw,
                                    };
                                } else {
                                    updatedData = {
                                        ...updatedShowLists[drawerIndex],
                                        ...drawerFormData,
                                    };
                                }

                                updatedShowLists[drawerIndex] = updatedData;
                                setShowLists(updatedShowLists);
                            }
                        } catch {
                            Swal.fire({
                                icon: 'error',
                                title: '後端服務錯誤',
                                timer: 1500,
                            });
                        }
                    };
                    postPersonalUse();
                }
            } else {
                //新增

                if (drawerFormData.short_code === '') {
                    Swal.fire({
                        icon: 'error',
                        title: '代碼不可留白',
                        confirmButtonText: '好',
                    });
                    return;
                } else {
                    if (token && drawerFormData) {
                        try {
                            const postPersonalUse = async () => {
                                const result =
                                    await postICD10PersonalUseService(
                                        token,
                                        drawerFormData
                                    );
                                if (result.result === 'success') {
                                    Swal.fire({
                                        icon: 'success',
                                        title: '新增成功',
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });

                                    if (isInUse) {
                                        fetchICD10PersonalUseData(keyword);
                                    } else {
                                        postICD10OfficialData(keyword);
                                    }

                                    const updatedShowLists = [...showLists];
                                    let updatedData;

                                    //裡面有值 回填lists就用custom_name_tw 取代 name_tw
                                    if (drawerFormData.custom_name_tw !== '') {
                                        updatedData = {
                                            ...updatedShowLists[drawerIndex],
                                            short_code:
                                                drawerFormData.short_code,
                                            name_tw:
                                                drawerFormData.custom_name_tw,
                                        };
                                        setShowLists(updatedShowLists);
                                    } else {
                                        updatedData = {
                                            ...updatedShowLists[drawerIndex],
                                            ...drawerFormData,
                                        };
                                        setShowLists(updatedShowLists);
                                    }

                                    updatedShowLists[drawerIndex] = updatedData;

                                    /// 更新抽屜

                                    setDrawerFormData({
                                        ...drawerFormData,
                                        data_id: result.data_id,
                                    });

                                    setHasUnsavedChanges(false);
                                }
                            };
                            postPersonalUse();
                        } catch {
                            Swal.fire({
                                icon: 'error',
                                title: '後端服務錯誤',
                                timer: 1500,
                            });
                        }
                    }
                }
            }
        }

        setEditStatus(false);
    };

    // 開始編輯
    const handleEdit = () => {
        setEditStatus(true);
        // 將原本的drawerFormData傳一份到checkDrawerFormData
        checkDrawerFormData.current = { ...drawerFormData };
    };

    // 點空白處關閉抽屜
    const handleClickOutside = (event: MouseEvent) => {
        if (
            drawerOpenStatus &&
            sidebarRef.current &&
            !sidebarRef.current.contains(event.target as Node)
        ) {
            setDrawerOpenStatus(false);
            setEditStatus(false);
            emptyCheckInfo();
            emtpyInfo();
        }
    };

    // 按 X 或 取消 關閉抽屜
    const handleCloseDrawer = (event: React.MouseEvent) => {
        if (hasUnsavedChanges) {
            Swal.fire({
                icon: 'question',
                title: '資料已變更，是否要儲存資料？',
                confirmButtonText: '是',
                cancelButtonText: '否',
                showCancelButton: true,
            }).then(result => {
                if (result.isConfirmed) {
                    handleSave();
                    setDrawerOpenStatus(false);
                    setDrawerKey(prevKey => prevKey + 1);
                    setEditStatus(false);
                    setHasUnsavedChanges(false);
                } else {
                    setDrawerOpenStatus(false);
                    setDrawerKey(prevKey => prevKey + 1);
                    setEditStatus(false);
                    setHasUnsavedChanges(false);
                }
            });
        } else {
            setDrawerOpenStatus(false);
            setDrawerKey(prevKey => prevKey + 1);
            setEditStatus(false);
            setHasUnsavedChanges(false);
        }
    };

    // 抽屜Css動畫
    const slideStyles: React.CSSProperties = {
        position: 'fixed',
        top: '116px',
        right: drawerOpenStatus ? '0' : '-40vw',
        zIndex: 30,
        width: '100%',
        maxWidth: '800px',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        transition: 'right 0.3s ease-in-out',
        height: `calc(${window.innerHeight}px - 116px)`,
        boxShadow: '-10px 0 10px -5px rgba(0, 0, 0, 0.2)',
    };

    // 停用自用
    const handleDeactivated = () => {
        Swal.fire({
            title: `是否要將「${
                drawerFormData.name_tw || drawerFormData.custom_name_tw
            }」停用？`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: '是',
            cancelButtonText: '否',
        }).then(result => {
            if (result.isConfirmed && token) {
                const deleteICD10PersonalUseData = async () => {
                    try {
                        const deleteICD10PersonalUseResult =
                            await deleteICD10PersonalUseService(
                                token,
                                drawerFormData.data_id
                            );
                        if (deleteICD10PersonalUseResult.result === 'success') {
                            Swal.fire({
                                icon: 'success',
                                title: '已停用',
                                showConfirmButton: false, // 隱藏確認按鈕
                                timer: 1500, // 設定自動關閉的時間 (1.5 秒)
                            });
                            setHasUnsavedChanges(false);
                            setDrawerOpenStatus(false);
                            if (isInUse) {
                                const newShowLists = showLists.filter(
                                    item =>
                                        item.data_id !== drawerFormData.data_id
                                );
                                setShowLists(newShowLists);

                                if (newShowLists.length === 0) {
                                    fetchICD10PersonalUseData();
                                } else {
                                    setShowLists(newShowLists);
                                }
                            } else if (!isInUse) {
                                postICD10OfficialData(keyword);
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: ' 操作失敗',
                                showConfirmButton: true, // 隱藏確認按鈕
                            });
                        }
                    } catch {
                        Swal.fire({
                            icon: 'error',
                            title: '後端服務錯誤',
                            timer: 1500,
                        });
                    }
                };

                deleteICD10PersonalUseData();
            } else {
                return;
            }
        });
    };

    // 所有的input與textarea, 有變化就存到drawerFormData 並且將hasUnsavedChanges變true 來做阻擋事件
    const handleChangeDrawerInput = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setDrawerFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
        setHasUnsavedChanges(true);
    };

    const [isCNtyping, setIsCNtyping] = useState<boolean>(false);

    const [isCustomNameTW, setIsCustomNameTW] = useState<boolean>(false);

    const [isInUse, setIsInUse] = useState<boolean>(true);

    const callICD10PersonalUseSpecAPI = async (id: string) => {
        const result = await callICD10PersonalUseSpecService(token!, id);
    };

    return (
        <>
            <Flex direction={'column'} w={'100%'} gap={'16px'}>
                <Flex gap={'8px'} w={'100%'} maxW={'900px'} pos={'relative'}>
                    <Box flex={'1 0 auto'}>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none" // This ensures the icon doesn't block input functionality
                            >
                                <HeroIcons name="search"></HeroIcons>
                            </InputLeftElement>
                            <Input
                                bg="#fff"
                                placeholder="請輸入病名代碼、ICD10 或中英文病名"
                                onChange={handleChangeKeyword}
                                onCompositionStart={() => {
                                    setIsCNtyping(true);
                                }}
                                onCompositionEnd={() => {
                                    setIsCNtyping(false);
                                }}
                                onKeyUp={event => {
                                    if (event.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                            />
                        </InputGroup>
                    </Box>

                    <Tooltip
                        label={keyword.length < 1 ? '輸入關鍵字至少 1 字' : ''}
                        hasArrow
                        bg={'red.500'}
                    >
                        <Button
                            isDisabled={keyword.length < 1}
                            colorScheme="rsPrimary"
                            onClick={handleSearch}
                        >
                            查詢 ICD10
                        </Button>
                    </Tooltip>

                    <Button
                        colorScheme="rsPrimary"
                        onClick={handleSearchPersonalUse}
                    >
                        查詢院內診斷
                    </Button>
                </Flex>

                {/* 渲染lists */}

                <Box pos={'relative'}>
                    {showLists && showLists.length > 0 ? (
                        <>
                            <Flex
                                borderBottom={'2px solid #ccc'}
                                pos={'relative'}
                                maxH={`${window.innerHeight - 220}px`}
                                overflow={'auto'}
                                w={'100%'}
                                direction={'column'}
                            >
                                <Box pos={'relative'}>
                                    <Box
                                        pos={'sticky'}
                                        top={0}
                                        bg={'gray.50'}
                                        zIndex={20}
                                    >
                                        <Flex w={'100%'}>
                                            <Box flex={'0 0 60px'}>
                                                <Text
                                                    p={'8px 12px'}
                                                    textStyle="semiTitle"
                                                    textAlign={'center'}
                                                >
                                                    {isInUse ? '院內' : '公告'}
                                                </Text>
                                            </Box>
                                            <Box flex={'0 0 128px'}>
                                                <Text
                                                    p={'8px 12px'}
                                                    textStyle="semiTitle"
                                                >
                                                    病名代碼
                                                </Text>
                                            </Box>
                                            <Box flex={'0 0 144px'}>
                                                <Text
                                                    p={'8px 12px'}
                                                    textStyle="semiTitle"
                                                >
                                                    ICD 10
                                                </Text>
                                            </Box>

                                            <Box flex={'1 0 auto'}>
                                                <Text
                                                    p={'8px 12px'}
                                                    textStyle="semiTitle"
                                                >
                                                    中文病名
                                                </Text>
                                            </Box>
                                            <Box flex={'1 0 auto'}>
                                                <Text
                                                    p={'8px 12px'}
                                                    textStyle="semiTitle"
                                                >
                                                    英文病名
                                                </Text>
                                            </Box>
                                        </Flex>
                                    </Box>
                                    <Box>
                                        {showLists&&showLists.map((item, index) => (
                                            <Flex
                                                key={index}
                                                borderBottom={'1px'}
                                                borderBottomColor={'#9B9B7A'}
                                            >
                                                <Box
                                                    flex={'0 0 60px'}
                                                    overflow={'hidden'}
                                                >
                                                    <Box p={'8px 12px'}>
                                                        <DrawerDetailsIcon
                                                            key={index}
                                                            ref={svgRef}
                                                            onClick={(
                                                                event: React.MouseEvent<HTMLElement>
                                                            ) =>
                                                                //檢視帶id, icd_code, name_tw,name_en是為了沒id時可以抓值到抽屜中顯示, index則是回填時使用
                                                                // lists顯示是code 但流程都是icd_code
                                                                handleChangeDrawStatus(
                                                                    event,
                                                                    item.data_id,
                                                                    item.id,
                                                                    item.icd_code,
                                                                    item.code,
                                                                    item.name_tw,
                                                                    item.name_en,
                                                                    item.short_code,

                                                                    index
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box
                                                    flex={'0 0 128px'}
                                                    overflow={'hidden'}
                                                >
                                                    <Text p={'8px 12px'}>
                                                        {item.short_code}
                                                    </Text>
                                                </Box>
                                                <Box
                                                    flex={'0 0 144px'}
                                                    overflow={'hidden'}
                                                >
                                                    <Text p={'8px 12px'}>
                                                        {item.code}
                                                    </Text>
                                                </Box>

                                                <Box
                                                    flex={
                                                        '1 0 calc( (100% - (88px + 128px + 144px)) / 2)'
                                                    }
                                                    overflow={'hidden'}
                                                >
                                                    <Tooltip
                                                        label={
                                                            item.name_tw !==
                                                            item.original_name
                                                                ? item.original_name
                                                                : ''
                                                        }
                                                        hasArrow
                                                        placement="bottom-start"
                                                        bg={'rsPrimary.500'}
                                                        openDelay={1000}
                                                    >
                                                        <Text
                                                            p={'8px 12px'}
                                                            whiteSpace={
                                                                'nowrap'
                                                            }
                                                            overflowX={'hidden'}
                                                            textOverflow={
                                                                'ellipsis'
                                                            }
                                                            fontWeight={
                                                                item.name_tw !=
                                                                item.original_name
                                                                    ? 'semibold'
                                                                    : 'normal'
                                                            }
                                                            textColor={
                                                                item.name_tw !=
                                                                item.original_name
                                                                    ? '#646B48'
                                                                    : '#000'
                                                            }
                                                        >
                                                            {item.name_tw !=
                                                            item.original_name
                                                                ? item.name_tw
                                                                : item.original_name}
                                                        </Text>
                                                    </Tooltip>
                                                </Box>
                                                <Box
                                                    flex={
                                                        '1 0 calc( (100% - (88px + 128px + 144px)) / 2)'
                                                    }
                                                    overflow={'hidden'}
                                                >
                                                    <Text
                                                        p={'8px 12px'}
                                                        w={'100%'}
                                                        whiteSpace={'nowrap'}
                                                        overflowX={'hidden'}
                                                        textOverflow={
                                                            'ellipsis'
                                                        }
                                                    >
                                                        {item.name_en}
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        ))}
                                    </Box>
                                </Box>
                            </Flex>
                        </>
                    ) : (
                        <>
                            {isAPILoading ? (
                                <>
                                    <Box
                                        h={'300px'}
                                        bg={'gray.50'}
                                        pos={'relative'}
                                    >
                                        <Loading />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box
                                        h={'300px'}
                                        py={'calc((300px - 68px - 24px) /2 )'}
                                        bg={'gray.50'}
                                        pos={'relative'}
                                        textAlign={'center'}
                                        textColor={'gray'}
                                    >
                                        <Heading
                                            as={'p'}
                                            size={'lg'}
                                            pb={'16px'}
                                        >
                                            查無資料
                                        </Heading>
                                        <Text>請更換關鍵字再試一次</Text>
                                    </Box>
                                </>
                            )}
                        </>
                    )}
                </Box>
                {/* 官方或自用的drawer */}
                {/* 抽屜內容 */}
                {drawerOpenStatus && (
                    <>
                        <Box ref={sidebarRef} id="a" style={slideStyles}>
                            <Flex
                                p={'8px 16px'}
                                align="center"
                                justify={'space-between'}
                                bg={'rsPrimary.500'}
                                textColor={'#fff'}
                            >
                                <Text fontSize={'lg'}>詳細內容</Text>
                                <Box
                                    onClick={handleCloseDrawer}
                                    cursor={'pointer'}
                                >
                                    <HeroIcons name="xmark"></HeroIcons>
                                </Box>
                            </Flex>
                            <Flex
                                direction={'column'}
                                gap={'16px'}
                                p={'16px'}
                                justify={'space-between'}
                                h={`calc(${window.innerHeight}px - 116px - 48px)`}
                            >
                                <>
                                    <Flex direction={'column'}>
                                        <Flex gap={'16px'} align={'center'}>
                                            <Flex flex={'0 0 100px'}>
                                                <Text
                                                    textStyle={'semiTitle'}
                                                    flex={'0 0 70px'}
                                                    py={'8px'}
                                                >
                                                    病名代碼
                                                </Text>
                                            </Flex>
                                            {editStatus ? (
                                                <>
                                                    <Input
                                                        onChange={
                                                            handleChangeDrawerInput
                                                        }
                                                        maxLength={8}
                                                        value={
                                                            drawerFormData.short_code
                                                        }
                                                        name="short_code"
                                                        bg={'#fff'}
                                                    ></Input>
                                                </>
                                            ) : (
                                                <>
                                                    <Text py={'8px'}>
                                                        {
                                                            drawerFormData.short_code
                                                        }
                                                    </Text>
                                                </>
                                            )}
                                        </Flex>

                                        {drawerFormData.icd_code && (
                                            <>
                                                <Flex
                                                    gap={'16px'}
                                                    align={'center'}
                                                >
                                                    <Flex flex={'0 0 100px'}>
                                                        <Text
                                                            textStyle={
                                                                'semiTitle'
                                                            }
                                                            flex={'0 0 70px'}
                                                            py={'8px'}
                                                        >
                                                            ICD10
                                                        </Text>
                                                    </Flex>
                                                    <Text py={'8px'}>
                                                        {
                                                            drawerFormData.icd_code
                                                        }
                                                    </Text>
                                                </Flex>
                                            </>
                                        )}

                                        <Flex gap={'16px'}>
                                            <Text
                                                textStyle={'semiTitle'}
                                                flex={'0 0 100px'}
                                                py={'8px'}
                                            >
                                                自訂病名
                                            </Text>

                                            {editStatus ? (
                                                <>
                                                    <Input
                                                        onChange={
                                                            handleChangeDrawerInput
                                                        }
                                                        value={
                                                            drawerFormData.custom_name_tw
                                                        }
                                                        name="custom_name_tw"
                                                        overflowX={'auto'}
                                                        w={'652px'}
                                                        whiteSpace={'nowrap'}
                                                    ></Input>
                                                </>
                                            ) : (
                                                // 創新顯示
                                                <>
                                                    <Text
                                                        py={'8px'}
                                                        overflowX={'auto'}
                                                        w={'652px'}
                                                        whiteSpace={'nowrap'}
                                                    >
                                                        {
                                                            drawerFormData.custom_name_tw
                                                        }
                                                    </Text>
                                                </>
                                            )}
                                        </Flex>

                                        <Flex gap={'16px'}>
                                            <Text
                                                textStyle={'semiTitle'}
                                                py={'8px'}
                                                flex={'0 0 100px'}
                                            >
                                                官方病名
                                            </Text>

                                            <Text
                                                py={'8px'}
                                                overflowX={'auto'}
                                                w={'652px'}
                                                whiteSpace={'nowrap'}
                                            >
                                                {drawerFormData.name_tw}
                                            </Text>
                                        </Flex>

                                        <Flex
                                            gap={'16px'}
                                            align={'center'}
                                            hidden
                                            data-note="這是切換的版本"
                                        >
                                            <Flex
                                                gap={'4px'}
                                                align={'center'}
                                                flex={'0 0 100px'}
                                            >
                                                <Text
                                                    textStyle={'semiTitle'}
                                                    py={'8px'}
                                                >
                                                    {isCustomNameTW
                                                        ? '自訂病名'
                                                        : '官方病名'}
                                                </Text>
                                                <Tooltip
                                                    label="官方/自訂"
                                                    hasArrow
                                                    bg={'rsPrimary.500'}
                                                >
                                                    <Box
                                                        textColor={
                                                            'rsPrimary.500'
                                                        }
                                                        flex={'0 0 24px'}
                                                        onClick={() => {
                                                            setIsCustomNameTW(
                                                                pre => !pre
                                                            );
                                                        }}
                                                        transform={
                                                            isCustomNameTW
                                                                ? 'rotate(0deg)'
                                                                : 'rotate(360deg)'
                                                        }
                                                        transition={'0.3s'}
                                                        _hover={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <HeroIcons name="recycle"></HeroIcons>
                                                    </Box>
                                                </Tooltip>
                                            </Flex>

                                            {isCustomNameTW ? (
                                                <>
                                                    {' '}
                                                    {editStatus ? (
                                                        <>
                                                            <Input
                                                                onChange={
                                                                    handleChangeDrawerInput
                                                                }
                                                                value={
                                                                    drawerFormData.custom_name_tw
                                                                }
                                                                name="custom_name_tw"
                                                            ></Input>
                                                        </>
                                                    ) : (
                                                        // 創新顯示
                                                        <>
                                                            <Text py={'8px'}>
                                                                {
                                                                    drawerFormData.custom_name_tw
                                                                }
                                                            </Text>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Text>
                                                        {drawerFormData.name_tw}
                                                    </Text>
                                                </>
                                            )}
                                        </Flex>

                                        <Flex gap={'16px'}>
                                            <Text
                                                textStyle={'semiTitle'}
                                                py={'8px'}
                                                flex={'0 0 100px'}
                                            >
                                                英文病名
                                            </Text>
                                            {drawerFormData.icd_code ? (
                                                <>
                                                    <Text
                                                        py={'8px'}
                                                        w={'652px'}
                                                        overflowX={'auto'}
                                                        whiteSpace={'nowrap'}
                                                    >
                                                        {drawerFormData.name_en}
                                                    </Text>
                                                </>
                                            ) : (
                                                <>
                                                    {editStatus ? (
                                                        <>
                                                            <Input
                                                                onChange={
                                                                    handleChangeDrawerInput
                                                                }
                                                                maxLength={8}
                                                                value={
                                                                    drawerFormData.custom_name_en
                                                                }
                                                                name="custom_name_en"
                                                                w={'652px'}
                                                                overflowX={
                                                                    'auto'
                                                                }
                                                                whiteSpace={
                                                                    'nowrap'
                                                                }
                                                            ></Input>
                                                        </>
                                                    ) : (
                                                        // 創新顯示
                                                        <>
                                                            <Text
                                                                py={'8px'}
                                                                w={'652px'}
                                                                overflowX={
                                                                    'auto'
                                                                }
                                                                whiteSpace={
                                                                    'nowrap'
                                                                }
                                                            >
                                                                {
                                                                    drawerFormData.custom_name_en
                                                                }
                                                            </Text>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Flex>
                                    </Flex>

                                    <Tabs
                                        variant={'none'}
                                        onChange={handleChangeTabIndex}
                                        index={selectedTab}
                                        flex={1}
                                    >
                                        <TabList>
                                            <Tab
                                                bg={
                                                    selectedTab === 0
                                                        ? '#F7F7F7'
                                                        : ''
                                                }
                                                textStyle={'semiTitle'}
                                            >
                                                Subjective
                                            </Tab>
                                            <Tab
                                                bg={
                                                    selectedTab === 1
                                                        ? '#F7F7F7'
                                                        : ''
                                                }
                                                textStyle={'semiTitle'}
                                            >
                                                Objective
                                            </Tab>
                                            <Tab
                                                bg={
                                                    selectedTab === 2
                                                        ? '#F7F7F7'
                                                        : ''
                                                }
                                                textStyle={'semiTitle'}
                                            >
                                                衛教
                                            </Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel bg={'#F7F7F7'}>
                                                <Box
                                                    h={`${window.innerHeight - 540}px`}
                                                >
                                                    {editStatus ? (
                                                        <>
                                                            <Textarea
                                                                bg={'#fff'}
                                                                resize="none"
                                                                name="subjective"
                                                                value={
                                                                    drawerFormData.subjective
                                                                }
                                                                onChange={
                                                                    handleChangeDrawerInput
                                                                }
                                                                h={'100%'}
                                                            ></Textarea>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Box
                                                                p={'8px 16px'}
                                                                h={'100%'}
                                                                overflowY={
                                                                    'auto'
                                                                }
                                                            >
                                                                <Text>
                                                                    {
                                                                        drawerFormData.subjective
                                                                    }
                                                                </Text>
                                                            </Box>
                                                        </>
                                                    )}
                                                </Box>
                                            </TabPanel>
                                            <TabPanel bg={'#F7F7F7'}>
                                                <Box
                                                    h={`${window.innerHeight - 540}px`}
                                                >
                                                    {editStatus ? (
                                                        <>
                                                            <Textarea
                                                                bg={'#fff'}
                                                                resize="none"
                                                                value={
                                                                    drawerFormData.objective
                                                                }
                                                                name="objective"
                                                                onChange={
                                                                    handleChangeDrawerInput
                                                                }
                                                                h={'100%'}
                                                            ></Textarea>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Box
                                                                p={'8px 16px'}
                                                                h={'100%'}
                                                                overflowY={
                                                                    'auto'
                                                                }
                                                            >
                                                                <Text>
                                                                    {
                                                                        drawerFormData.objective
                                                                    }
                                                                </Text>
                                                            </Box>
                                                        </>
                                                    )}
                                                </Box>
                                            </TabPanel>
                                            <TabPanel bg={'#F7F7F7'}>
                                                <Box
                                                    h={`${window.innerHeight - 540}px`}
                                                >
                                                    {editStatus ? (
                                                        <>
                                                            <Textarea
                                                                height={'full'}
                                                                bg={'#fff'}
                                                                resize="none"
                                                                value={
                                                                    drawerFormData.health_education
                                                                }
                                                                name="health_education"
                                                                onChange={
                                                                    handleChangeDrawerInput
                                                                }
                                                                h={'100%'}
                                                            ></Textarea>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Box
                                                                p={'8px 16px'}
                                                                h={'100%'}
                                                                overflowY={
                                                                    'auto'
                                                                }
                                                            >
                                                                <Text>
                                                                    {
                                                                        drawerFormData.health_education
                                                                    }
                                                                </Text>
                                                            </Box>
                                                        </>
                                                    )}
                                                </Box>
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>

                                    <Flex
                                        justify={'flex-end'}
                                        gap={'8px'}
                                        zIndex={30}
                                    >
                                        {editStatus ? (
                                            <>
                                                <Button
                                                    onClick={handleCloseDrawer}
                                                    colorScheme="rsSteel"
                                                >
                                                    取消
                                                </Button>
                                                <Button
                                                    colorScheme="rsSecondary"
                                                    onClick={handleSave}
                                                >
                                                    儲存
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    onClick={handleDeactivated}
                                                    colorScheme="rsDanger"
                                                    hidden={
                                                        drawerFormData
                                                            .short_code
                                                            .length === 0
                                                    }
                                                >
                                                    停用
                                                </Button>
                                                <Button
                                                    colorScheme="rsWarning"
                                                    onClick={handleEdit}
                                                >
                                                    編輯
                                                </Button>
                                            </>
                                        )}
                                    </Flex>
                                </>
                            </Flex>
                        </Box>
                    </>
                )}
            </Flex>
        </>
    );
};

export default DiseaseNameDataMaintenanceTable;
