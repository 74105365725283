// apiService.js 或 apiService.ts
import axios from 'axios';
import setAuthHeader from './setAuthHeader';

export const setupAxiosInterceptors = () => {
    let refresh = false;

    axios.interceptors.response.use(
        res => res,
        async err => {
            if (err.response.status === 401 && !refresh) {
                refresh = true;
                console.log('token掛囉');
                try {
                    const { data } = await axios.post(`/api/refreshToken`, {
                        refreshToken: localStorage.getItem('refreshToken'),
                    });

                    if (data && data.token) {
                        setAuthHeader(data.token);
                        localStorage.setItem('token', data.token);
                        return axios(err.config); // 重试原始请求
                    }
                } catch (error) {
                    // 处理错误
                } finally {
                    refresh = false;
                }
            }
            return Promise.reject(err);
        }
    );
};
