import { Box, Text, Button, Flex, Textarea } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import Swal from 'sweetalert2';
import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { useCCPEStore } from 'store/TreatmentManagement/useCCPEStore';
export const CCPE = () => {
    const [isShowMaximize, setIsShowMaximize] = useState<boolean>(false);

    const CCPERef = useRef<HTMLDivElement>(null);
    const [CCPEWidth, setCCPEWidth] = useState<number>(0);
    const { CCPEData, updateCCPEData } = useCCPEStore();

    useEffect(() => {
        if (CCPERef.current) {
            const width = CCPERef.current.offsetWidth;
            setCCPEWidth(width);
        }
    }, []);
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateCCPEData({
            cc: event?.target.value,
        });
    };

    return (
        <>
            <Text textStyle={'semiTitle'} textColor={'#646B48'}>
                CC / PE
            </Text>
            <Box
                id="CCPE"
                ref={CCPERef}
                pos={isShowMaximize ? 'fixed' : 'relative'}
                w={isShowMaximize ? `${CCPEWidth}px` : '100%'}
                maxH={
                    isShowMaximize
                        ? 'calc(100dvh - 41px - 8px - 8px - 44px - 24px - 8px - 56px)'
                        : 'calc(100% - 24px)'
                }
                h={'100%'}
                zIndex={isShowMaximize ? 50 : 0}
                boxShadow={isShowMaximize ? 'lg' : 'none'}
            >
                <Textarea
                    placeholder="請填寫 CC / PE"
                    resize={'none'}
                    bg={'#fff'}
                    pr={'36px'}
                    h={'100%'}
                    w={'100%'}
                    autoFocus
                    tabIndex={1}
                    value={CCPEData.cc ?? ''}
                    onChange={handleChange}
                    boxShadow={
                        isShowMaximize
                            ? 'none'
                            : '4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;'
                    }
                    fontSize={'18px'}
                ></Textarea>
                <Box
                    pos={'absolute'}
                    top={'8px'}
                    right={'24px'}
                    zIndex={45}
                    opacity={0.5}
                    _hover={{
                        cursor: 'pointer',
                        opacity: '1',
                    }}
                    onClick={() => {
                        setIsShowMaximize(pre => !pre);
                    }}
                    // ref={CCPEIconRef}
                >
                    <HeroIcons
                        name={
                            isShowMaximize
                                ? 'arrow-pointing-in'
                                : 'arrow-pointing-out'
                        }
                    ></HeroIcons>
                </Box>
            </Box>
            <Box
                pos={'fixed'}
                top={0}
                left={0}
                w={'100%'}
                h={'100%'}
                zIndex={40}
                onClick={() => {
                    setIsShowMaximize(false);
                }}
                hidden={!isShowMaximize}
                bg={'rgba(0,0,0,0.32)'}
            ></Box>
        </>
    );
};
