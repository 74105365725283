import { Cookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';

export type DeclareDownloadReqDataProps = {
    ym?: string;
};
interface DecodedToken {
    exp?: number;
    iat?: number;
    [key: string]: any;
}

export const callDeclareDownloadService = async (
    token: string,
    data?: string
): Promise<{ result: string; msg: string }> => {
    const cookies = new Cookies();
    const XTenant = cookies.get('X-Tenant');
    if (token) {
        console.log('token 存在');
        // 解碼 JWT token
        const decodedToken: DecodedToken = jwtDecode(token);
        // 獲取登入時間，如果 iat 存在則使用它，否則使用當前時間
        const loginTime = decodedToken.iat
            ? decodedToken.iat * 1000
            : Date.now();
        // 計算 4 小時後的過期時間
        const expTime_fourHourLater = loginTime + 4 * 60 * 60 * 1000;
        // const expTime_fourHourLater = loginTime + 5 * 1000;

        if (Date.now() < expTime_fourHourLater) {
            // config.headers['Authorization'] = token;
        } else {
            console.log('token 已過期');
            Swal.fire({
                title: '登入過期',
                text: '請重新登入',
                icon: 'warning',
                confirmButtonText: '確認',
                showConfirmButton: true,
            }).then(result => {
                if (result.isConfirmed) {
                    cookies.remove('token');
                    window.location.href = '/loginPage';
                }
            });
        }
    } else {
        console.log('token 不存在');
        if (location.pathname !== '/') {
            window.location.href = '/';
        }
    }
    try {
        const url = new URL('/api/declare/download', window.location.origin);
        if (data) {
            url.searchParams.append('ym', data);
        }

        // 從 cookies 獲取 X-Tenant

        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'Api-Token': token,
                'X-Tenant': XTenant,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const urlObject = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = urlObject;
        a.download = 'TOTFA.xml';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(urlObject);

        return { result: 'success', msg: 'File downloaded successfully' };
    } catch (error) {
        console.error('Download failed:', error);
        const errorMessage =
            error instanceof Error ? error.message : 'Download failed';
        return { result: 'failure', msg: errorMessage };
    }
};
