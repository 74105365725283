import React, { useEffect } from 'react';
import { Text, Flex, Button } from '@chakra-ui/react';
import Swal from 'sweetalert2';

import { Clinic } from './Clinic';
import { DatePick } from './DatePick';
import { TimePick } from './TimePick';
import { Pending } from './Pending';
import { ICReader } from './ICReader';

import { useUserStore } from 'store';

import { useICCardUpdateRangeStore } from 'store/ICCardUpdate/useICCardUpdateRangeStore';
import { useICCardUpdateListStore } from 'store/ICCardUpdate/useICCardUpdateListStore';
import { useICCardUpdateListTabIndexStore } from 'store/ICCardUpdate/useICCardUpdateListTabIndexStore';

import { useIcDataListsAPI } from 'service/icDataListsServiceHelper';
import { useIcDataVoidAPI } from 'service/icDataVoidServiceHelper';

export const ConditionalSearch = () => {
    const { userInfo } = useUserStore();
    const { icCardUpdateRangeData } = useICCardUpdateRangeStore();
    const { updateICCardUpdateList, clearICCardUpdateList } =
        useICCardUpdateListStore();
    const { tabIndex, setTabIndex } = useICCardUpdateListTabIndexStore();
    const { callIcDataVoidAPI } = useIcDataVoidAPI();
    const { callIcDataListsAPI } = useIcDataListsAPI();
    useEffect(() => {
        console.log('時間區間: ', icCardUpdateRangeData);
    }, [icCardUpdateRangeData]);

    const handleSearch = () => {
        const { start_time, end_time } = icCardUpdateRangeData;
        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

        if (
            (start_time !== '' && !timeRegex.test(start_time)) ||
            (end_time !== '' && !timeRegex.test(end_time))
        ) {
            Swal.fire({
                icon: 'warning',
                title: '時間格式錯誤',
                text: '開始時間和結束時間必須為空白或符合 HH:MM:SS 格式',
                confirmButtonText: '確定',
            });
            return;
        }
        if (tabIndex === 0) {
            callIcDataListsAPI();
        } else if (tabIndex === 1) {
            callIcDataVoidAPI();
        }
    };

    return (
        <>
            <Text textStyle={'semiTitle'} fontSize={'xl'}>
                搜尋
            </Text>
            <Flex direction={'column'} gap={'8px'}>
                <Clinic />
                <DatePick />
                <Flex
                    p={'8px 16px'}
                    bg={'gray.50'}
                    rounded={'lg'}
                    flexWrap={'wrap'}
                >
                    <TimePick />
                    <Pending />
                </Flex>
                {/* <ICReader /> */}
                <Button colorScheme={'rsPrimary'} onClick={handleSearch}>
                    搜尋
                </Button>
            </Flex>
        </>
    );
};
