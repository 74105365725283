import { Flex, Text, Box } from '@chakra-ui/react';

type doctorProps = {
    uid?: string;
    part?: number;
    point?: number;
};
export const DoctorInfo = () => {
    const doctor: doctorProps = {};
    return (
        <>
            {' '}
            <Flex flex={'0 0 284px'}>
                <Text fontWeight={'bold'} flex={'0 0 112px'}>
                    醫師身分證號
                </Text>
                <Text>{doctor.uid}</Text>
            </Flex>
            <Flex
                flex={'1 1 auto'}
                rounded={'md'}
                p={'4px 16px'}
                bg={'#BFC6A2'}
                boxShadow={'4px 2px 2px 0px rgba(0,0,0,0.25) inset'}
                justify={'space-between'}
                textColor={'#000'}
                fontWeight={'semibold'}
            >
                <Text>本次部分負擔</Text>
                <Text>{doctor.part} 元</Text>
            </Flex>
            <Flex
                flex={'1 1 auto'}
                rounded={'md'}
                p={'4px 16px'}
                bg={'#646B48'}
                boxShadow={'4px 2px 2px 0px rgba(0,0,0,0.25) inset'}
                justify={'space-between'}
                textColor={'#fff'}
                fontWeight={'semibold'}
            >
                <Text>本次申報合計點數</Text>
                <Text>{doctor.point} 元</Text>
            </Flex>
        </>
    );
};
