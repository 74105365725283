import apiService from "./apiService";
import { PatientInfoProps } from "store/Registration";



const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const removeEmptyValues = (obj: any, depth: number = 0): any => {
    if (obj instanceof Date) {
        return formatDate(obj);
    }

    if (Array.isArray(obj)) {
        return obj.map(item => removeEmptyValues(item, depth + 1));
    } else if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce<Record<string, any>>((acc, [key, value]) => {
            const cleanValue = removeEmptyValues(value, depth + 1);
            if (depth === 0 || (
                cleanValue !== null && 
                typeof cleanValue !== 'undefined' && 
                (cleanValue instanceof Date ||
                (Array.isArray(cleanValue) && cleanValue.length > 0) ||
                (!Array.isArray(cleanValue) && Object.keys(cleanValue).length > 0) ||
                cleanValue === 0 || 
                typeof cleanValue === 'boolean')
            )) {
                acc[key] = cleanValue;
            }
            return acc;
        }, {});
    }

    return obj;
};





// 編輯病患基本資料 
export const postPatientInfoService = async(token: string, finalData: PatientInfoProps) => {
    console.log('呼叫這隻')
    const finalData2 = removeEmptyValues(finalData);
    console.log(finalData2, '最終送出')
   
    
    return apiService({
        method: 'POST',
        path: '/api/patient/info',
        token,
        data: finalData2 
    })
}


export type PatientInfoServiceForAllergyMedicationProps= {
    info: {
        data_id: string,
        drug_allergy_describe: string
    }
}
export const postPatientInfoServiceForAllergyMedicationService = async(token: string, finalData: PatientInfoServiceForAllergyMedicationProps) => {
    const finalData2 = removeEmptyValues(finalData);
    console.log(finalData2, '最終送出')
   
    
    return apiService({
        method: 'POST',
        path: '/api/patient/info',
        token,
        data: finalData2 
    })
}