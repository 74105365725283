import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IsICWriteInOpenContextValue {
    isICWriteInOpenStatus: boolean;
    setIsICWriteInOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsICWriteInOpenContext = createContext<
    IsICWriteInOpenContextValue | undefined
>(undefined);

interface IsICWriteInOpenProviderProps {
    children: ReactNode;
}

export const IsICWriteInOpenProvider: React.FC<
    IsICWriteInOpenProviderProps
> = ({ children }) => {
    const [isICWriteInOpenStatus, setIsICWriteInOpenStatus] = useState(false);
    return (
        <IsICWriteInOpenContext.Provider
            value={{
                isICWriteInOpenStatus,
                setIsICWriteInOpenStatus,
            }}
        >
            {children}
        </IsICWriteInOpenContext.Provider>
    );
};

export const useIsICWriteInOpenStatus = (): IsICWriteInOpenContextValue => {
    const context = useContext(IsICWriteInOpenContext);

    if (!context) {
        throw new Error(
            'useIsICWriteInOpenStatus must be used within a isICWriteInOpenStatusProvider'
        );
    }

    return context;
};
