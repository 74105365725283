import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Text,
    Flex,
    Center,
    Tooltip,
    List,
    ListItem,
    Button,
    useDisclosure,
    Select,
    Input,
    Divider,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import Swal from 'sweetalert2';

import { EditOrders } from './EditOrders';

import { useUserStore } from 'store';

interface OrdersProps {
    data_id: string;
    order: string;
    use: boolean;
}

export const DoctorOrders = () => {
    const { userInfo } = useUserStore();

    return (
        <>
            <Box w={'100%'} h={'100%'} p={'8px'} overflowY={'auto'}>
                <Flex gap={'8px'} align={'center'} mb={'8px'}>
                    <Text textStyle={'semiTitle'}>醫師交代事項</Text>
                    <Box
                        opacity={0.5}
                        _hover={{ cursor: 'pointer', opacity: 1 }}
                        textColor={'#646B48'}
                    >
                        <HeroIcons name="add"></HeroIcons>
                    </Box>
                </Flex>

                <Flex flexWrap={'wrap'} gap={'8px'}>
                    <Box
                        hidden
                        bg={'#BFC6A2'}
                        p={'4px 16px'}
                        rounded={'lg'}
                        pos={'relative'}
                        _hover={{
                            p: '4px 36px 4px 16px',
                            cursor: 'pointer',
                        }}
                        transition={'0.5s'}
                        role="group"
                    >
                        <Box
                            pos={'absolute'}
                            right={'8px'}
                            top={'4px'}
                            _hover={{
                                cursor: 'pointer',
                            }}
                            opacity={0}
                            _groupHover={{ opacity: 1 }}
                            transition={'0.5s'}
                            transitionDelay={'0.2s'}
                        >
                            <HeroIcons name="xmark-sm"></HeroIcons>
                        </Box>
                    </Box>
                </Flex>
            </Box>

            {/* <EditOrders></EditOrders> 驗收前先不做 */}
        </>
    );
};
