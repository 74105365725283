import React, { useState, useEffect } from 'react';
import { Center, Box, Flex, Text, Textarea, Button } from '@chakra-ui/react';

import { Grid, GridItem } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { useIsPrintProofOpenStatus } from 'contexts/Registration/IsPrintProofOpenContext';
import { PatientInfo } from '../../PatientInfo';
import { InsetShadowBox } from 'style/chakraStyles';
import { usePatientInfoStore } from 'store/Registration';
import { ADDateToROCChineseString } from 'functions/ADDateToROCChineseString';
import { ADDateToROCString } from 'functions/ADDateToROCString';

export const DxProof = () => {
    const { patientInfo } = usePatientInfoStore();
    const { isPrintProofOpenStatus, setIsPrintProofOpenStatus } =
        useIsPrintProofOpenStatus();

    const GridItemStyle = {
        p: '10px 16px',
        border: '1px solid #f2f2f2',
    };

    return (
        <Center zIndex={70} bg={'#000000aa'} pos={'fixed'} inset={0}>
            <Box
                pos={'absolute'}
                top={'8px'}
                left={'calc(50% - 600px)'}
                w={'100%'}
                maxW={'1200px'}
                h={'auto'}
                bg={'#f3f3f3'}
                rounded={'lg'}
                border={'2px solid #646B48'}
            >
                <Flex justify={'space-between'} align={'center'} p={'4px 16px'}>
                    <Text textStyle={'semiTitle'}>開立診斷證明</Text>
                    <Box
                        _hover={{ cursor: 'pointer' }}
                        onClick={() => {
                            setIsPrintProofOpenStatus(prev => !prev);
                        }}
                    >
                        <HeroIcons name="xmark"></HeroIcons>
                    </Box>
                </Flex>
                <Box px={'16px'} mb={'16px'}>
                    <PatientInfo />
                    <Box sx={InsetShadowBox} my={'16px'}>
                        <Text fontSize={'lg'} textAlign={'center'} my={'8px'}>
                            耀聖診所
                        </Text>
                        <Text fontSize={'lg'} textAlign={'center'} my={'8px'}>
                            診斷證明書
                        </Text>

                        <Grid
                            templateRows="repeat(9, 1fr)"
                            templateColumns="repeat(10, 1fr)"
                        >
                            {/* row1 */}
                            <GridItem colSpan={1} sx={GridItemStyle}>
                                姓名
                            </GridItem>
                            <GridItem colSpan={4} sx={GridItemStyle}>
                                {patientInfo.info.name}
                            </GridItem>
                            <GridItem colSpan={1} sx={GridItemStyle}>
                                性別
                            </GridItem>
                            <GridItem colSpan={1} sx={GridItemStyle}>
                                {patientInfo.info.sex === 'M'
                                    ? '男'
                                    : patientInfo.info.sex === 'F'
                                      ? '女'
                                      : ''}
                            </GridItem>
                            <GridItem colSpan={1} sx={GridItemStyle}>
                                職業
                            </GridItem>

                            <GridItem colSpan={2} sx={GridItemStyle}>
                                {patientInfo.info.job ?? '-'}
                            </GridItem>

                            {/* row2 */}
                            <GridItem colSpan={1} sx={GridItemStyle}>
                                年齡
                            </GridItem>
                            <GridItem colSpan={1} sx={GridItemStyle}>
                                {patientInfo.info.birth_date &&
                                    new Date().getFullYear() -
                                        patientInfo.info.birth_date.getFullYear()}
                                歲
                            </GridItem>
                            <GridItem colSpan={3} sx={GridItemStyle}>
                                {patientInfo.info.birth_date &&
                                    ADDateToROCChineseString(
                                        patientInfo.info.birth_date
                                    )}
                            </GridItem>
                            <GridItem colSpan={1} sx={GridItemStyle}>
                                國別
                            </GridItem>
                            <GridItem colSpan={4} sx={GridItemStyle}>
                                -
                            </GridItem>

                            {/* row3 */}
                            <GridItem colSpan={1} sx={GridItemStyle}>
                                地址
                            </GridItem>
                            <GridItem colSpan={9} sx={GridItemStyle}>
                                {patientInfo.address.map((item, index) => (
                                    <Flex key={index} gap={'4px'}>
                                        <Box>{item.area_uid}</Box>
                                        <Box>{item.city_name}</Box>
                                        <Box>{item.area_name}</Box>
                                        <Box>{item.content}</Box>
                                    </Flex>
                                ))}
                            </GridItem>

                            {/* row4 */}
                            <GridItem
                                colSpan={1}
                                rowSpan={2}
                                sx={GridItemStyle}
                            >
                                應診日期
                            </GridItem>
                            <GridItem
                                colSpan={4}
                                rowSpan={2}
                                sx={GridItemStyle}
                            >
                                {/* <Text>自 113.07.08</Text>
                                <Text>至 113.07.08</Text> */}
                            </GridItem>
                            <GridItem colSpan={1} sx={GridItemStyle}>
                                科別
                            </GridItem>
                            <GridItem colSpan={4} sx={GridItemStyle}>
                                {/* 家醫科 */}
                            </GridItem>
                            <GridItem colSpan={1} sx={GridItemStyle}>
                                病歷號
                            </GridItem>
                            <GridItem colSpan={4} sx={GridItemStyle}>
                                {/* 000010 */}
                            </GridItem>

                            {/* row5 */}
                            <GridItem colSpan={1} sx={GridItemStyle}>
                                病名
                            </GridItem>
                            <GridItem colSpan={9} sx={GridItemStyle}>
                                {/* 急性支氣管炎 */}
                            </GridItem>

                            {/* row6 */}
                            <GridItem
                                colSpan={1}
                                rowSpan={3}
                                sx={GridItemStyle}
                            >
                                醫師囑言
                            </GridItem>
                            <GridItem
                                colSpan={9}
                                rowSpan={3}
                                sx={GridItemStyle}
                            >
                                <Textarea
                                    resize={'none'}
                                    w={'100%'}
                                    h={'100%'}
                                    sx={InsetShadowBox}
                                    defaultValue={''}
                                />
                            </GridItem>
                        </Grid>

                        <Text fontSize={'md'} textAlign={'center'} my={'16px'}>
                            以上病人經本院（所）醫師診斷屬實特予證明
                        </Text>
                    </Box>
                    <Flex gap={'8px'} justify={'flex-end'}>
                        <Button colorScheme="rsPrimary">儲存</Button>
                        <Button colorScheme="rsPrimary">列印</Button>
                    </Flex>
                </Box>
            </Box>
        </Center>
    );
};
