import RegistrationButtons from './RegistrationButtons';
import BigClock from './BigClock';
import RegisteredRoomStatus from './RegisteredRoomStatus';
import { useRegisteredTableHeightStatus } from '../../../contexts/RegisteredTableHeightContext';
import { useEffect, useRef } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';

export default function RegisteredFunction() {
    const { registeredTableHeightStatus, setRegisteredTableHeightStatus } =
        useRegisteredTableHeightStatus();
    const outerDivRef = useRef<HTMLDivElement | null>(null); // 明確指定 useRef 的類型為 HTMLDivElement 或 null

    useEffect(() => {
        function updateHeight() {
            if (outerDivRef.current) {
                const height = outerDivRef.current.clientHeight;
                setRegisteredTableHeightStatus(height);
            }
        }

        updateHeight(); // 初始更新
        window.addEventListener('resize', updateHeight); // 當視窗大小變化時更新高度

        // 組件卸載時清除事件監聽器
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [setRegisteredTableHeightStatus]);

    return (
        <>
            <div ref={outerDivRef}>
                <Flex justify="space-between">
                    <Flex flexShrink={0} gap={'16px'} align={'center'}>
                        <BigClock />
                        <RegistrationButtons />
                    </Flex>
                    {/* <RegisteredRoomStatus /> */}
                </Flex>
            </div>
        </>
    );
}
