import React from 'react';
import { Text, Flex, Input } from '@chakra-ui/react';
export const Allergy = () => {
    const handleChangeInput = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        console.log('textarea');
    };

    return (
        <>
            <Flex gap={'8px'} align={'center'}>
                <Text textStyle={'semiTitle'} flexShrink={0}>
                    藥物過敏史
                </Text>
                <Input
                    bg={'#fff'}
                    defaultValue={''}
                    onChange={handleChangeInput}
                ></Input>
            </Flex>
        </>
    );
};
