import React from 'react';
import { Flex, Button } from '@chakra-ui/react';
import { ShowCases } from './ShowCases';
import { ShowCasesOutline } from './ShowCasesOutline';
import { ExportAndPrint } from './ExportAndPrint';

export const CasesCounter = () => {
    return (
        <>
            <Flex gap={'8px'} mt={'8px'}>
                <ShowCases title={'總件數'} num={0} />
                <ShowCases title={'一般案件'} num={0} />
                <ShowCases title={'其他（專）案件'} num={0} />
                <ShowCasesOutline title={'押單'} num={0} />
                <ShowCasesOutline title={'刪除'} num={0} />
                <ExportAndPrint />
            </Flex>
        </>
    );
};
