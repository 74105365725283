import apiService from './apiService';

// 取得M欄清單
export const callDrugSpecialMarkService = async(token: string)=> {
    return apiService<string, any>({
        method: 'GET',
        path: '/api/drug/special_mark', 
        token, 
    })
}

