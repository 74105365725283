import { useEffect, useState, useRef } from 'react';
import { Box, Flex, Center, Text, Tooltip } from '@chakra-ui/react';
import Loading from 'components/CustomComponents/Loading';
import { SearchResultProps } from './PrescriptionRow';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';

interface BoxMetrics {
    innerWidth: number;
    innerHeight: number;
    top: number;
    left: number;
}
interface SearchResultListProps {
    searchResult: SearchResultProps[];
    isLoading: boolean;
    resultMsg: string;
    handleInsertPrescription: (index: number) => void;
    boxMetrics: BoxMetrics;
    setIsSearchListsStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchResultList: React.FC<SearchResultListProps> = ({
    searchResult,
    isLoading,
    resultMsg,
    handleInsertPrescription,
    boxMetrics,
    setIsSearchListsStatus
}) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedIndex(prev => Math.min(prev + 1, searchResult.length - 1));
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setSelectedIndex(prev => Math.max(prev - 1, 0));
            } else if (e.key === 'Enter') {
                e.preventDefault();
                if (selectedIndex >= 0) {
                    handleInsertPrescription(selectedIndex);
                }
            } else if (e.key === 'Escape') {
                e.preventDefault();
                setIsSearchListsStatus(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [selectedIndex, searchResult, handleInsertPrescription, setIsSearchListsStatus]);

    useEffect(() => {
        if (listRef.current && selectedIndex >= 0) {
            const selectedElement = listRef.current.children[selectedIndex + 1] as HTMLElement;
            if (selectedElement) {
                selectedElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
            }
        }
    }, [selectedIndex]);

    useEffect(() => {
        console.log('Search result:', searchResult);
        console.log('Is loading:', isLoading);
        console.log('Result message:', resultMsg);
    }, [searchResult, isLoading, resultMsg]);

    return (
        <Box
            pos={'fixed'}
            top={`calc(${boxMetrics.top}px + 8px)`}
            left={`calc(${boxMetrics.left}px + 16px)`}
            w={`calc(${boxMetrics.innerWidth}px - 32px)`}
            zIndex={50}
            rounded={'lg'}
            border={'2px solid #646B48'}
            bg={'#fff'}
            minH={`calc( 2 * 33px)`}
            maxH={`calc(34px + 7 * 33px)`}
            overflowY={'auto'}
            overflowX={'hidden'}
            shadow={'2xl'}
            ref={listRef}
            tabIndex={0}
            onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter' || e.key === 'Escape') {
                    e.preventDefault();
                }
            }}
        >
            {isLoading ? (
                <Center bg={'gray.50'} textColor={'gray.500'} h={'100%'} minH={`120px`}>
                    <Loading />
                </Center>
            ) : searchResult.length > 0 ? (
                <>
                    <Flex
                        borderBottom={'2px solid #646B48'}
                        pos={'sticky'}
                        top={0}
                        bg={'#fff'}
                    >
                        <Box flex={'0 0 8%'} fontWeight={'semibold'} textColor={'#646B48'} p={'4px 4px'}>代碼</Box>
                        <Box flex={'1 0 20%'} fontWeight={'semibold'} textColor={'#646B48'} p={'4px 4px'}>藥品名稱</Box>
                        <Box flex={'0 0 6%'} fontWeight={'semibold'} textColor={'#646B48'} p={'4px 4px'} textAlign={'center'}>單位</Box>
                        <Box flex={'0 0 6%'} fontWeight={'semibold'} textColor={'#646B48'} p={'4px 4px'} textAlign={'center'}>藥類</Box>
                        <Box flex={'0 0 12%'} fontWeight={'semibold'} textColor={'#646B48'} p={'4px 4px'}>健保碼</Box>
                        <Box flex={'0 0 8%'} fontWeight={'semibold'} textColor={'#646B48'} p={'4px 4px'}>健保價</Box>
                        <Box flex={'0 0 8%'} fontWeight={'semibold'} textColor={'#646B48'} p={'4px 4px'}>自費價</Box>
                        <Box flex={'0 0 8%'} fontWeight={'semibold'} textColor={'#646B48'} p={'4px 4px'}>用法</Box>
                        <Box flex={'0 0 8%'} textAlign={'center'} fontWeight={'semibold'} textColor={'#646B48'} p={'4px 4px'}>預設 CCPE</Box>
                        <Box flex={'0 0 8%'} textAlign={'center'} fontWeight={'semibold'} textColor={'#646B48'} p={'4px 4px'}>預設 DRUG</Box>
                        <Box flex={'0 0 8%'} textAlign={'center'} fontWeight={'semibold'} textColor={'#646B48'} p={'4px 4px'}>預設 ICD</Box>
                    </Flex>
                    {searchResult.map((item, index) => (
                        <Flex
                            key={index}
                            borderBottom={'1px solid #646B48'}
                            bg={index === selectedIndex ? '#D2D2B3' : 'transparent'}
                            _hover={{
                                bg: '#D2D2B3',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleInsertPrescription(index)}
                        >
                            <Box flex={'0 0 8%'} p={'4px 4px'}>{item.short_code}</Box>
                            <Tooltip hasArrow label={item.name_tw}>
                                <Box flex={'1 0 20%'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} p={'4px 4px'}>{item.name_tw}</Box>
                            </Tooltip>
                            <Box flex={'0 0 6%'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} p={'4px 4px'} textAlign={'center'}>{item.unit}</Box>
                            <Box flex={'0 0 6%'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} p={'4px 4px'} textAlign={'center'}>{item.drug_type_code}</Box>
                            <Box flex={'0 0 12%'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} p={'4px 4px'}>{item.drug_no}</Box>
                            <Box flex={'0 0 8%'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} p={'4px 4px'}>{item.drug_price}</Box>
                            <Box flex={'0 0 8%'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} p={'4px 4px'}>{item.self_price}</Box>
                            <Box flex={'0 0 8%'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} p={'4px 4px'}>{item.freq_code}</Box>
                            <Box flex={'0 0 8%'} p={'4px 4px'}>{item.attach_ccpe === 'Y' && <Box><HeroIcons name="check-circle-fill" /></Box>}</Box>
                            <Box flex={'0 0 8%'} p={'4px 4px'}>{item.attach_drugs === 'Y' && <Box><HeroIcons name="check-circle-fill" /></Box>}</Box>
                            <Box flex={'0 0 8%'} p={'4px 4px'}>{item.attach_icd === 'Y' && <Box><HeroIcons name="check-circle-fill" /></Box>}</Box>
                        </Flex>
                    ))}
                </>
            ) : (
                <Center bg={'gray.50'} textColor={'gray.500'} h={'100%'} minH={`120px`}>
                    <Text>{resultMsg || '沒有搜尋結果'}</Text>
                </Center>
            )}
        </Box>
    );
};
