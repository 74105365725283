import { callRshisGetTreatNumNoICCardService } from 'service/RsService/RshisGetTreatNumNoICCardService';

import {
    TreatNumNoICCardReqProps,
    pBufferProps,
} from 'service/RsService/RshisGetTreatNumNoICCardService';

export type hisGetTreatNumNoICCardResProps = {
    ERRORCODE: number;
    pBuffer: pBufferProps;
};

export const hisGetTreatNumNoICCard = async (
    data: TreatNumNoICCardReqProps
): Promise<hisGetTreatNumNoICCardResProps> => {
    try {
        const result = await callRshisGetTreatNumNoICCardService(data);

        const parsePBuffer = (pBuffer: string): pBufferProps => {
            return {
                computerDateTime: pBuffer.substring(0, 13).trim(),
                medicalInstitutionCode: pBuffer.substring(13, 23).trim(),
                medicalTreatmentIdentificationCode: pBuffer
                    .substring(23, 43)
                    .trim(),
            };
        };

        const parsedData = parsePBuffer(result.pBuffer);

        let res = {
            ERRORCODE: result.ERRORCODE,
            pBuffer: parsedData,
        };

        return res;
    } catch (error) {
        console.error('取得掛號基本資訊時發生錯誤:', error);
        throw error;
    }
};
