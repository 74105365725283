import { useState, useEffect } from 'react';
import { Button, Flex, Box, Text } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import '../../../style/components/datePickerCustomForMainStyle.css';
import iconICReader from '../../../../src/images/icon/icon-IC_Reader.png';

import Swal from 'sweetalert2';

import { useShowReadVHICStatus } from 'contexts/ShowReadVHICStatusContext';
import { useRegisteredFlowStatus } from 'contexts/RegisteredFlowStatusContext';

import {
    usePatientInfoStore,
    useCurrentVisitInfoStore,
    useSearchPatientStore,
} from 'store/Registration';
import { registerBasicPBufferProps } from 'functions/hisGetRegisterBasic';
import { callRshisGetRegisterBasicService } from 'service/RsService/RshisGetRegisterBasicService';
import { callPatientSearchService } from 'service/patientSearchService';
import { hisGetRegisterBasic } from 'functions/hisGetRegisterBasic';
import { RegisterBasicProps } from 'functions/hisGetRegisterBasic';

import { useVitalSignsDataStore } from 'store/Registration';

const RegistrationButtons = () => {
    const token = localStorage.getItem('token');
    const { updateSearchPatientData, resetSearchPatientData } = useSearchPatientStore();
    const { currentVisitInfoData, resetCurrentVisitInfoData } =
        useCurrentVisitInfoStore();
    const { resetVitalSignsData } = useVitalSignsDataStore();
    const { patientInfo, updatePatientInfo, resetPatientInfo } =
        usePatientInfoStore();
    const {
        registeredFlowStatus,
        setRegisteredFlowStatus,
        setShowPatientManagementStructureStatus,
    } = useRegisteredFlowStatus();
    const { setShowReadVHICStatus } = useShowReadVHICStatus();

    const {
        showSearchPatientMedicalRecord,
        setShowSearchPatientMedicalRecord,
    } = useShowReadVHICStatus();

    const parsePBuffer = (pBuffer: string) => {
        return {
            cardNumber: pBuffer.substring(0, 12).trim(),
            name: pBuffer.substring(12, 28).trim(),
            idOrDocumentNumber: pBuffer.substring(28, 39).trim(),
            birthDate: pBuffer.substring(39, 46).trim(),
            gender: pBuffer.substring(46, 47).trim(),
            issueDate: pBuffer.substring(47, 54).trim(),
            cancellationMark: pBuffer.substring(54, 55).trim(),
            insurerCode: pBuffer.substring(55, 57).trim(),
            insureIdentity: pBuffer.substring(57, 58).trim(),
            cardValidityPeriod: pBuffer.substring(58, 65).trim(),
            medicalTreatmentNumber: pBuffer.substring(65, 67).trim(),
            newBornAttachmentDate: pBuffer.substring(67, 74).trim(),
            newBornTwinsNote: pBuffer.substring(74, 81).trim(),
        };
    };

    const handleClickReadVHIC = () => {
        resetAllRegistrationStores()
        setShowReadVHICStatus(true);
    };

    const handleClickReadICCard = () => {
        resetAllRegistrationStores()
        // resetPatientInfo();
        // resetCurrentVisitInfoData();
        // resetVitalSignsData();
        callRshisGetRegisterBasicAPI();
    };

    const callRshisGetRegisterBasicAPI = async () => {
        try {
            const responseParse = await hisGetRegisterBasic();

            if (responseParse) {
                // console.log('讀卡成功', responseParse);

                const patientData = responseParse.PatCardNumber ? {
                    name: responseParse.PatName || '',
                    sex: responseParse.PatGender || '',
                    id_no: responseParse.PatIDNo || '',
                    insurerCode: responseParse.PatInsurerCode || '',
                    birth_date: parseBirthDate(responseParse.PatBirth || '')
                } : {
                    name: responseParse.name || '',
                    sex: responseParse.gender || '',
                    id_no: responseParse.idOrDocumentNumber || '',
                    insurerCode: responseParse.insurerCode || '',
                    birth_date: parseBirthDate(responseParse.birthDate || '')
                };

                if (patientData.id_no) {
                    try {
                        const dbResult = await callPatientSearchService(token!, 'id_no', patientData.id_no);
                        // console.log('資料庫搜尋結果:', dbResult);

                        if (dbResult && dbResult.result === 'success') {
                            if (dbResult.lists && dbResult.lists.length > 0) {
                                updateSearchPatientData({
                                    keywords: patientData.id_no,
                                    searchLists: dbResult.lists,
                                });
                                setShowSearchPatientMedicalRecord(true);
                            } else if (dbResult.info) {
                                updatePatientInfo({
                                    ...patientInfo,
                                    info: dbResult.info,
                                    email: dbResult.email,
                                    emer: dbResult.emer_contactor,
                                    address: dbResult.address,
                                    phone: dbResult.phone,
                                });
                                setShowSearchPatientMedicalRecord(false);
                                setShowPatientManagementStructureStatus(true);
                                setRegisteredFlowStatus('second');
                            } else {
                                handleNewPatient(patientData);
                            }
                        } else if (dbResult.msg === '查無符合資料') {
                            handleNewPatient(patientData);
                        } else {
                            console.log('資料庫查詢結果異常:', dbResult);
                        }
                    } catch (error) {
                        console.error('資料庫查詢錯誤:', error);
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '讀卡資料不完整',
                        text: '無法獲取病患身分證號',
                        timer: 1500,
                    });
                }
            } else {
                throw new Error('讀卡失敗');
            }
        } catch (error) {
            console.error('讀卡或解析錯誤:', error);
            Swal.fire({
                icon: 'error',
                title: '讀卡失敗',
                text: '請確認讀卡機及代理人是否正確',
                timer: 1500,
            });
        }
    };

    const parseBirthDate = (birthDateString: string): Date => {
        let ROCyyy = parseInt(birthDateString.slice(0, 3));
        let ROCmm = parseInt(birthDateString.slice(3, 5));
        let ROCdd = birthDateString.slice(5, 7);
        let ADYear = ROCyyy + 1911;
        let ADMonth = ROCmm - 1;
        return new Date(ADYear, ADMonth, parseInt(ROCdd));
    };

    const handleNewPatient = (patientData: any) => {
        // console.log('初診：新增病患資料');
        updatePatientInfo({
            ...patientInfo,
            info: {
                ...patientInfo.info,
                ...patientData,
            },
        });
        setShowPatientManagementStructureStatus(true);
        setRegisteredFlowStatus('first');
    };

    const resetAllRegistrationStores = () => {
        resetCurrentVisitInfoData();
        resetPatientInfo();
        resetVitalSignsData();
        resetSearchPatientData();
    }

    return (
        <>
            <Flex gap={'8px'}>
                <Flex direction={'column'} gap={'8px'}>
                    <Button
                        colorScheme="rsPrimary"
                        px={'24px'}
                        py={'10px'}
                        height={'auto'}
                        onClick={handleClickReadICCard}
                    >
                        <Box as="span" w={'60px'} mr={'5px'}>
                            <Image src={iconICReader} w={'100%'}></Image>
                        </Box>
                        <Text fontSize={'48px'} fontWeight={'normal'}>
                            IC 讀卡
                        </Text>
                    </Button>
                    <Flex gap={'8px'} justify={'space-between'}>
                        <Button
                            flex={1}
                            colorScheme="rsWarning"
                            onClick={() => {
                                resetAllRegistrationStores();
                                setShowSearchPatientMedicalRecord(true);
                            }}
                        >
                            查詢病患
                        </Button>
                        <Button
                            flex={1}
                            colorScheme={'rsSteel'}
                            onClick={() => {
                                resetAllRegistrationStores()
                                setRegisteredFlowStatus('first');
                                setShowPatientManagementStructureStatus(true);
                                // resetCurrentVisitInfoData();
                                // resetPatientInfo();
                            }}
                        >
                            初診新增
                        </Button>
                    </Flex>
                </Flex>
                <Button
                    colorScheme="rsSecondary"
                    h={'auto'}
                    onClick={handleClickReadVHIC}
                >
                    虛 <br />
                    擬 <br />
                    IC <br />
                    卡 <br />
                    讀 <br />卡
                </Button>
            </Flex>
        </>
    );
};

export default RegistrationButtons;
