import React from 'react';
import {
    Box,
    Flex,
    Text,
    Checkbox,
    Button,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react';
import { InsetShadowBox } from 'style/chakraStyles';

import { PatientInfo } from '../PatientInfo';
import { Record } from './Record';
import { GotHistoryList } from './GotHistoryList';

import { ADDateTransToROCStr } from 'functions/ADDateTransToROCStr';

export const SequentialPrescription = () => {
    return (
        <>
            <Box w={'100%'} sx={InsetShadowBox}>
                <PatientInfo />
                <Box
                    w={'100%'}
                    my={'12px'}
                    p={'12px'}
                    rounded={'lg'}
                    bg={'#F5F5F5'}
                >
                    <Tabs>
                        <TabList>
                            <Tab>
                                <Text textStyle={'semiTitle'}>糖尿病</Text>
                            </Tab>
                            <Tab>
                                <Text textStyle={'semiTitle'}>
                                    本態性高血壓
                                </Text>
                            </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Record />
                            </TabPanel>
                            <TabPanel>
                                <Record />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
                <Box mt={'24px'}>
                    <GotHistoryList />
                </Box>
            </Box>
        </>
    );
};
