import { create } from 'zustand';

// 定義 MedicalRecordModelProps 接口
export interface MedicalRecordModelProps {
    // IPatientBase
    Pat_Code: string; // 病歷號
    Pat_Name: string; // 病患姓名
    Pat_IdnoType: number; // 身份證件種類 0.身分證 1.護照 2.居留證號
    Pat_IDNo?: string; // 身分證字號(建議)
    Pat_Gender?: string; // 病患性別
    Pat_Birth?: string; // 病患生日(建議)
    Pat_BirthTime?: string; // 出生時間 Ex : 13:00
    Pat_Tel?: string; // 家用電話
    Pat_Mobile?: string; // 行動電話
    Pat_Addr?: string; // 病患地址
    Pat_Memo?: string; // 病患備註
    Pat_Job?: string; // 病患職業
    Pat_Mark?: string; // 病患註記
    // IDoctorBase
    Dr_Code?: string; // 醫師代碼
    Dr_Name: string; // 醫師姓名
    E_Dr_Name?: string; // 醫師英文姓名
    Dr_IDNo?: string; // 醫師身分證
    Dr_Certificate?: string; // 醫師證號
    Dr_Mobile?: string; // 醫師電話
    // IShiftBase
    Shift_Code?: string; // 時段代碼
    Shift_Name: string; // 時段名稱
    // IRoomBase
    RoomSeq?: number; // 第N診
    RoomName?: string; // 診間名稱(建議)
    // IDepartmentBase
    Dept_Code?: string; // 科別代碼
    Dept_Name?: string; // 科別名稱
    // 連籤
    OpenDate?: string; // 開立日期
    SlowFreq?: number; // 可調劑次數
    SlowDays?: number; // 開藥天數
    XmlD4?: string; // 特殊領藥註記
    // IMedicalRecord
    MedicalRecord_ID?: number; // 病歷 ID
    ClinicDateTime: string; // 門診日期
    Req?: number; // 就診序
    Nhi_Type: string; // 掛號身份
    Nhi_Card?: string; // 卡號
    TreatNumber?: string; // 就醫識別碼
    CC?: string; // CC
    PE?: string; // PE
    Kind?: string; // 案件
    DoseDays?: number; // 開藥天數
    Pres?: string; // 調劑方式
    Weight?: number; // 體重
    Temperature?: number; // 體溫
    QtyType?: string; // 劑量類型
    MedicalType?: string; // 病歷類型
    PayCode?: string; // 部分負擔代碼
}

// 定義 MedicalRecordModelStore 接口
interface MedicalRecordModelStore {
    medicalRecord: MedicalRecordModelProps;
    updateMedicalRecord: (medicalRecord: MedicalRecordModelProps) => void;
    resetMedicalRecord: () => void;
}

// 初始化 medicalRecord 的值
export const initialMedicalRecord: MedicalRecordModelProps = {
    // IPatienBase
    Pat_Code: '',
    Pat_Name: '',
    Pat_IdnoType: 0,
    Pat_IDNo: '',
    Pat_Gender: '',
    Pat_Birth: '',
    Pat_BirthTime: '',
    Pat_Tel: '',
    Pat_Mobile: '',
    Pat_Addr: '',
    Pat_Memo: '',
    Pat_Job: '',
    Pat_Mark: '',
    // IDoctorBase
    Dr_Code: '',
    Dr_Name: '',
    E_Dr_Name: '',
    Dr_IDNo: '',
    Dr_Certificate: '',
    Dr_Mobile: '',
    // IShiftBase
    Shift_Code: '',
    Shift_Name: '',
    // IRoomBase
    RoomSeq: 0,
    RoomName: '',
    // IDepartmentBase
    Dept_Code: '',
    Dept_Name: '',
    // 連籤
    OpenDate: '',
    SlowFreq: 0,
    SlowDays: 0,
    XmlD4: '',
    // IMedicalRecord
    MedicalRecord_ID: 0,
    ClinicDateTime: '',
    Req: 0,
    Nhi_Type: '',
    Nhi_Card: '',
    TreatNumber: '',
    CC: '',
    PE: '',
    Kind: '',
    DoseDays: 0,
    Pres: '',
    Weight: 0,
    Temperature: 0,
    QtyType: 'O', // 次量
    MedicalType: '門診',
    PayCode: '',
};

// 創建 useMedicalRecordModelStore
export const useMedicalRecordModelStore = create<MedicalRecordModelStore>(
    set => ({
        medicalRecord: initialMedicalRecord,
        updateMedicalRecord: medicalRecord =>
            set(() => ({
                medicalRecord: medicalRecord,
            })),
        resetMedicalRecord: () =>
            set(() => ({
                medicalRecord: initialMedicalRecord,
            })),
    })
);

export default useMedicalRecordModelStore;
