import {
    Box,
    Flex,
    Text,
    Center,
    Button,
    Tabs,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { Selections } from './Selections';
import { CasesCounter } from './CasesCounter';
import { Lists } from './Lists';
import { RightSide } from './RightSide';
import { InsetShadowBox } from 'style/chakraStyles';

export const DeclarationAudit = () => {
    return (
        <>
            <Flex
                h={'calc( 100vh - 40px)'}
                bg={'gray.550'}
                p={'48px 20px 20px'}
                direction={'column'}
            >
                <Box mb={'4px'}>
                    <Selections />
                </Box>
                <Flex>
                    <Box flex={'1 0 66.66%'}>
                        <Box mr={'4px'}>
                            <Lists />
                            <CasesCounter />
                        </Box>
                    </Box>
                    <Box flex={'1 0 33.33%'}>
                        <Box sx={InsetShadowBox} ml={'4px'}>
                            <RightSide />
                        </Box>
                    </Box>
                </Flex>
            </Flex>
        </>
    );
};
