import React, { useState, useEffect } from 'react';
import {
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Button,
    Text,
} from '@chakra-ui/react';

type GetMedTableProps = {
    tableData: Array<{
        seq: string;
        suggestedDate: string;
        gotMed: boolean;
        getMedDate: string;
    }>;
    onGetMed: (suggestedDateRange: string) => void;
};

export const GetMedTable = () => {
    const initialTableData: GetMedTableProps['tableData'] = [
        {
            seq: '第一次',
            suggestedDate: '113.07.26 ~ 113.08.15',
            gotMed: false,
            getMedDate: '',
        },
        {
            seq: '第二次',
            suggestedDate: '113.08.23 ~ 113.09.02',
            gotMed: false,
            getMedDate: '',
        },
        {
            seq: '第三次',
            suggestedDate: '113.09.02 ~ 113.09.11',
            gotMed: false,
            getMedDate: '',
        },
        {
            seq: '第四次',
            suggestedDate: '113.09.11 ~ 113.09.20',
            gotMed: false,
            getMedDate: '',
        },
    ];

    const [tableData, setTableData] =
        useState<GetMedTableProps['tableData']>(initialTableData);

    const parseDate = (dateStr: string) => {
        const [year, month, day] = dateStr.split('.').map(Number);
        return new Date(year + 1911, month - 1, day);
    };

    const isDateInRange = (suggestedDateRange: string) => {
        const today = new Date();
        const [startDateStr, endDateStr] = suggestedDateRange.split(' ~ ');
        const startDate = parseDate(startDateStr);
        const endDate = parseDate(endDateStr);
        return today >= startDate && today <= endDate;
    };

    const handleGetMed = (suggestedDateRange: string) => {
        if (isDateInRange(suggestedDateRange)) {
            const today = new Date();
            const formattedToday = `${today.getFullYear() - 1911}.${(today.getMonth() + 1).toString().padStart(2, '0')}.${today.getDate().toString().padStart(2, '0')}`;

            setTableData(prevData =>
                prevData.map(item =>
                    item.suggestedDate === suggestedDateRange
                        ? { ...item, gotMed: true, getMedDate: formattedToday }
                        : item
                )
            );
        } else {
            console.log('日期不在範圍內');
        }
    };

    return (
        <>
            <TableContainer>
                <Table variant="striped" size={'sm'}>
                    <Thead
                        bg={'#D9D9D9'}
                        pos={'sticky'}
                        top={0}
                        left={0}
                        zIndex={10}
                    >
                        <Tr>
                            <Th>
                                <Text textStyle={'semiTitle'}> 序</Text>
                            </Th>
                            <Th>
                                <Text textStyle={'semiTitle'}> 建議領藥日</Text>
                            </Th>
                            <Th>
                                <Text textStyle={'semiTitle'}> 領藥日</Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {tableData.map(item => (
                            <Tr key={item.seq}>
                                <Td>{item.seq}</Td>
                                <Td>{item.suggestedDate}</Td>
                                <Td>
                                    {item.gotMed ? (
                                        <Text py={1}>{item.getMedDate}</Text>
                                    ) : (
                                        <Button
                                            size={'sm'}
                                            colorScheme="rsDanger"
                                            boxShadow={
                                                'inset 2px -2px 4px rgba(0,0,0,0.2)'
                                            }
                                            onClick={() =>
                                                handleGetMed(item.suggestedDate)
                                            }
                                            isDisabled={
                                                !isDateInRange(
                                                    item.suggestedDate
                                                )
                                            }
                                        >
                                            領藥
                                        </Button>
                                    )}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};
