import { useState, useRef, useEffect } from 'react';
import {
    SimpleGrid,
    Box,
    Flex,
    Text,
    GridItem,
    FormLabel,
    Input,
    Center,
} from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { PediatricDoseProps } from './DrugDataMaintenanceTable';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';
interface DrugDataMaintenancePediatricDoseProps {
    pediatricDoseLists: PediatricDoseProps[];
    setPediatricDoseLists: React.Dispatch<
        React.SetStateAction<PediatricDoseProps[]>
    >;
    isEditing: boolean;
    // isChanged: boolean;
}
const DrugDataMaintenancePediatricDose = ({
    pediatricDoseLists,
    setPediatricDoseLists,
    isEditing,
}: // isChanged,
DrugDataMaintenancePediatricDoseProps) => {
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        console.log(pediatricDoseLists);
    }, [pediatricDoseLists]);
    // const [showLists, setShowLists] = useState<{ [key: string]: any }[]>([]);

    const handleChangeInput =
        (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            // const numberValue = value.replace(/[^0-9]/g, '');
            const regex = /^(?:\d{1,3}(?:\.\d{0,2})?|\.\d{1,2})$/;
            if (regex.test(value)) {
                setPediatricDoseLists(prev =>
                    prev.map((item, i) => {
                        if (i === index) {
                            const key = Object.keys(item)[0];
                            return { [key]: value };
                        }
                        return item;
                    })
                );
                setHasUnsavedChanges(true);
            }
        };

    return (
        <>
            <SimpleGrid spacing="8px" columns={10}>
                <GridItem colSpan={2} py={'8px'}>
                    <Center rounded={'md'}>
                        <Text textStyle={'semiTitle'}> 體重 KG ／ 劑量</Text>
                    </Center>
                </GridItem>

                <>
                    {pediatricDoseLists&&pediatricDoseLists.map((item, index) => {
                        const key = Object.keys(
                            item
                        )[0] as keyof PediatricDoseProps; // 使用类型断言
                        const value = item[key]; // 获取该键的值

                        return (
                            <Flex align={'center'} gap={'8px'} key={index}>
                                <FormLabel margin={0} htmlFor={`data-${key}`}>
                                    <Text
                                        w={'32px'}
                                        py={'8px'}
                                        textStyle={'semiTitle'}
                                        textAlign={'right'}
                                    >
                                        {index + 3} {/* 或者其他适合的标签 */}
                                    </Text>
                                </FormLabel>
                                <Input
                                    bg={isEditing ? '#fff' : '#f7f7f7'}
                                    disabled={!isEditing}
                                    value={value ?? ''}
                                    onChange={handleChangeInput(index)}
                                    id={`data-${key}`}
                                    type="text"
                                    _disabled={{
                                        border: '1px solid #e6e6e6',
                                    }}
                                    textAlign={'right'}
                                />
                            </Flex>
                        );
                    })}
                </>
            </SimpleGrid>
        </>
    );
};

export default DrugDataMaintenancePediatricDose;
