/* Hooks */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { jwtDecode } from 'jwt-decode';
import { CookiesProvider } from 'react-cookie';
/* css */
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
/* Status */
import { MainFunctionDndStatusProvider } from './contexts/MainFunctionDndStatusContext';
import { UnsavedChangesProvider } from 'contexts/UnsavedChangesContext';
import { NavigationStatusProvider } from 'contexts/NavigationStatusContext';
import { LogoutStatusProvider } from 'contexts/LogoutStatusContext';

// 上版要開

import ErrorBoundary from 'components/CustomComponents/ErrorBoundary';

// if (process.env.NODE_ENV == 'production') {
//     console.log('正式');
// console.log = function () {};
// }

// 應用初始化時進行檢查
const domNode = document.getElementById('root');
const root = createRoot(domNode!);

root.render(
    // 這邊使用strictMode dnd會有問題 先不開
    <React.StrictMode>
        <BrowserRouter>
            <MainFunctionDndStatusProvider>
                <UnsavedChangesProvider>
                    <NavigationStatusProvider>
                        <LogoutStatusProvider>
                            <ErrorBoundary>
                                <CookiesProvider>
                                    <App />
                                </CookiesProvider>
                            </ErrorBoundary>
                        </LogoutStatusProvider>
                    </NavigationStatusProvider>
                </UnsavedChangesProvider>
            </MainFunctionDndStatusProvider>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
