import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { useUserStore } from 'store';
import { useTreatmentInfoStore } from 'store/TreatmentManagement';
import { useDxStore } from 'store/TreatmentManagement';
import Swal from 'sweetalert2';

// import { callMedicalRecordHistoryDataListsService } from 'service/medicalRecordHistoryDateListsService';

type historyListProps = {
    treated_at: string;
    data_id: string;
    case_type: string | null;
    card_no: string;
    doctor_name: string;
    icd_code: string;
    diagnosis_name: string;
};

export const DxHistory = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const { userInfo } = useUserStore();
    const { treatmentInfo } = useTreatmentInfoStore();

    const { dxList } = useDxStore();

    const convertDate = (dateString: string): string => {
        const date = new Date(dateString);
        const year = date.getFullYear() - 1911;
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}.${month}.${day}`;
    };

    // 前三次歷史病歷 （240628 隱藏備用)
    // const [historyList, setHistoryList] = useState<historyListProps[]>([]);

    // const callMedicalRecordHistoryDataListsAPI = async () => {
    //     setLoading(true);
    //     if (treatmentInfo.patient_uid && userInfo.token) {
    //         try {
    //             const result = await callMedicalRecordHistoryDataListsService(
    //                 userInfo.token,
    //                 treatmentInfo.patient_uid
    //             );

    //             if (result.result === 'success') {
    //                 setHistoryList(result.lists);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching medical records:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     }
    // };
    // useEffect(() => {
    //     callMedicalRecordHistoryDataListsAPI();
    // }, []);

    useEffect(() => {
        console.log('dx:', dxList);
    }, []);

    return (
        <>
            <Box w={'100%'} h={'100%'} p={'8px 12px'}>
                <Text textStyle="semiTitle">本次診斷</Text>
                <Flex mb={'8px'} flexWrap={'wrap'} gap={'4px'}>
                    {dxList &&
                        dxList
                            .filter(item => item.data_id)
                            .map((item, index) => (
                                <Text
                                    key={index}
                                    p={'4px 16px'}
                                    bg={index === 0 ? '#D08C60' : '#BFC6A2'}
                                    textColor={index === 0 ? '#fff' : '#000'}
                                    rounded={'lg'}
                                    h={'30px'}
                                    textOverflow={'ellipsis'}
                                    overflow={'hidden'}
                                    whiteSpace={'nowrap'}
                                >
                                    {item.name_tw ? item.name_tw : item.name_en}{' '}
                                    {item.icd_code}
                                </Text>
                            ))}
                </Flex>

                {/* 
                240628 近三筆歷史病歷 隱藏備用
                <Box>
                    <Text textStyle="semiTitle">近 3 次診斷</Text>

                    <Flex
                        align={'center'}
                        justify={'space-between'}
                        borderBottom={'1px solid #646B48'}
                        bg={'#646B4811'}
                    >
                        <Text
                            textAlign={'center'}
                            textStyle={'semiTitle'}
                            p={'4px 16px'}
                            flex={'0 0 100px'}
                        >
                            日期
                        </Text>
                        <Text
                            textAlign={'center'}
                            textStyle={'semiTitle'}
                            p={'4px 16px'}
                            flex={'1 1 0%'}
                        >
                            主診斷
                        </Text>
                        <Text
                            textAlign={'center'}
                            textStyle={'semiTitle'}
                            p={'4px 16px'}
                            flex={'0 0 100px'}
                        >
                            案件
                        </Text>
                        <Text
                            textAlign={'center'}
                            textStyle={'semiTitle'}
                            p={'4px 16px'}
                            flex={'0 0 100px'}
                        >
                            天數
                        </Text>
                    </Flex>
                    <Box h={'calc(25px * 3)'} overflowY={'auto'}>
                        {historyList &&
                            historyList.slice(0, 3).map((item, index) => (
                                <Flex
                                    key={index}
                                    align={'center'}
                                    justify={'space-between'}
                                    borderBottom={'1px solid #646B4833'}
                                    _last={{ borderBottom: 'none' }}
                                >
                                    <Text
                                        p={'0px 16px'}
                                        flex={'0 0 100px'}
                                        textAlign={'center'}
                                    >
                                        {convertDate(item.treated_at)}
                                    </Text>

                                    <Text p={'0px 16px'} flex={'1 0 0%'}>
                                        {item.diagnosis_name}
                                    </Text>

                                    <Text
                                        p={'0px 16px'}
                                        flex={'0 0 100px'}
                                        textAlign={'center'}
                                    >
                                        {item.case_type}
                                    </Text>
                                    <Text
                                        p={'0px 16px'}
                                        flex={'0 0 100px'}
                                        textAlign={'center'}
                                    >
                                    </Text>
                                </Flex>
                            ))}
                    </Box>
                </Box> */}
            </Box>
        </>
    );
};
