import { Box, Text } from '@chakra-ui/react';
import { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { ConditionalSearch } from './ConditionalSearch';
import {
    callDeclareListsService,
    DeclareListsProps,
    DeclareListsResProps,
} from 'service/declareListsService';
import { useUserStore } from 'store';
import { useEffect } from 'react';

export const List = () => {
    const { userInfo } = useUserStore();
    const [data, setData] = useState<DeclareListsProps[]>([]);
    const callDeclareListsAPI = async () => {
        try {
            const result: DeclareListsResProps = await callDeclareListsService(
                userInfo.token
            );
            if (result.result === 'success') {
                setData(result.lists);
                console.log(result.lists, '申報要用的東西');
            }
        } catch (error) {
            console.error('調用申報列表服務時出錯:', error);
            // 這裡可以添加更多錯誤處理邏輯，例如顯示錯誤消息給用戶
        }
    };
    useEffect(() => {
        callDeclareListsAPI();
    }, []);

    return (
        <>
            <ConditionalSearch />

            <TableContainer
                h={`calc(${window.innerHeight}px - 320px)`}
                overflowY={'scroll'}
                pos={'relative'}
                mt={'8px'}
            >
                <Table variant="striped">
                    <Thead
                        pos={'sticky'}
                        top={0}
                        left={0}
                        w={'100%'}
                        zIndex={10}
                        bg={'#D9D9D9'}
                    >
                        <Tr>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>排序</Text>
                            </Th>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>病歷</Text>
                            </Th>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>案件</Text>
                            </Th>
                            <Th p={'4px 8px'} isNumeric>
                                <Text fontSize={'md'}>順序</Text>
                            </Th>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>日期</Text>
                            </Th>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>姓名</Text>
                            </Th>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>身份</Text>
                            </Th>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>病歷號</Text>
                            </Th>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>科別</Text>
                            </Th>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>主診斷</Text>
                            </Th>
                            <Th p={'4px 8px'} isNumeric>
                                <Text fontSize={'md'}>天數</Text>
                            </Th>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>卡號</Text>
                            </Th>
                            <Th p={'4px 8px'} isNumeric>
                                <Text fontSize={'md'}>點數</Text>
                            </Th>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>醫師</Text>
                            </Th>
                            <Th p={'4px 8px'}>
                                <Text fontSize={'md'}>查閱</Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data &&
                            data.map((item, index) => (
                                <Tr key={index}>
                                    <Td p={'4px 8px'}>&#x283F;</Td>
                                    <Td p={'4px 8px'}>
                                        <Box>
                                            <HeroIcons name="read-record" />
                                        </Box>
                                    </Td>
                                    <Td p={'4px 8px'}>{item.case_type}</Td>
                                    <Td p={'4px 8px'} isNumeric>
                                        {item.seq}
                                    </Td>
                                    <Td p={'4px 8px'}>{item.treated_at}</Td>
                                    <Td p={'4px 8px'}>{item.name}</Td>
                                    <Td p={'4px 8px'}>{item.membership}</Td>
                                    <Td p={'4px 8px'}>
                                        {item.medical_record_number}
                                    </Td>
                                    <Td p={'4px 8px'}>{item.subject_name}</Td>
                                    <Td p={'4px 8px'}>{item.main_diagnosis}</Td>
                                    <Td p={'4px 8px'} isNumeric>
                                        {/* {item.} */}
                                    </Td>
                                    <Td p={'4px 8px'}>{item.card_no}</Td>
                                    <Td p={'4px 8px'} isNumeric>
                                        {/* {item.points} */}
                                    </Td>
                                    <Td p={'4px 8px'}>{item.doctor_name}</Td>
                                    <Td p={'4px 8px'}>
                                        {/* {item.reviewed ? (
                                            <Box textColor={'rsPrimary.500'}>
                                                <HeroIcons name="check-circle-fill" />
                                            </Box>
                                        ) : (
                                            <Box textColor={'rsDanger.500'}>
                                                <HeroIcons name="book-open" />
                                            </Box>
                                        )} */}
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};
