import apiService from "./apiService";


function convertDateStringToDate(obj: any): any {
    if (typeof obj === 'string') {
      // 尝试转换ISO 8601日期字符串
      const date = Date.parse(obj);
      if (!isNaN(date)) {
        return new Date(obj);
      }
      return obj;
    } else if (Array.isArray(obj)) {
      return obj.map(item => convertDateStringToDate(item));
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj: { [key: string]: any } = {};
      Object.keys(obj).forEach(key => {
        newObj[key] = convertDateStringToDate(obj[key]);
      });
      return newObj;
    }
    return obj;
  }
export interface patientSearchListsProps{
    id: string;
    birth_date: string;
    id_no: string;
    id_no_type?:string;
    medical_record_number:string;
    name: string;
    phone: string;
    phone_type?: string;
}
// data1 = search_type
// data2 = keyword
// export const callPatientSearchService = async(token: string, data1: string, data2: string) => {
//     return apiService({
//         method: 'GET',
//         path: `/api/patient/search`,
//         token,
//         data: { data1, data2 }, 
//     })
// }
export const callPatientSearchService = async(token: string, data1: string, data2: string) => {
    const response = await apiService({
        method: 'GET',
        path: `/api/patient/search`,
        token,
        data: { data1, data2 }, 
    });
  

    // console.log( response?.info ,'只能先看')
    if (response && response.info && typeof response.info.birth_date === 'string') {
        console.log('有沒有來轉換')
      response.info.birth_date = new Date(response.info.birth_date);
    }
  
    return response;
  }