import {
    callIcDataVoidService,
    icDataVoidsReqDataProps,
} from 'service/icDataVoidService';
import { useICCardUpdateListStore } from 'store/ICCardUpdate/useICCardUpdateListStore';
import { useICCardUpdateRangeStore } from 'store/ICCardUpdate/useICCardUpdateRangeStore';
import { useUserStore } from 'store';

export const useIcDataVoidAPI = () => {
    const { userInfo } = useUserStore();
    const { clearICCardUpdateList, updateICCardUpdateList } =
        useICCardUpdateListStore();
    const { icCardUpdateRangeData } = useICCardUpdateRangeStore();

    const callIcDataVoidAPI = async (data?: icDataVoidsReqDataProps) => {
        console.log('時間區間: ', data);
        clearICCardUpdateList();
        try {
            const result = await callIcDataVoidService(
                userInfo.token,
                icCardUpdateRangeData
            );
            console.log('作廢清單：', result);
            if (result && result.lists) {
                const allItems = Object.values(result.lists)
                    .flat()
                    .map(item => ({
                        ...item,
                    }));
                updateICCardUpdateList(allItems);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return { callIcDataVoidAPI };
};
