import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ShowReadVHICStatusContextValue {
    showReadVHICStatus: boolean;
    setShowReadVHICStatus: React.Dispatch<React.SetStateAction<boolean>>;
    showSearchPatientMedicalRecord: boolean;
    setShowSearchPatientMedicalRecord: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

const ShowReadVHICStatusContext = createContext<
    ShowReadVHICStatusContextValue | undefined
>(undefined); // Set the initial value as undefined

interface ShowReadVHICStatusProviderProps {
    children: ReactNode;
}

export const ShowReadVHICStatusProvider: React.FC<
    ShowReadVHICStatusProviderProps
> = ({ children }) => {
    const [showReadVHICStatus, setShowReadVHICStatus] = useState(false);
    const [showSearchPatientMedicalRecord, setShowSearchPatientMedicalRecord] =
        useState(false);

    return (
        <ShowReadVHICStatusContext.Provider
            value={{
                showReadVHICStatus,
                setShowReadVHICStatus,
                showSearchPatientMedicalRecord,
                setShowSearchPatientMedicalRecord,
            }}
        >
            {children} {/* Fix: Remove double curly braces around children */}
        </ShowReadVHICStatusContext.Provider>
    );
};

export const useShowReadVHICStatus = (): ShowReadVHICStatusContextValue => {
    const context = useContext(ShowReadVHICStatusContext); // Fix: Remove the typo 'con'

    if (!context) {
        throw new Error(
            'useShowReadVHICStatus must be used within a ShowReadVHICStatusProvider'
        );
    }
    return context;
};
