import React, { useEffect, useState } from 'react';
import { Flex, Box, Button } from '@chakra-ui/react';
import { TypeOfMedicalVisit } from './TypeOfMedicalVisit';
import { WriteIntoIC } from './WriteIntoIC';
import { Printer } from './Printer';
import { BillCalculator } from './BillCalculator';
import { Saver } from './Saver';
import { useMedicalRecordStatus } from 'contexts/TreatmentManagement/MedicalRecordStatusContext';
import { useNavigate } from 'react-router-dom';
import { useTreatmentInfoStore } from 'store/TreatmentManagement';
import { useCurrentVisitInfoStore } from 'store/Registration';
import { useCCPEStore } from 'store/TreatmentManagement'
import { useDxStore } from 'store/TreatmentManagement';
import { usePrescriptionStore} from 'store/TreatmentManagement';
import { useCashDataStore } from 'store/TreatmentManagement/Payment';
import { useFeeStore } from 'store/TreatmentManagement/Payment';
import { usePatientInfoStore } from 'store/Registration';

export const CompleteAction: React.FC = () => {
    const navigate = useNavigate();
    const { medicalRecordStatus, setMedicalRecordStatus } = useMedicalRecordStatus();
    const { resetTreatmentInfo } = useTreatmentInfoStore();
    const { resetCurrentVisitInfoData } = useCurrentVisitInfoStore();
    const { resetCCPEData } = useCCPEStore();
    const { resetDx } = useDxStore();
    const { resetPrescriptionList } = usePrescriptionStore();
    const { resetCashDataStore } = useCashDataStore();
    const { resetFeeData } = useFeeStore();
    const { resetPatientInfo } = usePatientInfoStore();
    const resetAllStore = () => {
        resetTreatmentInfo();
        resetCurrentVisitInfoData();
        resetCCPEData();
        resetDx();
        resetPrescriptionList();
        resetCashDataStore();
        resetFeeData();
        resetPatientInfo();
    };
    const handleBack = () => {
        if (medicalRecordStatus === '1') {
            resetAllStore();
            setMedicalRecordStatus('0');
            setTimeout(() => {
                navigate('/OPDPage');
            }, 0);
        }else if(medicalRecordStatus === '2'){
            resetAllStore();
            setMedicalRecordStatus('0');
            setTimeout(() => {
                navigate('/ICCardUpdatePage');
            }, 0);
        }
    };
    return (
        <>
            <Flex gap={'8px'} py={'8px'} justify={'flex-end'}>
                {/* <Box hidden={true}>
                    <TypeOfMedicalVisit />
                    <WriteIntoIC />
                    <Printer />
                    <Saver />
                </Box> */}
                {(medicalRecordStatus === '1' || medicalRecordStatus === '2') && 
                    <Button
                        colorScheme='rsSecondary'
                        onClick={handleBack}
                    >
                        {`返回${medicalRecordStatus === '1' ? '候診表' : 'IC上傳清單'}`}
                    </Button>
                } 
                <BillCalculator />
            </Flex>
        </>
    );
};
