import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Text,
    Input,
    Button,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    TabIndicator,
    Select,
} from '@chakra-ui/react';

import Swal from 'sweetalert2';

import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';

import { useIsAddDrugFreqOpenStatus } from 'contexts/DataSetting/DrugFrequency';

import { useUserStore } from 'store';
import { useTakingTimeListsStore } from 'store/DataSetting/useTakingTimeListStore';
import { useDrugFreqListsStore } from 'store/DataSetting/useDrugFreqListStore';

// API
import { callDrugFreqBaseService } from 'service/drugFreqBaseService';
import { postDrugFreqSpecService } from 'service/drugFreqSpecService';

import { SetXValue } from './SetXValue'; // 假设 SetXValue 文件在同目录下
import { callDrugFreqListsService } from 'service/drugFreqListsService';

interface DrugBaseProps {
    freq_code?: string;
    directions?: string;
    days?: number;
    times?: number;
    transfer: number;
    minimum?: number | null;
    maximum?: number | null;
    type: number;
}

interface DrugSpecProps {
    data_id?: string;
    code: string;
    freq_code: string;
    ori_freq_code: string;
    directions: string;
    days?: number | null;
    times?: number | null;
    type: number;
    taking_time?: string;
    transfer?: number;
    x: number;
}

export const AddDrugFreq: React.FC = () => {
    const { userInfo } = useUserStore();

    const { drugFreqListsData, updateDrugFreqListsData } =
        useDrugFreqListsStore();
    const { takingTimeListsData } = useTakingTimeListsStore();

    const { isAddDrugFreqOpenStatus, setIsAddDrugFreqOpenStatus } =
        useIsAddDrugFreqOpenStatus();

    const [isSetXValueOpen, setIsSetXValueOpen] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [currentDirections, setCurrentDirections] = useState('');
    const [inputLimits, setInputLimits] = useState<{
        minimum?: number;
        maximum?: number;
    }>({});

    const handleCloseAddDrugFreq = () => {
        setIsAddDrugFreqOpenStatus(false);
        setDrugSpecData(initialAddingSpecData);
    };

    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleChangeTabIndex = (e: number) => {
        setSelectedIndex(e);
        callDrugFreqBaseAPI(e + 1);
    };

    const [baseList, setBaseList] = useState<DrugBaseProps[]>([]);

    const callDrugFreqBaseAPI = async (type: number) => {
        try {
            const result = await callDrugFreqBaseService(userInfo.token, type);

            if (result && result.result === 'success') {
                setBaseList(result.lists);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: `${result.msg}`,
                    timer: 1500,
                    showConfirmButton: false,
                });
                // console.error('API call did not succeed:', result);
            }
        } catch (error) {
            console.error('Error in callDrugFreqBaseAPI:', error);
        }
    };

    useEffect(() => {
        callDrugFreqBaseAPI(selectedIndex + 1);
    }, [selectedIndex]);

    const initialAddingSpecData: DrugSpecProps = {
        data_id: '',
        code: '',
        freq_code: '',
        ori_freq_code: '',
        directions: '',
        days: null,
        times: null,
        type: 0,
        taking_time: '',
        transfer: 0,
        x: 0,
    };

    const [drugSpecData, setDrugSpecData] = useState<DrugSpecProps>(
        initialAddingSpecData
    );

    const renderBaseFreqPanel = (
        data: DrugBaseProps[],
        handleCopyToCurrent: (item: DrugBaseProps) => void
    ) => {
        return data.map((item, index) => (
            <Flex
                key={`${item.freq_code}-${index}`}
                borderBottom={'1px solid #646B48'}
                _hover={{
                    cursor: 'pointer',
                    bg: '#D2D2B3',
                }}
                onClick={() => handleCopyToCurrent(item)}
            >
                <Box p={'4px 16px'} flex={'0 0 140px'}>
                    <Text>{item.freq_code}</Text>
                </Box>
                <Box p={'4px 16px'} flex={'1 0 0'}>
                    <Text>{item.directions}</Text>
                </Box>
                <Box p={'4px 16px'} flex={'0 0 140px'}>
                    <Box textAlign={'center'}>
                        <HeroIcons name="bars-arrow-up"></HeroIcons>
                    </Box>
                </Box>
            </Flex>
        ));
    };

    const handleCopyToCurrent = (item: DrugBaseProps) => {
        if (item.freq_code?.includes('x')) {
            setIsSetXValueOpen(true);
            setCurrentDirections(item.directions || '');
            setInputLimits({
                minimum: item.minimum || undefined,
                maximum: item.maximum || undefined,
            });
            setDrugSpecData(prevData => ({
                ...initialAddingSpecData,
                ...prevData,
                code: item.freq_code || '',
                freq_code: item.freq_code || '',
                ori_freq_code: item.freq_code || '',
                directions: item.directions || '',
                days: item.days ?? null,
                times: item.times ?? null,
                transfer: item.transfer,
                type: item.type !== undefined ? item.type : 0,
            }));
        } else {
            setDrugSpecData({
                ...initialAddingSpecData,
                code: item.freq_code || '',
                freq_code: item.freq_code || '',
                ori_freq_code: item.freq_code || '',
                directions: item.directions || '',
                days: item.days ?? null,
                times: item.times ?? null,
                transfer: item.transfer,
                type: item.type !== undefined ? item.type : 0,
            });
        }
    };

    const [IsACorPC, setIsACorPC] = useState(false);
    const containsACorPC = (str: string): boolean => {
        return /AC|PC/.test(str);
    };

    useEffect(() => {
        if (containsACorPC(drugSpecData.ori_freq_code)) {
            setIsACorPC(true);
        } else {
            setIsACorPC(false);
        }
    }, [drugSpecData]);

    const handleSetXValueSubmit = () => {
        if (
            (inputLimits.maximum &&
                inputLimits.maximum < parseFloat(inputValue)) ||
            (inputLimits.minimum &&
                inputLimits.minimum > parseFloat(inputValue))
        ) {
            Swal.fire({
                icon: 'warning',
                title: '數值超出限制範圍',
                text: `${inputLimits.minimum} ~ ${inputLimits.maximum}`,
                timer: 1500,
                showConfirmButton: false,
            });
            setInputValue('');
        } else {
            if (inputValue.trim() === '') {
                // 如果没有输入 x 值，则不更新 drugSpecData
                setIsSetXValueOpen(false);
                return;
            }
            const x = parseFloat(inputValue);
            const updatedFreqCode = drugSpecData.freq_code.replace(
                'x',
                inputValue
            );
            const updatedDirections = drugSpecData.directions.replace(
                'x',
                inputValue
            );

            setDrugSpecData(prevData => {
                let newTimes = prevData.times;
                let newDays = prevData.days;

                if (prevData.transfer !== undefined) {
                    if (prevData.transfer === 1) {
                        if (prevData.ori_freq_code === 'QxD') {
                            // QxD transfer 為 1，但需要計算 days
                            newDays = prevData.transfer * x;
                            newTimes = prevData.times;
                        } else {
                            // transfer 為 1 时，不需计算 times, days
                            newTimes = prevData.times;
                            newDays = prevData.days;
                        }
                    } else if (prevData.transfer < 0) {
                        // transfer 為負數時，計算 times
                        newTimes = -prevData.transfer / x;
                        newDays = prevData.days;
                    } else if (prevData.transfer > 0) {
                        // transfer 為正數時，計算 days
                        newDays = prevData.transfer * x;
                        newTimes = prevData.times;
                    }
                }

                return {
                    ...prevData,
                    code: updatedFreqCode,
                    freq_code: updatedFreqCode,
                    directions: updatedDirections,
                    times: newTimes,
                    days: newDays,
                    x: x,
                };
            });

            // 关闭 x 值设置对话框
            setIsSetXValueOpen(false);
            setInputValue('');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDrugSpecData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value: newTakingTime } = e.target;

        setDrugSpecData(prevData => {
            let { freq_code } = prevData;

            // 找出 AC 或 PC 的 index 位置
            const acIndex = freq_code.lastIndexOf('AC');
            const pcIndex = freq_code.lastIndexOf('PC');

            // 如果 index 不為 -1 代表字串中有 AC PC
            if (acIndex !== -1) {
                freq_code = freq_code.substring(0, acIndex); // 保留 index 0 ~ acIndex 的值，等於將 AC 之後的移除
            } else if (pcIndex !== -1) {
                freq_code = freq_code.substring(0, pcIndex);
            }

            // 添加新的 taking_time 到 freq_code
            freq_code = `${freq_code}${newTakingTime}`;

            return {
                ...prevData,
                taking_time: newTakingTime,
                freq_code: freq_code,
            };
        });
    };

    const postDrugFreqSpecAPI = async (data: DrugSpecProps) => {
        try {
            const result = await postDrugFreqSpecService(userInfo.token, data);

            if (result.result === 'success') {
                setIsAddDrugFreqOpenStatus(false);
                Swal.fire({
                    icon: 'success',
                    title: `${drugSpecData.code} 已新增`,
                    timer: 1500,
                    showConfirmButton: false,
                });

                callDrugFreqListsAPI();
            } else {
                console.error('API Error:', result);
                Swal.fire({
                    icon: 'error',
                    title: `${result.msg}`,
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
            console.log('drugSpecData:', drugSpecData);
            setDrugSpecData(prev => ({
                ...initialAddingSpecData,
            }));
        } catch (error) {
            console.error('Network or server error:', error);
        }
    };

    const handleSaveDrugSpec = () => {
        postDrugFreqSpecAPI(drugSpecData);
        console.log('save: ', drugSpecData);
    };

    const callDrugFreqListsAPI = async () => {
        try {
            const result = await callDrugFreqListsService(userInfo.token);
            if (result.result === 'success') {
                updateDrugFreqListsData(result.lists);
            } else {
                console.error('API 呼叫失敗:', result.msg);
            }
        } catch (error) {
            console.error('呼叫藥品頻率列表服務時發生錯誤:', error);
            Swal.fire({
                icon: 'error',
                title: '發生錯誤',
                text: '無法獲取藥品頻率列表，請稍後再試',
                confirmButtonText: '確定',
            });
        }
    };

    useEffect(() => {
        console.log('drugSpecData in UseEffect:', drugSpecData);
    }, [drugSpecData]);

    return (
        <>
            {isAddDrugFreqOpenStatus && (
                <Box
                    pos={'fixed'}
                    top={0}
                    left={0}
                    bg={'#00000099'}
                    w={'100%'}
                    h={'100%'}
                    zIndex={50}
                    p={'16px'}
                >
                    <Box
                        maxW={'1280px'}
                        bg={'#fff'}
                        w={'calc( 100% - 32px)'}
                        zIndex={60}
                        pos={'absolute'}
                        top={'90px'}
                        left={'50%'}
                        transform={'translateX(-50%)'}
                        maxH={`calc(${window.innerHeight}px - 90px - 16px)`}
                        overflowY={'auto'}
                    >
                        <Flex
                            p={'8px 16px'}
                            justify={'space-between'}
                            bg={'rsPrimary.800'}
                        >
                            <Text textColor={'#fff'}>新增用法</Text>
                            <Box
                                w={'24px'}
                                opacity={0.8}
                                _hover={{ cursor: 'pointer', opacity: 1 }}
                                onClick={handleCloseAddDrugFreq}
                                textColor={'#fff'}
                            >
                                <HeroIcons name="xmark"></HeroIcons>
                            </Box>
                        </Flex>

                        <Flex
                            p={'8px 16px'}
                            mb={'8px'}
                            gap={'8px'}
                            align={'flex-end'}
                        >
                            <Box flex={'0 0 140px'}>
                                <Text fontSize={'sm'}>代碼</Text>
                                <Input
                                    bg={'#fff'}
                                    boxShadow={
                                        'inset 4px 2px 2px rgba(0,0,0,0.2)'
                                    }
                                    border={'1px solid #7A7A7A'}
                                    placeholder="ex: QID"
                                    name="code"
                                    onChange={handleChange}
                                    value={drugSpecData.code}
                                ></Input>
                            </Box>
                            <Box flex={'1 0 auto'}>
                                <Text fontSize={'sm'}>用法</Text>
                                <Input
                                    bg={'#fff'}
                                    boxShadow={
                                        'inset 4px 2px 2px rgba(0,0,0,0.2)'
                                    }
                                    border={'1px solid #7A7A7A'}
                                    placeholder="ex: 每日 4 次"
                                    name="directions"
                                    onChange={handleChange}
                                    value={drugSpecData.directions}
                                ></Input>
                            </Box>
                            <Box flex={'0 0 160px'}>
                                <Text fontSize={'sm'}>服用時間</Text>
                                <Select
                                    disabled={IsACorPC}
                                    placeholder="請選擇"
                                    bg={'#fff'}
                                    onChange={handleSelectChange}
                                >
                                    {takingTimeListsData &&
                                        takingTimeListsData.map(item => (
                                            <option
                                                key={item.data_id}
                                                value={item.freq_code}
                                            >
                                                {item.freq_code}
                                            </option>
                                        ))}
                                </Select>
                            </Box>
                            <Box flex={'0 0 80px'}>
                                <Text fontSize={'sm'}>天數</Text>
                                <Input
                                    disabled={IsACorPC}
                                    bg={'#fff'}
                                    boxShadow={
                                        'inset 4px 2px 2px rgba(0,0,0,0.2)'
                                    }
                                    border={'1px solid #7A7A7A'}
                                    placeholder="ex: 1"
                                    name="days"
                                    onChange={handleChange}
                                    value={drugSpecData.days ?? ''}
                                ></Input>
                            </Box>
                            <Box flex={'0 0 80px'}>
                                <Text fontSize={'sm'}>次數</Text>
                                <Input
                                    disabled={IsACorPC}
                                    bg={'#fff'}
                                    boxShadow={
                                        'inset 4px 2px 2px rgba(0,0,0,0.2)'
                                    }
                                    border={'1px solid #7A7A7A'}
                                    placeholder="ex: 4"
                                    name="times"
                                    onChange={handleChange}
                                    value={drugSpecData.times ?? ''}
                                ></Input>
                            </Box>
                            <Box flex={'0 0 140px'}>
                                <Text fontSize={'sm'}>健保碼</Text>

                                <Box
                                    p={'7px 16px'}
                                    rounded={'lg'}
                                    bg={'cyan.50'}
                                    border={'1px solid #7A7A7A'}
                                    boxShadow={
                                        'inset 4px 2px 2px rgba(0,0,0,0.2)'
                                    }
                                    h={'40px'}
                                >
                                    {drugSpecData.freq_code}
                                </Box>
                            </Box>

                            <Button
                                colorScheme="rsSecondary"
                                flex={'0 0 auto'}
                                onClick={handleSaveDrugSpec}
                            >
                                儲存
                            </Button>
                        </Flex>

                        <Tabs
                            px={'16px'}
                            mb={'16px'}
                            onChange={handleChangeTabIndex}
                            index={selectedIndex}
                        >
                            <TabList
                                borderRadius={'15px'}
                                bgColor={'gray.50'}
                                borderBottom={'2px solid #ccc'}
                                px={'16px'}
                                py={'8px'}
                            >
                                <Tab
                                    textColor={'rsPrimary.500'}
                                    fontWeight={'semibold'}
                                    opacity={0.5}
                                    _selected={{
                                        opacity: 1,
                                    }}
                                >
                                    每日常規使用頻率
                                </Tab>
                                <Tab
                                    textColor={'rsPrimary.500'}
                                    fontWeight={'semibold'}
                                    opacity={0.5}
                                    _selected={{
                                        opacity: 1,
                                    }}
                                >
                                    非每日常規使用頻率
                                </Tab>
                                <Tab
                                    textColor={'rsPrimary.500'}
                                    fontWeight={'semibold'}
                                    opacity={0.5}
                                    _selected={{
                                        opacity: 1,
                                    }}
                                >
                                    需要時使用
                                </Tab>
                                <Tab
                                    textColor={'rsPrimary.500'}
                                    fontWeight={'semibold'}
                                    opacity={0.5}
                                    _selected={{
                                        opacity: 1,
                                    }}
                                >
                                    服用時間
                                </Tab>
                            </TabList>
                            <TabIndicator
                                mt="-16px"
                                height="2px"
                                bg="#9B9B7A"
                                borderRadius="1px"
                            />

                            <Box
                                maxH={`calc(${window.innerHeight}px - 90px - 77px - 8px - 58px - 8px - 16px - 16px - 64px)`}
                                overflowY={'auto'}
                                mt={'8px'}
                                boxShadow={'0 1px 1px #00000033'}
                            >
                                <Flex
                                    bg={'#D9D9D9'}
                                    pos={'sticky'}
                                    top={0}
                                    left={0}
                                    zIndex={10}
                                >
                                    <Box p={'4px 16px'} flex={'0 0 140px'}>
                                        <Text
                                            fontWeight={'semibold'}
                                            textColor={'#555B3E'}
                                        >
                                            健保碼
                                        </Text>
                                    </Box>
                                    <Box p={'4px 16px'} flex={'1 0 0'}>
                                        <Text
                                            fontWeight={'semibold'}
                                            textColor={'#555B3E'}
                                        >
                                            用法
                                        </Text>
                                    </Box>

                                    <Box p={'4px 16px'} flex={'0 0 140px'}>
                                        <Text
                                            fontWeight={'semibold'}
                                            textColor={'#555B3E'}
                                            textAlign={'center'}
                                        >
                                            帶入
                                        </Text>
                                    </Box>
                                </Flex>

                                <TabPanels pos={'relative'}>
                                    <TabPanel p={0} key={1}>
                                        {renderBaseFreqPanel(
                                            baseList,
                                            handleCopyToCurrent
                                        )}
                                    </TabPanel>
                                    <TabPanel p={0} key={2}>
                                        {renderBaseFreqPanel(
                                            baseList,
                                            handleCopyToCurrent
                                        )}
                                    </TabPanel>
                                    <TabPanel p={0} key={3}>
                                        {renderBaseFreqPanel(
                                            baseList,
                                            handleCopyToCurrent
                                        )}
                                    </TabPanel>
                                    <TabPanel p={0} key={4}>
                                        {renderBaseFreqPanel(
                                            baseList,
                                            handleCopyToCurrent
                                        )}
                                    </TabPanel>
                                </TabPanels>
                            </Box>
                        </Tabs>
                    </Box>
                    <SetXValue
                        isOpen={isSetXValueOpen}
                        onClose={() => {
                            setIsSetXValueOpen(false);
                            setDrugSpecData(initialAddingSpecData);
                            setInputValue('');
                        }}
                        onSubmit={handleSetXValueSubmit}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        directions={currentDirections}
                        inputLimits={inputLimits}
                    />
                </Box>
            )}
        </>
    );
};
