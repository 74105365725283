import React from 'react';
import { Box, Flex, Center, Text, Button } from '@chakra-ui/react';
import { usePatientInfoStore } from 'store/Registration';
import { ADDateTransToROCStr } from 'functions/ADDateTransToROCStr';

export const PatientInfo = () => {
    const { patientInfo } = usePatientInfoStore();

    return (
        <Flex gap={'32px'} h={'40px'} align={'center'}>
            <Flex gap={'8px'}>
                <Text fontWeight={'semibold'} w={'48px'} flexShrink={0}>
                    病歷號
                </Text>
                <Text fontWeight={'normal'}>
                    {patientInfo.info.medical_record_number}
                </Text>
            </Flex>

            <Flex gap={'8px'} align={'center'}>
                <Text fontWeight={'semibold'} w={'32px'} flexShrink={0}>
                    姓名
                </Text>
                <Box
                    bg={
                        patientInfo.info.sex === 'M'
                            ? '#79A09F'
                            : patientInfo.info.sex === 'F'
                              ? '#D28989'
                              : 'red.500'
                    }
                    h={'40px'}
                    px={'8px'}
                    rounded={'4px'}
                    textAlign={'center'}
                >
                    <Text
                        fontSize={'30px'}
                        textColor={'#fff'}
                        lineHeight={'40px'}
                    >
                        {patientInfo.info.name}
                    </Text>
                </Box>
            </Flex>

            <Flex gap={'8px'}>
                <Text fontWeight={'semibold'} w={'32px'} flexShrink={0}>
                    生日
                </Text>
                <Text fontWeight={'normal'}>
                    {patientInfo.info.birth_date &&
                        ADDateTransToROCStr(patientInfo.info.birth_date)}
                </Text>
            </Flex>
            <Flex gap={'8px'}>
                <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                    身分證號
                </Text>
                <Text fontWeight={'normal'}>{patientInfo.info.id_no}</Text>
            </Flex>
        </Flex>
    );
};
