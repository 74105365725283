import axios from 'axios';
import apiService from './apiService';
import axiosInstance from './axiosInstance';
// 取得小兒劑量
export const callDrugChildrenDosageService = async (token: string, data:string) => {
    const queryParams = data? {data} : undefined;
    return apiService<string, any>({
        method: 'GET',
        path: 'api/drug/children_dosage',
        token,
        data: queryParams
    })
}
// 取得小兒劑量
// export const callDrugChildrenDosageService = async (
//     token: string,
//     id: string
// ) => {
//     try {
//         const queryParams = { id };
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         const response = await axios.get('/api/drug/children_dosage', {
//             headers,
//             params: queryParams,
//         });
//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

// import axios from 'axios';


interface relatedSettingDataProps {
    drug_in_use_uid: string;
    [key: `kg_${number}`]: string | null;
}
// 增修小兒劑量
export const postDrugChildrenDosageService = async (token: string, updatedData: relatedSettingDataProps) => {
    return apiService<relatedSettingDataProps, any>({
        method: 'POST',
        path: '/api/drug/children_dosage',
        token,
        data: updatedData,
    });
};
// export const postDrugChildrenDosageService = async (
//     token: string,
//     updatedData?: relatedSettingDataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);

//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         console.log(requestBody, 'requestBody service印的');

//         const response = await axiosInstance.post(
//             '/api/drug/children_dosage',
//             requestBody,
//             {
//                 headers,
//             }
//         );

//         const result = response.data;

//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
