import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import WorkingScheduleDatePicker from './WorkingScheduleDatePicker';
import WorkingScheduleBoard from './WorkingScheduleBoard';
import { Flex, Box, Button, Grid, GridItem } from '@chakra-ui/react';
import GroupMemberIcon from '../../CustomComponents/GroupMemberIcon';
import { WeekRangeProvider } from 'contexts/WeekRangeContext';

interface WorkingScheduleTableProps {
    sidebarOpenRef: React.RefObject<any>;
}

const WorkingScheduleTable: React.FC<WorkingScheduleTableProps> = ({
    sidebarOpenRef,
}) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date()); // Add state for selected date
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);

    const handleChangeDate = (date: Date | null) => {
        setSelectedDate(date);
        // console.log('date picker 改變', date);
    };

    useEffect(() => {
        if (
            sidebarOpenRef.current !== undefined &&
            sidebarOpenRef.current !== null
        ) {
            setSidebarOpen(sidebarOpenRef.current);
        }
    });

    return (
        <>
            <Flex gap={'16px'}>
                <WeekRangeProvider>
                    <Box alignSelf={'baseline'} flex={'0 0 auto'}>
                        <WorkingScheduleDatePicker />
                    </Box>
                    <Box flex={'1 0 auto'}>
                        <WorkingScheduleBoard />
                    </Box>
                </WeekRangeProvider>
            </Flex>
        </>
    );
};

export default WorkingScheduleTable;
