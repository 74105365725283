import React from 'react';
import { Text, Textarea, Box } from '@chakra-ui/react';
import Loading from 'components/CustomComponents/Loading';
import { useIsHistoryLoadingStatus } from 'contexts/TreatmentManagement/MedicalRecord/IsHistoryLoadingContext';

type InfoProps = {
    data_id: string;
    cc: string;
    treated_at: string;
};

type CCPEProps = {
    info: InfoProps | null;
};

export const CCPECopy: React.FC<CCPEProps> = ({ info }) => {
    const { isHistoryLoadingStatus } = useIsHistoryLoadingStatus();
    
    return (
        <>
            <Text textColor={'#646B48'} fontWeight={'semibold'}>
                CC / PE
            </Text>
            <Box position="relative" height="120px">
                <Textarea
                    readOnly
                    bg={'#fff'}
                    resize={'none'}
                    boxShadow={'2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset'}
                    height={'100%'}
                    value={info ? info.cc : ''}
                    opacity={isHistoryLoadingStatus ? 0.5 : 1}
                />
                {isHistoryLoadingStatus && (
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bg="rgba(255, 255, 255, 0.7)"
                    >
                        <Loading />
                    </Box>
                )}
                {!isHistoryLoadingStatus && !info && (
                    <Textarea
                        readOnly
                        bg={'#fff'}
                        resize={'none'}
                        boxShadow={'2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset'}
                        height={'100%'}
                        defaultValue={'暫無資料'}
                        opacity={0}
                    />
                )}
            </Box>
        </>
    );
};
