import axios from 'axios';
import apiService from './apiService';

interface checkCodeProps {
    user: string;
    code: string;
    mac: string;
    request_time: string;
}
// 確認驗證碼 
export const postVerifyCheckCodeService = async (
    // token: string,
    updatedData: checkCodeProps
) => {
    return apiService<checkCodeProps, any>({
        method: 'POST',
        path: '/api/verify/check_code',
        // token,
        data: updatedData
    })
}
// export const postVerifyCheckCodeService = async (
//     token: string,
//     updatedData: checkCodeProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         console.log(requestBody, 'requestBody service印的');

//         const response = await axios.post('/api/verify/check_code', requestBody, {
//             headers,
//         });
//         const result = response.data;
//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
