import React from 'react';
import { Box, Text, Flex, Select, Input } from '@chakra-ui/react';

export const ClinicInfo = () => {
    const handleChangeInput = (
        event: React.ChangeEvent<
            HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
        >
    ) => {
        console.log('textarea');
    };
    return (
        <>
            <Text textStyle={'semiTitle'} mb={'8px'}>
                院所資料
            </Text>
            <Flex
                columnGap={'16px'}
                rowGap={'8px'}
                flexWrap={'wrap'}
                justify={'space-between'}
            >
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(33.333% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        醫師
                    </Text>
                    <Select bg={'#fff'} value={''} onChange={handleChangeInput}>
                        <option value="李阿信"></option>
                    </Select>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(33.333% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        科別
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(33.333% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        電話
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(33.333% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        傳真
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(66.666% - 8px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        email
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex align={'center'} gap={'8px'} flexBasis={'100%'}>
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        地址
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
            </Flex>
        </>
    );
};
