import {
    callDeclareDownloadService,
    DeclareDownloadReqDataProps,
} from 'service/declareDownloadService';
import { useUserStore } from 'store';
import Swal from 'sweetalert2';

export const useDeclareDownloadAPI = () => {
    const { userInfo } = useUserStore();

    const callDeclareDownloadAPI = async (data?: string) => {
        try {
            const response = await callDeclareDownloadService(
                userInfo.token,
                data
            );

            console.log('response', response);

            if (response.result === 'success') {
                console.log('Success:', response.result, '開始下載');
            } else if (response.result === 'failure') {
                console.log('Failure:', response.result);
                Swal.fire({
                    icon: 'error',
                    title: '下載過程發生錯誤',
                    confirmButtonText: '確定',
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire({
                icon: 'error',
                title: '下載過程發生錯誤',
                confirmButtonText: '確定',
            });
        }
    };
    return { callDeclareDownloadAPI };
};
