import React, { useEffect, useState } from 'react';
import { Button, Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { ADStringToROCString } from 'functions/ADStringToROCString';
import {
    useTreatmentInfoStore,
    useHistoryStore,
} from 'store/TreatmentManagement';
import { callMedicalRecordHistoryDataListsService } from 'service/medicalRecordHistoryDateListsService';
import { callMedicalRecordHistorySpecService } from 'service/medicalRecordHistorySpecService';
import { useUserStore } from 'store';
import { useIsHistoryLoadingStatus } from 'contexts/TreatmentManagement/MedicalRecord/IsHistoryLoadingContext';

import { useHistoryCCPEStore } from 'store/TreatmentManagement/HistoryRecord';
import { useHistoryDxListStore } from 'store/TreatmentManagement/HistoryRecord';
import { useHistoryPrescriptionStore } from 'store/TreatmentManagement/HistoryRecord';
type historyListProps = {
    treated_at: string;
    data_id: string;
    case_type: string | null;
    card_no: string;
    doctor_name: string;
    icd_code: string;
    diagnosis_name: string;
};

type SuccessResult = {
    result: 'success';
    lists: historyListProps[];
};

type FailureResult = {
    result: 'failure';
    msg: string;
};

type ApiResponse = SuccessResult | FailureResult;

const isSuccessResult = (result: ApiResponse): result is SuccessResult => {
    return result.result === 'success';
};

export const HistoryList: React.FC = () => {
    const { userInfo } = useUserStore();
    const { treatmentInfo } = useTreatmentInfoStore();
    const [historyList, setHistoryList] = useState<historyListProps[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const { historyData, updateHistoryData, resetHistoryData } =
        useHistoryStore();
    const { isHistoryLoadingStatus, setIsHistoryLoadingStatus } =
        useIsHistoryLoadingStatus();
        const { historyPrescriptionList, resetHistoryPrescriptionList } = useHistoryPrescriptionStore();
        const { historyCCPEData, resetHistoryCCPEData } = useHistoryCCPEStore();
        const { histroyDxList, resetHistoryDxList } = useHistoryDxListStore();
    const callMedicalRecordHistoryDateListsAPI = async () => {
        setLoading(true);
        console.log(treatmentInfo, '看一下patientInfo');
        if (treatmentInfo.patient_uid && userInfo.token) {
            try {
                const result = await callMedicalRecordHistoryDataListsService(
                    userInfo.token,
                    treatmentInfo.patient_uid
                );
                if (isSuccessResult(result)) {
                    console.log(result.lists, '先看一下歷史病例');
                    setHistoryList(result.lists);
                    setErrorMsg(null);
                    // setIsHistoryLoadingStatus(true)
                    setSelectedIndex(0);
                } else {
                    console.log(result.msg);
                    setErrorMsg(result.msg);
                }
            } catch (error) {
                console.error('Error fetching medical records:', error);
                setErrorMsg('Failed to fetch medical records');
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (treatmentInfo.patient_uid && userInfo.token) {
            callMedicalRecordHistoryDateListsAPI();
        }
    }, [treatmentInfo.patient_uid, userInfo.token]);

    const resetAllHistory = ()=>{
        resetHistoryPrescriptionList()
        resetHistoryCCPEData()
        resetHistoryDxList()
        resetHistoryData();
    }
    const handleChangeIndex = (index: number) => {
        resetAllHistory()
        console.log(index);
        setIsHistoryLoadingStatus(true);
        setSelectedIndex(index);
    };
 
    useEffect(() => {
        if (historyList.length > 0) {
      
            callMedicalRecordHistorySpecAPI();
        }
    }, [selectedIndex]);


    useEffect(()=>{
        console.log(historyData, 'historyDAta有東西嗎一開始')
    }, [historyData])

    const callMedicalRecordHistorySpecAPI = async () => {
        if (historyList.length > 0 && selectedIndex >= 0) {
            try {
             
                const result = await callMedicalRecordHistorySpecService(
                    userInfo.token,
                    historyList[selectedIndex].data_id
                );
                console.log(result, '先看一下歷史病例的result');
                if (result && result.result === 'success') {
                    updateHistoryData({
                        info: result.info || null,
                        diagnosis: result.diagnosis || [],
                        drugs: result.drugs || [],
                    });
                    setIsHistoryLoadingStatus(false);
                } else {
                    setErrorMsg('無法獲取特定醫療記錄');
                    setIsHistoryLoadingStatus(false);
                }
            } catch (error) {
                console.error('Error fetching specific medical record:', error);
                setErrorMsg('Failed to fetch specific medical record');
            }
        }
    };
    useEffect(()=>{
        resetAllHistory()
    }, [])

    return (
        <>
            {/* 歷史清單 */}
            <Box pos={'relative'} zIndex={20}>
                <Text textColor={'#646B48'} fontWeight={'semibold'}>
                    歷史病歷{' '}
                    {/* <Text as="span">
                        （ {visibleCount} / {historyList.length} 筆）
                    </Text> */}
                </Text>
                <Box maxH={'116px'} overflowY={'auto'}>
                    {/* 表頭 */}
                    <Flex
                        gap={'16px'}
                        borderBottom={'1px solid #111'}
                        py={'2px'}
                        pos={'sticky'}
                        top={0}
                        left={0}
                        bg={'#fff'}
                    >
                        <Text
                            fontWeight={'semibold'}
                            textColor={'#646B48'}
                            textAlign={'center'}
                            flex={'0 0 13%'}
                        >
                            日期
                        </Text>
                        <Text
                            fontWeight={'semibold'}
                            textColor={'#646B48'}
                            textAlign={'center'}
                            flex={'1 0 50%'}
                        >
                            主診斷
                        </Text>
                        <Text
                            fontWeight={'semibold'}
                            textColor={'#646B48'}
                            textAlign={'center'}
                            flex={'0 0 13%'}
                        >
                            案件
                        </Text>
                        <Text
                            fontWeight={'semibold'}
                            textColor={'#646B48'}
                            textAlign={'center'}
                            flex={'0 0 13%'}
                        >
                            卡號
                        </Text>
                    </Flex>

                    {loading ? (
                        <Flex justifyContent="center" py="8px">
                            <Text>載入中...</Text>
                        </Flex>
                    ) : errorMsg ? (
                        <Flex justifyContent="center" py="8px">
                            <Text>{errorMsg}</Text>
                        </Flex>
                    ) : historyList.length === 0 ? (
                        <Flex justifyContent="center" py="8px">
                            <Text>暫無資訊</Text>
                        </Flex>
                    ) : (
                        historyList &&
                        historyList.map((item, index) => (
                            <Flex
                                gap={'16px'}
                                py={'2px'}
                                key={index}
                                onClick={() => handleChangeIndex(index)}
                                bg={
                                    index === selectedIndex
                                        ? 'rsPrimary.100'
                                        : 'transparent'
                                }
                                _hover={{
                                    bg: 'rsPrimary.100',
                                    cursor: 'pointer',
                                }}
                                borderBottom={'1px solid #eee'}
                            >
                                <Text textAlign={'center'} flex={'0 0 13%'}>
                                    {ADStringToROCString(item.treated_at)}
                                </Text>
                                <Tooltip
                                    label={item.diagnosis_name}
                                    hasArrow
                                    bg={'gray'}
                                >
                                    <Text
                                        flex={'1 0 50%'}
                                        overflow={'hidden'}
                                        whiteSpace={'nowrap'}
                                        textOverflow={'ellipsis'}
                                    >
                                        {item.diagnosis_name}
                                    </Text>
                                </Tooltip>
                                <Text textAlign={'center'} flex={'0 0 13%'}>
                                    {item.case_type}
                                </Text>
                                <Text textAlign={'center'} flex={'0 0 13%'}>
                                    {item.card_no}
                                </Text>
                            </Flex>
                        ))
                    )}
                    <Box textAlign={'center'} mt={'8px'}>
                        <Button
                            colorScheme="rsSecondary"
                            size={'sm'}
                            w={'100%'}
                            hidden
                        >
                            載入更多
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
