import { Flex, Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { VitalSignHeader } from './VitalSignHeader';

export const WaitingListsHeader = () => {
    let location = useLocation();
    const pathName = location.pathname;
    const pathSegments = pathName.split('/');
    const lastSegment = pathSegments.pop();
    return (
        <>
            <Flex
                p={'5px'}
                bg={'gray.150'}
                borderBottom={'1px solid #868C6E'}
                pos={'sticky'}
                top={'0'}
                left={'0'}
                w={'100%'}
                zIndex={10}
                align={'center'}
            >
                <Box textColor={'rsPrimary.600'} px={'4px'} flex={'1 0 5%'}>
                    順序
                </Box>
                <Box textColor={'rsPrimary.600'} px={'4px'} flex={'1 0 5%'}>
                    時段
                </Box>
                <Box textColor={'rsPrimary.600'} px={'4px'} flex={'1 0 5%'}>
                    診間
                </Box>
                <Box textColor={'rsPrimary.600'} px={'4px'} flex={'1 0 9%'}>
                    醫師姓名
                </Box>
                <Box textColor={'rsPrimary.600'} px={'4px'} flex={'1 0 9%'}>
                    病患姓名
                </Box>
                <Box textColor={'rsPrimary.600'} px={'4px'} flex={'1 0 4%'}>
                    身份
                </Box>
                <Box textColor={'rsPrimary.600'} px={'4px'} flex={'1 0 7%'}>
                    生日
                </Box>
                <Box textColor={'rsPrimary.600'} px={'4px'} flex={'1 0 5%'}>
                    卡號
                </Box>
                <Box textColor={'rsPrimary.600'} px={'4px'} flex={'1 0 12%'}>
                    病歷號
                </Box>

                <VitalSignHeader />

                <Box
                    textColor={'rsPrimary.600'}
                    px={'4px'}
                    flex={'1 0 6%'}
                    textAlign={'center'}
                >
                    告知診間
                </Box>
                {lastSegment === 'RegisteredPage' ? (
                    <>
                        <Box
                            textColor={'rsPrimary.600'}
                            px={'4px'}
                            flex={'1 0 6%'}
                            textAlign={'center'}
                        >
                            詳細資訊
                        </Box>
                        <Box
                            textColor={'rsPrimary.600'}
                            px={'4px'}
                            flex={'1 0 4%'}
                            textAlign={'center'}
                        >
                            退掛
                        </Box>
                    </>
                ) : undefined}
            </Flex>
        </>
    );
};
