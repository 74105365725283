import React from 'react';
import { Mtable, MedicalRecord } from './index';
import { useRightSideOptionSelectStatus, RightSideOptionSelectStatus } from 'contexts/TreatmentManagement/Prescription';

export const RightSideOptionSelector: React.FC = () => {
    const { rightSideOptionSelectStatus } = useRightSideOptionSelectStatus();

    return (
        <>
            {(() => {
                switch (rightSideOptionSelectStatus) {
                    case RightSideOptionSelectStatus.Mtable:
                        return <Mtable />;
                    case RightSideOptionSelectStatus.MedicalRecord:
                        return <MedicalRecord />;
                    default:
                        return <MedicalRecord />;
                }
            })()}
        </>
    );
};

