import { create } from 'zustand';

// 定義 MedicalRecordDrugModelProps 接口
export interface MedicalRecordDrugModelProps {
    //IDrugBase
    Drug_Code: string;            // 藥品代碼
    Drug_ShortCode?: string;      // 藥品簡碼
    Drug_Name?: string;            // 藥品名稱
    Drug_ScientificName?: string; // 英文名稱
    Drug_Type: string;            // 藥類
    Drug_TypeCode: string;        // 藥類代碼
    Nhi_Code?: string;            // 健保碼
    Nhi_Price?: number;           // 健保價
    Drug_Unit?: string;           // 藥品單位
    Drug_PackUnit?: string;       // 包裝單位
    Drug_PackSize?: number;       // 包裝量
    Drug_Factor?: string;         // 藥品成份
    Drug_Indications?: string;    // 適應症
    Drug_SideEffect?: string;     // 副作用
    Drug_Warning?: string;        // 警語
    Drug_Appearance?: string;     // 藥品外觀
    Drug_ContNo?: string;         // 管制藥:許可證字號
    Drug_ContLevel?: number;      // 管制藥:級別
    Drug_ContVen?: string;        // 管制藥:製造廠名稱
    //IFrequencyBase
    Freq_Code?: string;           // 頻率代碼
    Freq_Name?: string;           // 頻率名稱
    Nhi_Freq?: string;            // 頻率健保代碼
    Times?: number;               // 次數
    Freq_Days?: number;           // 天數
    //IMedicalRecordDrug
    Freq_No?: string;             // 用法
    Qty?: number;                 // 劑量
    QtyTotal?: number;            // 總量
    QtyGive?: number;             // 給藥量
    QtyPack?: number;             // (包裝)給藥量
    QtyOne?: number;              // 劑量(次量)
    Days?: number;                // 天數
    ClmM?: string;                // M欄
    AsOrder?: string;             // 醫囑
    Seq?: number;                 // 順序
    SiteCode?: string;            // 部位代碼
}

// 定義 MedicalRecordDrugModelStore 接口
interface MedicalRecordDrugModelStore {
    drugModels: MedicalRecordDrugModelProps[];
    updateDrugModel: (index: number, drugModel: MedicalRecordDrugModelProps) => void;
    addDrugModel: (drugModel: MedicalRecordDrugModelProps) => void;
    resetDrugModels: () => void;
}

// 初始化 drugModel 的值
export const initialDrugModel: MedicalRecordDrugModelProps = {
    //IDrugBase
    Drug_Code: '',
    Drug_ShortCode: '',
    Drug_Name: '',
    Drug_ScientificName: '',
    Drug_Type: '',
    Drug_TypeCode: '',
    Nhi_Code: '',
    Nhi_Price: 0,
    Drug_Unit: '',
    Drug_PackUnit: '',
    Drug_PackSize: 0,
    Drug_Factor: '',
    Drug_Indications: '',
    Drug_SideEffect: '',
    Drug_Warning: '',
    Drug_Appearance: '',
    Drug_ContNo: '',
    Drug_ContLevel: 0,
    Drug_ContVen: '',
    //IFrequencyBase
    Freq_Code: '',
    Freq_Name: '',
    Nhi_Freq: '',
    Times: 0,
    Freq_Days: 0,
    //IMedicalRecordDrug
    Freq_No: '',
    Qty: 0,
    QtyTotal: 0,
    QtyGive: 0,
    QtyPack: 0,
    QtyOne: 0,
    Days: 0,
    ClmM: '',
    AsOrder: '',
    Seq: 0,
    SiteCode: '',
};

// 創建 useMedicalRecordDrugModelStore
export const useMedicalRecordDrugModelStore = create<MedicalRecordDrugModelStore>((set) => ({
    drugModels: [],
    updateDrugModel: (index, drugModel) =>
        set((state) => {
            const newDrugModels = [...state.drugModels];
            newDrugModels[index] = drugModel;
            return { drugModels: newDrugModels };
        }),
    addDrugModel: (drugModel) =>
        set((state) => ({
            drugModels: [...state.drugModels, drugModel]
        })),
    resetDrugModels: () =>
        set(() => ({
            drugModels: []
        })),
}));

export default useMedicalRecordDrugModelStore;
