import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IsAddDrugFreqOpenContextValue {
    isAddDrugFreqOpenStatus: boolean;
    setIsAddDrugFreqOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsAddDrugFreqOpenContext = createContext<
    IsAddDrugFreqOpenContextValue | undefined
>(undefined);

interface IsAddDrugFreqOpenProviderProps {
    children: ReactNode;
}

export const IsAddDrugFreqOpenProvider: React.FC<
    IsAddDrugFreqOpenProviderProps
> = ({ children }) => {
    const [isAddDrugFreqOpenStatus, setIsAddDrugFreqOpenStatus] =
        useState(false);
    return (
        <IsAddDrugFreqOpenContext.Provider
            value={{
                isAddDrugFreqOpenStatus,
                setIsAddDrugFreqOpenStatus,
            }}
        >
            {children}
        </IsAddDrugFreqOpenContext.Provider>
    );
};

export const useIsAddDrugFreqOpenStatus = (): IsAddDrugFreqOpenContextValue => {
    const context = useContext(IsAddDrugFreqOpenContext);

    if (!context) {
        throw new Error(
            'useIsAddDrugFreqOpenStatus must be used within a isAddDrugFreqOpenStatusProvider'
        );
    }

    return context;
};
