import React from 'react';
import { Box, Text, Flex, Center, Input } from '@chakra-ui/react';

export const PatientInfo = () => {
    const handleChangeInput = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        console.log('textarea');
    };
    return (
        <>
            <Text textStyle={'semiTitle'} mb={'8px'}>
                轉診單
            </Text>
            <Flex
                columnGap={'16px'}
                rowGap={'8px'}
                flexWrap={'wrap'}
                justify={'space-between'}
            >
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(33.333% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        姓名
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(33.333% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        生日
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(33.333% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        身分證號
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(33.333% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        性別
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(33.333% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        聯絡人
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex
                    align={'center'}
                    gap={'8px'}
                    flexBasis={'calc(33.333% - 16px)'}
                >
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        電話
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
                <Flex align={'center'} gap={'8px'} flexBasis={'100%'}>
                    <Text fontWeight={'semibold'} w={'64px'} flexShrink={0}>
                        地址
                    </Text>
                    <Input
                        placeholder=""
                        defaultValue={''}
                        onChange={handleChangeInput}
                        bg={'#fff'}
                    ></Input>
                </Flex>
            </Flex>
        </>
    );
};
