import { promises } from 'dns';
import apiService from './apiService';



export type DeclareListsProps = {
    card_no: string | null;
    case_type: string;
    doctor_name: string;
    main_diagnosis: string;
    medical_record_number: string | null;
    medical_record_uid: string;
    membership: string | null;
    name: string | null;
    seq: number;
    subject_name: string | null;
    treated_at: string;
};

export type DeclareListsResProps = {
    result: string;
    lists: DeclareListsProps[];
};
export const callDeclareListsService = async (token: string): Promise<DeclareListsResProps> => {
    return apiService<string, any>({
        method: 'GET',
        path: '/api/declare/lists/2024-08',
        token,
    });
};
