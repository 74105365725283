/* Hooks */
import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Image } from '@chakra-ui/react';

/* Components */
import logoIcon from '../images/icon/RS_LOGO.svg';
import SidebarRegistrationPageFunction from './SidebarFunction/SidebarRegistrationPageFunction';
import SidebarTreatmentRoomFunction from './SidebarFunction/SidebarTreatmentRoomFunction';
import SidebarOPDPageFunction from './SidebarFunction/SidebarOPDPageFunction';
import SidebarDataSettingPageFunction from './SidebarFunction/SidebarDataSettingPageFunction';

/* Status */

import { useSidebarStatus } from '../contexts/SidebarStatusContext';
import { useNavigationStatus } from 'contexts/NavigationStatusContext';
import { useLocation } from 'react-router-dom';

interface NavigationItem {
    name: string;
    path?: string;
    key: string;
    items?: NavigationItem[];
}
const Sidebar = () => {
    const { navigationStatus, setNavigationStatus } = useNavigationStatus();
    const location = useLocation();

    const sidebarRef = useRef<HTMLDivElement>(null);
    const [sidebarName, setSidebarName] = useState('');
    const { sidebarStatus, setSidebarStatus } = useSidebarStatus();
    useEffect(() => {
        const currentPath = location.pathname;
        if (navigationStatus) {
            const findItemsByPath = (data: NavigationItem[], path: string) => {
                for (let item of data) {
                    if (item.items) {
                        const foundItem = item.items.find(
                            subItem => subItem.path === path
                        );
                        if (foundItem) {
                            setSidebarName(item.name);
                            return item.items;
                        }
                    }
                }
                return null;
            };
            const matchedItems = findItemsByPath(navigationStatus, currentPath);

          
            if (matchedItems) {
                const sidebarPropsItems = matchedItems.map(item => ({
                    ...item,
                    path: item.path || '',
                }));
                setSidebarStatus(sidebarPropsItems);
            }
        }
    }, [location]);

    return (
        <div ref={sidebarRef} className="">
            {sidebarName === '掛號管理' && <SidebarRegistrationPageFunction />}
            {sidebarName === '診療管理' && <SidebarTreatmentRoomFunction />}
            {/* {sidebarName === '診療管理' && <SidebarOPDPageFunction />} */}
            {sidebarName === '資料設定' && <SidebarDataSettingPageFunction />}
        </div>
    );
};

export default Sidebar;
