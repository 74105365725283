import { useState } from 'react';
import {
    Box,
    Text,
    Flex,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { useTrailBalance } from 'components/TreatmentManagement/PaymentComponents/useTrailBalance';

import { useFeeStore } from 'store/TreatmentManagement/Payment';
import { textEllipsisOverflow } from 'style/chakraStyles/textStyles';

export const HIPointList = () => {
    const feeState = useFeeStore(state => state.feeData);

    const [tabIndex, setTabIndex] = useState(0);

    const ListItem = {
        align: 'center',
        gap: '8px',
        pos: 'relative',
        p: '2px',
    };

    const TabListItem = (isActive: boolean) => ({
        ...ListItem,
        bg: isActive ? '#BFC6A2' : '',
        rounded: 'sm',
    });

    const IconSize = {
        w: '20px',
        h: '20px',
    };

    return (
        <>
            <Box>
                <Flex pos={'relative'} justify={'space-between'}>
                    <Box flexBasis={'calc(50% - 1px)'} px={'8px'}>
                        <Flex sx={ListItem}>
                            <Text
                                flexGrow={'1'}
                                fontWeight={'semibold'}
                                pl={'26px'}
                            >
                                診察費
                            </Text>
                            <Text
                                flexShrink={0}
                                sx={textEllipsisOverflow}
                                w={'54px'}
                            >
                                {feeState.mFeePH}
                            </Text>
                        </Flex>
                        <Flex
                            sx={TabListItem(tabIndex === 0)}
                            _hover={{ opacity: 1, cursor: 'pointer' }}
                            onClick={() => {
                                setTabIndex(0);
                            }}
                        >
                            <Box
                                sx={IconSize}
                                pos={'absolute'}
                                top={'4px'}
                                left={'4px'}
                            >
                                <Box
                                    textColor={'#646B48'}
                                    sx={IconSize}
                                    opacity={0.8}
                                >
                                    <HeroIcons name="openWindow"></HeroIcons>
                                </Box>
                            </Box>
                            <Text
                                flexGrow={'1'}
                                fontWeight={'semibold'}
                                pl={'26px'}
                            >
                                診療費
                            </Text>
                            <Text
                                flexShrink={0}
                                sx={textEllipsisOverflow}
                                w={'54px'}
                            >
                                {feeState.mDiagnosisFee}
                            </Text>
                        </Flex>
                        <Flex
                            sx={TabListItem(tabIndex === 1)}
                            _hover={{ opacity: 1, cursor: 'pointer' }}
                            onClick={() => {
                                setTabIndex(1);
                            }}
                        >
                            <Box
                                sx={IconSize}
                                pos={'absolute'}
                                top={'4px'}
                                left={'4px'}
                            >
                                <Box
                                    textColor={'#646B48'}
                                    sx={IconSize}
                                    opacity={0.8}
                                    _hover={{ opacity: 1, cursor: 'pointer' }}
                                    onClick={() => {
                                        setTabIndex(1);
                                    }}
                                >
                                    <HeroIcons name="openWindow"></HeroIcons>
                                </Box>
                            </Box>
                            <Text
                                flexGrow={'1'}
                                fontWeight={'semibold'}
                                pl={'26px'}
                            >
                                藥費
                            </Text>
                            <Text
                                flexShrink={0}
                                sx={textEllipsisOverflow}
                                w={'54px'}
                            >
                                {feeState.mDrugFee}
                            </Text>
                        </Flex>

                        <Flex sx={ListItem}>
                            <Text
                                flexGrow={'1'}
                                fontWeight={'semibold'}
                                pl={'26px'}
                            >
                                藥事服務費
                            </Text>
                            <Text
                                flexShrink={0}
                                sx={textEllipsisOverflow}
                                w={'54px'}
                            >
                                {feeState.mFeeS}
                            </Text>
                        </Flex>

                        <Flex
                            sx={TabListItem(tabIndex === 2)}
                            _hover={{ opacity: 1, cursor: 'pointer' }}
                            onClick={() => {
                                setTabIndex(2);
                            }}
                        >
                            <Box
                                sx={IconSize}
                                pos={'absolute'}
                                top={'4px'}
                                left={'4px'}
                            >
                                <Box
                                    textColor={'#646B48'}
                                    sx={IconSize}
                                    opacity={0.8}
                                    _hover={{ opacity: 1, cursor: 'pointer' }}
                                    onClick={() => {
                                        setTabIndex(2);
                                    }}
                                >
                                    <HeroIcons name="openWindow"></HeroIcons>
                                </Box>
                            </Box>
                            <Text
                                flexGrow={'1'}
                                fontWeight={'semibold'}
                                pl={'26px'}
                            >
                                檢查檢驗費
                            </Text>
                            <Text
                                flexShrink={0}
                                sx={textEllipsisOverflow}
                                w={'54px'}
                            >
                                {feeState.mEQFee}
                            </Text>
                        </Flex>

                        <Flex sx={ListItem}>
                            <Text
                                flexGrow={'1'}
                                fontWeight={'semibold'}
                                pl={'26px'}
                            >
                                材料費
                            </Text>
                            <Text
                                flexShrink={0}
                                sx={textEllipsisOverflow}
                                w={'54px'}
                            >
                                {feeState.mFeeM}
                            </Text>
                        </Flex>
                    </Box>
                    <Box
                        pos={'absolute'}
                        top={0}
                        left={'50%'}
                        bg={'#646B48'}
                        w={'1px'}
                        h={'100%'}
                    ></Box>
                    <Box flexBasis={'calc(50% - 1px)'} px={'8px'}>
                        <Tabs
                            index={tabIndex}
                            onChange={index => setTabIndex(index)}
                        >
                            <TabList hidden>
                                <Tab>診療費</Tab>
                                <Tab>藥費</Tab>
                                <Tab>檢查檢驗費</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel p={0}>
                                    <Flex direction={'column'} w={'100%'}>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>
                                                注射技術費
                                            </Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeT}
                                            </Text>
                                        </Flex>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>復健費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeR}
                                            </Text>
                                        </Flex>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>處置費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeF}
                                            </Text>
                                        </Flex>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>手術費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeP}
                                            </Text>
                                        </Flex>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>麻醉費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeA}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </TabPanel>
                                <TabPanel p={0}>
                                    <Flex direction={'column'} w={'100%'}>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>口服藥費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeO}
                                            </Text>
                                        </Flex>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>外用藥費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeL}
                                            </Text>
                                        </Flex>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>藥水費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeW}
                                            </Text>
                                        </Flex>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>注射藥費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeI}
                                            </Text>
                                        </Flex>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>胰島素費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeG}
                                            </Text>
                                        </Flex>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>中藥費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFee7 +
                                                    feeState.mFee8}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </TabPanel>
                                <TabPanel p={0}>
                                    <Flex direction={'column'} w={'100%'}>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>檢查費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeE}
                                            </Text>
                                        </Flex>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>檢驗費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeQ}
                                            </Text>
                                        </Flex>
                                        <Flex sx={ListItem}>
                                            <Text flexGrow={'1'}>Ｘ光費</Text>
                                            <Text
                                                flexShrink={0}
                                                sx={textEllipsisOverflow}
                                                w={'54px'}
                                            >
                                                {feeState.mFeeX}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Flex>

                <Flex
                    align={'center'}
                    justify={'space-between'}
                    bg={'#646B48'}
                    textColor={'#fff'}
                    p={'8px 16px'}
                    rounded={'md'}
                    boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset'}
                >
                    <Text fontWeight={'semibold'}>申報合計</Text>
                    <Text fontWeight={'semibold'}>
                        {`${feeState.mAllFee}`} 點
                    </Text>
                </Flex>

                <Flex pos={'relative'} justify={'space-between'}>
                    <Box flexBasis={'calc(50% - 1px)'} px={'8px'}>
                        <Flex sx={ListItem}>
                            <Text
                                flexGrow={'1'}
                                fontWeight={'semibold'}
                                pl={'26px'}
                            >
                                交付藥費
                            </Text>
                            <Text flexShrink={0}>
                                {feeState.DeliverDrugFee}
                            </Text>
                        </Flex>
                        <Flex sx={ListItem}>
                            <Text
                                flexGrow={'1'}
                                fontWeight={'semibold'}
                                pl={'26px'}
                            >
                                交付復健
                            </Text>
                            <Text flexShrink={0}>{feeState.DeliverRFee}</Text>
                        </Flex>
                    </Box>
                    <Box
                        pos={'absolute'}
                        top={0}
                        left={'50%'}
                        bg={'#646B48'}
                        w={'1px'}
                        h={'100%'}
                    ></Box>
                    <Box flexBasis={'calc(50% - 1px)'} px={'8px'}>
                        <Flex sx={ListItem}>
                            <Text
                                flexGrow={'1'}
                                fontWeight={'semibold'}
                                pl={'26px'}
                            >
                                交付檢查(驗)
                            </Text>
                            <Text flexShrink={0}>{feeState.DeliverEQFee}</Text>
                        </Flex>
                        <Flex sx={ListItem}>
                            <Text
                                flexGrow={'1'}
                                fontWeight={'semibold'}
                                pl={'26px'}
                            >
                                交付材料
                            </Text>
                            <Text flexShrink={0}>{feeState.DeliverMFee}</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        </>
    );
};
