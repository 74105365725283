import { Center } from '@chakra-ui/react';

const NavReportIcon = ({ onClick }: any) => {
    return (
        <Center>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
            >
                <path
                    d="M11.0742 3.05499C9.38968 3.24325 7.79255 3.90331 6.46663 4.95923C5.14071 6.01514 4.13988 7.42399 3.57933 9.02361C3.01878 10.6232 2.9213 12.3486 3.2981 14.0012C3.6749 15.6538 4.51066 17.1664 5.70921 18.365C6.90776 19.5635 8.42037 20.3993 10.073 20.7761C11.7255 21.1529 13.4509 21.0554 15.0506 20.4949C16.6502 19.9343 18.059 18.9335 19.115 17.6076C20.1709 16.2816 20.8309 14.6845 21.0192 13H11.0742V3.05499Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.5622 8.99999H15.0742V3.51199C16.3396 3.96092 17.4889 4.68653 18.4383 5.63593C19.3877 6.58534 20.1133 7.73461 20.5622 8.99999Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Center>
    );
};

export default NavReportIcon;
