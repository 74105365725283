import axios from 'axios';
import axiosInstance from './axiosInstance';
import apiService from './apiService'

//取得院所看診時段
export interface clinicRoomProps {
    name: string;
    id: string;
}
export const callClinicRoomService = async (token: string) => {
    return apiService<undefined, any>({
        method: 'GET',
        path: '/api/clinic_room',
        token,
    });
};


interface updatedDataProps {
    data_id?: string | null;
    name: string;
    seq: string;
}
export const postClinicRoomService = async (token: string, updatedData: updatedDataProps) => {
    return apiService<updatedDataProps, any>({
        method: 'POST',
        path: '/api/clinic_room',
        token,
        data: updatedData,
    });
};


// 增修看診時段
// export const postShiftService = async (
//     token: string,
//     updatedData: updatedDataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);

//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axiosInstance.post('/api/shift', requestBody, {
//             headers,
//         });

//         const result = response.data;

//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };


interface sortListsProps {
    ids: string[];
}
export const postClinicRoomSortService = async (token: string, updatedData: sortListsProps) => {
    return apiService<sortListsProps, any>({
        method: 'POST',
        path: '/api/clinic_room/sort',
        token,
        data: updatedData,
    });
};


// 院所看診時間排序
// export const postClinicRoomSortService = async (
//     token: string,
//     sortLists: sortListsProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(sortLists);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         console.log(requestBody, 'requestBody service印的');

//         const response = await axios.post(
//             '/api/clinic_room/sort',
//             requestBody,
//             {
//                 headers,
//             }
//         );

//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };





export const deleteClinicRoomService = async (token: string, dataId: string) => {
    // 使用apiService调用DELETE方法
    return apiService<undefined, any>({
        method: 'DELETE',
        path: `/api/clinic_room?id=${dataId}`, 
        token,
    });
};

// 刪除看診時段
// export const deleteShiftService = async (token: string, dataId: string) => {
//     try {
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         const url = `/api/shift?id=${dataId}`;

//         const response = await axiosInstance.delete(url, {
//             headers,
//         });

//         const result = response.data;

//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
