import { create } from 'zustand';

export interface ICCardUpdateListItem {
    signature: string;
    medical_record_uid: string;
    medical_record_number: string;
    treated_at: string;
    name: string;
    id_no: string;
    birth_date: string;
    mt_type: string;
    card_no: string | null;
    upload_status: number;
    opd_uid: string;
}

interface ICCardUpdateListState {
    icCardUpdateList: ICCardUpdateListItem[];
    updateICCardUpdateList: (list: ICCardUpdateListItem[]) => void;
    addICCardUpdateItem: (item: ICCardUpdateListItem) => void;
    removeICCardUpdateItem: (medical_record_uid: string) => void;
    clearICCardUpdateList: () => void;
}

export const useICCardUpdateListStore = create<ICCardUpdateListState>(set => ({
    icCardUpdateList: [],
    updateICCardUpdateList: list => set({ icCardUpdateList: list }),
    addICCardUpdateItem: item =>
        set(state => ({
            icCardUpdateList: [...state.icCardUpdateList, item],
        })),
    removeICCardUpdateItem: medical_record_uid =>
        set(state => ({
            icCardUpdateList: state.icCardUpdateList.filter(
                item => item.medical_record_uid !== medical_record_uid
            ),
        })),
    clearICCardUpdateList: () => set({ icCardUpdateList: [] }),
}));
