import { useState, useEffect, useRef } from 'react';
import { Box, Flex, Text, Input, Button, Center } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';

interface SetXValueProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
    inputValue: string;
    setInputValue: (value: string) => void;
    directions: string;
    inputLimits: { minimum?: number; maximum?: number };
}

export const SetXValue: React.FC<SetXValueProps> = ({
    isOpen,
    onClose,
    onSubmit,
    inputValue,
    setInputValue,
    directions,
    inputLimits,
}) => {
    const handleBackspace = () => {
        if (inputValue.length > 0) {
            setInputValue(inputValue.slice(0, -1));
        }
    };
    return (
        <>
            <Box
                pos={'fixed'}
                top={0}
                left={0}
                bg={'#00000099'}
                w={'100%'}
                h={'100%'}
                zIndex={60}
                p={'16px'}
                hidden={!isOpen}
            >
                <Box
                    pos={'absolute'}
                    bg={'#fff'}
                    top={'50%'}
                    left={'50%'}
                    transform={'translate(-50%,-50%)'}
                >
                    <Flex
                        justify={'space-between'}
                        p={'8px 16px'}
                        bg={'rsPrimary.800'}
                    >
                        <Text></Text>
                        <Box
                            textColor={'#fff'}
                            onClick={onClose}
                            _hover={{ cursor: 'pointer' }}
                        >
                            <HeroIcons name="xmark-sm"></HeroIcons>
                        </Box>
                    </Flex>

                    <Box p={'16px'}>
                        <Text py={'8px'} textStyle={'semiTitle'}>
                            {directions.replace('x', ' x ')}
                        </Text>
                        <Flex gap={'8px'} align={'center'} my={'4px'}>
                            <Text flex={'0 0 auto'}>X : </Text>
                            <Input
                                placeholder={`ex: ${inputLimits.minimum} ~ ${inputLimits.maximum}`}
                                autoFocus
                                value={inputValue}
                                onChange={e => setInputValue(e.target.value)}
                                min={inputLimits.minimum}
                                max={inputLimits.maximum}
                                type="number"
                            />
                            <Button
                                colorScheme="rsSteel"
                                onClick={handleBackspace}
                            >
                                <HeroIcons name="arrow-left"></HeroIcons>
                            </Button>
                        </Flex>

                        <Center
                            w={'calc(80px * 3 + 5px * 2 + 16px * 2)'}
                            gap={'5px'}
                            flexWrap={'wrap'}
                            p={'16px'}
                            mx={'auto'}
                        >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(index => (
                                <Button
                                    flex={'1 0 80px'}
                                    h={'80px'}
                                    colorScheme="rsPrimary"
                                    textColor={'#fff'}
                                    key={index}
                                    fontSize={'2xl'}
                                    onClick={() =>
                                        setInputValue(
                                            inputValue + index.toString()
                                        )
                                    }
                                >
                                    {index}
                                </Button>
                            ))}
                        </Center>

                        <Flex justify={'flex-end'} gap={'8px'}>
                            <Button
                                colorScheme="rsSecondary"
                                onClick={onSubmit}
                            >
                                帶入
                            </Button>
                            <Button colorScheme="rsSteel" onClick={onClose}>
                                取消
                            </Button>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
