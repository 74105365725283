import React, { useState, useEffect } from 'react';
import { InUseList } from 'components/DataSettings/DrugFrequencyComponents/InUseList';
import { AddDrugFreq } from 'components/DataSettings/DrugFrequencyComponents/AddDrugFreq';
import { SetXValue } from 'components/DataSettings/DrugFrequencyComponents/AddDrugFreq/SetXValue';

import { IsAddDrugFreqOpenProvider } from 'contexts/DataSetting/DrugFrequency/';

const DrugFrequenceSettingPage = () => {
    return (
        <>
            <IsAddDrugFreqOpenProvider>
                <InUseList />
                <AddDrugFreq />
            </IsAddDrugFreqOpenProvider>
        </>
    );
};

export default DrugFrequenceSettingPage;
