// SummaryFinalData.ts
import moment from 'moment';
import { finalDataProps } from 'service/medicalRecordService';
import { TreatmentInfoProps } from 'store/TreatmentManagement/useTreatmentInfoStore';
import { accItemsLevelProps } from 'service/accItemsLevelService';
import {
    CCPEProps,
    DxProps,
    PrescriptionProps,
} from 'store/TreatmentManagement';
import { DrugFreqListsProps } from 'store/DataSetting/useDrugFreqListStore';
import { cashDataStoreProps } from 'store/TreatmentManagement/Payment';
import { FeeState } from 'store/TreatmentManagement/Payment';
import { finalFeeDataLogic } from './finalFeeDataLogic';
import { CashierProps } from 'service/medicalRecordService';
import { getDoseDay } from 'functions/getDoseDay';
import { drugTypeListProps } from 'service/drugTypeListService';

export const summaryData = (
    treatmentInfo: TreatmentInfoProps,
    CCPEData: CCPEProps,
    dxList: DxProps[],
    prescriptionList: PrescriptionProps[],
    cashDataStore: cashDataStoreProps,
    accItemsLevelList: accItemsLevelProps[],
    feeData: FeeState,
    if_formal_data: string,
    drugTypeList: drugTypeListProps[],
    drugFreqLists: DrugFreqListsProps[]
) => {
    const maxDoseDays = getDoseDay(
        prescriptionList,
        drugTypeList,
        drugFreqLists
    );
    const filteredDxList = dxList.filter(item => item.data_id);
    const filteredPrescriptionList =
        prescriptionList && prescriptionList.length > 0
            ? prescriptionList.filter(item => item.data_id)
            : [];

    const finalFeeData: CashierProps[] = finalFeeDataLogic(
        accItemsLevelList,
        treatmentInfo,
        feeData,
        cashDataStore
    );
    let finalData: finalDataProps = {
        // 有data_id 代表是修改
        ...(treatmentInfo.data_id ? { data_id: treatmentInfo.data_id } : {}),
        treated_at: treatmentInfo.treated_at ? moment(treatmentInfo.treated_at).format('YYYY-MM-DD HH:mm:ss') : moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        card_no: treatmentInfo.medical_record?.mb1?.card_no ?? treatmentInfo.card_no ?? '',
        opd_uid: treatmentInfo.id,
        if_formal_data: if_formal_data,
        shift_uid: treatmentInfo.shift_uid,
        clinic_room_uid: treatmentInfo.clinic_room_uid,
        patient_uid: treatmentInfo.patient_uid,
        medical_subject: treatmentInfo.subject_code,
        cc: CCPEData.cc ?? '',
        diagnosis: filteredDxList.map(item => ({ data_id: item.data_id })),
        doctor_uid: treatmentInfo.doctor_uid,
        declare_doctor_uid: treatmentInfo.declare_doctor_uid,
        case_type: treatmentInfo.case_type ?? '',
        pharmacist_uid: treatmentInfo.pharmacist_uid,
        drugs:
            filteredPrescriptionList && filteredPrescriptionList.length > 0
                ? filteredPrescriptionList.map(item => ({
                      drug_in_use_uid: item.data_id,
                      qty: Number(item.qty),
                      qty_in_day: Number(item.qty_in_day),
                      freq: item.freq,
                      site: item.site,
                      route: item.route_of_administration,
                      dose_days: Number(item.dose_day),
                      total_qty: Number(item.total_qty),
                      given_qty: Number(item.given_qty),
                      add_days: 0,
                      add_given_qty: 0,
                      memo: item.memo,
                      status: 0,
                      doctor_order: '',
                      icd_code: '',
                      started_at: '',
                      ended_at: '',
                      op_idno: treatmentInfo.doctor_id_no,
                      special_mark: item.special_mark
                          ? item.special_mark.split('')
                          : [],
                  }))
                : [],
        ...(treatmentInfo.prescription_style !== -1
            ? { prescription_style: treatmentInfo.prescription_style }
            : {}),
        ori_mt_type: treatmentInfo.mt_type, // 從 opd/base_info API 存的原就醫類別
        mt_type: treatmentInfo.selected_mt_type
            ? treatmentInfo.selected_mt_type
            : treatmentInfo.mt_type, // 診療室批價時更改的就醫類別
        copayment: treatmentInfo.copayment,
        referral: treatmentInfo.referral,
        benefit_type: treatmentInfo.benefit_type,
        special_treatment: treatmentInfo.special_treatment,
        dose_days: maxDoseDays,
        // 費用
        cashier: finalFeeData,
    };

    return finalData;
};
