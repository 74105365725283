import React from 'react';
import { Tbody, Tr, Td, Center, Box } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
export const AppointmentListBody = () => {
    return (
        <>
            <Tbody>
                <Tr>
                    <Td fontSize={'16px'}>
                        <Center
                            textColor={'rsPrimary.500'}
                            _hover={{ cursor: 'pointer', opacity: 1 }}
                            opacity={0.8}
                        >
                            <HeroIcons name="delete" />
                        </Center>
                    </Td>
                    <Td fontSize={'16px'}>
                        <Center
                            textColor={'rsPrimary.500'}
                            _hover={{ cursor: 'pointer', opacity: 1 }}
                            opacity={0.8}
                        >
                            <HeroIcons name="read-record" />
                        </Center>
                    </Td>
                    {/* <Td fontSize={'16px'}>113.07.27(六)</Td>
                    <Td fontSize={'16px'}>早診</Td>
                    <Td fontSize={'16px'}>1</Td>
                    <Td fontSize={'16px'}>李阿信</Td>
                    <Td fontSize={'16px'}>二診</Td>
                    <Td fontSize={'16px'}>陳美如</Td>
                    <Td fontSize={'16px'}>000010</Td>
                    <Td fontSize={'16px'}>88.02.23</Td> */}
                    <Td fontSize={'16px'}></Td>
                    <Td fontSize={'16px'}></Td>
                    <Td fontSize={'16px'}></Td>
                    <Td fontSize={'16px'}></Td>
                    <Td fontSize={'16px'}></Td>
                    <Td fontSize={'16px'}></Td>
                    <Td fontSize={'16px'}></Td>
                    <Td fontSize={'16px'}></Td>

                    <Td fontSize={'16px'}>
                        <Center textColor={'rsPrimary.500'}>
                            <HeroIcons name="notice" />
                        </Center>
                    </Td>
                    <Td fontSize={'16px'}>
                        {' '}
                        <Center textColor={'rsPrimary.500'}>
                            <HeroIcons name="notice" />
                        </Center>
                    </Td>
                    <Td fontSize={'16px'}>
                        {' '}
                        <Center textColor={'rsPrimary.500'}>
                            <HeroIcons name="notice" />
                        </Center>
                    </Td>

                    {/* <Td fontSize={'16px'}>0912345678</Td> */}
                    <Td fontSize={'16px'}></Td>
                </Tr>
            </Tbody>
        </>
    );
};
