import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IsChronicContextValue {
    isChronicStatus: boolean;
    setIsChronicStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsChronicContext = createContext<IsChronicContextValue | undefined>(
    undefined
);

interface IsChronicProviderProps {
    children: ReactNode;
}

export const IsChronicProvider: React.FC<IsChronicProviderProps> = ({
    children,
}) => {
    const [isChronicStatus, setIsChronicStatus] = useState(false);
    return (
        <IsChronicContext.Provider
            value={{
                isChronicStatus,
                setIsChronicStatus,
            }}
        >
            {children}
        </IsChronicContext.Provider>
    );
};

export const useIsChronicStatus = (): IsChronicContextValue => {
    const context = useContext(IsChronicContext);

    if (!context) {
        throw new Error(
            'useIsChronicStatus must be used within a isChronicStatusProvider'
        );
    }

    return context;
};
