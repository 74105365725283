import React from 'react';
import { Text, Flex, Tooltip, Box } from '@chakra-ui/react';
import { useIsHistoryLoadingStatus } from 'contexts/TreatmentManagement/MedicalRecord/IsHistoryLoadingContext';
import Loading from 'components/CustomComponents/Loading';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { DrugProps } from 'store/TreatmentManagement/useHistoryStore';

// type DrugProps = {
//     qty: string;
//     qty_in_day: string;
//     dose_days: number;
//     total_qty: string;
//     special_mark: string | string[];
//     drugs_in_use_uid: string;
//     drug_no: string;
//     name_tw: string;
// };

type PrescriptionDrugsProps = {
    drugs: DrugProps[];
};

export const PrescriptionCopy: React.FC<PrescriptionDrugsProps> = ({
    drugs,
}) => {
    const { isHistoryLoadingStatus } = useIsHistoryLoadingStatus();

    return (
        <>
            <Text textColor={'#646B48'} fontWeight={'semibold'}>
                Rx.處方名稱
            </Text>
            <Box position="relative" minHeight="200px">
                {' '}
                {/* 设定一个最小高度以维持布局 */}
                {isHistoryLoadingStatus && (
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bg="rgba(255, 255, 255, 0.7)"
                        zIndex={10}
                    >
                        <Loading />
                    </Box>
                )}
                <Flex
                    gap={'4px'}
                    borderBottom={'1px solid #111'}
                    mb={'4px'}
                    textColor={'#646B48'}
                    fontWeight={'semibold'}
                >
                    <Text textAlign={'center'} flex={'1 1 280px'}>
                        Rx.處方名稱
                    </Text>
                    <Text textAlign={'center'} flex={'0 0 50px'}>
                        日量
                    </Text>
                    <Text textAlign={'center'} flex={'0 0 50px'}>
                        天數
                    </Text>
                    <Text textAlign={'center'} flex={'0 0 50px'}>
                        用法
                    </Text>
                    <Text textAlign={'center'} flex={'0 0 50px'}>
                        總量
                    </Text>
                    <Text textAlign={'center'} flex={'0 0 50px'}>
                        申報
                    </Text>
                </Flex>
                {drugs ? (
                    drugs.map((item, index) => (
                        <Flex
                            key={index}
                            gap={'4px'}
                            borderBottom={'1px solid #eee'}
                            mb={'4px'}
                            textColor={'#646B48'}
                            fontWeight={'semibold'}
                        >
                            <Text flex={'1 1 280px'}>{item.name_tw}</Text>
                            <Text textAlign={'center'} flex={'0 0 50px'}>
                                {item.qty_in_day}
                            </Text>
                            <Text textAlign={'center'} flex={'0 0 50px'}>
                                {item.dose_days}
                            </Text>
                            <Text textAlign={'center'} flex={'0 0 50px'}>
                                {item.freq_custom_code}
                            </Text>
                            <Text textAlign={'center'} flex={'0 0 50px'}>
                                {item.given_qty}
                            </Text>
                            <Box textAlign={'center'} flex={'0 0 50px'}></Box>
                        </Flex>
                    ))
                ) : (
                    <Flex
                        gap={'4px'}
                        borderBottom={'1px solid #eee'}
                        mb={'4px'}
                        textColor={'#646B48'}
                        fontWeight={'semibold'}
                    >
                        <Text>查無資訊</Text>
                    </Flex>
                )}
            </Box>
        </>
    );
};
