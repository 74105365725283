// 處方模型（MedicalRecordDrugModel）

import { PrescriptionProps } from 'store/TreatmentManagement';
import { MedicalRecordDrugModelProps } from 'store/TreatmentManagement/Print/useMedicalRecordDrugModelStore';
import { drugTypeListProps } from 'components/TreatmentManagement/ConsultingRoomComponents/BillingProcess/CompleteOptions';
import { DrugFreqListsProps } from 'store/DataSetting/useDrugFreqListStore';

export const medicalRecordDrugLogic = (
    prescriptionList: PrescriptionProps[],
    drugTypeList: drugTypeListProps[],
    drugFreqLists: DrugFreqListsProps[]
): MedicalRecordDrugModelProps[] => {
    let finalData: MedicalRecordDrugModelProps[] = prescriptionList.map(
        (prescription, index) => ({
            // IDrugBase
            Drug_Code: prescription.short_code, // 藥品代碼
            Drug_ShortCode: '', // 藥品簡碼
            Drug_Name: prescription.name_tw || '',
            Drug_ScientificName: prescription.name_en || '',
            Drug_Type:
                drugTypeList.find(
                    item => item.data_id === prescription.drug_type
                )?.name || '', // 藥類的中文名稱
            Drug_TypeCode:
                drugTypeList.find(
                    item => item.data_id === prescription.drug_type
                )?.code || '', // 藥類代碼 O W L 等等
            Nhi_Code: prescription.drug_no || '',
            Nhi_Price: prescription.drug_price || 0,
            Drug_Unit: prescription.unit || '',
            Drug_PackUnit: '',
            Drug_PackSize: 0,
            Drug_Factor: '',
            Drug_Indications: '',
            Drug_SideEffect: '',
            Drug_Warning: '',
            Drug_Appearance: '',
            Drug_ContNo: '', // 管制藥:許可證字號
            Drug_ContLevel: prescription.controlled_level
                ? Number(prescription.controlled_level)
                : 0, // 管制藥:級別 確保轉換為 number
            Drug_ContVen: '', // 管制藥:製造商名稱

            // IFrequencyBase
            Freq_Code: prescription.freq_custom_code || '', // 頻率代碼
            Freq_Name: prescription.freq_directions, // 頻率名稱 ？？
            Nhi_Freq:
                drugFreqLists.find(item => item.data_id === prescription.freq)
                    ?.freq_code || '', // 頻率健保代碼
            Times: Number(prescription.qty) || 0, // 次數 qty? qty_in_day?
            Freq_Days: Number(prescription.dose_day) || 0,

            // IMedicalRecordDrug
            Freq_No: prescription.freq_custom_code || '', // 用法
            Qty: prescription.qty ? Number(prescription.qty) : 0, // 劑量
            QtyTotal: prescription.total_qty
                ? Number(prescription.total_qty)
                : 0, // 總量
            QtyGive: prescription.given_qty
                ? Number(prescription.given_qty)
                : 0, // 給藥量
            QtyPack: 0, // 默認為 0  // 包裝給藥量
            QtyOne: prescription.qty_in_day
                ? Number(prescription.qty_in_day)
                : 0, // 次量
            Days: prescription.dose_day ? Number(prescription.dose_day) : 0, // 天數
            ClmM: prescription.special_mark || '', // M欄
            AsOrder: '', // 醫囑
            Seq: index + 1, // 順序
            SiteCode: prescription.site || '', // 部位代碼
        })
    );

    return finalData;
};
