import apiService from './apiService';


export type DiagnosisProps = {
    icd_code: string;
    short_code: string;
    ds: string | null;
    icd_10_in_use_uid: string;
    name_tw: string;
    name_en: string;
};

export type DrugProps = {
    controlled_level: string;
    cost: string;
    data_id: string;
    disabled_date: string;
    dosage_form: string;
    dose: string;
    dose_days?: string;
    dosing_days: string;
    drug_no: string;
    drug_price: number | null;
    drug_type: string;
    freq: string;
    max_dose: string;
    max_dose_day: string;
    name_en: string;
    name_tw: string;
    route_of_administration: string;
    self_price: string;
    short_code: string;
    taking_time: string;
    unit: string;
    qty?: string;
    qty_in_day?: string;
    total_qty?: string;
    given_qty?: string;
    memo?: string;
    site?: string;
    special_mark?: string;
    freq_custom_code?: string;
    freq_directions?: string;
    drugs_in_use_uid?: string;
    ing_qty_unit?: string;
    freq_code?: string;
    drug_type_code?: string;
    if_declare?: string;
};
export type mb1Props = {
    signature: string; // 安全簽章
    treatment_no: string;  // 就醫識別碼
    treated_at: string; // 就診時間
    card_number: string; // 卡片號碼
    institution_type: string; // 機構類別
    sam_code: string; // SAM卡代碼
    card_no: string; // 卡片序號

}
export type MedicalRecordProps = {
    info: {
        data_id: string;
        cc: string | null;
        treated_at: string;
        case_type: string;
        medical_subject:string;
    };
    diagnosis: DiagnosisProps[];
    drugs: DrugProps[];
    mb1: mb1Props
};

export interface OpdBaseInfoResponseProps {
    result: string;
    msg: string;
    info: {
        data_id: string;
        id: string;
        opd_at: string;
        doctor_uid: string;
        card_no: string;
        patient_uid: string;
        shift_uid: string;
        clinic_room_uid: string;
        member_uid: string;
        mt_type: string;
        shift_name: string;
        clinic_room_name: string;
        doctor_name: string;
        doctor_id_no: string;
        patient_name: string;
        medical_record_number: string;
        birth_date: string;
        drug_allergy_describe: string | null;
        seek_history: string | null;
        id_no: string;
        sex: string;
        nhi_type_name: string;
        subject_code: string;
        subject_name: string;
    };
    vital_sign: [
        {
            data_id: string;
            value: string;
            vital_sign_uid: string;
            name: string;
        },
    ];
    medical_record?: MedicalRecordProps; // 醫療紀錄
}

// 取得病患就診基本資料
export const callOpdBaseInfoService = async (
    token: string,
    data: string
): Promise<OpdBaseInfoResponseProps> => {
    const queryParams = data ? { data } : undefined;
    return apiService<any, any>({
        method: 'GET',
        path: '/api/opd/base_info',
        token,
        data: queryParams,
    });
};
