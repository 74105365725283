import apiService from './apiService'

export interface vitalSignSetValueProps {
    data_id?: string;
    opd_uid: string;
    vital_sign_uid: string;
    value?: string | null 
}

// 寫入病患特定基礎體徵資料
export const postVitalSignSetValueService = async (token: string, data: vitalSignSetValueProps) => {
    return apiService<vitalSignSetValueProps, any>({
        method: 'POST',
        path: '/api/vital_sign/set_value',
        token,
        data: data
    });
};


