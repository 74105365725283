import React, { useEffect, useState } from 'react';

import {
    Button,
    Box,
    Flex,
    Text,
    Heading,
    Input,
    Tooltip,
    Image,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { useTreatmentInfoStore } from 'store/TreatmentManagement';
import { toROCDateString } from 'functions/toROCDateString';
import { ADDateToROCString } from 'functions/ADDateToROCString';
import { calculateAge } from 'functions/calculateAge';
import { useNavigate } from 'react-router-dom';
import { useIsBillingOpenStatus } from 'contexts/TreatmentManagement/BillingProcess';
import Clock from 'components/CustomComponents/Clock';
const RoomInformation: React.FC = () => {
    // 雲端藥歷及管治藥查詢
    const { treatmentInfo, updateTreatmentInfo } = useTreatmentInfoStore();
    const navigate = useNavigate();
    const { isBillingOpenStatus } = useIsBillingOpenStatus();

    if (treatmentInfo && treatmentInfo.id === '') {
        navigate('/OPDPage');
    }

    useEffect(() => {
        const age = treatmentInfo.birth_date
            ? calculateAge(treatmentInfo.birth_date)
            : '';
        console.log(age);
    }, [treatmentInfo]);

    const [aiOpen, setAiOpen] = useState<boolean>(false);
    const handleOpenAi = () => {
        setAiOpen(prev => !prev);
    };

    const [doctorAIWindow, setDoctorAIdWindow] = useState<Window | null>(null);

    const handleOpenDoctorAIWindow = () => {
        const url = 'https://ccpe.draiai.com/sttgcsv2.html';
        const windowFeatures = `width=980,height=${window.innerHeight},top=0,right=0`;

        if (doctorAIWindow && !doctorAIWindow.closed) {
            doctorAIWindow.focus();
        } else {
            const newWindow = window.open(url, '_blank', windowFeatures);
            if (newWindow) {
                setDoctorAIdWindow(newWindow);
                newWindow.focus();
            }
        }
    };

    useEffect(() => {
        if (!isBillingOpenStatus) {
            setAiOpen(false);
        }
    }, [isBillingOpenStatus]);

    return (
        <>
            <Flex
                justify={'space-between'}
                align={'center'}
                gap={'8px'}
                w={'100%'}
            >
                <Flex align={'center'} gap={'16px'}>
                    <Text fontWeight={'semibold'} fontSize={'20px'}>
                        {treatmentInfo.medical_record_number}-0003
                    </Text>

                    <Flex align={'center'} fontWeight={'semibold'} gap={'16px'}>
                        <Box
                            bg={
                                treatmentInfo.sex === 'M'
                                    ? '#79A09F'
                                    : treatmentInfo.sex === 'F'
                                      ? '#D28989'
                                      : 'red.500'
                            }
                            h={'40px'}
                            px={'8px'}
                            rounded={'4px'}
                        >
                            <Text
                                fontSize={'30px'}
                                textColor={'#fff'}
                                lineHeight={'40px'}
                            >
                                {treatmentInfo.patient_name}
                            </Text>
                        </Box>

                        <Tooltip
                            label={
                                treatmentInfo.birth_date
                                    ? ADDateToROCString(
                                          treatmentInfo.birth_date
                                      )
                                    : ''
                            }
                            bg={'#45533F'}
                            hasArrow
                        >
                            <Text fontSize={'30px'} lineHeight={'40px'}>
                                {treatmentInfo.birth_date
                                    ? calculateAge(treatmentInfo.birth_date)
                                    : ''}
                            </Text>
                        </Tooltip>
                        <Flex align={'center'} gap={'8px'}>
                            <HeroIcons name="key-circle-green"></HeroIcons>
                            <Text fontSize={'20px'} fontWeight={'semibold'}>
                                {treatmentInfo.id_no}
                            </Text>
                        </Flex>
                        <Flex align={'center'} gap={'8px'}>
                            <HeroIcons name="id-card-circle-green"></HeroIcons>
                            <Text fontSize={'20px'} fontWeight={'semibold'}>
                                {treatmentInfo.nhi_type_name}
                            </Text>
                        </Flex>
                    </Flex>

                    <Text
                        border={'1px solid #45533F'}
                        p={'0px 16px'}
                        rounded={'md'}
                        lineHeight={'40px'}
                        fontSize={'20px'}
                        fontWeight={'semibold'}
                    >
                        {treatmentInfo.card_no}
                    </Text>
                    <Button
                        bg={'#45533F'}
                        textColor={'#fff'}
                        boxShadow={
                            ' 3px -3px 2px 0px rgba(0, 0, 0, 0.50) inset'
                        }
                        _hover={{
                            bg: '#2A3327',
                            boxShadow: '-2px 2px 2px #000 inset',
                        }}
                    >
                        IC 讀卡
                    </Button>
                </Flex>
                <Box hidden={true}>
                    <Flex
                        align={'center'}
                        gap={'8px'}
                        rounded="10px"
                        bg="#646B48"
                        boxShadow={' 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset'}
                        p={'6px 16px'}
                    >
                        <Text
                            textColor={'#fff'}
                            fontWeight={'semibold'}
                            flexShrink={0}
                        >
                            候診人數
                        </Text>
                        <Box
                            p={'2px 16px'}
                            bg={'#fff'}
                            textColor={'#000'}
                            fontWeight={'semibold'}
                            rounded={'4px'}
                            flexShrink={0}
                        >
                            23
                        </Box>
                    </Flex>
                </Box>
                <Flex align={'center'} gap={'8px'}>
                    <Box
                        textColor={'rsPrimary.500'}
                        rounded={'4px'}
                        p={'4px 16px'}
                        textAlign={'center'}
                    >
                        <Clock />
                    </Box>
                    <Box
                        onClick={handleOpenDoctorAIWindow}
                        h={'40px'}
                        aspectRatio={3 / 1}
                        bg={'blue.50'}
                        p={'4px 16px'}
                        rounded={'md'}
                        boxShadow={'md'}
                        opacity={0.8}
                        _hover={{ opacity: 1, cursor: 'pointer' }}
                    >
                        <Image src="https://ccpe.draiai.com/logo.svg"></Image>
                    </Box>
                </Flex>

                {!isBillingOpenStatus && aiOpen && (
                    <Box
                        position={'fixed'}
                        top={'98px'}
                        right={'0px'}
                        w={'calc(33.33vw + 4px)'}
                        h={'852px'}
                        bg={'#00000033'}
                        zIndex={100}
                        rounded={'lg'}
                    >
                        <Box
                            p={'8px'}
                            bg={'#fff3e6'}
                            rounded={'lg'}
                            boxShadow={'inset 4px 2px 2px rgba(0,0,0,0.25)'}
                        >
                            <Box w={'calc(33.33vw + 4px)'} h={'852px'}>
                                <iframe
                                    width={'100%'}
                                    height={'100%'}
                                    src="https://ccpe.draiai.com/sttgcsv2.html"
                                    allowFullScreen
                                ></iframe>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Flex>
        </>
    );
};

export default RoomInformation;
