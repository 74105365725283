import React from 'react';
import { Thead, Tr, Th, Box, Flex } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { useLocation } from 'react-router-dom';
import { useIsVisitedWaitingListFeeDetailOpenStatus } from 'contexts/IsVisitedWaitingListFeeDetailOpenContext';
import { title } from 'process';
export const VisitedListHeader = () => {
    let location = useLocation();
    const pathName = location.pathname;
    const pathSegments = pathName.split('/');
    const lastSegment = pathSegments.pop();
    const {
        isVisitedWaitingListFeeDetailOpenStatus,
        setIsVisitedWaitingListFeeDetailOpenStatus,
    } = useIsVisitedWaitingListFeeDetailOpenStatus();

    const handleFeeDetail = () => {
        setIsVisitedWaitingListFeeDetailOpenStatus(prev => !prev);
    };
    const headerElementStyle = {
        textColor: 'rsPrimary.600',
        p: '5px 9px',
        bg: 'gray.150',
        borderBottom: '1px solid #868C6E',
        whiteSpace: 'nowrap',
    };
    return (
        <Flex align={'center'}>
            <Box sx={headerElementStyle} flex={'0 0 5%'}>
                順序
            </Box>
            <Box sx={headerElementStyle} flex={'0 0 5%'}>
                時段
            </Box>
            <Box sx={headerElementStyle} flex={'0 0 5%'}>
                診間
            </Box>
            <Box sx={headerElementStyle} flex={'0 0 10%'}>
                醫師姓名
            </Box>
            <Box sx={headerElementStyle} flex={'0 0 10%'}>
                病患姓名
            </Box>
            <Box sx={headerElementStyle} flex={'0 0 5%'}>
                身份
            </Box>
            <Box sx={headerElementStyle} flex={'0 0 10%'}>
                生日
            </Box>
            <Box sx={headerElementStyle} flex={'0 0 10%'}>
                卡號
            </Box>
            <Box sx={headerElementStyle} flex={'0 0 15%'}>
                病歷號
            </Box>
            <Box sx={headerElementStyle} flex={'0 0 15%'}>
                完診時間
            </Box>

            {lastSegment === 'RegisteredPage' ? (
                <>
                    <Box
                        sx={{ ...headerElementStyle, textAlign: 'center' }}
                        flex={'1 0 6%'}
                        onClick={handleFeeDetail}
                        _hover={{ cursor: 'pointer' }}
                    >
                        <Flex align={'center'} justify={'center'}>
                            <Box>費用</Box>
                            <Box
                                w={'24px'}
                                sx={
                                    isVisitedWaitingListFeeDetailOpenStatus
                                        ? { transform: 'scaleX(-1)' }
                                        : { transform: 'scaleX(1)' }
                                }
                            >
                                <HeroIcons name="chevron-right" />
                            </Box>
                        </Flex>
                    </Box>
                    {isVisitedWaitingListFeeDetailOpenStatus && (
                        <>
                            <Box
                                sx={{
                                    ...headerElementStyle,
                                    textAlign: 'center',
                                }}
                                flex={'1 0 6%'}
                            >
                                掛號
                            </Box>
                            <Box
                                sx={{
                                    ...headerElementStyle,
                                    textAlign: 'center',
                                }}
                                flex={'1 0 6%'}
                            >
                                自付
                            </Box>
                            <Box
                                sx={{
                                    ...headerElementStyle,
                                    textAlign: 'center',
                                }}
                                flex={'1 0 6%'}
                            >
                                高藥
                            </Box>
                            <Box
                                sx={{
                                    ...headerElementStyle,
                                    textAlign: 'center',
                                }}
                                flex={'1 0 6%'}
                            >
                                押單
                            </Box>
                            <Box
                                sx={{
                                    ...headerElementStyle,
                                    textAlign: 'center',
                                }}
                                flex={'1 0 6%'}
                            >
                                自費
                            </Box>
                        </>
                    )}
                </>
            ) : (
                <Box sx={headerElementStyle} flex={'1 0 6%'}>
                    案件
                </Box>
            )}
        </Flex>
    );
};
