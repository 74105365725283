import React, { createContext, useContext, useState, ReactNode } from 'react';

interface HealthCheckRegisteredTableHeightContextValue {
    healthCheckRegisteredTableHeightStatus: number;
    setHealthCheckRegisteredTableHeightStatus: React.Dispatch<
        React.SetStateAction<number>
    >;
}

const HealthCheckRegisteredTableHeightContext = createContext<
    HealthCheckRegisteredTableHeightContextValue | undefined
>(undefined);

interface HealthCheckRegisteredTableHeightStatusProps {
    children: ReactNode;
}

export const HealthCheckRegisteredTableHeightStatusProvider: React.FC<
    HealthCheckRegisteredTableHeightStatusProps
> = ({ children }) => {
    const [
        healthCheckRegisteredTableHeightStatus,
        setHealthCheckRegisteredTableHeightStatus,
    ] = useState(0);

    return (
        <HealthCheckRegisteredTableHeightContext.Provider
            value={{
                healthCheckRegisteredTableHeightStatus,
                setHealthCheckRegisteredTableHeightStatus,
            }}
        >
            {children}
        </HealthCheckRegisteredTableHeightContext.Provider>
    );
};

export const useHealthCheckRegisteredTableHeightStatus =
    (): HealthCheckRegisteredTableHeightContextValue => {
        const context = useContext(HealthCheckRegisteredTableHeightContext);

        if (!context) {
            throw new Error(
                'useHealthCheckRegisteredTableHeightStatus must be used within a HealthCheckRegisteredTableHeightStatusProvider'
            );
        }

        return context;
    };
