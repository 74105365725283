import { useEffect } from 'react';
import { Input, Checkbox, Flex, Text, Select } from '@chakra-ui/react';
import { relatedSettingDataProps } from './DrugDataMaintenanceTable';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';

interface DrugDataMaintenanceRelatedSetting {
    relatedSettingData: relatedSettingDataProps;
    setRelatedSettingData: React.Dispatch<
        React.SetStateAction<relatedSettingDataProps>
    >;
    isEditing: boolean;
}

const DrugDataMaintenanceRelatedSetting = ({
    relatedSettingData,
    setRelatedSettingData,
    isEditing,
}: DrugDataMaintenanceRelatedSetting) => {
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    const handleChangeSelect = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        setHasUnsavedChanges(true);

        setRelatedSettingData((pre: relatedSettingDataProps) => ({
            ...pre,
            [name]: value,
        }));
    };

    const handleChangeInput = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> & {
            target: HTMLInputElement;
        }
    ) => {
        const { name, value } = event.target;
        if (
            name === 'originPackaging' ||
            name === 'reminderForInsuredPartiesToInquire'
        ) {
            const { name, checked } = event.target;
            setRelatedSettingData((pre: relatedSettingDataProps) => ({
                ...pre,
                [name]: checked ? '1' : '0',
            }));
            setHasUnsavedChanges(true);
        } else if (name === 'package_capacity' || name === 'package_qty') {
            const regex = /^(?:\d{1,7}(?:\.\d{0,2})?|\.\d{1,2})$/;
            if (regex.test(value))
                setRelatedSettingData((pre: relatedSettingDataProps) => ({
                    ...pre,
                    [name]: value,
                }));
            setHasUnsavedChanges(true);
        } else {
            setRelatedSettingData((pre: relatedSettingDataProps) => ({
                ...pre,
                [name]: value,
            }));
            setHasUnsavedChanges(true);
        }
    };

    return (
        <>
            <Flex rowGap={'24px'} flexWrap={'wrap'}>
                <Flex
                    align={'center'}
                    flex={window.innerWidth > 1440 ? '0 1 25%' : '0 1 50%'}
                    gap={'8px'}
                    pr={'24px'}
                >
                    <Checkbox
                        colorScheme="rsPrimary"
                        name="bottle_package"
                        id="bottle_package"
                        onChange={handleChangeInput}
                        isDisabled={!isEditing}
                        defaultValue={'0'}
                        value={relatedSettingData.bottle_package ?? ''}
                    ></Checkbox>
                    <Text textStyle={'semiTitle'} flex={'0 0 auto'}>
                        <label htmlFor="bottle_package">原瓶包裝</label>
                    </Text>
                </Flex>

                <Flex
                    align={'center'}
                    flex={window.innerWidth > 1440 ? '0 1 25%' : '0 1 50%'}
                    gap={'8px'}
                    pr={'24px'}
                >
                    <Text
                        color={'rsPrimary.500'}
                        fontWeight={'semibold'}
                        flex={'0 0 auto'}
                    >
                        包裝量
                    </Text>
                    <Input
                        textColor={'#000'}
                        disabled={!isEditing}
                        name="package_capacity"
                        value={relatedSettingData.package_capacity ?? ''}
                        onChange={handleChangeInput}
                        bg={isEditing ? '#fff' : 'transparent'}
                    ></Input>
                </Flex>

                <Flex
                    align={'center'}
                    flex={window.innerWidth > 1440 ? '0 1 25%' : '0 1 50%'}
                    gap={'8px'}
                    pr={'24px'}
                >
                    <Text
                        color={'rsPrimary.500'}
                        fontWeight={'semibold'}
                        flex={'0 0 64px'}
                    >
                        包裝單位
                    </Text>
                    <Input
                        textColor={'#000'}
                        disabled={!isEditing}
                        name="package_unit"
                        value={relatedSettingData.package_unit ?? ''}
                        maxLength={10}
                        onChange={handleChangeInput}
                        bg={isEditing ? '#fff' : 'transparent'}
                    ></Input>
                </Flex>

                <Flex
                    align={'center'}
                    flex={window.innerWidth > 1440 ? '0 1 25%' : '0 1 50%'}
                    gap={'8px'}
                    pr={'24px'}
                >
                    <Text textStyle={'semiTitle'} flex={'0 0 auto'}>
                        包裝個數
                    </Text>
                    <Input
                        textColor={'#000'}
                        disabled={!isEditing}
                        name="package_qty"
                        value={relatedSettingData.package_qty ?? ''}
                        onChange={handleChangeInput}
                        bg={isEditing ? '#fff' : 'transparent'}
                    ></Input>
                </Flex>

                <Flex
                    align={'center'}
                    flex={window.innerWidth > 1440 ? '0 1 25%' : '0 1 50%'}
                    gap={'8px'}
                    pr={'24px'}
                >
                    <Checkbox
                        name="check_insure_status"
                        id="check_insure_status"
                        onChange={handleChangeInput}
                        isDisabled={!isEditing}
                        defaultValue={'0'}
                        value={relatedSettingData.check_insure_status ?? ''}
                    ></Checkbox>
                    <Text
                        textStyle={'semiTitle'}
                        _hover={{ cursor: 'pointer' }}
                    >
                        <label htmlFor="check_insure_status">
                            提醒應保險對象查詢
                        </label>
                    </Text>
                </Flex>

                <Flex
                    align={'center'}
                    flex={window.innerWidth > 1440 ? '0 1 25%' : '0 1 50%'}
                    gap={'8px'}
                    pr={'24px'}
                >
                    <Text
                        color={'rsPrimary.500'}
                        fontWeight={'semibold'}
                        flex={'0 0 64px'}
                    >
                        檢驗申報
                    </Text>

                    <Select
                        textColor={'#000'}
                        disabled={!isEditing}
                        name="exam_declare"
                        value={relatedSettingData.exam_declare ?? ''}
                        onChange={handleChangeSelect}
                        placeholder="請選擇"
                        bg={'#fff'}
                    >
                        <option value="交檢">交檢*</option>
                        <option value="自行">自行*</option>
                        <option value="委自">委自*</option>
                        <option value="委交">委交*</option>
                    </Select>
                </Flex>

                <Flex
                    align={'center'}
                    flex={window.innerWidth > 1440 ? '0 1 25%' : '0 1 50%'}
                    gap={'8px'}
                    pr={'24px'}
                >
                    <Text
                        color={'rsPrimary.500'}
                        fontWeight={'semibold'}
                        flex={'0 0 64px'}
                    >
                        DRG碼
                    </Text>
                    <Input
                        textColor={'#000'}
                        disabled={!isEditing}
                        name="drg_code"
                        maxLength={2}
                        value={relatedSettingData.drg_code ?? ''}
                        onChange={handleChangeInput}
                        bg={isEditing ? '#fff' : 'transparent'}
                    ></Input>
                </Flex>

                <Flex
                    align={'center'}
                    flex={window.innerWidth > 1440 ? '0 1 25%' : '0 1 50%'}
                    gap={'8px'}
                    pr={'24px'}
                >
                    <Text
                        color={'rsPrimary.500'}
                        fontWeight={'semibold'}
                        flex={'0 0 64px'}
                    >
                        成分碼
                    </Text>
                    <Input
                        textColor={'#000'}
                        disabled={!isEditing}
                        name="ingredientCode"
                        value={relatedSettingData.ingredientCode ?? ''}
                        onChange={handleChangeInput}
                        bg={isEditing ? '#fff' : 'transparent'}
                    ></Input>
                </Flex>

                <Flex
                    align={'center'}
                    flex={window.innerWidth > 1440 ? '0 1 25%' : '0 1 50%'}
                    gap={'8px'}
                    pr={'24px'}
                >
                    <Text
                        color={'rsDanger.500'}
                        fontWeight={'semibold'}
                        flex={'0 0 64px'}
                    >
                        替代藥品
                    </Text>
                    <Input
                        className="text-white"
                        bg={isEditing ? 'rsDanger.600' : 'rsDanger.500'}
                        disabled={!isEditing}
                        name="substitute"
                        value={relatedSettingData.substitute ?? ''}
                        onChange={handleChangeInput}
                    ></Input>
                </Flex>

                <Flex
                    align={'center'}
                    flex={window.innerWidth > 1440 ? '0 1 25%' : '0 1 50%'}
                    gap={'8px'}
                    pr={'24px'}
                >
                    <Text
                        color={'rsPrimary.500'}
                        fontWeight={'semibold'}
                        flex={'0 0 64px'}
                    >
                        特定部位
                    </Text>

                    <Select
                        textColor={'#000'}
                        disabled={!isEditing}
                        name="specific_part"
                        value={relatedSettingData.specific_part ?? ''}
                        onChange={handleChangeSelect}
                        placeholder="請選擇"
                        bg={'#fff'}
                    >
                        <option value="item1">item1*</option>
                        <option value="item2">item2*</option>
                        <option value="item3">item3*</option>
                        <option value="item4">item4*</option>
                    </Select>
                </Flex>

                <Flex
                    align={'center'}
                    flex={window.innerWidth > 1440 ? '0 1 25%' : '0 1 50%'}
                    gap={'8px'}
                    pr={'24px'}
                >
                    <Text
                        color={'rsPrimary.500'}
                        fontWeight={'semibold'}
                        flex={'0 0 64px'}
                    >
                        治療代號
                    </Text>
                    <Input
                        textColor={'#000'}
                        disabled={!isEditing}
                        name="treatment_code"
                        value={relatedSettingData.treatment_code ?? ''}
                        onChange={handleChangeInput}
                        bg={isEditing ? '#fff' : 'transparent'}
                        maxLength={2}
                    ></Input>
                </Flex>
            </Flex>
        </>
    );
};

export default DrugDataMaintenanceRelatedSetting;
