import { Box, Image } from '@chakra-ui/react';
import { useState } from 'react';

const DrawerDetailsIcon = ({ onClick, disabled, isActive }: any) => {
    const [hover, setHover] = useState(false);
    const handleMouseEnter = () => {
        setHover(true);
    };
    const handleMouseLeave = () => {
        setHover(false);
    };
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            cursor={!disabled ? 'pointer' : 'not-allowed'}
            _hover={
                !disabled
                    ? { color: 'green.500', transform: 'translateY(-1px)' }
                    : {}
            }
            onClick={!disabled ? onClick : undefined}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <>
                <>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 27 27"
                        fill="none"
                    >
                        <rect
                            x="11"
                            y="3"
                            width="13"
                            height="21"
                            rx="1"
                            fill={hover && !disabled ? '#9B9B7A' : '#708368'}
                        />
                        <path
                            d="M23.2222 1H3.77778C2.24365 1 1 3.77778 1 3.77778V23.2222C1 24.7563 2.24365 26 3.77778 26H23.2222C24.7563 26 26 24.7563 26 23.2222V3.77778C26 2.24365 24.7563 1 23.2222 1Z"
                            stroke={hover && !disabled ? '#9B9B7A' : '#708368'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </>
            </>
        </Box>
    );
};

export default DrawerDetailsIcon;
