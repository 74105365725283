import { ReactHTMLElement, useEffect, useRef, useState } from 'react';
import {
    Flex,
    Box,
    Text,
    Button,
    Input,
    Select,
    Tooltip,
    Tag,
} from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';

import { useVitalSignQuery } from './useVitalSignQuery';
import { WaitingListsStatusProvider } from 'contexts/Registration/WaitingListsStatusContext';
import { useSignContext } from 'contexts/AllPageStatusContext'; // 导入 useSignContext

import { WaitingListsHeader } from './WaitingListsHeader';
import { WaitingListsFilterBar } from './WaitingListsFilterBar';
import { WaitingListsTable } from './WaitingListsTable';

//store
import { usePatientInfoStore } from 'store/Registration';

type WaitingListsProps = {
    opd_at: string;
    data_id?: string;
    serial_no: string;
    shift_uid?: string;
    clinic_room_uid?: string;
    doctor_name?: string;
    patient_name: string;
    reg_fee_identity?: string;
    card_no: string;
    medical_record_no: string;
    birthday: string;
    weight: string;
    temperature: string;
    note_to_treatment: string;
};
export const WaitingList = () => {
    const token = localStorage.getItem('token');
    let location = useLocation();

    const pathName = location.pathname;
    const pathSegments = pathName.split('/');
    const lastSegment = pathSegments.pop();

    const { state, dispatch } = useSignContext(); // 使用 useSignContext
    const { patientInfo, updatePatientInfo } = usePatientInfoStore();

    useState<WaitingListsProps[]>();
    const [ROCBirthDate, setROCBirthDate] = useState('');
    useEffect(() => {
        if (patientInfo.info.birth_date) {
            let birthDate = new Date(patientInfo.info.birth_date);
            let year = birthDate.getFullYear();
            let month = birthDate.getMonth() + 1;
            let day = birthDate.getDate();
            let ROCYear = year - 1911;
            let formattedDate = `${ROCYear}.${month}.${day}`;
            setROCBirthDate(formattedDate);
        }
    }, [patientInfo.info.birth_date]);

    const { lists, isLoading, error } = useVitalSignQuery();

    useEffect(() => {
        if (
            !isLoading &&
            lists &&
            lists.length > 4 &&
            state.selectedSigns.length == 0
        ) {
            // console.log(
            //     '觸發 近來幾次',
            //     isLoading,
            //     'isLoading',
            //     lists,
            //     'lists',
            //     state,
            //     'state'
            // );
            dispatch({ type: 'TOGGLE_SIGN', payload: lists[3].id });
            dispatch({ type: 'TOGGLE_SIGN', payload: lists[4].id });
        }
        // console.log('有觸發吧？', isLoading, 'isLoading', lists, 'lists');
    }, [lists, dispatch, isLoading]);

    return (
        <>
            <WaitingListsStatusProvider>
                <WaitingListsFilterBar />

                <Flex
                    direction={'column'}
                    maxH={
                        lastSegment === 'RegisteredPage'
                            ? `${window.innerHeight - 380}px`
                            : 'auto'
                    }
                    overflowY={'scroll'}
                >
                    <WaitingListsHeader />
                    <Flex direction={'column'}>
                        <WaitingListsTable />
                    </Flex>
                </Flex>
            </WaitingListsStatusProvider>
        </>
    );
};
