import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
} from 'react';

interface AutoSavedStatusContextValue {
    autoSavedStatus: boolean;
    setAutoSavedStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const AutoSavedStatusContext = createContext<
    AutoSavedStatusContextValue | undefined
>(undefined);

interface AutoSavedStatusProviderProps {
    children: ReactNode;
}

export const AutoSavedStatusProvider: React.FC<
    AutoSavedStatusProviderProps
> = ({ children }) => {
    const [autoSavedStatus, setAutoSavedStatus] = useState(false);
    return (
        <AutoSavedStatusContext.Provider
            value={{ autoSavedStatus, setAutoSavedStatus }}
        >
            {children}
        </AutoSavedStatusContext.Provider>
    );
};

export const useAutoSavedStatus = (): AutoSavedStatusContextValue => {
    const context = useContext(AutoSavedStatusContext);
    if (context === undefined) {
        throw new Error(
            'useAutoSavedStatus must be used within a AutoSavedStatusProvider'
        );
    }
    return context;
};
