import React, { useEffect, useState } from 'react';
import { Center, Text, Select } from '@chakra-ui/react';
import { callCustomerService } from 'service/customerService';
import { useUserStore } from 'store';

export const Clinic = () => {
    const { userInfo } = useUserStore();
    const [hospName, setHospName] = useState<string>();
    const callCustomerAPI = async () => {
        try {
            const result = await callCustomerService(userInfo.token);
            if (result.result === 'success') {
                console.log('callCustomerService:', result.info);
                setHospName(result.info.hosp_name);
            }
        } catch (error) {
            console.error('調用客戶服務時出錯:', error);
        }
    };

    useEffect(() => {
        callCustomerAPI();
    }, []);
    return (
        <Center gap={'16px'}>
            <Text fontWeight={'semibold'} flex={'0 0 auto'}>
                院所
            </Text>
            <Select value={hospName}>
                <option value={hospName}>{hospName}</option>
            </Select>
        </Center>
    );
};
