import { useState } from 'react';
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { WaitingList } from 'components/WaitingListTable/WaitingList';
import { VisitedList } from 'components/WaitingListTable/VisitedList';
import { AppointmentList } from 'components/WaitingListTable/AppointmentList';
import { WaitingListsStatusProvider } from 'contexts/Registration/WaitingListsStatusContext';

export const RegisteredWaitingTable = () => {
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (index: number) => {
        console.log(index, 'index');
        setTabIndex(index);
    };

    return (
        <>
            <Box>
                <Tabs index={tabIndex} onChange={handleTabChange}>
                    <TabList bg={'gray.50'}>
                        <Tab textColor={'rsSecondary.500'}>候診</Tab>
                        <Tab textColor={'rsSecondary.500'}>已診</Tab>
                        {/* <Tab textColor={'rsSecondary.500'}>預約</Tab> */}
                    </TabList>
                    <WaitingListsStatusProvider>
                        <TabPanels>
                            <TabPanel px={0}>
                                {tabIndex === 0 && <WaitingList />}
                            </TabPanel>
                            <TabPanel px={0}>
                                {tabIndex === 1 && <VisitedList />}
                            </TabPanel>
                            <TabPanel px={0}>
                                {tabIndex === 2 && <AppointmentList />}
                            </TabPanel>
                        </TabPanels>
                    </WaitingListsStatusProvider>
                </Tabs>
            </Box>
        </>
    );
};
