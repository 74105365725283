// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datePickerCustomForWorkingSchedule .react-datepicker {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.datePickerCustomForWorkingSchedule .react-datepicker__header {
    background-color: transparent;
}

.datePickerCustomForWorkingSchedule .react-datepicker__today-button {
    background-color: #f0f0f0;
    border-top: none;
}
.datePickerCustomForWorkingSchedule .react-datepicker__today-button:hover {
    background-color: #e0e0e0;
}

.datePickerCustomForWorkingSchedule .react-datepicker__day-name {
    font-weight: 700;
    color: #708368;
}
`, "",{"version":3,"sources":["webpack://./src/style/components/datePickerCustomForWorkingSchedule.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".datePickerCustomForWorkingSchedule .react-datepicker {\n    border: none;\n    background-color: transparent;\n    box-shadow: none;\n}\n\n.datePickerCustomForWorkingSchedule .react-datepicker__header {\n    background-color: transparent;\n}\n\n.datePickerCustomForWorkingSchedule .react-datepicker__today-button {\n    background-color: #f0f0f0;\n    border-top: none;\n}\n.datePickerCustomForWorkingSchedule .react-datepicker__today-button:hover {\n    background-color: #e0e0e0;\n}\n\n.datePickerCustomForWorkingSchedule .react-datepicker__day-name {\n    font-weight: 700;\n    color: #708368;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
