import { ICCardUpdateDrugProps, ICCardContent } from 'store/TreatmentManagement/ICCardUpdate/useICCardUpdateStore';
import apiService from './apiService';

export interface IcDataProps {
    patient_uid: string; // 病患資料 id
    // medical_record_number: string; // 病例資料 id
    medical_record_uid: string;
    patient_name: string; // 病患姓名
    patient_id_no: string; // 病患身分證號，從IC卡獲取的資料
    patient_birth_date: string; // 病患生日，從IC卡獲取的資料，長度7碼，民國年格式YYYmmdd
    mt_type: string; // 就醫類別，長度固定兩碼
    treated_at: string; // 取就醫序號時獲得的就醫時間，長度固定13碼，民國年格式YYYmmddHHiiss
    card_no: string; // 就醫序號，固定長度四碼
    ic_content: ICCardContent; // 整包IC Data
    drugs: ICCardUpdateDrugProps[]
}
//寫入待上傳的 IC 資料
//2024-07-31 因應8/2其中驗收，只先寫入部分資料，其中驗收後需補完
export const postIcDataService = async (token: string, finalData: IcDataProps) => {
    console.log(finalData, '要送出的待上傳得IC資料')
    return apiService<IcDataProps, any>({
        method: 'POST',
        path: '/api/ic_data',
        token,
        data: finalData,
    });
};