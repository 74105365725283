import React, { useEffect, useState, useRef } from 'react';
import { Box, Input, Flex, useTimeout } from '@chakra-ui/react';
import { useDrag, useDrop } from 'react-dnd';
import {
    usePrescriptionStore,
    PrescriptionProps,
    useCCPEStore,
    useDxStore,
    usePricingStore,
} from 'store/TreatmentManagement';
import { initialDxList } from 'store/TreatmentManagement';
import moment from 'moment';
import {
    callDrugsForTreatmentService,
    postDrugsForTreatmentService,
} from 'service/drugsForTreatmentService';
import { usePrescriptionInputRefs } from 'contexts/TreatmentManagement/Prescription';
import { useUserStore } from 'store';
import Swal from 'sweetalert2';
import {
    RightSideOptionSelectStatus,
    useRightSideOptionSelectStatus,
    useIsSearchListsStatus,
} from 'contexts/TreatmentManagement/Prescription';
import { SearchResultList } from './SearchResultLists';
import { handleFrequencyMatching } from './HandleFrequencyMatching';
import { callDrugFreqSpecService } from 'service/drugFreqSpecService';
import { callDrugFreqListsService } from 'service/drugFreqListsService';
import { useIsCopyDrugFromHistoryStatus } from 'contexts/TreatmentManagement/IsCopyDrugFromHistoryContext';
interface PrescriptionRowProps {
    rowIndex: number;
    item: PrescriptionProps;
    selectedIndex: { row: number; col: number };
    setSelectedIndex: (index: { row: number; col: number }) => void;
    setIsEditing: (isEditing: boolean) => void;
    updateCellValue: (
        rowIndex: number,
        colIndex: number,
        value: string
    ) => void;
    boxRefs: React.MutableRefObject<Array<Array<HTMLDivElement | null>>>;
    isEditing: boolean;
    handleAddMultipleRows: (index: number, count: number) => Promise<number>;
    updateInputValues: (values: { columns: string[] }[]) => void;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
    updatePrescription: (
        index: number,
        prescription: Partial<PrescriptionProps>,
        inputValues: string[]
    ) => void;
    isComposingRef: React.MutableRefObject<boolean>;
    onAddRow: (index: number) => Promise<number>;
    setPrescriptionList: (list: PrescriptionProps[]) => void;
    drugFreqLists: DrugFreqListsProps[];
    boxMetrics: BoxMetrics;
}

interface DrugFreqListsProps {
    data_id: string;
    code: string;
    freq_code: string;
    ori_freq_code: string;
    directions: string;
    days: number;
    times: number;
    type: string;
}

interface Drug {
    data_id: string;
    name_tw: string;
    drug_no: string;
    short_code: string;
}

export interface SearchResultProps {
    attach_ccpe: string;
    attach_drugs: string;
    attach_icd: string;
    cost: string;
    data_id: string;
    drug_no: string;
    drug_price: string;
    drug_type: string;
    drug_type_code: string;
    drug_type_name: string;
    freq: string;
    freq_directions: string;
    freq_code: string;
    name_tw: string;
    self_price: string;
    short_code: string;
    unit: string;
}

export interface SearchDetailResultProps {
    data_id: string;
    short_code: string;
    name_tw: string;
    name_en: string;
    attach_ccpe: string;
    attach_drugs: attachDrugProps[];
    attach_icd: attachICDProps[];
    attach_so: attachSoProps;
}

type attachSoProps = {
    subjective: string;
    objective: string;
};

type attachICDProps = {
    attach_icd_uid: string;
    data_id: string;
    icd_code: string;
    name_en: string;
    name_tw: string;
    short_code: string;
};

type attachDrugProps = {
    controlled_level: string;
    cost: string;
    custom_name_en: string;
    custom_name_tw: string;
    data_id: string;
    disabled_date: string;
    dosage_form: string;
    dose: string;
    dosing_days: string;
    drug_no: string;
    drug_price: number | null;
    drug_type: string;
    freq: string;
    max_dose: string;
    max_dose_day: string;
    name_en: string;
    name_tw: string;
    route_of_administration: string;
    self_price: string;
    short_code: string;
    taking_time: string;
    unit: string;
    freq_custom_code?: string;
    freq_directions?: string;
};

const ItemType = 'PRESCRIPTION_ROW';

interface BoxMetrics {
    innerWidth: number;
    innerHeight: number;
    top: number;
    left: number;
}

export const PrescriptionRow: React.FC<PrescriptionRowProps> = ({
    rowIndex,
    item,
    selectedIndex,
    setSelectedIndex,
    setIsEditing,
    handleAddMultipleRows,
    updateCellValue,
    boxRefs,
    isEditing,
    isComposingRef,
    updateInputValues,
    moveRow,
    updatePrescription,
    onAddRow,
    setPrescriptionList,
    drugFreqLists,
    boxMetrics,
}) => {
    const { userInfo } = useUserStore();
    const { updateCostPrice, updateInsurancePrice, updateSelfFundedPrice } =
        usePricingStore();
    const { inputValues, prescriptionList } = usePrescriptionStore();
    const { CCPEData, updateCCPEData } = useCCPEStore();
    const { prescriptionInputRefs, setPrescriptionInputRefs } =
        usePrescriptionInputRefs();
    const { setRightSideOptionSelectStatus } = useRightSideOptionSelectStatus();
    const [searchResult, setSearchResult] = useState<SearchResultProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [resultMsg, setResultMsg] = useState<string>('');
    const { isSearchListsStatus, setIsSearchListsStatus } =
        useIsSearchListsStatus();
    const [focusNextRow, setFocusNextRow] = useState(false);
    const { dxList, updateDx, resetDx } = useDxStore();
    const [isDrugExists, setIsDrugExists] = useState(false);
    const [isBlurDisabled, setIsBlurDisabled] = useState(false);

    const [, ref] = useDrag({
        type: ItemType,
        item: { rowIndex },
    });
    const initialPrescription: PrescriptionProps = {
        controlled_level: '',
        cost: '',
        data_id: '',
        disabled_date: '',
        dosage_form: '',
        dose: '',
        qty: '',
        dose_day: '',
        dosing_days: '',
        drug_no: '',
        drug_price: null,
        drug_type: '',
        freq: '',
        freq_custom_code: '',
        max_dose: '',
        max_dose_day: '',
        name_en: '',
        name_tw: '',
        route_of_administration: '',
        self_price: '',
        short_code: '',
        taking_time: '',
        unit: '',
        qty_in_day: '',
        total_qty: '',
        given_qty: '',
        memo: '',
        site: '',
        special_mark: '',
    };


    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem: { rowIndex: number }) => {
            if (draggedItem.rowIndex !== rowIndex) {
                moveRow(draggedItem.rowIndex, rowIndex);
                draggedItem.rowIndex = rowIndex;
            }
        },
    });
    const { isCopyDrugFromHistoryStatus, setIsCopyDrugFromHistoryStatus } =
        useIsCopyDrugFromHistoryStatus();
    const handleUpdateCellValue = (
        rowIndex: number,
        colIndex: number,
        value: string
    ) => {
        console.log(value, '更新了哪些');
        updateCellValue(rowIndex, colIndex, value);
    };

    const updateDxListsFunc = (attach_icd: attachICDProps[]) => {
        let updatedDxCount = 0;
        let dxIndex = dxList.findIndex(dx => dx.icd_code === '');
        for (let i = 0; i < attach_icd.length; i++) {
            const icdCodeExists = dxList.some(
                dx => dx.icd_code === attach_icd[i].icd_code
            );
            if (icdCodeExists) {
                continue;
            }
            if (dxIndex !== -1) {
                updateDx(dxIndex, {
                    ...initialDxList,
                    name_tw: attach_icd[i].name_tw,
                    icd_code: attach_icd[i].icd_code,
                    data_id: attach_icd[i].data_id,
                });
                updatedDxCount++;
                dxIndex++;
                if (updatedDxCount >= 6) break;
            } else break;
        }
    };

    const handleCCPEDataUpdate = async (
        SpecResult: SearchDetailResultProps
    ) => {
        if (SpecResult.attach_so) {
            if (CCPEData.cc === '') {
                updateCCPEData({
                    ...CCPEData,
                    cc: SpecResult.attach_so.subjective,
                });
            } else {
                try {
                    const result = await Swal.fire({
                        icon: 'question',
                        title: '此病名有預設帶入CCPE',
                        showConfirmButton: true,
                        confirmButtonText: '接在目前症狀後端',
                        showDenyButton: true,
                        denyButtonText: '覆蓋現有資料',
                        showCancelButton: true,
                        cancelButtonText: '不帶入任何資料',
                    });

                    if (result.isConfirmed) {
                        updateCCPEData({
                            ...CCPEData,
                            cc: `${CCPEData.cc}, ${SpecResult.attach_so.subjective}`,
                        });
                    } else if (result.isDenied) {
                        updateCCPEData({
                            ...CCPEData,
                            cc: SpecResult.attach_so.subjective,
                        });
                    }
                } catch (error) {
                    console.error('更新CCPE數據時發生錯誤:', error);
                }
            }
        }
    };

    const handleSpecResultProcessing = async (
        SpecResult: SearchDetailResultProps,
        updatedPrescriptionList: PrescriptionProps[],
        updatedInputValues: { columns: string[] }[]
    ) => {
        // 處理 SpecResult 的函數
        try {
            // 檢查所有處方是否都沒有 data_id
            const allIcdCodesEmpty = updatedPrescriptionList.every(
                item => item.data_id === ''
            );

            if (allIcdCodesEmpty) {
                // 如果所有處方都沒有 data_id
                if (SpecResult.attach_drugs) {
                    // 如果 SpecResult 包含附加藥物，更新藥物列表
                    try {
                        await updateDrugListsFunc(
                            SpecResult.attach_drugs,
                            updatedPrescriptionList,
                            updatedInputValues
                        );
                    } catch (error) {
                        console.error('更新藥物列表時發生錯誤:', error);
                    }
                }
                if (!isDrugExists) {
                    // 如果藥物不存在
                    if (SpecResult.attach_icd) {
                        // 如果 SpecResult 包含附加 ICD 碼，更新診斷列表
                        try {
                            updateDxListsFunc(SpecResult.attach_icd);
                        } catch (error) {
                            console.error('更新診斷列表時發生錯誤:', error);
                        }
                    }
                    if (SpecResult.attach_so) {
                        // 如果 SpecResult 包含附加主觀症狀，更新 CCPE 數據
                        try {
                            updateCCPEData({
                                ...CCPEData,
                                cc: SpecResult.attach_so.subjective,
                            });
                        } catch (error) {
                            console.error('更新CCPE數據時發生錯誤:', error);
                        }
                    }
                }
                setIsSearchListsStatus(false);
            } else {
                // 如果至少有一個處方有 data_id
                if (SpecResult.attach_drugs) {
                    // 如果 SpecResult 包含附加藥物，更新藥物列表
                    try {
                        await updateDrugListsFunc(
                            SpecResult.attach_drugs,
                            updatedPrescriptionList,
                            updatedInputValues
                        );
                    } catch (error) {
                        console.error('更新藥物列表時發生錯誤:', error);
                    }
                }
                if (!isDrugExists) {
                    // 如果藥物不存在
                    if (SpecResult.attach_icd) {
                        // 如果 SpecResult 包含附加 ICD 碼，更新診斷列表
                        try {
                            updateDxListsFunc(SpecResult.attach_icd);
                        } catch (error) {
                            console.error('更新診斷列表時發生錯誤:', error);
                        }
                    }
                    if (SpecResult.attach_so) {
                        // 如果 SpecResult 包含附加主觀症狀，更新 CCPE 數據
                        try {
                            updateCCPEData({
                                ...CCPEData,
                                cc: SpecResult.attach_so.subjective,
                            });
                        } catch (error) {
                            console.error('更新CCPE數據時發生錯誤:', error);
                        }
                    }
                }
                // 處理 CCPE 數據更新
                try {
                    await handleCCPEDataUpdate(SpecResult);
                } catch (error) {
                    console.error('更新CCPE數據時發生錯誤:', error);
                }
            }
        } catch (error) {
            console.error('處理SpecResult時發生錯誤:', error);
        }
    };

    // 定義一個非同步函數，用於呼叫藥物治療API
    const callDrugsForTreatmentAPI = async (
        data_id: string,
        date: string,
        keyword: string,
        rowIndex: number,
        colIndex: number,
        updatedPrescriptionList: PrescriptionProps[],
        updatedInputValues: { columns: string[] }[]
    ) => {
        // 準備API所需的數據
        const finalData = { data_id, date };
        try {
            // 呼叫API服務
            const result = await callDrugsForTreatmentService(
                userInfo.token!,
                finalData
            );
            // 檢查API回傳結果是否成功且包含data_id
            if (result.result === 'success' && result.info.data_id) {
                // 獲取舊的處方資料，如果不存在則使用初始處方

                // 創建新的處方資料，只使用API返回的資訊
                const newPrescription = {
                    controlled_level: result.info.controlled_level,
                    cost: result.info.cost,
                    data_id: result.info.data_id,
                    disabled_date: result.info.disabled_date,
                    dosage_form: result.info.dosage_form,
                    dose: result.info.dose,
                    dosing_days: result.info.dosing_days,
                    drug_no: result.info.drug_no,
                    drug_price: result.info.drug_price,
                    drug_type: drugFreqLists.find(
                        item => item.data_id === result.info.drug_type
                    )?.code,
                    freq: result.info.freq,
                    freq_custom_code: result.info.freq_custom_cod,
                    max_dose: result.info.max_dose,
                    max_dose_day: result.info.max_dose_day,
                    name_en: result.info.custom_name_en || result.info.name_en,
                    name_tw: result.info.custom_name_tw || result.info.name_tw,
                    route_of_administration:
                        result.info.route_of_administration,
                    self_price: result.info.self_price,
                    short_code: result.info.short_code,
                    taking_time: result.info.taking_time,
                    unit: result.info.unit,
                    qty_in_day: result.info.qty_in_day,
                    qty: result.info.dose,
                    dose_day: result.info.dosing_day,
                    total_qty: result.info.total_qty,
                    given_qty: result.info.given_qty,
                    memo: result.info.memo,
                    site: result.info.site,
                    special_mark: result.info.special_mark,
                };

                // 創建新的輸入值數組，只使用API返回的資訊
                let columns = [
                    // 藥物名稱：優先使用自定義中文名稱，其次是一般中文名稱
                    result.info.custom_name_tw || result.info.name_tw || '',
                    result.info.dose || '', // 劑量（dose）
                    result.info.freq_custom_code || '', // 頻率（freq）
                    result.info.dosing_days || '', // 用藥天數（dose_days）
                    result.info.qty_in_day || '', // 每日劑量（qty_in_day）
                    result.info.total_qty || '', // 總量（total_qty）
                    result.info.given_qty || '', // 發藥量（given_qty）
                    result.info.self_price || '', // 自費價格（self_price）
                    result.info.special_mark || '', // 特殊標記（special_mark）
                    result.info.memo || '', // 備註（memo）
                ];
                // console.log('1', newPrescription, '2', columns)
                // 更新處方列表和輸入值
                // updatedPrescriptionList[rowIndex] = newPrescription;
                updatedInputValues[rowIndex] = { columns };
                // 調用更新處方的函數
                updatePrescription(rowIndex, newPrescription, columns);
            } else {
                console.log('資料ID缺失或API呼叫失敗。');
            }
        } catch (error) {
            console.error('呼叫藥物治療服務時發生錯誤:', error);
        }
    };

    const postDrugsForTreatmentAPI = async (
        keyword: string,
        rowIndex: number,
        colIndex: number
    ) => {
        try {
            const finalData = {
                keyword,
                date: moment(new Date()).format('YYYY-MM-DD'),
            };
            setIsLoading(true);
            setSearchResult([]);
            setIsSearchListsStatus(true);
            const result = await postDrugsForTreatmentService(
                userInfo.token!,
                finalData
            );

            if (result.result === 'success' && result.lists.length === 1) {
                console.log('一筆');
                const drug = result.lists[0] as Drug;

                const isDrugExists = prescriptionList.some(
                    prescription => prescription.drug_no === drug.drug_no
                );
                setIsDrugExists(true);
                if (isDrugExists) {
                    const swalResult = await Swal.fire({
                        icon: 'question',
                        title: `重複用藥: ${drug.name_tw} ,確定開列?`,
                        showConfirmButton: true,
                        confirmButtonText: '是',
                        showDenyButton: true,
                        denyButtonText: '否',
                    });

                    if (!swalResult.isConfirmed) {
                        setIsLoading(false);
                        setIsSearchListsStatus(false);
                        return;
                    }
                }

                let updatedPrescriptionList = [...prescriptionList];
                let updatedInputValues = [...inputValues];

                await callDrugsForTreatmentAPI(
                    drug.data_id,
                    finalData.date,
                    keyword,
                    rowIndex,
                    colIndex,
                    updatedPrescriptionList,
                    updatedInputValues
                );
                const oldPrescription =
                    prescriptionList[rowIndex] || initialPrescription;

                const finalSpecData = {
                    data_id: drug.data_id,
                    date: moment(new Date()).format('YYYY-MM-DD'),
                };
                const SpecResult = await callDrugsForTreatmentService(
                    userInfo.token!,
                    finalSpecData
                );
                await handleSpecResultProcessing(
                    SpecResult,
                    updatedPrescriptionList,
                    updatedInputValues
                );
                const newPrescription = {
                    ...oldPrescription,
                    controlled_level: SpecResult.info.controlled_level,
                    cost: SpecResult.info.cost,
                    data_id: SpecResult.info.data_id,
                    disabled_date: SpecResult.info.disabled_date,
                    dosage_form: SpecResult.info.dosage_form,
                    dose: SpecResult.info.dose,
                    qty: SpecResult.info.dose,
                    dose_day: SpecResult.info.dosing_days,
                    dosing_days: SpecResult.info.dosing_days,
                    drug_no: SpecResult.info.drug_no,
                    drug_price: SpecResult.info.drug_price,
                    drug_type: SpecResult.info.drug_type,
                    freq: SpecResult.info.freq,
                    freq_custom_code: SpecResult.info.freq_custom_code,
                    max_dose: SpecResult.info.max_dose,
                    max_dose_day: SpecResult.info.max_dose_day,
                    name_en: SpecResult.info.custom_name_en
                        ? SpecResult.info.custom_name_en
                        : SpecResult.info.name_en,
                    name_tw: SpecResult.info.custom_name_tw
                        ? SpecResult.info.custom_name_tw
                        : SpecResult.info.name_tw,
                    route_of_administration:
                        SpecResult.info.route_of_administration,
                    self_price: SpecResult.info.self_price,
                    short_code: SpecResult.info.short_code,
                    taking_time: SpecResult.info.taking_time,
                    unit: SpecResult.info.unit,
                };
                const columns = [
                    SpecResult.info.custom_name_tw
                        ? SpecResult.info.custom_name_tw
                        : SpecResult.info.name_tw,
                    SpecResult.info.dose || '', // dose
                    SpecResult.info.freq_custom_code || '', // freq
                    SpecResult.info.dosing_days || '', // dosing_days(天數)
                    '', //qty_in_day
                    '', // total_qty
                    '', // given_qty
                    SpecResult.info.self_price || '', // self_price
                    '', // special_mark
                    '', // memo
                ];
                setPrescriptionList(updatedPrescriptionList);
                updateInputValues(updatedInputValues);
                updatePrescription(rowIndex, newPrescription, columns);

                if (
                    SpecResult.info.dose &&
                    SpecResult.info.freq_custom_code &&
                    SpecResult.info.dosing_days
                ) {
                    setIsNewPrescriptionCalculated(true);
                } else {
                    setIsNewPrescriptionCalculated(false);
                }
                setIsLoading(false);
                setIsSearchListsStatus(false);
            } else if (result.result === 'success' && result.lists.length > 1) {
                setIsLoading(false);
                setSearchResult(result.lists);
            } else {
                setIsLoading(false);
                setResultMsg(result.msg);
            }
            return result;
        } catch (error) {
            console.error('處理藥物治療API時發生錯誤:', error);
            setIsLoading(false);
            setIsSearchListsStatus(false);
            setResultMsg('處理藥物治療時發生錯誤，請稍後再試。');
            throw error;
        }
    };

    const updateDrugListsFunc = async (
        attachDrugs: attachDrugProps[],
        updatedPrescriptionList: PrescriptionProps[],
        updatedInputValues: { columns: string[] }[]
    ) => {
        // 有附加藥品才進來
        try {
            const startIndex = updatedPrescriptionList.length;
            await handleAddMultipleRows(startIndex, attachDrugs.length);

            for (let i = 0; i < attachDrugs.length; i++) {
                try {
                    const newIndex = startIndex + i;
                    const drug = attachDrugs[i];
                    const isDrugExists = updatedPrescriptionList.some(
                        prescription => prescription.drug_no === drug.drug_no
                    );
                    if (isDrugExists) {
                        const swalResult = await Swal.fire({
                            icon: 'question',
                            title: `重複用藥: ${drug.name_tw} ,確定開列?`,
                            showConfirmButton: true,
                            confirmButtonText: '是',
                            showDenyButton: true,
                            denyButtonText: '否',
                        });

                        if (!swalResult.isConfirmed) {
                            continue; // 如果用戶選擇否，跳過當前藥物
                        }
                    }

                    const oldPrescription =
                        updatedPrescriptionList[newIndex] ||
                        initialPrescription;
                    const newPrescription = {
                        ...oldPrescription,
                        controlled_level:
                            drug.controlled_level ||
                            oldPrescription.controlled_level,
                        cost: drug.cost || oldPrescription.cost,
                        data_id: drug.data_id || oldPrescription.data_id,
                        disabled_date:
                            drug.disabled_date || oldPrescription.disabled_date,
                        dosage_form:
                            drug.dosage_form || oldPrescription.dosage_form,
                        dose: drug.dose || oldPrescription.dose,
                        dose_day: drug.dosing_days,
                        dosing_days:
                            drug.dosing_days || oldPrescription.dosing_days,
                        drug_no: drug.drug_no || oldPrescription.drug_no,
                        drug_price:
                            drug.drug_price || oldPrescription.drug_price,
                        drug_type: drug.drug_type || oldPrescription.drug_type,
                        freq: drug.freq || oldPrescription.freq,
                        freq_custom_code:
                            drug.freq_custom_code ||
                            oldPrescription.freq_custom_code,
                        max_dose: drug.max_dose || oldPrescription.max_dose,
                        max_dose_day:
                            drug.max_dose_day || oldPrescription.max_dose_day,
                        name_en: drug.custom_name_en
                            ? drug.custom_name_en
                            : drug.name_en || oldPrescription.name_en,
                        name_tw: drug.custom_name_tw
                            ? drug.custom_name_tw
                            : drug.name_tw || oldPrescription.name_tw,
                        route_of_administration:
                            drug.route_of_administration ||
                            oldPrescription.route_of_administration,
                        self_price:
                            drug.self_price || oldPrescription.self_price,
                        short_code:
                            drug.short_code || oldPrescription.short_code,
                        taking_time:
                            drug.taking_time || oldPrescription.taking_time,
                        unit: drug.unit || oldPrescription.unit,
                        qty_in_day: oldPrescription.qty_in_day,
                        total_qty: oldPrescription.total_qty,
                        given_qty: oldPrescription.given_qty,
                        qty: drug.dose || oldPrescription.qty,

                        memo: oldPrescription.memo,
                        site: oldPrescription.site,
                        special_mark: oldPrescription.special_mark,
                    };

                    const oldColumns =
                        updatedInputValues[newIndex]?.columns ||
                        Array(10).fill('');
                    const columns = [
                        drug.custom_name_tw
                            ? drug.custom_name_tw
                            : drug.name_tw || oldColumns[0] || '',
                        drug.dose || oldColumns[1] || '', // dose
                        drug.freq_custom_code || oldColumns[2] || '', // freq
                        drug.dosing_days || oldColumns[3] || '', // dosing_days
                        oldColumns[4] || '', // qty_in_day
                        oldColumns[5] || '', // total_qty
                        oldColumns[6] || '', // given_qty
                        drug.self_price || oldColumns[7] || '0', // self_price
                        oldColumns[8] || '', // special_mark
                        oldColumns[9] || '', // memo
                    ];
                    if (newIndex < updatedPrescriptionList.length) {
                        updatedPrescriptionList[newIndex] = newPrescription;
                        updatedInputValues[newIndex] = { columns };
                    } else {
                        updatedPrescriptionList.push(newPrescription);
                        updatedInputValues.push({ columns });
                    }
                    console.log(drug, '跑哪幾個藥');
                    // 如果有dose, dosing_days 和 freq_custom_code，進行運算
                    if (
                        drug.dose &&
                        drug.dosing_days &&
                        drug.freq_custom_code
                    ) {
                        setIsNewPrescriptionCalculated(true);
                    } else {
                        setIsNewPrescriptionCalculated(false);
                        // 如果缺少資訊，移動到下一行的第一個空欄位
                        const nextEmptyColumn = columns.findIndex(
                            col => col === ''
                        );
                        if (nextEmptyColumn !== -1) {
                            prescriptionInputRefs.current[newIndex]?.[
                                nextEmptyColumn
                            ]?.focus();
                        }
                    }
                } catch (error) {
                    console.error('處理單個藥物時發生錯誤:', error);
                }
            }
        } catch (error) {
            console.error('更新藥物列表時發生錯誤:', error);
        }
    };

    // 在這兩個函數之後，添加一個新的 useEffect 來處理計算

    const handleInsertPrescription = async (SearchResultIndex: number) => {
        // 要開searchList  才會觸發
        try {
            const data_id = searchResult[SearchResultIndex].data_id;
            const finalData = {
                data_id,
                date: moment(new Date()).format('YYYY-MM-DD'),
            };

            const SpecResult = await callDrugsForTreatmentService(
                userInfo.token!,
                finalData
            );
            setIsLoading(false);

            const oldPrescription =
                prescriptionList[rowIndex] || initialPrescription;

            const isDrugExists = prescriptionList.some(
                prescription => prescription.drug_no === SpecResult.info.drug_no
            );

            if (isDrugExists) {
                const result = await Swal.fire({
                    icon: 'question',
                    title: '此藥品有重複是否帶入？',
                    showConfirmButton: true,
                    confirmButtonText: '是',
                    showDenyButton: true,
                    denyButtonText: '否',
                });

                if (!result.isConfirmed) {
                    return;
                }
                if (result.isConfirmed) {
                    setIsDrugExists(true);
                }
            }
            console.log(SpecResult, '搜尋要填入的藥');
            const newPrescription = {
                ...oldPrescription,
                controlled_level: SpecResult.info.controlled_level || '',
                cost: SpecResult.info.cost || '',
                data_id: SpecResult.info.data_id || '',
                disabled_date: SpecResult.info.disabled_date || '',
                dosage_form: SpecResult.info.dosage_form || '',
                qty: SpecResult.info.dose || '',
                dosing_days: SpecResult.info.dosing_days || '',
                drug_no: SpecResult.info.drug_no || '',
                drug_price: SpecResult.info.drug_price || '',
                drug_type: SpecResult.info.drug_type || '',
                freq: SpecResult.info.freq || '',
                freq_custom_code: SpecResult.info.freq_custom_code || '',
                max_dose: SpecResult.info.max_dose || '',
                max_dose_day: SpecResult.info.max_dose_day || '',
                name_en: SpecResult.info.custom_name_en
                    ? SpecResult.info.custom_name_en
                    : SpecResult.info.name_en || '',
                name_tw: SpecResult.info.custom_name_tw
                    ? SpecResult.info.custom_name_tw
                    : SpecResult.info.name_tw || '',
                route_of_administration:
                    SpecResult.info.route_of_administration || '',
                self_price: SpecResult.info.self_price || '',
                short_code: SpecResult.info.short_code || '',
                taking_time: SpecResult.info.taking_time || '',
                unit: SpecResult.info.unit || '',
                dose_day: SpecResult.info.dosing_day || '',
            };

            let columns = [
                SpecResult.info.custom_name_tw
                    ? SpecResult.info.custom_name_tw
                    : SpecResult.info.name_tw,

                SpecResult.info.dose || '', // dose
                SpecResult.info.freq_custom_code || '', // freq
                SpecResult.info.dosing_days || '', // dose_days
                '', // qty_in_day
                '', // total_qty
                '', // given_qty
                SpecResult.info.self_price || '', // self_price
                '', // special_mark
                '', // memo
            ];

            const updatedPrescriptionList = [...prescriptionList];
            updatedPrescriptionList[rowIndex] = newPrescription;

            const updatedInputValues = [...inputValues];
            updatedInputValues[rowIndex] = { columns };

            try {
                await handleSpecResultProcessing(
                    SpecResult,
                    updatedPrescriptionList,
                    updatedInputValues
                );
            } catch (error) {
                console.error('處理 SpecResult 時發生錯誤:', error);
            }
            if (
                SpecResult.info.dose &&
                SpecResult.info.freq_custom_code &&
                SpecResult.info.dosing_days
            ) {
                try {
                    // await handleCountGiven();
                    setIsNewPrescriptionCalculated(true);
                } catch (error) {
                    console.error('計算給藥數量時發生錯誤:', error);
                    setIsNewPrescriptionCalculated(false);
                }
            } else {
                setIsNewPrescriptionCalculated(false);
            }

            setPrescriptionList(updatedPrescriptionList);
            updateInputValues(updatedInputValues);
            updatePrescription(rowIndex, newPrescription, columns);

            setSearchResult([]);
            setIsLoading(false);
            setIsSearchListsStatus(false);
        } catch (error) {
            console.error('插入處方時發生錯誤:', error);
            setIsLoading(false);
            setIsSearchListsStatus(false);
        }
        // return result;
    };

    // 如果想刪整欄但會沒辦法新增 要調整
    const handleDeleteRow = (rowIndex: number) => {
        const updatedPrescriptionList = prescriptionList.filter(
            (_, index) => index !== rowIndex
        );
        const updatedInputValues = inputValues.filter(
            (_, index) => index !== rowIndex
        );

        setPrescriptionList(updatedPrescriptionList);
        updateInputValues(updatedInputValues);
    };

    const clearDataIdForRow = async (rowIndex: number) => {
        const updatedPrescriptionList = prescriptionList.map(
            (prescription, index) =>
                index === rowIndex
                    ? { ...prescription, ...initialPrescription }
                    : prescription
        );
        const updatedInputValues = inputValues.map((row, index) =>
            index === rowIndex ? { columns: Array(10).fill('') } : row
        );
        setPrescriptionList(updatedPrescriptionList);
        updateInputValues(updatedInputValues);
    };

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>, colIndex: number) => {
    //     const value = e.target.value;

    //     handleUpdateCellValue(rowIndex, colIndex, value);
    //     if (colIndex === 0 && value === '') {
    //         clearDataIdForRow(rowIndex);
    //         prescriptionInputRefs.current[rowIndex]?.[0]?.focus();
    //     }
    // };

    // useEffect(()=>{
    //     console.log(rowIndex, inputValues[rowIndex].columns, '最終陣列', colLastIndex)
    //     if(colLastIndex === 1 || colLastIndex ===2 || colLastIndex ===3) {
    //         setTimeout(()=>{
    //             prescriptionInputRefs.current[rowIndex][colLastIndex + 1]?.focus();
    //          },0)
    //     }
    // }, [inputValues])
    useEffect(() => {
        console.log(prescriptionList, '最終物件');
    }, [prescriptionList]);
    // 讓inputValues可以正常輸入
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        colIndex: number
    ) => {
        const value = e.target.value;
        if (colIndex === 0) {
            handleUpdateCellValue(rowIndex, colIndex, value);
            if (value === '') {
                clearDataIdForRow(rowIndex);
                setTimeout(() => {
                    prescriptionInputRefs.current[rowIndex]?.[0]?.focus();
                }, 0);
            } else {
                // Clear data_id in prescriptionList for this row
                const updatedPrescriptionList = [...prescriptionList];
                if (updatedPrescriptionList[rowIndex]) {
                    updatedPrescriptionList[rowIndex] = {
                        ...updatedPrescriptionList[rowIndex],
                        data_id: '',
                    };
                    setPrescriptionList(updatedPrescriptionList);
                }
            }
        } else if (colIndex === 2) {
            setIsChanged(false);
            const upperCaseValue = value.toLocaleUpperCase();
            // setTimeout(() => {
            //     prescriptionInputRefs.current[rowIndex]?.[0]?.focus();
            // }, 0);
            handleUpdateCellValue(rowIndex, colIndex, upperCaseValue);
        } else {
            handleUpdateCellValue(rowIndex, colIndex, value);
        }
    };
    const handleKeyDown = async (
        e: React.KeyboardEvent<HTMLInputElement>,
        colIndex: number
    ) => {
        if (isSearchListsStatus) {
            return; // 如果 isSearchListsStatus 為 true，則暫停處理鍵盤事件
        }

        if (!isComposingRef.current) {
            if (e.key === 'Escape') {
                prescriptionInputRefs.current[rowIndex][colIndex]?.focus();
                prescriptionInputRefs.current[rowIndex][colIndex]?.select();
            } else if (e.key === 'Enter' || e.key === 'Tab') {
                console.log(colIndex, '多少');
                if (colIndex === 9) {
                    try {
                        if (rowIndex === inputValues.length - 1) {
                            await onAddRow(rowIndex);
                            if (e.key === 'Enter') {
                                await prescriptionInputRefs.current[
                                    rowIndex + 1
                                ]?.[0]?.focus();
                            }
                        } else {
                            if (e.key === 'Enter') {
                                await prescriptionInputRefs.current[
                                    rowIndex + 1
                                ]?.[0]?.focus();
                            }
                        }
                    } catch (error) {
                        console.error(
                            '處理新行添加或焦點移動時發生錯誤:',
                            error
                        );
                    }
                } else {
                    setTimeout(() => {
                        prescriptionInputRefs.current[rowIndex]?.[
                            colIndex + 1
                        ]?.focus();
                    }, 0);
                }
                setIsBlurDisabled(false);
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                if (rowIndex > 0) {
                    setIsBlurDisabled(true);
                    prescriptionInputRefs.current[rowIndex - 1]?.[
                        colIndex
                    ]?.focus();
                }
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                if (rowIndex < inputValues.length - 1) {
                    setIsBlurDisabled(true);
                    prescriptionInputRefs.current[rowIndex + 1]?.[
                        colIndex
                    ]?.focus();
                }
            } else if (e.key === 'ArrowLeft') {
                if (colIndex > 0 && e.currentTarget.selectionStart === 0) {
                    e.preventDefault();
                    setIsBlurDisabled(true);
                    prescriptionInputRefs.current[rowIndex]?.[
                        colIndex - 1
                    ]?.focus();
                }
            } else if (e.key === 'ArrowRight') {
                if (
                    colIndex < 9 &&
                    e.currentTarget.selectionEnd ===
                        e.currentTarget.value.length
                ) {
                    e.preventDefault();
                    setIsBlurDisabled(true);
                    prescriptionInputRefs.current[rowIndex]?.[
                        colIndex + 1
                    ]?.focus();
                }

                //     } else if (inputValues[rowIndex].columns[colIndex] !== '') {
                //         prescriptionInputRefs.current[rowIndex]?.[colIndex + 1]?.focus();
                //         // 注释掉的代码块
                //     } else {
                //         // 如果輸入為空，顯示警告訊息
                //         Swal.fire({
                //             icon: 'warning',
                //             title: '請輸入文字',
                //             timer: 1500,
                //             showConfirmButton: false,
                //         });
                //     }
                // } else if (
                //     colIndex === 1 ||
                //     colIndex === 5 ||
                //     colIndex === 6 ||
                //     colIndex === 7
                // ) {
                //     try {
                //         await setSelectedIndex({
                //             row: rowIndex,
                //             col: colIndex + 1,
                //         });
                //         // await handleSaveAll();
                //         await prescriptionInputRefs.current[rowIndex]?.[
                //             colIndex + 1
                //         ]?.focus();
                //     } catch (error) {
                //         console.error('處理索引和保存時發生錯誤:', error);
                //         // 可以在這裡添加額外的錯誤處理邏輯
                //     }
                // } else if (colIndex === 2 || colIndex === 3) {
                //     try {
                //         await setSelectedIndex({
                //             row: rowIndex,
                //             col: colIndex + 1,
                //         });
                //         // await handleSaveAll();
                //         // handleCountGiven();
                //         await prescriptionInputRefs.current[rowIndex]?.[
                //             colIndex + 1
                //         ]?.focus();
                //     } catch (error) {
                //         console.error('處理索引、保存和計數時發生錯誤:', error);
                //         // 可以在這裡添加額外的錯誤處理邏輯
                //     }
                // } else if (colIndex === 4) {
                //     // 注释掉的代码块
                //     try {
                //         await setSelectedIndex({
                //             row: rowIndex,
                //             col: colIndex + 1,
                //         });
                //         // await handleSaveAll();
                //         await prescriptionInputRefs.current[rowIndex]?.[
                //             colIndex + 1
                //         ]?.focus();
                //         // handleCountGiven();
                //     } catch (error) {
                //         console.error(
                //             '處理索引、保存和計數時發生錯誤:',
                //             error
                //         );
                //         // 可以在這裡添加額外的錯誤處理邏輯
                //     }
                // } else if (colIndex === 8) {
                //     // 處理特殊標記選擇，類似於 Mtable
                //     const selectedValue =
                //         inputValues[rowIndex].columns[colIndex];
                //     if (selectedValue) {
                //         try {
                //             // 注释掉的代码块
                //             await prescriptionInputRefs.current[rowIndex]?.[
                //                 colIndex + 1
                //             ]?.focus();
                //         } catch (error) {
                //             console.error('處理特殊標記選擇時發生錯誤:', error);
                //             // 可以在這裡添加額外的錯誤處理邏輯
                //         }
                //     }
                // } else if (colIndex === 9) {
                //     try {
                //         if (rowIndex === inputValues.length - 1) {
                //             // await onAddRow(rowIndex);
                //             // setFocusNextRow(true);
                //             // await handleSaveAll();
                //             await prescriptionInputRefs.current[rowIndex]?.[
                //                 colIndex + 1
                //             ]?.focus();
                //         } else {
                //             // setFocusNextRow(true);
                //             // await handleSaveAll();
                //             await prescriptionInputRefs.current[rowIndex]?.[
                //                 colIndex + 1
                //             ]?.focus();
                //         }
                //     } catch (error) {
                //         console.error(
                //             '處理新行添加或焦點移動時發生錯誤:',
                //             error
                //         );
                //         // 可以在這裡添加額外的錯誤處理邏輯
                //     }
                // }
            }
        }
    };

    // useEffect(() => {
    //     if (focusNextRow) {
    //         setTimeout(() => {
    //             setSelectedIndex({ row: rowIndex + 1, col: 0 });
    //             prescriptionInputRefs.current[rowIndex + 1]?.[0]?.focus();
    //         }, 100);
    //         setFocusNextRow(false);
    //     }
    // }, [focusNextRow, prescriptionInputRefs, rowIndex, setSelectedIndex]);

    const handleCloseBG = () => {
        setIsSearchListsStatus(false);
    };

    const handleSaveAll = () => {
        const updatedPrescription = { ...item };
        const columnsToUpdate = inputValues[rowIndex].columns;

        columnsToUpdate.forEach((value, index) => {
            switch (index) {
                case 0:
                    updatedPrescription.name_tw = value;
                    break;
                case 1:
                    updatedPrescription.qty = value;
                    break;
                case 2:
                    const drugFreqItem = drugFreqLists.find(
                        item => item.code === value.toUpperCase()
                    );
                    if (drugFreqItem) {
                        updatedPrescription.freq = drugFreqItem.data_id;
                    }
                    updatedPrescription.freq_custom_code =
                        value.toLocaleUpperCase();
                    break;
                case 3:
                    updatedPrescription.dose_day = value;
                    break;
                case 4:
                    updatedPrescription.qty_in_day = value;
                    break;
                case 5:
                    updatedPrescription.total_qty = value;
                    break;
                case 6:
                    updatedPrescription.given_qty = value;
                    break;
                case 7:
                    updatedPrescription.self_price = value;
                    break;
                case 8:
                    updatedPrescription.special_mark = value;
                    break;
                case 9:
                    updatedPrescription.memo = value;
                    break;
            }
        });

        console.log('Saving all columns for row:', rowIndex);
        updatePrescription(
            rowIndex,
            updatedPrescription,
            inputValues[rowIndex].columns
        );
    };
    // const handleCountGiven = async () => {
    //     const freq_custom_code = inputValues[rowIndex]?.columns?.[4];
    //     const days = parseFloat(inputValues[rowIndex]?.columns?.[3]);
    //     const times = parseFloat(inputValues[rowIndex]?.columns?.[2]);
    //     const totalQty = parseFloat(inputValues[rowIndex]?.columns?.[5]);
    //     const givenQty = parseFloat(inputValues[rowIndex]?.columns?.[6]);

    //     console.log(
    //         times,
    //         '2',
    //         days,
    //         '3',
    //         freq_custom_code,
    //         '4',
    //         totalQty,
    //         '5',
    //         givenQty,
    //         '6'
    //     );
    //     console.log('有進這個算錢func');

    //     const calculateValues = async () => {
    //         if (freq_custom_code && drugFreqLists && drugFreqLists.length > 0) {
    //             const matchedFreqId = drugFreqLists.find(
    //                 list => list.code === freq_custom_code
    //             )?.data_id;

    //             console.log(matchedFreqId, 'matchedFreqId');

    //             if (matchedFreqId) {
    //                 const result = await callDrugFreqSpecService(
    //                     userInfo.token!,
    //                     { id: matchedFreqId }
    //                 );

    //                 if (result.result === 'success') {
    //                     const totalDosage = handleFrequencyMatching(
    //                         days || 1, // 默認為 1 天
    //                         result.info.times || 1, // 默認為 1 次
    //                         result.info.transfer || 1, // 默認為 1
    //                         result.info.x ?? 1, // 默認為 1
    //                         times || 1 // 默認為 1 次
    //                     );

    //                     if (totalDosage !== null) {
    //                         const updatedInputValues = [...inputValues];

    //                         // 根據已知的值計算缺失的值
    //                         if (!isNaN(totalQty)) {
    //                             console.log('進第一層');
    //                             // 使用 totalQty 計算缺失的值
    //                             if (isNaN(days)) {
    //                                 updatedInputValues[rowIndex].columns[3] = (
    //                                     totalQty / (times || 1)
    //                                 ).toFixed(2);
    //                             }
    //                             if (isNaN(times)) {
    //                                 updatedInputValues[rowIndex].columns[2] = (
    //                                     totalQty / (days || 1)
    //                                 ).toFixed(2);
    //                             }
    //                         } else if (!isNaN(givenQty)) {
    //                             console.log('進第2層');
    //                             // 使用 givenQty 計算缺失的值
    //                             if (isNaN(days)) {
    //                                 updatedInputValues[rowIndex].columns[3] = (
    //                                     givenQty / (times || 1)
    //                                 ).toFixed(2);
    //                             }
    //                             if (isNaN(times)) {
    //                                 updatedInputValues[rowIndex].columns[2] = (
    //                                     givenQty / (days || 1)
    //                                 ).toFixed(2);
    //                             }
    //                         } else if (!isNaN(days) && !isNaN(times)) {
    //                             console.log('進第3層');
    //                             // 使用 days 和 times 計算 totalQty 和 givenQty
    //                             console.log(totalDosage, '這邊應該多少');
    //                             // updatedInputValues[rowIndex].columns[5] = formatPrice(totalDosage.toString());
    //                             // updatedInputValues[rowIndex].columns[6] = formatPrice(totalDosage.toString());
    //                         }
    //                         updatedInputValues[rowIndex].columns[5] =
    //                             formatPrice(totalDosage.toString());
    //                         updatedInputValues[rowIndex].columns[6] =
    //                             formatPrice(totalDosage.toString());

    //                         updateInputValues(updatedInputValues);
    //                     }
    //                 }
    //             }
    //         }
    //     };

    //     await calculateValues();
    // };

    const [colLastIndex, setLastColIndex] = useState(-1);
    const [isNewPrescriptionCalculated, setIsNewPrescriptionCalculated] =
        useState(false);
    const handleCountGiven = async () => {
        if (isBlurDisabled) {
            return;
        }
        const times = parseFloat(inputValues[rowIndex]?.columns?.[1]);
        const freq_custom_code =
            inputValues[rowIndex]?.columns?.[2].toLocaleUpperCase();
        const days = parseFloat(inputValues[rowIndex]?.columns?.[3]);
        if (times && freq_custom_code && days) {
            const performFrequencyMatching = async () => {
                if (
                    freq_custom_code &&
                    days &&
                    times &&
                    drugFreqLists &&
                    drugFreqLists.length > 0
                ) {
                    const matchedFreqId = drugFreqLists.find(
                        list => list.code === freq_custom_code
                    )?.data_id;

                    if (matchedFreqId) {
                        try {
                            const result = await callDrugFreqSpecService(
                                userInfo.token!,
                                { id: matchedFreqId }
                            );

                            if (result.result === 'success') {
                                const totalDosage = handleFrequencyMatching(
                                    days,
                                    result.info.times,
                                    result.info.transfer,
                                    result.info.x ?? 1,
                                    times
                                );
                                console.log(totalDosage, '總量計算成果');
                                if (totalDosage !== null) {
                                    const updatedInputValues = [...inputValues]; // 创建一个浅拷贝
                                    const updatedPrescriptionList = [
                                        ...prescriptionList,
                                    ]; // 创建处方列表的浅拷贝

                                    // 确保仅更新当前的 rowIndex
                                    const updatedRow = {
                                        ...updatedInputValues[rowIndex],
                                    };
                                    const updatedPrescription = {
                                        ...updatedPrescriptionList[rowIndex],
                                    };

                                    // 更新 inputValues
                                    updatedRow.columns[4] = formatPrice(
                                        totalDosage.dailyAmount.toString()
                                    );
                                    updatedRow.columns[5] = formatPrice(
                                        totalDosage.total.toString()
                                    );
                                    updatedRow.columns[6] = formatPrice(
                                        totalDosage.total.toString()
                                    );

                                    // 更新 prescriptionList 中的对应字段
                                    updatedPrescription.qty = times.toString();
                                    updatedPrescription.qty_in_day =
                                        updatedRow.columns[4];
                                    updatedPrescription.total_qty =
                                        updatedRow.columns[5];
                                    updatedPrescription.freq_custom_code =
                                        freq_custom_code;
                                    updatedPrescription.freq =
                                        drugFreqLists.find(
                                            (item: DrugFreqListsProps) =>
                                                item.code === freq_custom_code
                                        )?.data_id || '';
                                    updatedPrescription.total_qty =
                                        updatedRow.columns[6];
                                    updatedPrescription.given_qty =
                                        updatedRow.columns[6];

                                    console.log(
                                        updatedRow,
                                        updatedPrescription,
                                        '要更新前'
                                    );
                                    // 用更新后的行替换特定的 rowIndex
                                    updatedInputValues[rowIndex] = updatedRow;
                                    updatedPrescriptionList[rowIndex] =
                                        updatedPrescription;

                                    // 更新状态
                                    updateInputValues(updatedInputValues);
                                    setPrescriptionList(
                                        updatedPrescriptionList
                                    );

                                    console.log('算完');
                                }
                            }
                        } catch (error) {
                            console.error(
                                '調用藥物頻率規格服務時發生錯誤：',
                                error
                            );
                        }
                    }
                }
            };
            if (times && freq_custom_code && days) {
                console.log('近來幾次');
                await performFrequencyMatching();
                await handleSaveAll();
                setIsChanged(false);
            }
        }
    };
    const addRow = async () => {
        console.log('開啟下一行', rowIndex);
        await onAddRow(rowIndex + 1);
        return true;
    };

    useEffect(() => {
        console.log('近來幾次 開關次數', isNewPrescriptionCalculated);
        if (isNewPrescriptionCalculated) {
            console.log('算');
            handleCountGiven().then(() => {
                const isLastRow = rowIndex === prescriptionList.length - 1;
                if (isLastRow) {
                    addRow().then(() => {
                        setTimeout(() => {
                            prescriptionInputRefs.current[
                                rowIndex + 1
                            ]?.[0]?.focus();
                        }, 0);
                    });
                } else {
                    setTimeout(() => {
                        prescriptionInputRefs.current[
                            rowIndex + 1
                        ]?.[0]?.focus();
                    }, 0);
                }

                setIsNewPrescriptionCalculated(false);
            });
        }
    }, [isNewPrescriptionCalculated]);

    const [isChanged, setIsChanged] = useState(false);
    const formatPrice = (price: string): string => {
        if (!price.includes('.')) {
            return price + '.00';
        }
        const [integerPart, decimalPart] = price.split('.');
        if (decimalPart.length === 1) {
            return price + '0';
        }
        return price;
    };

    // useEffect(() => {
    //     // 這個 useEffect 主要用於處理處方的價格計算和更新

    //     // 獲取第6列的值（給藥量）
    //     const column6Value = inputValues[rowIndex]?.columns?.[6];
    //     if (column6Value) {
    //         // 計算成本總價
    //         const cost = parseFloat(item.cost) || 0;
    //         const column6Number = parseFloat(column6Value) || 0;
    //         const costTotal = cost * column6Number;

    //         // 更新成本價格
    //         updateCostPrice(costTotal.toString());

    //         // 初始化保險價格和自費價格
    //         let newTotalInsurancePrice = 0;
    //         let newTotalSelfFundedPrice = 0;
    //         const updatedPrescriptionList = [...prescriptionList];

    //         // 遍歷所有輸入值，計算總保險價格和總自費價格
    //         inputValues.forEach((row, index) => {
    //             const col6Val = parseFloat(row.columns[6]) || 0;

    //             const prescription = prescriptionList[index];
    //             if (prescription && prescription.data_id) {
    //                 const rowDrugPrice = parseFloat(prescription.drug_price?.toString() || '0');
    //                 const rowSelfPrice = parseFloat(prescription.self_price?.toString() || '0');

    //                 // 根據藥品價格類型更新總價
    //                 if (rowDrugPrice) {
    //                     newTotalInsurancePrice += rowDrugPrice * col6Val;
    //                 } else {
    //                     newTotalSelfFundedPrice += rowSelfPrice * col6Val;
    //                 }

    //                 const qty = parseFloat(prescription.dose || '0');

    //                 // 更新處方列表中的數量相關字段
    //                 updatedPrescriptionList[index] = {
    //                     ...updatedPrescriptionList[index],
    //                     total_qty: col6Val.toFixed(2),
    //                     given_qty: col6Val.toFixed(2),
    //                     qty: qty.toString(),
    //                 };
    //             }
    //         });

    //         // 更新保險價格和自費價格
    //         updateInsurancePrice(newTotalInsurancePrice.toString());
    //         updateSelfFundedPrice(newTotalSelfFundedPrice.toString());

    //         // 檢查輸入值是否有變化，以避免無限循環
    //         if (JSON.stringify(previousValuesRef.current) !== JSON.stringify(inputValues)) {
    //             setPrescriptionList(updatedPrescriptionList);
    //             previousValuesRef.current = inputValues;
    //         }
    //     }
    // }, [isChanged])

    // 一開始強制算一次
    // useEffect(() => {
    //     if (
    //         inputValues &&
    //         inputValues[rowIndex] &&
    //         inputValues[rowIndex].columns[1] &&
    //         inputValues[rowIndex].columns[2] &&
    //         inputValues[rowIndex].columns[3] &&
    //         !inputValues[rowIndex].columns[5] &&
    //         !inputValues[rowIndex].columns[6] &&
    //         isChanged
    //     ) {
    //         handleCountGiven();
    //     }
    // }, [isChanged, inputValues]);

    // 處理給藥量和總量的計算和同步
    const handleGivenAndTotal = () => {
        // 先計算給藥量
        handleCountGiven();

        // 如果總量有值但給藥量沒有，將總量的值賦給給藥量
        if (
            inputValues[rowIndex].columns[5] &&
            !inputValues[rowIndex].columns[6]
        ) {
            inputValues[rowIndex].columns[6] = inputValues[rowIndex].columns[5];
        }
        // 如果給藥量有值但總量沒有，將給藥量的值賦給總量
        else if (
            inputValues[rowIndex].columns[6] &&
            !inputValues[rowIndex].columns[5]
        ) {
            inputValues[rowIndex].columns[5] = inputValues[rowIndex].columns[6];
        }
        // 注意：如果兩者都有值或都沒有值，不做任何改變
    };
    const updateInputValuesFromPrescription = (
        rowIndex: number,
        prescription: PrescriptionProps,
        updatedInputValues: any[]
    ) => {
        console.log('要更新幾次', rowIndex, '第幾蘭', prescription, '什麼藥');

        // 初始化新行
        if (!updatedInputValues[rowIndex]) {
            updatedInputValues[rowIndex] = {
                columns: Array(10).fill(''),
            };
        }

        for (let colIndex = 0; colIndex < 10; colIndex++) {
            switch (colIndex) {
                case 0:
                    updatedInputValues[rowIndex].columns[colIndex] =
                        prescription.name_tw || prescription.name_en || '';
                    break;
                case 1:
                    updatedInputValues[rowIndex].columns[colIndex] =
                        prescription.qty || '';
                    break;
                case 2:
                    updatedInputValues[rowIndex].columns[colIndex] =
                        prescription.freq_custom_code || '';
                    break;
                case 3:
                    updatedInputValues[rowIndex].columns[colIndex] =
                        prescription.dose_day || '';
                    break;
                case 4:
                    updatedInputValues[rowIndex].columns[colIndex] =
                        prescription.qty_in_day || '';
                    break;
                case 5:
                    updatedInputValues[rowIndex].columns[colIndex] =
                        prescription.total_qty || '';
                    break;
                case 6:
                    updatedInputValues[rowIndex].columns[colIndex] =
                        prescription.given_qty || '';
                    break;
                case 7:
                    updatedInputValues[rowIndex].columns[colIndex] =
                        prescription.self_price || '';
                    break;
                case 8:
                    updatedInputValues[rowIndex].columns[colIndex] =
                        prescription.special_mark || '';
                    break;
                case 9:
                    updatedInputValues[rowIndex].columns[colIndex] =
                        prescription.memo || '';
                    break;
                default:
                    break;
            }
        }

        console.log('新增的回圈新看', updatedInputValues);
    };

    // 歷史
    useEffect(() => {
        if (prescriptionList && isCopyDrugFromHistoryStatus) {
            console.log('用物件改畫面', prescriptionList);

            // 先準備足夠的空行
            let updatedInputValues = [...inputValues];
            while (updatedInputValues.length < prescriptionList.length) {
                updatedInputValues.push({ columns: Array(10).fill('') });
            }

            // 逐行更新狀態
            prescriptionList.forEach((prescription, index) => {
                updateInputValuesFromPrescription(
                    index,
                    prescription,
                    updatedInputValues
                );
            });

            console.log(updatedInputValues, '要更新的內容');
            // 統一更新狀態
            updateInputValues(updatedInputValues);
            setIsCopyDrugFromHistoryStatus(false);
        }
    }, [isCopyDrugFromHistoryStatus]);

    const handleBlur = async (colIndex: number) => {
        if (isBlurDisabled) {
            return;
        }

        setIsChanged(true);
        setLastColIndex(colIndex);

        if (inputValues[rowIndex].columns[colIndex]) {
            if (colIndex === 0) {
                if (prescriptionList[rowIndex].data_id) {
                    // 如果 data_id 已存在，直接处理
                    // ...
                } else {
                    const result = await postDrugsForTreatmentAPI(
                        inputValues[rowIndex].columns[colIndex],
                        rowIndex,
                        colIndex
                    );
                    if (result && result.msg === '查無資料') {
                        return;
                    } else {
                        return;
                    }
                    // console.error('123')
                    // handleCountGiven()
                }
            } else if (
                (colIndex === 1 || colIndex === 3) &&
                !isNewPrescriptionCalculated
            ) {
                await handleCountGiven();
                await handleSaveAll();
            } else if (colIndex === 2 && !isNewPrescriptionCalculated) {
                const freqCode =
                    inputValues[rowIndex].columns[colIndex].toLocaleUpperCase();
                try {
                    const result = await callDrugFreqListsAPI();
                    if (result && result.lists) {
                        const matchingFreq = result.lists.find(
                            item => item.code === freqCode
                        );
                        if (!matchingFreq) {
                            Swal.fire({
                                icon: 'warning',
                                text: '找不到此用法',
                            });
                            handleUpdateCellValue(rowIndex, colIndex, '');
                            return;
                        } else {
                            handleCountGiven();
                            return;
                        }
                    }
                } catch (error) {
                    console.error('API 調用出錯', error);
                    handleUpdateCellValue(rowIndex, colIndex, 'Error Value');
                }
            } else {
                await handleSaveAll();
            }
        }
    };
    const callDrugFreqListsAPI = async (): Promise<{
        result: string;
        lists: DrugFreqListsProps[];
    }> => {
        try {
            const result = await callDrugFreqListsService(userInfo.token);
            console.log(result, '用藥頻率清單');
            return result;
        } catch (error) {
            console.error('頻率設定api取得異常', error);
            throw error; // Re-throw the error to be handled by the caller
        }
    };
    useEffect(()=>{
        console.log('檢查', prescriptionList)
    }, [prescriptionList])

    return (
        <Flex
            ref={node => {
                ref(node);
                drop(node);
            }}
            gap={'4px'}
            align={'center'}
            _hover={{ bg: 'gray.50' }}
        >
            <Box
                key={`drag-handle-${rowIndex}`}
                ref={el => {
                    if (!boxRefs.current[rowIndex]) {
                        boxRefs.current[rowIndex] = [];
                    }
                    boxRefs.current[rowIndex][0] = el;
                }}
                flex={'0 0 32px'}
                fontSize={'20px'}
                h={'35px'}
                textAlign={'center'}
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => {
                    setSelectedIndex({ row: rowIndex, col: 0 });
                    setIsEditing(false);
                    boxRefs.current[rowIndex][0]?.focus();
                }}
            >
                <Box
                    data-name="bloodPressure"
                    opacity={0.5}
                    _hover={{ opacity: 1, cursor: 'grab' }}
                    fontSize={'16px'}
                >
                    &#x283F;
                </Box>
            </Box>
            {Array(10)
                .fill('')
                .map((_, colIndex) => (
                    <Input
                        key={colIndex}
                        ref={el => {
                            if (!prescriptionInputRefs.current[rowIndex]) {
                                prescriptionInputRefs.current[rowIndex] = [];
                            }
                            prescriptionInputRefs.current[rowIndex][colIndex] =
                                el;
                        }}
                        value={
                            colIndex === 2
                                ? (
                                      inputValues[rowIndex]?.columns?.[
                                          colIndex
                                      ] || ''
                                  ).toUpperCase()
                                : inputValues[rowIndex]?.columns?.[colIndex] ||
                                  ''
                        }
                        onChange={e => handleChange(e, colIndex)}
                        flex={
                            colIndex === 0
                                ? '1 1 30%'
                                : colIndex === 9
                                  ? '0 0 calc(6% + 30px)'
                                  : '0 0 6%'
                        }
                        w={colIndex === 0 ? '30%' : '6%'}
                        readOnly={colIndex === 4}
                        border={colIndex === 4 ? 'none' : '1px solid #E2E8F0'}
                        fontSize={'20px'}
                        px={'4px'}
                        h={'35px'}
                        textAlign={colIndex === 0 ? 'left' : 'right'}
                        autoFocus={
                            isEditing &&
                            selectedIndex.row === rowIndex &&
                            selectedIndex.col === colIndex
                        }
                        onBlur={() => handleBlur(colIndex)}
                        // placeholder={rowIndex.toString() + colIndex.toString()}
                        onFocus={e => {
                            if (colIndex === 8) {
                                setRightSideOptionSelectStatus(
                                    RightSideOptionSelectStatus.Mtable
                                );
                            } else {
                                setRightSideOptionSelectStatus(
                                    RightSideOptionSelectStatus.MedicalRecord
                                );
                            }
                            e.target.select();
                            setSelectedIndex({
                                row: rowIndex,
                                col: colIndex,
                            });
                        }}
                        onKeyDown={e => handleKeyDown(e, colIndex)}
                    />
                ))}
            {/* <Box flex={'0 0 30px'}></Box> */}
            {isSearchListsStatus && rowIndex === selectedIndex.row && (
                <SearchResultList
                    searchResult={searchResult}
                    isLoading={isLoading}
                    resultMsg={resultMsg}
                    handleInsertPrescription={handleInsertPrescription}
                    boxMetrics={boxMetrics}
                    setIsSearchListsStatus={setIsSearchListsStatus}
                />
            )}
            {isSearchListsStatus && (
                <Flex
                    className="fixed inset-0 z-40 "
                    onClick={handleCloseBG}
                ></Flex>
            )}
        </Flex>
    );
};
