    import { Box, Text, Flex, Center, Input } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import Swal from 'sweetalert2';
import { useState, useEffect, useRef } from 'react';
import { callICD10ForTreatmentService } from 'service/ICD10ForTreatmentService';
import { useUserStore } from 'store';
import { useDxStore, DxProps, initialDxList } from 'store/TreatmentManagement';
import Loading from 'components/CustomComponents/Loading';
import { SearchResultList } from './DxICD10Components/SearchResultLists';
import { useCCPEStore } from 'store/TreatmentManagement';
import { callICD10PersonalUseSpecService } from 'service/ICD10PersonalUseService';

export interface SearchResultProps {
    data_id: string;
    short_code: string;
    name_tw: string;
    name_en: string;
    icd_code: string;
    code: string;
    original_name: string;
    attach_ccpe: string;
}
export const DxICD10 = () => {
    const { userInfo } = useUserStore();
    const [dxToggle, setDxToggle] = useState<boolean>(false);
    const { CCPEData, updateCCPEData } = useCCPEStore();
    const { dxList, updateDx, resetDx } = useDxStore();
    const [searchResult, setSearchResult] = useState<SearchResultProps[]>([]);
    const [isSearchListsStatus, setIsSearchListsStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [resultMsg, setResultMsg] = useState<string>('');
    const [dxIndex, setDxIndex] = useState<number>(-1);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [checkDxName, setCheckDxName] = useState('');

    const handleBlur = async (
        e: React.FocusEvent<HTMLInputElement>,
        dxIndex: number
    ) => {
        if (isSearchListsStatus) return; // 如果搜索列表打開，則不處理blur事件
        
        if (dxList[dxIndex].icd_code) {
            if (inputRefs.current[dxIndex + 1]) {
                inputRefs.current[dxIndex + 1]!.focus();
            }
            return;
        }
        const keyword = dxList[dxIndex].name_tw;
        if (!keyword.trim()) {
            return;
        }
        setDxIndex(dxIndex);
        setSearchResult([]);
        setIsLoading(true);
        setIsSearchListsStatus(true);
        const queryParams: {
            keyword: string;
            page: string | number;
            type: string;
        } = {
            keyword,
            page: '1',
            type: 'short_code',
        };
        try {
            const result = await callICD10ForTreatmentService(
                userInfo.token,
                queryParams
            );
            console.log(result, '診斷結果如何')
            if (result.result === 'success') {
                setIsLoading(false);
                if (result.lists.length === 1) {
                    const dxIncludes = dxList.some(
                        dx => dx.name_tw === result.lists[0].name_tw
                    );
                    if (dxIncludes) {
                        updateDx(dxIndex, {
                            ...initialDxList,
                        });
                        Swal.fire({
                            icon: 'warning',
                            title: `重複輸入`,
                            text: `${result.lists[0].name_tw} `,
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else {
                        updateDx(dxIndex, {
                            ...initialDxList,
                            name_tw: result.lists[0].name_tw,
                            icd_code: result.lists[0].icd_code,
                            data_id: result.lists[0].data_id,
                            name_en: result.lists[0].name_en,
                            original_name:
                                result.lists[0].original_name,
                            code: result.lists[0].code,
                            short_code: result.lists[0].short_code,
                        });
                        if (inputRefs.current[dxIndex + 1]) {
                            inputRefs.current[dxIndex + 1]!.focus();
                        }
                    }
                    setIsSearchListsStatus(false);
                    setSearchResult([]);
                } else {
                    setSearchResult(result.lists);
                }
            } else {
                setResultMsg(result.msg);
            }
        } catch (error) {
            console.error('Error fetching ICD10 data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        if(isSearchListsStatus) return
        const { value } = e.target;
        if (value === '') {
            updateDx(index, { ...initialDxList });
            return;
        }
        const updatedDx = [...dxList];
        updatedDx[index] = { ...updatedDx[index], name_tw: value };
        updateDx(index, updatedDx[index]);
    };

    const handleInsertDx = async (SearchResultIndex: number) => {
        console.log(SearchResultIndex, '多少', searchResult)
     
        if (searchResult[SearchResultIndex].attach_ccpe === 'Y') {
            try {
                const SpecResult = await callICD10PersonalUseSpecAPI(
                    searchResult[SearchResultIndex].data_id
                );
                console.log('SpecResult: ', SpecResult);
                const allIcdCodesEmpty = dxList.every(dx => dx.icd_code === '');
                if (allIcdCodesEmpty && CCPEData.cc === "") {
                    updateCCPEData({ ...CCPEData, cc: SpecResult.subjective });
                    setIsSearchListsStatus(false);
                } else {
                    Swal.fire({
                        icon: 'question',
                        title: '此病名有預設帶入CCPE',
                        showConfirmButton: true,
                        confirmButtonText: '接在目前症狀後端',
                        showDenyButton: true,
                        denyButtonText: '覆蓋現有資料',
                        showCancelButton: true,
                        cancelButtonText: '不帶入任何資料',
                    }).then(result => {
                        if (result.isConfirmed) {
                            if (CCPEData.cc === '') {
                                updateCCPEData({
                                    ...CCPEData,
                                    cc: SpecResult.subjective,
                                });
                            } else {
                                updateCCPEData({
                                    ...CCPEData,
                                    cc:
                                        CCPEData.cc +
                                        ',' +
                                        SpecResult.subjective,
                                });
                            }
                        } else if (result.isDenied) {
                            updateCCPEData({
                                ...CCPEData,
                                cc: SpecResult.subjective,
                            });
                        } else {
                            return;
                        }
                    });
                }
            } catch (error) {
                console.error('錯誤：無法獲取或處理ICD10個人使用規格', error);
                Swal.fire({
                    icon: 'error',
                    title: '發生錯誤',
                    text: '無法獲取或處理ICD10個人使用規格，請稍後再試。',
                });
            }
        }

        const dxIncludes = dxList.some(
            dx => dx.name_tw === searchResult[SearchResultIndex].name_tw
        );
        if (dxIncludes) {
            updateDx(dxIndex, {
                ...initialDxList,
            });
            Swal.fire({
                icon: 'warning',
                title: `重複輸入`,
                text: `${searchResult[SearchResultIndex].name_tw} `,
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            updateDx(dxIndex, {
                ...initialDxList,
                data_id: searchResult[SearchResultIndex].data_id,
                icd_code: searchResult[SearchResultIndex].icd_code,
                name_tw: searchResult[SearchResultIndex].name_tw,
                name_en: searchResult[SearchResultIndex].name_en,
                original_name: searchResult[SearchResultIndex].original_name,
                code: searchResult[SearchResultIndex].code,
                short_code: searchResult[SearchResultIndex].short_code,
            });
            
            // Move to the next input field
            if (inputRefs.current[dxIndex + 1]) {
                inputRefs.current[dxIndex + 1]!.focus();
            }
        }

        setIsSearchListsStatus(false);
        setSearchResult([]);
    };

    const handleCancelInsert = () => {
        setIsSearchListsStatus(false);
        setSearchResult([]);
    };

    useEffect(() => {
        setDxToggle(false);
        resetDx();
    }, [resetDx]);

    useEffect(() => {
        console.log('searchResult: ', searchResult);
    }, [searchResult]);

    const callICD10PersonalUseSpecAPI = async (icd_uid: string) => {
        try {
            const result = await callICD10PersonalUseSpecService(
                userInfo.token,
                icd_uid
            );
            if (result.result === 'success') {
                return result.info;
            } else {
                throw new Error('API 調用失敗');
            }
        } catch (error) {
            console.error('調用 ICD10 個人使用規格 API 時發生錯誤:', error);
            // 可以在這裡添加錯誤處理邏輯，例如顯示錯誤消息給用戶
            return null;
        }
    };
    const handleCloseBG = () => {
        setIsSearchListsStatus(false);
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (isSearchListsStatus) {
            return;
        }

        const rowCount = 2;
        const colCount = 3;
        const totalInputs = rowCount * colCount;

        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                if (index >= colCount) {
                    inputRefs.current[index - colCount]?.focus();
                }
                break;
            case 'ArrowDown':
                e.preventDefault();
                if (index < totalInputs - colCount) {
                    inputRefs.current[index + colCount]?.focus();
                }
                break;
            case 'ArrowLeft':
                e.preventDefault();
                if (index % colCount !== 0) {
                    inputRefs.current[index - 1]?.focus();
                    inputRefs.current[index - 1]?.select();
                }
                break;
            case 'ArrowRight':
                e.preventDefault();
                if ((index + 1) % colCount !== 0 && index < totalInputs - 1) {
                    inputRefs.current[index + 1]?.focus();
                    inputRefs.current[index + 1]?.select();
                }
                break;
            case 'Enter':
                e.preventDefault();
                handleBlur(e as any, index);
                break;
            case 'Tab':
                if (!e.shiftKey) {
                    handleBlur(e as any, index);
                }
                break;
        }
    };

    return (
        <>
            <Box pos={'relative'}>
                <Text textStyle={'semiTitle'} textColor={'#646B48'}>
                    Dx（ICD10）
                </Text>
                <Flex
                    gap={'4px'}
                    h={dxToggle ? '88px' : '40px'}
                    overflowY={'hidden'}
                    transition={'height 0.3s'}
                    pr={'40px'}
                >
                    <Flex
                        gap={'8px'}
                        flexWrap={'wrap'}
                        id="dxInputs"
                        flex={'1 1 auto'}
                    >
                        {dxList &&
                            dxList.map((dx: DxProps, index: number) => (
                                <Input
                                    key={index}
                                    flex={'1 0 calc(100% / 3 - 16px)'}
                                    bg={index === 0 ? '#D08C60' : '#fff'}
                                    ref={el => (inputRefs.current[index] = el)}
                                    textColor={index === 0 ? '#fff' : '#000'}
                                    onFocus={e => {
                                        e.target.select();
                                        if (dxList[index]) {
                                            setCheckDxName(
                                                dxList[index].name_tw
                                            );
                                        }
                                    }}
                                    placeholder={`Dx${index + 1}`}
                                    _placeholder={{
                                        color: index === 0 ? '#ccc' : '#aaa',
                                    }}
                                    name={`dx${index + 1}`}
                                    fontSize={'18px'}
                                    boxShadow={
                                        '4px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;'
                                    }
                                    tabIndex={index + 1}
                                    onBlur={e => handleBlur(e, index)}
                                    value={dx.name_tw}
                                    onChange={e => handleChange(e, index)}
                                    onKeyDown={e => handleKeyDown(e, index)}
                                    autoComplete="off"
                                />
                            ))}
                    </Flex>

                    <Center
                        pos={'absolute'}
                        right={0}
                        bottom={0}
                        w={'40px'}
                        h={'40px'}
                        onClick={() => {
                            setDxToggle(prev => !prev);
                        }}
                        transform={
                            dxToggle ? 'rotateX(0deg)' : 'rotateX(180deg)'
                        }
                        opacity={0.5}
                        _hover={{
                            cursor: 'pointer',
                            opacity: '1',
                        }}
                    >
                        <HeroIcons name="chevron-down"></HeroIcons>
                    </Center>
                </Flex>
                {isSearchListsStatus && (
                    <Box
                        pos={'absolute'}
                        top={'100%'}
                        left={0}
                        transform={'translateY(8px)'}
                        w={'100%'}
                        zIndex={20}
                        rounded={'lg'}
                        border={'2px solid #646B48'}
                        bg={'#fff'}
                        minH={`calc( 2 * 33px)`}
                        maxH={`calc(34px + 7 * 33px)`}
                        overflowY={'scroll'}
                        minW={`calc(80px + 200px + 200px + 80px + 140px + 30px + 30px)`}
                    >
                        <SearchResultList
                            searchResult={searchResult}
                            isLoading={isLoading}
                            resultMsg={resultMsg}
                            handleInsertDx={handleInsertDx}
                            setIsSearchListsStatus={setIsSearchListsStatus}
                        />
                    </Box>
                )}
                 {isSearchListsStatus && (
                <Flex
                    className="fixed inset-0 z-10 "
                    onClick={handleCloseBG}
                />
                )}
            </Box>
        </>
    );
};
