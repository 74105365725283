import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { BasicInfo } from './BasicInfo';
import { DxList } from './DxList';
import { RxList } from './RxList';
import { Points } from './Points';
import { Others } from './Others';

export const Details = () => {
    return (
        <Box>
            <BasicInfo />

            <DxList />

            <RxList />

            <Flex>
                <Points />
                <Others />
            </Flex>
        </Box>
    );
};
