import {
    Box,
    Flex,
    Text,
    Heading,
    Input,
    Button,
    Tag,
    Tabs,
    Tab,
    TabList,
    TabIndicator,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react';
import { useEffect, useState, ChangeEvent } from 'react';
import { useShowReadVHICStatus } from 'contexts/ShowReadVHICStatusContext';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';

import {EssentialPatientInformation} from '../EssentialPatientInformation';
import CurrentVisitInformation from './Components/VisitInformation';
import { VitalSigns } from './Components/VitalSigns';

//store
import { usePatientInfoStore } from 'store/Registration';

function CurrentRegistered() {
    const { showReadVHICStatus, setShowReadVHICStatus } =
        useShowReadVHICStatus();
    const { patientInfo } = usePatientInfoStore();
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    return (
        <>
            <Flex direction={'column'} gap={'10px'} w={'100%'}>
                <EssentialPatientInformation />
                <Flex gap={'8px'}>
                    <CurrentVisitInformation />
                    <VitalSigns />
                </Flex>
            </Flex>
        </>
    );
}
export default CurrentRegistered;
