import apiService from './apiService';
import axiosInstance from './axiosInstance';




// 取得班表

export const callClinicScheduleService = async (
    token: string,
    data: string
)=>{
    // console.log(data, '先看瘩')
    const queryParams = data ? {data } :undefined
    return apiService<string, any>({
        method: 'GET',
        path:'/api/clinic_schedule',
        token,
        data: queryParams 
    })
}

// export const callClinicScheduleService = async (
//     token: string,
//     date: string
// ) => {
//     try {
//         const queryParams = { date };
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         const response = await axios.get('/api/clinic_schedule', {
//             headers,
//             params: queryParams,
//         });
//         const result = response.data;

//         console.log('callClinicScheduleService: ', result);

//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };

export interface updatedDataProps {
    start_date: string;
    if_general: string;
    shift_uid: string;
    clinic_room_uid: string;
    subject_code?: string;
    medical_staff_uid: string;
    seq_setting_uid?: string;
}
// 設定班表
export const postClinicScheduleService = async (
    token: string,
    updatedData: updatedDataProps
) => {
    return apiService<updatedDataProps, any>({
        method: 'POST',
        path: '/api/clinic_schedule',
        token,
        data: updatedData
    })
}
// export const postClinicScheduleService = async (
//     token: string,
//     updatedData: updatedDataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);

//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         console.log(requestBody, 'requestBody service印的');

//         const response = await axiosInstance.post('/api/clinic_schedule', requestBody, {
//             headers,
//         });

//         const result = response.data;

//         console.log(result, 'postClinicScheduleService API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };





// 刪除特定班表
export const deleteClinicScheduleService = async (
    token: string,
    data: object 
) => {
    return apiService<undefined, any>({
        method: 'DELETE',
        path: `/api/clinic_schedule`,
        token,
        data: data
    })
}
// export const deleteClinicScheduleService = async (
//     token: string,
//     dataId: string
// ) => {
//     try {
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const url = `/api/clinic_schedule?id=${dataId}`;

//         const response = await axios.delete(url, {
//             headers,
//         });

//         const result = response.data;

//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
