export const ROCStringyyyMMddHHmmssToDateyyyyMMddHHmmss = (rocString: string): string => {
    if (rocString.length !== 13) {
        throw new Error('Invalid ROC date string format. Expected 13 characters.');
    }

    const rocYear = parseInt(rocString.substring(0, 3), 10);
    const month = rocString.substring(3, 5);
    const day = rocString.substring(5, 7);
    const time = rocString.substring(7);

    const adYear = rocYear + 1911;

    return `${adYear}${month}${day}${time}`;
};
