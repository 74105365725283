import React, { useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import {
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from '@chakra-ui/react';

export const ReportTable = () => {
    return (
        <>
            <Box p={'8px 16px'} rounded={'lg'} bg={'#F5F5F5'} minH={'468px'}>
                <Text textStyle="semiTitle">申報列印</Text>
                <TableContainer>
                    <Table>
                        <Thead bg={'#D9D9D9'}>
                            <Td>
                                <Text fontSize={'md'} textStyle={'semiTitle'}>
                                    病歷
                                </Text>
                            </Td>
                            <Td>
                                <Text fontSize={'md'} textStyle={'semiTitle'}>
                                    案件
                                </Text>
                            </Td>
                            <Td>
                                <Text fontSize={'md'} textStyle={'semiTitle'}>
                                    流水號
                                </Text>
                            </Td>
                            <Td>
                                <Text fontSize={'md'} textStyle={'semiTitle'}>
                                    姓名
                                </Text>
                            </Td>
                            <Td>
                                <Text fontSize={'md'} textStyle={'semiTitle'}>
                                    刪
                                </Text>
                            </Td>
                            <Td>
                                <Text fontSize={'md'} textStyle={'semiTitle'}>
                                    預覽
                                </Text>
                            </Td>
                        </Thead>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
};
