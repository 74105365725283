import React from 'react';
import { Box, Flex, Center, Text, Button } from '@chakra-ui/react';
import { InsetShadowBox } from 'style/chakraStyles';

import { SideMenu } from './SideMenu';
import { PatientInfo } from '../PatientInfo';
import { MedicalHistoryList } from './MedicalHistoryList';

export const PrintProofAndReceipt = () => {
    return (
        <>
            <Box sx={InsetShadowBox} w={'100%'}>
                <Flex h={'100%'} gap={'8px'}>
                    <SideMenu />
                    <Flex direction={'column'} gap={'8px'} w={'100%'}>
                        <PatientInfo />
                        <MedicalHistoryList />
                    </Flex>
                </Flex>
            </Box>
        </>
    );
};
