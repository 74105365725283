import {create} from 'zustand';

export interface DxProps {
    data_id: string;
    short_code: string;
    icd_code: string;
    code: string;
    name_en: string;
    name_tw: string;
    original_name: string;
    attach_ccpe: string; 
}

interface DxStore {
    dxList: DxProps[];
    updateDx: (index: number, diagnosis: DxProps) => void;
    appendDx: (diagnosis: DxProps) => void;
    updateDxList: (newDxList: DxProps[]) => void;
    resetDx: () => void;
}

export const initialDxList: DxProps = {
    data_id: '',
    short_code: '',
    icd_code: '',
    code: '',
    name_en: '',
    name_tw: '',
    original_name: '',
    attach_ccpe: ''
};

export const useDxStore = create<DxStore>((set) => ({
    dxList: Array(6).fill(initialDxList),
    updateDx: (index, diagnosis) =>
        set((state) => {
            const newDxList = [...state.dxList];
            newDxList[index] = diagnosis;
            return { dxList: newDxList };
        }),
    updateDxList: (newDxList) =>
        set(() => ({
            dxList: newDxList
    })),
    appendDx: (diagnosis) =>
        set((state) => ({
            dxList: [...state.dxList, diagnosis] // 追加模式
        })),

    resetDx: () => set({ dxList: Array(6).fill(initialDxList) }),
}));

export default useDxStore;
