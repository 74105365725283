import {
    Box,
    Flex,
    Text,
    Heading,
    Input,
    Button,
    Tag,
    Tabs,
    Tab,
    TabList,
    TabIndicator,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react';
import { useEffect, ChangeEvent } from 'react';
import { useShowReadVHICStatus } from 'contexts/ShowReadVHICStatusContext';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';
import Swal from 'sweetalert2';
import Loading from 'components/CustomComponents/Loading';

import React, { Suspense, useState } from 'react';

// import BasicDataSettingMainInformationComponent from './MainInformation';
// import BasicDataSettingOtherInformationComponent from './OtherInformation';
// import BasicDataSettingRelatedHistoryComponent from './RelatedHistory';
// import BasicDataSettingDailyHabitsComponent from './DailyHabits';
import { useRegisteredFlowStatus } from 'contexts/RegisteredFlowStatusContext';

// store
import {usePatientInfoStore} from 'store/Registration';
// API
import { postPatientInfoService } from 'service/patientInfoService';
import { useUserStore } from 'store';

const BasicDataSettingMainInformationComponent = React.lazy(
    () => import('./MainInformation')
);
const BasicDataSettingOtherInformationComponent = React.lazy(
    () => import('./OtherInformation')
);
const BasicDataSettingRelatedHistoryComponent = React.lazy(
    () => import('./RelatedHistory')
);
const BasicDataSettingDailyHabitsComponent = React.lazy(
    () => import('./DailyHabits')
);
function BasicDataSetting() {
    const {userInfo} = useUserStore()
    const { setRegisteredFlowStatus, setShowPatientManagementStructureStatus } =
        useRegisteredFlowStatus();
    const { showReadVHICStatus, setShowReadVHICStatus } =
        useShowReadVHICStatus();

    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
    const [basicSettingEditStatus, setBasicSettingEditStatus] =
        useState<boolean>(true);
    const [tabIndex, setTabIndex] = useState<number>(0);

    const [Component, setComponent] =
        useState<React.ComponentType<string> | null>(null);

    const handleChangeTabIndex = (index: number) => {
        setBasicSettingEditStatus(true);
        setTabIndex(index);

        // switch(index) {
        //     case 0:
        //         setComponent(BasicDataSettingMainInformationComponent);
        //         break;
        //     case 1:
        //         setComponent(BasicDataSettingOtherInformationComponent);
        //         break;
        //     case 2:
        //         setComponent(BasicDataSettingRelatedHistoryComponent);
        //         break;
        //     case 3:
        //         setComponent(BasicDataSettingDailyHabitsComponent);
        //         break;
        //     default:
        //         setComponent(null);
        // }
    };

    const patientInfoServiceAPI = async () => {

        if (userInfo.token) {

            if (patientInfo.info && patientInfo.info.data_id) {
                // 修改基本資料
                try {
                    const result = await postPatientInfoService(
                        userInfo.token,
                        patientInfo
                    );
                    if (result && result.result === 'success') {
                        updatePatientInfo({
                            ...patientInfo.info,
                            info: {
                                ...patientInfo.info,
                                data_id: result.data_id,
                            },
                        });
                        setRegisteredFlowStatus('second');
                    }
                } catch {
                    Swal.fire({
                        icon: 'error',
                        title: '後端服務錯誤',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                }
            } else if(patientInfo.info){
                //新增資本資料
                try {
                    const result = await postPatientInfoService(
                        userInfo.token,
                        patientInfo
                    );
                    if (result && result.result === 'success') {
                        updatePatientInfo({
                            ...patientInfo.info,
                            info: {
                                ...patientInfo.info,
                                data_id: result.data_id,
                            },
                        });
                        setRegisteredFlowStatus('second');
                    }
                } catch {
                    Swal.fire({
                        icon: 'error',
                        title: '後端服務錯誤',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                }
            }else{
                console.error('異常')
            }
        }
    };
  
    const { patientInfo, updatePatientInfo } = usePatientInfoStore();
    const handleSave = () => {
        patientInfoServiceAPI();
    };
    const handleSaveAndGoRegistration = () => {
        if (patientInfo.info.data_id) {
            patientInfoServiceAPI();
        } else if (
            patientInfo.info.birth_date &&
            patientInfo.info.name &&
            patientInfo.info.id_no &&
            patientInfo.info.if_drug_allergy
        ) {
            patientInfoServiceAPI();
        } else {
            Swal.fire({
                icon: 'warning',
                title: '請確實填寫必填的欄位',
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };
  

    return (
        <>
            <Suspense fallback={<Loading></Loading>}>
                <Tabs w={'100%'}>
                    <TabList
                        borderRadius={15}
                        bgColor={'gray.50'}
                        mb={'8px'}
                        px={'16px'}
                        py={'8px'}
                        borderBottom={'2px solid #ccc'}
                    >
                        <Tab
                            textColor={'rsPrimary.500'}
                            fontWeight={'bold'}
                            filter={'opacity(0.5)'}
                            _selected={{ filter: 'opacity(1)' }}
                            onClick={e => handleChangeTabIndex(0)}
                        >
                            主要基本資料
                        </Tab>
                        <Tab
                            textColor={'rsPrimary.500'}
                            fontWeight={'bold'}
                            filter={'opacity(0.5)'}
                            _selected={{ filter: 'opacity(1)' }}
                            onClick={e => handleChangeTabIndex(1)}
                        >
                            其他基本資料
                        </Tab>
                        <Tab
                            textColor={'rsPrimary.500'}
                            fontWeight={'bold'}
                            filter={'opacity(0.5)'}
                            _selected={{ filter: 'opacity(1)' }}
                            onClick={e => handleChangeTabIndex(2)}
                        >
                            相關歷史
                        </Tab>
                        <Tab
                            textColor={'rsPrimary.500'}
                            fontWeight={'bold'}
                            filter={'opacity(0.5)'}
                            _selected={{ filter: 'opacity(1)' }}
                            onClick={e => handleChangeTabIndex(3)}
                        >
                            生活習慣
                        </Tab>
                    </TabList>
                    <TabIndicator
                        mt="-24px"
                        height="2px"
                        bgColor="rsPrimary.500"
                    />

                    <Box
                        bgColor={'gray.50'}
                        borderRadius={15}
                        px={'16px'}
                        py={'16px'}
                        pos={'relative'}
                        borderBottom={'2px solid #ccc'}
                    >
                        {/* top Buttons */}
                        <Flex gap={'8px'}>
                            <Button
                                size={'sm'}
                                colorScheme="rsPrimary"
                                onClick={handleSaveAndGoRegistration}
                                hidden={patientInfo.info.data_id ? true : false}
                            >
                                前往掛號
                            </Button>
                            <Button
                                hidden={patientInfo.info.data_id ? false : true}
                                size={'sm'}
                                colorScheme="rsSecondary"
                                onClick={handleSave}
                            >
                                儲存
                            </Button>
                        </Flex>
                        {/* <TabPanels overflowY={'scroll'} mt={'16px'} h={`${window.innerHeight - 257}px`}>
                        <TabPanel pt={0}>
                            {tabIndex === 0 && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <BasicDataSettingMainInformationComponent />
                            </Suspense>
                            )}
                        </TabPanel>
                        <TabPanel pt={0}>
                            {tabIndex === 1 && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <BasicDataSettingOtherInformationComponent />
                            </Suspense>
                            )}
                        </TabPanel>
                        <TabPanel pt={0}>
                            {tabIndex === 2 && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <BasicDataSettingRelatedHistoryComponent />
                            </Suspense>
                            )}
                        </TabPanel>
                        <TabPanel pt={0}>
                            {tabIndex === 3 && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <BasicDataSettingDailyHabitsComponent />
                            </Suspense>
                            )}
                        </TabPanel>
                        </TabPanels> */}

                        <TabPanels
                            overflowY={'scroll'}
                            mt={'16px'}
                            h={`${window.innerHeight - 257}px`}
                        >
                            <TabPanel pt={0}>
                                <>
                                    <BasicDataSettingMainInformationComponent />
                                </>
                            </TabPanel>
                            <TabPanel pt={0}>
                                <>
                                    <BasicDataSettingOtherInformationComponent />
                                </>
                            </TabPanel>
                            <TabPanel pt={0}>
                                <>
                                    <BasicDataSettingRelatedHistoryComponent />
                                </>
                            </TabPanel>
                            <TabPanel pt={0}>
                                <>
                                    <BasicDataSettingDailyHabitsComponent />
                                </>
                            </TabPanel>
                        </TabPanels>
                    </Box>
                </Tabs>
            </Suspense>
        </>
    );
}

export default BasicDataSetting;
