import React, { useEffect, useState } from 'react';
import { Box, Flex, Button, Text, Divider } from '@chakra-ui/react';

import { RadioGroup, Radio } from '@chakra-ui/react';

export const RadioOptions = () => {
    const [selectedValue1, setSelectedValue1] = useState<string>('全月');
    const [selectedValue2, setSelectedValue2] = useState<string>('送核');
    const [selectedValue3, setSelectedValue3] = useState<string>('公告藥名');
    const [selectedValue4, setSelectedValue4] = useState<string>('列印CCPE');
    const [selectedValue5, setSelectedValue5] =
        useState<string>('健保點數清單');

    // 通用的 handleChange 函數
    const handleChange = (
        value: string,
        group: 'group1' | 'group2' | 'group3' | 'group4' | 'group5'
    ) => {
        switch (group) {
            case 'group1':
                setSelectedValue1(value);
                break;
            case 'group2':
                setSelectedValue2(value);
                break;
            case 'group3':
                setSelectedValue3(value);
                break;
            case 'group4':
                setSelectedValue4(value);
                break;
            case 'group5':
                setSelectedValue5(value);
                break;
            default:
                break;
        }
    };
    return (
        <>
            <Box w={'300px'} bg={'#F5F5F5'} p={'8px 16px'} rounded={'lg'}>
                <Text textStyle={'semiTitle'}>申報資料選擇</Text>
                <RadioGroup
                    onChange={value => handleChange(value, 'group1')}
                    value={selectedValue1}
                >
                    <Flex gap={'8px'} py={'8px'}>
                        <Radio bg={'#fff'} value="全月">
                            全月
                        </Radio>
                        <Radio bg={'#fff'} value="上半月">
                            上半月
                        </Radio>
                        <Radio bg={'#fff'} value="下半月">
                            下半月
                        </Radio>
                    </Flex>
                </RadioGroup>
                <Divider />

                <RadioGroup
                    onChange={value => handleChange(value, 'group2')}
                    value={selectedValue2}
                >
                    <Flex gap={'8px'} py={'8px'}>
                        <Radio bg={'#fff'} value="送核">
                            送核
                        </Radio>
                        <Radio bg={'#fff'} value="補報">
                            補報
                        </Radio>
                    </Flex>
                </RadioGroup>
            </Box>
            <Box w={'300px'} bg={'#F5F5F5'} p={'8px 16px'} rounded={'lg'}>
                <Text textStyle={'semiTitle'}>列印選項</Text>

                <RadioGroup
                    onChange={value => handleChange(value, 'group3')}
                    value={selectedValue3}
                >
                    <Flex gap={'8px'} py={'8px'}>
                        <Radio bg={'#fff'} value="公告藥名">
                            公告藥名
                        </Radio>
                        <Radio bg={'#fff'} value="院內藥名">
                            院內藥名
                        </Radio>
                    </Flex>
                </RadioGroup>
                <Divider />
                <RadioGroup
                    onChange={value => handleChange(value, 'group4')}
                    value={selectedValue4}
                >
                    <Flex gap={'8px'} py={'8px'}>
                        <Radio bg={'#fff'} value="列印CCPE">
                            列印CCPE
                        </Radio>
                        <Radio bg={'#fff'} value="不印">
                            不印
                        </Radio>
                    </Flex>
                </RadioGroup>
            </Box>
            <Box w={'300px'} bg={'#F5F5F5'} p={'8px 16px'} rounded={'lg'}>
                <Text textStyle={'semiTitle'}>列印格式選擇</Text>
                <RadioGroup
                    onChange={value => handleChange(value, 'group5')}
                    value={selectedValue5}
                >
                    <Flex gap={'8px'} my={'8px'}>
                        <Radio bg={'#fff'} value="健保點數清單">
                            健保點數清單
                        </Radio>
                        <Button
                            borderWidth={'2px'}
                            colorScheme="rsPrimary"
                            variant={'outline'}
                        >
                            印表機設定
                        </Button>
                    </Flex>
                    <Flex gap={'8px'} my={'8px'}>
                        <Radio bg={'#fff'} value="處方箋-申報">
                            處方箋-申報
                        </Radio>
                        <Button
                            borderWidth={'2px'}
                            colorScheme="rsPrimary"
                            variant={'outline'}
                        >
                            印表機設定
                        </Button>
                    </Flex>
                    <Flex gap={'8px'} my={'8px'}>
                        <Radio bg={'#fff'} value="處方箋-病歷">
                            處方箋-病歷
                        </Radio>
                        <Button
                            borderWidth={'2px'}
                            colorScheme="rsPrimary"
                            variant={'outline'}
                        >
                            印表機設定
                        </Button>
                    </Flex>
                    <Flex gap={'8px'} my={'8px'}>
                        <Radio bg={'#fff'} value="病歷首頁">
                            病歷首頁
                        </Radio>
                    </Flex>
                </RadioGroup>
            </Box>
        </>
    );
};
