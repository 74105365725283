import axios from 'axios';
import axiosInstance from './axiosInstance';
import apiService from './apiService';

interface dataProps {
    keyword: string;
}
// 13. 模糊搜尋官方ICD-10
export const postICD10OfficialService = async (
    token: string,
    updatedData: dataProps
) => {
    return apiService({
        method: 'POST',
        path: '/api/icd10/official',
        token,
        data: updatedData,
    });
};
// export const postICD10OfficialService = async (
//     token: string,
//     updatedData: dataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axiosInstance.post('/api/icd10/official', requestBody, {
//             headers,
//         });
//         const result = response.data;

//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
