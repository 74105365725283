import axios from 'axios';
// import RsApiService from './RSApiService'

type finalDataProps = {
    sHospid: string;
    sMrecs: number;
    sPrecs: number;
    sPatData: string;
    sType: string; // 類別：A1 正式，A2 預檢，ZZ 測試
};
// Rs取得就醫序號新版-就醫識別碼
export const ICUpload2Service = async (data: finalDataProps): Promise<any> => {
    try {
        const response = await axios.post(
            'http://localhost:23752/cshis/IC_UpLoad2',
            data,
            {
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                },
            }
        );

        console.log(response.data, '上傳 IC 資料');
        return response.data;
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.error('Error:', error.message);
        } else {
            console.error('An unknown error occurred');
        }
    }
};
