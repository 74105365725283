import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';

interface DrugFormsSettingProps {
    isPowder: boolean;
    onClick: () => void;
}

export const DrugFormsSetting: FC<DrugFormsSettingProps> = ({
    isPowder,
    onClick,
}) => {
    return (
        <Button
            bg={'#ECECEC'}
            borderWidth={'2px'}
            variant={'outline'}
            colorScheme={isPowder ? 'rsDanger' : 'rsPrimary'}
            onClick={onClick}
            boxShadow={
                isPowder ? '4px 4px 2px 0px rgba(0, 0, 0, 0.25) inset;' : 'none'
            }
        >
            {isPowder ? '磨粉' : '顆粒'}
        </Button>
    );
};
