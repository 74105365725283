import { useState, useRef } from 'react';
import { Flex, Text, Textarea, Box } from '@chakra-ui/react';
import { soDataProps } from './DrugDataMaintenanceTable';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';

interface DrugDataMaintenanceAttachSoProps {
    soData: soDataProps;
    setSoData: React.Dispatch<React.SetStateAction<soDataProps>>;
    isEditing: boolean;
}

const DrugDataMaintenanceAttachSo = ({
    soData,
    setSoData,
    isEditing,
}: DrugDataMaintenanceAttachSoProps) => {
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    // 處理文字框變動後的事
    const handleChangeInput = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target; //已經在 Textarea 設定好對應的 name，取得該 DOM 的 name 就可以拿到對的 value
        setHasUnsavedChanges(true);
        setSoData((pre: soDataProps) => ({
            ...pre, // 展開
            [name]: value, // 匹配 soData 中的 name，並把 value 設進去
        }));
    };

    return (
        <>
            <Flex gap="16px" direction={'column'}>
                <Box>
                    <Text textStyle={'semiTitle'} mb={'4px'}>
                        主觀性資料
                    </Text>

                    {isEditing ? (
                        <>
                            <Textarea
                                resize={'none'}
                                onChange={handleChangeInput}
                                value={soData.subjective}
                                name="subjective"
                                bg={'#fff'}
                                h={'80px'}
                            ></Textarea>
                        </>
                    ) : (
                        <>
                            <Box
                                w="100%"
                                h="80px"
                                overflowY={'auto'}
                                borderWidth={'1px'}
                                rounded={'md'}
                                p={'8px 16px'}
                                bg={'#fafafa'}
                            >
                                {soData.subjective}
                            </Box>
                        </>
                    )}
                </Box>

                <Box>
                    <Text textStyle={'semiTitle'} mb={'4px'}>
                        客觀性資料
                    </Text>
                    {isEditing ? (
                        <>
                            <Textarea
                                resize={'none'}
                                onChange={handleChangeInput}
                                value={soData.objective}
                                name="objective"
                                bg={'#fff'}
                                h={'80px'}
                            ></Textarea>
                        </>
                    ) : (
                        <>
                            <Box
                                w={'100%'}
                                h={'80px'}
                                overflowY={'auto'}
                                borderWidth={'1px'}
                                rounded={'md'}
                                p={'8px 16px'}
                                bg={'#fafafa'}
                            >
                                {soData.objective}
                            </Box>
                        </>
                    )}
                </Box>
            </Flex>
        </>
    );
};

export default DrugDataMaintenanceAttachSo;
