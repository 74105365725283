import { useQuery } from "react-query";
import { callRegistrationOpdListsService } from 'service/opdListsService';
import { callClinicOpdListsService } from "service/opdListsService";
import Swal from "sweetalert2";
import { useUserStore } from "store";
import { useRegistrationListStatus } from "contexts/Registration/RegistrationListStatusContext";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

export const useVisitedListsQuery = () => {
    const { filterByDateStatus } = useRegistrationListStatus();
    const { userInfo } = useUserStore();
    const location = useLocation();

    // Convert filterByDateStatus to YYYY-MM-DD format and adjust for timezone
    const formattedDate = filterByDateStatus ? new Date(new Date(filterByDateStatus).getTime() + (24 * 60 * 60 * 1000)).toISOString().split('T')[0] : '';

    const isRegisteredPage = location.pathname.includes('RegisteredPage');

    const query = useQuery(['registrationOpdLists', formattedDate, isRegisteredPage], 
        () => {
            if (isRegisteredPage) {
                return callRegistrationOpdListsService(userInfo.token!, formattedDate);
            } else {
                return callClinicOpdListsService(userInfo.token!, formattedDate);
            }
        },
        {
            onSuccess: (result) => {
                // console.log(result, '整排的lists');
            },
            onError: (error) => {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            },
            enabled: !!userInfo.token,
            staleTime: Infinity,
            refetchInterval: 30000,
        }
    );

    return {
        ...query,
        opdLists: query.data?.lists ?? {},
    };
};
