import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IsFormMaximizeContextValue {
    isFormMaximizeStatus: boolean;
    setIsFormMaximizeStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsFormMaximizeContext = createContext<
    IsFormMaximizeContextValue | undefined
>(undefined);

interface IsFormMaximizeProviderProps {
    children: ReactNode;
}

export const IsFormMaximizeProvider: React.FC<IsFormMaximizeProviderProps> = ({
    children,
}) => {
    const [isFormMaximizeStatus, setIsFormMaximizeStatus] = useState(false);
    return (
        <IsFormMaximizeContext.Provider
            value={{
                isFormMaximizeStatus,
                setIsFormMaximizeStatus,
            }}
        >
            {children}
        </IsFormMaximizeContext.Provider>
    );
};

export const useIsFormMaximizeStatus = (): IsFormMaximizeContextValue => {
    const context = useContext(IsFormMaximizeContext);

    if (!context) {
        throw new Error(
            'useIsFormMaximizeStatus must be used within a isFormMaximizeStatusProvider'
        );
    }

    return context;
};
