import React, {  useState, useEffect } from 'react';
import {  Button, Text, Checkbox, Select, Flex } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';

import Swal from 'sweetalert2';

import { useTreatmentInfoStore } from 'store/TreatmentManagement';

import { specificTreatmentListsProps } from 'service/specificTreatmentListsService';
import { useDropDownMenuQuery } from 'query/useDropDownMenuQuery';
import { useUserStore } from 'store';

// interface ChronicSettingProps {
//     onClick: () => void;
// }

export const ChronicSetting = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { treatmentInfo, updateTreatmentInfo } = useTreatmentInfoStore();
    const { userInfo } = useUserStore();
    const toast = useToast();

    // 特殊原因清單
    const [specificTreatmentLists, setSpecificTreatmentLists] = useState<
        specificTreatmentListsProps[]
    >([]);
    const {
        data: specificTreatmentListsData,
        isSuccess: isSpecificTreatmentListsSuccess,
    } = useDropDownMenuQuery('specificTreatmentLists', userInfo.token!);
    useEffect(() => {
        if (isSpecificTreatmentListsSuccess) {
            setSpecificTreatmentLists(specificTreatmentListsData.lists);
        }
    }, [isSpecificTreatmentListsSuccess]);

    const [selectedTimes, setSelectedTimes] = useState<number | null>(null);

    const handleChangeCheckbox = (value: number) => {
        setSelectedTimes(prev => (prev === value ? null : value));
    };

    const [selectedReasons, setSelectedReasons] = useState<string>('');
    const handleChangeSelect = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSelectedReasons(event.target.value);
    };

    const handleSave = () => {
        updateTreatmentInfo({
            chronic_reasons: selectedReasons,
            chronic_times: selectedTimes,
        });

        console.log('Save:', selectedTimes, selectedReasons);
        console.log(
            'Save: treatment:',
            treatmentInfo.chronic_times,
            treatmentInfo.chronic_reasons
        );
        Swal.fire({
            icon: 'success',
            title: '慢性處方簽已設立',
            timer: 1500,
            showConfirmButton: false,
        });
        onClose();
    };

    useEffect(() => {
        if (treatmentInfo.chronic_reasons !== '') {
            setSelectedReasons(treatmentInfo.chronic_reasons);
        }

        if (treatmentInfo.chronic_times !== null) {
            setSelectedTimes(treatmentInfo.chronic_times);
        }
    }, []);

    return (
        <>
            <Button
                colorScheme={
                    treatmentInfo.chronic_times ? 'rsDanger' : 'rsPrimary'
                }
                textColor={'#fff'}
                boxShadow={'2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset;'}
                onClick={onOpen}
            >
                {treatmentInfo.chronic_times !== null
                    ? `連續 ${treatmentInfo.chronic_times}`
                    : '慢性'}
            </Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size={'xl'}
                closeOnOverlayClick={false}
                isCentered
                motionPreset={'slideInTop'}
            >
                <ModalOverlay backdropFilter="blur(3px) " />
                <ModalContent>
                    <ModalHeader>
                        <Text textStyle={'semiTitle'}>開立慢性連續處方簽</Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction={'column'} gap={'12px'}>
                            <Flex gap={'12px'} align={'center'}>
                                <Text
                                    textStyle={'semiTitle'}
                                    w={'120px'}
                                    flexShrink={0}
                                >
                                    原處方日期
                                </Text>
                                <Text>113.06.26</Text>
                            </Flex>
                            <Flex gap={'12px'} align={'center'}>
                                <Text
                                    textStyle={'semiTitle'}
                                    w={'120px'}
                                    flexShrink={0}
                                >
                                    原就醫序號
                                </Text>
                                <Text>0021</Text>
                            </Flex>
                            <Flex gap={'12px'} align={'center'}>
                                <Text
                                    textStyle={'semiTitle'}
                                    w={'120px'}
                                    flexShrink={0}
                                >
                                    開立醫師
                                </Text>
                                <Text>李阿信</Text>
                            </Flex>
                            <Flex gap={'12px'} align={'center'}>
                                <Text
                                    textStyle={'semiTitle'}
                                    w={'120px'}
                                    flexShrink={0}
                                >
                                    可調劑次數
                                </Text>
                                <Flex gap={'8px'}>
                                    {[2, 3, 4, 5].map((num, index) => (
                                        <Checkbox
                                            colorScheme="rsPrimary"
                                            key={index}
                                            isChecked={selectedTimes === num}
                                            onChange={() => {
                                                handleChangeCheckbox(num);
                                            }}
                                        >
                                            {num}
                                        </Checkbox>
                                    ))}
                                </Flex>
                            </Flex>
                            <Flex gap={'12px'} align={'center'}>
                                <Text
                                    textStyle={'semiTitle'}
                                    w={'120px'}
                                    flexShrink={0}
                                >
                                    本次調劑序
                                </Text>
                                <Text>1</Text>
                            </Flex>
                            <Flex gap={'12px'} align={'center'}>
                                <Text
                                    textStyle={'semiTitle'}
                                    w={'120px'}
                                    flexShrink={0}
                                >
                                    連簽特殊原因
                                </Text>
                                <Select
                                    onChange={handleChangeSelect}
                                    value={selectedReasons}
                                >
                                    <option value="">請選擇</option>
                                    {specificTreatmentLists && specificTreatmentLists.map(
                                        (item, index) => (
                                            <option
                                                value={item.code}
                                                key={index}
                                            >
                                                {item.code} {item.name}
                                            </option>
                                        )
                                    )}
                                </Select>
                            </Flex>
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant={'ghost'} mr={3} onClick={onClose}>
                            取消
                        </Button>
                        <Button colorScheme="rsPrimary" onClick={handleSave}>
                            確認
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
