import apiService from './apiService';

export type icDataListsReqDataProps = {
    date: string;
    start_time: string;
    end_time: string;
    not_upload: string;
};

export type icDataListsResDataProps = {
    result: string;
    lists: icDataListsDataProps[];
};
export type icDataListsDataProps = {
    signature: string; // 是否取得簽章，0：否、1：是
    medical_record_uid: string; // 病例資料id
    medical_record_number: string;
    treated_at: string; // 就醫日期時間
    name: string; // 病患姓名
    id_no: string; // 病患身分證號
    birth_date: string; // 病患生日
    mt_type: string; // 就醫類別
    card_no: string | null; // 健保卡號（可能為 null）
    upload_status: number; // 上傳狀態（0：未傳、1：已傳、5：不傳）
    opd_uid: string; // 掛號id
};

// 22. IC 卡資料上傳名單
export const callIcDataListsService = async (
    token: string,
    data?: icDataListsReqDataProps
): Promise<icDataListsResDataProps> => {
    const queryParams = data ? { data } : undefined;
    console.log('queryParams data', data);
    return apiService<any, icDataListsResDataProps>({
        method: 'GET',
        path: '/api/ic_data/lists',
        token,
        data: queryParams,
    });
};
