import {
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    TableContainer,
    Select,
    Flex,
    Text,
    Center,
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';
import Swal from 'sweetalert2';
import {
    Button,
    InputGroup,
    InputLeftElement,
    Image,
    Input,
} from '@chakra-ui/react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';

import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';

// Components
import Loading from 'components/CustomComponents/Loading';
import DragIcon from '../../CustomComponents/DragIcon';
import DeleteIcon from 'components/CustomComponents/DeleteIcon';
import SaveIcon from 'components/CustomComponents/SaveIcon';
import DrawerDetailsIcon from 'components/CustomComponents/DrawerDetailsIcon';
import DrawerCloseIcon from 'components/CustomComponents/DrawerCloseIcon';
import RealsunBgImg from '../../../images/RealsunBgImg.png';

// api
import { callMembershipService } from 'service/memberShipService';
// import { callMembershipService } from 'service/callMembershipService';
import { callMembershipDiscountService } from 'service/memberShipDiscountService';
// import { callMembershipDiscountService } from 'service/callMembershipDiscountService';
import { callGTypeArithmeticService } from 'service/gTypeService';
import { callGTypeFeeTypeService } from 'service/gTypeService';

import { deleteMembershipService } from 'service/memberShipService';
// import { deleteMembershipService } from 'service/delelteMembershipService';
import { postMembershipService } from 'service/memberShipService';
// import { postMembershipService } from 'service/postMembershipService';
// import { deleteMembershipDiscountService } from 'service/deleteMembershipDiscountService';
import { deleteMembershipDiscountService } from 'service/memberShipDiscountService';
// import { postMembershipSortService } from 'service/postMembershipSortService';
import { postMembershipSortService } from 'service/memberShipSortService';

// lists的資料型態
interface ShowListsProps {
    data_id: string;
    name: string;
}
//四則運算資料型態
interface ArithmeticListsProps {
    code: string;
    name: string;
}
interface drawerFormDataProps {
    data_id: string;
    name: string;
}
interface feeTypeLists {
    code: string;
    name: string;
}
interface feeDiscountListsProps {
    data_id?: string;
    membership_uid?: string;
    // 費用代碼
    fee_type_code: string;
    // 符號
    arithmetic_code: string;
    // 設定值
    value: string;
}

interface newMembershipProps {
    data_id?: string;
    name: string;
    lists: Array<feeDiscountListsProps>;
}
interface returnFeeDiscountProps {
    data_id: string;
    membership_uid: string;
}
const PreferentialStatusTable = () => {
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
    // 高度
    const [preferentialStatusTableHeight, setPreferentialStatusTableHeight] =
        useState(0);
    const windowHeight = window.innerHeight;
    const [tableHeight, setTableHeight] = useState(0);
    // 現在指向的lists是哪個,-1代表沒選
    const [selectedShowListsIndex, setSelectedShowListsIndex] = useState(-1);
    const outerDivRef = useRef<HTMLDivElement | null>(null);
    const bgRef = useRef<HTMLDivElement>(null);
    //修改時需要紀錄data_id
    const [drawerFormDataId, setDrawerFormDataId] = useState('');
    const token = localStorage.getItem('token');
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [editStatus, setEditStatus] = useState(false);
    const [drawerOpenStatus, setDrawerOpenStatus] = useState(false);
    const [keyword, setKeyword] = useState<string>('');
    const [feeDiscountLists, setFeeDiscountLists] = useState<
        feeDiscountListsProps[]
    >([]);
    const [showLists, setShowLists] = useState<ShowListsProps[]>([]);
    const [drawerFormData, setDrawerFormData] = useState<drawerFormDataProps>({
        data_id: '',
        name: '',
    });
    const [feeTypeLists, setFeeTypeLists] = useState<feeTypeLists[]>([]);

    useEffect(() => {
        function updateHeight() {
            if (outerDivRef.current) {
                const height = outerDivRef.current.clientHeight;
                setPreferentialStatusTableHeight(height);
            }
        }
        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [setPreferentialStatusTableHeight]);
    useEffect(() => {
        const finalHeight = windowHeight - preferentialStatusTableHeight - 140;
        setTableHeight(finalHeight);
    }, [windowHeight, preferentialStatusTableHeight]);

    //修改keyword值
    const handleChangeKeyword = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const inputElement = event.target.value;
        setKeyword(inputElement);
    };

    const [arithmeticLists, setArithmeticLists] = useState<
        ArithmeticListsProps[]
    >([]);

    //取得四則運算符號api
    const fetchArithmetic = async () => {
        if (token) {
            try {
                const fetchArithmeticData =
                    await callGTypeArithmeticService(token);
                if (fetchArithmeticData.result === 'success') {
                    setArithmeticLists(fetchArithmeticData.lists);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    //取得特定優免身份折扣設定清單費用類別
    const fetchFeeType = async () => {
        if (token) {
            try {
                const fetchFeeTypeData = await callGTypeFeeTypeService(token);

                if (fetchFeeTypeData.result === 'success') {
                    setFeeTypeLists(fetchFeeTypeData.lists);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    useEffect(() => {
        fetchArithmetic();
        fetchFeeType();
    }, []);
    //清空抽屜資料
    const emptyDrawerFormData = () => {
        let emtpyData = {
            data_id: '',
            name: '',
        };
        setDrawerFormData(emtpyData);
    };
    const emptyFeeDiscountList = () => {
        let emtypData = [
            {
                data_id: '',
                membership_uid: '',
                // 費用代碼
                fee_type_code: '',
                // 符號
                arithmetic_code: '',
                // 設定值
                value: '',
            },
        ];
        setFeeDiscountLists(emtypData);
    };
    const handleSearch = () => {};
    const handleNewDrawer = () => {
        setIsNewDrawer(true);
        setDrawerOpenStatus(true);
        setDrawerFormDataId('');
        emptyDrawerFormData();
        setEditStatus(true);
        emptyFeeDiscountList();
    };
    const [selectedFeeTypeGids, setSelectedFeeTypeGids] = useState(new Set());

    //打開抽屜
    const handleChangeDrawStatus = (
        event: React.MouseEvent,
        data_id: string,
        name: string,
        index: number
    ) => {
        const fetchDrawerFormLists = async () => {
            if (token) {
                try {
                    const result = await callMembershipDiscountService(
                        token,
                        data_id
                    );
                    if (result.result === 'success') {
                        setFeeDiscountLists(result.lists);
                    }
                } catch {
                    Swal.fire({
                        icon: 'error',
                        title: '後端服務錯誤',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                }
            }
        };
        setDrawerFormData(prevFormData => ({
            ...prevFormData,
            name: name,
        }));
        setDrawerFormDataId(data_id);
        fetchDrawerFormLists();
        setDrawerOpenStatus(true);
        setSelectedShowListsIndex(index);
    };

    const getArithmeticName = (gid: string) => {
        const matchingItem = arithmeticLists.find(item => item.code === gid);
        return matchingItem ? matchingItem.name : '請選擇';
    };

    const getFeeTypeName = (feeTypeId: string) => {
        const feeType = feeTypeLists.find(
            feeType => feeType.code === feeTypeId
        );
        return feeType ? feeType.name : '請選擇';
    };
    const handleCloseDrawer = (event: React.MouseEvent) => {
        if (hasUnsavedChanges) {
            event.stopPropagation();
            Swal.fire({
                title: '資料尚未儲存，是否要離開？',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: '是',
                cancelButtonText: '否',
            }).then(result => {
                if (result.isConfirmed) {
                    setDrawerOpenStatus(false);
                    setDrawerKey(prevKey => prevKey + 1);
                    setHasUnsavedChanges(false);
                    setEditStatus(false);
                    setSelectedShowListsIndex(-1);
                } else {
                    setDrawerOpenStatus(true);
                }
            });
        } else {
            setEditStatus(false);
            setDrawerOpenStatus(false);
        }
    };
    const handleChangeDrawerInput = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value } = event.target;
        setDrawerFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
        setHasUnsavedChanges(true);
    };
    const [isNewDrawer, setIsNewDrawer] = useState(false);
    const handleChangeFeeDiscountListsInput = (
        event: React.ChangeEvent<HTMLInputElement>,
        index: number // 添加 index 参数
    ) => {
        const { name, value } = event.target;
        const regex = /^(?:\d{1,5}(?:\.\d{0,2})?|\.\d{1,2})$/;

        // Check if the value matches the regular expression
        if (regex.test(value)) {
            setFeeDiscountLists(prevLists => {
                const newLists = [...prevLists];
                const updatedItem = { ...newLists[index], [name]: value };
                newLists[index] = updatedItem;
                return newLists;
            });
        }
        setHasUnsavedChanges(true);
    };
    const handleChangeArithmetic = (
        event: React.ChangeEvent<HTMLSelectElement>,
        index: number
    ) => {
        const { name, value } = event.target;
        setFeeDiscountLists(prevLists => {
            const newLists = [...prevLists];
            const updatedItem = { ...newLists[index], [name]: value };
            newLists[index] = updatedItem;
            return newLists;
        });
        setHasUnsavedChanges(true);
    };
    const checkDrawerFormData = useRef<drawerFormDataProps>({
        data_id: '',
        name: '',
    });
    const checkFeeDiscountListsRef = useRef<feeDiscountListsProps[]>([]);
    const handleEdit = () => {
        checkDrawerFormData.current = { ...drawerFormData };
        // checkFeeDiscountListsRef.current = [...feeDiscountLists];
        //深拷貝
        checkFeeDiscountListsRef.current = feeDiscountLists.map(item => ({
            ...item,
        }));
        setEditStatus(true);
    };
    const handleDeactivated = () => {
        if (drawerFormDataId && token) {
            Swal.fire({
                title: `是否要將「 ${drawerFormData.name}」的資料停用`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: '是',
                cancelButtonText: '否',
            }).then(result => {
                if (result.isConfirmed) {
                    const deleteAPI = async () => {
                        try {
                            const deleteData = await deleteMembershipService(
                                token,
                                drawerFormDataId
                            );
                            if (deleteData.result === 'success') {
                                Swal.fire({
                                    icon: 'success',
                                    title: '刪除成功',
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                                setShowLists(prev =>
                                    prev.filter(
                                        item =>
                                            item.data_id !== drawerFormDataId
                                    )
                                );
                                setDrawerOpenStatus(false);
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: '刪除失敗',
                                    showCancelButton: true,
                                    timer: 1500,
                                });
                            }
                        } catch {
                            Swal.fire({
                                icon: 'error',
                                title: '後端服務錯誤',
                                timer: 1500,
                                showConfirmButton: false,
                            });
                        }
                    };
                    deleteAPI();
                } else {
                    return;
                }
            });
        }
        setEditStatus(false);
    };

    const handleSave = () => {
        const feeDiscountContent = feeDiscountLists.every(
            item =>
                item.fee_type_code !== '' &&
                item.arithmetic_code !== '' &&
                item.value !== ''
        );
        //都是true 代表都有資料
        if (drawerFormData.name) {
            if (feeDiscountContent) {
                if (drawerFormDataId) {
                    //修改方法 (有data_id)

                    let newData: newMembershipProps = {
                        data_id: drawerFormDataId,
                        name: drawerFormData.name,
                        lists: feeDiscountLists,
                    };

                    if (token && newData) {
                        const postData = async () => {
                            try {
                                const postDataMembership =
                                    await postMembershipService(token, newData);

                                if (postDataMembership.result === 'success') {
                                    Swal.fire({
                                        icon: 'success',
                                        title: '修改成功',
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });

                                    setShowLists(prev =>
                                        prev.map(item =>
                                            item.data_id === drawerFormDataId
                                                ? {
                                                      ...item,
                                                      name: drawerFormData.name,
                                                  }
                                                : item
                                        )
                                    );
                                    setEditStatus(false);
                                    // setDrawerOpenStatus(false)
                                    setHasUnsavedChanges(false);

                                    //修改的時候抽屜會繼續開著所以要把api回傳的id回填到lists

                                    const returnLists =
                                        postDataMembership.lists;

                                    setFeeDiscountLists(prevLists => {
                                        return prevLists.map((item, index) => {
                                            if (
                                                !item.data_id &&
                                                !item.membership_uid
                                            ) {
                                                return {
                                                    ...item,
                                                    data_id:
                                                        returnLists[index]
                                                            .data_id,
                                                    membership_uid:
                                                        returnLists[index]
                                                            .membership_uid,
                                                };
                                            }
                                            return item;
                                        });
                                    });
                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: '修改失敗',
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                }
                            } catch {
                                Swal.fire({
                                    icon: 'error',
                                    title: '後端服務錯誤',
                                    timer: 1500,
                                    showConfirmButton: false,
                                });
                            }
                        };
                        postData();
                    }
                } else {
                    //新增方法 (沒有data_id)
                    let newData: newMembershipProps = {
                        // data_id: drawerFormData.id,
                        name: drawerFormData.name,
                        lists: feeDiscountLists,
                    };
                    if (token && newData) {
                        const postData = async () => {
                            try {
                                const postDataMembership =
                                    await postMembershipService(token, newData);
                                if (postDataMembership.result === 'success') {
                                    Swal.fire({
                                        icon: 'success',
                                        title: '新增成功',
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                    let returnData = {
                                        data_id: postDataMembership.data_id,
                                        name: drawerFormData.name,
                                    };
                                    setShowLists(prev => [returnData, ...prev]);
                                    setEditStatus(false);
                                    setDrawerOpenStatus(false);
                                    setHasUnsavedChanges(false);
                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: '新增失敗',
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                }
                            } catch {
                                Swal.fire({
                                    icon: 'error',
                                    title: '後端服務錯誤',
                                    timer: 1500,
                                    showConfirmButton: false,
                                });
                            }
                        };
                        postData();
                    }
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '新增失敗，輸入的資料不完整',
                    showConfirmButton: true,
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: '新增失敗，名稱不能空白',
                showConfirmButton: true,
            });
        }
    };

    const handleNewFeeDiscountLists = () => {
        // 增加一個新的discouontLists
        // setFeeDiscountLists()
        // const unselectedCode = getFirstUnselectedFeeType();

        // if (unselectedCode) {
        setEditStatus(true);
        checkDrawerFormData.current = { ...drawerFormData };
        const newFeeDiscount = {
            fee_type_code: '',
            arithmetic_code: '',
            value: '',
        };
        checkFeeDiscountListsRef.current = feeDiscountLists.map(item => ({
            ...item,
        }));
        setFeeDiscountLists(prevLists => [...prevLists, newFeeDiscount]);
        // checkFeeDiscountListsRef.current = [...feeDiscountLists];
        //深拷貝
        // }
    };
    const [drawerKey, setDrawerKey] = React.useState(0);
    // 點空白處關閉抽屜

    const handleOutsideClick = (event: MouseEvent) => {
        const isClickInsideSwal = (event.target as HTMLElement).closest(
            '.swal2-container'
        );
        if (isClickInsideSwal) {
            return; // 如果點擊在 Swal 上，則不進行任何操作
        }

        if (
            //抽屜開著
            drawerOpenStatus &&
            sidebarRef.current &&
            !sidebarRef.current.contains(event.target as Node) &&
            !bgRef.current?.contains(event.target as Node)
        ) {
            if (hasUnsavedChanges) {
                return;
            } else {
                closeDrawer();
            }
        }
    };

    const closeDrawer = () => {
        setDrawerOpenStatus(false);
        setDrawerKey(prevKey => prevKey + 1);
        setHasUnsavedChanges(false);
        setSelectedShowListsIndex(-1);
        setEditStatus(false);
    };
    useEffect(() => {
        if (drawerOpenStatus === false) {
            checkFeeDiscountListsRef.current = []; // 清空檢查陣列
        }
    }, [drawerOpenStatus]);

    useEffect(() => {
        if (drawerOpenStatus) {
            document.addEventListener('click', handleOutsideClick, true);
        } else {
            document.removeEventListener('click', handleOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick, true);
        };
    }, [drawerOpenStatus, sidebarRef]);

    const handleChangeFeeDiscount = (
        event: React.ChangeEvent<HTMLSelectElement>,
        index: number
    ) => {
        const newGid = event.target.value;
        const oldGid = feeDiscountLists[index].fee_type_code;

        setFeeDiscountLists(prevLists => {
            const newLists = [...prevLists];
            newLists[index].fee_type_code = newGid;
            return newLists;
        });

        setSelectedFeeTypeGids(prevSelectedGids => {
            const newSelectedGids = new Set(prevSelectedGids);
            newSelectedGids.delete(oldGid);
            newSelectedGids.add(newGid);
            return newSelectedGids;
        });

        setHasUnsavedChanges(true);
    };
    useEffect(() => {
        const initialSelectedGids = new Set(
            feeDiscountLists.map(item => item.fee_type_code)
        );
        setSelectedFeeTypeGids(initialSelectedGids);
    }, [feeDiscountLists]);

    const handleCancel = () => {
        if (hasUnsavedChanges) {
            Swal.fire({
                title: '資料尚未儲存，是否要離開？',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: '是',
                cancelButtonText: '否',
            }).then(result => {
                if (result.isConfirmed) {
                    if (isNewDrawer) {
                        setDrawerOpenStatus(false);
                        setEditStatus(false);
                    } else {
                        setFeeDiscountLists(checkFeeDiscountListsRef.current);
                        setEditStatus(false);
                        setDrawerOpenStatus(true);

                        setHasUnsavedChanges(false);
                    }
                } else {
                    setEditStatus(true);
                    setDrawerOpenStatus(true);
                    return;
                }
            });
        } else {
            setEditStatus(false);
            // 當全空時按取消
            if (
                drawerFormData.name === '' &&
                feeDiscountLists.map(
                    item =>
                        item.arithmetic_code === '' &&
                        item.fee_type_code === '' &&
                        item.value === ''
                )
            ) {
                setDrawerOpenStatus(false);
                setEditStatus(false);
            } else {
                setFeeDiscountLists(prevLists => {
                    const filteredLists = prevLists.filter(
                        item =>
                            item.arithmetic_code !== '' &&
                            item.fee_type_code !== '' &&
                            item.value !== ''
                    );

                    return filteredLists;
                });
            }
        }
    };
    //取得 優免身份清單api
    const fetchShowLists = async () => {
        if (token) {
            try {
                const result = await callMembershipService(token);
                if (result.result === 'success') {
                    setShowLists(result.lists);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        }
    };
    useEffect(() => {
        fetchShowLists();
    }, []);

    //拖曳
    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return; // 没有有效的拖放目标
        } else {
            const newData = [...showLists];

            const [movedItem] = newData.splice(result.source.index, 1);
            newData.splice(result.destination.index, 0, movedItem);

            setShowLists(newData);

            if (newData) {
                const postData = async () => {
                    const data = {
                        ids: newData.map(item => item.data_id),
                    };
                    const postMemberShipSort = async () => {
                        if (token) {
                            try {
                                const sortApi = await postMembershipSortService(
                                    token,
                                    data
                                );
                                if (sortApi.result === 'success') {
                                    Swal.fire({
                                        icon: 'success',
                                        title: '修改成功',
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: '修改失敗',
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                }
                            } catch {
                                Swal.fire({
                                    icon: 'error',
                                    title: '後端服務錯誤',
                                    timer: 1500,
                                    showConfirmButton: false,
                                });
                            }
                        }
                    };
                    postMemberShipSort();
                };
                postData();
            }
            // fetchData()
        }
    };
    const handleDeactivatedDiscount = (
        data_id: string | undefined,
        feeTypeGid: string,
        index: number
    ) => {
        const foundItem = feeTypeLists.find(item => item.code === feeTypeGid);
        if (foundItem) {
            if (token && data_id) {
                Swal.fire({
                    title: `是否要將「 ${foundItem.name}」刪除？`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                }).then(result => {
                    if (result.isConfirmed) {
                        const deleteDiscountAPI = async () => {
                            try {
                                const deleteDiscountData =
                                    await deleteMembershipDiscountService(
                                        token,
                                        data_id
                                    );
                                if (deleteDiscountData.result === 'success') {
                                    Swal.fire({
                                        icon: 'success',
                                        title: '刪除成功',
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                    setFeeDiscountLists(prev =>
                                        prev.filter(
                                            item => item.data_id !== data_id
                                        )
                                    );
                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: '刪除失敗',
                                        showConfirmButton: false,
                                        timer: 1500,
                                    });
                                }
                            } catch {}
                            Swal.fire({
                                icon: 'error',
                                title: '後端服務錯誤',
                                timer: 1500,
                                showConfirmButton: false,
                            });
                        };
                        deleteDiscountAPI();
                    }
                    return;
                });
            }
        } else if (index) {
            setFeeDiscountLists(prev =>
                prev.filter((item, subIndex) => subIndex !== index)
            );
        }
    };
    return (
        <>
            {feeTypeLists.length > 0 && (
                <>
                    <Button
                        colorScheme="rsPrimary"
                        onClick={handleNewDrawer}
                        mb={'16px'}
                    >
                        新增優免身份
                    </Button>
                </>
            )}

            <DragDropContext onDragEnd={handleDragEnd}>
                <Box
                    bg={'#F7F7F7'}
                    rounded={'15px'}
                    maxW={'600px'}
                    overflow={'hidden'}
                    borderBottom={'2px solid #ccc'}
                >
                    <Flex
                        borderBottom={'2px solid #9B9B7A'}
                        gap={'16px'}
                        pos={'sticky'}
                        top={0}
                        bg={'#F7F7F7'}
                        p={'8px 16px'}
                    >
                        <Center flex={'0 0 100px'}>排序</Center>
                        <Center flex={'0 0 100px'}>詳細內容</Center>

                        <Box flex={'1 0 auto'} minW={'100px'}>
                            名稱
                        </Box>
                    </Flex>
                    <Droppable droppableId="droppable-table" type="group">
                        {provided => (
                            <Box
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                maxHeight={`${window.innerHeight - 80 - 56 - 56 - 58}px`}
                                overflowY={'auto'}
                            >
                                {showLists && showLists.map((item, index) => (
                                    <Draggable
                                        key={item.data_id}
                                        draggableId={item.data_id}
                                        index={index}
                                    >
                                        {provided => (
                                            <Flex
                                                p={'8px 16px'}
                                                gap={'16px'}
                                                bg={
                                                    selectedShowListsIndex ===
                                                    index
                                                        ? '#DDDBBA'
                                                        : ''
                                                }
                                                _hover={{
                                                    bgColor: '#E9E5DA',
                                                }}
                                                borderBottom={'1px solid #ddd'}
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                                align={'center'}
                                            >
                                                <Center
                                                    flex={'0 0 100px'}
                                                    fontSize={'20px'}
                                                    {...provided.dragHandleProps}
                                                >
                                                    &#x283F;
                                                </Center>
                                                <Center flex={'0 0 100px'}>
                                                    <DrawerDetailsIcon
                                                        key={index}
                                                        isActive={
                                                            selectedShowListsIndex ===
                                                            index
                                                        }
                                                        onClick={(
                                                            event: React.MouseEvent<HTMLElement>
                                                        ) =>
                                                            //檢視帶id, icd_code, name_tw,name_en是為了沒id時可以抓值到抽屜中顯示, index則是回填時使用
                                                            // lists顯示是code 但流程都是icd_code
                                                            handleChangeDrawStatus(
                                                                event,
                                                                item.data_id,

                                                                item.name,

                                                                index
                                                            )
                                                        }
                                                    />
                                                </Center>

                                                <Box
                                                    flex={'1 0 auto'}
                                                    minW={'100px'}
                                                >
                                                    <Text>
                                                        {item.name || ''}
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </Box>
            </DragDropContext>

            {/* 打開抽屜 */}

            {drawerOpenStatus && (
                <>
                    <Box
                        ref={sidebarRef}
                        id="a"
                        zIndex={20}
                        pos={'fixed'}
                        top={'80px'}
                        right={drawerOpenStatus ? '0' : '-800px'}
                        w={'100%'}
                        maxW={'800px'}
                        bg={'#fff'}
                        transition={'right 0.3s ease-in-out'}
                        h={`calc(${window.innerHeight}px  - 80px)`}
                        boxShadow={'2xl'}
                    >
                        <Flex
                            justify={'space-between'}
                            align={'center'}
                            p={'8px 16px'}
                            bg={'rsPrimary.500'}
                        >
                            <Text textColor={'#fff'} fontSize={'lg'}>
                                詳細內容
                            </Text>
                            <Box
                                textColor={'#fff'}
                                onClick={handleCloseDrawer}
                                opacity={0.8}
                                _hover={{ cursor: 'pointer', opacity: 1 }}
                            >
                                <HeroIcons name="xmark"></HeroIcons>
                            </Box>
                        </Flex>
                        <Flex p={'16px'} gap={'16px'} direction={'column'}>
                            <Flex
                                justify={'space-between'}
                                align={'center'}
                                gap={'50px'}
                            >
                                <Flex gap={'16px'} flex={'1'}>
                                    <Text
                                        textStyle={'semiTitle'}
                                        flex={'0 0 auto'}
                                        py={'8px'}
                                    >
                                        優免身份名稱
                                    </Text>
                                    {editStatus ? (
                                        <>
                                            <Input
                                                onChange={
                                                    handleChangeDrawerInput
                                                }
                                                maxLength={50}
                                                name="name"
                                                value={drawerFormData.name}
                                                flex={'1 0 300px'}
                                            ></Input>
                                        </>
                                    ) : (
                                        <>
                                            <Box
                                                flex={'1 0 300px'}
                                                p={'8px 16px'}
                                                border={'1px solid transparent'}
                                            >
                                                {drawerFormData.name}
                                            </Box>
                                        </>
                                    )}
                                </Flex>

                                {/* 如果有ode才是有官方連結的 沒有的是創新的 */}
                                <Box flex={'0 0 auto'}>
                                    {feeDiscountLists.length <
                                        feeTypeLists.length && (
                                        <>
                                            <Button
                                                colorScheme="rsPrimary"
                                                onClick={
                                                    handleNewFeeDiscountLists
                                                }
                                            >
                                                新增費用類別
                                            </Button>
                                        </>
                                    )}
                                </Box>
                            </Flex>

                            <Box
                                bg={'#F7F7F7'}
                                rounded={'15px'}
                                width={'full'}
                                borderBottom={'2px solid #ccc'}
                                overflow={'hidden'}
                            >
                                <Flex
                                    pos={'sticky'}
                                    top={0}
                                    zIndex={20}
                                    p={'16px 24px'}
                                    borderBottom={'2px solid #9B9B7A'}
                                    gap={'16px'}
                                >
                                    <Box flex={'1 0 0'}>費用類別</Box>
                                    <Box flex={'1 0 0'}>折扣類別</Box>
                                    <Box flex={'1 0 0'}>折抵金額/折扣</Box>
                                    <Center flex={'0 0 50px'}>刪除</Center>
                                </Flex>

                                <Box
                                    overflowY={'auto'}
                                    maxH={`calc(${window.innerHeight}px - 58px - 16px - 40px - 16px - 48px - 80px - 16px - 60px)`}
                                >
                                    {feeDiscountLists && feeDiscountLists.map((item, index) => (
                                        <Flex
                                            key={index}
                                            borderBottom={'1px solid #9B9B7A'}
                                            _last={{
                                                borderBottom:
                                                    '1px solid transparent',
                                            }}
                                            p={'8px 24px'}
                                            gap={'16px'}
                                            _hover={{ bg: '#fff' }}
                                        >
                                            <Box flex={'1 0 0'}>
                                                {editStatus ? (
                                                    <>
                                                        <Select
                                                            bg={'#fff'}
                                                            value={
                                                                feeDiscountLists[
                                                                    index
                                                                ].fee_type_code
                                                                    ? feeDiscountLists[
                                                                          index
                                                                      ]
                                                                          .fee_type_code
                                                                    : '請選擇'
                                                            }
                                                            onChange={event =>
                                                                handleChangeFeeDiscount(
                                                                    event,
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <option disabled>
                                                                請選擇
                                                            </option>
                                                            {feeTypeLists && feeTypeLists
                                                                .filter(
                                                                    feeType =>
                                                                        !selectedFeeTypeGids.has(
                                                                            feeType.code
                                                                        ) ||
                                                                        feeType.code ===
                                                                            feeDiscountLists[
                                                                                index
                                                                            ]
                                                                                .fee_type_code
                                                                )
                                                                .map(
                                                                    feeType => (
                                                                        <option
                                                                            key={
                                                                                feeType.code
                                                                            }
                                                                            value={
                                                                                feeType.code
                                                                            }
                                                                        >
                                                                            {
                                                                                feeType.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                        </Select>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Box
                                                            p={'8px 0px'}
                                                            rounded={'lg'}
                                                        >
                                                            {getFeeTypeName(
                                                                item.fee_type_code
                                                            )}
                                                        </Box>
                                                    </>
                                                )}
                                            </Box>
                                            <Box flex={'1 0 0'}>
                                                {editStatus ? (
                                                    <>
                                                        <Select
                                                            bg={'#fff'}
                                                            value={
                                                                feeDiscountLists[
                                                                    index
                                                                ]
                                                                    .arithmetic_code
                                                                    ? feeDiscountLists[
                                                                          index
                                                                      ]
                                                                          .arithmetic_code
                                                                    : '請選擇'
                                                            }
                                                            onChange={event =>
                                                                handleChangeArithmetic(
                                                                    event,
                                                                    index
                                                                )
                                                            }
                                                            name="arithmetic_code"
                                                        >
                                                            <option disabled>
                                                                請選擇
                                                            </option>
                                                            {arithmeticLists && arithmeticLists.map(
                                                                arithmetic => (
                                                                    <option
                                                                        key={
                                                                            arithmetic.code
                                                                        }
                                                                        value={
                                                                            arithmetic.code
                                                                        }
                                                                    >
                                                                        {
                                                                            arithmetic.name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </Select>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Box
                                                            p={'8px 16px'}
                                                            rounded={'lg'}
                                                        >
                                                            {getArithmeticName(
                                                                feeDiscountLists[
                                                                    index
                                                                ]
                                                                    .arithmetic_code
                                                            )}
                                                        </Box>
                                                    </>
                                                )}
                                            </Box>
                                            <Box
                                                flex={'1 0 0'}
                                                overflow={'hidden'}
                                            >
                                                {editStatus ? (
                                                    <>
                                                        <Input
                                                            bg={'#fff'}
                                                            value={item.value}
                                                            textAlign={'right'}
                                                            onChange={event =>
                                                                handleChangeFeeDiscountListsInput(
                                                                    event,
                                                                    index
                                                                )
                                                            }
                                                            name="value"
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Box
                                                            textAlign={'right'}
                                                            p={'8px 16px'}
                                                            rounded={'lg'}
                                                            bg={'#fff'}
                                                        >
                                                            {item.value}
                                                        </Box>
                                                    </>
                                                )}
                                            </Box>
                                            <Center
                                                flex={'0 0 50px'}
                                                textColor={'rsPrimary.500'}
                                                opacity={0.8}
                                                _hover={{
                                                    opacity: 1,
                                                    cursor: 'pointer',
                                                    textColor: 'red.500',
                                                }}
                                                onClick={() =>
                                                    handleDeactivatedDiscount(
                                                        item.data_id,
                                                        item.fee_type_code,
                                                        index
                                                    )
                                                }
                                            >
                                                <HeroIcons name="delete"></HeroIcons>
                                            </Center>
                                        </Flex>
                                    ))}
                                </Box>
                            </Box>
                            <Flex justify={'flex-end'} gap={'8px'}>
                                {editStatus ? (
                                    <>
                                        <Button
                                            onClick={handleCancel}
                                            colorScheme="rsSteel"
                                        >
                                            取消
                                        </Button>
                                        <Button
                                            colorScheme="rsSecondary"
                                            onClick={handleSave}
                                        >
                                            儲存
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            onClick={handleDeactivated}
                                            colorScheme="rsDanger"
                                        >
                                            停用
                                        </Button>
                                        <Button
                                            colorScheme="rsPrimary"
                                            onClick={handleEdit}
                                        >
                                            修改
                                        </Button>
                                    </>
                                )}
                            </Flex>
                        </Flex>
                    </Box>
                </>
            )}
            {editStatus && (
                <>
                    <div
                        ref={bgRef}
                        className=" bg-opacity-60  fixed top-0 left-0 z-10 w-full h-full"
                    ></div>
                </>
            )}
        </>
    );
};

export default PreferentialStatusTable;
