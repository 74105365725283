import React from 'react';
import {
    Box,
    Flex,
    Text,
    Center,
    Button,
    Select,
    Input,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { InsetShadow } from 'style/chakraStyles';
export const Selections = () => {
    return (
        <Flex gap={'24px'}>
            <Select w={'174px'} bg={'#fff'} sx={InsetShadow}>
                <option value="">耀聖診所*</option>
            </Select>
            <Flex w={'226px'} gap={'8px'} align={'center'}>
                <Text fontWeight={'semibold'} textColor={'#000'} flexShrink={0}>
                    費用年月
                </Text>
                <Input type={'date'} sx={InsetShadow}></Input>
            </Flex>
        </Flex>
    );
};
