// 批價模型（ChargeModel）
import { currentVisitInfoDataProps } from 'store/Registration';
import { cashDataStoreProps } from 'store/TreatmentManagement/Payment';
import { FeeState } from 'store/TreatmentManagement/Payment/useFeeStore';
import { TreatmentInfoProps } from 'store/TreatmentManagement/useTreatmentInfoStore';

import { PatientInfoProps } from 'store/Registration';

export const chargeLogic = (
    treatmentInfo: TreatmentInfoProps,
    feeData: FeeState,
    cashData: cashDataStoreProps,
    patientInfo: PatientInfoProps
) => {
    let finalData = {
        // IPatientBase
        Pat_Code: treatmentInfo.medical_record_number,
        Pat_Name: treatmentInfo.patient_name,
        Pat_IdnoType: (() => {
            switch (patientInfo.info.id_no_type) {
                case '1': // 身分證
                    return 0;
                case '2': // 居留證號
                    return 2;
                case '3': // 護照
                    return 1;
                default:
                    return 0;
            }
        })(), //雲端邏輯 1 身分證 2 居留證號 3 護照 ； 列印邏輯 0 身分證 1 護照 2 居留證號
        Pat_IDNo: treatmentInfo.id_no,
        Pat_Gender:
            treatmentInfo.sex === 'F'
                ? '女'
                : treatmentInfo.sex === 'M'
                  ? '男'
                  : '',
        Pat_Birth: treatmentInfo.birth_date?.toISOString(),
        Pat_BirthTime: '', // 目前應該沒
        Pat_Tel:
            patientInfo.phone.find(phone => phone.type === '2')?.number || '',
        Pat_Mobile:
            patientInfo.phone.find(phone => phone.type === '1')?.number || '',
        Pat_Addr: patientInfo.address?.[0]?.area_name
            ? `${patientInfo.address[0].area_name}` +
              `${patientInfo.address[0].city_name}` +
              `${patientInfo.address[0].content}`
            : '',
        Pat_Memo: '', // 病患備註 #20 押單收200塊, % 都不收, $20 掛號收200 等...規則
        Pat_Job: patientInfo.info.job,
        Pat_Mark: patientInfo.info.note, // 病患註記

        // IChargeBase
        Fee_Date: new Date().toDateString(), //必填但目前沒有 // 入帳日期
        Fee_TDiscount: 0, //應該沒有 // 折扣
        Fee_Amount: cashData.receivableFee ?? 0, // 應收
        Fee_Allowance: cashData.discount ?? 0, // 折讓
        // Fee_Total: cashData.receivableFee - cashData.discount,
        Fee_Total: cashData.actualFee, // 實收
        Fee_ReceiptNo: '',
        DataType: '', // 類別
        Dr_Name: treatmentInfo.doctor_name,
        Nhi_Card: treatmentInfo.card_no,
        Fee_Memo: '', // 批價備註

        // ICharge
        Handler: '', // 經手人
        Shd_CO: 0, // 要用level3去對 應收自付
        // Fee_HG: feeData.xFeeHG,
        Fee_HG: cashData.registrationFee,
        Fee_CO: cashData.deductibleFee, // 要用level3 去對 自付 //自付額
        Fee_HD: feeData.mFeeHD,
        Fee_EQ: feeData.xFeeEQ,
        Fee_PH: feeData.xFeePH,
        Fee_LPay: cashData.depositFee, // 押單費
        Fee_Drug: feeData.xDrugFee, // 藥費
        Fee_O: feeData.xFeeO,
        Fee_L: feeData.xFeeL,
        Fee_I: feeData.xFeeI,
        Fee_T: feeData.xFeeT,
        Fee_E: feeData.xFeeE,
        Fee_Q: feeData.xFeeQ,
        Fee_X: feeData.xFeeX,
        Fee_R: feeData.xFeeR,
        Fee_F: feeData.xFeeF,
        Fee_P: feeData.xFeeP,
        Fee_A: feeData.xFeeA,
        Fee_M: feeData.xFeeM,
        Fee_D: 0, //病床沒有
        Fee_S: feeData.xFeeS,
        Fee_N: 0, // 管灌
        Fee_U: 0, //精神
        Fee_B: feeData.xFeeB,
        Fee_Y: 0, //托嬰
        Fee_J: 0, //代辦
        Fee_K: 0, //其他
        Fee_HC: feeData.xFeePH,
        Nhi_PH: feeData.mFeePH,
        Nhi_Drug: feeData.mDrugFee, // 藥費(點數) // 畫面上是多少就是多少
        Nhi_O: feeData.mFeeO,
        Nhi_L: feeData.mFeeL,
        Nhi_I: feeData.mFeeI,
        Nhi_T: feeData.mFeeT,
        Nhi_E: feeData.mFeeE,
        Nhi_Q: feeData.mFeeQ,
        Nhi_X: feeData.mFeeX,
        Nhi_R: feeData.mFeeR,
        Nhi_F: feeData.mFeeF,
        Nhi_P: feeData.mFeeP,
        Nhi_A: feeData.mFeeA,
        Nhi_M: feeData.mFeeM,
        Nhi_D: 0, //病床
        Nhi_S: feeData.mFeeS,
        Nhi_N: 0, //管罐
        Nhi_U: 0, //精神
        Nhi_B: feeData.mFeeB,
        Nhi_Y: 0, //托嬰
        Nhi_J: 0, //代辦
        Nhi_K: 0, //其他費用
        TNhi_Points: feeData.mAllFee,
        Give_S: feeData.DeliverDrugFee, // 交付藥費 // 其實不會印出來
        Give_HD: 0, //沒有這東西
        Discount_Code: '', //沒有
        Discount_Name: '', //沒有
    };
    return finalData;
};
