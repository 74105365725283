import React from 'react';
import { Flex, Button, Checkbox, Text } from '@chakra-ui/react';

import { useIsReferralOpenStatus } from 'contexts/TreatmentManagement/BillingProcess';

export const Others = () => {
    const { setIsReferralOpenStatus } = useIsReferralOpenStatus();
    const handleExit = () => {
        setIsReferralOpenStatus(prev => !prev);
    };
    return (
        <>
            <Flex align={'center'} gap={'8px'} justify={'flex-end'}>
                <Checkbox fontWeight={'semibold'} textColor={'rsPrimary.500'}>
                    電子
                </Checkbox>
                <Button colorScheme="rsPrimary">完成不印</Button>
                <Button colorScheme="rsPrimary">完成列印</Button>
                <Button
                    bg={'#ECECEC'}
                    fontWeight={'semibold'}
                    border={'2px solid #646B48'}
                    onClick={handleExit}
                >
                    離開
                </Button>
            </Flex>
        </>
    );
};
