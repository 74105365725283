// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-gradient {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(334deg, #8fd6d2, #ced7aa, #c1e3a6);
    /* background-image: linear-gradient(
            45deg,
            rgba(194, 233, 221, 0.5) 1%,
            rgba(104, 119, 132, 0.5) 100%
        ),
        linear-gradient(-45deg, #494d71 0%, rgba(217, 230, 185, 0.5) 80%); */

    background-size: 500%;
    animation: gradient-animation 5s ease infinite;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/style/components/login.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,8DAA8D;IAC9D;;;;;4EAKwE;;IAExE,qBAAqB;IACrB,8CAA8C;AAClD;;AAEA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,8BAA8B;IAClC;IACA;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".login-gradient {\n    width: 100vw;\n    height: 100vh;\n    background: linear-gradient(334deg, #8fd6d2, #ced7aa, #c1e3a6);\n    /* background-image: linear-gradient(\n            45deg,\n            rgba(194, 233, 221, 0.5) 1%,\n            rgba(104, 119, 132, 0.5) 100%\n        ),\n        linear-gradient(-45deg, #494d71 0%, rgba(217, 230, 185, 0.5) 80%); */\n\n    background-size: 500%;\n    animation: gradient-animation 5s ease infinite;\n}\n\n@keyframes gradient-animation {\n    0% {\n        background-position: 0% 0%;\n    }\n    50% {\n        background-position: 100% 100%;\n    }\n    100% {\n        background-position: 0% 0%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
