/* Hooks */
import { useState, useEffect } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import moment from 'moment';

const RadioSelected = () => {
    const token = localStorage.getItem('token');

    const [currentTime, setCurrentTime] = useState('0:00:00');
    const [currentAmPm, setCurrentAmPm] = useState('時段');
    const [currentRocDate, setCurrentRocDate] = useState('民國 000 年 0月 0日');
    const [currentWeek, setCurrentWeek] = useState('星期');
    moment.locale('zh-tw');

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const time = moment(now).format('h:mm:ss');
            const ampm = moment(now).format('a');
            const year = moment(now).format('YYYY');
            const month = moment(now).format('M');
            const day = moment(now).format('D');
            const weekday = moment(now).format('dddd');

            const RocYear = parseInt(year) - 1911;
            const RocDate = `民國 ${RocYear} 年 ${month} 月 ${day} 日  `;

            setCurrentAmPm(ampm);
            setCurrentTime(time);
            setCurrentRocDate(RocDate);
            setCurrentWeek(weekday);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {/* 大時鐘 */}
            <Flex direction={'column'} gap={'8px'} minW={'400px'}>
                <Flex gap={'8px'} align={'center'}>
                    <Text fontSize={'24px'} textColor={'rsPrimary.500'}>
                        {currentRocDate}
                    </Text>
                    <Text fontSize={'24px'} textColor={'rsPrimary.500'}>
                        {currentWeek}
                    </Text>
                </Flex>
                <Flex
                    gap={'8px'}
                    textColor={'rsPrimary.500'}
                    align={'flex-start'}
                >
                    <Box
                        w={'40px'}
                        fontSize={'40px'}
                        lineHeight={'40px'}
                        whiteSpace={'wrap'}
                    >
                        {currentAmPm}
                    </Box>
                    <Box fontSize={'80px'} lineHeight={1} fontWeight={'bold'}>
                        {currentTime}
                    </Box>
                </Flex>
            </Flex>
        </>
    );
};

export default RadioSelected;
