// SummaryICCardUpdateData.ts
import { ICCardUpdateProps } from 'store/TreatmentManagement/ICCardUpdate/useICCardUpdateStore';
import { TreatmentInfoProps } from 'store/TreatmentManagement/useTreatmentInfoStore';
import { accItemsLevelProps } from 'service/accItemsLevelService';
import {
    CCPEProps,
    DxProps,
    PrescriptionProps,
} from 'store/TreatmentManagement';
import { DrugFreqListsProps } from 'store/DataSetting/useDrugFreqListStore';
import { cashDataStoreProps } from 'store/TreatmentManagement/Payment';
import { FeeState } from 'store/TreatmentManagement/Payment';
import { SeqNumber256N1Props } from 'functions/hisGetSeqNumber256N1';
import { RegisterBasicProps } from 'functions/hisGetRegisterBasic';
import { drugTypeListProps } from 'service/drugTypeListService';
import { InstitutionInfoProps } from 'store/DataSetting/useInstitutionInfoStore';
import { getDoseDay } from 'functions/getDoseDay';
import { ADDateToROCStringNoDot } from 'functions/ADDateToROCStringNoDot';
import { D02orderTypeLogic } from './ICDataUpdateLogic/D02orderTypeLogic';
import { D05Logic } from './ICDataUpdateLogic/D05Logic';
import { D14Logic } from './ICDataUpdateLogic/D14Logic';
import { ROCStringyyyMMddHHmmssToDateyyyyMMddHHmmss } from 'functions/ROCStringyyyMMddHHmmssToDateyyyyMMddHHmmss';
import { VHChisGetSeqNumber256N1ResultProps } from 'service/VHIC/VHChisGetSeqNumber256N1Format';
import { VHCHisWriteTreatmentCodeResProps } from 'service/VHIC/VHChisWriteTreatmentCodeService';
import { VHChisGetRegisterBasicJsonResProps } from 'service/VHIC/VHChisGetRegisterBasicJsonService';

export const SummaryICCardUpdateData = (
    treatmentInfo: TreatmentInfoProps,
    CCPEData: CCPEProps,
    dxList: DxProps[],
    prescriptionList: PrescriptionProps[] | null,
    // drugFreqListsData: DrugFreqListsProps[],
    cashDataStore: cashDataStoreProps,
    accItemsLevelList: accItemsLevelProps[],
    feeData: FeeState,
    if_formal_data: string,
    caseUid: string,
    drugTypeLists: drugTypeListProps[],
    institutionInfoData: InstitutionInfoProps,
    drugFreqLists: DrugFreqListsProps[],
    seqNumber256N1Result?: SeqNumber256N1Props,
    basicResult?: RegisterBasicProps,
    VHChisGetSeqNumber256N1Result? :VHChisGetSeqNumber256N1ResultProps,
    VHChisGetRegisterBasicJsonResult?: VHChisGetRegisterBasicJsonResProps
) => {
    const filteredDxList = dxList.filter(item => item.icd_code);
    const filteredPrescriptionList = prescriptionList
        ? prescriptionList.filter(item => item.drug_no)
        : [];
    const doseDay = getDoseDay(
        prescriptionList || [],
        drugTypeLists,
        drugFreqLists
    );
    console.log(doseDay, '我看一下這邊格式', typeof doseDay);
    console.log(basicResult, '這樣看看吧')
    console.log(VHChisGetRegisterBasicJsonResult, '看一下虛擬卡的基本資料')
    const treatment_no = 
    VHChisGetSeqNumber256N1Result?.medicalIdentificationCode 
    || seqNumber256N1Result?.pBuffer.visitIdentificationCode
    || treatmentInfo.medical_record?.mb1.treatment_no
    || ''
    const cardNumber = VHChisGetRegisterBasicJsonResult?.cardNo || basicResult?.PatCardNumber || basicResult?.cardNumber || ''; 
    const card_no = VHChisGetSeqNumber256N1Result?.medicalSerialNumber || seqNumber256N1Result?.pBuffer.visitSerialNumber ||   treatmentInfo.card_no || 
    '';
    const sam_code =  VHChisGetSeqNumber256N1Result?.samId || seqNumber256N1Result?.pBuffer?.samId || '';// 跟敬輝確認過了,就是這個
    const signature = VHChisGetSeqNumber256N1Result?.securitySignature || seqNumber256N1Result?.pBuffer.securitySignature || '';
    

    const treated_at = treatmentInfo.medical_record?.mb1.treated_at
        ? ROCStringyyyMMddHHmmssToDateyyyyMMddHHmmss(treatmentInfo.medical_record.mb1.treated_at)
        : seqNumber256N1Result?.pBuffer.visitDateTime || VHChisGetSeqNumber256N1Result?.visitDateTime || "";
    

    let finalData: ICCardUpdateProps = {
        ic_content: {
            drugs: filteredPrescriptionList.map((item, index) => {
                const matchedDrugTypeCode =
                    drugTypeLists.find(
                        subItem => subItem.data_id === item.drug_type
                    )?.code || '';

                const D02 = D02orderTypeLogic(
                    item,
                    matchedDrugTypeCode,
                    institutionInfoData
                );
                const D05 = D05Logic(item,  D02);
                const D14 = D14Logic(item, matchedDrugTypeCode);

                return {
                    treated_at: treated_at,
                    drug_category: D02, // Provide a default value
                    seq: (index + 1).toString(),
                    prescript_category: 'A', // 等下問
                    prescription_style: D05,
                    drug_no: item.drug_no,
                    drug_freq: item.freq_custom_code ?? '',
                    dose_days: Number(item.dose_day),
                    total_qty: Number(item.total_qty).toFixed(1).toString(),
                    signature: '',
                    drug_path: D14,
                    note: item.memo ?? '',

                    drug_type: item.drug_type,
                    data_id: item.data_id,
                    qty: Number(item.qty),
                    qty_in_day: Number(item.qty_in_day),
                    freq: item.freq,
                    given_qty: Number(item.given_qty),
                };
            }),
            diagnosis: filteredDxList.map(item => ({
                icd_code: item.icd_code,
            })),
            sam_code: sam_code, // 跟敬輝確認過了,就是這個
            card_number: cardNumber,
            patient_id_no: basicResult?.PatIDNo
                ? basicResult?.PatIDNo 
                : (basicResult?.idOrDocumentNumber 
                    ? basicResult?.idOrDocumentNumber 
                    : treatmentInfo.id_no),
            patient_birth_date:
                basicResult?.PatBirth ??
                (basicResult?.birthDate ??
                (treatmentInfo.birth_date
                    ? ADDateToROCStringNoDot(new Date(treatmentInfo.birth_date))
                    : '')),
            nhid: institutionInfoData.nhid,
            doctor_id_no: treatmentInfo.doctor_id_no,
            mt_type: treatmentInfo.selected_mt_type
                ? treatmentInfo.selected_mt_type
                : treatmentInfo.mt_type, // 診療室批價時更改的就醫類別
            treated_at: treated_at,
            xml_m12: '1', //目前沒有補卡
            card_no: card_no,
            signature: signature,
            treatment_no: treatment_no,
            dose_days:
                prescriptionList && prescriptionList.length === 0 ? 0 : (treatmentInfo.prescription_style === 1 ? 0 : Number(doseDay)), // 沒有先當交付給0
            // 有值 如果是 0醫師字條,2院內藥師 院內調劑 1交付調劑    這個欄位0自調 1交付
            prescription_style:
                treatmentInfo.prescription_style !== -1
                    ? treatmentInfo.prescription_style === 1
                        ? 1
                        : 0
                    : institutionInfoData.prescription_style ? institutionInfoData.prescription_style : 1,
            refill_a: prescriptionList && prescriptionList.length === 0 ? null : '1',

            fee: (feeData.mAllFee - cashDataStore.deductibleFee).toString(),
            copayment: cashDataStore.deductibleFee
                ? cashDataStore.deductibleFee.toString()
                : '0',
            benefit_type: treatmentInfo.benefit_type || '4',
            copayment_2: '0',
            copayment_3: feeData.mFeeHD ? feeData.mFeeHD.toString() : '0',
            copayment_4: feeData.mFeeEQ ? feeData.mFeeEQ.toString() : '0',
            institution_type: institutionInfoData.institution_type
                ? institutionInfoData.institution_type.toString()
                : '',
        },
        ori_medical_record_uid: treatmentInfo.data_id ?? '',
        card_no: card_no,
        treated_at: treated_at,
        mt_type: treatmentInfo.selected_mt_type
            ? treatmentInfo.selected_mt_type
            : treatmentInfo.mt_type, // 診療室批價時更改的就醫類別
        patient_id_no: basicResult?.PatIDNo
            ? basicResult?.PatIDNo 
            : (basicResult?.idOrDocumentNumber 
                ? basicResult?.idOrDocumentNumber 
                : treatmentInfo.id_no),
        patient_birth_date:
            basicResult?.PatBirth ??
            (basicResult?.birthDate ??
            (treatmentInfo.birth_date
                ? ADDateToROCStringNoDot(new Date(treatmentInfo.birth_date))
                : '')),
        patient_uid: treatmentInfo.patient_uid,
        patient_name: treatmentInfo.patient_name,
        medical_record_uid: caseUid,

        drugs: filteredPrescriptionList.map((item, index) => {
            const matchedDrugTypeCode =
                drugTypeLists.find(
                    subItem => subItem.data_id === item.drug_type
                )?.code || '';

            console.log(matchedDrugTypeCode, 'kkkkkkk');
            const D02 = D02orderTypeLogic(
                item,
                matchedDrugTypeCode,
                institutionInfoData
            );
            const D05 = D05Logic(item,  D02);
            const D14 = D14Logic(item, matchedDrugTypeCode);

            return {
                treated_at: treated_at,
                drug_category: D02, // Provide a default value
                seq: (index + 1).toString(),
                prescript_category: 'A',
                prescription_style: D05,
                drug_no: item.drug_no,
                drug_freq: item.freq_custom_code ?? '',
                dose_days: Number(item.dose_day), // Changed to Number type
                total_qty: Number(item.total_qty).toFixed(1).toString(),
                signature: '',
                drug_path: D14,
                note: item.memo ?? '',

                drug_type: item.drug_type,
                data_id: item.data_id,
                qty: Number(item.qty),
                qty_in_day: Number(item.qty_in_day),
                freq: item.freq,
                given_qty: Number(item.given_qty),
            };
        }),
    };

    return finalData;
};
