import React from 'react';
import { Thead, Tr, Th } from '@chakra-ui/react';

export const AppointmentListHeader = () => {
    return (
        <>
            <Thead bg={'#D9D9D9'}>
                <Tr>
                    <Th fontSize={'16px'} textAlign={'center'}>
                        取消
                    </Th>
                    <Th fontSize={'16px'} textAlign={'center'}>
                        修改
                    </Th>
                    <Th fontSize={'16px'}>日期</Th>
                    <Th fontSize={'16px'}>時段</Th>
                    <Th fontSize={'16px'}>序號</Th>
                    <Th fontSize={'16px'}>醫師</Th>
                    <Th fontSize={'16px'}>診間</Th>
                    <Th fontSize={'16px'}>姓名</Th>
                    <Th fontSize={'16px'}>病歷號</Th>
                    <Th fontSize={'16px'}>生日</Th>
                    <Th fontSize={'16px'}>預約項目</Th>
                    <Th fontSize={'16px'}>掛號註記</Th>
                    <Th fontSize={'16px'}>列印備註</Th>
                    <Th fontSize={'16px'}>電話</Th>
                </Tr>
            </Thead>
        </>
    );
};
