import {
    Box,
    Flex,
    Text,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    Tag,
    Radio,
    RadioGroup,
} from '@chakra-ui/react';
import React, { useEffect, useState, ChangeEvent } from 'react';
import { useVirtualCardState } from 'contexts/VirtualCardContext';
import { VHCHisReadCSVAndGetLastTokenProps } from '../../../service/VHIC/VHCHisReadCSVAndGetLastToken';
import {
    useRegisteredFlowStatus,
} from 'contexts/RegisteredFlowStatusContext';
import Swal from 'sweetalert2';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
// API

import { postGetVHCTeleMedicineReqCodeService } from 'service/VHIC/VHCHisReadCSVAndGetLastToken';
import { postVHChisGetRegisterBasicJsonService } from 'service/VHIC/VHChisGetRegisterBasicJsonService';
import { postGetQRCodeByVHCTLMRReqCodeService } from 'service/VHIC/getQRCodeByVHCTLMRReqCodeService';
import { postUploadqrcodeService } from 'service/VHIC/VHChisUploadqrcodeService';
import { useEscapeToClose } from 'functions/useEscapeToClose';
import { callPatientSearchService } from 'service/patientSearchService';
import { useCurrentVisitInfoStore, useSearchPatientStore } from 'store/Registration';
import { useUserStore } from 'store';
import { useRsGetStokenHelper } from 'service/RsService/RsGetStokenHelper';
//store
import { usePatientInfoStore } from 'store/Registration';
import { useVirtualCardAuthorizeTokenStore } from 'store/useVirtualCardAuthorizeTokenStore';
import { useVirtualCardSearchWayStore } from 'store/useVirtualCardSearchWayStore';
import { useInstitutionInfoStore } from 'store/DataSetting/useInstitutionInfoStore';
import { useCustomerAPI } from 'service/customerServiceHelper';
function ReadVirtualHealthInsuranceCard() {
    // const token = localStorage.getItem('token');
    const { userInfo } = useUserStore();
    const { patientInfo, updatePatientInfo } = usePatientInfoStore();
    const { institutionInfoData } = useInstitutionInfoStore();
    const { callCustomerAPI } = useCustomerAPI();
    const [hospID, setHospID] = useState(institutionInfoData.nhid);
    useEffect(() => {
        callCustomerAPI(); // 页面加载时检查是否需要调用 API
    }, []);


    useEffect(() => {
        // 当 institutionInfoData 更新后，判断数据是否为空再设置 hospID
        if (institutionInfoData.nhid) {
            setHospID(institutionInfoData.nhid);
        }
    }, [institutionInfoData]);
    const {
        showSearchPatientMedicalRecord,
        setShowSearchPatientMedicalRecord,
    } = useVirtualCardState();
    const { virtualCardAuthorizeToken } = useVirtualCardAuthorizeTokenStore();
    const { updateSearchPatientData, resetSearchPatientData } =
        useSearchPatientStore();
    const { updateCurrentVisitInfoData} = useCurrentVisitInfoStore()
    const {
        showPatientManagementStructureStatus,
        setShowPatientManagementStructureStatus,
        registeredFlowStatus,
        setRegisteredFlowStatus,
    } = useRegisteredFlowStatus();

    const { virtualCardSearchWay, updateVirtualCardSearchWay } = useVirtualCardSearchWayStore();
    const [sToken, setSToken] = useState(
        ''
    );
    useEffect(() => {
        setSToken(virtualCardAuthorizeToken.token);
    }, [virtualCardAuthorizeToken]);
    const { showReadVHICStatus, setShowReadVHICStatus } =
        useVirtualCardState();
    const [requestState, setRequestState] = useState(1); // 改狀態
    const [isRequestLoading, setIsRequestLoading] = useState(false); // 發送請求倒數 5s

    const [timeRemaining, setTimeRemaining] = useState<number>(300); // 5 minutes in seconds
    const [timerActive, setTimerActive] = useState<boolean>(false);

    const [rocID, setRocId] = useState<string>('');
    const [pendingRocID, setPendingRocID] = useState<string>('');
    // const [searchWay, setSearchWay] = useState('qrCode'); // qrCode:QRcode 掃描,  rocId:輸入身份證號


    const handleChangeSearchWay = (value: 'qrCode' | 'rocId') => {
        updateVirtualCardSearchWay(value);
        setQRCodeString('');
        setRocId('');
    };

    const [QRCodeString, setQRCodeString] = useState('');

    const closeBtn = () => {
        setShowReadVHICStatus(false);
        updateVirtualCardSearchWay('rocId'); // 關閉視窗後再打開會回到預設的 QR-Code
    };

    // 這是要從當地的excel中取得的
    const {checkAndFetchToken}  = useRsGetStokenHelper();

    // 發送請求權限按鈕
    const handleSendRequest = async () => {
        if (rocID.length < 10) {
            Swal.fire({
                title: '身分證字數有誤',
                icon: 'error',
                confirmButtonText: '好',
            });
        } else if (!/^[a-zA-Z]\d{9}$/.test(rocID)) {
            Swal.fire({
                title: '身分證格式錯誤',
                icon: 'error',
                confirmButtonText: '好',
            });
        } else {
            const currentSToken = await checkAndFetchToken();
            if (currentSToken) {
                setRequestState(2);
                setIsRequestLoading(true);
                setTimeRemaining(300);
                setTimerActive(true);
                setPendingRocID(rocID);
        
                let finalData = {
                    HospID: hospID,
                    sToken: currentSToken, // 使用直接获取到的最新 token
                    ROCID: encryptCode,
                };
                try {
                    await postVHCHisReadCSVAndGetLastTokenAPI(finalData);
                } catch (error) {
                    console.error('發送請求時發生錯誤:', error);
                    Swal.fire({
                        icon: 'error',
                        title: '發送請求失敗',
                        text: '請稍後再試',
                        confirmButtonText: '確定',
                    });
                }
            }
        }
    };
    // 發生請求病患虛擬健保卡pending
    const postVHCHisReadCSVAndGetLastTokenAPI = async (
        finalData: VHCHisReadCSVAndGetLastTokenProps
    ) => {
        try {
            const result =
                await postGetVHCTeleMedicineReqCodeService(finalData);
            // let result = {
            //     responseCode: 0,
            //     responseDesc: 'SUCCESS',
            //     responseTime: '2024-03-13T01:13:32Z',
            //     responseBody: {
            //         ErrorCode: 0,
            //         message: null,
            //         ReqCode: 'qnO5K2AccU',
            //         ReqCodeExpire: '20240313092332',
            //     },
            // };
            if (result.responseDesc === 'SUCCESS') {
                setQRCode(result.responseBody.ReqCode);
            }
        } catch {
            Swal.fire({
                icon: 'error',
                title: '讀卡機服務錯誤',
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };

    // 發送請求 倒數五分鐘
    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (timerActive && timeRemaining > 0) {
            intervalId = setInterval(() => {
                setTimeRemaining(prevTime => prevTime - 1);
            }, 1000);
        }

        if (timeRemaining == 0) {
            handleCancelBtn();
        }

        return () => clearInterval(intervalId);
    }, [timerActive, timeRemaining]);

    // 更改倒計時的時間格式
    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    // 取得授權按鈕
    const handleCheckRequest = async () => {
        try {
            const isSuccess = await QRCodeByVHCLMRReqCodeAPI();
            // await postFirstDiagnosisAPI();
            //好像不能坐在這
            if (isSuccess) {
                // await postQRCodeReaderUpdateqrcodeAPI();
            } else {
                Swal.fire({
                    icon: 'info',
                    title: '病患尚未同意，請稍後再試',
                    timer: 1500,
                    confirmButtonText: '好',
                });
            }
            setIsRequestLoading(false);
        } catch {
            Swal.fire({
                icon: 'error',
                title: '後端服務錯誤，取得授權失敗',
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };
    const QRCodeByVHCLMRReqCodeAPI = async () => {
        let finalData = {
            HospID: hospID,
            sToken: sToken,
            ROCID: encryptCode,
            ReqCode: QRCode,
        };
        try {
            const result =
                await postGetQRCodeByVHCTLMRReqCodeService(finalData);

            if (result.responseDesc === 'SUCCESS') {
                if (result.responseBody.ErrorCode === 0) {
                    setTimerActive(false);
                    // 同意
                    setRequestState(prev => prev + 1);
                    setQRCodeString(result.responseBody.QRCode);
                    return result;

                    // 比對是否為院所病患
                } else if (result.responseBody.ErrorCode == 1) {
                    // 等待中

                    return;
                } else if (result.responseBody.ErrorCode == 2) {
                    setTimerActive(false);

                    // 不同意
                    setRequestState(prev => prev + 2);
                } else if (result.responseBody.ErrorCode == -25) {
                    setTimerActive(false);

                    Swal.fire({
                        title: '此病患尚未申請虛擬健保卡',
                        icon: 'info',
                        confirmButtonText: '好',
                    });
                    // 不同意
                    // setRequestState(prev => prev + 2);
                    return;
                } else {
                    return;
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
        } catch {
            Swal.fire({
                icon: 'error',
                title: '後端服務錯誤',
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };

    // 發送請求後 放棄按鈕
    const handleCancelBtn = () => {
        setRequestState(1);
        setIsRequestLoading(false);
        setTimerActive(false);

        setTimeRemaining(300);
    };

    // 授權失敗重新嘗試按鈕
    const handleRetry = () => {
        setRequestState(1);
        handleCancelBtn();
    };

    // 控制身分證輸入欄位
    const handleRocIdInput = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toUpperCase();
        setRocId(value);
    };

    const [encryptCode, setEncryCode] = useState('');
    const formatDate = (date: Date, format: string): string => {
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        if (format === 'YYYYMMDD') {
            return year + month + day;
        } else if (format === 'YYMMDD') {
            return year.slice(-2) + month + day;
        } else {
            throw new Error('Unsupported date format');
        }
    };

    const padWithZerosNodeStyle = (
        input: string,
        length: number
    ): Uint8Array => {
        let paddedString = input.padEnd(length, '0');
        let encoder = new TextEncoder();
        return encoder.encode(paddedString);
    };

    const encryptReact = async (
        text: string,
        hospID: string
    ): Promise<string> => {
        const currentDate = new Date();
        const yyyymmdd = formatDate(currentDate, 'YYYYMMDD');
        const yymmdd = formatDate(currentDate, 'YYMMDD');
        console.log(hospID + yyyymmdd, '看一下hospID + yyyymmdd');
        console.log(hospID + yymmdd, '看一下hospID + yymmdd');
        console.log(hospID, '看一下hospID');

        const keyMaterial = padWithZerosNodeStyle(hospID + yyyymmdd, 32);
        const iv = padWithZerosNodeStyle(hospID + yymmdd, 16);

        let key, encryptedData;
        try {
            key = await window.crypto.subtle.importKey(
                'raw',
                keyMaterial,
                { name: 'AES-CBC' },
                false,
                ['encrypt']
            );

            encryptedData = await window.crypto.subtle.encrypt(
                { name: 'AES-CBC', iv },
                key,
                new TextEncoder().encode(text)
            );
        } catch (error) {
            console.error('加密過程中發生錯誤:', error);
            throw new Error('加密失敗');
        }

        const base64Encoded = window.btoa(
            String.fromCharCode(...new Uint8Array(encryptedData))
        );
        return base64Encoded;
    };

    useEffect(() => {
        const fetchEncryptedID = async () => {
            if (rocID.length === 10) {
                try {
                    console.log(institutionInfoData.nhid, '看一下institutionInfoData.nhid 加密');
                    console.log(rocID, '看一下rocID 加密');

                    const encryptedID = await encryptReact(rocID, institutionInfoData.nhid);
                    setEncryCode(encryptedID);
                } catch (error) {
                    console.error('加密過程中發生錯誤:', error);
                    // 這裡可以添加更多錯誤處理邏輯，例如顯示錯誤消息給用戶
                }
            }
        };
        fetchEncryptedID();
    }, [institutionInfoData.nhid, rocID]);
    useEffect(()=>{
        console.log(hospID, 'hospID', typeof hospID);
    }, [hospID])

    useEffect(() => {
        if (showReadVHICStatus) {
            setQRCodeString('');
            setRocId('');
        }
    }, [showReadVHICStatus]);

    const [QRCode, setQRCode] = useState('');
    const handleChangeQRCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputElement = event.target.value;

        const isNonLatin = /[^\u0000-\u00ff]/.test(inputElement);

        if (isNonLatin) {
            Swal.fire({
                icon: 'warning',
                title: '請切換為英文輸入法',
                timer: 1500,
                confirmButtonText: '好',
            });
            setQRCodeString('');
            return;
        }

        setQRCodeString(inputElement);
    };
    // const getVHCQRCodeCloudTokenAPI = async () => {
    //     console.log(QRCodeString, '看一下QRCodeString');
    //     try {
    //         const result = await getVHCQRCodeCloudTokenService(QRCodeString);
    //         console.log(result, '取得虛擬健保卡');
    //         if (result.ERRORCODE == 0) {
    //             console.log('有進來要再打api');
    //             VHChisGetRegisterBasicJsonAPI();
    //         }
    //     } catch {
    //         Swal.fire({
    //             icon: 'error',
    //             title: '後端服務錯誤',
    //             timer: 1500,
    //             showConfirmButton: false,
    //         });
    //     }
    // };
    const VHChisGetRegisterBasicJsonAPI = async () => {
        try {
            const result = await postVHChisGetRegisterBasicJsonService();

            if (result && result.ROCID) {
                const dbResult = await callPatientSearchService(
                    userInfo.token!,
                    'id_no',
                    result.ROCID
                );
                let newDate = new Date(result.birthDate);
                let birthDateString = result.birthDate;
                let ROCyyy = birthDateString.slice(0, 3);
                let ROCmm = birthDateString.slice(3, 5);
                let ROCdd = birthDateString.slice(5, 7);
                let newBirthDate = new Date(
                    parseInt(ROCyyy) + 1911,
                    parseInt(ROCmm) - 1,
                    parseInt(ROCdd)
                );
                // console.log(ROCyyy, ',', ROCmm, ',', ROCdd, '年月日');
                if (dbResult && dbResult.result === 'success') {
                    if (dbResult.lists && dbResult.lists.length > 1) {
                  
                        updateSearchPatientData({
                            keywords: result.ROCID,
                            searchLists: dbResult.lists,
                        });
                                            
                        //spring/17 先註解
                        // updateCurrentVisitInfoData({
                        //     card_no: 'V999',
                        // });
                        setShowSearchPatientMedicalRecord(true);
                        return;
                        // setSearchResult(trasDate);
                    } else if (dbResult.info) {
                        //spring/17 先註解 
                        // updateCurrentVisitInfoData({
                        //     card_no: 'V999',
                        // });
                        updatePatientInfo({
                            ...patientInfo,
                            info: dbResult.info,
                            email: dbResult.email,
                            emer: dbResult.emer_contactor,
                            address: dbResult.address,
                            phone: dbResult.phone,
                        });
                        setShowSearchPatientMedicalRecord(false);
                        setShowPatientManagementStructureStatus(true);
                        setRegisteredFlowStatus('second');
                        return;
                    } else {
                        updatePatientInfo({
                            ...patientInfo,
                            info: {
                                ...patientInfo.info,
                                name: result.name,
                                birth_date: newBirthDate,
                                sex: result.sex,
                                id_no: result.ROCID,
                                // medical_record_number: '',
                            },
                        });
                        //spring/17 先註解
                        // updateCurrentVisitInfoData({
                        //     card_no: 'V999',
                        // });
                        setShowSearchPatientMedicalRecord(false);
                    }
                    return;
                } else {
                    // 有拿到資料去初診新增
                    updatePatientInfo({
                        ...patientInfo,
                        info: {
                            ...patientInfo.info,
                            name: result.name.trim(),
                            birth_date: newBirthDate,
                            sex: result.sex,
                            id_no: result.ROCID,
                        },
                    });
                    // spring/17 先註解
                    // updateCurrentVisitInfoData({
                    //     card_no: 'V999',
                    // });
                    setShowPatientManagementStructureStatus(true);
                    setRegisteredFlowStatus('first');
                    return;
                }
            }
        } catch {
            Swal.fire({
                icon: 'error',
                title: '後端服務錯誤',
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };

    // 當QRCodeString到172碼直接打API去寫入中控台
    useEffect(() => {
        if (QRCodeString.length === 172) {
            // 直接寫入中控台
            postQRCodeReaderUpdateqrcodeAPI();
        } else if (QRCodeString.length > 172) {
            alert('超過172')
        }
    }, [QRCodeString]);

    // 傳到健保中控台的QRCode
    const postQRCodeReaderUpdateqrcodeAPI = async () => {
        try {
            const result =
                await postUploadqrcodeService(QRCodeString);
            if (result && result.ERRORCODE == 0 && !result.message) {
                // setShowPatientManagementStructureStatus(true)
                
                // 0406註掉的 應該會放下一層
                setShowReadVHICStatus(false);
                // setRegisteredFlowStatus('first');
                // setShowPatientManagementStructureStatus(true);
                // 比對AJ資料庫東西
                VHChisGetRegisterBasicJsonAPI();
            }
            return
        } catch {
            Swal.fire({
                icon: 'error',
                title: '後端服務錯誤',
                timer: 1500,
                showConfirmButton: false,
            });
            setRequestState(4);
        }
    };
    useEffect(() => {
        if (QRCode) {
            QRCodeByVHCLMRReqCodeAPI();
        }
    }, [QRCode]);

    useEscapeToClose(setShowReadVHICStatus);
    return (
        <>
            {showReadVHICStatus ? (
                <>
                    <Box
                        pos={'fixed'}
                        top={'0px'}
                        left={0}
                        bg={'rgba(0,0,0,0.4)'}
                        w={'100%'}
                        h={'100%'}
                        zIndex={60}
                    >
                        <Flex
                            pos={'relative'}
                            top={'10vh'}
                            left={'50%'}
                            bg={'#fff'}
                            p={'24px'}
                            rounded={'15px'}
                            className=" translate-x-[-50%]"
                            w={'1000px'}
                            gap={'16px'}
                            direction={'column'}
                            boxShadow={'0px 15px 30px #00000055'}
                        >
                            <Flex justify={'space-between'} align={'center'}>
                                <Heading
                                    fontSize={'20px'}
                                    textColor={'rsPrimary.500'}
                                >
                                    虛擬健保卡讀卡
                                </Heading>
                                <Box
                                    onClick={closeBtn}
                                    _hover={{ cursor: 'pointer' }}
                                    textColor={'rsPrimary.500'}
                                >
                                    <HeroIcons name="xmark"></HeroIcons>
                                </Box>
                            </Flex>

                            <hr />
                            <RadioGroup
                                onChange={handleChangeSearchWay}
                                value={virtualCardSearchWay.searchWay}
                            >
                                <Flex gap={'16px'}>
                                    <Radio value="qrCode">QR code</Radio>
                                    <Radio value="rocId">身份證字號驗證</Radio>
                                </Flex>
                            </RadioGroup>

                            {virtualCardSearchWay.searchWay === 'qrCode' ? (
                                <>
                                    <Flex gap={'8px'} align={'center'}>
                                        <InputGroup flex={'1 1 auto'}>
                                            <InputLeftElement
                                                pointerEvents="none"
                                                textColor={'rsSteel.500'}
                                            >
                                                <HeroIcons name="qrcode"></HeroIcons>
                                            </InputLeftElement>
                                            <Input
                                                onChange={handleChangeQRCode}
                                                w={'100%'}
                                                placeholder="請掃描 QR-Code"
                                                value={QRCodeString}
                                                autoFocus
                                                id="scanQRcode"
                                                pr={'40px'}
                                                inputMode={'text'}
                                                style={{ imeMode: 'disabled' }}
                                            ></Input>
                                        </InputGroup>
                                        <Button
                                            flex={'0 0 auto'}
                                            colorScheme="rsSecondary"
                                            hidden={QRCodeString === ''}
                                            onClick={() => {
                                                setQRCodeString('');
                                            }}
                                            opacity={0.5}
                                            _hover={{
                                                opacity: 1,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <HeroIcons name="xmark-sm"></HeroIcons>
                                            <Text>清除</Text>
                                        </Button>
                                    </Flex>
                                </>
                            ) : virtualCardSearchWay.searchWay === 'rocId' ? (
                                <>
                                    <Flex
                                        align={'center'}
                                        gap={'16px'}
                                        justify={'space-between'}
                                        p={'16px'}
                                        bg={'gray.50'}
                                        rounded={'4px'}
                                    >
                                        <Flex align={'center'} gap={'16px'}>
                                            <Text
                                                textStyle={'semiTitle'}
                                                flex={'0 0 auto'}
                                            >
                                                請輸入身份證字號
                                            </Text>
                                            <Input
                                                flex={'1 1 auto'}
                                                maxLength={10}
                                                placeholder="ex: A123456789"
                                                bg={'#fff'}
                                                name="rocID"
                                                value={rocID}
                                                onChange={handleRocIdInput}
                                                autoFocus
                                                onKeyUp={e => {
                                                    if (e.key === 'Enter') {
                                                        handleSendRequest();
                                                    }
                                                }}
                                                textTransform="uppercase"  // 添加這行
                                            ></Input>
                                        </Flex>
                                        <Flex gap={'8px'}>
                                            <Button
                                                colorScheme="rsSecondary"
                                                onClick={handleSendRequest}
                                                isLoading={isRequestLoading}
                                                loadingText="等待同意中"
                                                spinnerPlacement="start"
                                            >
                                                發送請求權限
                                                {/* 身分證輸入不完整 不能發送請求 */}
                                            </Button>
                                            {/* <Button onClick={handleSendFET}>
                                                這邊用遠傳測
                                            </Button> */}
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        borderLeft={'10px solid #eee'}
                                        px={'16px'}
                                        ml={'6px'}
                                        direction={'column'}
                                        gap={'8px'}
                                    >
                                        <Flex>
                                            <Text textStyle={'semiTitle'}>
                                                狀態：
                                            </Text>
                                            {requestState === 1 ? (
                                                <Text textColor={'rsSteel.500'}>
                                                    待命中
                                                </Text>
                                            ) : requestState === 2 ? (
                                                <Text
                                                    textColor={'rsWarning.600'}
                                                >
                                                    請求授權中{' '}
                                                    <Tag> {pendingRocID}</Tag>
                                                </Text>
                                            ) : requestState === 3 ? (
                                                <Text
                                                    textColor={'rsPrimary.500'}
                                                    fontWeight={'semibold'}
                                                >
                                                    已取得授權
                                                </Text>
                                            ) : requestState === 4 ? (
                                                <Text
                                                    textColor={'rsDanger.500'}
                                                >
                                                    授權失敗
                                                </Text>
                                            ) : (
                                                <></>
                                            )}
                                        </Flex>
                                        <Flex gap={'8px'} direction={'column'}>
                                            {requestState === 1 ? (
                                                <>
                                                    <Text fontSize={'sm'}>
                                                        1. 請輸入身分證字號
                                                    </Text>
                                                    <Text fontSize={'sm'}>
                                                        2. 點選發送請求
                                                    </Text>
                                                </>
                                            ) : requestState === 2 ? (
                                                <>
                                                    <Text fontSize={'sm'}>
                                                        1.
                                                        請引導病患使用『健保快易通
                                                        APP』
                                                        {'>>'}
                                                        『虛擬健保卡功能』
                                                        {'>>'} 點選『同意授權』
                                                    </Text>
                                                    <Text fontSize={'sm'}>
                                                        2. 請病患於 5
                                                        分鐘內完成上述動作{' '}
                                                        <Text
                                                            as={'span'}
                                                            color={
                                                                'rsDanger.500'
                                                            }
                                                            fontWeight={
                                                                'semibold'
                                                            }
                                                        >
                                                            ＊授權將於{' '}
                                                            {formatTime(
                                                                timeRemaining
                                                            )}{' '}
                                                            後失效
                                                        </Text>
                                                    </Text>
                                                    <Text fontSize={'sm'}>
                                                        3.
                                                        病患點選『同意』後，請點擊
                                                        <Text
                                                            as={'span'}
                                                            textColor={
                                                                'rsWarning.600'
                                                            }
                                                        >
                                                            『取得授權』
                                                        </Text>
                                                        按鈕
                                                    </Text>
                                                    <Text fontSize={'sm'}>
                                                        ＊
                                                        如欲重新填寫身份證證字號，請點選
                                                        <Text
                                                            as={'span'}
                                                            textColor={
                                                                'rsSteel.500'
                                                            }
                                                        >
                                                            『放棄』
                                                        </Text>
                                                        按鈕，再次嘗試
                                                    </Text>
                                                </>
                                            ) : requestState === 3 ? (
                                                <>
                                                    <Text
                                                        textColor={
                                                            'rsPrimary.500'
                                                        }
                                                        fontWeight={'semibold'}
                                                    >
                                                        1. 讀取病患資料....
                                                    </Text>
                                                    <Text
                                                        textColor={
                                                            'rsPrimary.500'
                                                        }
                                                        fontWeight={'semibold'}
                                                    >
                                                        2. 檢查是否為初診....
                                                    </Text>
                                                    <Text
                                                        textColor={
                                                            'rsPrimary.500'
                                                        }
                                                        fontWeight={'semibold'}
                                                    >
                                                        3. 前往新增基本資料....
                                                    </Text>
                                                </>
                                            ) : requestState === 4 ? (
                                                <>
                                                    <Text
                                                        fontSize={'sm'}
                                                        textColor={
                                                            'rsDanger.500'
                                                        }
                                                    >
                                                        病患不同意授權虛擬健保卡資料
                                                    </Text>
                                                    <Text fontSize={'sm'}>
                                                        請重新確認身分證證字號是否正確
                                                    </Text>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Flex>

                                        <Flex
                                            justify={'flex-end'}
                                            mt={'32px'}
                                            gap={'8px'}
                                        >
                                            {requestState === 2 ? (
                                                <>
                                                    <Button
                                                        colorScheme="rsWarning"
                                                        onClick={
                                                            handleCheckRequest
                                                        }
                                                    >
                                                        取得授權
                                                    </Button>
                                                    <Button
                                                        colorScheme="rsSteel"
                                                        onClick={
                                                            handleCancelBtn
                                                        }
                                                    >
                                                        放棄
                                                    </Button>
                                                </>
                                            ) : requestState === 4 ? (
                                                <>
                                                    <Button
                                                        colorScheme="rsDanger"
                                                        onClick={handleRetry}
                                                    >
                                                        再試一次
                                                    </Button>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Flex>
                                    </Flex>
                                </>
                            ) : (
                                <></>
                            )}
                        </Flex>
                    </Box>
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export default ReadVirtualHealthInsuranceCard;