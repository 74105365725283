import React from 'react';
import { WaitingList } from 'components/WaitingListTable/WaitingList';
import { VisitedList } from 'components/WaitingListTable/VisitedList';

import { AppointmentList } from 'components/WaitingListTable/AppointmentList';

import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { WaitingListsStatusProvider } from 'contexts/Registration/WaitingListsStatusContext';

export const ConsultingRoomWaitingListTable = () => {
    return (
        <Box p={'48px 20px 20px'}>
            <Tabs>
                <TabList bg={'gray.50'}>
                    <Tab textColor={'rsSecondary.500'}>候診</Tab>
                    <Tab textColor={'rsSecondary.500'}>已診</Tab>
                </TabList>
                <WaitingListsStatusProvider>
                    <TabPanels>
                        <TabPanel px={0}>
                            <WaitingList />
                        </TabPanel>
                        <TabPanel px={0}>
                            <VisitedList />
                        </TabPanel>
                    </TabPanels>
                </WaitingListsStatusProvider>
            </Tabs>
        </Box>
    );
};
