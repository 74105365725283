import React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Text,
} from '@chakra-ui/react';
type ListProps = {
    order?: string;
    code?: string;
    icd?: string;
    name?: string;
    part?: string;
};
export const DxList = () => {
    const list: ListProps[] = [];

    const fakeList = [
        {
            order: 1,
            code: 'URI',
            icd: 'J069',
            name: '急性上呼吸道感染',
            part: '',
        },

        {
            order: 2,
            code: '143',
            icd: 'R143',
            name: '胃腸脹氣',
            part: '',
        },
        {
            order: '1',
            code: 'URI',
            icd: 'J069',
            name: '急性上呼吸道感染',
            part: '',
        },
        {
            order: '2',
            code: '143',
            icd: 'R143',
            name: '胃腸脹氣',
            part: '',
        },
        {
            order: '1',
            code: 'URI',
            icd: 'J069',
            name: '急性上呼吸道感染',
            part: '',
        },
        {
            order: '2',
            code: '143',
            icd: 'R143',
            name: '胃腸脹氣',
            part: '',
        },
    ];

    return (
        <TableContainer my={'12px'} h={'120px'} overflowY={'scroll'}>
            <Table variant={'striped'} size={'sm'}>
                <Thead
                    bg={'#D9D9D9'}
                    pos={'sticky'}
                    top={0}
                    left={0}
                    zIndex={'10'}
                >
                    <Tr>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>排序</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>代碼</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>ICD10</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>中文病名</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>部位</Text>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {list &&
                        list.map((item, index) => (
                            <Tr key={index}>
                                <Td p={'4px 8px'}>{item.order}</Td>
                                <Td p={'4px 8px'}>{item.code}</Td>
                                <Td p={'4px 8px'}>{item.icd}</Td>
                                <Td p={'4px 8px'}>{item.name}</Td>
                                <Td p={'4px 8px'}>{item.part}</Td>
                            </Tr>
                        ))}
                </Tbody>
            </Table>
        </TableContainer>
    );
};
