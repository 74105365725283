import { create } from 'zustand';

export type ICCardUpdateRangeDataProps = {
    date: string;
    start_time: string;
    end_time: string;
    not_upload: string;
};

interface ICCardUpdateRangeState {
    icCardUpdateRangeData: ICCardUpdateRangeDataProps;
    updateICCardUpdateRangeData: (
        newData: Partial<ICCardUpdateRangeState['icCardUpdateRangeData']>
    ) => void;
    resetICCardUpdateRangeData: () => void;
}

const initialICCardUpdateRangeData = {
    date: new Date().toISOString().split('T')[0],
    start_time: '',
    end_time: '',
    not_upload: '1',
};

export const useICCardUpdateRangeStore = create<ICCardUpdateRangeState>(
    set => ({
        icCardUpdateRangeData: initialICCardUpdateRangeData,
        updateICCardUpdateRangeData: newData =>
            set(state => ({
                icCardUpdateRangeData: {
                    ...state.icCardUpdateRangeData,
                    ...newData,
                },
            })),
        resetICCardUpdateRangeData: () =>
            set(() => ({
                icCardUpdateRangeData: initialICCardUpdateRangeData,
            })),
    })
);
