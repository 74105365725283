import { Center } from '@chakra-ui/react';

const NavDataSettingIcon = ({ onClick }: any) => {
    return (
        <Center>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
            >
                <path
                    d="M10.3992 4.317C10.8252 2.561 13.3232 2.561 13.7492 4.317C13.8131 4.5808 13.9384 4.82578 14.1149 5.032C14.2914 5.23822 14.5141 5.39985 14.7649 5.50375C15.0156 5.60764 15.2874 5.65085 15.558 5.62987C15.8286 5.60889 16.0905 5.5243 16.3222 5.383C17.8652 4.443 19.6322 6.209 18.6922 7.753C18.5511 7.98466 18.4667 8.24634 18.4457 8.51677C18.4248 8.78721 18.468 9.05877 18.5717 9.30938C18.6755 9.55999 18.837 9.78258 19.0429 9.95905C19.2489 10.1355 19.4937 10.2609 19.7572 10.325C21.5132 10.751 21.5132 13.249 19.7572 13.675C19.4934 13.7389 19.2484 13.8642 19.0422 14.0407C18.836 14.2172 18.6744 14.4399 18.5705 14.6907C18.4666 14.9414 18.4234 15.2132 18.4443 15.4838C18.4653 15.7544 18.5499 16.0162 18.6912 16.248C19.6312 17.791 17.8652 19.558 16.3212 18.618C16.0896 18.4769 15.8279 18.3924 15.5574 18.3715C15.287 18.3506 15.0154 18.3938 14.7648 18.4975C14.5142 18.6013 14.2916 18.7627 14.1152 18.9687C13.9387 19.1747 13.8133 19.4194 13.7492 19.683C13.3232 21.439 10.8252 21.439 10.3992 19.683C10.3353 19.4192 10.21 19.1742 10.0335 18.968C9.85702 18.7618 9.63433 18.6001 9.38357 18.4963C9.1328 18.3924 8.86105 18.3491 8.59043 18.3701C8.31981 18.3911 8.05797 18.4757 7.82622 18.617C6.28322 19.557 4.51622 17.791 5.45622 16.247C5.59732 16.0153 5.68177 15.7537 5.7027 15.4832C5.72364 15.2128 5.68046 14.9412 5.57669 14.6906C5.47292 14.44 5.31148 14.2174 5.10549 14.0409C4.8995 13.8645 4.65478 13.7391 4.39122 13.675C2.63522 13.249 2.63522 10.751 4.39122 10.325C4.65502 10.2611 4.9 10.1358 5.10622 9.95929C5.31244 9.7828 5.47407 9.56011 5.57796 9.30935C5.68186 9.05859 5.72507 8.78683 5.70409 8.51621C5.68311 8.24559 5.59852 7.98375 5.45722 7.752C4.51722 6.209 6.28322 4.442 7.82722 5.382C8.82322 5.99 10.1232 5.452 10.3992 4.317Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.0742 12C15.0742 12.7956 14.7581 13.5587 14.1955 14.1213C13.6329 14.6839 12.8699 15 12.0742 15C11.2786 15 10.5155 14.6839 9.9529 14.1213C9.39029 13.5587 9.07422 12.7956 9.07422 12C9.07422 11.2044 9.39029 10.4413 9.9529 9.87868C10.5155 9.31607 11.2786 9 12.0742 9C12.8699 9 13.6329 9.31607 14.1955 9.87868C14.7581 10.4413 15.0742 11.2044 15.0742 12Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Center>
    );
};

export default NavDataSettingIcon;
