import React, { useEffect, useState } from 'react';
import WorkingScheduleTable from 'components/DataSettings/WorkingScheduleComponents/WorkingScheduleTable';

import { WeekRangeProvider } from 'contexts/WeekRangeContext';

interface WorkingSchedulePageProps {
    sidebarOpenRef: React.RefObject<any>;
}

const WorkingSchedulePage: React.FC<WorkingSchedulePageProps> = ({
    sidebarOpenRef,
}) => {
    const [bgOpenStatus, setBgOpenStatue] = useState(false);
    const handleOpen = () => {
        setBgOpenStatue(true);
    };
    const handleClose = () => {
        setBgOpenStatue(false);
    };
    return (
        <>
            <WeekRangeProvider>
                <WorkingScheduleTable sidebarOpenRef={sidebarOpenRef} />
            </WeekRangeProvider>
        </>
    );
};

export default WorkingSchedulePage;
