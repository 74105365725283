import { InstitutionInfoProps } from 'store/DataSetting/useInstitutionInfoStore';
import { UserInfoProps } from 'store/useUserStore';

export const hospitalLogic = (institutionInfoData: InstitutionInfoProps) => {
    let finalData = {
        //IHospitalBase
        Hosp_Name: institutionInfoData.hosp_name,
        E_Hosp_Name: '', //沒有醫院英文名
        Hosp_Code: institutionInfoData.nhid,
        Hosp_Tel: institutionInfoData.tel_number,
        Hosp_Addr: institutionInfoData.address,
        E_Hosp_Addr: '',
        Hosp_Fax: '',
        Hosp_Mail: '',
        Hosp_Practice: institutionInfoData.business_license,
        Hosp_Owner: institutionInfoData.responsible_doctor, //先給使用者
        Pharm_Name: institutionInfoData.pharmacist,
        QRCode: '',
    };
    return finalData;
};
