import {create} from 'zustand'
export type ICCardUpdateDiagnosisProps = {
    icd_code: string;
    ds?: string;
    doctor_orders?: string;
};


export type ICCardUpdateDrugProps = {
    treated_at: string;//[D01]就診日期時間
    drug_category: string;//[D02]醫令類別 一碼 要從耀聖藥類轉成醫令類別
    seq: string; //[D03]醫令序號
    prescript_category: string; //[D04]處方種類
    prescription_style: string //[D05]醫令調劑方式 去抓院所調劑方式 若有醫令自己才用自己的
    drug_no: string;//[D06]診療項目代號
    drug_freq: string; //[D08]用法(藥品使用頻率)
    dose_days: number; //[D09]天數
    total_qty: string;//[D10]總量
    signature: string; //[D11]處方簽章
    drug_path: string; //[D14]給藥途徑/作用部位
    note: string; //[D15]備註說明


    drug_type: string; 
    data_id: string;
     
    
    qty: number;
    qty_in_day: number;
    freq: string | undefined;
    site?: string;
    route?: string;
    given_qty?: number;
    add_days?: number;
    add_given_qty?: number;
    memo?: string;
    status?: number;
    doctor_order?: string;
    icd_code?: string;
    started_at?: string;
    ended_at?: string;
    op_idno?: string;
    special_mark?: string;
};

export type CashierProps = {
    acc_item_uid: string;
    cashier_type: number;
    price: number;
    discount?: number;
    final: number;
};

export type ICCardUpdateProps = {
    ic_content: ICCardContent;
    data_id?: string;
    ori_medical_record_uid?: string,
    patient_uid: string;
    medical_record_uid: string;
    patient_name: string;
    patient_id_no: string;
    patient_birth_date: string;
    mt_type: string;
    treated_at: string;
    card_no: string;
    drugs: ICCardUpdateDrugProps[];
};

export type ICCardContent = {
    drugs:  ICCardUpdateDrugProps[];
    diagnosis: ICCardUpdateDiagnosisProps[];
    sam_code: string; //[M01] 安於蠻模組代碼
    card_number: string; //[M02] 卡片號碼
    patient_id_no: string; //[M03] 身份證號或身分證明文件號碼
    patient_birth_date: string; //[M04] 出生日期
    nhid: string;  //[M05] 醫療院所代碼
    doctor_id_no: string; //[M06]醫事人員身份證號
    mt_type: string; //[M07]就醫類別
    treated_at: string; //[M11]就醫日期時間
    xml_m12: string;   //[M12]補卡註記
    card_no: string; //[M13] 就醫序號
    signature: string; //[M14]安全簽章
    treatment_no: string; //[M15]就醫識別碼
    dose_days: number; //[M20]給藥日份
    prescription_style: number; //[M23]處方調劑方式
    refill_a: string; //[M24] 可調劑次數_A-一班處方籤
    // diagnosis_0?: ICCardUpdateDiagnosisProps; //[M35]  主要診斷碼
    // diagnosis_1?: ICCardUpdateDiagnosisProps; //[M36]  次要診斷碼1
    // diagnosis_2?: ICCardUpdateDiagnosisProps; //[M37]  次要診斷碼2
    // diagnosis_3?: ICCardUpdateDiagnosisProps; //[M38]  次要診斷碼3
    // diagnosis_4?: ICCardUpdateDiagnosisProps; //[M39]  次要診斷碼4
    // diagnosis_5?: ICCardUpdateDiagnosisProps; //[M40]  次要診斷碼5
    fee: string; //[M44]門診醫療費用
    copayment: string;//[M45]門診部分負擔費用
    benefit_type: string;//[M51] 給付類別（M51）
    copayment_2: string; //[M53] 部分負擔-2
    copayment_3: string; //[M54] 部分負擔-3
    copayment_4: string; //[M55] 部分負擔-4
    institution_type: string; //[M56] 醫事類別

};

const initialDiagnosis: ICCardUpdateDiagnosisProps = {
    icd_code: '',
    ds: '',
    doctor_orders: ''
};

//0822備著
// const initialICCard: ICCardUpdateProps = {
//     diagnosis: [],
//     drugs: [],
//     // cashier: [],
//     sam_code: '',
//     card_number: '',
//     patient_id_no: '',
//     patient_birth_date: '',
//     refill_a: '',
 
//     doctor_id_no: '',
//     mt_type: '',
//     treated_at: '',
//     xml_m12: '',
//     card_no: '',
//     signature: '',
//     dose_days: '',
//     treatment_no: '',
//     prescription_style: 0,
//     nhid: '',
    
//     diagnosis_0: initialDiagnosis,
//     diagnosis_1: initialDiagnosis,
//     diagnosis_2: initialDiagnosis,
//     diagnosis_3: initialDiagnosis,
//     diagnosis_4: initialDiagnosis,
//     diagnosis_5: initialDiagnosis,
//     fee: '',
//     copayment: '',
//     benefit_type: '',
//     copayment_2: '',
//     copayment_3: '',
//     copayment_4: '',
//     institution_type: '',

    
    
//     patient_uid: '',
//     patient_name: '',
//     medical_record_uid: '',
//     medical_record_number: '',

   


//     // opd_uid: '',
//     // shift_uid: '',
//     // clinic_room_uid: '',
//     // doctor_uid: '',
//     // referral: '',
//     // special_treatment: '',
//     // ori_mt_type: undefined,

// };
const initialICCard: ICCardUpdateProps = {
    ori_medical_record_uid: '',
    ic_content: {
        drugs: [],
        diagnosis: [],
        sam_code: '',
        card_number: '',
        patient_id_no: '',
        patient_birth_date: '',
        nhid: '',
        doctor_id_no: '',
        mt_type: '',
        treated_at: '',
        xml_m12: '',
        card_no: '', 
        signature: '',
        treatment_no: '',
        dose_days: 0,
        prescription_style: 0,
        refill_a: '',
        // diagnosis_0: initialDiagnosis,
        // diagnosis_1: initialDiagnosis,
        // diagnosis_2: initialDiagnosis,
        // diagnosis_3: initialDiagnosis,
        // diagnosis_4: initialDiagnosis,
        // diagnosis_5: initialDiagnosis,
        fee: '',
        copayment: '',
        benefit_type: '',
        copayment_2: '',
        copayment_3: '',
        copayment_4: '',
        institution_type: '',
    },
    // cashier: [],
    
    patient_uid: '',
    medical_record_uid: '',
    patient_name: '',
    patient_id_no: '',
    patient_birth_date: '',
    // medical_record_number: '',
    
    mt_type: '',
    treated_at: '',
    card_no: '',
    drugs: [],
    
   


    // opd_uid: '',
    // shift_uid: '',
    // clinic_room_uid: '',
    // doctor_uid: '',
    // referral: '',
    // special_treatment: '',
    // ori_mt_type: undefined,

};


type ICCardUpdateStore = {
    icCardData: ICCardUpdateProps;
    setICCardData: (data: ICCardUpdateProps) => void;
    updateICCardData: (data: Partial<ICCardUpdateProps>) => void; // 调整为接收对象
    resetICCardData: () => void; // 添加 reset 方法
};

export const useICCardUpdateStore = create<ICCardUpdateStore>((set) => ({
    icCardData: initialICCard, // 使用初始值
    setICCardData: (data) => set({ icCardData: data }),
    updateICCardData: (data) =>
        set((state) => ({
            icCardData: {
                ...state.icCardData,
                ...data, // 合并传入的数据
            },
        })),
    resetICCardData: () =>
        set(() => ({
            icCardData: initialICCard, 
        })),
}));