import axios from 'axios';
import { PrintPrescriptionProps } from 'store/TreatmentManagement/Print/usePrintPrescriptionStore';
// import RsApiService from './RSApiService'

// Rs讀取卡片狀態
export const postRsPrintPrescriptionService = async (
    data: PrintPrescriptionProps
) => {
    try {
        const response = await axios.post(
            'http://localhost:23752/Print/Prescription',
            data,
            {
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                },
            }
        );

        console.log('單一列印結果', response.data);
        return response.data;
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.error('Error:', error.message);
        } else {
            console.error('An unknown error occurred');
        }
    }
};
