import { Image, Box } from '@chakra-ui/react';

import HomePageHISSystemIcon from '../../images/icon/HomePageHISSystemIcon.png';

const HomeBottomSide = () => {
    return (
        <>
            <Box w={'300px'} px={'16px'}>
                <Image src={HomePageHISSystemIcon} />
            </Box>
        </>
    );
};

export default HomeBottomSide;
