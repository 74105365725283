import {
    Box,
    Input,
    InputGroup,
    InputRightElement,
    ListItem
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import React, { useState } from 'react';

interface AddAllergyItemProps {
    isShowAll: boolean;
    handleAddItem: () => void;
    newAllergyItem: string;
    setNewAllergyItem: (value: string) => void;
}

export const AddAllergyItem: React.FC<AddAllergyItemProps> = ({
    isShowAll,
    handleAddItem,
    newAllergyItem,
    setNewAllergyItem
}) => {
    const [isCNTyping, setIsCNTyping] = useState<boolean>(false);

    return (
        <ListItem display={'flex'} hidden={!isShowAll}>
            <Box w={'30px'} mx={'auto'} opacity={0.5} _hover={{ opacity: 1, cursor: 'pointer' }} textColor={'rsPrimary.500'}>
                <HeroIcons name="add-fill" />
            </Box>
            <InputGroup>
                <Input
                    h={'28px'}
                    px={'4px'}
                    placeholder="新增過敏項目, 輸入後按 Enter 加入"
                    value={newAllergyItem}
                    onCompositionStart={() => setIsCNTyping(true)}
                    onCompositionEnd={() => setIsCNTyping(false)}
                    onChange={event => setNewAllergyItem(event.target.value)}
                    onKeyDown={e => {
                        if (!isCNTyping && e.key === 'Enter') {
                            handleAddItem();
                        } else if (!isCNTyping && e.key === 'Escape') {
                            setNewAllergyItem('');
                        }
                    }}
                />
                <InputRightElement h={'28px'}>
                    <Box
                        textColor={'rsPrimary.500'}
                        _hover={{ opacity: 1, cursor: 'pointer', filter: 'brightness(1.2)' }}
                        opacity={'0.5'}
                        onClick={handleAddItem}
                    >
                        <HeroIcons name="check" />
                    </Box>
                </InputRightElement>
            </InputGroup>
        </ListItem>
    );
};

