import React from 'react';
import { Text, Flex, Tooltip, Box } from '@chakra-ui/react';
import { useIsHistoryLoadingStatus } from 'contexts/TreatmentManagement/MedicalRecord/IsHistoryLoadingContext';
import Loading from 'components/CustomComponents/Loading'; // 确保导入加载组件

type DiagnosisProps = {
    icd_10_in_use_uid: string;
    icd_code: string;
    ds: string;
    name_tw: string;
};

type DxICD10CopyProps = {
    diagnosis: DiagnosisProps[];
};

export const DxICD10Copy: React.FC<DxICD10CopyProps> = ({ diagnosis }) => {
    const { isHistoryLoadingStatus } = useIsHistoryLoadingStatus();
    
    return (
        <>
            <Text textColor={'#646B48'} fontWeight={'semibold'}>
                Dx（ICD10）
            </Text>
            <Box position="relative">
                <Flex flexWrap={'wrap'} gap={'4px'}>
                    {diagnosis ? (
                        diagnosis.map((item, index) => (
                            <Tooltip
                                key={index}
                                label={item.name_tw}
                                hasArrow
                                bg={'gray'}
                                boxShadow={'lg'}
                                zIndex={10}
                            >
                                <Box
                                    flex={'1 0 calc(33% - 4px)'}
                                    bg={index === 0 ? '#D08C60' : '#BFC6A2'}
                                    textColor={index === 0 ? '#fff' : '#000'}
                                    whiteSpace={'nowrap'}
                                    textOverflow={'ellipsis'}
                                    overflow={'hidden'}
                                    p={'4px 16px'}
                                    rounded={'md'}
                                >
                                    {item.name_tw}
                                </Box>
                            </Tooltip>
                        ))
                    ) : (
                        <Box
                            flex={'1 0 calc(33% - 4px)'}
                            whiteSpace={'nowrap'}
                            textOverflow={'ellipsis'}
                            overflow={'hidden'}
                            p={'4px 16px'}
                            rounded={'md'}
                        >
                            查無資訊
                        </Box>
                    )}
                </Flex>
                {isHistoryLoadingStatus && (
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bg="rgba(255, 255, 255, 0.7)"
                    >
                        <Loading />
                    </Box>
                )}
            </Box>
        </>
    );
};
