export const ADDateTransToROCStr = (dateInput: Date | string) => {
    // Attempt to convert to a Date object if `dateInput` is not already one
    const date = dateInput instanceof Date ? dateInput : new Date(dateInput);

    // Check if `date` is a valid Date object
    if (isNaN(date.getTime())) {
        return '';
    } else {
        const rocYear = date.getFullYear() - 1911;
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        const rocDate = `${rocYear}.${month}.${day}`;
        return rocDate;
    }
};
