import { TableContainer, Table } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { VisitedListHeader } from './VisitedListHeader';
import { VisitedListBody } from './VisitedListBody';
import { WaitingListsFilterBar } from '../WaitingList/WaitingListsFilterBar';
import { WaitingListsStatusProvider } from 'contexts/Registration/WaitingListsStatusContext';
import { callRegistrationOpdListsService } from 'service/opdListsService';
import { useEffect, useState } from 'react';
import { useUserStore } from 'store';
import { Flex } from '@chakra-ui/react';
import { RegisteredFlowStatusProvider } from 'contexts/RegisteredFlowStatusContext';
import { RegistrationListStatusProvider } from 'contexts/Registration/RegistrationListStatusContext';
import { RegistrationLIstFilterBar } from './RegistrationLIstFilterBar';

export const VisitedList = () => {
    let location = useLocation();

    const pathName = location.pathname;
    const pathSegments = pathName.split('/');
    const lastSegment = pathSegments.pop();
    return (
        <>
            <RegistrationListStatusProvider>
                <RegistrationLIstFilterBar />
                <Flex
                    direction={'column'}
                    maxH={
                        lastSegment === 'RegisteredPage'
                            ? `${window.innerHeight - 380}px`
                            : 'auto'
                    }
                    overflow={'scroll'}
                >
                    <VisitedListHeader />
                    <Flex direction={'column'}>
                        <VisitedListBody />
                    </Flex>
                </Flex>
                {/* <TableContainer
                    maxH={
                        lastSegment === 'RegisteredPage'
                            ? `${window.innerHeight - 374}px`
                            : 'auto'
                    }
                    overflowY={'scroll'}
                >
                    <Table>
                        <VisitedListHeader />
                        <Flex direction={'column'}>
                            <VisitedListBody/>
                        </Flex>
                    </Table>
                </TableContainer> */}
            </RegistrationListStatusProvider>
        </>
    );
};
