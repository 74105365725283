import React from 'react';
import { Flex, Text, Textarea } from '@chakra-ui/react';

export const DoctorNotice = () => {
    const handleChangeInput = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        console.log('textarea');
    };
    return (
        <>
            <Flex gap={'8px'} align={'center'}>
                <Text textStyle={'semiTitle'} w={'64px'} flexShrink={0}>
                    醫師交班 注意事項
                </Text>
                <Textarea
                    bg={'#fff'}
                    resize={'none'}
                    h={'80px'}
                    value={''}
                    onChange={handleChangeInput}
                ></Textarea>
            </Flex>
        </>
    );
};
