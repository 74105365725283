import { Button, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { usePatientInfoStore } from 'store/Registration';
import { useTreatmentInfoStore } from 'store/TreatmentManagement';
import { useComputeFeeStatus } from 'contexts/TreatmentManagement/ComputeFeeContext';

import {
    useDxStore,
    useCCPEStore,
    usePrescriptionStore,
} from 'store/TreatmentManagement';

import { useIsBillingOpenStatus } from 'contexts/TreatmentManagement/BillingProcess';

export const BillCalculator = () => {
    const { setIsBillingOpenStatus } = useIsBillingOpenStatus();
    const { patientInfo } = usePatientInfoStore();
    const { prescriptionList, setPrescriptionList } = usePrescriptionStore();
    const { treatmentInfo } = useTreatmentInfoStore();
    const { CCPEData } = useCCPEStore();
    const { computeFeeStatus, setComputeFeeStatus } = useComputeFeeStatus();

    const { dxList } = useDxStore();

    const handleShowBill = () => {
        const filteredDxList = dxList.filter(item => item.data_id);
        const filteredPrescriptionList = prescriptionList.filter(
            item => item.data_id
        );
        setPrescriptionList(filteredPrescriptionList);
        console.log(filteredPrescriptionList, '這邊應該要少一個醫令');

        if (dxList[0].icd_code === '') {
            Swal.fire({
                icon: 'warning',
                title: '無填寫主診斷',
                timer: 1500,
                showConfirmButton: false,
            });
            setIsBillingOpenStatus(false);
        } else {
            setIsBillingOpenStatus(true);
            setComputeFeeStatus(true);
        }
        //  else if (CCPEData.cc === '') {
        //     Swal.fire({
        //         icon: 'warning',
        //         title: '無填寫 CCPE',
        //         timer: 1500,
        //         showConfirmButton: false,
        //     });
        //     setIsBillingOpenStatus(false);
        // } else if (filteredPrescriptionList.length === 0) {
        //     Swal.fire({
        //         icon: 'warning',
        //         title: '無填寫醫令處方',
        //         timer: 1500,
        //         showConfirmButton: false,
        //     });
        //     setIsBillingOpenStatus(false);
        // } else {
        //     setIsBillingOpenStatus(true);
        //     setComputeFeeStatus(true)
        // }

        // postMedicalRecordAPI(finalData);

        // setIsBillingOpenStatus(true);
    };
    // const postMedicalRecordAPI = async (finalData: finalDataProps) => {
    //     const result = await postMedicalRecordService(
    //         userInfo.token,
    //         finalData
    //     );
    //     console.log(result, '成功嗎');
    // };

    return (
        <>
            <Tooltip
                label={'病歷撰寫完成進入批價畫面'}
                hasArrow
                bg={'#45533F'}
                openDelay={1000}
            >
                <Button
                    colorScheme="rsPrimary"
                    bg={'#45533F'}
                    boxShadow={' 3px -3px 2px 0px rgba(0, 0, 0, 0.50) inset'}
                    onClick={handleShowBill}
                >
                    完成批價
                </Button>
            </Tooltip>
        </>
    );
};
