import { useEffect } from 'react';

export function useEscapeToClose(setShow: (show: boolean) => void) {
    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (event.key === 'Escape' && event.ctrlKey) {
                setShow(false); 
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
        window.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShow]);
}

