import axios from 'axios';
// import RsApiService from './RSApiService'

export type TreatNumNoICCardResProps = {
    ERRORCODE: number;
    pBuffer: string;
};

export type pBufferProps = {
    computerDateTime: string; // 電腦日期時間 (1-13)
    medicalInstitutionCode: string; // 醫療院所代碼 (14-23)
    medicalTreatmentIdentificationCode: string; // 就醫識別碼 (24-43)
};

export type TreatNumNoICCardReqProps = {
    pPatientID: string; // 保險對象身分證號，長度 11 bytes(含 null char)
    cTreatHospCode: string; // 醫療院所代號，長度 11 bytes(含 null char)
};

// 確保輸入字串長度符合要求的輔助函數
const padAndTruncate = (str: string, length: number): string => {
    if (str.length >= length) {
        return str.slice(0, length);
    }
    return str.padEnd(length, ' ');
};

// 驗證並格式化輸入參數的函數
export const formatTreatNumNoICCardReq = (
    req: TreatNumNoICCardReqProps
): TreatNumNoICCardReqProps => {
    const formattedReq = {
        pPatientID: padAndTruncate(req.pPatientID, 11), // 11 字元
        cTreatHospCode: padAndTruncate(req.cTreatHospCode, 11), // 11 字元
    };
    console.log('pPatientID 長度:', formattedReq.pPatientID.length);
    console.log('cTreatHospCode 長度:', formattedReq.cTreatHospCode.length);
    return formattedReq;
};

// Rs中控讀卡
export const callRshisGetTreatNumNoICCardService = async (
    data: TreatNumNoICCardReqProps
): Promise<TreatNumNoICCardResProps> => {
    try {
        const response = await axios.post(
            'http://localhost:23752/cshis/RshisGetTreatNumNoICCard',
            data,
            {
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                },
            }
        );

        console.log('response: ', response);

        const resultObj = JSON.parse(response.data);

        console.log('resultObj.ERRORCODE: ', resultObj.ERRORCODE);

        if (resultObj.ERRORCODE === 0) {
            console.log('成功取得就醫識別碼: ', resultObj);
            return resultObj;
        } else {
            console.log('取得就醫識別碼失敗: ', resultObj);
            return resultObj;
        }
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.error('Error:', error.message);
        } else {
            console.error('An unknown error occurred');
        }

        throw error;
    }
};
