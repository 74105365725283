import { Center } from '@chakra-ui/react';

const NavRegistrationIcon = ({ onClick }: any) => {
    return (
        <Center>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
            >
                <path
                    d="M3.07422 10H21.0742M7.07422 15H8.07422M12.0742 15H13.0742M6.07422 19H18.0742C18.8699 19 19.6329 18.6839 20.1955 18.1213C20.7581 17.5587 21.0742 16.7956 21.0742 16V8C21.0742 7.20435 20.7581 6.44129 20.1955 5.87868C19.6329 5.31607 18.8699 5 18.0742 5H6.07422C5.27857 5 4.51551 5.31607 3.9529 5.87868C3.39029 6.44129 3.07422 7.20435 3.07422 8V16C3.07422 16.7956 3.39029 17.5587 3.9529 18.1213C4.51551 18.6839 5.27857 19 6.07422 19Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Center>
    );
};

export default NavRegistrationIcon;
