import { Box } from '@chakra-ui/react';

const ConsultingRoomCloudMedicationHistoryIcon = ({ onClick }: any) => {
    return (
        <Box>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    d="M6.66654 13.3333C5.62734 13.334 4.62538 12.9464 3.85727 12.2464C3.08916 11.5465 2.61033 10.5847 2.51474 9.54994C2.41915 8.51514 2.7137 7.48198 3.34059 6.65316C3.96748 5.82434 4.88146 5.25969 5.9032 5.07C6.08267 4.11473 6.59033 3.2522 7.33839 2.63159C8.08645 2.01097 9.02788 1.6713 9.99987 1.6713C10.9719 1.6713 11.9133 2.01097 12.6613 2.63159C13.4094 3.2522 13.9171 4.11473 14.0965 5.07C15.1149 5.26355 16.0245 5.82956 16.6481 6.6576C17.2716 7.48564 17.5642 8.51629 17.4689 9.54845C17.3735 10.5806 16.8971 11.5402 16.1324 12.24C15.3677 12.9398 14.3697 13.3296 13.3332 13.3333M7.49987 15.8333L9.99987 18.3333M9.99987 18.3333L12.4999 15.8333M9.99987 18.3333V8.33334"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Box>
    );
};

export default ConsultingRoomCloudMedicationHistoryIcon;
