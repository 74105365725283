import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ShowRegisteredFormStatusContextValue {
    showRegisteredFormStatus: boolean;
    setShowRegisteredFormStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowRegisteredFormStatusContext = createContext<
    ShowRegisteredFormStatusContextValue | undefined
>(undefined); // Set the initial value as undefined

interface ShowRegisteredFormStatusProviderProps {
    children: ReactNode;
}

export const ShowRegisteredFormStatusProvider: React.FC<
    ShowRegisteredFormStatusProviderProps
> = ({ children }) => {
    const [showRegisteredFormStatus, setShowRegisteredFormStatus] =
        useState(false);

    return (
        <ShowRegisteredFormStatusContext.Provider
            value={{
                showRegisteredFormStatus,
                setShowRegisteredFormStatus,
            }}
        >
            {children} {/* Fix: Remove double curly braces around children */}
        </ShowRegisteredFormStatusContext.Provider>
    );
};

export const useShowRegisteredFormStatus =
    (): ShowRegisteredFormStatusContextValue => {
        const context = useContext(ShowRegisteredFormStatusContext); // Fix: Remove the typo 'con'

        if (!context) {
            throw new Error(
                'useShowRegisteredFormStatus must be used within a ShowRegisteredFormStatusProvider'
            );
        }
        return context;
    };
