import {
    Box,
    Flex,
    Text,
    Heading,
    Input,
    Button,
    Tag,
    Tabs,
    Tab,
    TabList,
} from '@chakra-ui/react';
import { useEffect, useState, ChangeEvent } from 'react';
import { useRegisteredFlowStatus } from 'contexts/RegisteredFlowStatusContext';
import { AsideMenu } from './AsideMenu';
import BasicDataSetting from './BasicData';
import CurrentRegistered from './CurrentRegistered';
import { SequentialPrescription } from './SequentialPrescription';
import { MedicalHistory } from './MedicalHistory';
import { Receivables } from './Receivables';
import { PrintProofAndReceipt } from './PrintProofAndReceipt';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { useIsChronicStatus } from 'contexts/Registration/IsChronicContext';
import Swal from 'sweetalert2';
import { useIsPrintProofOpenStatus } from 'contexts/Registration/IsPrintProofOpenContext';
import { DxProof } from './PrintProofAndReceipt/DxProof';
import { useCurrentVisitInfoStore } from 'store/Registration';
import { useSearchPatientStore } from 'store/Registration';
import { useVitalSignsDataStore } from 'store/Registration';

//store
import { usePatientInfoStore } from 'store/Registration';
function MainFuncStructure() {
    const {
        registeredFlowStatus,
        setRegisteredFlowStatus,

        showPatientManagementStructureStatus,
        setShowPatientManagementStructureStatus,
    } = useRegisteredFlowStatus();
    const { patientInfo, updatePatientInfo, resetPatientInfo } =
        usePatientInfoStore();

    const { isChronicStatus, setIsChronicStatus } = useIsChronicStatus();
    const {currentVisitInfoData, resetCurrentVisitInfoData} = useCurrentVisitInfoStore()
    const {searchPatientData, resetSearchPatientData}= useSearchPatientStore()
    const {vitalSignsData, resetVitalSignsData} = useVitalSignsDataStore()

    console.log('currentVisitInfoData:', currentVisitInfoData);
    console.log('patientInfo:', patientInfo);
    console.log('searchPatientData:', searchPatientData);
    console.log('vitalSignsData:', vitalSignsData);
    useEffect(() => {
        if (
            showPatientManagementStructureStatus &&
            isChronicStatus &&
            patientInfo.info.name === '何靜得' // 先假設何靜得有慢性病
        ) {
            Swal.fire({
                icon: 'info',
                title: '此病患有連續處方簽，是否領藥？',
                showCancelButton: true,
                showConfirmButton: true,
                cancelButtonText: '否',
                confirmButtonText: '是',
            }).then(result => {
                if (result.isConfirmed) {
                    setRegisteredFlowStatus('SequentialPrescription');
                } else {
                    return;
                }
            });
        } else {
        }
    }, [isChronicStatus]);

    const handleClose = () => {
        resetAllRegistrationStores()
        setShowPatientManagementStructureStatus(false);
        setIsChronicStatus(false);
        
    };
    const resetAllRegistrationStores = () => {
        resetCurrentVisitInfoData();
        resetPatientInfo();
        resetVitalSignsData();
        resetSearchPatientData();
    }


    const { isPrintProofOpenStatus, setIsPrintProofOpenStatus } =
        useIsPrintProofOpenStatus();

    return (
        <>
            {showPatientManagementStructureStatus && (
                <>
                    <Flex
                        pos={'fixed'}
                        top={0}
                        left={0}
                        bg={'rgba(0,0,0,0.4)'}
                        justify={'center'}
                        w={'100%'}
                        h={'100%'}
                        zIndex={60}
                    >
                        <Box
                            pos={'relative'}
                            top={'50%'}
                            left={'0px'}
                            transform={'translateY(-50%)'}
                            bg={'#fff'}
                            rounded={'15px'}
                            alignSelf={'flex-start'}
                            w={'1400px'}
                            maxH={'100vh'}
                            overflowY={'scroll'}
                        >
                            <Flex
                                justify={'space-between'}
                                align={'center'}
                                p={'8px 16px'}
                                bg={'#fff'}
                                pos={'sticky'}
                                top={0}
                                zIndex={10}
                            >
                                <Heading
                                    fontSize={'20px'}
                                    textColor={'rsPrimary.500'}
                                    fontWeight={'normal'}
                                >
                                    {(() => {
                                        switch (registeredFlowStatus) {
                                            case 'first':
                                                return '初診新增';
                                            case 'second':
                                                return '當次掛號';
                                            case 'third':
                                                return '????';
                                            case 'SequentialPrescription':
                                                return '連箋領藥';
                                            case 'MedicalHistory':
                                                return '歷史病歷';
                                            case 'Receivables':
                                                return '欠還款管理';

                                            default:
                                                return null;
                                        }
                                    })()}
                                    {/* <Box>{patientInfo.info.data_id ?? ''}</Box> */}
                                </Heading>

                                <Box
                                    cursor={'pointer'}
                                    opacity={0.8}
                                    _hover={{
                                        opacity: 1,
                                        cursor: 'pointer',
                                    }}
                                    textColor={'rsPrimary.500'}
                                    onClick={handleClose}
                                >
                                    <HeroIcons name="xmark"></HeroIcons>
                                </Box>
                            </Flex>
                            <Flex gap={'8px'} p={'0px 16px 16px'}>
                                <AsideMenu />
                                {(() => {
                                    switch (registeredFlowStatus) {
                                        case 'first':
                                            return <BasicDataSetting />;
                                        case 'second':
                                            return <CurrentRegistered />;
                                        case 'third':
                                            return <BasicDataSetting />;
                                        case 'SequentialPrescription':
                                            return <SequentialPrescription />;
                                        case 'MedicalHistory':
                                            return <MedicalHistory />;
                                        case 'Receivables':
                                            return <Receivables />;
                                        case 'PrintProofAndReceipt':
                                            return <PrintProofAndReceipt />;

                                        default:
                                            return null;
                                    }
                                })()}
                            </Flex>
                        </Box>
                    </Flex>
                </>
            )}

            {isPrintProofOpenStatus && <DxProof />}
        </>
    );
}

export default MainFuncStructure;
