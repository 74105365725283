import React from 'react';
import {
    Flex,
    Box,
    Text,
    Input,
    InputGroup,
    InputRightAddon,
    Textarea,
    Button,
} from '@chakra-ui/react';
import {
    TableContainer,
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
} from '@chakra-ui/react';
import { InsetShadow, InsetShadowBox } from 'style/chakraStyles';

export const Debt = () => {
    return (
        <>
            <Flex
                textColor={'#fff'}
                bg={'#C5713A'}
                p={'8px 16px'}
                justify={'space-between'}
                rounded={'lg'}
                boxShadow={'inset 4px 2px 2px rgba(0,0,0,0.25)'}
                mb={'8px'}
            >
                <Text>總欠款金額</Text>

                <Text fontSize={'xl'}> 元</Text>
            </Flex>

            <Box bg={'#F5F5F5'} rounded={'lg'} p={'8px 16px'} mb={'8px'}>
                <Text textStyle={'semiTitle'}>本次欠款</Text>
                <Flex align={'center'} gap={'8px'} pl={'16px'} mb={'8px'}>
                    <Text fontWeight={'semibold'} flexShrink={0}>
                        欠款金額
                    </Text>
                    <InputGroup>
                        <Input
                            defaultValue={'0'}
                            bg={'#fff'}
                            textAlign={'right'}
                        ></Input>
                        <InputRightAddon>元</InputRightAddon>
                    </InputGroup>
                </Flex>
                <Flex align={'flex-start'} gap={'8px'} pl={'16px'} mb={'8px'}>
                    <Text fontWeight={'semibold'} flexShrink={0}>
                        欠款原因
                    </Text>
                    <Textarea resize={'none'} h={'88px'} bg={'#fff'}></Textarea>
                </Flex>
                <Flex justify={'flex-end'}>
                    <Button colorScheme="rsDanger">欠款</Button>
                </Flex>
            </Box>

            <TableContainer h={'300px'} overflowY={'scroll'}>
                <Table>
                    <Thead
                        bg={'#D9D9D9'}
                        pos={'sticky'}
                        top={0}
                        left={0}
                        zIndex={10}
                    >
                        <Tr>
                            <Th>
                                <Text fontSize={'md'}>欠款日期</Text>
                            </Th>
                            <Th>
                                <Text fontSize={'md'} textAlign={'right'}>
                                    欠款金額
                                </Text>
                            </Th>
                            <Th>
                                <Text fontSize={'md'}>欠款原因</Text>
                            </Th>
                            <Th>
                                <Text fontSize={'md'}>經手人</Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            {/* <Td>113.07.08</Td>
                            <Td textAlign={'right'}>500</Td>
                            <Td>病患沒帶錢包</Td>
                            <Td>李阿信</Td> */}
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    );
};
