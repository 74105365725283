import { Button, Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import { useTreatmentInfoStore } from 'store/TreatmentManagement';
import { callMedicalStaffSpecService } from 'service/medicalStaffSpecService';
import { callRsGetPeaCareNameService } from 'service/RsService/RsGetPeaCarenameService';
import { ADDateToROCString } from 'functions/ADDateToROCString';
import moment from 'moment';
import { mainNavItemButton } from 'style/chakraStyles';
import Swal from 'sweetalert2';

export const ControlledDrugSearch = () => {
    const token = localStorage.getItem('token');
    const [doctorROCId, setDoctorROCId] = useState('');
    const { treatmentInfo } = useTreatmentInfoStore();
    const callMedicalStaffSpecAPI = async () => {
        try {
            const result = await callMedicalStaffSpecService(
                token!,
                treatmentInfo.doctor_uid
            );
            if (result.result === 'success') {
                setDoctorROCId(result.id_no);
            }
        } catch (error) {
            console.error('調用醫療人員規格API時出錯:', error);
        }
    };
    const handleOpenMedCloudWindow = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        try {
            await callMedicalStaffSpecAPI();
            await callRsGetPeaCarenameAPI();
        } catch (error) {
            console.error('打開MedCloud窗口時出錯:', error);
        }
    };

    const callRsGetPeaCarenameAPI = async () => {
        if (treatmentInfo.birth_date) {
            let formatPatBirth = ADDateToROCString(treatmentInfo.birth_date);
            const splitFormatPatBirth = formatPatBirth.split('.').join('');
            if (formatPatBirth.length === 6) {
                formatPatBirth = '0' + formatPatBirth;
            }
            let url = 'https://medcloud.nhi.gov.tw/iese0000/IESE0200S00.aspx';
            console.log(formatPatBirth, '改過的生日');
            // let finalData = {
            //     dr_idno: doctorROCId,
            //     pat_idno: treatmentInfo.id_no,
            //     pat_birth: splitFormatPatBirth,
            //     com_port: '0'
            // }

            // 測試用
            let finalData = {
                dr_idno: '9999999999',
                pat_idno: 'Z299999965',
                pat_birth: '0930416',
                com_port: '0',
            };
            try {
                const result = await callRsGetPeaCareNameService(finalData);
                if (result) {
                    if (result.ERRORCODE == 0) {
                        const url = result.pBuffer;
                        const windowFeatures =
                            'width=980,height=567,top=0,left=0';
                        if (url) {
                            const newWindow = window.open(
                                url,
                                '_blank',
                                windowFeatures
                            );
                            if (newWindow) {
                                newWindow.focus();
                            }
                        }
                    } else if (result.ERRORCODE && result.ERRORCODE !== 0) {
                        Swal.fire({
                            icon: 'error',
                            title: '錯誤',
                            confirmButtonText: '確定',
                        });
                    }
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '錯誤',
                    text: '卡機異常',
                    confirmButtonText: '確定',
                });
            }
        }
    };
    return (
        <>
            <Button
                variant="ghost"
                size={'sm'}
                sx={mainNavItemButton}
                boxShadow={'inset 0px 2px 2px #00000099'}
                onClick={handleOpenMedCloudWindow}
            >
                <HeroIcons name="controlled-medication-query"></HeroIcons>
                <Box as="span" ml={'4px'}>
                    管制藥查詢
                </Box>
            </Button>
        </>
    );
};
