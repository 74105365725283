import { Box } from '@chakra-ui/react';

const ConsultingRoomMedicalTestReportIcon = ({ onClick }: any) => {
    return (
        <Box>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    d="M10.8333 5.83334H17.5M17.5 5.83334V12.5M17.5 5.83334L10.8333 12.5L7.5 9.16667L2.5 14.1667"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Box>
    );
};

export default ConsultingRoomMedicalTestReportIcon;
