import { create } from 'zustand';
import { patientSearchListsProps } from 'service/patientSearchService';

export interface searchPatientProps {
    keywords: string;
    searchLists?: patientSearchListsProps[];
}
const initialSearchPatientData: searchPatientProps = {
    keywords: '',
    searchLists: [],
};
interface searchPatientState {
    searchPatientData: searchPatientProps;
    updateSearchPatientData: (newData: Partial<searchPatientProps>) => void;
    resetSearchPatientData: () => void;
}

export const useSearchPatientStore = create<searchPatientState>(set => ({
    searchPatientData: initialSearchPatientData,
    updateSearchPatientData: newData =>
        set(state => ({
            searchPatientData: { ...state.searchPatientData, ...newData },
        })),
    resetSearchPatientData: () =>
        set(() => ({
            searchPatientData: initialSearchPatientData,
        })),
}));
