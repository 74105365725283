import React, { useState } from 'react';
import {
    Box,
    Text,
    Flex,
    Stack,
    Radio,
    RadioGroup,
    Input,
} from '@chakra-ui/react';
import { textEllipsisOverflow } from 'style/chakraStyles/textStyles';

interface dxListProps {
    code?: string;
    name?: string;
}

export const Diagnosis = () => {
    // const dxList: dxListProps[] = [
    //     {
    //         name: 'URI急性上呼吸道感染',
    //         code: 'J069',
    //     },
    //     {
    //         name: '急性扁桃腺炎',
    //         code: 'J0390',
    //     },
    //     {
    //         name: '急性胃炎未伴有出血 ',
    //         code: ' K.',
    //     },
    //     {
    //         name: '激躁性腸症候群未伴有腹瀉',
    //         code: '',
    //     },
    // ];

    const dxList: dxListProps[] = [];
    return (
        <>
            <Text textStyle={'semiTitle'} mb={'8px'}>
                診斷
            </Text>

            <Flex
                direction={'column'}
                rowGap={'4px'}
                h={'140px'}
                overflowY={'scroll'}
                w={'100%'}
            >
                {dxList &&
                    dxList.map((item, index) => (
                        <Box
                            key={index}
                            p={'4px 16px'}
                            rounded={'md'}
                            bg={index === 0 ? '#D08C60' : '#BFC6A2'}
                            textColor={index === 0 ? '#fff' : '#000'}
                        >
                            <Text sx={textEllipsisOverflow}>
                                {item.name} {item.code}
                            </Text>
                        </Box>
                    ))}
            </Flex>
        </>
    );
};
