import { useEffect } from 'react';
import { useLoadingStatus } from '../../contexts/LoadingStatusContext';
import Loading from '../../components/CustomComponents/Loading';
import { ConsultingRoomWaitingListTable } from 'components/TreatmentManagement/ConsultingRoomWaitingListTable';

export default function OPDPage() {
    const { loadingStatus } = useLoadingStatus();

    return (
        <>
            {loadingStatus && <Loading></Loading>}
            <ConsultingRoomWaitingListTable />
        </>
    );
}
