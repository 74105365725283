import { Icon, Box } from '@chakra-ui/react';
import { PiCaretDoubleUpBold } from 'react-icons/pi';

const GroupViewIcon = ({ onClick }: any) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            // _hover={{ color: 'orange.500', transform: 'translateY(-1px)' }}
            cursor="pointer"
            onClick={onClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </Box>
    );
};

export default GroupViewIcon;
