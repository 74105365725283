import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ShowICCardRegisteredFormStatusContextValue {
    showICCardRegisteredFormStatus: boolean;
    setShowICCardRegisteredFormStatus: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

const ShowICCardRegisteredFormStatusContext = createContext<
    ShowICCardRegisteredFormStatusContextValue | undefined
>(undefined); // Set the initial value as undefined

interface ShowICCardRegisteredFormStatusProviderProps {
    children: ReactNode;
}

export const ShowICCardRegisteredFormStatusProvider: React.FC<
    ShowICCardRegisteredFormStatusProviderProps
> = ({ children }) => {
    const [showICCardRegisteredFormStatus, setShowICCardRegisteredFormStatus] =
        useState(false);

    return (
        <ShowICCardRegisteredFormStatusContext.Provider
            value={{
                showICCardRegisteredFormStatus,
                setShowICCardRegisteredFormStatus,
            }}
        >
            {children} {/* Fix: Remove double curly braces around children */}
        </ShowICCardRegisteredFormStatusContext.Provider>
    );
};

export const useShowICCardRegisteredFormStatus =
    (): ShowICCardRegisteredFormStatusContextValue => {
        const context = useContext(ShowICCardRegisteredFormStatusContext); // Fix: Remove the typo 'con'

        if (!context) {
            throw new Error(
                'useShowICCardRegisteredFormStatus must be used within a ShowICCardRegisteredFormStatusProvider'
            );
        }
        return context;
    };
