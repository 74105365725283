import React, { useState } from 'react';
import {
    Box,
    Text,
    Flex,
    Stack,
    Radio,
    RadioGroup,
    Input,
} from '@chakra-ui/react';
export const Purpose = () => {
    const [purpose, setPurpose] = useState<string>('');
    const [other, setOther] = useState<string>('');

    const handleChange = (value: string) => {
        setPurpose(value);
        if (value !== '6') {
            setOther('');
        }
    };
    const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOther(event.target.value);
    };
    return (
        <>
            <Text textStyle={'semiTitle'} mb={'8px'}>
                轉診目的
            </Text>
            <RadioGroup onChange={handleChange} value={purpose}>
                <Stack spacing={'16px'} direction={'row'}>
                    <Box>
                        <Stack spacing={'8px'}>
                            <Radio size={'lg'} bg={'#fff'} value="1">
                                1. 急診治療
                            </Radio>
                            <Radio size={'lg'} bg={'#fff'} value="2">
                                2. 住院治療
                            </Radio>
                            <Radio size={'lg'} bg={'#fff'} value="3">
                                3. 門診治療
                            </Radio>
                            <Radio size={'lg'} bg={'#fff'} value="4">
                                4. 近一步檢查項目
                            </Radio>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack spacing={'8px'}>
                            <Radio size={'lg'} bg={'#fff'} value="5">
                                5. 轉回轉出或適當之院所繼續追蹤
                            </Radio>
                            <Flex gap={'8px'}>
                                <Radio size={'lg'} bg={'#fff'} value="6">
                                    <Text whiteSpace={'nowrap'}>
                                        6. 其他（防疫用關鍵字）
                                    </Text>
                                </Radio>
                                <Input
                                    bg={'#fff'}
                                    onChange={handleOtherChange}
                                    value={other}
                                    isDisabled={purpose !== '6'}
                                ></Input>
                            </Flex>
                            <Radio size={'lg'} bg={'#fff'} value="7">
                                <Text whiteSpace={'nowrap'}>
                                    7. COVID-19個案（含疑似轉診治療）
                                </Text>
                            </Radio>
                        </Stack>
                    </Box>
                </Stack>
            </RadioGroup>
        </>
    );
};
