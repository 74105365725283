import {
    Input,
    InputGroup,
    InputLeftElement,
    Image,
    Button,
    Box,
    Flex,
    Center,
    Text,
    Heading,
    filter,
} from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { DiagnosisListsProps } from './DrugDataMaintenanceTable';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';
//排序
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';

// API
import { callICD10PersonalUseService } from 'service/ICD10PersonalUseService';
import { postDrugAttachICDSortService } from 'service/drugAttachICDSortService';

import {
    callDrugAttachICDService,
    deleteDrugAttachIcdService,
} from 'service/drugAttachICDService';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import Loading from 'components/CustomComponents/Loading';

interface DrugDataMaintenanceDiagnosisProps {
    diagnosisLists: DiagnosisListsProps[];
    setDiagnosisLists: React.Dispatch<
        React.SetStateAction<DiagnosisListsProps[]>
    >;
    isEditing: boolean;
    setIsFromDelete: React.Dispatch<React.SetStateAction<boolean>>;
    dataIdFromTable: string;
    setOriginDiagnosisLists: React.Dispatch<
        React.SetStateAction<DiagnosisListsProps[]>
    >;
}
interface finalData {
    data_id: string;
    ids: string[];
}

interface SearchListsProps {
    code: string;
    data_id: string;
    name_en: string;
    name_tw: string;
    short_code: string;
    icd_code: string;
}

const DrugDataMaintenanceDiagnosis = ({
    diagnosisLists,
    setDiagnosisLists,
    isEditing,
    setIsFromDelete,
    dataIdFromTable,
    setOriginDiagnosisLists,
}: DrugDataMaintenanceDiagnosisProps) => {
    const token = localStorage.getItem('token');
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
    const [searchLists, setSearchLists] = useState<SearchListsProps[]>([]);
    const boxRef = useRef<HTMLDivElement>(null);

    const [isCNtyping, setIsCNtyping] = useState<boolean>(false);

    const deleteResultSuccessBottom = () => {
        Swal.fire({
            icon: 'success',
            title: '刪除成功',
            showConfirmButton: false,
            timer: 1500,
        });
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Node;

            if (boxRef.current && !boxRef.current.contains(target)) {
                setOpenShowListsStatus(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [boxRef]);

    const callICD10PersonalUseSpecificAPI = async () => {
        let page = 1;
        if (token) {
            // 這個之後要討論
            const queryParams = { keyword: keyword, page: page };
            try {
                const result = await callICD10PersonalUseService(
                    token,
                    queryParams
                );
                if (result.result === 'success') {
                    setSearchLists(result.lists);
                } else if (result.result === 'failure') {
                    Swal.fire({
                        icon: 'warning',
                        title: '查無資料',
                        showConfirmButton: true,
                        confirmButtonText: '重新嘗試',
                    });
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    timer: 1500,
                });
            }
        }
    };
    const handleSearch = () => {
        callICD10PersonalUseSpecificAPI();
        setOpenShowListsStatus(true);
        setSearchLists([]);
    };
    const [keyword, setKeyword] = useState<string>('');
    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputElement = event.target.value;
        setKeyword(inputElement);
    };
    const [openShowListsStatus, setOpenShowListsStatus] =
        useState<boolean>(false);

    const handleClickInsert = (
        event: React.MouseEvent<HTMLDivElement>,
        index: number
    ) => {
        const selectedItem = searchLists[index];

        let finalData = {
            attach_icd_uid: selectedItem.data_id,
            name_en: selectedItem.name_en,
            name_tw: selectedItem.name_tw,
            short_code: selectedItem.short_code,
            icd_code: selectedItem.code,
        };

        const isAlreadyInShowLists = diagnosisLists.some(
            item => item.attach_icd_uid === finalData.attach_icd_uid
        );

        const deleteId = diagnosisLists.find(
            item => item.attach_icd_uid === searchLists[index].data_id
        )?.data_id;
        const deleteIndex = diagnosisLists.findIndex(
            item => item.attach_icd_uid === searchLists[index].data_id
        );

        if (diagnosisLists.length < 6) {
            if (isAlreadyInShowLists) {
                if (diagnosisLists && diagnosisLists[deleteIndex].data_id) {
                    Swal.fire({
                        icon: 'question',
                        title: '是否要刪除此診斷？',
                        confirmButtonText: '是',
                        cancelButtonText: '否',
                        showCancelButton: true,
                    }).then(result => {
                        if (result.isConfirmed) {
                            if (deleteId) {
                                deleteDrugAttachIcdAPI(deleteId);
                                setOpenShowListsStatus(true);
                                setDiagnosisLists(prevLists =>
                                    prevLists.filter(
                                        item => item.data_id !== deleteId
                                    )
                                );
                            }
                        } else {
                            return;
                        }
                    });
                } else {
                    setDiagnosisLists(prevLists =>
                        prevLists.filter(
                            item => item.attach_icd_uid !== selectedItem.data_id
                        )
                    );
                }
            } else {
                setDiagnosisLists(prevLists => [...prevLists, finalData]);
                setHasUnsavedChanges(true);
            }
        } else {
            Swal.fire({
                icon: 'info',
                title: '已達到最大上限（6筆）',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    };

    const deleteDrugAttachIcdAPI = async (id: string, deleteIndex?: number) => {
        if (token && diagnosisLists && id) {
            try {
                const result = await deleteDrugAttachIcdService(token, id);
                let newArr = diagnosisLists.filter(
                    (item, index) => index !== deleteIndex
                );
                if (result.result === 'success') {
                    if (deleteIndex !== undefined && id) {
                        setDiagnosisLists(newArr);
                    }
                    setIsFromDelete(true);
                    deleteResultSuccessBottom();
                    Swal.fire({
                        icon: 'success',
                        title: '刪除成功',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else if (result.result === 'failure') {
                    Swal.fire({
                        icon: 'error',
                        title: '刪除失敗',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            } catch (error) {
                console.error('刪除藥物附加ICD時發生錯誤:', error);
                Swal.fire({
                    icon: 'error',
                    title: '刪除失敗',
                    text: '發生未知錯誤，請稍後再試',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        }
    };

    // 刪除診斷
    const handleDelete = (deleteIndex: number) => {
        let newArr = diagnosisLists.filter(
            (item, index) => index !== deleteIndex
        );
        let id = diagnosisLists[deleteIndex].data_id;

        if (id) {
            Swal.fire({
                title: '是否要刪除此診斷？',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: '是',
                cancelButtonText: '否',
            }).then(result => {
                if (result.isConfirmed) {
                    if (diagnosisLists[deleteIndex].data_id && id) {
                        deleteDrugAttachIcdAPI(id, deleteIndex);
                    }
                }
                return;
            });
        } else {
            setDiagnosisLists(newArr);
            setIsFromDelete(true);
        }
    };

    const bottomContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // 在組件渲染後取得元素高度
        if (bottomContentRef.current) {
            const height = bottomContentRef.current.clientHeight;
        }
    }, [handleSearch]); // 空的依賴數組表示只在組件第一次渲染後執行

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        } else {
            const newData = [...diagnosisLists];

            const [movedItem] = newData.splice(result.source.index, 1);
            newData.splice(result.destination.index, 0, movedItem);

            setDiagnosisLists(newData);
            let newArr: string[] = [];

            newData.forEach(item => {
                if (item.data_id) {
                    newArr.push(item.data_id);
                }
            });

            if (newData && newArr) {
                const postDrugAttachIcdSortAPI = async () => {
                    const finalData: finalData = {
                        data_id: dataIdFromTable,
                        ids: newArr,
                    };
                    if (token && finalData && !hasUnsavedChanges) {
                        try {
                            const result = await postDrugAttachICDSortService(
                                token,
                                finalData
                            );
                            if (result.result === 'success') {
                                Swal.fire({
                                    icon: 'success',
                                    title: ' 更新成功',
                                    showConfirmButton: false, // 隱藏確認按鈕
                                    timer: 1500, // 設定自動關閉的時間 (1.5 秒)
                                });
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: ' 更新失敗',
                                    showConfirmButton: false, // 隱藏確認按鈕
                                    timer: 1500, // 設定自動關閉的時間 (1.5 秒)
                                });
                            }
                        } catch {
                            Swal.fire({
                                icon: 'error',
                                title: '後端服務錯誤',
                                timer: 1500,
                            });
                        }
                    }
                };
                postDrugAttachIcdSortAPI();
            }
        }
    };

    return (
        <>
            <Flex direction={'column'} gap={'16px'} ref={bottomContentRef}>
                <Flex gap={'8px'} w={'100%'}>
                    <Box flex={'1 0 auto'} pos={'relative'} zIndex={20}>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none">
                                <HeroIcons name="search"></HeroIcons>
                            </InputLeftElement>
                            <Input
                                bg={isEditing ? '#fff' : 'transparent'}
                                placeholder="代碼、ICD10、中英文病名"
                                onChange={handleChangeInput}
                                isDisabled={!isEditing}
                                onCompositionStart={() => {
                                    setIsCNtyping(true);
                                }}
                                onCompositionEnd={() => {
                                    setIsCNtyping(false);
                                }}
                                onKeyDown={e => {
                                    if (!isCNtyping && e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                            />
                        </InputGroup>
                        {openShowListsStatus && (
                            <>
                                <Box
                                    ref={boxRef}
                                    pos={'absolute'}
                                    top={'40px'}
                                    left={'0px'}
                                    w={'100%'}
                                    overflowY={'scroll'}
                                    rounded={'md'}
                                    zIndex={'60'}
                                    maxH={'230px'}
                                    boxShadow={'lg'}
                                    bg={'#fff'}
                                >
                                    <Flex
                                        bg="rsPrimary.500"
                                        textColor={'#fff'}
                                        p={'8px 20px'}
                                        justify={'space-between'}
                                        pos={'sticky'}
                                        top={0}
                                    >
                                        <Flex gap={'8px'}>
                                            <Text>至多 6 筆</Text>
                                            <Text
                                                fontWeight={'normal'}
                                                textColor={'#fff'}
                                            >
                                                （已選擇 {diagnosisLists.length}
                                                ）
                                            </Text>
                                        </Flex>
                                        <Box
                                            onClick={() => {
                                                setOpenShowListsStatus(false);
                                            }}
                                            opacity={'0.8'}
                                            _hover={{
                                                cursor: 'pointer',
                                                opacity: '1',
                                            }}
                                        >
                                            DONE
                                        </Box>
                                    </Flex>

                                    {searchLists ? (
                                        <>
                                            {searchLists.map((item, index) => {
                                                const isInShowLists =
                                                    diagnosisLists.some(
                                                        showItem =>
                                                            showItem.attach_icd_uid ===
                                                            item.data_id
                                                    );
                                                return (
                                                    <>
                                                        <Flex
                                                            key={index}
                                                            onClick={event =>
                                                                handleClickInsert(
                                                                    event,
                                                                    index
                                                                )
                                                            }
                                                            bg={
                                                                isInShowLists
                                                                    ? '#ddf2f0'
                                                                    : '#fff'
                                                            }
                                                            _hover={{
                                                                bg: '#E9E5DA',
                                                            }}
                                                            textColor={'#000'}
                                                            borderBottom={
                                                                '1px solid rgba(0, 0, 0, 0.05)'
                                                            }
                                                        >
                                                            <Box
                                                                flex={
                                                                    '0 0 150px'
                                                                }
                                                                p={'8px 16px'}
                                                            >
                                                                <Text>
                                                                    {
                                                                        item.short_code
                                                                    }
                                                                </Text>
                                                            </Box>
                                                            <Box
                                                                flex={
                                                                    '0 0 150px'
                                                                }
                                                                p={'8px 16px'}
                                                            >
                                                                <Text>
                                                                    {
                                                                        item.icd_code
                                                                    }
                                                                </Text>
                                                            </Box>
                                                            <Box
                                                                flex={
                                                                    '1 1 auto'
                                                                }
                                                                p={'8px 16px'}
                                                            >
                                                                <Text>
                                                                    {item.name_tw
                                                                        ? item.name_tw
                                                                        : item.name_en}
                                                                </Text>
                                                            </Box>
                                                            <Center
                                                                w={'50px'}
                                                                textColor={
                                                                    'rsPrimary.500'
                                                                }
                                                                cursor={
                                                                    'pointer'
                                                                }
                                                            >
                                                                {isInShowLists ? (
                                                                    <HeroIcons name="xmark-sm" />
                                                                ) : (
                                                                    <HeroIcons name="add-fill" />
                                                                )}
                                                            </Center>
                                                        </Flex>
                                                    </>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            <Box
                                                pos={'relative'}
                                                minH={'80px'}
                                                bg={'#fff'}
                                            >
                                                <Loading></Loading>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box flex={'0 0 auto'}>
                        <Button
                            colorScheme="rsPrimary"
                            onClick={handleSearch}
                            isDisabled={!isEditing}
                        >
                            查詢
                        </Button>
                    </Box>
                </Flex>

                <Box>
                    {/* table header */}
                    <Flex
                        borderBottom={'1px solid #708368'}
                        pos={'sticky'}
                        top={'40px'}
                        zIndex={10}
                    >
                        <Box
                            p={'8px 16px'}
                            fontWeight={'semibold'}
                            w={'80px'}
                            textAlign={'center'}
                        >
                            排序
                        </Box>
                        <Box
                            p={'8px 16px'}
                            fontWeight={'semibold'}
                            flex={'0 0 150px'}
                        >
                            診斷代碼
                        </Box>
                        <Box
                            p={'8px 16px'}
                            fontWeight={'semibold'}
                            flex={'0 0 150px'}
                        >
                            ICD10
                        </Box>
                        <Box
                            p={'8px 16px'}
                            fontWeight={'semibold'}
                            flex={'1 1 0'}
                        >
                            診斷名稱
                        </Box>
                        <Center
                            w={'50px'}
                            p={'8px 8px'}
                            fontWeight={'semibold'}
                        >
                            刪除
                        </Center>
                    </Flex>

                    {/* table body */}
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="root">
                            {provided => (
                                <Flex
                                    h={'150px'}
                                    mt={'8px'}
                                    overflowY={'scroll'}
                                    direction={'column'}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {diagnosisLists && (
                                        <>
                                            {diagnosisLists.map(
                                                (item, index) => (
                                                    <Draggable
                                                        key={item.data_id}
                                                        draggableId={
                                                            item.data_id ||
                                                            `item-${index}`
                                                        }
                                                        index={index}
                                                    >
                                                        {provided => (
                                                            <Flex
                                                                key={
                                                                    item.data_id
                                                                }
                                                                {...provided.draggableProps}
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                textColor={
                                                                    '#8c8c8c'
                                                                }
                                                                borderBottom={
                                                                    '1px solid #0000000d'
                                                                }
                                                            >
                                                                {isEditing ? (
                                                                    <Center
                                                                        w={
                                                                            '80px'
                                                                        }
                                                                        {...provided.dragHandleProps}
                                                                        fontSize={
                                                                            '20px'
                                                                        }
                                                                    >
                                                                        &#x283F;
                                                                    </Center>
                                                                ) : (
                                                                    <Center
                                                                        w={
                                                                            '80px'
                                                                        }
                                                                        pointerEvents={
                                                                            'none'
                                                                        }
                                                                        opacity={
                                                                            0.5
                                                                        }
                                                                    >
                                                                        &#x283F;
                                                                    </Center>
                                                                )}

                                                                <Box
                                                                    p={
                                                                        '8px 16px'
                                                                    }
                                                                    fontWeight={
                                                                        'semibold'
                                                                    }
                                                                    flex={
                                                                        '0 0 150px'
                                                                    }
                                                                >
                                                                    {
                                                                        item.short_code
                                                                    }
                                                                </Box>
                                                                <Box
                                                                    p={
                                                                        '8px 16px'
                                                                    }
                                                                    fontWeight={
                                                                        'semibold'
                                                                    }
                                                                    flex={
                                                                        '0 0 150px'
                                                                    }
                                                                >
                                                                    {
                                                                        item.icd_code
                                                                    }
                                                                </Box>
                                                                <Box
                                                                    p={
                                                                        '8px 16px'
                                                                    }
                                                                    fontWeight={
                                                                        'semibold'
                                                                    }
                                                                    flex={
                                                                        '1 1 0'
                                                                    }
                                                                >
                                                                    {
                                                                        item.name_tw
                                                                    }
                                                                </Box>
                                                                {isEditing ? (
                                                                    <Center
                                                                        w={
                                                                            '50px'
                                                                        }
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                index
                                                                            )
                                                                        }
                                                                        opacity={
                                                                            0.8
                                                                        }
                                                                        _hover={{
                                                                            cursor: 'pointer',
                                                                            opacity:
                                                                                '1',
                                                                        }}
                                                                    >
                                                                        <HeroIcons name="delete-fill"></HeroIcons>
                                                                    </Center>
                                                                ) : (
                                                                    <Center
                                                                        w={
                                                                            '50px'
                                                                        }
                                                                        opacity={
                                                                            0.5
                                                                        }
                                                                        pointerEvents={
                                                                            'none'
                                                                        }
                                                                    >
                                                                        <HeroIcons name="delete-fill"></HeroIcons>
                                                                    </Center>
                                                                )}
                                                            </Flex>
                                                        )}
                                                    </Draggable>
                                                )
                                            )}
                                        </>
                                    )}
                                </Flex>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Box>
            </Flex>
        </>
    );
};

export default DrugDataMaintenanceDiagnosis;
