import { useState, useEffect } from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { InsetShadowBox } from 'style/chakraStyles';
import Swal from 'sweetalert2';

import { useIsICWriteInOpenStatus } from 'contexts/ICUpload/IsICWriteInOpen';

import { Lists } from './Lists';
import { ICWriteIn } from './ICWriteIn';
import { ConditionalSearch } from './ConditionalSearch';
import { Statistics } from './Statistics';
import { useUserStore } from 'store';
import { useICCardUpdateListStore } from 'store/ICCardUpdate/useICCardUpdateListStore';
import { postIcDataDataSetService } from 'service/icDataDataSetService';
import { postIcDataUploadResultService } from 'service/icDataUploadResultService';
import { ICUpload2Service } from 'service/RsService/ICUpload2Service';

import { useIcDataListsAPI } from 'service/icDataListsServiceHelper';

import { useICCardUpdateListTabIndexStore } from 'store/ICCardUpdate/useICCardUpdateListTabIndexStore';

import { callCustomerService } from 'service/customerService';
import { useInstitutionInfoStore } from 'store/DataSetting/useInstitutionInfoStore';

export const ICCardUpdate = () => {
    const { userInfo } = useUserStore();
    const { isICWriteInOpenStatus, setIsICWriteInOpenStatus } =
        useIsICWriteInOpenStatus();
    const { institutionInfoData, updateInstitutionInfoData } =
        useInstitutionInfoStore();

    const { icCardUpdateList } = useICCardUpdateListStore();

    const { callIcDataListsAPI } = useIcDataListsAPI();

    const { tabIndex } = useICCardUpdateListTabIndexStore();

    // 取得院所資料 callCustomerAPI
    const callCustomerAPI = async () => {
        if (userInfo.token) {
            try {
                const result = await callCustomerService(userInfo.token);
                if (result.result === 'success') {
                    updateInstitutionInfoData(result.info);
                }
            } catch {
                Swal.fire({
                    icon: 'error',
                    title: '後端服務錯誤',
                    showConfirmButton: false,
                });
            }
        }
    };
    useEffect(() => {
        callCustomerAPI();
        console.log('院所基本資料:', institutionInfoData);
    }, []);

    const handleUpload = () => {
        console.log('所選擇的上傳名單:', icCardUpdateList);
        const ids = icCardUpdateList.map(item => item.medical_record_uid);

        if (ids.length === 0) {
            Swal.fire({
                title: '名單中無資料',
                text: '請先利用右手邊搜尋上傳的資料',
                icon: 'warning',
                confirmButtonText: '確定',
            });
        } else {
            console.log('開始傳送 ids:', ids);
            postIcDataListsAPI(ids);
        }
    };

    const postIcDataListsAPI = async (ids: string[]) => {
        try {
            const data = {
                ids: ids,
            };
            const result = await postIcDataDataSetService(userInfo.token, data);
            if (result.result === 'success') {
                console.log('回傳 API23 結果:', result);
                // 接到 23 回傳的資料後，整理成一格陣列，呼叫小張哥 API

                const ic_upload2_data = {
                    sHospid: institutionInfoData.nhid,
                    sPrecs: result.drugs_count,
                    sPatData: result.ic_content,
                    sType: 'A1',
                    sMrecs: result.medical_record_count,
                };

                console.log(
                    '準備給代理人的 ic_upload2_data: ',
                    ic_upload2_data
                );
                console.log('呼叫代理人 API');
                const response = await ICUpload2Service(ic_upload2_data);
                const responseObj = JSON.parse(response);

                console.log('代理人 API 回傳:');
                console.log('RtnCode:', responseObj.RtnCode);
                console.log('Opcode:', responseObj.Opcode);

                if (responseObj.RtnCode === '0000') {
                    console.log('代理人 API 回傳成功，呼叫 API24');
                    postIcDataUploadResultAPI(ids);
                } else {
                    const errorMessages: { [key: string]: string } = {
                        '5002': '下載檔案尚未就緒，請稍後再試',
                        '5003': '作業類別錯誤',
                        '5004': '安全模組卡無效或與上傳時不符',
                        '8201': '服務異常，請稍後再試',
                        '8202': '服務異常，請稍後再試',
                        '8203': '簽章已逾有效期限，請重新取簽章',
                        '8205': '安全模組卡已註銷，請聯繫所屬分區業務組',
                        '9002': '驗簽失敗，未完成驗證作業',
                        '9005': '醫療院所代號不存在',
                    };

                    const errorMessage =
                        errorMessages[responseObj.RtnCode] ||
                        responseObj.ERRORCODE;
                    const icon = ['5002', '8203'].includes(responseObj.RtnCode)
                        ? 'warning'
                        : 'error';

                    Swal.fire({
                        title: '上傳 IC 資料失敗',
                        text: errorMessage,
                        icon: icon,
                        confirmButtonText: '確定',
                    });
                }
            }
        } catch (error) {
            console.error('上傳 IC 資料失敗:', error);
        }
    };

    const postIcDataUploadResultAPI = async (ids: string[]) => {
        const data = {
            ids: ids,
        };
        try {
            const result = await postIcDataUploadResultService(
                userInfo.token,
                data
            );
            if (result.result === 'success') {
                console.log('API24 回傳成功，呼叫 API22 刷新頁面');
                callIcDataListsAPI();
                Swal.fire({
                    icon: 'success',
                    title: '上傳成功',
                    confirmButtonText: '確定',
                });
            } else {
                console.log('API24 回傳失敗');
                Swal.fire({
                    icon: 'error',
                    title: '上傳失敗',
                    text: '請聯繫管理員',
                    confirmButtonText: '確定',
                });
            }
        } catch (error) {
            console.error('測試IC資料失敗:', error);
        }
    };

    return (
        <>
            <Flex
                h={'calc( 100vh - 40px)'}
                bg={'gray.550'}
                p={'56px 20px 20px'}
                gap={'16px'}
            >
                <Box flex={'1 1 calc(70% - 8px)'}>
                    <Flex direction={'column'} gap={'16px'} h={'100%'}>
                        <Box flex={'1 1 100%'} sx={InsetShadowBox}>
                            <Lists />
                        </Box>
                        <Flex gap={'8px'} height={'40px'} flexShrink={0}>
                            <Button
                                variant={'outline'}
                                colorScheme="rsPrimary"
                                borderWidth={'2px'}
                                hidden={true}
                            >
                                讀取 IC 卡相關資料
                            </Button>
                            <Button
                                hidden={true}
                                boxShadow={
                                    '2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset;'
                                }
                                colorScheme="rsPrimary"
                            >
                                補正上傳
                            </Button>
                            <Button
                                hidden={tabIndex !== 0}
                                boxShadow={
                                    '2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset;'
                                }
                                colorScheme="rsDanger"
                                onClick={handleUpload}
                            >
                                上傳
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
                <Box flex={'1 1 calc(30% - 8px)'}>
                    <Flex direction={'column'} gap={'16px'} h={'100%'}>
                        <Box sx={InsetShadowBox}>
                            <ConditionalSearch />
                        </Box>
                        <Box sx={InsetShadowBox}>
                            <Statistics />
                        </Box>
                    </Flex>
                </Box>
            </Flex>

            {isICWriteInOpenStatus && <ICWriteIn />}
        </>
    );
};
