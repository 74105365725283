

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface WaitingListsStatusContextValue {
    // waitingListsStatus: boolean;
    // setWaitingListsStatus: React.Dispatch<
    //     React.SetStateAction<boolean>
    // >;
    filterByPeriodStatus: string;
    setFilterByPeriodStatus: React.Dispatch<React.SetStateAction<string>>
    filterByDoctorStatus: string;
    setFilterByDoctorStatus:React.Dispatch<React.SetStateAction<string>>
    filterByPatientDataStatus: string;
    setFilterByPatientDataStatus:React.Dispatch<React.SetStateAction<string>>
}

const WaitingListsStatusContext = createContext<
    WaitingListsStatusContextValue | undefined
>(undefined);

interface WaitingListsStatusProps {
    children: ReactNode;
}

export const WaitingListsStatusProvider: React.FC<
    WaitingListsStatusProps
> = ({ children }) => {
    // const [filteredData, setFilteredData] = useState<OpdListsProps>({});
    const [filterByPeriodStatus, setFilterByPeriodStatus] = useState('');
    const [filterByDoctorStatus, setFilterByDoctorStatus] = useState('');
    const [filterByPatientDataStatus, setFilterByPatientDataStatus] = useState('');
    // const [waitingListsStatus, setWaitingListsStatus] =
    //     useState(false);

    return (
        <WaitingListsStatusContext.Provider
            value={{
                // waitingListsStatus,
                // setWaitingListsStatus,
                filterByDoctorStatus,
                setFilterByDoctorStatus,
                filterByPeriodStatus,
                setFilterByPeriodStatus,
                filterByPatientDataStatus,
                setFilterByPatientDataStatus
            }}
        >
            {children}
        </WaitingListsStatusContext.Provider>
    );
};

export const useWaitingListsStatus =
    (): WaitingListsStatusContextValue => {
        const context = useContext(WaitingListsStatusContext);

        if (!context) {
            throw new Error(
                'useRoomStatus must be used within a WaitingListsStatus'
            );
        }

        return context;
    };
