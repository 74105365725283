import React, { createContext, useContext, useState, useRef, ReactNode } from 'react';

type PrescriptionInputRefsContextType = {
    prescriptionInputRefs: React.MutableRefObject<Array<Array<HTMLInputElement | null>>>;
    setPrescriptionInputRefs: React.Dispatch<React.SetStateAction<Array<Array<HTMLInputElement | null>>>>;
};

const PrescriptionInputRefsContext = createContext<PrescriptionInputRefsContextType | undefined>(undefined);

export const usePrescriptionInputRefs = () => {
    const context = useContext(PrescriptionInputRefsContext);
    if (!context) {
        throw new Error('usePrescriptionInputRefs must be used within a PrescriptionInputRefsProvider');
    }
    return context;
};

interface PrescriptionInputRefsProviderProps {
    children: ReactNode;
}

export const PrescriptionInputRefsProvider: React.FC<PrescriptionInputRefsProviderProps> = ({ children }) => {
    const prescriptionInputRefs = useRef<Array<Array<HTMLInputElement | null>>>([]);
    const [_, setPrescriptionInputRefs] = useState(prescriptionInputRefs.current);

    return (
        <PrescriptionInputRefsContext.Provider value={{ prescriptionInputRefs, setPrescriptionInputRefs }}>
            {children}
        </PrescriptionInputRefsContext.Provider>
    );
};
