import axios from 'axios';

// RsPea 保險對象特定查詢
export const callRsGetPeaCareNameService = async (data: object) => {
    try {
        const response = await axios.post(
            'http://localhost:23752/PEA/CARENAME',
            data,
            {
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                },
            }
        );
        let result;
        if (typeof response.data === 'string') {
            result = JSON.parse(response.data);
        } else {
            result = response.data;
        }
        return result;
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.error('Error:', error.message);
        } else {
            console.error('An unknown error occurred');
        }
    }
};
