import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
export const PrescriptionInfo = () => {
    const Items = {
        p: '4px 8px',
        gap: '24px',
        align: 'center',
    };
    return (
        <>
            <Flex sx={Items}>
                <Text textStyle={'semiTitle'} w={'120px'}>
                    就醫日期
                </Text>
                <Text flex={1}>113.07.08</Text>
            </Flex>
            <Flex sx={Items}>
                <Text textStyle={'semiTitle'} w={'120px'}>
                    中文病名
                </Text>
                <Text
                    flex={1}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                >
                    糖尿病
                </Text>
            </Flex>
            <Flex sx={Items}>
                <Text textStyle={'semiTitle'} w={'120px'}>
                    連簽特殊原因
                </Text>
                <Text flex={1}>H8-預定出國一次領取</Text>
            </Flex>
            <Flex sx={Items}>
                <Text textStyle={'semiTitle'} w={'120px'}>
                    調劑次數
                </Text>
                <Text flex={1}>3</Text>
            </Flex>
            <Flex sx={Items}>
                <Text textStyle={'semiTitle'} w={'120px'}>
                    原卡號
                </Text>
                <Text flex={1}>0021</Text>
            </Flex>
        </>
    );
};
