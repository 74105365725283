import axios from 'axios';
import axiosInstance from './axiosInstance';
import apiService from './apiService';

export type membershipProps = {
    data_id: string;
    name: string;
}
// 取得優免身份清單
export const callMembershipService = async (token: string) => {
    return apiService<string, any>({
        method: 'GET',
        path: '/api/membership',
        token 
    })
}
// export const callMembershipService = async (token: string) => {
//     try {
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         const response = await axiosInstance.get('/api/membership', {
//             headers,
//         });
//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };




//增修優免身份清單

export const postMembershipService = async (token: string, data?: any) => {
    return apiService<any, any>({
        method: 'POST',
        path: '/api/membership',
        token,
        data: data
    })
}
// export const postMembershipService = async (token: string, data?: any) => {
//     try {
//         const requestBody = JSON.stringify(data);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         console.log(requestBody, '看一下這是啥');
//         const response = await axiosInstance.post('/api/membership', requestBody, {
//             headers,
//         });
//         const result = response.data;

//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };





// 刪除優免身份清單
export const deleteMembershipService = async (
    token: string,
    data_id: string
) => {
    return apiService<undefined, any>({
        method: 'DELETE',
        path:  `/api/membership?id=${data_id}`,
        token
    })
}
// export const deleteMembershipService = async (
//     token: string,
//     dataId: string
// ) => {
//     try {
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         console.log('service這邊有嗎', dataId);
//         const url = `/api/membership?id=${dataId}`;

//         const response = await axiosInstance.delete(url, {
//             headers,
//         });

//         const result = response.data;
//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
