import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IsHistoryLoadingContextValue {
    isHistoryLoadingStatus: boolean;
    setIsHistoryLoadingStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsHistoryLoadingContext = createContext<IsHistoryLoadingContextValue | undefined>(
    undefined
);

interface IsHistoryLoadingProviderProps {
    children: ReactNode;
}

export const IsHistoryLoadingProvider: React.FC<IsHistoryLoadingProviderProps> = ({
    children,
}) => {
    const [isHistoryLoadingStatus, setIsHistoryLoadingStatus] = useState(false);
    return (
        <IsHistoryLoadingContext.Provider
            value={{
                isHistoryLoadingStatus,
                setIsHistoryLoadingStatus,
            }}
        >
            {children}
        </IsHistoryLoadingContext.Provider>
    );
};

export const useIsHistoryLoadingStatus = (): IsHistoryLoadingContextValue => {
    const context = useContext(IsHistoryLoadingContext);

    if (!context) {
        throw new Error(
            'useIsHistoryLoadingStatus must be used within a isHistoryLoadingStatusProvider'
        );
    }

    return context;
};
