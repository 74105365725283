import { useQuery, UseQueryResult } from 'react-query';
import { callClinicRoomService } from 'service/clinicRoomService';
import {
    callGTypeAddressTypeService,
    callGTypeArithmeticService,
    callGTypeRegFeeIdenService,
    callGTypeBloodTypeService,
    callGTypeFeeTypeService,
    callGTypeJobService,
    callGTypeMedicalAffairsTypeService,
    callGTypePrescriptionStyleService,
    callGTypeReservationTypeService,
    callGTypeIdentityService,
    callGTypeIdNoTypeService,
    callGTypeMarriageService,
    callGTypeBornTypeService,
    callGTypeJobHistoryService,
    callGTypeSeekHistoryService,
    callGTypeFamilyHistoryService,
    callGTypeLongTermDrugsService,
    callGTypeMedicalTypeService,
    callGTypeCaseTypeService,
    callGTypeBenefitTypeService,
    callGTypeRouteOfAdministrationService,
} from 'service/gTypeService';
import { callMembershipService } from 'service/memberShipService';
import { callShiftService } from 'service/shiftService';
import { callCityService } from 'service/cityService';
import { callDrugSpecialMarkService } from 'service/drugSpecialMarkService';
import { callDrugTypeListService } from 'service/drugTypeListService';
import { callHospSubjectService } from 'service/hospSubjectService';
import { callCopaymentExemptionsService } from 'service/copaymentExemptionsService';
import { callSpecificTreatmentListsService } from 'service/specificTreatmentListsService';

interface ServiceFunctionMap {
    [key: string]: (token: string) => Promise<any>;
}

const serviceFunctionMap: ServiceFunctionMap = {
    // g_type 通用
    addressType: callGTypeAddressTypeService,
    regFeeIden: callGTypeRegFeeIdenService,
    arithmetic: callGTypeArithmeticService,
    bloodType: callGTypeBloodTypeService,
    feeType: callGTypeFeeTypeService,
    job: callGTypeJobService,
    medicalAffairsType: callGTypeMedicalAffairsTypeService,
    prescriptionStyle: callGTypePrescriptionStyleService,
    reservationType: callGTypeReservationTypeService,
    identity: callGTypeIdentityService,
    idNoType: callGTypeIdNoTypeService,
    marriage: callGTypeMarriageService,
    bornType: callGTypeBornTypeService,
    jobHistory: callGTypeJobHistoryService,
    seekHistory: callGTypeSeekHistoryService,
    familyHistory: callGTypeFamilyHistoryService,
    longTermDrugs: callGTypeLongTermDrugsService,
    medicalType: callGTypeMedicalTypeService,
    caseType: callGTypeCaseTypeService,
    benefitType: callGTypeBenefitTypeService,
    routeOfAdministration: callGTypeRouteOfAdministrationService,

    // 沒有的
    // address_type 地址類別
    // exam_declare exam_declare 檢驗申報方式
    // phone_type 電話類別
    // shift_type // 班別時段
    // 人員

    membership: callMembershipService,
    clinicRoom: callClinicRoomService,
    shift: callShiftService,
    city: callCityService,
    drugSpecialMark: callDrugSpecialMarkService,
    drugTypeList: callDrugTypeListService,
    hospSubject: callHospSubjectService,
    copaymentExemptions: callCopaymentExemptionsService,
    specificTreatmentLists: callSpecificTreatmentListsService,
};

export function useDropDownMenuQuery(
    queryKey: string,
    token: string
): UseQueryResult<any, unknown> {
    const queryFunction = serviceFunctionMap[queryKey];

    // console.log(`Query Key: ${queryKey}, Token: ${token}`); // 打印 queryKey 和 token
    // console.log(`Query Key: ${queryKey}, `); // 打印 queryKey 和 token


    if (!queryFunction) {
        console.error(`No query function found for query key: ${queryKey}`);
        return useQuery(
            [queryKey, token],
            () => Promise.reject(`No query function for ${queryKey}`),
            {
                enabled: false,
            }
        );
    }

    return useQuery([queryKey, token], () => queryFunction(token), {
        enabled: !!token,
        staleTime: Infinity,
    });
}
