// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker {
    border: 1px solid #e1e1e1;
    background: #fff;
    box-shadow: 0px 5px 6px #00000033;
}

.react-datepicker-wrapper {
    background: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/style/components/datePickerCustomForMainStyle.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,iCAAiC;AACrC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".react-datepicker {\n    border: 1px solid #e1e1e1;\n    background: #fff;\n    box-shadow: 0px 5px 6px #00000033;\n}\n\n.react-datepicker-wrapper {\n    background: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
