import {
    Box,
    Flex,
    Text,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    TabIndicator,
} from '@chakra-ui/react';
import React from 'react';
import { InsetShadowBox } from 'style/chakraStyles';
import { Lorem } from 'components/CustomComponents/Lorem';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';

import { List } from './List'; // 清單
import { AuditPrint } from './AuditPrint'; // 抽審列印

export const Lists = () => {
    return (
        <Box sx={InsetShadowBox} h={`calc(${window.innerHeight}px - 200px)`}>
            <Tabs position="relative" variant="unstyled">
                <TabList>
                    <Tab
                        display={'flex'}
                        gap={'4px'}
                        textColor={'rsPrimary.500'}
                    >
                        <HeroIcons name="clipboard-document-list" />
                        <Text>清單</Text>
                    </Tab>

                    <Tab
                        display={'flex'}
                        gap={'4px'}
                        textColor={'rsPrimary.500'}
                    >
                        <HeroIcons name="x-circle" />
                        <Text>錯誤清單</Text>
                    </Tab>

                    <Tab
                        display={'flex'}
                        gap={'4px'}
                        textColor={'rsPrimary.500'}
                    >
                        <HeroIcons name="printer" />
                        <Text>抽審列印</Text>
                    </Tab>
                </TabList>
                <TabIndicator
                    mt="-1.5px"
                    height="2px"
                    bg="rsPrimary.500"
                    borderRadius="1px"
                />
                <TabPanels>
                    <TabPanel px={0}>
                        <List />
                    </TabPanel>

                    <TabPanel px={0}>
                        <List />
                    </TabPanel>

                    <TabPanel px={0}>
                        <AuditPrint />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};
