import { create } from 'zustand';

// 定義 HospitalModelProps 接口
export interface HospitalModelProps {
    //IHospitalBase
    Hosp_Name: string;        // 院所名稱
    E_Hosp_Name?: string;     // 英文院所名稱
    Hosp_Code?: string;        // 醫事機構編號
    Hosp_Tel?: string;        // 院所電話
    Hosp_Addr?: string;       // 院所地址
    E_Hosp_Addr?: string;     // 英文院所地址
    Hosp_Fax?: string;        // 傳真
    Hosp_Mail?: string;       // 信箱
    Hosp_Practice?: string;   // 開業執照
    Hosp_Owner: string;       // 負責人
    Pharm_Name?: string;      // 藥師
    QRCode?: string;          // QRCode
}

// 定義 HospitalModelStore 接口
interface HospitalModelStore {
    hospitalModel: HospitalModelProps;
    updateHospitalModel: (hospitalModel: HospitalModelProps) => void;
    resetHospitalModel: () => void;
}

// 初始化 hospitalModel 的值
export const initialHospitalModel: HospitalModelProps = {
    //IHospitalBase
    Hosp_Name: '',
    E_Hosp_Name: '',
    Hosp_Code: '',
    Hosp_Tel: '',
    Hosp_Addr: '',
    E_Hosp_Addr: '',
    Hosp_Fax: '',
    Hosp_Mail: '',
    Hosp_Practice: '',
    Hosp_Owner: '',
    Pharm_Name: '',
    QRCode: ''
};

// 創建 useHospitalModelStore
export const useHospitalModelStore = create<HospitalModelStore>((set) => ({
    hospitalModel: initialHospitalModel,
    updateHospitalModel: (hospitalModel) =>
        set(() => ({
            hospitalModel: hospitalModel
        })),
    resetHospitalModel: () =>
        set(() => ({
            hospitalModel: initialHospitalModel
        })),
}));

export default useHospitalModelStore;
