// src/utils/dateUtils.ts
export function ADStringToROCString(dateStr: string): string {
    const date = new Date(dateStr);
    const rocYear = date.getFullYear() - 1911;
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');
    return `${rocYear}.${formattedMonth}.${formattedDay}`;
}
