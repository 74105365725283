import axios from 'axios';
import apiService from './apiService';

// 開通帳號

export const postVerifySetUserService= async (
    token: string,
    user: object,
) => {
    console.log(user, '這邊丟什麼')
    return apiService<string, any>({
        method: 'POST',
        path: '/api/verify/set_user',
        token,
        data: user
    })
}

// export const postVerifySetUserService= async (
//     token: string,
//     user: string,
// ) => {
//     try {
//         const requestBody = JSON.stringify({ user: user });

//         console.log(requestBody, '有service的開通帳號', token)
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axios.post('/api/verify/set_user', requestBody, {
//             headers,
//         });

//         const result = response.data;

//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
