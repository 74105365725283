import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

export const BasicInfo = () => {
    return (
        <Flex
            justify={'space-between'}
            align={'center'}
            gap={'12px'}
            mt={'8px'}
        >
            <Flex
                p={'4px 8px'}
                bg={'#BFC6A2'}
                rounded={'md'}
                fontWeight={'semibold'}
                fontSize={'18px'}
                gap={'8px'}
                minW={'322px'}
                h={'35px'}
            >
                {/* <Text>674</Text>
                <Text>陳美如</Text>
                <Text>100.12.23</Text>
                <Text>H222435827</Text> */}
            </Flex>

            <Flex gap={'8px'}>
                <Flex gap={'4px'} minW={'64px'}>
                    <Text fontWeight={'semibold'}>案件</Text>
                    {/* <Text>01</Text> */}
                </Flex>
                <Flex gap={'4px'} minW={'84px'}>
                    <Text fontWeight={'semibold'}>醫師</Text>
                    {/* <Text>李阿信</Text> */}
                </Flex>
                <Flex gap={'4px'} minW={'84px'}>
                    <Text fontWeight={'semibold'}>藥師</Text>
                    {/* <Text>鍾娃娃</Text> */}
                </Flex>
            </Flex>
        </Flex>
    );
};
