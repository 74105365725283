
export const ADDateToROCStringNoDot = (date: Date) => {


    let rocYear = (date.getFullYear() - 1911).toString().padStart(3, '0');
    const month = date.getMonth() + 1; 
    const day = date.getDate();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${rocYear}${formattedMonth}${formattedDay}`;

};

