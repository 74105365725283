import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IsReferralOpenContextValue {
    isReferralOpenStatus: boolean;
    setIsReferralOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsReferralOpenContext = createContext<
    IsReferralOpenContextValue | undefined
>(undefined);

interface IsReferralOpenProviderProps {
    children: ReactNode;
}

export const IsReferralOpenProvider: React.FC<IsReferralOpenProviderProps> = ({
    children,
}) => {
    const [isReferralOpenStatus, setIsReferralOpenStatus] = useState(false);
    return (
        <IsReferralOpenContext.Provider
            value={{
                isReferralOpenStatus,
                setIsReferralOpenStatus,
            }}
        >
            {children}
        </IsReferralOpenContext.Provider>
    );
};

export const useIsReferralOpenStatus = (): IsReferralOpenContextValue => {
    const context = useContext(IsReferralOpenContext);

    if (!context) {
        throw new Error(
            'useIsReferralOpenStatus must be used within a isReferralOpenStatusProvider'
        );
    }

    return context;
};
