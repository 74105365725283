import React, { createContext, useContext, useState, ReactNode } from 'react';

interface SidebarProps {
    name: string;
    path: string;
    key: string;
}
interface SidebarStatusContextValue {
    sidebarStatus: SidebarProps[];
    setSidebarStatus: React.Dispatch<React.SetStateAction<SidebarProps[]>>;
}

const SidebarStatusContext = createContext<
    SidebarStatusContextValue | undefined
>(undefined);

interface SidebarStatusProviderProps {
    children: ReactNode;
}

export const SidebarStatusProvider: React.FC<SidebarStatusProviderProps> = ({
    children,
}) => {
    const [sidebarStatus, setSidebarStatus] = useState<SidebarProps[]>([]);

    return (
        <SidebarStatusContext.Provider
            value={{ sidebarStatus, setSidebarStatus }}
        >
            {children}
        </SidebarStatusContext.Provider>
    );
};

export const useSidebarStatus = (): SidebarStatusContextValue => {
    const context = useContext(SidebarStatusContext);

    if (!context) {
        throw new Error(
            'useSideBarStatus must be used within a SidebarStatusProvider'
        );
    }

    return context;
};
