import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Text,
    Flex,
    Center,
    Tooltip,
    List,
    ListItem,
    Button,
    useDisclosure,
    Select,
    Input,
    Divider,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    TabIndicator,
} from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import Swal from 'sweetalert2';

import { HIPointList } from './HIPointList';
import { SelfPayList } from './SelfPayList';

import { useUserStore } from 'store';

interface OrdersProps {
    data_id: string;
    order: string;
    use: boolean;
}

export const BillingDetail = () => {
    const { userInfo } = useUserStore();

    return (
        <>
            <Box w={'100%'} h={'100%'} p={'8px 12px'}>
                <Tabs position="relative" variant="unstyled">
                    <TabList gap={'8px'}>
                        <Tab
                            p={'4px 0px'}
                            opacity={0.5}
                            _selected={{
                                opacity: 1,
                                fontWeight: 'semibold',
                                textColor: '#646B48',
                            }}
                        >
                            健保/點數
                        </Tab>
                        <Tab
                            p={'4px 0px'}
                            opacity={0.5}
                            _selected={{
                                opacity: 1,
                                fontWeight: 'semibold',
                                textColor: '#646B48',
                            }}
                        >
                            自費項目
                        </Tab>
                    </TabList>
                    <TabIndicator
                        mt="-1.5px"
                        height="2px"
                        bg="#646B48"
                        borderRadius="1px"
                    />
                    <TabPanels>
                        <TabPanel p={'8px 0px'}>
                            <HIPointList />
                        </TabPanel>
                        <TabPanel p={'8px 0px'}>
                            <SelfPayList />
                        </TabPanel>
                        <TabPanel p={'8px 0px'}>
                            <p>three!</p>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </>
    );
};
