import { callRshisGetRegisterBasicService } from 'service/RsService/RshisGetRegisterBasicService';

export type RegisterBasicProps = {
    cardNumber: string;
    name: string;
    idOrDocumentNumber: string;
    birthDate: string;
    gender: string;
    issueDate: string;
    cancellationMark: string;
    insurerCode: string;
    insureIdentity: string;
    cardValidityPeriod: string;
    medicalTreatmentNumber: string;
    newBornAttachmentDate: string;
    newBornTwinsNote: string;
    PatCardNumber?: string;
    PatName?: string;
    PatIDNo?: string;
    PatBirth?: string;
    PatGender?: string;
    PatCardDate?: string;
    PatCancelMark?: string;
    PatInsurerCode?: string;
    PatInsurerType?: string;
    PatCardExpDate?: string;
    PatAvaliableCount?: string;
    PatNewBornBirth?: string;
    PatNewBornMark?: string;
    pBuffer?: string;
};

export type registerBasicPBufferProps = {
    PatCardNumber: string;
    PatName: string;
    PatIDNo: string;
    PatBirth: string;
    PatGender: string;
    PatCardDate: string;
    PatCancelMark: string;
    PatInsurerCode: string;
    PatInsurerType: string;
    PatCardExpDate: string;
    PatAvaliableCount: string;
    PatNewBornBirth: string;
    PatNewBornMark: string;
    pBuffer: string;
}

export const hisGetRegisterBasic = async (): Promise<RegisterBasicProps> => {
    try {
        const response = await callRshisGetRegisterBasicService();
        console.log(response, 'hisGetRegisterBasic origin data');
        
        let parsedData: RegisterBasicProps;

        if (response.PatCardNumber) {
            // 新版本，直接使用回傳的資料
            console.log('new version')
            parsedData = {
                cardNumber: response.PatCardNumber,
                name: response.PatName || '',
                idOrDocumentNumber: response.PatIDNo || '',
                birthDate: response.PatBirth || '',
                gender: response.PatGender || '',
                issueDate: response.PatCardDate || '',
                cancellationMark: response.PatCancelMark || '',
                insurerCode: response.PatInsurerCode || '',
                insureIdentity: response.PatInsurerType || '',
                cardValidityPeriod: response.PatCardExpDate || '',
                medicalTreatmentNumber: response.PatAvaliableCount || '',
                newBornAttachmentDate: response.PatNewBornBirth || '',
                newBornTwinsNote: response.PatNewBornMark || '',
                ...response
            };
        } else if (response.pBuffer) {
            console.log('old version')
            // 舊版本，需要解析 pBuffer
            const secPBuffer = response.pBuffer
            console.log(secPBuffer, '我還沒切Buffer的資料')
            parsedData = parsePBuffer(secPBuffer);
            console.log('old version data', parsedData)
        } else {
            console.error('Invalid response format');
            throw new Error('Invalid response format');
        }

        console.log(parsedData, 'Parsed data from getregisterbasic service');
        return parsedData;
    } catch (error) {
        console.error('取得掛號基本資訊時發生錯誤:', error);
        throw error;
    }
};

const parsePBuffer = (pBuffer: string): RegisterBasicProps => {
    const parsedData = {
        cardNumber: pBuffer.substring(0, 12).trim(),
        name: pBuffer.substring(12, 28).trim(),
        idOrDocumentNumber: pBuffer.substring(28, 39).trim(),
        birthDate: pBuffer.substring(39, 46).trim(),
        gender: pBuffer.substring(46, 47).trim(),
        issueDate: pBuffer.substring(47, 54).trim(),
        cancellationMark: pBuffer.substring(54, 55).trim(),
        insurerCode: pBuffer.substring(55, 57).trim(),
        insureIdentity: pBuffer.substring(57, 58).trim(),
        cardValidityPeriod: pBuffer.substring(58, 65).trim(),
        medicalTreatmentNumber: pBuffer.substring(65, 67).trim(),
        newBornAttachmentDate: pBuffer.substring(67, 74).trim(),
        newBornTwinsNote: pBuffer.substring(74, 81).trim(),
    };

    console.log('Parsed pBuffer values:');
    for (const [key, value] of Object.entries(parsedData)) {
        console.log(`${key}: "${value}"`);
    }

    return parsedData;
};
