import axios from 'axios';
import axiosInstance from './axiosInstance';
import apiService from './apiService';

// 取得群組清單及所有權限明細
export const callGroupAuthListService = async (token: string) => {
    return apiService<string, any>({ 
        method: 'GET',
        path: '/api/group_auth/list',
        token,
    });
};
// export const callGroupAuthListService = async (token: string) => {
//     try {
//         const headers = {
//             'Api-Token': `${token}`,
//         };
//         const response = await axiosInstance.get('/api/group_auth/list', {
//             headers,
//         });
//         const result = response.data;
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };



interface updatedDataProps {
    data_id?: string;
    name: string;
    func_auth: {
        [key: string]: string; // 使用索引签名定义 func_auth
    };
}
// 增修群組的權限明細
export const postGroupAuthService = async (
    token: string,
    updatedData: updatedDataProps
) => {
    return apiService<updatedDataProps, any>({
        method: 'POST',
        path: '/api/group_auth',
        token,
        data: updatedData
    })
}
// export const postGroupAuthService = async (
//     token: string,
//     updatedData: updatedDataProps
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };

//         const response = await axiosInstance.post('/api/group_auth', requestBody, {
//             headers,
//         });
//         const result = response.data;

//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };




// 刪除看診時段
export const deleteGroupAuthService = async (
    token: string,
    data_id: string
) => {
    return apiService<undefined, any>({
        method: 'DELETE',
        path: `/api/group_auth?id=${data_id}`,
        token
    })
}
// export const deleteGroupAuthService = async (token: string, dataId: string) => {
//     try {
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         const url = `/api/group_auth?id=${dataId}`;

//         const response = await axiosInstance.delete(url, {
//             headers,
//         });

//         const result = response.data;

//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
