import { create } from 'zustand';
import { useOpdModelStore, OpdModelProps } from './useOpdModelStore';
import {
    useMedicalRecordModelStore,
    MedicalRecordModelProps,
} from './useMedicalRecordModelStore';
import {
    useMedicalRecordDiagModelStore,
    MedicalRecordDiagModelProps,
} from './useMedicalRecordDiagModelStore';
import {
    useMedicalRecordDrugModelStore,
    MedicalRecordDrugModelProps,
} from './useMedicalRecordDrugModelStore';
import { useChargeModelStore, ChargeModelProps } from './useChargeModelStore';
import {
    useHospitalModelStore,
    HospitalModelProps,
} from './useHospitalModelStore';
import {
    usePatientModelStore,
    PatientModelProps,
} from './usePatientModelStore';
import {
    useSlowRecModelStore,
    SlowRecModelProps,
} from './useSlowRecModelStore';

// 定義 PrintCompleteClinicProps 接口
export interface PrintCompleteClinicProps {
    FormList: string[];
    Opd: OpdModelProps; //
    MedicalRecord: MedicalRecordModelProps; //病歷模型
    MedicalRecordDiag: MedicalRecordDiagModelProps[];
    MedicalRecordDrug: MedicalRecordDrugModelProps[];
    Charge: ChargeModelProps;
    Hospital: HospitalModelProps;
    Patient: PatientModelProps;
    SlowRec: SlowRecModelProps;
}

// 初始化 PrintCompleteClinicProps
export const initialPrintCompleteClinicData: PrintCompleteClinicProps = {
    FormList: [''],
    Opd: useOpdModelStore.getState().opdModel, // 從 useOpdModelStore 獲取初始 patient
    MedicalRecord: useMedicalRecordModelStore.getState().medicalRecord,
    MedicalRecordDiag: useMedicalRecordDiagModelStore.getState().diagModels,
    MedicalRecordDrug: useMedicalRecordDrugModelStore.getState().drugModels,
    Charge: useChargeModelStore.getState().chargeModel,
    Hospital: useHospitalModelStore.getState().hospitalModel,
    Patient: usePatientModelStore.getState().patientModel,
    SlowRec: useSlowRecModelStore.getState().slowRecModel,
};

// 定義 PrintCompleteClinicState 接口
interface PrintCompleteClinicState {
    PrintCompleteClinicData: PrintCompleteClinicProps;
    updatePrintCompleteClinicData: (
        newData: Partial<PrintCompleteClinicProps>
    ) => void;
    resetPrintCompleteClinicData: () => void;
}

// 創建 usePrintCompleteClinicStore
export const usePrintCompleteClinicStore = create<PrintCompleteClinicState>(
    set => ({
        PrintCompleteClinicData: initialPrintCompleteClinicData,
        updatePrintCompleteClinicData: newData =>
            set(state => ({
                PrintCompleteClinicData: {
                    ...state.PrintCompleteClinicData,
                    ...newData,
                },
            })),
        resetPrintCompleteClinicData: () =>
            set(() => ({
                PrintCompleteClinicData: initialPrintCompleteClinicData,
            })),
    })
);

export default usePrintCompleteClinicStore;
