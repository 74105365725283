import React, { createContext, useContext, useState, ReactNode } from 'react';

export enum RightSideOptionSelectStatus {
    Mtable = 'Mtable',
    MedicalRecord = 'MedicalRecord'
}

interface RightSideOptionSelectContextValue {
    rightSideOptionSelectStatus: RightSideOptionSelectStatus;
    setRightSideOptionSelectStatus: React.Dispatch<React.SetStateAction<RightSideOptionSelectStatus>>;
}

const RightSideOptionSelectContext = createContext<RightSideOptionSelectContextValue | undefined>(
    undefined
);

interface RightSideOptionSelectProviderProps {
    children: ReactNode;
}

export const RightSideOptionSelectProvider: React.FC<RightSideOptionSelectProviderProps> = ({
    children,
}) => {
    const [rightSideOptionSelectStatus, setRightSideOptionSelectStatus] = useState<RightSideOptionSelectStatus>(
        RightSideOptionSelectStatus.MedicalRecord
    );

    return (
        <RightSideOptionSelectContext.Provider
            value={{
                rightSideOptionSelectStatus,
                setRightSideOptionSelectStatus,
            }}
        >
            {children}
        </RightSideOptionSelectContext.Provider>
    );
};

export const useRightSideOptionSelectStatus = (): RightSideOptionSelectContextValue => {
    const context = useContext(RightSideOptionSelectContext);

    if (!context) {
        throw new Error(
            'useRightSideOptionSelectStatus must be used within a RightSideOptionSelectProvider'
        );
    }

    return context;
};
