import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        // 500: 主色
        // 600: hover
        // 700: active
        rsPrimary: {
            50: '#ECECEC',
            100: '#D2D2B3',
            400: '#555B3E',
            500: '#45533F',
            600: '#646B48',
            700: '#708368',
            800: '#5e6639',
            900: '#646B48',
        },

        rsSecondary: {
            500: '#95B4B3',
            600: '#79A09F',
            700: '#5F8C8A',
        },

        rsDanger: {
            500: '#D08C60',
            600: '#C26326',
            700: '#EEC9B1',
            800: '#C5713A',
        },

        rsWarning: {
            500: '#e4c776',
            600: '#D8AD37',
            700: '#F7E9C3',
        },
        rsSteel: {
            500: '#A9A9A9',
            600: '#DBD5D5',
        },

        gray: {
            50: '#fafafa',
            150: '#E9E5DA',
            250: '#F8F3E7',
            550: '#ECECEC',
        },

        // 給號規則色碼
        numRule: {
            100: '#8B8C6E',
            150: '#8B8C6Eaa',

            200: '#E7D7C1',
            250: '#E7D7C1aa',

            300: '#ECD38F',
            350: '#ECD38Faa',

            400: '#95B4B3',
            450: '#95B4B3aa',

            500: '#B392AC',
            550: '#B392ACaa',

            600: '#A3B18A',
            650: '#A3B18Aaa',

            700: '#D08C60',
            750: '#D08C60aa',

            800: '#BDE0FE',
            850: '#BDE0FEaa',

            900: '#FFCDB2',
            950: '#FFCDB2aa',
        },
    },

    components: {
        // Button: {
        //     baseStyle: {
        //         fontWeight: 'semibold',
        //         boxShadow: '2px -2px 2px 0px rgba(0, 0, 0, 0.50) inset',
        //         _hover: {
        //             boxShadow: 'inset 0px 0px 2px 0px rgba(0,0,0,0.25)',
        //         },
        //     },
        // },
    },
    textStyles: {
        semiTitle: {
            fontWeight: 'semibold',
            fontSize: '16px',
            color: '#45533F',
        },
    },
});

export default theme;
