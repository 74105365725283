import React from 'react';
import { Box, Flex, Center, Text, Button } from '@chakra-ui/react';

export const SideMenu = () => {
    return (
        <Flex
            p={'16px 24px'}
            direction={'column'}
            bg={'#F5F5F5'}
            gap={'8px'}
            rounded={'lg'}
        >
            <Button
                variant={'outline'}
                colorScheme="rsDanger"
                borderWidth={'2px'}
                boxShadow={'inset 4px 2px 2px rgba(0,0,0,0.25)'}
            >
                診斷證明書
            </Button>
            <Button
                variant={'outline'}
                colorScheme="rsPrimary"
                borderWidth={'2px'}
                boxShadow={'inset 4px 2px 2px rgba(0,0,0,0.25)'}
            >
                就醫證明
            </Button>
        </Flex>
    );
};
