import {
    TableContainer,
    Table,
} from '@chakra-ui/react';
import {} from '../WaitingList';
import { AppointmentListHeader } from './AppointmentListHeader';
import { AppointmentListBody } from './AppointmentListBody';
import { WaitingListsFilterBar } from '../WaitingList/WaitingListsFilterBar';
import { WaitingListsStatusProvider } from 'contexts/Registration/WaitingListsStatusContext';

export const AppointmentList = () => {
    return (
        <>
            <WaitingListsStatusProvider>
                <WaitingListsFilterBar />
                <TableContainer
                    h={`calc(${window.innerHeight}px - 374px)`}
                    overflowY={'scroll'}
                >
                    <Table>
                        <AppointmentListHeader />
                        <AppointmentListBody />
                    </Table>
                </TableContainer>
            </WaitingListsStatusProvider>
        </>
    );
};
