import {
    Grid,
    GridItem,
    Input,
    InputGroup,
    Box,
    Select,
    Button,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useUnsavedChanges } from 'contexts/UnsavedChangesContext';
import { BasicInfoProps } from './DrugDataMaintenanceTable';
import ROCDatePicker from 'components/CustomComponents/ROCDatePicker';
import moment from 'moment';
import { useUserStore } from 'store';

import { callDrugFreqListsService } from 'service/drugFreqListsService';
import { useDropDownMenuQuery } from 'query/useDropDownMenuQuery';
import { useDrugFreqListsStore } from 'store/DataSetting/useDrugFreqListStore';
import { routeOfAdministrationProps } from 'service/gTypeService';

interface DrugDataMaintenanceBasicInfoProps {
    basicInfoData: BasicInfoProps;
    setBasicInfoData: React.Dispatch<React.SetStateAction<BasicInfoProps>>;
    isEditing: boolean;
    shortCodeInputRef: React.RefObject<HTMLInputElement>;
    // isChanged: boolean;
}

interface drugTypeListProps {
    data_id: string;
    code: string;
    name: string;
}

const DrugDataMaintenanceBasicInfo = ({
    basicInfoData,
    setBasicInfoData,
    isEditing,
    shortCodeInputRef,
}: // isChanged,
DrugDataMaintenanceBasicInfoProps) => {
    const { userInfo } = useUserStore();

    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    const handleChangeInput = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        const regex = /^(?:\d{0,5}(?:\.\d{0,2})?|\.\d{1,2})$/;

        if (
            name === 'self_price' ||
            name === 'cost' ||
            name === 'dose' ||
            name === 'max_dose' ||
            name === 'max_dose_day'
        ) {
            if (regex.test(value)) {
                setBasicInfoData((prev: BasicInfoProps) => ({
                    ...prev,
                    [name]: value,
                }));
            }
            setHasUnsavedChanges(true);
        } else if (name === 'controlled_level') {
            // 檢查值是否為 1, 2, 3, 4 或空字符串
            if (['1', '2', '3', '4', ''].includes(value)) {
                // 如果值為空字符串，將其轉換為 null
                setBasicInfoData((prev: BasicInfoProps) => ({
                    ...prev,
                    controlled_level: value === '' ? null : value,
                }));
            }
            // 確保其他更改不會丟失
            setHasUnsavedChanges(true);
            return;
        } else if (name === 'dosing_days') {
            const regex = /^(?:\d{0,3})$/;
            if (regex.test(value)) {
                setBasicInfoData((prev: BasicInfoProps) => ({
                    ...prev,
                    [name]: value,
                }));
            }
        } else if (name === 'freq') {
            const selectedItem = drugFreqListsData.find(
                item => item.data_id === value
            );
            const directions = selectedItem ? selectedItem.directions : '';

            setBasicInfoData((prev: BasicInfoProps) => ({
                ...prev,
                [name]: value,
                freq_directions: directions,
            }));
        } else if (name === 'drug_type') {
            if (value === '') {
                setBasicInfoData((prev: BasicInfoProps) => ({
                    ...prev,
                    drug_type: '',
                    drug_type_code: '',
                }));
            } else {
                const selectedDrugType = drugTypeList.find(
                    item => item.data_id === value
                );
                if (selectedDrugType) {
                    setBasicInfoData((prev: BasicInfoProps) => ({
                        ...prev,
                        drug_type: value,
                        drug_type_code: selectedDrugType.code,
                    }));
                }
            }
            setHasUnsavedChanges(true);
        } else {
            setBasicInfoData((prev: BasicInfoProps) => ({
                ...prev,
                [name]: value,
            }));
            setHasUnsavedChanges(true);
        }
    };

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [preSelectedDate, setPreSelectedDate] = useState<Date | null>(null);
    const handleChangeDate = (selectedDate: Date | null) => {
        setSelectedDate(selectedDate);
        console.log(moment(selectedDate).format(`YYYY-MM-DD`));
        setBasicInfoData((prev: BasicInfoProps) => ({
            ...prev,
            disabled_date: moment(selectedDate).format(`YYYY-MM-DD`),
        }));
    };
    const handleFocusDate = (_: React.FocusEvent<HTMLInputElement>) => {
        setPreSelectedDate(null);
        // setPreSelectedDate(selectedDate);
        setSelectedDate(null);
    };

    const handleBlur = (event: any) => {
        if (event.currentTarget.value.length < 6) {
            setSelectedDate(preSelectedDate);
            return;
        }

        if (event.currentTarget.value.length === 7) {
            const enteredValue = event.currentTarget.value;
            const year = parseInt(enteredValue.substring(0, 3)) + 1911;
            const month = enteredValue.substring(3, 5);
            const day = enteredValue.substring(5, 7);

            const enteredDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
            //若輸入時間為之前 則顯示今天
            if (enteredDate.isBefore(moment())) {
                setSelectedDate(preSelectedDate);
                return;
            }
            const parsedDate = moment(`${year}.${month}.${day}`, 'YYYY.MM.DD');
            if (parsedDate.isValid()) {
                handleChangeDate(parsedDate.toDate());
            }
        } else if (event.currentTarget.value.length === 6) {
            const enteredValue = event.currentTarget.value;
            const year = parseInt(`0${enteredValue.substring(0, 2)}`) + 1911;
            const month = enteredValue.substring(2, 4);
            const day = enteredValue.substring(4, 6);
            const enteredDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
            //若輸入時間為之前 則顯示今天
            if (enteredDate.isBefore(moment())) {
                setSelectedDate(preSelectedDate);
                return;
            }
            const parsedDate = moment(`${year}.${month}.${day}`, 'YYYY.MM.DD');
            if (parsedDate.isValid()) {
                handleChangeDate(parsedDate.toDate());
            }
        }
    };
    const [nameSwitch, setNameSwitch] = useState(true);
    useEffect(() => {
        setSelectedDate(null);
    }, [basicInfoData.data_id]);

    // useQuery Lists

    // 藥類清單
    const [drugTypeList, setDrugTypeList] = useState<drugTypeListProps[]>([]);
    const { data: drugTypeListData, isSuccess: isDrugTypeListSuccess } =
        useDropDownMenuQuery('drugTypeList', userInfo.token!);
    useEffect(() => {
        if (isDrugTypeListSuccess) {
            setDrugTypeList(drugTypeListData.lists);
        }
    }, [isDrugTypeListSuccess]);

    // 用藥頻率清單
    const { drugFreqListsData, updateDrugFreqListsData } =
        useDrugFreqListsStore();
    const { data: drugFreqListData, isSuccess: isDrugFreqListSuccess } =
        useQuery(
            'drugFreqList',
            () => callDrugFreqListsService(userInfo.token),
            { enabled: !!userInfo.token, staleTime: Infinity }
        );
    useEffect(() => {
        if (isDrugFreqListSuccess) {
            updateDrugFreqListsData(drugFreqListData.lists);
        }
    }, [isDrugFreqListSuccess]);

    // 給藥途徑清單 route_of_administration
    const [routeOfAdministration, setRouteOfAdministration] = useState<
        routeOfAdministrationProps[]
    >([]);
    const {
        data: routeOfAdministrationData,
        isSuccess: isRouteOfAdministrationSuccess,
    } = useDropDownMenuQuery('routeOfAdministration', userInfo.token!);
    useEffect(() => {
        if (isRouteOfAdministrationSuccess) {
            setRouteOfAdministration(routeOfAdministrationData.lists);
        }
    }, [isRouteOfAdministrationSuccess]);

    return (
        <>
            <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px] text-[#95B4B3] text-end font-semibold shrink-0">
                        健保碼
                    </div>
                    <Input
                        className="text-black"
                        disabled
                        name="drug_no"
                        value={basicInfoData.drug_no}
                        borderColor={'#95B4B3'}
                        opacity={1}
                        maxLength={10}
                        bg={isEditing ? '#D6EDED' : '#C3DBDB'}
                    ></Input>
                </GridItem>
                <GridItem colSpan={2} className="flex items-center gap-3">
                    <div className="w-[64px] text-end font-semibold shrink-0">
                        藥品名稱
                    </div>
                    <InputGroup className=" relative flex items-center space-x-2">
                        <Input
                            disabled
                            className={` text-black`}
                            maxLength={255}
                            onChange={handleChangeInput}
                            opacity={1}
                            name={nameSwitch ? 'name_tw' : 'name_en'}
                            value={
                                nameSwitch
                                    ? basicInfoData.name_tw
                                    : basicInfoData.name_en
                            }
                            bg={isEditing ? '#fff' : 'transparent'}
                        />

                        <Box className="flex space-x-2">
                            <Button
                                size={'sm'}
                                rounded={4}
                                className=" h-5 w-5 leading-5 text-center font-semibold  "
                                bgColor={nameSwitch ? '#62624B' : '#9B9B7A'}
                                _hover={{ filter: 'brightness(1.2)' }}
                                color={'white'}
                                onClick={() => {
                                    setNameSwitch(true);
                                }}
                            >
                                中
                            </Button>
                            <Button
                                size={'sm'}
                                rounded={4}
                                className=" h-5 w-5 leading-5 text-center font-semibold   "
                                color={'white'}
                                bgColor={nameSwitch ? '#9B9B7A' : '#62624B'}
                                _hover={{ filter: 'brightness(1.2)' }}
                                onClick={() => {
                                    setNameSwitch(false);
                                }}
                            >
                                英
                            </Button>
                        </Box>
                    </InputGroup>
                </GridItem>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px] text-[#95B4B3] text-end font-semibold shrink-0">
                        健保價
                    </div>
                    <Input
                        className="text-black"
                        disabled
                        name="drug_price"
                        value={basicInfoData.drug_price}
                        borderColor={'#95B4B3'}
                        opacity={1}
                        bg={isEditing ? '#D6EDED' : '#C3DBDB'}
                    ></Input>
                </GridItem>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        支付成數
                    </div>
                    <Input
                        className="text-black"
                        disabled
                        name="rateType"
                        value={basicInfoData.rateType}
                        opacity={1}
                    ></Input>
                </GridItem>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        兒童加成
                    </div>

                    <Select
                        className="text-black"
                        name="childrenBonus"
                        defaultValue={'請選擇'}
                        disabled
                        opacity={1}
                        value={basicInfoData.childrenBonus || ''}
                    ></Select>
                </GridItem>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        藥品代碼
                    </div>
                    <Input
                        ref={shortCodeInputRef}
                        className="text-black"
                        disabled={!isEditing}
                        onChange={handleChangeInput}
                        name="short_code"
                        value={basicInfoData.short_code}
                        maxLength={10}
                        bg={isEditing ? '#fff' : 'transparent'}
                        opacity={1}
                    ></Input>
                </GridItem>
                <GridItem colSpan={2} className="flex items-center gap-3">
                    <div className="w-[64px] text-end font-semibold shrink-0">
                        自訂名稱
                    </div>
                    <InputGroup className="relative flex items-center space-x-2">
                        <Input
                            disabled={!isEditing}
                            className={` text-black`}
                            onChange={handleChangeInput}
                            opacity={1}
                            maxLength={255}
                            name={
                                nameSwitch ? 'custom_name_tw' : 'custom_name_en'
                            }
                            value={
                                nameSwitch
                                    ? basicInfoData.custom_name_tw
                                    : basicInfoData.custom_name_en
                            }
                            bg={isEditing ? '#fff' : 'transparent'}
                        />

                        <Box className="flex space-x-2">
                            <Button
                                size={'sm'}
                                rounded={4}
                                className=" h-5 w-5 leading-5 text-center font-semibold  "
                                bgColor={nameSwitch ? '#62624B' : '#9B9B7A'}
                                _hover={{ filter: 'brightness(1.2)' }}
                                color={'white'}
                                onClick={() => {
                                    setNameSwitch(true);
                                }}
                            >
                                中
                            </Button>
                            <Button
                                size={'sm'}
                                rounded={4}
                                className=" h-5 w-5 leading-5 text-center font-semibold   "
                                color={'white'}
                                bgColor={nameSwitch ? '#9B9B7A' : '#62624B'}
                                _hover={{ filter: 'brightness(1.2)' }}
                                onClick={() => {
                                    setNameSwitch(false);
                                }}
                            >
                                英
                            </Button>
                        </Box>
                    </InputGroup>
                </GridItem>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px] text-[#95B4B3] text-end font-semibold shrink-0">
                        自費價
                    </div>
                    <Input
                        className="text-black"
                        disabled={!isEditing}
                        name="self_price"
                        value={basicInfoData.self_price}
                        onChange={handleChangeInput}
                        bgColor={'#C3DBDB'}
                        borderColor={'#95B4B3'}
                        bg={isEditing ? '#D6EDED' : '#C3DBDB'}
                    ></Input>
                </GridItem>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px] text-[#95B4B3] text-end font-semibold shrink-0">
                        成本價
                    </div>
                    <Input
                        className="text-black"
                        disabled={!isEditing}
                        name="cost"
                        value={basicInfoData.cost}
                        onChange={handleChangeInput}
                        bgColor={'#C3DBDB'}
                        borderColor={'#95B4B3'}
                        bg={isEditing ? '#D6EDED' : '#C3DBDB'}
                    ></Input>
                </GridItem>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px] text-[#E9B491] text-end font-semibold shrink-0">
                        管制等級
                    </div>
                    <Input
                        className="text-black"
                        disabled={!isEditing}
                        placeholder={'1-4'}
                        name="controlled_level"
                        value={basicInfoData.controlled_level ?? ''}
                        bg={isEditing ? '#E9B491' : '#F2CCB3'}
                        onChange={handleChangeInput}
                    ></Input>
                </GridItem>
                <GridItem className="flex items-center gap-3" colSpan={2}>
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        使用頻率
                    </div>

                    <Select
                        textColor={'#000'}
                        disabled={!isEditing}
                        onChange={handleChangeInput}
                        value={basicInfoData.freq}
                        name="freq"
                        bg={isEditing ? '#fff' : 'transparent'}
                    >
                        <option value="">請選擇</option>
                        {drugFreqListsData &&
                            drugFreqListsData.map((item, index) => (
                                <option key={index} value={item.data_id}>
                                    {item.code} {item.directions}
                                </option>
                            ))}
                    </Select>
                </GridItem>
                {/* <GridItem className="flex items-center gap-3">
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        服用時間
                    </div>
                    <Select
                        onChange={handleChangeInput}
                        name="taking_time"
                        className="text-black"
                        disabled={!isEditing}
                        value={basicInfoData.taking_time || ''}
                        bg={isEditing ? '#fff' : 'transparent'}
                    >
                        {drugFreqListsData &&
                            drugFreqListsData
                                .filter(item => item.type === 4)
                                .map((item, index) => (
                                    <option key={index} value={item.data_id}>
                                        {item.code} {item.directions}
                                    </option>
                                ))}
                    </Select>
                </GridItem> */}
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        預設天數
                    </div>
                    <Input
                        className="text-black"
                        disabled={!isEditing}
                        onChange={handleChangeInput}
                        name="dosing_days"
                        value={basicInfoData.dosing_days}
                        bg={isEditing ? '#fff' : 'transparent'}
                    ></Input>
                </GridItem>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        給藥途徑
                    </div>
                    <Select
                        disabled={!isEditing}
                        onChange={handleChangeInput}
                        value={basicInfoData.route_of_administration || ''}
                        name="route_of_administration"
                        className="text-black"
                        bg={isEditing ? '#fff' : 'transparent'}
                    >
                        <option value="">請選擇</option>
                        {routeOfAdministration &&
                            routeOfAdministration.map((item, index) => (
                                <option value={item.code} key={index}>
                                    {item.code} {item.name}
                                </option>
                            ))}
                    </Select>
                </GridItem>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        預設劑量
                    </div>
                    <Input
                        className="text-black"
                        disabled={!isEditing}
                        onChange={handleChangeInput}
                        value={basicInfoData.dose}
                        name="dose"
                        bg={isEditing ? '#fff' : 'transparent'}
                    ></Input>
                </GridItem>
                <div className="lg:block hidden">{/* 卡位 */}</div>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        藥類
                    </div>
                    <Select
                        bg={isEditing ? '#fff' : 'transparent'}
                        textColor={'#000'}
                        disabled={!isEditing}
                        name="drug_type"
                        onChange={handleChangeInput}
                        value={basicInfoData.drug_type || ''}
                    >
                        <option value="">請選擇</option>
                        {drugTypeList &&
                            drugTypeList.map((item, index) => (
                                <option key={index} value={item.data_id}>
                                    {item.code} {item.name}
                                </option>
                            ))}
                    </Select>
                </GridItem>

                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        單位
                    </div>
                    <Input
                        className="text-black"
                        disabled
                        name="unit"
                        value={basicInfoData.unit}
                        opacity={1}
                    ></Input>
                </GridItem>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        劑型
                    </div>
                    <Input
                        className="text-black"
                        disabled
                        name="dosage_form"
                        value={basicInfoData.dosage_form}
                        opacity={1}
                    ></Input>
                </GridItem>

                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px]  text-end font-semibold shrink-0">
                        單次限量
                    </div>
                    <Input
                        className="text-black"
                        disabled={!isEditing}
                        onChange={handleChangeInput}
                        value={basicInfoData.max_dose}
                        name="max_dose"
                        bg={isEditing ? '#fff' : 'transparent'}
                    ></Input>
                </GridItem>

                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px] text-[#E9B491] text-end font-semibold shrink-0">
                        每日極量
                    </div>
                    <Input
                        className="text-black"
                        disabled={!isEditing}
                        onChange={handleChangeInput}
                        name="max_dose_day"
                        value={basicInfoData.max_dose_day}
                        bg={isEditing ? '#E9B491' : '#F2CCB3'}
                    ></Input>
                </GridItem>
                <div className="lg:block hidden">{/* 卡位 */}</div>
                <GridItem className="flex items-center gap-3">
                    <div className="w-[64px] text-[#E9B491] text-end font-semibold shrink-0">
                        停用日期
                    </div>
                    <div className="z-20">
                        <ROCDatePicker
                            disabled={!isEditing}
                            onChange={handleChangeDate}
                            selectedDate={selectedDate}
                            handleChangeDate={handleChangeDate}
                            onFocus={handleFocusDate}
                            onBlur={handleBlur}
                            name="disabled_date"
                            minDate={new Date()}
                        ></ROCDatePicker>
                    </div>
                </GridItem>
            </Grid>
        </>
    );
};

export default DrugDataMaintenanceBasicInfo;
