import {create}from'zustand'


export interface CCPEProps {
    cc?: string;
    pe?: string;
}
const initialCCPEData: CCPEProps = {
    cc: '',
    pe: ''
}
interface CCPEState {
    CCPEData: CCPEProps;
    updateCCPEData: (newData: Partial<CCPEProps>) => void;
    resetCCPEData: () => void;
}


export const useCCPEStore = create<CCPEState>((set)=> ({
    CCPEData: initialCCPEData,
    updateCCPEData: (newData)=>
    set((state)=>({
        CCPEData: {...state.CCPEData, ...newData}
    })),
    resetCCPEData: () =>
    set(()=>({
        CCPEData: initialCCPEData
    }))
}))