import { Flex, Spinner } from '@chakra-ui/react';

const Loading = () => {
    return (
        <Flex
            pos={'absolute'}
            zIndex={40}
            inset={0}
            align={'center'}
            justify={'center'}
            bg={'#00000001'}
        >
            <Spinner size="xl" emptyColor="gray.200" color="blue.500" />
        </Flex>
    );
};

export default Loading;
