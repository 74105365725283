import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

const axiosInstance = axios.create();
interface DecodedToken {
    exp?: number;
    iat? :number;
    [key: string]: any; 
}
  
axiosInstance.interceptors.request.use(
  (config) => {
    const XTenant = localStorage.getItem('X-Tenant');
    if (XTenant) {
      config.headers['X-Tenant'] = XTenant;
    }

    const token = localStorage.getItem('token');
    if (token) {
        const decodedToken: DecodedToken = jwtDecode(token);
        if (decodedToken.exp && decodedToken.exp * 1000 > Date.now()) {
        config.headers['Authorization'] = token;
        // console.log('token認證通過')
    } else {
        console.log('token過期')
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
