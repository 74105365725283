import PersonnelProfileSettingTable from 'components/DataSettings/PersonnelProfileSettingComponents/PersonnelProfileSettingTable';
// import {Index} from 'components/DataSettings/PersonnelProfileSettingComponents/index'
import { useState } from 'react';
import { PersonnelProfileSettingStatusProvider } from 'contexts/DataSetting/personnelProfileSettingContext';
const PersonnelProfileSettingPage = () => {
    const [bgOpenStatus, setBgOpenStatue] = useState(false);
    const handleOpen = () => {
        setBgOpenStatue(true);
    };
    const handleClose = () => {
        setBgOpenStatue(false);
    };
    return (
        <>
            <PersonnelProfileSettingStatusProvider>
                <PersonnelProfileSettingTable />
                {/* <Index/> */}
            </PersonnelProfileSettingStatusProvider>
        </>
    );
};
export default PersonnelProfileSettingPage;
