import apiService from './apiService'



export interface vitalSignSettingListsProps {
    id: string,
    name: string,
    unit: string,
    exam_code : null | string,
    if_show: number,
    seq: number,
}

// 取得基礎體徵項目設定清單
export const callVitalSignSettingListsService = async (token: string) => {
    return apiService<undefined, any>({
        method: 'GET',
        path: '/api/vital_sign/setting/lists',
        token,
    });
};


