import { Center, Text, Box, Select } from '@chakra-ui/react';
import { HeroIcons } from 'components/CustomComponents/CustomHeroIcons';
import React, { useState, useEffect } from 'react';
import ROCDatePicker from 'components/CustomComponents/ROCDatePicker';
import moment from 'moment';
import { useICCardUpdateRangeStore } from '../../../store/ICCardUpdate/useICCardUpdateRangeStore';

export const DatePick = () => {
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [inputValue, setInputValue] = useState('');
    const [preSelectedDate, setPreSelectedDate] = useState<Date>(new Date());
    const { icCardUpdateRangeData, updateICCardUpdateRangeData } =
        useICCardUpdateRangeStore();

    const handleChangeDate = (date: Date | null) => {
        if (date && moment(date).isSameOrBefore(moment(), 'day')) {
            setSelectedDate(date);
        } else {
            setSelectedDate(new Date());
        }
    };

    const handleFocusDate = () => {
        setPreSelectedDate(selectedDate);
        setSelectedDate(new Date());
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const enteredValue = event.currentTarget.value.replace(/\./g, '');

        if (enteredValue.length < 6) {
            setSelectedDate(preSelectedDate);
            return;
        }

        const yearOffset = enteredValue.length === 7 ? 0 : 1;
        const year = parseInt(enteredValue.substring(0, 3 - yearOffset)) + 1911;
        const month = enteredValue.substring(3 - yearOffset, 5 - yearOffset);
        const day = enteredValue.substring(5 - yearOffset);

        const formattedDate = `${year}-${month}-${day}`;
        const enteredDate = moment(formattedDate, 'YYYY-MM-DD');

        if (!enteredDate.isValid() || enteredDate.isAfter(moment(), 'day')) {
            setSelectedDate(preSelectedDate);
        } else if (enteredDate.toDate() !== selectedDate) {
            handleChangeDate(enteredDate.toDate());
        }
    };

    useEffect(() => {
        const rocYear = selectedDate.getFullYear() - 1911;
        const formattedDate = moment(selectedDate).format('MM.DD');
        setInputValue(`${rocYear}.${formattedDate}`);
    }, [selectedDate]);

    useEffect(() => {
        const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
        updateICCardUpdateRangeData({
            ...icCardUpdateRangeData,
            date: formattedDate,
        });
    }, [selectedDate]);

    useEffect(() => {
        if (!icCardUpdateRangeData.date) {
            const today = moment().format('YYYY-MM-DD');
            updateICCardUpdateRangeData({
                ...icCardUpdateRangeData,
                date: today,
            });
        }
    }, []);

    return (
        <Center gap={'16px'} zIndex={50}>
            <Text fontWeight={'semibold'} flex={'0 0 auto'}>
                日期
            </Text>
            <ROCDatePicker
                onChange={handleChangeDate}
                selectedDate={selectedDate}
                handleChangeDate={handleChangeDate}
                onFocus={handleFocusDate}
                onBlur={handleBlur}
                maxDate={new Date()}
            />
            <Box w={'24px'} h={'24px'} flex={'0 0 auto'}>
                <HeroIcons name="calendar-days" />
            </Box>
        </Center>
    );
};
