import React, { createContext, useContext, useState, ReactNode } from 'react';

interface WeekRangeProps {
    startOfWeek: Date;
    endOfWeek: Date;
}

interface WeekRangeContextValue {
    weekRange: WeekRangeProps[];
    setWeekRange: React.Dispatch<React.SetStateAction<WeekRangeProps[]>>;
    selectedDate: Date | null;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
}
const WeekRangeContext = createContext<WeekRangeContextValue | undefined>(
    undefined
);

interface WeekRangeProviderProps {
    children: ReactNode;
}

export const WeekRangeProvider: React.FC<WeekRangeProviderProps> = ({
    children,
}) => {
    const [weekRange, setWeekRange] = useState<WeekRangeProps[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date()); // 允许 selectedDate 是 Date 或 null

    return (
        <WeekRangeContext.Provider
            value={{ weekRange, setWeekRange, selectedDate, setSelectedDate }}
        >
            {children}
        </WeekRangeContext.Provider>
    );
};

export const useWeekRange = (): WeekRangeContextValue => {
    const context = useContext(WeekRangeContext);

    if (!context) {
        throw new Error(
            'useSideBarStatus must be used within a WeekRangeProvider'
        );
    }

    return context;
};
