import React from 'react';
import { Text } from '@chakra-ui/react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react';

type ListProps = {
    code?: string;
    name?: string;
    time?: number;
    day?: number;
    freq?: string;
    part?: string;
    total?: number;
    point?: number;
    percent?: number;
    member?: string;
};

export const RxList = () => {
    const list: ListProps[] = [];
    const fakeList: ListProps[] = [
        {
            code: 'A039609151',
            name: 'Cypromin Solut Cypromin Solut',

            time: 1,
            day: 3,
            freq: 'QID',
            part: '',
            total: 36,
            point: 3.85,
            percent: 0,
            member: '',
        },
        {
            code: 'A023490100',
            name: ' Cypromin Solut Cypromin Solut',

            time: 1,
            day: 3,
            freq: 'QID',
            part: '',
            total: 36,
            point: 1.54,
            percent: 0,
            member: '',
        },
        {
            code: 'A024857118',
            name: ' Cypromin Solut Cypromin Solut',

            time: 1,
            day: 3,
            freq: 'QID',
            part: '',
            total: 36,
            point: 2.22,
            percent: 0,
            member: '',
        },
    ];

    return (
        <TableContainer
            my={'12px'}
            h={`calc(${window.innerHeight}px - 674px)`}
            overflowY={'scroll'}
        >
            <Table variant={'striped'} size={'sm'}>
                <Thead
                    bg={'#D9D9D9'}
                    pos={'sticky'}
                    top={0}
                    left={0}
                    zIndex={'10'}
                >
                    <Tr>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>健保碼</Text>
                        </Th>
                        <Th p={'4px 8px'} w={'130px'}>
                            <Text fontSize={'md'}>藥品名稱</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>次</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>天</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>頻率</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>部位</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>總量</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>點數</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>%</Text>
                        </Th>
                        <Th p={'4px 8px'}>
                            <Text fontSize={'md'}>執行人員</Text>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {list &&
                        list.map((item, index) => (
                            <Tr key={index}>
                                <Td p={'4px 8px'}>{item.code}</Td>
                                <Td p={'4px 8px'}>
                                    <Text
                                        w={'130px'}
                                        textOverflow={'ellipsis'}
                                        overflow={'hidden'}
                                        whiteSpace={'nowrap'}
                                    >
                                        {item.name}
                                    </Text>
                                </Td>
                                <Td p={'4px 8px'}>{item.time}</Td>
                                <Td p={'4px 8px'}>{item.day}</Td>
                                <Td p={'4px 8px'}>{item.freq}</Td>
                                <Td p={'4px 8px'}>{item.part}</Td>
                                <Td p={'4px 8px'}>{item.total}</Td>
                                <Td p={'4px 8px'}>{item.point}</Td>
                                <Td p={'4px 8px'}>{item.percent}</Td>
                                <Td p={'4px 8px'}>{item.member}</Td>
                            </Tr>
                        ))}
                </Tbody>
            </Table>
        </TableContainer>
    );
};
