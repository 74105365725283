// import axios from 'axios';
import axiosInstance from './axiosInstance';
import apiService from './apiService';

// 設定優免身份排序
export const postMembershipSortService = async (token: string, data?: object) => {
    return apiService<object, any>({
        method: 'POST',
        path: '/api/membership/sort',
        token,
        data: data
    })
}
// export const postMembershipSortService = async (
//     token: string,
//     updatedData: object
// ) => {
//     try {
//         const requestBody = JSON.stringify(updatedData);
//         const headers = {
//             'Api-Token': token,
//             'Content-Type': 'application/json',
//         };
//         console.log(requestBody, 'requestBody service印的');

//         const response = await axiosInstance.post('/api/membership/sort', requestBody, {
//             headers,
//         });
//         console.log(response, '這個responses不乖');
//         const result = response.data;
//         console.log(result, 'API response');
//         return result;
//     } catch (error: unknown) {
//         if (error instanceof Error) {
//             console.error('Error fetching data:', error.message);
//         } else {
//             console.error('An unknown error occurred');
//         }
//     }
// };
