import { ICCardUpdate } from 'components/ICCardUpdate';

const ICCardUpdatePage = () => {
    return (
        <>
            <ICCardUpdate />
        </>
    );
};

export default ICCardUpdatePage;
