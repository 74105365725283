// 病歷模型 MedicalRecordModel

import { CCPEProps } from 'store/TreatmentManagement';
import { mb1Props, TreatmentInfoProps } from 'store/TreatmentManagement/useTreatmentInfoStore';
import { PatientInfoProps } from 'store/Registration';
import { DoctorSpecDataProps } from '../CompleteOptions';
import { PrescriptionProps } from 'store/TreatmentManagement/usePrescriptionStore';
import { SeqNumber256N1DataProps } from 'functions/hisGetSeqNumber256N1';
import { getDoseDay } from 'functions/getDoseDay';
import { drugTypeListProps } from 'service/drugTypeListService';
import { DrugFreqListsProps } from 'store/DataSetting/useDrugFreqListStore';
import { pBufferProps } from 'service/RsService/RshisGetTreatNumNoICCardService';
export const medicalRecordLogic = (
    treatmentInfo: TreatmentInfoProps,
    CCPEData: CCPEProps,
    patientInfo: PatientInfoProps,
    doctorSpecData: DoctorSpecDataProps,
    filteredList: PrescriptionProps[],
    drugTypeList: drugTypeListProps[],
    drugFreqLists: DrugFreqListsProps[],
    seqNumber256N1Result?: SeqNumber256N1DataProps,
    hisGetTreatNumNoICCardResult?: pBufferProps,
    mb1?: mb1Props,
) => {
    // 為 seqNumber256N1Result 提供預設值

    let finalData = {
        //IPatientBase
        Pat_Code: treatmentInfo.medical_record_number,
        Pat_Name: treatmentInfo.patient_name,
        Pat_IdnoType: (() => {
            switch (patientInfo.info.id_no_type) {
                case '1': // 身分證
                    return 0;
                case '2': // 居留證號
                    return 2;
                case '3': // 護照
                    return 1;
                default:
                    return 0;
            }
        })(), //雲端邏輯 1 身分證 2 居留證號 3 護照 ； 列印邏輯 0 身分證 1 護照 2 居留證號
        Pat_IDNo: treatmentInfo.id_no,
        Pat_Gender:
            treatmentInfo.sex === 'F'
                ? '女'
                : treatmentInfo.sex === 'M'
                  ? '男'
                  : '',
        Pat_Birth: treatmentInfo.birth_date?.toISOString(),
        Pat_BirthTime: '', // 目前應該沒
        Pat_Tel:
            patientInfo.phone.find(phone => phone.type === '2')?.number || '',
        Pat_Mobile:
            patientInfo.phone.find(phone => phone.type === '1')?.number || '',
        Pat_Addr: patientInfo.address?.[0]?.area_name
            ? `${patientInfo.address[0].area_name}` +
              `${patientInfo.address[0].city_name}` +
              `${patientInfo.address[0].content}`
            : '',
        Pat_Memo: '', // 病患備註 #20 押單收200塊, % 都不收, $20 掛號收200 等...規則
        Pat_Job: patientInfo.info.job,
        Pat_Mark: patientInfo.info.note, // 病患註記

        // IDoctorBase
        Dr_Code: doctorSpecData.Dr_Code || '',
        Dr_Name: doctorSpecData.Dr_Name || '',
        E_Dr_Name: doctorSpecData.E_Dr_Name || '',
        Dr_IDNo: doctorSpecData.Dr_IDNo || '',
        Dr_Certificate: doctorSpecData.Dr_Certificate || '',
        Dr_Mobile: doctorSpecData.Dr_Mobile || '',

        // IShiftBase
        Shift_Code: '',
        Shift_Name: treatmentInfo.shift_name,

        // IRoomBase
        RoomSeq: 1,
        RoomName: treatmentInfo.clinic_room_name,

        // IDepartmentBase
        Dept_Code: treatmentInfo.subject_code,
        Dept_Name: treatmentInfo.subject_name,

        // 連籤
        OpenDate: '',
        SlowFreq: 0,
        SlowDays: 0,
        XmlD4: '',

        // IMedicalRecord
        MedicalRecord_ID: Number(treatmentInfo.id),
        ClinicDateTime: treatmentInfo.opd_at
            ? treatmentInfo.opd_at?.toISOString()
            : '',
        Req: 1,
        Nhi_Type: treatmentInfo.nhi_type_name,
        Nhi_Card:
            treatmentInfo.card_no ||
            seqNumber256N1Result?.visitSerialNumber ||
            '',
        // TreatNumber: treatmentInfo.card_no
        //     ? hisGetTreatNumNoICCardResult?.medicalTreatmentIdentificationCode
        //     : seqNumber256N1Result?.visitIdentificationCode || mb1.treatment_no,
        TreatNumber: mb1?.treatment_no || hisGetTreatNumNoICCardResult?.medicalTreatmentIdentificationCode || seqNumber256N1Result?.visitIdentificationCode || '',
        CC: CCPEData.cc,
        PE: CCPEData.pe,
        Kind: treatmentInfo.case_type ? treatmentInfo.case_type : '01', // 案件
        // DoseDays: filteredList.reduce((maxDays, prescription) => {
        //     const currentDays = Number(prescription.dosing_days) || 0;
        //     return currentDays > maxDays ? currentDays : maxDays;
        // }, 0), // 初始值設為 0
        DoseDays: getDoseDay(filteredList, drugTypeList, drugFreqLists),
        Pres: (() => {
            switch (treatmentInfo.prescription_style) {
                case 0:
                    return '（醫師）自行調劑';
                case 1:
                    return '交付調劑';
                case 2:
                    return '院內（藥師）調劑';
                default:
                    return '';
            }
        })(), // 調劑方式
        Weight: parseFloat(
            Number(
                treatmentInfo.vital_sign?.find(item => item.name === '體重')
                    ?.value
            ).toFixed(1)
        ),
        Temperature: parseFloat(
            Number(
                treatmentInfo.vital_sign?.find(item => item.name === '體溫')
                    ?.value
            ).toFixed(1)
        ),
        QtyType: 'O', // 次量
        MedicalType: '門診',
        PayCode: treatmentInfo.copayment,
    };
    return finalData;
};
