import { useContext, useMemo } from 'react';
import { useWaitingListsStatus } from 'contexts/Registration/WaitingListsStatusContext';
import { RegistrationOpdListProps, ClinicListProps } from 'service/opdListsService';
import { RegistrationListProps } from './VisitedListBody';
import { useRegistrationListStatus } from 'contexts/Registration/RegistrationListStatusContext';
import { useLocation } from 'react-router-dom';

export const useFilterRegistrationOpdLists = (opdLists: RegistrationListProps | ClinicListProps) => {
    const {
        filterByDoctorStatus,
        filterByPeriodStatus,
        filterByPatientDataStatus,
    } = useRegistrationListStatus();
    const location = useLocation();
    const isRegisteredPage = location.pathname.includes('RegisteredPage');

    return useMemo(() => {
        const regex = new RegExp(filterByPatientDataStatus, 'i');
        const filteredData: RegistrationOpdListProps | ClinicListProps = {};

        Object.entries(opdLists).forEach(([key, item]) => {
            if (
                (filterByPeriodStatus !== '' &&
                    item.shift_name !== filterByPeriodStatus) ||
                (filterByDoctorStatus !== '' &&
                    item.doctor_name !== filterByDoctorStatus)
            ) {
                return;
            }
            if (
                regex.test(item.patient_name) ||
                regex.test(item.birth_date) ||
                regex.test(item.medical_record_number)
            ) {
                filteredData[key] = isRegisteredPage 
                    ? item as RegistrationListProps 
                    : { ...item, data_id: (item as ClinicListProps).data_id };
            }
        });
        return filteredData;
    }, [
        opdLists,
        filterByPeriodStatus,
        filterByDoctorStatus,
        filterByPatientDataStatus,
        isRegisteredPage,
    ]);
};
