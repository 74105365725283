import { Box } from '@chakra-ui/react';

const AsideArrowIcon = ({ onClick }: any) => {
    return (
        <Box>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
            >
                <g clipPath="url(#clip0_66_206)">
                    <g filter="url(#filter0_d_66_206)">
                        <path
                            d="M17.5001 32.0833C25.5542 32.0833 32.0834 25.5542 32.0834 17.5C32.0834 9.44585 25.5542 2.91667 17.5001 2.91667C9.44593 2.91667 2.91675 9.44585 2.91675 17.5C2.91675 25.5542 9.44593 32.0833 17.5001 32.0833Z"
                            fill="#E9E5DA"
                        />
                    </g>
                    <path
                        d="M17.5 23.3333L23.3333 17.5L17.5 11.6667"
                        stroke="#868C6E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M11.6667 17.5H23.3334"
                        stroke="#868C6E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_d_66_206"
                        x="1.91675"
                        y="1.91667"
                        width="33.1667"
                        height="33.1667"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx="1" dy="1" />
                        <feGaussianBlur stdDeviation="1" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_66_206"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_66_206"
                            result="shape"
                        />
                    </filter>
                    <clipPath id="clip0_66_206">
                        <rect width="35" height="35" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </Box>
    );
};

export default AsideArrowIcon;
