import apiService from './apiService';
// 寫入病歷及批價資訊
type DiagnosisProps = {
    data_id: string;
    ds?: string;
    doctor_orders?: string;
};

type DrugProps = {
    drug_in_use_uid: string; // 醫令資料id (必填)
    qty: number; // 使用量 (必填, 數字型態，整數5位，小數點2位)
    qty_in_day: number; // 日量 (必填, 數字型態，整數5位，小數點2位)
    freq: string; // 用法 (必填, 給藥頻率的資料 id)
    site?: string; // 部位code
    route?: string; // 途徑code (當健保碼長度為 10 碼時，此欄位為必填)
    dose_days?: number; // 給藥天數 (數字型態)
    total_qty: number; // 總量 (必填, 數字型態，整數7位，小數點2位)
    given_qty: number; // 給藥量 (必填, 數字型態，整數7位，小數點2位)
    add_days?: number; // 加開天數 (數字型態)
    add_given_qty?: number; // 加開給藥量 (數字型態，整數7位，小數點2位)
    memo?: string; // 備註
    status?: number; // 階段 (0：開立、5：已批、6：已執行、9：封存)
    doctor_order?: string; // 醫囑
    icd_code?: string; // 國際病名碼
    started_at?: string; // 開始時間 (西元日期時間型態YYYY-mm-dd HH:ii:ss)
    ended_at?: string; // 結束時間 (西元日期時間型態YYYY-mm-dd HH:ii:ss)
    op_idno?: string; // 執行人員/身分證號
    special_mark?: string[]; // M欄註記
};

export type CashierProps = {
    acc_item_uid: string;
    cashier_type: number;
    price: number;
    discount?: number;
    final: number;
};

export type finalDataProps = {
    data_id?: string;
    if_formal_data?: string;
    medical_type?: number;
    treated_at: string; //y
    card_no?: string;
    opd_uid: string; //y
    shift_uid: string; //y
    clinic_room_uid: string; //y
    patient_uid: string; //y
    doctor_uid: string; //y

    seq?: string;
    declare_doctor_uid?: string;
    pharmacist_uid?: string;
    case_type?: string;
    medical_subject?: string;
    dose_days?: number;
    prescription_style?: number;
    cc?: string;
    note?: string;
    diagnosis: DiagnosisProps[];
    drugs: DrugProps[];
    cashier: CashierProps[];
    ori_mt_type: string | undefined;
    mt_type: string;
    copayment: string;
    referral: string;
    benefit_type: string;
    special_treatment: string;
};

export const postMedicalRecordService = async (
    token: string,
    finalData: finalDataProps
) => {
    return apiService<finalDataProps, any>({
        method: 'POST',
        path: '/api/medical_record',
        token,
        data: finalData,
    });
};
